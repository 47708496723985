import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import Select, { OptionsType, OptionTypeBase, components } from 'react-select';
import i18n from 'core/i18n/i18n';

import {
  StyledInput,
  StyledTextarea,
  InputFieldWrapper,
  ToolTipWrapper,
} from '../TextField/textFieldStyle';
import { selectStyles, Blanket, Menu, Selector } from './phoneFieldStyle';
import { Paragraph as P, ToolTip, Flex, Grid, IcomoonIcons } from 'components';
import { InputLabel, InputError, InputContainer, Spacer } from 'style';

const dial_codes = require('./dial_codes.json');

interface Props {
  /** The name of the input for Formik */
  name: string;
  /** Static placeholder text to display */
  placeholder?: string;
  /** Static label to display */
  label?: string | React.ReactNode;
  /** Localized label for the text field. */
  /** MUST REFERENCE ITEM IN RESOURCE OBJECT. */
  i18nLabel?: string;
  /** Toggle the input on or off */
  disabled?: boolean;
  /** Cypres testing attribute */
  cypress?: string;
  /** if the field requires a tooltip, what to display */
  tooltip?: string;

  required?: boolean;
}

const Dropdown = ({ children, isOpen, target, onClose }: { children:any, isOpen:any, target:any, onClose:any }) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <IcomoonIcons icon="search" color="blueGrey" />
    </components.DropdownIndicator>
  );
};

const PhoneField: React.FC<Props> = ({
  label,
  i18nLabel,
  tooltip,
  required,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [country, setCountry] = useState<OptionTypeBase>();
  const [inputRef, setInputRef] = useState<any>();

  const { t } = useTranslation();

  const options: OptionsType<OptionTypeBase> = dial_codes.map((code:any) => (
    {
      value: code.dial_code,
      label: `${code.flag} ${i18n.t('countries:' + code.name)} (${code.dial_code})`,
      icon: code.flag,
    }
  ));

  useEffect(() => {
    if (field.value) {
      if (country?.value === undefined || !field.value.startsWith(country.value)) {
        setCountry(options.find(o => field.value.startsWith(o.value)));
      }
    } else {
      setCountry(undefined);
    }
  }, [field.value]);

  return (
    <InputContainer
      data-cypress={props.cypress}
      data-override="input-container">
      {label && (
        <Flex>
          {typeof label === 'string' ? (
            <>
              <InputLabel htmlFor={field.name}>{`${
                i18nLabel ? t(i18nLabel) : label
              }`}</InputLabel>
              {required && <InputLabel>*</InputLabel>}
            </>
          ) : (
            label
          )}
          {tooltip && (
            <>
              <Spacer width={5} />
              <ToolTip content={tooltip} iconSize={14} />
            </>
          )}
        </Flex>
      )}
      <Grid gridTemplateColumns="75px 1fr" gridColumnGap="14px">
        <Dropdown
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          target={
            <Selector onClick={() => setIsOpen(!isOpen)} type="button">
              {country ? `${country.icon}` : '🌐'}
              <IcomoonIcons icon="chevron-down" color="blueGrey" />
            </Selector>
          }
        >
          <Select
            autoFocus
            isDisabled={props.disabled}
            backspaceRemovesValue={false}
            controlShouldRenderValue={false}
            components={{ DropdownIndicator }}
            menuIsOpen
            value={country}
            onChange={value => {
              setIsOpen(false);
              setCountry(value);
              helpers.setValue(value.value);
              inputRef.focus();
            }}
            placeholder="Search..."
            tabSelectsValue={false}
            onBlur={field.onBlur}
            options={options}
            styles={selectStyles}
          />
        </Dropdown>
        <InputFieldWrapper>
          <StyledInput
            id={field.name}
            type='text'
            error={meta.touched && meta.error ? true : false}
            ref={ref => (setInputRef(ref))}
            data-testid="text-field"
            {...field}
            {...props}
          />
        </InputFieldWrapper>
      </Grid>
      {meta.touched && meta.error ? (
        <InputError>
          <P
            size="small"
            color="secondary"
            data-cypress={`${props.cypress}-error`}
            data-testid="text-field-error">
            {meta.error}
          </P>
        </InputError>
      ) : (
        <Spacer height={20} />
      )}
    </InputContainer>
  );
};

export default PhoneField;
