import styled from '@emotion/styled';
import { theme, flexCenterAll } from 'style';

export const Container = styled.div`
  ${flexCenterAll};
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.15s ease-in-out;
`;

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`;

export const Items = styled.div<{ isOpen: boolean }>`
  overflow-y: scroll;
  background-color: ${theme.colors.white};
  z-index: 5;
  height: calc(100vh - 80px);
  padding-bottom: 20px;
`;

export const Divider = styled.div`
  width: calc(100% - 60px);
  margin: 20px auto;
  border-bottom: ${theme.borders(1, 'lightBlueGrey')};
`;

export const PageHeading = styled.p`
  text-transform: capitalize;
`
