import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18n from 'core/i18n/i18n';
import { Formik, Form } from 'formik';

import { DoorDetails, EditDoorParams } from 'models';
import { FormComponents, SelectField, TextField, Spinner } from 'components';
import { useDoorDetailsStore } from 'stores';
import { editDoorDetails } from 'services';
import { translateRefreshFrequency } from '../../../../utils';
import { Container, RowContainer } from './editDoorDetailsStyle';

interface EditDoorDetailsProps {
  data: DoorDetails;
  toggleEditDetails: () => void;
}

const Validation = Yup.object({
  name: Yup.string().required(i18n.t('forms:validation.required')),
});

const EditDoorDetails = ({ data, toggleEditDetails }: EditDoorDetailsProps) => {
  const { t } = useTranslation();
  const {
    refreshFrequencyData,
    refreshFrequencyFetch,
    doorDetailsFetch,
  } = useDoorDetailsStore();
  const [error, setError] = useState('');

  const translatedRefreshFrequencyData = translateRefreshFrequency(
    t,
    refreshFrequencyData,
  );

  const onSubmit = async (
    values: EditDoorParams,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true);
    try {
      await editDoorDetails({
        id: data.icNumber,
        editParams: {
          name: values.name,
          refreshFrequency: values.refreshFrequency,
        },
      });
      await doorDetailsFetch(data.icNumber);
      toggleEditDetails();
    } catch (err) {
      setError(t('spaceDetails:modals.error'));
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    refreshFrequencyFetch();
  }, []);

  return (
    <FormComponents.Wrapper>
      <FormComponents.Header heading={t('doorDetails:edit_form.heading')} />
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
        validationSchema={Validation}
        initialValues={
          data && {
            name: data.name,
            refreshFrequency: data.syncSchedule,
          }
        }>
        {(props) => (
          <Form>
            <Container>
              <RowContainer style={{ maxWidth: 500 }}>
                <TextField
                  label={t('doorDetails:edit_form.labels.name')}
                  name="name"
                />
              </RowContainer>
              <RowContainer>
                {refreshFrequencyData.length > 0 ? (
                  <SelectField
                    label={t('doorDetails:edit_form.labels.refresh_frequency')}
                    name="refreshFrequency"
                    options={translatedRefreshFrequencyData}
                    tooltip={t('doorDetails:edit_form.labels.type_tooltip')}
                    disabled={false}
                  />
                ) : (
                  <Spinner color="lightBlueGrey" />
                )}
              </RowContainer>
            </Container>
            <FormComponents.Submit
              style={{ paddingRight: 0 }}
              label={t('doorDetails:edit_form.buttons.save')}
              cancelAction={toggleEditDetails}
              errorText={error || null}
              {...props}
            />
          </Form>
        )}
      </Formik>
    </FormComponents.Wrapper>
  );
};

export default EditDoorDetails;
