import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Controls,
  MobileFlare,
  LegendItem,
} from './historicOccupancyStyle';
import HistoricOccupancyTable from './HistoricOccupancyTable/HistoricOccupancyTable';
import LoadingSkeleton from './LoadingSkeleton/LoadingSkeleton';

import {
  ChartContainer,
  Flex,
  Paragraph as P,
  SearchBar,
  DateSlideToggle,
} from 'components';
import {
  useTableSortBy,
  useTableSearch,
  useScreenSize,
  usePrevious,
} from 'hooks';
import { occupancyChartConfig, CurrentOccupancyDataObject } from 'utils';
import { useHistoricOccupancyStore, useWebSocketMessageStore } from 'stores';
import { HistoricOccupancy as HistoricOccupancyModel } from 'models';

interface Props {
  followedOnly: boolean;
}

const shouldTableUpdate = (spaceId: string, data: HistoricOccupancyModel[]) => {
  const ids = data.map((item: HistoricOccupancyModel) => item.space.id);
  if (ids.includes(spaceId)) {
    return true;
  } else {
    return false;
  }
};

const Legend = ({ data }: { data: CurrentOccupancyDataObject[] }) => {
  const { t } = useTranslation();
  const { isTablet } = useScreenSize().screenSize();

  return (
    <Flex justifyContent={isTablet ? 'flex-start' : 'flex-end'}>
      {data
        .filter((item) => item.name !== 'future')
        .map((item: CurrentOccupancyDataObject) => (
          <LegendItem key={item.label.historic}>
            <MobileFlare color={item.color} />
            <P color="charcoal" margin="0" size="small">
              {t(item.label.historic)}
            </P>
          </LegendItem>
        ))}
    </Flex>
  );
};

const TableError = ({
  heading,
  subheading,
}: {
  heading: string;
  subheading: string;
}) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    paddingTop={window.innerHeight / 2 - 350}>
    <P size="large" color="primary" bold mb="15px">
      {heading}
    </P>
    <P size="normal" color="charcoal">
      {subheading}
    </P>
  </Flex>
);

const HistoricOccupancy = ({ followedOnly }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  const {
    historicOccupancyData,
    historicOccupancyFetch,
    isLoading,
    params,
    setParams,
    dateRangeParam,
    setDateRangeParam,
  } = useHistoricOccupancyStore();

  useEffect(() => {
    historicOccupancyFetch({ followedOnly });
  }, [followedOnly, dateRangeParam]);

  const previousParams = usePrevious(params);
  useEffect(() => {
    if (
      previousParams &&
      JSON.stringify(params) !== JSON.stringify(previousParams)
    ) {
      historicOccupancyFetch({ followedOnly });
    }
  }, [params]);

  const viewAllClickHandler = () => {
    history.push('/spaces');
  };

  const { sortByHandler } = useTableSortBy(params, setParams);
  const sortWrapper = (sortBy: any) => {
    switch (sortBy) {
      case 'space.dataSummary.exceededPct':
        return sortByHandler('Exceeded');
      case 'space.dataSummary.approachingPct':
        return sortByHandler('Approaching');
      case 'space.dataSummary.withinPct':
        return sortByHandler('Within');
      case 'space.dataSummary.offlinePct':
        return sortByHandler('Offline');
      default:
        return sortByHandler(sortBy);
    }
  };

  const { searchValue, searchHandler, clearSearch } = useTableSearch(
    params,
    setParams,
  );

  const clearSearchWrapper = () => {
    clearSearch();
    historicOccupancyFetch({ followedOnly });
  };

  const { lastMessage } = useWebSocketMessageStore();

  useEffect(() => {
    if (lastMessage) {
      if (
        lastMessage.Event === 'OccupancyStatusChange' ||
        lastMessage.Event === 'SpaceOffline' ||
        lastMessage.Event === 'SpaceOnline'
      ) {
        const identifier = lastMessage?.Target?.Identifier;

        if (
          identifier &&
          shouldTableUpdate(identifier, historicOccupancyData)
        ) {
          historicOccupancyFetch({ update: true, followedOnly });
        }
      }
    }
  }, [lastMessage]);

  return (
    <ChartContainer
      title={t('historicOccupancy:title')}
      tooltip={t('historicOccupancy:tooltip')}
      toggle={
        <div style={{ width: '50%' }}>
          <DateSlideToggle setDateRange={setDateRangeParam} untilNow={true} />
        </div>
      }>
      <Container>
        {!isMobile && (
          <Controls>
            <SearchBar
              placeholder={t('historicOccupancy:search_placeholder')}
              searchValue={searchValue || ''}
              searchHandler={searchHandler}
              clearSearch={clearSearchWrapper}
            />

            <Legend
              data={occupancyChartConfig.filter(
                (item: CurrentOccupancyDataObject) =>
                  item.name !== 'notCommissioned',
              )}
            />
          </Controls>
        )}
        {!isLoading && historicOccupancyData.length === 0 ? (
          <TableError
            heading={t('spacesList:table.errors.empty.heading')}
            subheading={t('spacesList:table.errors.empty.subheading')}
          />
        ) : (
          <LoadingSkeleton isLoading={isLoading}>
            <HistoricOccupancyTable
              sortBy={sortWrapper}
              data={historicOccupancyData}
            />
          </LoadingSkeleton>
        )}
      </Container>
    </ChartContainer>
  );
};

export default HistoricOccupancy;
