import create from 'zustand';

type ScreenSizeState = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLargeMobile: boolean;
  isLandscapeTablet: boolean;
  isWideDesktop: boolean;
};

type ScreenSizeStore = ScreenSizeState & {
  updateValues: () => void;
};

const useScreenSize = () => {
  const [screenSize, screenSizeStore] = create<ScreenSizeStore>((set, get) => ({
    isMobile: window.innerWidth < 768,
    isTablet: window.innerWidth >= 768 && window.innerWidth < 992,
    isDesktop: window.innerWidth >= 992,
    isLargeMobile: window.innerWidth >= 576 && window.innerWidth < 768,
    isLandscapeTablet: window.innerWidth >= 992 && window.innerWidth < 1025,
    isWideDesktop: window.innerWidth >= 1200,
    updateValues: () => {
      set((state) => {
        const windowWidth = window.innerWidth;
        return {
          ...state,
          ...{
            isMobile: windowWidth < 768,
            isTablet: windowWidth >= 768 && windowWidth < 992,
            isDesktop: windowWidth >= 992,
            isLargeMobile: windowWidth >= 576 && windowWidth < 768,
            isLandscapeTablet: windowWidth >= 992 && windowWidth < 1025,
            isWideDesktop: windowWidth >= 1200,
          },
        };
      });
    },
  }));

  return { screenSize, screenSizeStore };
};

export default useScreenSize;
