import React from 'react';

import { Button } from 'components';
import { AnnouncementsCount, AnnouncementsToggleContainer } from './announcementsToggleStyle';

interface Props {
  count: number;
  isOpen: boolean;
  onClick?: () => void;
}

const AnnouncementsToggle = ({
  count,
  isOpen,
  onClick
}: Props) => {
  return (
    <AnnouncementsToggleContainer isOpen={isOpen}>
      <Button variant="bare" icon="announcement" onClick={onClick}>
      </Button>
      {count > 0 && (
        <AnnouncementsCount>{count}</AnnouncementsCount>
      )}
    </AnnouncementsToggleContainer>
  );
};

export default AnnouncementsToggle;
