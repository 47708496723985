import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Directory imports
import { CurrentOccupancy, HistoricOccupancy } from './charts';
import { DashboardHeader } from './components';
import { SectionHeader } from './dashboardStyles';
// Src imports
import {
  Heading as H,
  SlideToggle,
  Container,
  Paragraph as P,
  Announcements,
  Button,
} from 'components';
import { useScreenSize } from 'hooks';
import { useCurrentOccupancyStore, useModalStore } from 'stores';
import { User } from 'models';
import { Spacer, theme } from 'style';
import { SectionHeaderActions } from 'products/door-insights/DoorDetails/components/Alert/alertStyle';
import { ExportReportModal } from '../SpaceDetails/modals';

type Props = RouteComponentProps;

const Dashboard: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [userMe, setUserMe] = useState<User>({} as User);
  const { exportReportModal } = useModalStore();

  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  const { currentOccupancyData } = useCurrentOccupancyStore();

  const [followedOnly, setFollowedOnly] = useState<boolean>(false);
  const toggleFollowedOnly = (status: boolean) => {
    setFollowedOnly(status);
  };

  const slideToggleItems = [
    {
      label: t('dashboard:slide_toggle.allSpaces'),
      callback: () => toggleFollowedOnly(false),
    },
    {
      label: t('dashboard:slide_toggle.followingSpaces'),
      callback: () => toggleFollowedOnly(true),
    },
  ];
  const { isMobile } = useScreenSize().screenSize();

  return (
    <>
      {!isMobile && (
        <DashboardHeader lastUpdate={currentOccupancyData?.lastUpdate} />
      )}

      <Announcements />

      <Container>
        <SectionHeader>
          <H color="primary" size="h3" mb={['15px', 0]}>
            {userMe?.account?.name}{' '}
            {t('dashboard:network_health_status_heading')}
          </H>
          <SlideToggle
            items={slideToggleItems}
            indexOverride={followedOnly ? 1 : 0}
          />
        </SectionHeader>
        <SectionHeaderActions
          style={{ borderTop: theme.borders(1, `blueGrey`) }}>
          {userMe.userRole === 'Admin' && (
            // border-bottom: ${theme.borders(1, `blueGrey`)};
            <P ml="15px" mt="25px" mb={0}>
              <Button
                variant="contained"
                icon="edit"
                onClick={exportReportModal.toggleModal}>
                {t('spaceDetails:modals.export_report.title')}
              </Button>
            </P>
          )}
        </SectionHeaderActions>
        <Spacer height={30} />
        <CurrentOccupancy followedOnly={followedOnly} />
        <Spacer height={30} />
        <HistoricOccupancy followedOnly={followedOnly} />
      </Container>

      <ExportReportModal
        userId={userMe.id}
        accountId={userMe.account?.id}
        isNetworkLevelReport={true}
      />
    </>
  );
};

export default Dashboard;
