
import { RefreshFrequency } from 'models';

const translateRefreshFrequency = (t: any, refreshFrequencyData: RefreshFrequency[]): RefreshFrequency[] => {
	return refreshFrequencyData.map((refreshFrequencyRow)=> {
    const { label } = refreshFrequencyRow;
    refreshFrequencyRow.label = label
      .replace("mins", t('settings:door_settings.refresh_cycle_field.mins'))
      .replace("Hourly", t('settings:door_settings.refresh_cycle_field.hourly'))
      .replace("Daily (max)", t('settings:door_settings.refresh_cycle_field.daily_max'));
    return refreshFrequencyRow;
  });
};

export default translateRefreshFrequency;
