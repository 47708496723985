import styled from '@emotion/styled';

import { theme } from 'style';

export const DatePickerWrapper = styled.div<any>`
  & {
    position: absolute;
    z-index: 2;
    top: 70px;
    right: 0;
    font-family: ${theme.typography.fontFamily};
    box-shadow: ${theme.boxShadow};
    .CalendarDay__selected:hover {
      background: ${theme.colors.greyBlue};
      border: ${theme.borders(1, 'greyBlue')};
      color: white;
    }
    .CalendarDay__default {
      color: ${theme.colors.charcoal};
    }
    .CalendarDay__default:hover {
      border: ${theme.borders(1, 'primary')};
      background: ${theme.colors.primary};
      color: white;
    }
    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
      border: ${theme.borders(1, 'greyBlue')};
      background: ${theme.colors.greyBlue};
      color: white;
    }
    .CalendarDay__selected {
      background: ${theme.colors.primary};
      color: white;
      border: ${theme.borders(1, 'primary')};
    }
    .CalendarDay__selected_span {
      background: ${theme.colors.greyBlue};
      color: white;
      border: ${theme.borders(1, 'greyBlue')};
    }
    .CalendarMonth_caption {
      font-family: ${theme.typography.fontFamily};
      font-weight: 600;
      color: ${theme.colors.primary};
      font-size: 20px;
    }
    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
      color: ${theme.colors.grey};
      background: white;
      border: ${theme.borders(1, 'lightGrey')};
    }
    .CalendarMonth_table {
      margin-top: 5px;
      tbody {
        tr {
          td {
            vertical-align: middle;
            border: ${theme.borders(1, 'lightGrey')};
          }
        }
      }
    }
    .DayPickerNavigation_button__default {
      border: none;
    }
    .DayPickerNavigation_button__default:hover {
      border: none;
    }
    .DayPicker_weekHeader_ul {
      font-family: ${theme.typography.fontFamily};
      font-size: 14px;
      line-height: 21px;
      color: ${theme.colors.grey};
      margin-bottom: 5px;
    }
    .DateInput_input {
      font-family: ${theme.typography.fontFamily};
    }
    .DateInput_input__focused {
      border-bottom: 2px solid ${theme.colors.primary};
    }
  }
  .DayPicker {
    border: ${theme.borders(1, 'lightBlueGrey')};
    border-radius: 4px;
  }
`;

const NavigationArrowWrapper = styled.div`
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 3px;
  cursor: pointer;
  color: ${theme.colors.blueGrey};
  :hover {
    color: ${theme.colors.primary};
  }
  transition: color 0.15 ease-out;
`;

export const RightArrowWrapper = styled(NavigationArrowWrapper)`
  right: 22px;
`;

export const LeftArrowWrapper = styled(NavigationArrowWrapper)`
  left: 22px;
`;

export const Arrow = styled.div`
  height: 20px;
  width: 20px;
  border-top: ${theme.borders(1, 'lightBlueGrey')};
  border-left: ${theme.borders(1, 'lightBlueGrey')};
  background: white;
  position: absolute;
  top: -10px;
  transform: rotate(45deg);
  z-index: 2;
  right: 40px;
`;
