import { Table } from 'components';
import { ReportsList } from 'models';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTable } from 'react-table';

interface Props {
  data: ReportsList[];
  sortBy: (header: any) => void;
  onClick: (report: ReportsList) => void;
}

const ReportsTable = ({ data, sortBy, onClick }: Props) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'reports:reportName',
            accessor: 'title',
            sortable: false,
            filterable: true,
          },
          {
            Header: 'reports:reportPeriod',
            accessor: 'period',
            sortable: false,
            filterable: true,
          },
        ],
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <Table
      columns="2.5fr 4.5fr 60px"
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      rows={rows}
      prepareRow={prepareRow}
      onRowClick={onClick}
      minWidth={1100}
      navigateIcon="download"
      sortBy={sortBy}
    />
  );
};

export default ReportsTable;
