import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  StyledInput,
  StyledTextarea,
  InputFieldWrapper,
  ToolTipWrapper,
} from './textFieldStyle';
import { Paragraph as P, ToolTip, Flex } from 'components';
import { InputLabel, InputError, InputContainer, Spacer } from 'style';

interface Props {
  /** The name of the input for Formik */
  name: string;
  /** Static placeholder text to display */
  placeholder?: string;
  /** Static label to display */
  label?: string | React.ReactNode;
  /** Localized label for the text field. */
  /** MUST REFERENCE ITEM IN RESOURCE OBJECT. */
  i18nLabel?: string;
  /** Toggle the input on or off */
  disabled?: boolean;
  /** What type of input the field will be used for */
  type?: 'text' | 'email' | 'password' | 'textarea';
  /** Cypres testing attribute */
  cypress?: string;
  /** if the field requires a tooltip, what to display */
  tooltip?: string;

  corporateEmailOnly?: boolean;

  required?: boolean;

  rows?: number;
}

const TextField: React.FC<Props> = ({
  label,
  i18nLabel,
  tooltip,
  required,
  corporateEmailOnly,
  ...props
}) => {
  const [field, meta] = useField(props);

  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" width="100%">
      <InputContainer
        data-cypress={props.cypress}
        data-override="input-container">
        {label && (
          <Flex>
            {typeof label === 'string' ? (
              <>
                <InputLabel htmlFor={field.name}>{`${
                  i18nLabel ? t(i18nLabel) : label
                }`}</InputLabel>
                {required && <InputLabel>*</InputLabel>}
              </>
            ) : (
              label
            )}
            {tooltip && (
              <>
                <Spacer width={5} />
                <ToolTip content={tooltip} iconSize={14} />
              </>
            )}
          </Flex>
        )}
        <InputFieldWrapper>
          {props.type == 'textarea' ? (
            <StyledTextarea
              id={field.name}
              error={meta.touched && meta.error ? true : false}
              {...field}
              {...props}
              data-testid="text-field"
              rows={props.rows || 10}
            />
          ) : (
            <StyledInput
              id={field.name}
              type={props.type || 'text'}
              error={meta.touched && meta.error ? true : false}
              {...field}
              {...props}
              data-testid="text-field"
            />
          )}
        </InputFieldWrapper>
        {corporateEmailOnly && (
          <>
            <Spacer height={5} />
            <P size="small" color="charcoal">
              {t('forms:validation.corporate_email')}
            </P>
            <Spacer height={10} />
          </>
        )}
        {meta.touched && meta.error ? (
          <InputError>
            <P
              size="small"
              color="secondary"
              data-cypress={`${props.cypress}-error`}
              data-testid="text-field-error">
              {meta.error}
            </P>
          </InputError>
        ) : (
          <Spacer height={20} />
        )}
      </InputContainer>
    </Flex>
  );
};

export default TextField;
