import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  LineChart,
  Tooltip,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';

import { SpaceTrafficHistory } from 'models';
import { ChartComponents, Skeleton } from 'components';
import { theme } from 'style';
import { useScreenSize } from 'hooks';

import { LINE_COLORS } from '../TrafficHistory';
import { formatChartData, getYDomainValue } from './utils';
import CustomToolTip from './CustomToolTip/CustomToolTip';
interface Props {
  data: SpaceTrafficHistory[];
  isLoading: boolean;
  dataScale: 'low' | 'high' | 'average';
  timePeriod: 'today' | 'week';
  selectedDay?: string;
}

const TrafficHistoryChart = ({
  isLoading,
  data,
  dataScale,
  timePeriod,
  selectedDay,
}: Props) => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  const todayName = moment(new Date()).format('dddd');
  const setDatakey = (date: any) =>
    selectedDay
      ? selectedDay
      : moment(timePeriod === 'today' ? new Date() : date).format('dddd');

  const linesWithOpacity = (
    todayName: string,
    scaleValue: 'average' | 'high' | 'low',
    dataKey: 'average' | 'high' | 'low',
  ) => {
    return dataKey === scaleValue
      ? LINE_COLORS[todayName]
      : `${LINE_COLORS[todayName]}40`;
  };

  const chartData = formatChartData(data);

  const [singleDayView, setSingleDayView] = useState<boolean>(true);

  useEffect(() => {
    timePeriod === 'today' || selectedDay
      ? setSingleDayView(true)
      : setSingleDayView(false);
  }, [timePeriod, selectedDay]);

  return (
    <ResponsiveContainer
      height={275}
      width="100%"
      data-testid="daily-traffic-chart">
      {!isLoading ? (
        <LineChart
          data={chartData}
          margin={{ top: 5, left: -15, bottom: 5, right: 10 }}>
          {ChartComponents.CartesianGrid()}

          {singleDayView
            ? ['average', 'high', 'low'].map((value: any, i: number) => (
                <Line
                  key={i}
                  isAnimationActive={false}
                  type="linear"
                  dataKey={`${selectedDay ? selectedDay : todayName}.${value}`}
                  dot={{ strokeWidth: 3 }}
                  stroke={linesWithOpacity(
                    selectedDay ? selectedDay : todayName,
                    value,
                    dataScale,
                  )}
                />
              ))
            : data.map((item: SpaceTrafficHistory, i: number) => {
                const { date } = item;
                const dataKey = setDatakey(date);
                return (
                  <Line
                    key={i}
                    isAnimationActive={false}
                    type="linear"
                    dataKey={`${dataKey}.${dataScale}`}
                    dot={{ strokeWidth: 3 }}
                    stroke={LINE_COLORS[dataKey]}
                  />
                );
              })}

          <YAxis
            axisLine={false}
            type="number"
            scale="linear"
            domain={[0, getYDomainValue(data)]}
            allowDecimals={false}
            tickMargin={10}
            tick={{
              fontSize: '14px',
              fill: theme.colors.charcoal,
            }}
            tickLine={false}
          />
          <XAxis
            dataKey="from"
            xAxisId={0}
            axisLine={false}
            tickLine={false}
            tick={{
              fontSize: '14px',
              lineHeight: '21px',
              fill: theme.colors.charcoal,
            }}
            tickFormatter={(time: number) => {
              return moment(time, 'HH:mm:ss').format('H');
            }}
          />
          <XAxis
            dataKey="from"
            xAxisId={1}
            tick={{
              fontSize: '14px',
              lineHeight: '21px',
              fontWeight: 'bold',
              textAlign: 'left',
              fill: theme.colors.charcoal,
            }}
            tickMargin={10}
            tickFormatter={(time) => moment(time, 'HH:mm:ss').format('A')}
            axisLine={false}
            tickLine={false}
            ticks={['00:00:00', '12:00:00']}
          />
          <Tooltip
            content={
              <CustomToolTip
                showSingleDay={
                  timePeriod === 'today' || selectedDay !== undefined
                }
              />
            }
            cursor={{ stroke: theme.colors.primary }}
            allowEscapeViewBox={{ x: !isMobile, y: !isMobile }}
          />
        </LineChart>
      ) : (
        <Skeleton height={275} />
      )}
    </ResponsiveContainer>
  );
};

export default TrafficHistoryChart;
