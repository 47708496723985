import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from 'hooks';
import {
  ChartContainer,
  Flex,
  Heading as H,
  Paragraph as P,
  IcomoonIcons,
} from 'components';
import { Spacer } from 'style';

interface Props {
  chartTitle: string;
  heading: string;
  content: string;
}

const NoFollowedDoors = ({ chartTitle, heading, content }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize().screenSize();

  return (
    <ChartContainer
      elevated
      childrenStyleProps={{ padding: 0 }}
      title={t(chartTitle)}>
      <Flex
        centered
        flexDirection="column"
        height={isMobile ? '360px' : '400px'}>
        <IcomoonIcons icon="success" color="success" size={90} />
        <Spacer height={25} />
        <H size="h6" color="primary" mr="8px">
          {t(heading)}
        </H>
        <Spacer height={10} />
        <P size="normal" color="charcoal" textAlign="center">
          {t(content)}
        </P>
      </Flex>
    </ChartContainer>
  );
};

export default NoFollowedDoors;
