import styled from '@emotion/styled';
import { theme } from 'style';

export const Spacer = styled.div<{ height?: number; width?: number }>(
  (props) => ({
    height: props.height,
    width: props.width,
  }),
);

export const HorizontalRule = styled.div<{ margin?: number }>`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.lightBlueGrey};
  margin: 30px 0px;
`;

// Inputs
export const InputContainer = styled.div({
  width: '100%',
});

type InputLabelProps = React.HTMLAttributes<HTMLLabelElement>;

export const InputLabel = styled.label<InputLabelProps>`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  color: ${theme.colors.primary};
  margin-bottom: 10px;
`;

export const InputLabelWithAction = styled(InputLabel)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const InputActionLabel = styled.a`
  color: ${theme.colors.greyBlue};
`;

export const InputError = styled.div`
  height: 30px;
  margin-top: 8px;
  margin-bottom: 20px;
  p {
    color: ${theme.colors.secondary};
  }
`;

export const AlertGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: 'repeat(2, 1fr)',
  gridGap: 20,
});
