import styled from '@emotion/styled';
import { theme, flexVertical, flexCenter, mq } from 'style';

interface LinkProps {
  isHiddenRoute?: boolean;
}

export const StyledDiv = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  ${flexVertical}
  background-color: white;
  display: flex;
  justify-content: space-between;
  box-shadow: ${theme.boxShadow};

  a {
    color: ${theme.colors.primary};
    text-decoration: none;
  }

  ${mq.largeDown} {
    display: flex;
    justify-content: flex-end;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    padding-top: 35px;
    height: calc(35px + 45px);
  }
`;

export const IsHiddenContainer = styled.div`
  ${flexCenter}
  align-items: center;
  width: 100%;
  height: ${theme.vars.headerHeight};
`;

export const SignOutButton = styled.div<LinkProps>`
  position: relative;
  ${flexVertical}
  padding: 0px 30px;
  height: ${theme.vars.headerHeight};
  cursor: pointer;
  font-size: ${(props) =>
    props.isHiddenRoute ? '14px !important' : '16px !important'};;
  color: ${theme.colors.primary};
  border: ${theme.borders(1, 'lightBlueGrey')};
  border-right: none;
  color: ${(props) =>
    props.isHiddenRoute
      ? `${theme.colors.charcoal} !important`
      : `${theme.colors.primary} !important`};
  span {
    margin-left: 10px;
  }

  ${mq.largeDown} {
    border: none;
  }
`;

export const Icon = styled.div`
  width: 25px;
`;
