import styled from '@emotion/styled';
import {
  theme,
  flexCenterAll,
  InputContainer,
  InputLabel,
  inputDefaults,
} from 'style';

export const Wrapper = styled.div`
  & {
    font-family: ${theme.typography.fontFamily};

    .react-datepicker-popper {
      z-index: 11;
    }

    .react-datepicker {
      border: ${theme.borders(1, 'lightBlueGrey')};
      &__triangle {
        position: relative;
        /* display: none; */
        &::before {
          display: none;
        }
        &::after {
          content: '';
          height: 15px;
          width: 15px;
          border-top: ${theme.borders(1, 'lightBlueGrey')};
          border-left: ${theme.borders(1, 'lightBlueGrey')};
          background: white;
          position: absolute;
          top: 50%;
          left: -50%;
          z-index: 2;
          transform: rotate(45deg);
        }
      }
      &-wrapper {
        width: 100%;
      }
      .react-datepicker__navigation {
        top: 20px;
      }
    }
    .react-datepicker__input-container {
      width: 100%;

      > input {
        ${inputDefaults};
        width: 100%;
        border-radius: 4px;
        box-sizing: border-box;
        margin-bottom: 0;
        line-height: normal;
        font-size: 14px;

        background-color: ${theme.colors.white} !important;
        border: ${theme.borders(1, 'lightBlueGrey')};
      }
    }
    .react-datepicker__header,
    .react-datepicker-year-header {
      border-bottom: none;
      padding: 20px 25px 13px 25px;
      background-color: white;
      color: ${theme.colors.primary};
    }
  }
  .react-datepicker__monthPicker {
    margin: 0px 25px 20px 25px;
    border: ${theme.borders(1, 'lightBlueGrey')};
  }
  .react-datepicker__month-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    > .react-datepicker__month-text {
      margin: 0px;
      font-size: 14px;
      line-height: 20px;
      padding: 9px 33px;
      color: ${theme.colors.charcoal};

      &--keyboard-selected {
        color: white;
        border-radius: 0px;
      }

      ${flexCenterAll};
      &:hover {
        border-radius: 0px;
      }

      &--keyboard-selected {
        border-radius: 0px;
      }
    }

    > .react-datepicker__month {
      border-radius: 0px;

      &--in-range {
        color: white;
        border-radius: 0px;
        background: ${theme.colors.greyBlue};
      }

      &--range-start {
        background-color: ${theme.colors.primary};
      }
      &--range-end {
        background-color: ${theme.colors.primary};
      }

      &--disabled {
        background-color: #e9e9ea;
        color: ${theme.colors.grey};
      }
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  > input {
    ${inputDefaults};
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 0;
    line-height: normal;
    font-size: 14px;
    background-color: ${theme.colors.white} !important;
    border: ${theme.borders(1, 'lightBlueGrey')};
    box-sizing: border-box;
    cursor: pointer;
    ::placeholder {
      color: ${theme.colors.blueGrey};
    }
  }
  > svg {
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
`;
