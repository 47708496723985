import styled from '@emotion/styled';
import { theme, mq } from 'style';

export const StyledWrapper = styled.div`
  position: relative;
`;

export const Popout = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: 260px;
  height: auto;
  padding: 25px;
  background-color: white;
  border-radius: 4px;
  z-index: 98;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 97;
`;

export const Dismiss = styled.span`
  color: ${theme.colors.primary};
  text-decoration: underline;
  cursor: pointer;
`;
