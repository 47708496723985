import styled from '@emotion/styled';

export const GeneralHeading = styled.text`
  color: #003594;
  font-size: 12px;
  background-color: #ebf2ff;
  border-radius: 8px;
  padding: 4px 6px;
  align-items: center;
  font-weight: normal;
  margin-left: 5px;
`;

export const CriticalHeading = styled.text`
  color: #e3002a !important;
  font-size: 12px;
  background-color: #ffe9ed;
  border-radius: 8px;
  padding: 4px 6px;
  align-items: center;
  font-weight: normal;
  margin-left: 5px;
`;
