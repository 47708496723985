import React from "react"


const AlertCircle = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.99967 13.4163C3.44134 13.4163 0.583008 10.558 0.583008 6.99967C0.583008 3.44134 3.44134 0.583008 6.99967 0.583008C10.558 0.583008 13.4163 3.44134 13.4163 6.99967C13.4163 10.558 10.558 13.4163 6.99967 13.4163ZM6.99967 1.74967C4.08301 1.74967 1.74967 4.08301 1.74967 6.99967C1.74967 9.91634 4.08301 12.2497 6.99967 12.2497C9.91634 12.2497 12.2497 9.91634 12.2497 6.99967C12.2497 4.08301 9.91634 1.74967 6.99967 1.74967Z" fill="#E4002B" />
      <path d="M6.99967 7.58301C6.64967 7.58301 6.41634 7.34967 6.41634 6.99967V4.66634C6.41634 4.31634 6.64967 4.08301 6.99967 4.08301C7.34967 4.08301 7.58301 4.31634 7.58301 4.66634V6.99967C7.58301 7.34967 7.34967 7.58301 6.99967 7.58301Z" fill="#E4002B" />
      <path d="M7.58301 9.33301C7.58301 9.65517 7.32184 9.91634 6.99967 9.91634C6.67751 9.91634 6.41634 9.65517 6.41634 9.33301C6.41634 9.01084 6.67751 8.74967 6.99967 8.74967C7.32184 8.74967 7.58301 9.01084 7.58301 9.33301Z" fill="#E4002B" />
    </svg>
  )
}

export default AlertCircle
