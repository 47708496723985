import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledSection,
  StyledHeader,
  StyledWrapper,
  StyledInActive,
  StyledSubmit,
  StyledBody,
  SectionLayoutProps,
  StyledSectionFullWidthContainer,
  StyledMobileLastSection,
} from './formComponentsStyle';
import { InputError } from 'style';
import { useScreenSize } from 'hooks';
import {
  Button,
  Paragraph as P,
  Heading as H,
  Flex,
  Skeleton,
  Grid,
  ToolTip,
} from 'components';
import { Spacer } from 'style';
import FloatingActionBar from 'components/FloatingActionBar/FloatingActionBar';
import { ParagraphSizes } from 'components/Paragraph/Paragraph';
import { OnboardingStep } from 'pages/UserOnboarding/UserOnboarding';

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return <StyledWrapper>{children}</StyledWrapper>;
};

export type SectionProps = {
  heading?: string | null;
  headingSize?: ParagraphSizes;
  layout: SectionLayoutProps;
  children: React.ReactNode;
  paddingSize?: 'small' | 'large' | 'none';
};

const Section = ({
  heading,
  headingSize = 'normal',
  children,
  layout,
  paddingSize = 'large',
}: SectionProps) => (
  <StyledSection layout={layout} paddingSize={paddingSize}>
    {heading && (
      <P color="primary" size={headingSize} bold mb="20px">
        {heading}
      </P>
    )}
    <div>{children}</div>
  </StyledSection>
);

const MobileLastSection = ({
  heading,
  children,
  layout,
  paddingSize = 'large',
}: SectionProps) => (
  <StyledMobileLastSection layout={layout} paddingSize={paddingSize}>
    {heading && (
      <P color="primary" size="normal" bold mb="20px">
        {heading}
      </P>
    )}
    <div>{children}</div>
  </StyledMobileLastSection>
);

const SectionHeading = ({
  heading,
  shouldHaveMarginTop,
}: {
  heading: string;
  shouldHaveMarginTop?: boolean;
}) => (
  <P
    color="primary"
    size="normal"
    bold
    mb="20px"
    mt={shouldHaveMarginTop ? '20px' : 'inherit'}>
    {heading}
  </P>
);

const SectionFullWidthContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <StyledSectionFullWidthContainer>{children}</StyledSectionFullWidthContainer>
);

const Header = ({
  heading,
  headingSize = 'h6',
  preHeading,
  onClick,
  paddingSize = 'large',
  activeStep,
}: {
  heading: string | null;
  headingSize?: 'h3' | 'h6';
  preHeading?: string | null;
  onClick?: () => void;
  paddingSize?: 'small' | 'large';
  activeStep?: OnboardingStep;
}) => {
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  return (
    <StyledHeader paddingSize={paddingSize}>
      {(preHeading || heading) && (
        <div>
          {preHeading && (
            <P bold color="charcoal">
              {preHeading}
            </P>
          )}
          {heading && (
            <H size={headingSize} color="primary">
              {heading}
            </H>
          )}
        </div>
      )}
      {onClick && (
        <Button
          data-testid="edit-btn"
          icon="pencil"
          variant="outlined"
          iconSize={18}
          onClick={onClick}
          mb="-6px"
          mt="-7px">
          {!isMobile && t('common:buttons.edit')}
        </Button>
      )}
    </StyledHeader>
  );
};

const Body = ({
  children,
  alignment,
  paddingSize = 'large',
}: {
  children: React.ReactNode;
  alignment: 'horizontal' | 'vertical';
  paddingSize?: 'small' | 'large';
}) => {
  return (
    <StyledBody alignment={alignment} paddingSize={paddingSize}>
      {children}
    </StyledBody>
  );
};

const Submit = ({
  cancelAction,
  label,
  cancelLabel,
  showRequiredText,
  errorText,
  paddingSize = 'large',
  isDisabled = false,
  ...props
}: {
  label: string;
  cancelLabel?: string;
  paddingSize?: 'small' | 'large';
  isDisabled?: boolean;
  cancelAction?: () => void;
  showRequiredText?: boolean;
  [x: string]: any;
}) => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const { t } = useTranslation();
  const primaryButton = (
    <Button
      type={'submit'}
      disabled={!props.isValid || props.isSubmitting || isDisabled}
      isLoading={props.isSubmitting}
      cypress="form-wrapper-submit">
      {label}
    </Button>
  );
  const cancelButton = (
    <>
      <Button variant="outlined" type="button" onClick={cancelAction}>
        {cancelLabel ? cancelLabel : t('common:buttons.cancel')}
      </Button>
    </>
  );

  if (isMobile) {
    return (
      <FloatingActionBar
        primaryAction={primaryButton}
        secondaryAction={cancelButton}
      />
    );
  }

  return (
    <StyledSubmit paddingSize={paddingSize}>
      <div>
        {showRequiredText && (
          <P size="small" color="charcoal">
            {t('forms:required.fields')}
          </P>
        )}
      </div>
      <Grid gridTemplateColumns="repeat(2,200px)" gridColumnGap="10px">
        {cancelAction ? cancelButton : <div></div>}
        {primaryButton}
      </Grid>
      {errorText && (
        <InputError>
          <P size="small" color="secondary">
            {errorText}
          </P>
        </InputError>
      )}
    </StyledSubmit>
  );
};

const InActive = ({
  title,
  content,
  columns,
  loading,
  border = true,
  tooltip,
  tooltipIcon,
  contentVerticalLayout = 'flex',
}: {
  title: string | React.ReactNode;
  content?: any;
  columns?: number;
  loading?: boolean;
  border?: boolean;
  tooltip?: string;
  tooltipIcon?: 'question' | 'info' | 'offline';
  contentVerticalLayout?: 'block' | 'flex';
}) => {
  const { t } = useTranslation();

  return (
    <StyledInActive
      columns={columns}
      border={border}
      contentVerticalLayout={contentVerticalLayout}>
      <Flex>
        {typeof title === 'string' ? (
          <P size="small" color="blueGrey" mb="4px">
            <span>{title.toUpperCase()}</span>
          </P>
        ) : (
          title
        )}
        {tooltip && (
          <>
            <Spacer width={5} />
            <P mt="3px">
              <ToolTip
                content={tooltip}
                iconSize={14}
                iconType={tooltipIcon || 'info'}
              />
            </P>
          </>
        )}
      </Flex>

      {loading ? (
        <Skeleton height="24px" width="5ch" />
      ) : (
        <P size="normal" color={content ? 'charcoal' : 'blueGrey'}>
          {content || t('forms:not_set')}
        </P>
      )}
    </StyledInActive>
  );
};
const FormComponents = {
  Wrapper,
  Submit,
  Section,
  SectionFullWidthContainer,
  MobileLastSection,
  SectionHeading,
  Header,
  Body,
  InActive,
};

export default FormComponents;
