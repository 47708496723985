import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18n from 'core/i18n/i18n';

import { TextField, FormComponents } from 'components';
import { useScreenSize } from 'hooks';

const { screenSize } = useScreenSize();

export const Validation = Yup.object({
  name: Yup.string()
    .ensure()
    .required(i18n.t('forms:validation.required')),
  email: Yup.string()
    .ensure()
    .email(i18n.t('forms:validation.email')),
  address: Yup.object({
    street: Yup.string()
      .ensure()
      .required(i18n.t('forms:validation.required')),
    city: Yup.string()
      .ensure()
      .required(i18n.t('forms:validation.required')),
    state: Yup.string().ensure(),
    postalCode: Yup.string()
      .ensure()
      .required(i18n.t('forms:validation.required')),
    country: Yup.string()
      .ensure()
      .required(i18n.t('forms:validation.required')),
  }),
});

export type AccountSettingsSchema = Yup.InferType<typeof Validation>;

// Will be populated by an API GET to the user details
export const initialAccountDetails: AccountSettingsSchema = {
  name: '',
  email: '',
  address: {
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  },
};

export const Form = () => {
  const { t } = useTranslation();
  const { isMobile } = screenSize();
  return (
    <>
      <FormComponents.Section layout={isMobile ? 'single' : 'double'}>
        <TextField
          name="name"
          label={t('forms:account_settings.name')}
          required
        />
        <TextField
          name="email"
          label={t('forms:account_settings.email')}
          corporateEmailOnly
        />
      </FormComponents.Section>

      <FormComponents.Section
        layout={isMobile ? 'single' : 'double'}
        heading={t('forms:account_settings.address')}>
        <TextField
          name="address.street"
          label={t('forms:account_settings.address_street')}
          required
        />
        <TextField
          name="address.city"
          label={t('forms:account_settings.address_city')}
          required
        />
        <TextField
          name="address.state"
          label={t('forms:account_settings.address_state')}
        />
        <TextField
          name="address.postalCode"
          label={t('forms:account_settings.address_postalcode')}
          required
        />
        <TextField
          name="address.country"
          label={t('forms:account_settings.address_country')}
          required
        />
      </FormComponents.Section>
    </>
  );
};
