import styled from '@emotion/styled';
import { compose, layout, flexbox } from 'styled-system';
import { theme, mq } from 'style';

export const DESKTOP_HORIZONTAL_PADDING = 45;
export const DESKTOP_PADDING = `padding: 30px ${DESKTOP_HORIZONTAL_PADDING}px;`;
export const MOBILE_PADDING = 'padding: 20px 25px;';

interface StyledModalProps {
  minWidth?: number;
  maxWidth?: number;
}

export const StyledModal = styled.div<StyledModalProps>`
  box-sizing: border-box;
  ${(props) =>
    props.minWidth ? `min-width: ${props.minWidth}px;` : 'min-width: 500px;'}
  ${(props) =>
    props.maxWidth
      ? `max-width: ${props.maxWidth}px;`
      : 'max-width: 605px;'}
  background-color: white;
  box-shadow: ${theme.boxShadow};
  border-radius: 4px;

  ${mq.mobile()} {
    min-width: 100%;
    margin: 0;
    overflow-y: scroll;
  }
`;

export const StyledHeader = styled.div`
  ${DESKTOP_PADDING};
  ${mq.mobile()} {
    ${MOBILE_PADDING};

    > p {
      margin-right: 25px;
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${theme.borders(1, 'lightBlueGrey')};
`;

export const StyledActions = styled.div`
  ${DESKTOP_PADDING};
  ${mq.mobile()} {
    ${MOBILE_PADDING};
  }
  height: auto;
  border-top: ${theme.borders(1, 'lightBlueGrey')};
`;

interface StyleBodyProps {
  shouldPadContent: boolean;
}

export const StyledBody = styled.div<StyleBodyProps>`
  max-height: 90vh;
  overflow-y: auto;
  ${(props) => props.shouldPadContent && DESKTOP_PADDING};

  ${mq.mobile()} {
    ${(props) => props.shouldPadContent && MOBILE_PADDING};

    > p {
      margin-right: 25px;
    }
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 97;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: ${(props) => (props.stickToTop ? '5%' : '50%')};
  left: 50%;
  transform: ${(props) =>
    props.stickToTop
      ? 'translate(-50.1%, -0.1%)'
      : 'translate(-50.1%, -50.1%)'};
  z-index: 98;
  width: inherit;
  outline: 0;

  ${compose(layout, flexbox)}

  ${mq.mobile()} {
    width: 90%;
    margin: 0;

    overflow-y: scroll;
  }
`;
