import React from 'react';
import { useHistory } from 'react-router-dom';

import { Flex, Paragraph as P, IcomoonIcons } from 'components';
import { useScreenSize } from 'hooks';
import { Spacer } from 'style';

import { Icon, Button } from './markerTooltipStyle';
import greenMarker from '../green-marker.svg';
import redMarker from '../red-marker.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  id: string;
  name: string;
  alertCount: number;
}

const MarkerTooltip = ({ id, name, alertCount }: Props) => {
  const history = useHistory();
  const { isMobile } = useScreenSize().screenSize();
  const { t } = useTranslation();

  const handleClick = () => {
    history.push(`/doors/${id}`);
  };

  return (
    <Flex alignItems="center">
      <Icon src={alertCount === 0 ? greenMarker : redMarker} />
      <Spacer width={5} />
      <P
        color="primary"
        fontWeight="bold"
        paddingRight="20px"
        style={{ fontSize: '14px', flex: 1 }}>
        {name}
      </P>
      <Button onClick={handleClick}>
        {isMobile ? (
          <IcomoonIcons icon="chevron-right" />
        ) : (
          <P
            color="charcoal"
            style={{
              textDecoration: 'underline',
              fontSize: '12px',
            }}>
              {t('doorList:map:view_asset')}
          </P>
        )}
      </Button>
    </Flex>
  );
};

export default MarkerTooltip;
