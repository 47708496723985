import React from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutProps } from 'styled-system';

import {
  OuterContainer,
  InnerContainer,
  BackgroundImage,
  Headings,
} from './pageHeaderStyle';

import { Heading as H, Flex, Truncate, Skeleton, Grid } from 'components';
import { Spacer } from 'style';
import { useScreenSize } from 'hooks';
import FloatingActionBar from 'components/FloatingActionBar/FloatingActionBar';

interface Props extends LayoutProps {
  heading?: string | React.ReactNode;
  subComponent?: React.ReactNode;
  backgroundImage?: string;
  actionComponents?: React.ReactNode;
  isHeadingLoading?: boolean;
  /** If true, then on mobile, floating action bar actions mimic what's in the header. If false, there are no FAB actions. You can also pass in custom FAB Actions */
  fabActions?: boolean | React.ReactNode;
}

const PageHeader = ({
  heading,
  subComponent,
  backgroundImage,
  actionComponents,
  fabActions,
  isHeadingLoading,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  return (
    <>
      <OuterContainer
        backgroundColor
        {...rest}
        data-cypress="content-header-outer-container">
        {backgroundImage && (
          <BackgroundImage headerBackgroundImage={backgroundImage} />
        )}
        <InnerContainer data-cypress="content-header-inner-container">
          <Headings>
            {isHeadingLoading ? (
              <Grid gridRowGap="5px">
                <Skeleton height="50px" width="25ch" style={{ opacity: 0.5 }} />
                <Skeleton height="24px" width="15ch" style={{ opacity: 0.5 }} />
              </Grid>
            ) : (
              <>
                <Truncate>
                  <H
                    size="h2"
                    color="white"
                    data-testid="page-heading"
                    lineHeight={['35px', '50px']}>
                    {heading}
                  </H>
                </Truncate>
                {subComponent && (
                  <>
                    <Spacer height={5} />
                    {subComponent}
                  </>
                )}
              </>
            )}
          </Headings>
          {!isMobile && (
            <Flex
              flexDirection="column"
              alignItems="flex-end"
              justifyContent="center">
              {actionComponents && <>{actionComponents}</>}
            </Flex>
          )}
        </InnerContainer>
      </OuterContainer>
      {isMobile && fabActions && (
        <FloatingActionBar>
          {typeof fabActions === 'boolean' ? actionComponents : fabActions}
        </FloatingActionBar>
      )}
    </>
  );
};

export default PageHeader;
