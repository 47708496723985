import React, { forwardRef, useRef } from 'react';
import Select, { OptionsType, OptionTypeBase, components } from 'react-select';

import { selectStyles } from './dropdownSelectStyle';

import { IcomoonIcons } from 'components';
import { InputActionLabel, InputLabelWithAction } from 'style';

export interface DropdownSelectProps {
  value?: any;
  label?: string;
  hideLabel?: boolean;
  options: OptionsType<OptionTypeBase>;
  name: string;
  onChange: (e: any, action?: any) => void;
  disabled?: boolean;
  resetComponent?: () => void;
  clear?: () => void;
  actionLinkLabel?: string;
  onActionLink?: (name: string) => void;
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <IcomoonIcons icon="chevron-down" color="blueGrey" />
    </components.DropdownIndicator>
  );
};

const SelectField = forwardRef(
  ({
    label,
    hideLabel,
    options,
    onChange,
    value,
    name,
    disabled,
    actionLinkLabel,
    onActionLink,
  }: DropdownSelectProps) => {
    const selectInputRef = useRef<any | null>(null);

    return (
      <div data-override="input-container">
        {label && !hideLabel && (
          <InputLabelWithAction>
            <span>{label}</span>
            <InputActionLabel
              href="javascript:void(0)"
              onClick={() => onActionLink && onActionLink(name)}>
              {actionLinkLabel}
            </InputActionLabel>
          </InputLabelWithAction>
        )}
        <Select
          maxMenuHeight={260}
          ref={selectInputRef}
          name={name}
          isDisabled={disabled}
          components={{ DropdownIndicator }}
          value={
            options.find((option: OptionTypeBase) => option.value === value) ||
            value
          }
          onChange={(e: any, action?: any) => onChange(e, action)}
          options={options}
          styles={selectStyles}
          isSearchable={false}
          defaultValue={options[0]}
          menuPortalTarget={document.body}
        />
      </div>
    );
  },
);

export default SelectField;
