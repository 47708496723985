import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, FormComponents, ToolTip } from 'components';
import { isDualProducts } from 'utils';

export const Form = () => {
  const { t } = useTranslation();

  return (
    <FormComponents.Section
      layout="single"
      heading={t('profile:communication_preferences.reportsHeading')}>
      <Checkbox
        label={t('profile:communication_preferences.reportTypes')}
        labeltooltip={t('profile:communication_preferences.report_tooltip')}
        name="diReportPreferences"
        checkboxItems={[
          {
            label: t('profile:communication_preferences.reports.yearly'),
            value: 'Yearly',
          },
          {
            label: t('profile:communication_preferences.reports.monthly'),
            value: 'Monthly',
          },
        ]}
      />

      {isDualProducts() && <hr />}
    </FormComponents.Section>
  );
};
