//use for tapping into the mobile top bar
import create from 'zustand';
import upperFirst from 'lodash/upperFirst';

interface UseTopBarI {
  title: string;
  setTitle: (_: string) => void;
  rightAction: null | React.ReactNode;
  setRightAction: (_: React.ReactNode) => void;
  leftAction: null | React.ReactNode;
  setLeftAction: (_: React.ReactNode) => void;
}

const [useTopBarStore] = create<UseTopBarI>((set) => ({
  title: upperFirst(window.location.pathname.split('/')[1]),
  setTitle: (title: string) => set(() => ({ title: title })),
  rightAction: null,
  setRightAction: (action: React.ReactNode) =>
    set(() => ({ rightAction: action })),
  leftAction: null,
  setLeftAction: (action: React.ReactNode) =>
    set(() => ({ leftAction: action })),
}));

export default useTopBarStore;
