import React from 'react';

import {
  SearchBarWrapper,
  IconWrapper,
  SearchBarInput,
  CloseWrapper,
} from './searchBarStyle';

import { DropdownSelect, IcomoonIcons } from 'components';
import { LabelValue } from 'models';

export interface SearchBarProps {
  searchValue: string;
  searchHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  clearSearch: () => void;
  style?: any;
  name?: string;
  options?: LabelValue[];
}

const SearchBar = ({
  searchValue,
  searchHandler,
  placeholder,
  clearSearch,
  style,
  name = 'search-bar',
  options,
}: SearchBarProps) => {
  return (
    <SearchBarWrapper>
      <IconWrapper>
        <IcomoonIcons icon="search" color="blueGrey" />
      </IconWrapper>
      { options && options.length > 0 ? (
        <DropdownSelect
          value={searchValue}
          name={name}
          onChange={searchHandler}
          options={options}
        />
      ) : (
        <React.Fragment>
          <SearchBarInput
            data-testid="search-bar-input"
            placeholder={placeholder}
            value={searchValue}
            onChange={searchHandler}
          />
          {searchValue && (
            <CloseWrapper onClick={clearSearch} data-testid="clear-search-btn">
              <IcomoonIcons icon="close" color="blueGrey" size={18} />
            </CloseWrapper>
          )}
        </React.Fragment>
      )}
    </SearchBarWrapper>
  );
};

export default SearchBar;
