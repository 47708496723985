import create from 'zustand';

import { fetchConnectedDoorHealthAlerts, fetchDoorsStats } from 'services';
import {
  ConnectedDoorHealthAlerts,
  ConnectedDoorHealthConnectivity,
} from 'models';

interface ConnectedDoorHealthStore {
  connectedDoorHealthAlerts: ConnectedDoorHealthAlerts[];
  connectedDoorHealthConnectivity: ConnectedDoorHealthConnectivity[];
  isLoading: boolean;
  connectedDoorHealthAlertsFetch: (props: {
    update?: boolean;
    followedOnly?: boolean;
    accountId?: string;
  }) => void;
  connectedDoorHealthConnectivityFetch: (props: {
    update?: boolean;
    group?: boolean;
    followedOnly?: boolean;
    accountId?: string;
  }) => void;
}

const [useConnectedDoorHealthStore] = create<ConnectedDoorHealthStore>(
  (set) => ({
    connectedDoorHealthAlerts: [] as ConnectedDoorHealthAlerts[],
    connectedDoorHealthConnectivity: [] as ConnectedDoorHealthConnectivity[],
    isLoading: true,
    connectedDoorHealthAlertsFetch: async ({
      update = false,
      followedOnly = false,
      accountId = '',
    }) => {
      try {
        if (!update) {
          set({ isLoading: true });
        }
        const res = await fetchConnectedDoorHealthAlerts(
          followedOnly,
          accountId,
        );
        set({
          connectedDoorHealthAlerts: (await res) as ConnectedDoorHealthAlerts[],
          isLoading: false,
        });
      } catch (err) {
        set({
          connectedDoorHealthAlerts: {} as ConnectedDoorHealthAlerts[],
          isLoading: false,
        });
        throw err;
      }
    },
    connectedDoorHealthConnectivityFetch: async ({
      update = false,
      group = false,
      followedOnly = false,
      accountId = '',
    }) => {
      try {
        if (!update) {
          set({ isLoading: true });
        }
        const res = await fetchDoorsStats(followedOnly, accountId, group);
        set({
          connectedDoorHealthConnectivity: (await res) as ConnectedDoorHealthConnectivity[],
          isLoading: false,
        });
      } catch (err) {
        set({
          connectedDoorHealthConnectivity: {} as ConnectedDoorHealthConnectivity[],
          isLoading: false,
        });
        throw err;
      }
    },
  }),
);

export default useConnectedDoorHealthStore;
