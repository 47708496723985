import React from 'react';

import { Wrapper, Form, Heading, Subheading } from './smallFormContainerStyle';
import { Heading as H } from 'components';

interface Props {
  mainHeading: string;
  subHeading?: string;
}

const SmallFormContainer: React.FC<Props> = ({
  children,
  mainHeading,
  subHeading,
}) => {
  return (
    <Wrapper data-cypress="small-form-container">
      <Heading>
        <H size="h3" center color="primary">
          {mainHeading}
        </H>
        {subHeading && <Subheading color="charcoal">{subHeading}</Subheading>}
      </Heading>

      <Form>{children}</Form>
    </Wrapper>
  );
};

export default SmallFormContainer;
