import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph as P, Heading as H, IcomoonIcons } from 'components';
import { Container, TextContainer } from './noNotificationStyle';
import { NotificationType } from 'models';

const NoNotification = ({
  type = NotificationType.Alert,
  followedOnly,
}: {
  type: NotificationType;
  followedOnly: boolean;
}) => {
  const { t } = useTranslation();
  const BASED_ON_FOLLOW = {
    alerts: followedOnly
      ? t('operationalOverview:alerts_dashboard_follow')
      : t('operationalOverview:alerts_dashboard'),
    mode_utilisation: followedOnly
      ? t('operationalOverview:mode_change_dashboard_follow')
      : t('operationalOverview:mode_change_dashboard'),
  };
  return (
    <Container>
      <div>
        <TextContainer>
          <IcomoonIcons icon="success" size={92} color="success" />
        </TextContainer>
        <TextContainer>
          <H size="h6" color="primary" mt={16}>
            {type === NotificationType.Alert
              ? t('operationalOverview:no_alert')
              : t('operationalOverview:no_mode_changes')}
          </H>
        </TextContainer>
        <TextContainer>
          <P size="normal" color="charcoal" textAlign="center">
            {type === NotificationType.Alert
              ? BASED_ON_FOLLOW.alerts
              : BASED_ON_FOLLOW.mode_utilisation}
          </P>
        </TextContainer>
      </div>
    </Container>
  );
};

export default NoNotification;
