import React from 'react';
import Select, { OptionsType, OptionTypeBase, components } from 'react-select';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { selectStyles } from './selectFieldStyle';

import { InputLabel, InputError, InputContainer, Spacer } from 'style';
import { Paragraph as P, IcomoonIcons, ToolTip, Flex } from 'components';

interface Props {
  /** The name of the input for Formik */
  name: string;
  /** Static label to display */
  label?: string;
  /** Options */
  options: OptionsType<OptionTypeBase>;
  /** Localized label for the text field. */
  /** MUST REFERENCE ITEM IN RESOURCE OBJECT. */
  i18nLabel?: string;
  /** Toggle the input on or off */
  disabled?: boolean;
  /** Cypres testing attribute */
  cypress?: string;
  /** if the field requires a tooltip, what to display */
  tooltip?: string;

  required?: boolean;
}

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <IcomoonIcons icon="chevron-down" color="blueGrey" />
    </components.DropdownIndicator>
  );
};

const SelectField: React.FC<Props> = ({
  label,
  i18nLabel,
  tooltip,
  required,
  options,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const { t } = useTranslation();

  return (
    <InputContainer
      data-cypress={props.cypress}
      data-override="input-container">
      {label && (
        <Flex>
          <InputLabel htmlFor={field.name}>{`${i18nLabel ? t(i18nLabel) : label}${
            required ? '*' : ''
          }`}</InputLabel>
          {tooltip && (
            <>
              <Spacer width={5} />
              <ToolTip content={tooltip} iconSize={14} />
            </>
          )}
        </Flex>
      )}
      <Select
        isDisabled={props.disabled}
        name={field.name}
        id={field.name}
        components={{ DropdownIndicator }}
        value={
          options
            ? options.find(
                (option: OptionTypeBase) => option.value === field.value,
              )
            : ''
        }
        onChange={(option: OptionTypeBase): void =>
          helpers.setValue(option.value)
        }
        onBlur={field.onBlur}
        options={options}
        placeholder={t('common:select')}
        styles={selectStyles}
      />
      {meta.touched && meta.error ? (
        <InputError>
          <P
            size="small"
            data-cypress={`${props.cypress}-error`}
            data-testid="text-field-error">
            {meta.error}
          </P>
        </InputError>
      ) : (
        <Spacer height={20} />
      )}
    </InputContainer>
  );
};

export default SelectField;
