import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Formik, Form } from 'formik';

import ChangePasswordModal from './ChangePasswordModal/ChangePasswordModal';

import {
  FormComponents,
  Heading as H,
  Paragraph as P,
  Button,
  Container,
  PageHeader,
  Spinner,
  FloatingActionBar,
} from 'components';
import {
  AccountDetails,
  CommunicationPreferences,
  ReportPreferences,
} from './forms';
import {
  UserUpdate,
  LabelValue,
  IdValue,
  User,
  NotificationType,
  LabelValueWithIsCriticalField,
  NotifcationTypeFilter,
} from 'models';
import {
  updateUser,
  fetchNotificationScopes,
  fetchUserMeDetails,
  fetchAvailableNotificationTypes,
} from 'services';
import { useScreenSize } from 'hooks';
import { useModalStore } from 'stores';
import {
  FilterTranslations,
  applyFilterTranslations,
  isDI,
  isDIS,
  isDualProducts,
  isOI,
  shouldShowModeElement,
  userMeStorage,
} from 'utils';
import { sortBy } from 'lodash';
import { getAlertTypeNames } from 'utils/getAlertTypeNames';
import { getNotificationTypeOptions } from 'utils/getNotificationTypeOptions';

const scopeTranslations: FilterTranslations = {
  Following: i18n.t('forms:communication_preferences.typesDI.following'),
  All: i18n.t('forms:communication_preferences.typesDI.all'),
  'N/A': i18n.t('forms:communication_preferences.frequencies.none'),
};

const languageSetting = (lang: string) => i18n.t('languages:' + lang);

const { screenSize } = useScreenSize();

const Profile = () => {
  const { t } = useTranslation();
  const { isMobile } = screenSize();

  const [userMe, setUserMe] = useState<User>({} as User);
  const [submitIsDisabledDI, setSubmitIsDisabledDI] = useState<boolean>(false);
  const [showMobileWarningDI, setShowMobileWarningDI] = useState<boolean>(
    false,
  );

  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const toggleEdit = () => {
    setIsEditable(!isEditable);
    window.scrollTo(0, 0);
  };

  const [notificationScopes, setNotificationScopes] = useState<LabelValue[]>();
  useEffect(() => {
    const fetchScopes = async () => {
      try {
        const notificationScopesRes = (await fetchNotificationScopes()) as IdValue[];
        const notificationScopesTranslated = await applyFilterTranslations(
          notificationScopesRes,
          scopeTranslations,
        );
        setNotificationScopes(sortBy(notificationScopesTranslated, ['label']));
      } catch (err) {
        console.log(err);
      }
    };
    fetchScopes();
  }, []);

  const [alertTypes, setAlertTypes] = useState<LabelValueWithIsCriticalField[]>(
    [],
  );

  const fetchAlertTypes = async () => {
    const alertTypesData = await fetchAvailableNotificationTypes();
    const alertTypeOptions = getNotificationTypeOptions(
      alertTypesData ?? [],
      NotificationType.Alert,
      true,
    );
    setAlertTypes(alertTypeOptions);
  };

  useEffect(() => {
    fetchAlertTypes();
  }, []);

  const disableSubmitLogicDI = (props: any) => {
    if (
      !props.values.notificationPreferences.what ||
      !props.values.notificationPreferences.mode
    ) {
      return setSubmitIsDisabledDI(true);
    }

    if (
      props.values.notificationPreferences.what !== 'N/A' ||
      props.values.notificationPreferences.mode !== 'N/A'
    ) {
      if (
        (!props.values.notificationPreferences.how.includes('sms') &&
          !props.values.notificationPreferences.how.includes('email')) ||
        (props.values.notificationPreferences.what !== 'N/A' &&
          (props.values.notificationPreferences.generalAlertTypes?.length ??
            0) +
            (props.values.notificationPreferences.criticalAlertTypes?.length ??
              0) ===
            0)
      ) {
        return setSubmitIsDisabledDI(true);
      }

      if (
        props.values.notificationPreferences.how.includes('sms') &&
        showMobileWarningDI
      ) {
        return setSubmitIsDisabledDI(true);
      }
    }
    setSubmitIsDisabledDI(false);
  };

  const noMobileWarningDI = (props: any) => {
    if (
      props.values.notificationPreferences.how.includes('sms') &&
      !props.values.mobile
    ) {
      setShowMobileWarningDI(true);
    } else {
      setShowMobileWarningDI(false);
    }
  };

  const onSubmit = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true);

    try {
      const userUpdatePayload: UserUpdate = {
        firstName: values.firstName,
        lastName: values.lastName,
        salutation: values.salutation,
        honorific: values.honorific,
        mobileNumber: values.mobile,
        phoneNumber: values.phone,
        language: values.language,
        modeNotificationScope: values.notificationPreferences?.mode,
        diNotificationScope: values.notificationPreferences?.what,
        alertExceeded: values.notificationPreferences?.when?.includes(
          'alertExceeded',
        ),
        alertApproaching: values.notificationPreferences?.when?.includes(
          'alertApproaching',
        ),
        diNotifyViaEmail: values.notificationPreferences?.how?.includes(
          'email',
        ),
        diNotifyViaSms: values.notificationPreferences?.how?.includes('sms'),
        diNotificationAlertTypes:
          values.notificationPreferences?.what === 'N/A'
            ? []
            : (values.notificationPreferences?.generalAlertTypes ?? []).concat(
                values.notificationPreferences?.criticalAlertTypes ?? [],
              ),
        subscribeDIMonthlyReport: values.diReportPreferences?.includes(
          'Monthly',
        ),
        subscribeDIYearlyReport: values.diReportPreferences?.includes('Yearly'),
      };
      const res = await updateUser(userUpdatePayload, alertTypes);

      if (res) {
        const update = await fetchUserMeDetails();
        if (update) {
          setUserMe(update);
          await userMeStorage.set(update);
          i18n.changeLanguage(update.language);
          window.location.reload();
        }
        toggleEdit();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const { changePasswordModal } = useModalStore();

  return (
    <>
      <ChangePasswordModal />

      {!isMobile ? (
        <PageHeader
          heading={t('profile:heading')}
          actionComponents={
            <Button
              inverse
              onClick={changePasswordModal.toggleModal}
              disabled={isEditable}>
              {t('profile:change_password_form.button')}
            </Button>
          }
        />
      ) : (
        <FloatingActionBar>
          <Button
            onClick={changePasswordModal.toggleModal}
            disabled={isEditable}
            stretch>
            {t('profile:change_password_form.button')}
          </Button>
        </FloatingActionBar>
      )}

      <Container mb={isMobile ? 120 : 30}>
        {userMe ? (
          <FormComponents.Wrapper>
            <FormComponents.Header
              heading={t('profile:form_header.inactive')}
              onClick={!isEditable ? toggleEdit : undefined}
            />
            {isEditable ? (
              <Formik
                initialValues={{
                  ...userMe,
                  notificationPreferences: {
                    what: userMe.diNotificationScope ?? 'All',
                    mode: !isOI()
                      ? userMe.modeNotificationScope ?? 'N/A'
                      : undefined,
                    how: [
                      userMe.diNotifyViaEmail ? 'email' : '',
                      userMe.diNotifyViaSms ? 'sms' : '',
                    ],
                    generalAlertTypes: userMe.generalAlertTypes ?? [],
                    criticalAlertTypes: userMe.criticalAlertTypes ?? [],
                  },
                  diReportPreferences: [
                    userMe.subscribeDIYearlyReport ? 'Yearly' : undefined,
                    userMe.subscribeDIMonthlyReport ? 'Monthly' : undefined,
                  ],
                }}
                validationSchema={AccountDetails.Validation}
                onSubmit={(values, { setSubmitting }) => {
                  onSubmit(values, setSubmitting);
                }}>
                {(props) => {
                  if (
                    props.values.notificationPreferences.what === 'N/A' &&
                    props.values.notificationPreferences.mode === 'N/A'
                  ) {
                    props.values.notificationPreferences.how = [];
                  }

                  disableSubmitLogicDI(props);
                  noMobileWarningDI(props);

                  return (
                    <Form data-testid="edit-profile-form">
                      <AccountDetails.Form values={props.values} />
                      <CommunicationPreferences.Form
                        notificationScopes={
                          notificationScopes
                            ? notificationScopes
                            : ([] as LabelValue[])
                        }
                        doesUserWantNotifications={
                          (props.values.notificationPreferences.what &&
                            props.values.notificationPreferences.what !==
                              'N/A') ||
                          (props.values.notificationPreferences.mode &&
                            props.values.notificationPreferences.mode !== 'N/A')
                            ? true
                            : false
                        }
                        doesUserWantAlerts={
                          props.values.notificationPreferences.what &&
                          props.values.notificationPreferences.what !== 'N/A'
                            ? true
                            : false
                        }
                        showMobileWarningDI={showMobileWarningDI}
                        showAlertTypesWarningDI={
                          (props.values.notificationPreferences
                            .generalAlertTypes?.length ?? 0) +
                            (props.values.notificationPreferences
                              .criticalAlertTypes?.length ?? 0) ===
                          0
                        }
                      />

                      {(isDI() || isDualProducts()) && (
                        <ReportPreferences.Form />
                      )}

                      {isMobile && (
                        <FormComponents.Section layout="single">
                          <P size="small" color="charcoal">
                            {t('forms:required.fields')}
                          </P>
                        </FormComponents.Section>
                      )}

                      <FormComponents.Submit
                        label={t('profile:buttons.save_changes')}
                        cancelAction={toggleEdit}
                        showRequiredText
                        isDisabled={submitIsDisabledDI}
                        {...props}
                      />
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <>
                <FormComponents.Section
                  heading={t('profile:personal_information.heading')}
                  layout={isMobile ? 'single' : 'inactive'}>
                  <FormComponents.InActive
                    title={t('forms:personal_details.salutation')}
                    content={userMe?.salutation}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.honorific')}
                    content={userMe?.honorific}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.first_name')}
                    content={userMe?.firstName}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.last_name')}
                    content={userMe?.lastName}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.mobile_no')}
                    content={userMe?.mobile}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.phone_no')}
                    content={userMe?.phone}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.email')}
                    content={userMe?.email}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.account')}
                    content={userMe?.account?.name}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.user_type')}
                    content={
                      userMe?.userRole == 'Viewer'
                        ? t('userDetails:roles.viewer')
                        : t('userDetails:roles.admin')
                    }
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.language')}
                    content={languageSetting(userMe?.language)}
                  />
                </FormComponents.Section>
                <FormComponents.Section
                  heading={t('profile:communication_preferences.heading')}
                  layout={notificationScopes ? 'inactive' : 'single'}>
                  {notificationScopes ? (
                    <>
                      <FormComponents.InActive
                        title={t('forms:communication_preferences.alerts')}
                        content={
                          notificationScopes
                            ? notificationScopes.find(
                                (scope: LabelValue) =>
                                  scope.value == userMe?.diNotificationScope,
                              )?.label
                            : ''
                        }
                        contentVerticalLayout="block"
                      />
                      <FormComponents.InActive
                        title={t(
                          'forms:communication_preferences.critical_alerts_selected',
                        )}
                        content={getAlertTypeNames(
                          userMe,
                          alertTypes,
                          NotifcationTypeFilter.CriticalOnly,
                        )}
                      />
                      <FormComponents.InActive
                        title={t(
                          'forms:communication_preferences.general_alerts_selected',
                        )}
                        content={getAlertTypeNames(
                          userMe,
                          alertTypes,
                          NotifcationTypeFilter.GeneralOnly,
                        )}
                      />
                      {shouldShowModeElement() ? (
                        <FormComponents.InActive
                          title={t(
                            'forms:communication_preferences.mode_changes',
                          )}
                          content={
                            notificationScopes
                              ? notificationScopes.find(
                                  (scope: LabelValue) =>
                                    scope.value ==
                                    userMe?.modeNotificationScope,
                                )?.label
                              : ''
                          }
                        />
                      ) : (
                        <></>
                      )}
                      <FormComponents.InActive
                        title={t('forms:communication_preferences.method')}
                        content={
                          (userMe?.diNotifyViaSms
                            ? t('forms:communication_preferences.methods.sms')
                            : '') +
                          (userMe?.diNotifyViaSms && userMe?.diNotifyViaEmail
                            ? ', '
                            : '') +
                          (userMe?.diNotifyViaEmail
                            ? t('forms:communication_preferences.methods.email')
                            : '')
                        }
                      />
                    </>
                  ) : (
                    <Spinner color="lightBlueGrey" />
                  )}
                </FormComponents.Section>
                <>
                  {' '}
                  {(isDI() || isDualProducts()) && (
                    <FormComponents.Section
                      heading={t(
                        'profile:communication_preferences.reportsHeading',
                      )}
                      layout={isMobile ? 'single' : 'inactive'}>
                      <FormComponents.InActive
                        title={t(
                          'profile:communication_preferences.reportsSelected',
                        )}
                        content={
                          (userMe?.subscribeDIYearlyReport
                            ? t(
                                'profile:communication_preferences.reports.yearly',
                              )
                            : '') +
                          (userMe?.subscribeDIYearlyReport &&
                          userMe?.subscribeDIMonthlyReport
                            ? ', '
                            : '') +
                          (userMe?.subscribeDIMonthlyReport
                            ? t(
                                'profile:communication_preferences.reports.monthly',
                              )
                            : '')
                        }
                      />
                    </FormComponents.Section>
                  )}
                </>
              </>
            )}
          </FormComponents.Wrapper>
        ) : (
          <H>Error: No user data</H>
        )}
      </Container>
    </>
  );
};

export default Profile;
