import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import {
  Paragraph as P,
  PageHeader,
  Container,
  FormComponents,
} from 'components';
import { useScreenSize } from 'hooks';
import { AccountSettings, SpaceSettings } from './forms';
import { fetchAccount, updateAccount, updateAccountSpace } from 'services';
import { Account, AccountUpdate, AccountSpaceUpdate, User } from 'models';
import { userMeStorage } from 'utils';

const Settings = () => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const { t } = useTranslation();

  const [isEditAccount, setIsEditAccount] = useState<boolean>(false);
  const [isEditSpace, setIsEditSpace] = useState<boolean>(false);
  const toggleEditAccount = () => {
    setIsEditAccount(!isEditAccount);
    window.scrollTo(0, 0);
  };
  const toggleEditSpace = () => {
    setIsEditSpace(!isEditSpace);
    window.scrollTo(0, 0);
  };

  const [userMe, setUserMe] = useState<User>({} as User);
  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  const [isAccountLoading, setIsAccountLoading] = useState<boolean>(true);
  const [accountData, setAccountData] = useState<Account>({} as Account);

  const fetchAccountData = async (userId: string) => {
    try {
      const res = await fetchAccount(userId);
      if (res) {
        setAccountData(res);
        userMeStorage.set({ ...userMe, account: res });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsAccountLoading(false);
    }
  };

  useEffect(() => {
    if (userMe?.account?.id) {
      fetchAccountData(userMe.account.id);
    }
  }, [userMe]);

  const onAccountSubmit = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true);

    try {
      const accountUpdatePayload: AccountUpdate = {
        name: values.name,
        email: values.email,
        street: values.address.street,
        city: values.address.city,
        state: values.address.state,
        postcode: values.address.postalCode,
        country: values.address.country,
      };
      const res = await updateAccount(
        userMe?.account?.id,
        accountUpdatePayload,
      );
      if (res === 200) {
        fetchAccountData(userMe.account.id).then(() => toggleEditAccount());
      } else {
        //TODO: error
        console.error(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSpaceSubmit = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true);
    try {
      const spaceUpdatePayload: AccountSpaceUpdate = {
        oiUnitOfMeasure: values.oiUnitOfMeasure,
        oiPerPersonSpaceAllowance: Number(values.oiPerPersonSpaceAllowance),
        defaultAccountTimezone: values.defaultAccountTimezone,
        oiEntranceDisplayMessage: values.oiEntranceDisplayMessage,
      };
      const res = await updateAccountSpace(
        userMe?.account?.id,
        spaceUpdatePayload,
      );

      if (res === 200) {
        fetchAccountData(userMe.account.id).then(() => toggleEditSpace());
      } else {
        //TODO: error
        console.error(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <PageHeader heading={t('settings:heading')} />
      {!isEditSpace && (
        <Container mt="30px" mb="30px">
          <FormComponents.Wrapper>
            <FormComponents.Header
              heading={
                !isEditAccount
                  ? t('settings:form_header.account_settings.inactive')
                  : t('settings:form_header.account_settings.active')
              }
              onClick={!isEditAccount ? toggleEditAccount : undefined}
              paddingSize="large"
            />
            {isEditAccount ? (
              <Formik
                initialValues={accountData}
                validationSchema={AccountSettings.Validation}
                onSubmit={(values, { setSubmitting }) => {
                  onAccountSubmit(values, setSubmitting);
                }}>
                {(props) => {
                  return (
                    <Form>
                      <AccountSettings.Form />

                      {isMobile && (
                        <FormComponents.Section layout="single">
                          <P size="small" color="charcoal">
                            {t('forms:required.fields')}
                          </P>
                        </FormComponents.Section>
                      )}

                      <FormComponents.Submit
                        label={t('settings:buttons.save_changes')}
                        cancelAction={toggleEditAccount}
                        showRequiredText
                        {...props}
                      />
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <>
                <FormComponents.Section
                  layout={isMobile ? 'single' : 'inactive'}>
                  <FormComponents.InActive
                    title={t('forms:account_settings.name')}
                    content={accountData?.name}
                    loading={isAccountLoading}
                  />
                  <FormComponents.InActive
                    title={t('forms:account_settings.email')}
                    content={accountData?.email}
                    loading={isAccountLoading}
                  />
                </FormComponents.Section>
                <FormComponents.Section
                  heading={t('forms:account_settings.address')}
                  layout={isMobile ? 'single' : 'inactive'}>
                  <FormComponents.InActive
                    title={t('forms:account_settings.address_street')}
                    content={accountData?.address?.street}
                    loading={isAccountLoading}
                  />
                  <FormComponents.InActive
                    title={t('forms:account_settings.address_city')}
                    content={accountData?.address?.city}
                    loading={isAccountLoading}
                  />
                  <FormComponents.InActive
                    title={t('forms:account_settings.address_state')}
                    content={accountData?.address?.state}
                    loading={isAccountLoading}
                  />
                  <FormComponents.InActive
                    title={t('forms:account_settings.address_postalcode')}
                    content={accountData?.address?.postalCode}
                    loading={isAccountLoading}
                  />
                  <FormComponents.InActive
                    title={t('forms:account_settings.address_country')}
                    content={accountData?.address?.country}
                    loading={isAccountLoading}
                  />
                </FormComponents.Section>
              </>
            )}
          </FormComponents.Wrapper>
        </Container>
      )}

      {!isEditAccount && (
        <Container mt="30px" mb="30px">
          <FormComponents.Wrapper>
            <FormComponents.Header
              heading={
                !isEditSpace
                  ? t('settings:form_header.space_settings.inactive')
                  : t('settings:form_header.space_settings.active')
              }
              onClick={!isEditSpace ? toggleEditSpace : undefined}
              paddingSize="large"
            />
            {isEditSpace ? (
              <Formik
                initialValues={accountData}
                validationSchema={SpaceSettings.Validation}
                onSubmit={(values, { setSubmitting }) => {
                  onSpaceSubmit(values, setSubmitting);
                }}>
                {(props) => {
                  return (
                    <Form>
                      <SpaceSettings.Form />

                      {isMobile && (
                        <FormComponents.Section layout="single">
                          <P size="small" color="charcoal">
                            {t('forms:required.fields')}
                          </P>
                        </FormComponents.Section>
                      )}

                      <FormComponents.Submit
                        label={t('settings:buttons.save_changes')}
                        cancelAction={toggleEditSpace}
                        showRequiredText
                        {...props}
                      />
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <FormComponents.Section layout={isMobile ? 'single' : 'inactive'}>
                <FormComponents.InActive
                  title={t('settings:space_settings.unit')}
                  content={accountData?.oiUnitOfMeasure}
                  loading={isAccountLoading}
                />
                <FormComponents.InActive
                  title={t('settings:space_settings.occupancy_rate')}
                  content={accountData?.oiPerPersonSpaceAllowance}
                  loading={isAccountLoading}
                />
                <FormComponents.InActive
                  title={t('settings:space_settings.time_zone.label')}
                  content={accountData?.defaultAccountTimezone}
                  loading={isAccountLoading}
                />
                <FormComponents.InActive
                  title={t('settings:space_settings.display_message')}
                  content={accountData?.oiEntranceDisplayMessage}
                  loading={isAccountLoading}
                />
              </FormComponents.Section>
            )}
          </FormComponents.Wrapper>
        </Container>
      )}
    </>
  );
};

export default Settings;
