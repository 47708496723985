import React from 'react';
import { useTranslation } from 'react-i18next';

import { WarningBox } from 'components';
import { isDualProducts } from 'utils';

const NoMobileWarning = ({ isProfile = false }: { isProfile?: boolean }) => {
  const { t } = useTranslation();

  return (
    <WarningBox style={{ marginTop: '-20px' }}>
      {isProfile && isDualProducts()
        ? t('userOnboarding:warning.no_mobile_profile_dual')
        : isProfile
        ? t('userOnboarding:warning.no_mobile_profile')
        : t('userOnboarding:warning.no_mobile_onboarding')}
    </WarningBox>
  );
};

export default NoMobileWarning;
