import styled from '@emotion/styled';
import { theme, flexVertical, flexCenterAll, mq, contentWidths } from 'style';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${theme.colors.white};
  width: 100%;
  height: auto;
  padding: 30px 0px;
  z-index: 2;
  ${flexVertical};
  ${mq.mobile()} {
    background-color: transparent;
  }
`;

export const Dropdown = styled.div`
  border-radius: 4px;
  border: 1px solid ${theme.colors.blueGrey};
`;

interface FilterProps {
  isExpanded: boolean;
}
export const Filters = styled.div<FilterProps>`
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: ${(props) =>
    props.isExpanded ? theme.colors.lightGreyBlue : theme.colors.white};
  ${flexCenterAll};
  border: 1px solid
    ${(props) =>
      props.isExpanded
        ? theme.colors.lightGreyBlue
        : theme.colors.lightBlueGrey};
  ${mq.mobile()} {
    border: none;
    padding: 16px;
    height: 50px;
    box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.05);
  }
`;

export const ClearListFiltersOI = styled.div`
  position: absolute;
  ${flexVertical};
  right: 0;
  top: -25px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  color: #003594;
  cursor: pointer;
`;

export const ClearListFiltersDI = styled.div`
  position: relative;
  /* ${flexVertical}; */
  /* right: 0;
  top: -25px; */
  margin-top: 82px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  color: #003594;
  cursor: pointer;
`;

//not sure what to do about these settings props
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0 30px;
  margin: 0 auto;
  width: 100%;
  ${mq.mobile()} {
    padding: 0;
    max-width: ${contentWidths.mobile};
  }
  ${mq.wideDesktop()} {
    max-width: ${contentWidths.wideDesktop};
  }
`;

export const IconButton = styled.div`
  cursor: pointer;
  margin: auto 10px;
`;
