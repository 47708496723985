import styled from '@emotion/styled';
import { flexCenter, theme } from 'style';

export const EllipsisDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledTooltip = styled.div`
  position: relative;
  overflow: hidden;
  ${flexCenter}
`;

type ContentPopupProps = {
  height: number;
  position: ClientRect;
};

export const ContentPopup = styled.div<ContentPopupProps>`
  position: absolute;
  left: ${({ position }) => position.left}px;
  top: ${({ position, height }) => position.top + window.scrollY - 80}px;
  transform: ${({ position }) =>
    `translateX(calc(-50% + ${position.width / 2 - 2}px))`};
  padding: 9px 18px;
  font-size: 14px;
  line-height: 21px;
  opacity: ${({ height }) => (height === 0 ? 0 : 1)};
  color: ${theme.colors.charcoal};
  z-index: 99;
  max-width: 250px;
  border: 1px solid ${theme.colors.grey};
  background-color: ${theme.colors.white};
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.05);
`;

export const Triangle = styled.div`
  height: 14px;
  width: 14px;
  left: 50%;
  transform: rotate(45deg) translateX(-50%);
  background: ${theme.colors.primary};
  position: absolute;
  bottom: -7px;
`;
