import create from 'zustand';
import { fetchAccountList } from 'services';
import { AccountList } from '../models';
import { OptionsType, OptionTypeBase } from 'react-select';
import i18next from 'i18next';

interface AccountStore {
  accountData: AccountList[];
  accountList: OptionsType<OptionTypeBase>;
  isLoading: boolean;
  accountListCount: number;
  selectedAccount: OptionTypeBase;
  fetchAccounts: () => Promise<any>;
  setSelectedAccount: (account: OptionTypeBase) => void;
}

export const defaultAccount = {
  value: '',
  label: i18next.t('dashboard:all_accounts'),
};

const [useAccountStore] = create<AccountStore>((set, get) => ({
  accountData: [] as AccountList[],
  accountList: [] as OptionsType<OptionTypeBase>,
  accountListCount: 0,
  isLoading: true,
  selectedAccount: defaultAccount,
  fetchAccounts: async () => {
    try {
      set({
        isLoading: true,
        accountData: [],
        accountList: [],
        accountListCount: 0,
      });
      const res = await fetchAccountList();
      const accounts = await res;
      set({ accountListCount: accounts?.length });
      const accountList = accounts?.map((account) => ({
        value: account.id,
        label: account.name,
      }));

      accountList?.unshift(defaultAccount);

      set({
        accountData: accounts as AccountList[],
        isLoading: false,
        accountList,
      });

      if (accountList?.length === 2 && accountList.includes(defaultAccount)) {
        set({ selectedAccount: accountList[1] });
      }
    } catch (err) {
      set({
        accountData: [] as AccountList[],
        isLoading: false,
        accountList: [],
      });
      throw err;
    }
  },
  setSelectedAccount: (account: OptionTypeBase) => {
    return set({
      selectedAccount: account,
    });
  },
}));

export default useAccountStore;
