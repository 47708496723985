import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18n from 'core/i18n/i18n';
import { TextField, SelectField, PhoneField, FormComponents } from 'components';
import { useScreenSize } from 'hooks';

const { screenSize } = useScreenSize();

export const Validation = Yup.object({
  firstName: Yup.string().required(i18n.t('forms:validation.required')),
  lastName: Yup.string().required(i18n.t('forms:validation.required')),
  email: Yup.string()
    .email(i18n.t('forms:validation.email'))
    .required(i18n.t('forms:validation.required')),
  phone: Yup.string()
    .ensure()
    .matches(/^$|^\+(?:[0-9] ?){6,14}[0-9]$/, i18n.t('forms:validation.phone'))
    .nullable(),
  mobile: Yup.string()
    .ensure()
    .matches(/^$|^\+(?:[0-9] ?){6,14}[0-9]$/, i18n.t('forms:validation.mobile'))
    .nullable(),
  account: Yup.string(),
  userRole: Yup.string().required(i18n.t('forms:validation.required')),
  language: Yup.string().required(i18n.t('forms:validation.required')),
});

export type AccountDetailsSchema = Yup.InferType<typeof Validation>;

// Will be populated by an API GET to the user details
export const initialAccountDetails: AccountDetailsSchema = {
  firstName: '',
  lastName: '',
  mobile: '',
  phone: '',
  email: '',
  account: '',
  userRole: '',
  language: 'en',
};

export const Form = () => {
  const { t } = useTranslation();
  const { isMobile } = screenSize();

  const languageOptions = [
    { value: 'en', label: i18n.t('languages:en') },
    { value: 'de', label: i18n.t('languages:de') },
    { value: 'fr', label: i18n.t('languages:fr') },
  ];

  return (
    <FormComponents.Section layout={isMobile ? 'single' : 'double'}>
      <TextField
        required
        name="firstName"
        label={t('forms:personal_details.first_name')}
      />
      <TextField
        required
        name="lastName"
        label={t('forms:personal_details.last_name')}
      />

      <PhoneField name="mobile" label={t('forms:personal_details.mobile_no')} />
      <PhoneField name="phone" label={t('forms:personal_details.phone_no')} />
      <TextField
        name="email"
        label={t('forms:personal_details.email')}
        required
        corporateEmailOnly
      />
      <TextField
        disabled
        name="account.name"
        label={t('forms:personal_details.account')}
      />
      <SelectField
        name="userRole"
        tooltip={t('userCreate:form.user_type.tooltip')}
        required
        label={t('forms:personal_details.user_type')}
        options={[
          { value: 'Admin', label: i18n.t('userDetails:roles.admin') },
          { value: 'Viewer', label: i18n.t('userDetails:roles.viewer') },
        ]}
      />
      <SelectField
        name="language"
        required
        label={t('forms:personal_details.language')}
        options={languageOptions}
      />
    </FormComponents.Section>
  );
};
