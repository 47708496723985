import styled from '@emotion/styled';
import { flexVertical, theme } from 'style';

export const Overlay = styled.div`
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
  background-color: black;
`;

export const MenuHeader = styled.div`
  padding-top: 45px;
  padding-left: 25px;
  height: 80px;
  background-color: ${theme.colors.primary};
`;

export const HeaderIcon = styled.div`
  position: absolute;
  top: 58px;
  right: 10px;
  transform: translateY(-50%);
`;

export const ListDefault = styled.div`
  position: relative;
  height: 70px;
  ${flexVertical};
  padding: 0px 30px;
  cursor: pointer;
  font-size: 14px !important;
  border-left: ${theme.borders(1, 'lightBlueGrey')};
`;

export const ListOpen = styled.div`
  width: 100%;
`;

export const DashboardListWrapper = styled.div`
  width: 100%;
  background-color: white;
`;
