import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import styled from '@emotion/styled';

import { Paragraph as P } from 'components';
import { flexVertical, theme } from 'style';

interface Props {}

const EULA = ({}: Props) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Field type="checkbox" name="eula" id="eula">
        {({ field }: { field: any }) => (
          <label // style the label to replace the hidden input
            htmlFor={`${field.name}`}
            style={{
              height: 18,
              width: 18,
              marginRight: 15,
            }}>
            <StyledInput checked={field.value} data-testid="checkbox-input">
              <TickIcon
                viewBox="0 0 24 24"
                checked={field.value}
                data-testid="check-icon">
                <polyline points="20 6 9 17 4 12" />
              </TickIcon>
            </StyledInput>
            <input
              type="checkbox"
              {...field}
              id={`${field.name}`}
              checked={field.value}
              style={{
                // hide the default input
                border: 0,
                clip: 'rect(0 0 0 0)',
                height: '1px',
                margin: '-1px',
                overflow: 'hidden',
                padding: 0,
                position: 'absolute',
                whiteSpace: 'nowrap',
                width: '1px',
              }}
            />
          </label>
        )}
      </Field>

      <P size="tiny" color="charcoal" margin={0}>
        {t('common:eula.text')}
      </P>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;

const TickIcon = styled.svg<{ checked: boolean }>`
  fill: none;
  stroke: white;
  stroke-width: 4px;
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
`;

const StyledInput = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 18px !important;
  height: 18px;
  border-radius: 2px;
  transition: all 150ms;
  cursor: pointer;
  box-sizing: border-box;
  background: ${(props) => (props.checked ? theme.colors.primary : 'white')};
  border: ${(props) =>
    props.checked
      ? `2px solid ${theme.colors.primary}`
      : `2px solid ${theme.colors.blueGrey}`};
`;

export default EULA;
