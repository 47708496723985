import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OptionTypeBase } from 'react-select';

import DoorsTable from './DoorsTable/DoorsTable';
import DoorTableLoading from './DoorsTable/LoadingSkeleton/LoadingSkeleton';
import DoorsMap from './DoorsMap/DoorsMap';
import ListMapToggle from './ListMapToggle/ListMapToggle';

import {
  Paragraph as P,
  Flex,
  PageHeader,
  ActionBar,
  Container,
} from 'components';
import {
  useTableSearch,
  useTableFilter,
  useScreenSize,
  usePrevious,
} from 'hooks';
import {
  useAccountStore,
  useDoorStore,
  useDoorTypeStore,
  useWebSocketMessageStore,
} from 'stores';
import { SearchBarProps } from 'components/SearchBar/SearchBar';
import { DropdownSelectProps } from 'components/DropdownSelect/DropdownSelect';
import { isDIS } from 'utils';

const TableError = ({
  heading,
  subheading,
}: {
  heading: string;
  subheading: string;
}) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    paddingTop={window.innerHeight / 2 - 350}>
    <P size="large" color="primary" bold mb="15px">
      {heading}
    </P>
    <P size="normal" color="charcoal">
      {subheading}
    </P>
  </Flex>
);

const Doors = () => {
  const history = useHistory();
  const location = useLocation<string>();
  const { t } = useTranslation();
  const {
    fetchDoors,
    pagedDoorData,
    filteredDoorData,
    totalDoors,
    isLoading,
    listParams,
    setListParams,
  } = useDoorStore();
  const [showLoader, setLoaderState] = useState(false);
  const { fetchDoorTypes, doorTypeData } = useDoorTypeStore();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const [view, setView] = useState<'List' | 'Map'>('List');
  const locationState = location.state as any;
  const [followedOnly, setFollowedOnly] = useState<boolean>(
    locationState?.followedOnly || false,
  );

  const { accountData, fetchAccounts } = useAccountStore();

  const limit = 20;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasDoorsLoaded, setDoorsHasLoaded] = useState(false);

  const fetch = async (
    page = 1,
    followedOnly: boolean,
    forceLoadFromApi: boolean = false,
  ) => {
    setCurrentPage(page);

    try {
      setLoaderState(true);
      await fetchDoors({
        params:
          {
            ...listParams,
            offset: (page - 1) * limit,
            limit,
          } || {},
        followedOnly,
        forceLoadFromApi,
      });

      setLoaderState(false);
    } catch (err) {
      console.log(err);
    }
  };

  const { lastMessage } = useWebSocketMessageStore();

  useEffect(
    () =>
      setListParams(
        locationState ? { status: locationState.filter } : ({} as any),
      ),
    [],
  );

  useEffect(() => {
    // only re-render if there is last message, and it's about door insights
    if (lastMessage && lastMessage.DoorUUID) {
      fetch(1, followedOnly);
    }
  }, [lastMessage]);

  // Searching ===============================================================
  const { searchValue, searchHandler, clearSearch } = useTableSearch(
    listParams,
    setListParams,
  );
  const clearSearchWrapper = () => {
    clearSearch();
    fetch(1, followedOnly);
  };
  const searchBarSettings: SearchBarProps = {
    placeholder: t('doorList:search_bar_placeholder'),
    searchValue: searchValue || '',
    searchHandler,
    clearSearch: clearSearchWrapper,
  };

  // Dropdown Toggle ===============================================================
  const dropdownOptions = [
    { value: false, label: t('doorList:dropdown_toggle.all') },
    { value: true, label: t('doorList:dropdown_toggle.favorite') },
  ];
  const [dropdownValue, setDropdownValue] = useState<OptionTypeBase>(
    dropdownOptions[0],
  );

  const dropdownHandler = (e: typeof dropdownOptions[0]) => {
    setListParams({ ...listParams });
    setFollowedOnly(e.value);
    setDropdownValue(e);
  };
  const dropdownToggleSettings: DropdownSelectProps = {
    options: dropdownOptions,
    onChange: dropdownHandler,
    name: 'dropdown-toggle',
    value: dropdownValue,
    clear: () => {
      setFollowedOnly(false);
      setDropdownValue(dropdownOptions[0]);
    },
  };

  // set default alerts and followedOnly if user click from dashboard current alerts
  const params = new URLSearchParams(location.search);
  useEffect(() => {
    if (params.get('followedOnly') === 'true') setFollowedOnly(true);
    if (params.get('alerts') === 'active')
      setListParams({ ...listParams, alerts: 'active' });
    history.replace('/doors');
  }, []);

  // Filtering ===============================================================
  const [filtersFromServer, setFiltersFromServer] = useState<any>({
    alerts: [],
    types: [],
  });

  const fetchFilters = async () => {
    try {
      setFiltersFromServer({
        alerts: [
          { label: t('doorList:dropdown_alert.active_alert'), value: 'active' },
          { label: t('doorList:dropdown_alert.no_alert'), value: 'noactive' },
        ],
        types: doorTypeData,
        accounts: accountData.map((account) => ({
          label: account.name,
          value: account.id,
        })),
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchFilters();
  }, [pagedDoorData]);

  const [alertFilter] = useTableFilter(listParams, setListParams, {
    options: filtersFromServer.alerts,
    label: t('doorList:dropdown_alert.title'),
    name: 'alerts',
  });

  const [typeFilter] = useTableFilter(listParams, setListParams, {
    options: filtersFromServer.types,
    label: t('doorList:dropdown_type.title'),
    name: 'type',
  });

  const [accountIdFilter] = useTableFilter(listParams, setListParams, {
    options: filtersFromServer.accounts,
    label: t('doorList:dropdown_account.title'),
    name: 'accountId',
  });

  const filters: DropdownSelectProps[] = [alertFilter, typeFilter];

  if (isDIS()) {
    filters.push(accountIdFilter);
  }

  useEffect(() => {
    fetchDoorTypes();
    if (isDIS()) {
      fetchAccounts();
    }
  }, []);

  useEffect(() => {
    if (!location.search) {
      fetch(1, followedOnly, !hasDoorsLoaded);
      setDoorsHasLoaded(true);
    }
  }, [followedOnly]);

  const previousParams = usePrevious(listParams);
  useEffect(() => {
    if (
      previousParams &&
      JSON.stringify(listParams) !== JSON.stringify(previousParams)
    ) {
      fetch(1, followedOnly, true);
    }
  }, [listParams]);

  const onLoadMore = (nextPage: number) => {
    fetch(nextPage, followedOnly);
  };

  const paginated = totalDoors > limit;

  function isListView() {
    return view === 'List';
  }

  const onUpdateIsFollowing = (id: string) => {
    const list = [...filteredDoorData];

    const item = list.find((i) => i.id === id);
    if (item) {
      item.isFollowing = !item?.isFollowing;
    }
  };

  const renderList = () => {
    return (
      <Container pt={[0, '30px']} pb="30px" mt="0px">
        {!showLoader && totalDoors !== 0 ? (
          <P color="charcoal" mb={'24px'}>
            {t('common:showing_total.showing')}{' '}
            {currentPage === 1
              ? `1 - ${Math.min(limit, pagedDoorData.length)}`
              : `${(currentPage - 1) * limit + 1} - ${
                  (currentPage - 1) * limit + pagedDoorData.length > totalDoors
                    ? totalDoors
                    : (currentPage - 1) * limit + pagedDoorData.length
                }`}{' '}
            {t('common:showing_total.of')} {totalDoors}{' '}
            {t('common:showing_total.doors')}
          </P>
        ) : (
          <div></div>
        )}

        {!showLoader && pagedDoorData?.length === 0 ? (
          <TableError
            heading={t('doorList:table.errors.empty.heading')}
            subheading={t('doorList:table.errors.empty.subheading')}
          />
        ) : (
          <DoorTableLoading isLoading={showLoader}>
            <DoorsTable
              data={pagedDoorData}
              onLoadMore={onLoadMore}
              onChange={onUpdateIsFollowing}
              total={totalDoors}
              currentPage={currentPage}
              limit={limit}
              paginated={paginated}
            />
          </DoorTableLoading>
        )}
      </Container>
    );
  };

  const renderMap = () => {
    return (
      <>
        {!isLoading && pagedDoorData?.length === 0 ? (
          <Container pt={[0, '30px']} pb="30px" mt="0px">
            <TableError
              heading={t('doorList:table.errors.empty.heading')}
              subheading={t('doorList:table.errors.empty.subheading')}
            />
          </Container>
        ) : (
          <DoorsMap
            isLoading={isLoading}
            mapData={filteredDoorData}
            tableData={filteredDoorData}
            onLoadMore={onLoadMore}
            onUpdateIsFollowing={onUpdateIsFollowing}
            total={totalDoors}
            currentPage={currentPage}
            limit={limit}
            paginated={paginated}
            listParams={listParams}
            followedOnly={followedOnly}
          />
        )}
      </>
    );
  };

  return (
    <>
      {!isMobile && <PageHeader heading={t('doorList:heading')} />}
      <ActionBar
        listParams={listParams}
        searchBarSettings={searchBarSettings}
        dropdownToggle={dropdownToggleSettings}
        filters={filters}
        setListParams={setListParams}
        ListMapToggle={<ListMapToggle setView={setView} />}
      />
      {isListView() ? renderList() : renderMap()}
    </>
  );
};

export default Doors;
