import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTable } from 'react-table';

import { BarChart, Percentage } from '../CellComponents';

import { Grid, Table } from 'components';
import { HistoricOccupancy } from 'models';

import { useScreenSize } from 'hooks';

import HistoricListItem from './HistoricListItem/HistoricListItem';

interface Props {
  data: HistoricOccupancy[];
  sortBy: (
    sortBy: 'exceeded' | 'approaching' | 'within' | 'offline' | 'name',
  ) => void;
}

const HistoricOccupancyTable = ({ data, sortBy }: Props) => {
  const history = useHistory();

  const { isMobile, isTablet } = useScreenSize().screenSize();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'historicOccupancy:table.column.name',
            accessor: 'space.name',
            sortable: true,
            filterable: true,
          },
          {
            Header: 'historicOccupancy:table.column.exceeded',
            accessor: 'space.dataSummary.exceededPct',
            sortable: true,
            filterable: true,
            Cell: (props: any) => <Percentage value={props.cell.value} />,
          },
          {
            Header: 'historicOccupancy:table.column.approached',
            accessor: 'space.dataSummary.approachingPct',
            sortable: true,
            filterable: true,
            Cell: (props: any) => <Percentage value={props.cell.value} />,
          },
          {
            Header: 'historicOccupancy:table.column.within',
            accessor: 'space.dataSummary.withinPct',
            sortable: true,
            filterable: true,
            Cell: (props: any) => <Percentage value={props.cell.value} />,
          },
          {
            Header: 'historicOccupancy:table.column.offline',
            accessor: 'space.dataSummary.offlinePct',
            sortable: true,
            filterable: true,
            Cell: (props: any) => <Percentage value={props.cell.value} />,
          },
          {
            Header: '',
            accessor: 'percent',
            sortable: false,
            filterable: false,
            Cell: (props: any) => (
              <BarChart dataSummary={props.row.original.space.dataSummary} />
            ),
          },
        ],
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState: {
      hiddenColumns: isMobile
        ? [
            'dataSummary.exceededPct',
            'dataSummary.approachingPct',
            'dataSummary.withinPct',
            'dataSummary.offlinePct',
          ]
        : [],
    },
  });

  const navigateOnRowClick = (row: HistoricOccupancy) => {
    history.push({
      pathname: `/spaces/${row.space.id}`,
      state: {
        fromPage: history.location.pathname,
        id: row.space.id,
        name: row.space.name,
      },
    });
  };

  if (isMobile) {
    return (
      <Grid gridRowGap="30px" gridAutoFlow="row" mt={30}>
        {rows.map((row: any, index: number) => {
          return (
            <HistoricListItem
              key={index}
              row={row}
              navigateOnRowClick={navigateOnRowClick}
            />
          );
        })}
      </Grid>
    );
  }
  return (
    <Table
      columns={
        isTablet
          ? '2fr 1fr 1fr 1fr 1fr 100px 30px'
          : '2fr 140px 140px 140px 140px 2fr 60px'
      }
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      rows={rows}
      prepareRow={prepareRow}
      onRowClick={navigateOnRowClick}
      navigateOnRowClick={navigateOnRowClick}
      sortBy={sortBy}
    />
  );
};

export default HistoricOccupancyTable;
