import styled from '@emotion/styled';
import { theme, inputDefaults } from 'style';

interface ContainerProps {
  collapse: boolean;
}
export const StyledContainer = styled.div<ContainerProps>((props) => ({
  width: props.collapse ? '100%' : 'calc(50% - 15px)',
}));

interface InputProps {
  disabled?: boolean;
  error?: boolean;
}

export const StyledInput = styled.input<InputProps>`
  ${inputDefaults};
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 0;
  line-height: normal;
  font-size: 14px;
  &:focus {
    border-color: ${theme.colors.primary};
    outline: none;
  }
  color: ${theme.colors.charcoal};
  ::placeholder {
    color: ${theme.colors.blueGrey};
  }
  background-color: ${(props) =>
    props.disabled
      ? theme.colors.lightGrey
      : `${theme.colors.white} !important`};
  border: ${(props) =>
    props.disabled
      ? `1px solid ${theme.colors.lightGrey}`
      : theme.borders(1, 'lightBlueGrey')};
  ${({ error }) =>
    error &&
    `
      border: ${theme.borders(1, 'secondary')}
    `};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${theme.colors.charcoal};
    box-shadow: 0 0 0 30px white inset !important;
    color: ${theme.colors.charcoal} !important;
  }
`;

export const StyledTextarea = styled.textarea<InputProps>`
  ${inputDefaults};
  height: initial;
  padding: 15px;
  resize: none;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 0;
  line-height: normal;
  font-size: 14px;
  &:focus {
    border-color: ${theme.colors.primary};
    outline: none;
  }
  color: ${theme.colors.charcoal};
  ::placeholder {
    color: ${theme.colors.blueGrey};
  }
  background-color: ${(props) =>
    props.disabled
      ? theme.colors.lightGrey
      : `${theme.colors.white} !important`};
  border: ${(props) =>
    props.disabled
      ? `1px solid ${theme.colors.lightGrey}`
      : theme.borders(1, 'lightBlueGrey')};
  ${({ error }) =>
    error &&
    `
      border: ${theme.borders(1, 'secondary')}
    `};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${theme.colors.charcoal};
    box-shadow: 0 0 0 30px white inset !important;
    color: ${theme.colors.charcoal} !important;
  }
`;

export const InputFieldWrapper = styled.div`
  position: relative;
`;

export const ToolTipWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;
