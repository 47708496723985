import styled from '@emotion/styled';
import { variant, margin } from 'styled-system';
import { mq } from 'style';

import { ThemeType, theme, flexCenterAll, Colors } from 'style';

export type ButtonVariants =
  | 'contained'
  | 'outlined'
  | 'whiteoutlined'
  | 'bare';

type ButtonProps = {
  width?: number;
  variant: ButtonVariants;
  disabled?: boolean;
  stretch?: boolean;
  theme: ThemeType;
  isLoading?: boolean;
  color?: string;
  hasChildren?: boolean;
  inverse?: boolean;
};

export const LoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
  border-radius: 4px;
  height: 100%;
  background-color: inherit;
  z-index: 1;
  ${flexCenterAll}
  pointer-events: none;
  cursor: not-allowed;
`;

export const BaseButton = styled.button<ButtonProps>`
  border-radius: 4px;
  position: relative;
  outline: none;
  height: 50px;
  min-width: max-content;
  padding: ${({ hasChildren }) => (hasChildren ? '15px 35px' : '11px 13px')};
  cursor: pointer;
  font-weight: 600;
  transition: all 0.15s ease-in-out;
  font-size: 16px;
  line-height: 24px;
  border: none;
  box-sizing: border-box;
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  ${mq.mobile()} {
  padding-left: 24px;
  padding-right: 24px;
    ${({ hasChildren }) =>
      !hasChildren &&
      `
      padding: 13px;
    `}
};
${({ stretch }) =>
  stretch &&
  `
  width: 100%;
`}
  path {
    transition: all 0.15s ease-in-out !important;
  }

  > span {
    margin-right: ${(props) => (props.hasChildren ? '7px' : 0)};
    ${flexCenterAll}
  
  }

  ${flexCenterAll}
  ${variant({
    variants: {
      contained: {
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        border: theme.borders(2, 'primary'),
        path: {
          fill: `${theme.colors.white} !important`,
        },
        '.sk-circle .sk-child:before': {
          backgroundColor: `${theme.colors.white} !important`,
        },
        '&:hover': {
          backgroundColor: theme.colors.charcoal,
          color: theme.colors.white,
          border: theme.borders(2, 'charcoal'),
        },
        '&:disabled': {
          backgroundColor: theme.colors.lightGrey,
          color: theme.colors.white,
          border: theme.colors.lightGrey,
        },
      },
      outlined: {
        backgroundColor: theme.colors.white,
        color: theme.colors.primary,
        border: theme.borders(2, 'primary'),
        '.sk-circle .sk-child:before': {
          backgroundColor: theme.colors.primary,
        },
        path: {
          fill: `${theme.colors.primary} !important`,
        },
        '&:hover': {
          backgroundColor: theme.colors.primary,
          color: theme.colors.white,
          border: theme.borders(2, 'primary'),
          '.sk-circle .sk-child:before': {
            backgroundColor: `${theme.colors.white} !important`,
          },
          path: {
            fill: `${theme.colors.white} !important`,
          },
        },
        '&:disabled': {
          backgroundColor: theme.colors.white,
          color: theme.colors.lightGrey,
          border: `2px solid ${theme.colors.lightGrey}`,
          path: {
            fill: `${theme.colors.lightGrey} !important`,
          },
        },
      },
      whiteoutlined: {
        backgroundColor: theme.colors.primary,
        color: theme.colors.white,
        border: theme.borders(2, 'white'),
        '.sk-circle .sk-child:before': {
          backgroundColor: theme.colors.white,
        },
        path: {
          fill: `${theme.colors.white} !important`,
        },
        '&:hover': {
          backgroundColor: theme.colors.white,
          color: theme.colors.primary,
          border: theme.borders(2, 'white'),
          '.sk-circle .sk-child:before': {
            backgroundColor: `${theme.colors.primary} !important`,
          },
          path: {
            fill: `${theme.colors.primary} !important`,
          },
        },
        '&:disabled': {
          backgroundColor: theme.colors.primary,
          color: theme.colors.greyBlue,
          border: `2px solid ${theme.colors.greyBlue}`,
          path: {
            fill: `${theme.colors.greyBlue} !important`,
          },
        },
      },
      bare: {
        backgroundColor: theme.colors.white,
        color: theme.colors.primary,
        border: theme.borders(2, 'transparent'),
        '.sk-circle .sk-child:before': {
          backgroundColor: theme.colors.primary,
        },
        path: {
          fill: `${theme.colors.primary} !important`,
        },
        boxShadow: `${theme.boxShadow}`,
        '&:hover': {
          backgroundColor: theme.colors.primary,
          color: theme.colors.white,
          border: theme.borders(2, 'primary'),
          '.sk-circle .sk-child:before': {
            backgroundColor: `${theme.colors.white} !important`,
          },
          path: {
            fill: `${theme.colors.white} !important`,
          },
        },
        '&:disabled': {
          backgroundColor: theme.colors.white,
          color: theme.colors.lightGrey,
          border: `2px solid ${theme.colors.lightGrey}`,
          path: {
            fill: `${theme.colors.lightGrey} !important`,
          },
        },
      },
    },
  })};
  ${({ inverse }) =>
    inverse &&
    variant({
      variants: {
        contained: {
          backgroundColor: `${theme.colors.white}`,
          color: theme.colors.primary,
          border: theme.borders(2, theme.colors.white),
          '.sk-circle .sk-child:before': {
            backgroundColor: theme.colors.primary,
          },
          '&:hover': {
            backgroundColor: theme.colors.blueGrey,
            color: theme.colors.primary,
            border: theme.borders(2, 'blueGrey'),
          },
          '&:disabled': {
            backgroundColor: theme.colors.greyBlue,
            color: theme.colors.primary,
            border: theme.borders(2, 'greyBlue'),
          },
          path: {
            fill: `${theme.colors.primary} !important`,
          },
        },
        outlined: {
          backgroundColor: theme.colors.primary,
          color: theme.colors.white,
          border: theme.borders(2, theme.colors.white),
          '.sk-circle .sk-child:before': {
            backgroundColor: `${theme.colors.white} !important`,
          },
          path: {
            fill: `${theme.colors.white} !important`,
          },
          '&:hover': {
            color: theme.colors.blueGrey,
            border: theme.borders(2, 'blueGrey'),
            '.sk-circle .sk-child:before': {
              backgroundColor: theme.colors.blueGrey,
            },
            path: {
              fill: `${theme.colors.blueGrey} !important`,
            },
          },
          '&:disabled': {
            backgroundColor: 'transparent',
            color: theme.colors.greyBlue,
            border: theme.borders(2, 'greyBlue'),
            path: {
              fill: `${theme.colors.greyBlue} !important`,
            },
          },
        },
      },
    })}
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor: not-allowed;
    `}

    ${margin}
`;
