import { captureMessage } from '@sentry/browser';
import { useState, useEffect } from 'react';
import moment from 'moment';

import { fetchDoorActivity } from 'services';
import { DailyTraffic } from 'models';
import { generateDatePeriod } from 'stores/useDailyTrafficStore/useDailyTrafficStore';

export interface TrafficUiState {
  data: any;
  isLoading: boolean;
  lastRefresh: string;
}

let retries = 3;
const useDailyTraffic = (spaceId: string) => {
  const [activeDevice, setActiveDevice] = useState<any>();
  const [trafficDirection, setTrafficDirection] = useState<'In' | 'Out'>('In');
  const [datePeriod, setDatePeriod] = useState<'Today' | 'Yesterday'>('Today');

  // Data ======== Yesterday ======

  const [error, setError] = useState<string>();

  const [todayTraffic, setTodayTraffic] = useState<TrafficUiState>({
    data: {} as DailyTraffic,
    isLoading: true,
    lastRefresh: '',
  });
  const fetchTodaysTraffic = async (id: string) => {
    // let retries = 3;
    try {
      const data = await fetchDoorActivity(id, generateDatePeriod('Today'));
      const mapped = data?.devices.map((device: any) => {
        return { ...device, lastRefreshed: moment().format('HH:mm:ss') };
      });
      const merged = { ...data, devices: mapped };
      if (data) {
        setTodayTraffic({
          data: merged,
          isLoading: false,
          lastRefresh: moment().format('HH:mm:ss'),
        });
      }
    } catch (err) {
      if (err.response.status === 400 && retries > 0) {
        fetchTodaysTraffic(id);
        retries = retries - 1;
      } else {
        setError(err);
        captureMessage(err.response.data);
        throw err;
      }
    }
  };

  const [yesterdayTraffic, setYesterdayTraffic] = useState<TrafficUiState>({
    data: {} as DailyTraffic,
    isLoading: true,
    lastRefresh: '',
  });
  const fetchYesterdaysTraffic = async (id: string) => {
    try {
      const data = await fetchDoorActivity(id, generateDatePeriod('Yesterday'));
      const mapped = data?.devices.map((device: any) => {
        return {
          ...device,
          lastRefreshed: moment()
            .subtract(1, 'day')
            .format('DD/MM/YYYY'),
        };
      });
      const merged = { ...data, devices: mapped };
      if (data) {
        setYesterdayTraffic({
          data: merged,
          isLoading: false,
          lastRefresh: moment()
            .subtract(1, 'day')
            .format('DD/MM/YYYY'),
        });
      }
    } catch (err) {
      if (err.code === 400 && retries > 0) {
        fetchYesterdaysTraffic(id);
        retries = retries - 1;
      } else {
        setError(err);
        captureMessage(err.response.data);
        throw err;
      }
    }
  };

  useEffect(() => {
    fetchTodaysTraffic(spaceId);
    fetchYesterdaysTraffic(spaceId);
  }, []);

  return {
    fetchTodaysTraffic,
    todayTraffic,
    yesterdayTraffic,
    error,
    activeDevice,
    setActiveDevice,
    trafficDirection,
    setTrafficDirection,
    datePeriod,
    setDatePeriod,
  };
};

export default useDailyTraffic;
