import _ from 'lodash';

import { User } from 'models';
import { SignOut } from 'core/auth/auth';

export enum Product {
  DI = 'DI',
  OI = 'OI',
  DIS = 'DIS',
  DIOI = 'DI;OI',
}

export enum ProductType {
  Entrivo = 'ETV',
  EntriWorX = 'EWX',
  EntrivoAndEntriWorX = 'EVX',
}

export const EntriWorX = 'EntriWorX';

export const getCurrentProduct = (): Product | undefined => {
  const x = window.localStorage.getItem('userMe');
  if (x) {
    const userDetails: User = JSON.parse(x);

    // sign out and re-setup
    if (!_.has(userDetails, 'productsCanAccess')) {
      SignOut();
      window.location.reload();
    }

    return userDetails.productsCanAccess.length > 1
      ? Product.DIOI
      : userDetails.productsCanAccess[0];
  }
  return undefined;
};

export const getProductType = () => {
  const x = window.localStorage.getItem('userMe');
  if (x) {
    const userDetails: User = JSON.parse(x);

    // ETV, EWX, EVX
    return userDetails.productType;
  }

  return '';
};

export const getCurrentProductName = () => {
  let productName = '';
  const x = window.localStorage.getItem('userMe');
  if (x) {
    const userDetails: User = JSON.parse(x);

    // DI, DIS, OI, DIOI
    const product = getCurrentProduct();

    // ETV, EWX, EVX
    const productType = userDetails.productType;

    if (productType === ProductType.Entrivo) {
      switch (product) {
        case Product.DI:
          productName = 'for Entrivo Door Insights';
          break;
        case Product.OI:
          productName = 'for Entrivo Occupancy Insights';
          break;
        case Product.DIS:
          productName = 'for Entrivo Supported Service';
          break;
      }
    } else if (productType === ProductType.EntriWorX) {
      switch (product) {
        case Product.DI:
          productName = 'for Door Insights';
          break;
        case Product.DIS:
          productName = 'for Service';
          break;
      }
    } else if (productType === ProductType.EntrivoAndEntriWorX) {
      switch (product) {
        case Product.DI:
          productName = 'for Door Insights';
          break;
        case Product.DIS:
          productName = 'for Service';
          break;
      }
    }
  }
  return productName;
};

export const isDualProducts = () => {
  return getCurrentProduct() === Product.DIOI;
};

export const isDIS = () => {
  return getCurrentProduct() === Product.DIS;
};

export const isDI = () => {
  return getCurrentProduct() === Product.DI;
};

export const isOI = () => {
  return getCurrentProduct() === Product.OI;
};

export const shouldShowModeElement = () =>
  getProductType() === ProductType.Entrivo ||
  getProductType() === ProductType.EntrivoAndEntriWorX;

export const shouldShowModeWarning = () =>
  getProductType() === ProductType.EntrivoAndEntriWorX;
