import React, { useState, useEffect } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerRangeController } from 'react-dates';
import moment, { Moment } from 'moment';
import i18n from 'i18next';
import { useScreenSize } from 'hooks';

import {
  DatePickerWrapper,
  RightArrowWrapper,
  LeftArrowWrapper,
  Arrow,
} from './dateSelectorStyles';
import 'moment/locale/de';
import 'moment/locale/fr';
import { IcomoonIcons } from 'components';

interface SelectedDates {
  startDate: Moment | null;
  endDate: Moment | null;
}
interface Props {
  isVisible?: boolean;
  onOutsideClick: () => void;
  onDateChange: (selectedDates: SelectedDates) => void;
  startDate: Moment | null;
  endDate: Moment | null;
  earliestDate?: Date | string;
}

const calcEarliestDate = (date?: any) => {
  const DEFAULT = 90;
  if (date) {
    const today = moment().startOf('day');
    const m = moment(date);
    // @ts-ignore
    const daysSince = Math.round(moment.duration(today - m).asDays());
    return daysSince;
  } else {
    return DEFAULT;
  }
};
export const DatePicker = ({
  isVisible = true,
  onOutsideClick,
  onDateChange,
  startDate,
  endDate,
  earliestDate,
}: Props) => {
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>(
    'startDate',
  );
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  useEffect(() => {
    moment.locale(i18n.language);
  }, []);

  return isVisible ? (
    <DatePickerWrapper>
      <Arrow />
      <DayPickerRangeController
        startDate={startDate}
        endDate={endDate}
        initialVisibleMonth={() => moment(new Date()).subtract(1, 'month')}
        navNext={
          <RightArrowWrapper>
            <IcomoonIcons icon="chevron-right" />
          </RightArrowWrapper>
        }
        navPrev={
          <LeftArrowWrapper>
            <IcomoonIcons icon="chevron-left" />
          </LeftArrowWrapper>
        }
        focusedInput={focusedInput}
        isOutsideRange={(day) => {
          return (
            moment(day).isBefore(
              earliestDate ? earliestDate :
              moment()
                .local()
                .subtract(calcEarliestDate(), 'days'),
            ) || moment(day).isAfter(moment().endOf('day'))
          );
        }}
        onOutsideClick={onOutsideClick}
        onFocusChange={(focusedInput) => {
          if (focusedInput === 'startDate') {
            onDateChange({ startDate: null, endDate: null });
          }
          setFocusedInput(focusedInput || 'startDate');
        }}
        hideKeyboardShortcutsPanel
        noBorder
        numberOfMonths={isMobile ? 1 : 2}
        onDatesChange={({ startDate, endDate }: SelectedDates) => {
          onDateChange({ startDate, endDate });
        }}
      />
    </DatePickerWrapper>
  ) : null;
};

export default DatePicker;
