import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18n from 'core/i18n/i18n';

import {
  Heading as H,
  Paragraph as P,
  TextField,
  SelectField,
  FormComponents,
  Grid,
  ToolTip,
  Flex,
  Spinner,
} from 'components';
import { Divider } from './spaceSettingsStyle';
import { useScreenSize } from 'hooks';
import { fetchAccountPicklists } from 'services';
import { AccountPicklists, IdValue, LabelValue } from 'models';
import { FilterTranslations, applyFilterTranslations } from 'utils';
import { Spacer } from 'style';

const { screenSize } = useScreenSize();

export const Validation = Yup.object({
  oiPerPersonSpaceAllowance: Yup.number()
    .positive(i18n.t('forms:validation.number_positive'))
    .required(i18n.t('forms:validation.required'))
    .typeError(i18n.t('forms:validation.must_be_number')),
  oiUnitOfMeasure: Yup.string().required(i18n.t('forms:validation.required')),
  defaultAccountTimezone: Yup.string().required(
    i18n.t('forms:validation.required'),
  ),
  oiEntranceDisplayMessage: Yup.string()
    .nullable()
    .required(i18n.t('forms:validation.required'))
    .max(255, i18n.t('forms:validation.max_255')),
});

export type SpaceSettingsSchema = Yup.InferType<typeof Validation>;

// Will be populated by an API GET to the user details
export const initialAccountDetails: SpaceSettingsSchema = {
  oiPerPersonSpaceAllowance: 0,
  oiUnitOfMeasure: 'Metre',
  defaultAccountTimezone: '',
  oiEntranceDisplayMessage: '',
};

const unitsTranslations: FilterTranslations = {
  Metres: 'm2',
  Feet: 'ft2',
};

export const Form = () => {
  const { t } = useTranslation();

  const [picklists, setPicklists] = useState<any>();

  useEffect(() => {
    const fetchPicklists = async () => {
      try {
        const picklistsRes = (await fetchAccountPicklists()) as AccountPicklists;
        const unitsTranslated = await applyFilterTranslations(
          picklistsRes.unitsOfMeasure,
          unitsTranslations,
        );
        setPicklists({
          timezones: picklistsRes.timezones.map((item: IdValue) => ({
            label: item.id,
            value: item.value,
          })),
          unitsOfMeasure: unitsTranslated,
        });
      } catch (err) {
        console.log(err);
      }
    };
    fetchPicklists();
  }, []);
  return (
    <FormComponents.Section layout="single">
      <P size="small" color="charcoal">
        {t('settings:space_settings.description')}
      </P>
      <Flex alignItems="center">
        <H size="h6" color="primary" mt="40px" mb="30px">
          {t('settings:space_settings.space_required.description')}
        </H>
        <Spacer width={5} />
        <ToolTip
          iconSize={14}
          content={t('settings:space_settings.space_required.tooltip')}
        />
      </Flex>

      <Grid gridTemplateColumns={['1fr', '1fr 1fr']}>
        <Grid gridTemplateColumns="1fr 30%" gridColumnGap="14px">
          <TextField required name="oiPerPersonSpaceAllowance" />
          {picklists ? (
            <SelectField
              required
              name="oiUnitOfMeasure"
              options={
                picklists ? (picklists.unitsOfMeasure as LabelValue[]) : []
              }
            />
          ) : (
            <Spinner color="lightBlueGrey" />
          )}
        </Grid>
      </Grid>
      <Divider />

      <H size="h6" color="primary" mt="40px" mb="30px">
        {t('settings:space_settings.time_zone.description')}
      </H>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr']}>
        {picklists ? (
          <SelectField
            // required
            name="defaultAccountTimezone"
            label={t('settings:space_settings.time_zone.label')}
            options={picklists ? (picklists.timezones as LabelValue[]) : []}
            disabled
            tooltip={t('forms:personal_details.tooltip')}
          />
        ) : (
          <Spinner color="lightBlueGrey" />
        )}
      </Grid>
      <Divider />

      <H size="h6" color="primary" mt="40px" mb="30px">
        {t('settings:space_settings.default_message.description')}
      </H>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr']}>
        <TextField
          required
          type="textarea"
          name="oiEntranceDisplayMessage"
          label={t('settings:space_settings.default_message.label')}
        />
      </Grid>
    </FormComponents.Section>
  );
};
