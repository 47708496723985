export enum NotificationType {
  Alert = 'Alert',
  Mode = 'Mode',
  EntriWorX = 'EntriWorX',
  All = 'All',
  SupportedServiceAlert = 'DIS',
  CriticalAlert = 'CriticalAlert',
  GeneralAlert = 'GeneralAlert',
  AllCritical = 'AllCritical',
  AllGeneral = 'AllGeneral',
}

export enum NotifcationTypeFilter {
  CriticalOnly,
  GeneralOnly,
}

export interface NotificationGroup {
  name: string;
  displayName: string;
  value: number;
  type: NotificationType;
  isCritical: boolean;
  valueSuffix?: string;
}

export interface NotificationsSummaryChart {
  notificationGroups: NotificationGroup[];
  entrivoDoorsCount: number;
}

export interface NotificationDetails {
  alerts: any;
  modes: ModeDetails[];
}

export interface ModeDetails {
  duration: number;
  endTime: string;
  startTime: string;
  type: NotificationType;
  id: number;
  name: string;
  displayName: string;
}

export interface ModeDetailsChartData {
  name: string;
  modeChanges: ModeDetails[];
}
