import React from 'react';
import { useTranslation} from 'react-i18next'

import { StyledDiv } from './tabContentHeadingStyle';

import { } from 'components';


interface Props {
children: string;
}


const TabContentHeading = ({ children }: Props) => {
const { t } = useTranslation();
return <StyledDiv>{children}</StyledDiv>;
};

export default TabContentHeading;