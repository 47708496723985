import styled from '@emotion/styled';

export const IconContainer = styled.div`
  margin-right: 8px;
`;

export const IconCheckContainer = styled.div`
  margin-right: 6px;
  border-radius: 50%;
  padding: 0px 0px 0px 1px;
  border: 2px solid #73ad21;
`;
