import React from 'react';
import { UseTableRowProps } from 'react-table';
import { useTranslation } from 'react-i18next';

import { MobileTableComponents, Paragraph, StatusIndicator } from 'components';
import { getDeviceColor } from '../DailyTrafficTable';
import { TrafficDevice } from 'models';

type Props = {
  row: UseTableRowProps<TrafficDevice>;
  activeDevice?: any;
  setActiveDevice?: any;
};
export const DeviceItem = ({ row, activeDevice, setActiveDevice }: Props) => {
  const { t } = useTranslation();

  console.log('row: ', row);

  const handleClickEvent = () => {
    setActiveDevice(row);
  };

  return (
    <MobileTableComponents.Wrapper
      key={row.id}
      backgroundColor={
        activeDevice && activeDevice.id === row.original.id
          ? 'lightBlueGrey'
          : 'white'
      }
      onClick={handleClickEvent}>
      <MobileTableComponents.Item
        row={1}
        column="span 3"
        style={{ marginBottom: 10 }}>
        <StatusIndicator
          label={row.original.deviceName}
          indicatorColor={getDeviceColor(row.index)}
        />
      </MobileTableComponents.Item>

      <MobileTableComponents.ColumnHeader row={3}>
        Connectivity
      </MobileTableComponents.ColumnHeader>
      <MobileTableComponents.Item row={4}>
        {row.original.isOnline ? (
          <Paragraph size="small">
            {t('spaceDetails:charts.daily_traffic_chart.online')}
          </Paragraph>
        ) : (
          <Paragraph size="small" bold color="error">
            {t('spaceDetails:charts.daily_traffic_chart.offline')}
          </Paragraph>
        )}
      </MobileTableComponents.Item>
      <MobileTableComponents.ColumnHeader row={3} column={2}>
        In Traffic
      </MobileTableComponents.ColumnHeader>
      <MobileTableComponents.Item row={4} column={2}>
        <Paragraph size="small">{row.original.totalIn}</Paragraph>
      </MobileTableComponents.Item>
      <MobileTableComponents.ColumnHeader row={3} column={3}>
        Out Traffic
      </MobileTableComponents.ColumnHeader>
      <MobileTableComponents.Item row={4} column={3}>
        <Paragraph size="small">{row.original.totalOut}</Paragraph>
      </MobileTableComponents.Item>
    </MobileTableComponents.Wrapper>
  );
};
export default DeviceItem;
