import React from 'react';
import { Skeleton, Flex } from 'components';
import { useScreenSize } from 'hooks';

const StackedBarChartSkeleton = ({
  legendCount = 2,
}: {
  legendCount?: number;
}) => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  const legendSkeleton = (marginLeft: string) => (
    <Skeleton
      key={marginLeft}
      width={isMobile ? 80 : 100}
      height={isMobile ? 30 : 40}
      style={{
        marginTop: '-60px',
        marginLeft: marginLeft,
      }}
    />
  );

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <Skeleton
        width={isMobile ? 220 : 500}
        height={isMobile ? 250 : 250}
        variant="rectangle"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '80px',
          marginTop: isMobile ? '15px' : 0,
        }}></Skeleton>
      <Flex
        flexDirection={isMobile && legendCount > 2 ? 'column' : 'row'}
        justifyContent="center">
        {isMobile && legendCount > 2 ? (
          <Skeleton
            width={isMobile ? 80 : 100}
            height={isMobile ? 30 : 40}
            style={{ marginTop: '-100px' }}
          />
        ) : (
          Array.from(Array(legendCount).keys()).map((count) => {
            return legendSkeleton(`${count * 20}px`);
          })
        )}
      </Flex>
    </Flex>
  );
};

export default StackedBarChartSkeleton;
