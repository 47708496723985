import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';

import {
  TextField,
  FullScreenContainer,
  Flex,
  Button,
  Link,
  SmallFormContainer,
  InfoBox,
} from 'components';
import { Spacer } from 'style';
import { Login as LoginModel } from 'models';
import { SignIn } from 'core/auth/auth';
import { statusByUsername } from 'services';

import { PasswordResetRequest } from './forms';

type Props = RouteComponentProps;

const Login: React.FC<Props> = () => {
  const [showPasswordReset, setPasswordReset] = useState<boolean>(false);
  const [verificationIsRequired, setVerificationIsRequired] = useState<boolean>(
    false,
  );

  const togglePasswordReset = () => setPasswordReset(!showPasswordReset);
  const { t } = useTranslation();

  const history = useHistory();

  const validationSchema = Yup.object({
    email: Yup.string().email(t('validation:email')),
    password: Yup.string(),
  });

  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  const initialValues: ValidationSchema = {
    email: '',
    password: '',
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const session = await Auth.currentSession();
        const isValid = await session.isValid();
        setIsAuthenticated(isValid);
      } catch (err) {
        throw err;
      }
    };
    fetchSessionData();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  }, [isAuthenticated]);

  const onSubmit = async (
    values: ValidationSchema,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (fields: { [field: string]: string }) => void,
  ) => {
    setSubmitting(true);
    try {
      const userStatus = await statusByUsername(values.email);

      switch (userStatus) {
        case 'Active':
          const payload: LoginModel = {
            username: values.email,
            password: values.password,
          };
          await SignIn(payload);
          history.push('/loading');
          break;
        case 'Disabled':
          setVerificationIsRequired(false);
          setErrors({ email: t('cognitoErrors:UserIsDisabled') });
          break;
        case 'Pending':
          setVerificationIsRequired(false);
          setErrors({ email: t('loginActivate:login.error.pending') });
          break;
        case 'VerificationRequired':
          setVerificationIsRequired(true);
          break;
        case 'Unknown':
          setVerificationIsRequired(false);
          setErrors({ email: t('loginActivate:login.error.username') });
          break;
      }
    } catch (err) {
      setVerificationIsRequired(false);
      const { code, message, status } = err;

      if (err?.response?.status === 404) {
        setErrors({ email: t('loginActivate:login.error.username') });
      } else if (code === 'NotAuthorizedException') {
        setErrors({ password: t('loginActivate:login.error.password') });
      } else {
        setErrors({ password: message });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const headerSettings = {
    mainHeading: t('loginActivate:forgot_password.heading'),
    subHeading: t('loginActivate:forgot_password.subheading'),
  };

  const [initialEmail, setInitialEmail] = useState<string>();

  return (
    <FullScreenContainer>
      {verificationIsRequired && (
        <>
          <Spacer height={35} />
          <InfoBox
            title={t('loginActivate:login.verification_required.heading')}
            description={t(
              'loginActivate:login.verification_required.subheading',
            )}
          />
          <Spacer height={35} />
        </>
      )}
      {!showPasswordReset ? (
        <SmallFormContainer mainHeading={t('loginActivate:login.heading')}>
          <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              onSubmit(values, setSubmitting, setErrors);
            }}>
            {(props) => {
              const checkValues = (obj: any) => {
                for (const key in obj) {
                  if (obj[key] === '') {
                    return true;
                  }
                }
              };
              setInitialEmail(props.values.email);

              return (
                <Form data-testid="login-form-wrapper">
                  <Flex stretch justifyContent="center" flexDirection="column">
                    <TextField
                      name="email"
                      label={t('forms:email')}
                      type="email"
                      placeholder={t('forms:email')}
                    />
                    <TextField
                      name="password"
                      label={t('forms:password')}
                      type="password"
                      placeholder={t('forms:password')}
                      data-testid="login-password-input"
                    />
                    <Button
                      type="submit"
                      disabled={
                        !(props.isValid && props.dirty) ||
                        props.isSubmitting ||
                        checkValues(props.values)
                      }
                      isLoading={props.isSubmitting}
                      data-testid="form-wrapper-submit">
                      {t('forms:login')}
                    </Button>
                    <Spacer height={20} />
                  </Flex>
                </Form>
              );
            }}
          </Formik>
          <Spacer height={10} />
          <Flex justifyContent="center">
            <Link
              size="small"
              color="charcoal"
              onClick={togglePasswordReset}
              data-cypress="login-forgot-password">
              {t('loginActivate:login.forgot_password')}
            </Link>
          </Flex>
        </SmallFormContainer>
      ) : (
        <PasswordResetRequest.Form
          headerSettings={headerSettings}
          togglePasswordReset={togglePasswordReset}
          initialEmail={initialEmail}
        />
      )}
    </FullScreenContainer>
  );
};

export default Login;
