// @ts-nocheck
interface Config {
  development: ConfigItem;
  testing: ConfigItem;
  staging: ConfigItem;
  production: ConfigItem;
}
interface ConfigItem {
  baseUrl: string;
  API: string;
  websocket: string;
  documentTitle: string;
  externalDisplay: string;
  doorStateWebSocket: string;
}

const env = process.env.REACT_APP_ENV || 'development';

const config: Config = {
  development: {
    baseUrl: 'http://localhost:3000',
    API:
      'https://obau4i5fi9.execute-api.ap-southeast-2.amazonaws.com/Prod/api/',
    websocket: 'wss://smeowkpxtf.execute-api.ap-southeast-2.amazonaws.com/dev',
    doorStateWebSocket:
      'wss://pxh239igba.execute-api.ap-southeast-2.amazonaws.com/dev',
    documentTitle: '[Dev] Entrivo Engage',
    externalDisplay: 'http://localhost:3006',
    cognito: {
      Auth: {
        userPoolWebClientId: '4bvefa8jmonqj5pnokdgaq9i0g',
        userPoolId: 'ap-southeast-2_Tn2TAZCZH',
        region: 'ap-southeast-2',
      },
    },
  },
  testing: {
    baseUrl: 'https://d16ynmb0wv7mgk.cloudfront.net',
    API:
      'https://obau4i5fi9.execute-api.ap-southeast-2.amazonaws.com/Prod/api/',
    //  'https://b6urvloe5d.execute-api.ap-southeast-2.amazonaws.com/Prod/api/',
    websocket: 'wss://6u6kyfpm85.execute-api.ap-southeast-2.amazonaws.com/dev',
    doorStateWebSocket:
      'wss://pxh239igba.execute-api.ap-southeast-2.amazonaws.com/dev',
    documentTitle: '[Test] Entrivo Engage',
    externalDisplay: 'https://d3jkcmuavq4xub.cloudfront.net',
    cognito: {
      Auth: {
        //userPoolWebClientId: '6hlosrpok9r24186j1sg232jck',
        //userPoolId: 'ap-southeast-2_HBNbQlqi7',
        userPoolWebClientId: '4bvefa8jmonqj5pnokdgaq9i0g',
        userPoolId: 'ap-southeast-2_Tn2TAZCZH',
        region: 'ap-southeast-2',
      },
    },
  },
  staging: {
    baseUrl: 'https://d2ja3m6sf4w79o.cloudfront.net',
    API:
      'https://obau4i5fi9.execute-api.ap-southeast-2.amazonaws.com/Prod/api/',
    websocket: 'wss://smeowkpxtf.execute-api.ap-southeast-2.amazonaws.com/dev',
    doorStateWebSocket:
      'wss://pxh239igba.execute-api.ap-southeast-2.amazonaws.com/dev',
    documentTitle: '[UAT] Entrivo Engage',
    externalDisplay: 'https://d3jkcmuavq4xub.cloudfront.net',
    cognito: {
      Auth: {
        userPoolWebClientId: '4bvefa8jmonqj5pnokdgaq9i0g',
        userPoolId: 'ap-southeast-2_Tn2TAZCZH',
        region: 'ap-southeast-2',
      },
    },
  },
  production: {
    baseUrl: '',
    API: 'https://w9mgy1lwa5.execute-api.eu-central-1.amazonaws.com/Prod/api/',
    websocket:
      'wss://elbguetje5.execute-api.eu-central-1.amazonaws.com/production',
    doorStateWebSocket:
      'wss://lp5jmzze76.execute-api.eu-central-1.amazonaws.com/production',
    documentTitle: 'Entrivo Engage',
    externalDisplay: 'http://entrivo.engagedoordisplay.dormakaba.com/',
    cognito: {
      Auth: {
        userPoolWebClientId: '6rhvc7lcq5go792te1ioq53q7u',
        userPoolId: 'eu-central-1_vKcXRDp97',
        region: 'eu-central-1',
      },
    },
  },
};

export default config[env];
