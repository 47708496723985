import React from 'react';
import moment from 'moment';
import { StatusFlare } from './statusStyle';
import { Flex, Paragraph as P, Pill } from 'components';
import { useScreenSize } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Spacer, theme } from 'style';

interface StatusProps {
  isActive: boolean;
  isOffline: boolean;
  lastUpdate: string;
}

const Status = ({ isActive, isOffline, lastUpdate }: StatusProps) => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const { t } = useTranslation();

  return (
    <Flex alignItems="center">
      <StatusFlare
        backgroundColor={
          !isActive ? 'error' : isOffline ? 'lightBlueGrey' : 'success'
        }
      />
      <P
        size={isMobile ? 'small' : 'normal'}
        color="white"
        data-testid="status">
        {!isActive
          ? t('common:status.decommissioned')
          : `${
              isOffline
                ? t('common:status.offline')
                : t('common:status.commissioned')
            }`}
      </P>
      {isActive && isOffline && (
        <>
          <Spacer width={8} />
          <Pill
            text={`${t('doorDetails:page_header.last_received')} ${moment(
              lastUpdate,
            ).fromNow()}`}
            textColor="white"
            backgroundColor={theme.colors.error}
          />
        </>
      )}
    </Flex>
  );
};

export default Status;
