import { Alert, LabelValueWithIsCriticalField, NotificationType } from 'models';
import { isDIS, shouldShowModeElement } from './getCurrentProduct';
import i18n from 'core/i18n/i18n';

export const getNotificationTypeOptions = (
  alertTypesData: Alert[],
  filterBy?: NotificationType,
  shouldBundleDISAlerts?: boolean,
  isDISFromApi?: boolean,
): LabelValueWithIsCriticalField[] => {
  const alertTypeOptions = alertTypesData
    ?.filter((alertType) => {
      let matchesFilter = false;
      if (!shouldShowModeElement()) {
        matchesFilter = alertType.notificationType !== NotificationType.Mode;
      } else if (filterBy) {
        matchesFilter = alertType.notificationType === filterBy;
      } else {
        matchesFilter = true;
      }

      if (shouldBundleDISAlerts) {
        matchesFilter =
          matchesFilter && alertType.profileVisibility !== '[DIS]';
      }

      return matchesFilter;
    })
    .map(({ alertId, alertDisplayName, isCritical }: Alert) => ({
      value: alertId,
      label: alertDisplayName,
      isCritical,
    }));

  if (shouldBundleDISAlerts && (isDIS() || isDISFromApi)) {
    alertTypeOptions.push({
      value: NotificationType.SupportedServiceAlert,
      label: i18n.t('alerts:names.supported_service_alerts'),
      isCritical: false,
    });
  }

  alertTypeOptions?.sort((a, b) => (a.label > b.label ? 1 : -1));
  return alertTypeOptions;
};
