import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  List,
  DonutContainer,
  ListContainer,
  TabsWrapper,
} from './operationalOverviewStyle';
import GridItem from './GridItem/GridItem';
import NoNotification from './NoNotification/NoNotification';
import {
  ChartContainer,
  DonutChart,
  Grid,
  Skeleton,
  Paragraph as P,
  ToolTip,
} from 'components';
import { useDoorsStatsStore, useNotificationStore } from 'stores';
import { NotificationType, SelectedDates, SelectedDateTypeEnum } from 'models';
import {
  formatDIOperationalAlertsOverviewData,
  formatDIOperationalModeChangesOverviewData,
  shouldShowModeElement,
  shouldShowModeWarning,
} from 'utils';
import { useHistory } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useScreenSize } from 'hooks';
import { theme, Spacer } from 'style';
import ModeLabel from 'components/ModeLabel/ModeLabel';

interface Props {
  followedOnly: boolean;
  selectedDates: SelectedDates;
  selectedDateType: SelectedDateTypeEnum;
  triggerUpdate?: boolean;
  accountId?: string;
}

const ListSkeletonRow = () => (
  <Grid gridTemplateColumns="2fr 40px" alignItems="center" height={65}>
    <Skeleton width={120} height={28} />
    <Skeleton width={40} height={28} />
  </Grid>
);

const ListSkeleton = ({ children, alertIsLoading }: any) =>
  alertIsLoading ? (
    <>
      <ListSkeletonRow />
      <ListSkeletonRow />
      <ListSkeletonRow />
    </>
  ) : (
    children
  );

const OperationalOverview = ({
  followedOnly,
  selectedDates,
  selectedDateType,
  triggerUpdate,
  accountId,
}: Props) => {
  const { t } = useTranslation();
  const {
    notificationsIsLoading,
    notificationsSummaryData,
    entrivoDoorsCount,
    notificationsSummaryFetch,
  } = useNotificationStore();
  const totalAlert = notificationsSummaryData
    .filter((notification) => notification.type === NotificationType.Alert)
    .reduce((acc, alert) => acc + alert.value, 0);
  const groupedAlerts = formatDIOperationalAlertsOverviewData(
    notificationsSummaryData,
  );
  const totalModeChanges = notificationsSummaryData.filter(
    (notification) => notification.type === NotificationType.Mode,
  ).length;
  const groupedModeChanges = formatDIOperationalModeChangesOverviewData(
    notificationsSummaryData,
    selectedDateType,
  );
  const { doorsStatsData } = useDoorsStatsStore();
  const history = useHistory();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  // filter relevant configs for current alerts
  useEffect(() => {
    notificationsSummaryFetch(
      selectedDates,
      selectedDateType,
      followedOnly,
      undefined,
      accountId,
    );
  }, [selectedDates, triggerUpdate, followedOnly, accountId]);

  const navigateOnRowClick = (row: any) => {
    history.push({
      pathname: `/notifications`,
      search: `?accountId=${accountId}&type=${row.name}&startDate=${
        selectedDates.startDate
      }&endDate=${
        selectedDates.endDate
      }&followedOnly=${followedOnly}&dateType=${selectedDateType}&status=${
        selectedDateType === 'now' ? 'Active' : ''
      }&commissionedOrDecommissioned=true`,
      state: {
        fromPage: history.location.pathname,
        id: row.name,
        name: `${row.displayName}`,
      },
    });
  };

  return (
    <>
      <TabsWrapper>
        <Tabs>
          <TabList style={{ marginBottom: isMobile ? '20px' : '30px' }}>
            <Tab>{t('common:tabs.alerts')}</Tab>
            {shouldShowModeElement() && (
              <Tab>{t('common:tabs.mode_changes')}</Tab>
            )}
          </TabList>
          <TabPanel>
            {notificationsIsLoading ? (
              <ChartContainer
                childrenStyleProps={{ padding: 0 }}
                title={t('operationalOverview:title')}
                hideHeader={true}>
                <Container>
                  <DonutContainer>
                    <DonutChart
                      dataKey="value"
                      nameKey="label"
                      label={{
                        countValue: totalAlert,
                        i18nLabel: t('operationalOverview:donut_label'),
                      }}
                      isLoading={notificationsIsLoading}
                    />
                  </DonutContainer>
                  <ListContainer>
                    <List>
                      <ListSkeleton alertIsLoading />
                    </List>
                  </ListContainer>
                </Container>
              </ChartContainer>
            ) : (
              <ChartContainer
                childrenStyleProps={{ padding: 0 }}
                title={t('operationalOverview:title')}
                hideHeader={true}
                greenBorder={totalAlert === 0 && !notificationsIsLoading}>
                {totalAlert === 0 && !notificationsIsLoading ? (
                  <NoNotification
                    followedOnly={followedOnly}
                    type={NotificationType.Alert}
                  />
                ) : (
                  <Container>
                    <DonutContainer>
                      <DonutChart
                        isLoading={notificationsIsLoading}
                        data={totalAlert ? groupedAlerts : undefined}
                        dataKey="value"
                        nameKey="label"
                        label={{
                          countValue: totalAlert,
                          i18nLabel: t('operationalOverview:donut_label'),
                        }}
                      />
                    </DonutContainer>
                    <ListContainer>
                      <List>
                        <ListSkeleton alertIsLoading={notificationsIsLoading}>
                          {groupedAlerts.map(
                            (groupedAlert: any, index: number) => (
                              <GridItem
                                key={`${groupedAlert}_${index}}`}
                                groupedAlert={groupedAlert}
                                noBorder={index === groupedAlerts.length - 1}
                                navigateOnRowClick={navigateOnRowClick}
                              />
                            ),
                          )}
                        </ListSkeleton>
                      </List>
                    </ListContainer>
                  </Container>
                )}
              </ChartContainer>
            )}
          </TabPanel>
          <TabPanel>
            {notificationsIsLoading ? (
              <ChartContainer
                childrenStyleProps={{ padding: 0 }}
                title={t('operationalOverview:title')}
                hideHeader={true}>
                <Container>
                  <DonutContainer>
                    <DonutChart
                      dataKey="value"
                      nameKey="label"
                      isLoading={notificationsIsLoading}
                      type="pie"
                    />
                  </DonutContainer>
                  <ListContainer>
                    <List>
                      <ListSkeleton alertIsLoading />
                    </List>
                  </ListContainer>
                </Container>
              </ChartContainer>
            ) : (
              <ChartContainer
                childrenStyleProps={{ padding: 0 }}
                title={t('operationalOverview:title')}
                hideHeader={true}
                greenBorder={totalModeChanges === 0 && !notificationsIsLoading}>
                {totalModeChanges === 0 && !notificationsIsLoading ? (
                  <NoNotification
                    followedOnly={followedOnly}
                    type={NotificationType.Mode}
                  />
                ) : (
                  <Container>
                    <DonutContainer>
                      <DonutChart
                        isLoading={notificationsIsLoading}
                        data={totalModeChanges ? groupedModeChanges : undefined}
                        dataKey="value"
                        nameKey="label"
                        type="pie"
                      />
                    </DonutContainer>
                    <ListContainer>
                      <List>
                        <ListSkeleton alertIsLoading={notificationsIsLoading}>
                          {groupedModeChanges.map(
                            (groupedModeChange: any, index: number) => {
                              return (
                                <GridItem
                                  key={`${groupedModeChange}_${index}}`}
                                  groupedAlert={groupedModeChange}
                                  noBorder={
                                    index === groupedModeChanges.length - 1
                                  }
                                  navigateOnRowClick={navigateOnRowClick}
                                  valueSuffix={
                                    selectedDateType === 'now'
                                      ? groupedModeChange.value === 1
                                        ? ' ' +
                                          t('dashboard:door').toLowerCase()
                                        : ' ' +
                                          t('dashboard:doors').toLowerCase()
                                      : '%'
                                  }
                                />
                              );
                            },
                          )}
                          <Spacer height={10} />
                          <div
                            style={
                              shouldShowModeWarning()
                                ? {
                                    display: 'grid',
                                    gridTemplateColumns: '1.8fr 1fr',
                                  }
                                : { textAlign: 'right' }
                            }>
                            {selectedDateType === 'now' ? (
                              <div />
                            ) : (
                              <P size="small" color={theme.colors.blueGrey}>
                                {t('operationalOverview:rounding_disclaimer')}
                              </P>
                            )}
                            {shouldShowModeWarning() ? (
                              <ModeLabel variant="small">
                                {t('common:mode_label.home', {
                                  entrivoDoorsCount:
                                    doorsStatsData.entrivoCount,
                                  totalDoorsCount: doorsStatsData.totalCount,
                                })}
                              </ModeLabel>
                            ) : (
                              <></>
                            )}
                          </div>
                        </ListSkeleton>
                      </List>
                      {selectedDateType === 'now' ? (
                        <></>
                      ) : (
                        <div style={{ height: '100%', paddingTop: '10%' }}>
                          <ToolTip
                            content={t('common:mode_label.utilisation_tooltip')}
                            iconSize={20}
                            isInline={true}
                          />
                        </div>
                      )}
                    </ListContainer>
                  </Container>
                )}
              </ChartContainer>
            )}
          </TabPanel>
        </Tabs>
      </TabsWrapper>
    </>
  );
};

export default OperationalOverview;
