import React from 'react';
import { useTranslation } from 'react-i18next'

import { DoorStateKeyDiv, StyledDiv } from './tabContentLineItemStyle';

import { } from 'components';


interface Props {
  doorStateKey: React.ReactNode;
  doorStateValue: React.ReactNode;
}


const TabContentLineItem = ({ doorStateKey, doorStateValue }: Props) => {
  return <StyledDiv>
    <DoorStateKeyDiv>{doorStateKey}</DoorStateKeyDiv>
    <div>{doorStateValue}</div>
  </StyledDiv>;
};

export default TabContentLineItem;