import styled from '@emotion/styled';
import { theme, mq } from 'style';

const DESKTOP_MARGIN = 'margin: 0 45px;';
const MOBILE_MARGIN = 'margin: 0 25px;';

export const Container = styled.div`
  margin-top: 10px;

  ${mq.mobile()} {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

interface StyledRowProps {
  border?: boolean;
}

export const StyledRow = styled.div<StyledRowProps>`
  display: flex;
  box-sizing: border-box;
  border-bottom: ${({ border }) => border && theme.borders(1, 'lightBlueGrey')};
  ${DESKTOP_MARGIN}

  &:last-of-type {
    border-bottom: none;
  }

  ${mq.mobile()} {
    ${MOBILE_MARGIN};
  }
`;
