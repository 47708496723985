import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { theme, mq } from 'style';

export const StyledWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: ${theme.colors.white};
  border: ${theme.borders(1, 'lightBlueGrey')};
`;

//Typescript was being super weird.
export type SectionLayoutProps = 'single' | 'double' | 'triple' | 'inactive' | 'error';
export type PaddingSize = 'small' | 'large' | 'none';
interface SectionProps {
  layout: SectionLayoutProps;
  paddingSize: PaddingSize;
}
const applyPaddingSize = ({ paddingSize }: { paddingSize: PaddingSize }) => {
  if (paddingSize === 'small') {
    return css`
      padding: 35px 40px;
      ${mq.mobile()} {
        padding: 20px 30px;
        height: auto;
      }
    `;
  }
  if (paddingSize === 'large') {
    return css`
      padding: 40px 65px;
      ${mq.mobile()} {
        padding: 30px 30px;
      }
    `;
  }
  if (paddingSize === 'none') {
    return css`
      padding: 0;
      ${mq.mobile()} {
        padding: 0;
      }
    `;
  }
};
const sectionLayout = ({
  layout,
}: {
  layout: SectionLayoutProps;
}) => {
  switch (layout) {
    case 'single':
    case 'error':
      return css`
        grid-template-columns: repeat(1, 1fr);
      `;
    case 'double':
      return css`
        grid-template-columns: repeat(2, 1fr);
      `;
    case 'triple':
      return css`
        grid-template-columns: repeat(3, 1fr);
      `;
    case 'inactive':
      return css`
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 0px;
      `;
  }
};

export const StyledSection = styled.section<SectionProps>`
  border-bottom: ${theme.borders(1, 'lightBlueGrey')};
  &:last-of-type {
    border-bottom: none;
  }

  > div {
    display: grid;
    grid-column-gap: 30px;
    margin-bottom: 0px;
    ${!mq.mobile()} {
      ${props => props.layout === 'error' && `
        margin-bottom: 0;
      `}
    }

    ${sectionLayout};

    ${props => props.layout === 'error' && `
      color: ${theme.colors.alerts.generalFault};
    `}

    > hr {
      display: block;
      width: 100%;
      height: 1px;
      border: none;
      background-color: ${theme.colors.lightBlueGrey};
      margin: 40px 0;
      grid-column: 1 / -1;
    }
  }
  ${applyPaddingSize}
`;

export const StyledMobileLastSection = styled.div<SectionProps>`
  border-top: ${theme.borders(1, 'lightBlueGrey')};
  > div {
    display: grid;
    grid-column-gap: 30px;
    ${!mq.mobile()} {
      ${props => props.layout === 'error' && `
        margin-bottom: 0;
      `}
    }

    ${sectionLayout};

    ${props => props.layout === 'error' && `
      color: ${theme.colors.alerts.generalFault};
    `}

    > hr {
      display: block;
      width: 100%;
      height: 1px;
      border: none;
      background-color: ${theme.colors.lightBlueGrey};
      margin: 40px 0;
      grid-column: 1 / -1;
    }
  }
  ${applyPaddingSize}

  ${mq.mobile()} {
    border-top: ${theme.borders(1, 'lightBlueGrey')};
  }
`;

export const StyledSectionFullWidthContainer = styled.div`
  grid-column: 1 / -1;
`;

type BodyProps = {
  alignment: 'horizontal' | 'vertical';
  paddingSize?: 'small' | 'large';
};
const applyVertical = ({
  alignment,
}: {
  alignment: 'horizontal' | 'vertical';
}) => {
  if (alignment === 'vertical') {
    return css`
      display: flex;
    `;
  }
};

export const StyledBody = styled.div<BodyProps>`
  padding: 40px 65px;
  ${applyVertical};
  border-radius: 0 0 4px 4px;
`;

export const StyledHeader = styled.div<{ paddingSize: PaddingSize }>`
  ${applyPaddingSize};
  display: flex;
  border-radius: 4px 4px 0 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${theme.borders(1, 'lightBlueGrey')};
  ${mq.mobile()} {
    padding: 20px 25px;
    height: auto;
  }
`;

export const StyledSubmit = styled.div<{ paddingSize: PaddingSize }>`
  display: flex;
  justify-content: space-between;
  /* padding: 40px 65px 60px 65px; */
  border-top: ${theme.borders(1, 'lightBlueGrey')};
  ${applyPaddingSize}
`;

interface StyledInActiveProps {
  columns?: number;
  border?: boolean;
  contentVerticalLayout?: 'block' | 'flex';
}
export const StyledInActive = styled.div<StyledInActiveProps>`
  box-sizing: border-box;
  display: ${({contentVerticalLayout}) => contentVerticalLayout};
  flex-direction: column;
  justify-content: space-between;
  min-height: 80px;
  min-width: 50%;
  padding: 18px 0;
  border-bottom: ${({ border }) => border && theme.borders(1, 'lightBlueGrey')};

  &:nth-of-type(2n + 1):nth-last-of-type(-n + 2),
  &:nth-of-type(2n + 1):nth-last-of-type(-n + 2) ~ & {
    border-bottom: none;
  }
  ${mq.mobile()} {
    height: auto;
    padding-bottom: 20px;
    padding-top: 0;
    border-bottom: 0;
    min-height: 60px;
  }
`;

export const StyledRow = styled.div<StyledInActiveProps>`
  padding: 35px 40px;
  ${mq.mobile()} {
    padding: 20px 30px;
    height: auto;
  }
`;