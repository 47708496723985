import create from 'zustand';
import { fetchNotificationPreference, notificationPreference } from 'services';
import { NotificationPreference } from '../models';

interface NotificationPreferenceStore {
  notificationPreferenceData: NotificationPreference;
  isLoading: boolean;
  notificationPreferenceFetch: () => Promise<any>;
  notificationPreference: (notificationPreferenceData: NotificationPreference) => Promise<void>;
}

const [useNotificationPreferenceStore] = create<NotificationPreferenceStore>((set) => ({
  notificationPreferenceData: {} as NotificationPreference,
  isLoading: true,
  notificationPreferenceFetch: async () => {
    try {
      set({ isLoading: true });
      const res = await fetchNotificationPreference();
      set({
        notificationPreferenceData: res as NotificationPreference,
        isLoading: false,
      });
    } catch (err) {
      throw err;
    }
  },
  notificationPreference: async (notificationPreferenceData) => {
    try {
      set({ isLoading: true });
      await notificationPreference(notificationPreferenceData);
      set({
        notificationPreferenceData: notificationPreferenceData,
        isLoading: false,
      });
    } catch (err) {
      throw err;
    }
  },
}));

export default useNotificationPreferenceStore;
