import React from 'react';
import { Skeleton } from 'components';
import { useScreenSize } from 'hooks';

interface DonutChartSkeletonProps {
  type?: 'donut' | 'pie';
}

const DonutChartSkeleton = ({
  type = 'donut'
}: DonutChartSkeletonProps) => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  return (
    <Skeleton
      size={isMobile ? '280px' : '320px'}
      variant="circle"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      { type === 'donut' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            background: 'white',
            height: isMobile ? '200px' : '220px',
            width: isMobile ? '200px' : '220px',
            borderRadius: '100%',
          }}></div>
      )}
    </Skeleton>
  );
};
export default DonutChartSkeleton;
