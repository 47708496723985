import styled from '@emotion/styled';
import { mq, theme, flexCenterAll } from 'style';

export const SectionHeader = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-template-rows: 1fr;

  ${mq.tablet} {
    display: flex;
    flex-direction: column;
  }
  ${mq.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const SectionHeaderActions = styled.div`
  display: flex;
  ${mq.tablet} {
    margin-top: 15px;
  }
  ${mq.mobile} {
    margin-top: 15px;
  }

  ${mq.smallMobile} {
    display: block;
    button {
      width: 100%;
    }
    > p {
      margin-left: 0;
      margin-top: 15px;
    }
  }

  ${mq.largeTablet} {
    justify-content: flex-end;
  }
`


export const NoAlertContainer = styled.div<{isOffline: boolean}>`
  padding: 32px 40px;
  border: 1px solid ${props => props.isOffline ? theme.colors.lightBlueGrey : theme.colors.success};
  width: 100%;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.05);
  ${flexCenterAll};
  height: 100%;
`;
