import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Directory imports
import {
  HeaderStatus,
  DetailsSection,
  ActionsListButton,
  FollowSpace,
} from './components';
import { EditSpaceDetails } from './forms';
import {
  StatusDisplayModal,
  CalibrateOccupancyModal,
  ExportReportModal,
} from './modals';
import {
  SpaceDailyOccupancyStatus,
  SpaceCurrentOccupancy,
  HistoricOccupancyChart,
  DailyTraffic,
  MobileCharts,
  TrafficHistory,
} from './charts';
import {
  SectionHeader,
  SectionHeaderActions,
  TabsWrapper,
} from './spaceDetailsStyle';

// Src imports
import {
  Grid,
  Button,
  BreadCrumbs,
  Paragraph as P,
  Heading as H,
  IcomoonIcons,
  ChartContainer,
  PageHeader,
  Container,
  Flex,
  BasicError,
} from 'components';
import { useScreenSize, useTopBar } from 'hooks';
import { Spacer } from 'style';
import {
  useSpaceDetailsStore,
  useSpaceOccupancyStore,
  useWebSocketMessageStore,
  useModalStore,
} from 'stores';
import { fetchSpaceTrafficHistory } from 'services';
import {
  TrafficDevice,
  SpaceDetails as SpaceDetailsModel,
  User,
  SpaceTrafficHistory,
} from 'models';

import useTraffic from './hooks/useDailyTraffic';

import trafficHistoryMock from './charts/TrafficHistory/mock.json';

type Props = RouteComponentProps;

type UseParamsProps = {
  id: string;
};

const offlineDoorCount = (devices: TrafficDevice[]) => {
  return devices.reduce(
    (acc, cur: TrafficDevice) => (!cur.isOnline ? ++acc : acc),
    0,
  );
};

const SpaceDetails = ({}: Props) => {
  const { id: spaceId } = useParams<UseParamsProps>();
  const history = useHistory();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const { exportReportModal } = useModalStore();

  const [userMe, setUserMe] = useState<User>({} as User);
  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  const [isEditDetails, setIsEditDetails] = useState(false);
  const toggleEditDetails = () => {
    setIsEditDetails(!isEditDetails);
    window.scrollTo(0, 0);
  };

  const { t } = useTranslation();

  const {
    spaceDetailsData,
    isLoading,
    spaceDetailsFetch,
    spaceDetailsError,
    cleanUpSpaceDetailsData,
  } = useSpaceDetailsStore();

  const {
    todayTraffic,
    fetchTodaysTraffic,
    yesterdayTraffic,
    error: dailyTrafficHasError,
    activeDevice,
    setActiveDevice,
    trafficDirection,
    setTrafficDirection,
    datePeriod,
    setDatePeriod,
  } = useTraffic(spaceId);

  const [offlineDoors, setOfflineDoors] = useState<number>(0);
  useEffect(() => {
    if (todayTraffic.data?.devices) {
      const doorsCount = offlineDoorCount(todayTraffic.data.devices);
      setOfflineDoors(doorsCount);
    }
  }, [todayTraffic]);

  const { dailyOccupancyFetch } = useSpaceOccupancyStore();

  const { setLeftAction } = useTopBar();

  const fetchWrapper = (id: string) => {
    spaceDetailsFetch(id);
    dailyOccupancyFetch(id);
  };

  const [isLoadingTrafficHistory, setIsLoadingTrafficHistory] = useState<
    boolean
  >(true);
  const [trafficHistoryData, setTrafficHistoryData] = useState<
    SpaceTrafficHistory[]
  >([] as SpaceTrafficHistory[]);

  const trafficHistory = async () => {
    try {
      const res = await fetchSpaceTrafficHistory({ id: spaceId });
      setTrafficHistoryData(res);
      setIsLoadingTrafficHistory(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    trafficHistory();
    fetchWrapper(spaceId);
    setLeftAction(
      <div onClick={() => history.goBack()}>
        <IcomoonIcons icon="chevron-left" color="white" />
      </div>,
    );

    return () => {
      cleanUpSpaceDetailsData();
      setLeftAction(null);
    };
  }, []);

  const { lastMessage } = useWebSocketMessageStore();

  useEffect(() => {
    if (lastMessage) {
      const identifier = lastMessage?.Target?.Identifier;

      if (identifier && spaceId === identifier) {
        switch (lastMessage.Event) {
          case 'OccupancyChange':
            console.log(`Updating data for space ${spaceId}`);
            fetchWrapper(identifier);
            fetchTodaysTraffic(identifier);
            break;
          case 'OccupancyStatusChange':
            console.log('OccupancyStatusChange: ', identifier);
            fetchWrapper(identifier);
            fetchTodaysTraffic(identifier);
            break;
          case 'SpaceOffline':
            console.log('SpaceOffline: ', identifier);
            fetchWrapper(identifier);
            break;
          case 'SpaceOnline':
            console.log('SpaceOnline:', identifier);
            fetchWrapper(identifier);
            break;
          case 'SpaceCacheCleared':
            console.log('SpaceCacheCleared:', identifier);
            cleanUpSpaceDetailsData();
            spaceDetailsFetch(identifier);
            break;
        }
      }
    }
  }, [lastMessage]);

  return spaceDetailsError ? (
    <BasicError
      heading="Error 403: Unauthorized"
      subheading={t('spaceDetails:errors.unauthorized')}
      action={() => history.push('/home')}
      actionLabel={t('spaceDetails:errors.action_label')}
    />
  ) : (
    <>
      {!isMobile && (
        <BreadCrumbs>
          <Grid
            onClick={() => history.push('/spaces')}
            gridTemplateColumns="repeat(2, min-content)"
            gridColumnGap="8px">
            <IcomoonIcons icon="chevron-left" color="white" />
            <P fontWeight="bold" color="white">
              {t('spaceDetails:page_header.title')}
            </P>
          </Grid>
          {!isLoading && (
            <P color="white">{spaceDetailsData?.details?.spaceDisplayName}</P>
          )}
        </BreadCrumbs>
      )}

      <PageHeader
        heading={isLoading ? '' : spaceDetailsData?.details?.spaceDisplayName}
        isHeadingLoading={isLoading}
        fabActions={!isEditDetails}
        subComponent={
          <HeaderStatus
            details={spaceDetailsData || ({} as SpaceDetailsModel)}
          />
        }
        actionComponents={
          <Grid gridTemplateColumns="1fr 1fr" gridGap={20}>
            <FollowSpace
              spaceDetailsData={spaceDetailsData}
              isEditDetails={isEditDetails}
              isLoading={isLoading}
            />

            <ActionsListButton isDisabled={isEditDetails || isLoading} />
          </Grid>
        }
      />

      <Container>
        <SectionHeader>
          <H color="primary" size="h3" mb={0}>
            {t('doorDetails:overview')}
          </H>
          <SectionHeaderActions>
            {userMe.userRole === 'Admin' && (
              <Button
                variant="contained"
                icon="edit"
                onClick={exportReportModal.toggleModal}>
                {t('spaceDetails:modals.export_report.title')}
              </Button>
            )}
          </SectionHeaderActions>
        </SectionHeader>
        <Spacer height={20} />

        {isEditDetails ? (
          <EditSpaceDetails
            data={spaceDetailsData}
            toggleEditDetails={toggleEditDetails}
          />
        ) : (
          <>
            <Grid
              gridTemplateColumns={['1fr', 'repeat(2, 1fr)']}
              gridRowGap={[30, 0]}
              gridColumnGap={[0, 20]}>
              <ChartContainer
                title={t('spaceDetails:charts.current_occupancy')}>
                <SpaceCurrentOccupancy
                  data={spaceDetailsData}
                  isLoading={isLoading}
                />
              </ChartContainer>

              <DetailsSection
                data={spaceDetailsData}
                isLoading={isLoading}
                toggleEdit={
                  !isLoading && userMe?.userRole === 'Admin'
                    ? toggleEditDetails
                    : undefined
                }
              />
            </Grid>

            {spaceDetailsData?.details?.commissionStatus ? (
              <>
                <Flex justifyContent="space-between" alignItems="center">
                  <H size="h3" color="primary" mt={'40px'} mb={'20px'}>
                    {t('spaceDetails:sections.insights')}
                  </H>
                </Flex>

                <TabsWrapper>
                  <Tabs>
                    <TabList
                      style={{ marginBottom: isMobile ? '20px' : '30px' }}>
                      <Tab>{t('spaceDetails:charts.tabs.compliance')}</Tab>
                      <Tab>{t('spaceDetails:charts.tabs.occupancy')}</Tab>
                      <Tab>
                        <Flex alignItems="center">
                          <span style={{ marginRight: 10 }}>
                            {t('spaceDetails:charts.tabs.doors')}
                          </span>
                          {offlineDoors > 0 && (
                            <>
                              <IcomoonIcons
                                icon="alert-triangle"
                                size={16}
                                color="error"
                              />
                              <span style={{ marginLeft: 5 }}>
                                {offlineDoors}
                              </span>
                            </>
                          )}
                        </Flex>
                      </Tab>
                    </TabList>
                    <TabPanel>
                      {isMobile ? (
                        <MobileCharts />
                      ) : (
                        <>
                          <SpaceDailyOccupancyStatus />
                          <Spacer height={30} />
                          <HistoricOccupancyChart />
                        </>
                      )}
                    </TabPanel>

                    <TabPanel>
                      <TrafficHistory
                        data={trafficHistoryData}
                        isLoading={isLoadingTrafficHistory}
                      />
                    </TabPanel>
                    <TabPanel>
                      <DailyTraffic
                        todayTraffic={todayTraffic}
                        yesterdayTraffic={yesterdayTraffic}
                        error={dailyTrafficHasError}
                        activeDevice={activeDevice}
                        setActiveDevice={setActiveDevice}
                        trafficDirection={trafficDirection}
                        setTrafficDirection={setTrafficDirection}
                        datePeriod={datePeriod}
                        setDatePeriod={setDatePeriod}
                        fetchTodaysTraffic={fetchTodaysTraffic}
                      />
                    </TabPanel>
                  </Tabs>
                </TabsWrapper>
              </>
            ) : null}
          </>
        )}
      </Container>

      {/* MODALS */}
      <StatusDisplayModal spaceId={spaceId} />
      <CalibrateOccupancyModal spaceId={spaceId} />
      <ExportReportModal
        spaceId={spaceId}
        userId={userMe.id}
        commissionedAt={spaceDetailsData?.details?.commissionedAt}
        isNetworkLevelReport={false}
      />
    </>
  );
};
export default SpaceDetails;
