import React, { useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { followDoor, unfollowDoor } from 'services';
import {
  Box,
  Grid,
  IcomoonIcons,
  Paragraph as P,
  MobileTableComponents,
  Spinner,
  Skeleton,
} from 'components';
import { Status } from '../CellComponents';
import { DoorSummary } from 'models';
import { ColumnState } from 'components/Table/Table';
import { isDIS } from 'utils';

interface Props {
  row: any;
  onRowClick?: (row: any) => void;
  navigateOnRowClick: (id: any) => void;
}

const DoorListItem = ({ navigateOnRowClick, onRowClick, row }: Props) => {
  const { t } = useTranslation();
  const isFollowing: boolean = row.original.isFollowing;
  const id: string = row.original.id;
  const doorSummary: DoorSummary = row.original;
  const {
    name,
    type,
    lastUpdateTime,
    alertCount,
    account,
    isOffline,
  } = doorSummary;

  const [isLoading, setIsLoading] = useState(false);
  const [following, setFollowing] = useState(isFollowing);

  const toggleIsFollowing = async (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!isLoading) {
      setIsLoading(true);
      const status = !following ? await followDoor(id) : await unfollowDoor(id);

      if (status === 200) {
        setFollowing(!following);
      }
      setIsLoading(false);
    }
  };

  return (
    <MobileTableComponents.Wrapper
      key={doorSummary.id}
      onClick={() => {
        if (onRowClick) {
          onRowClick(doorSummary);
        } else {
          navigateOnRowClick(doorSummary);
        }
      }}>
      <MobileTableComponents.Item truncate column="1 / span 3">
        <P
          size="small"
          color="charcoal"
          marginBottom={isDIS() ? '0px' : '10px'}>
          {name}
        </P>
        {isDIS() && (
          <P size="tiny" color="charcoal" marginBottom="10px">
            {account.name}
          </P>
        )}
      </MobileTableComponents.Item>

      <Grid gridColumn={4} gridRow={1}>
        <Box
          onClick={toggleIsFollowing}
          outlined={false}
          backgroundColor={following ? 'lightBlueGrey' : 'lightGrey'}
          p="2px 6px">
          <Grid
            gridTemplateColumns="repeat(2, min-content)"
            alignItems="center"
            gridColumnGap="3px">
            {isLoading ? (
              <Box
                width="42px"
                padding="0"
                backgroundColor="transparent"
                border="none">
                <Spinner
                  diameter="13px"
                  margin="0 auto"
                  color={isFollowing ? 'primary' : 'charcoal'}
                />
              </Box>
            ) : (
              <>
                <IcomoonIcons
                  icon={following ? 'favourite-filled' : 'favourite'}
                  size={10}
                  color={following ? 'primary' : 'charcoal'}
                />
                <P
                  color={following ? 'primary' : 'charcoal'}
                  fontWeight="bold"
                  style={{ fontSize: '10px', lineHeight: '15px' }}>
                  Follow
                </P>
              </>
            )}
          </Grid>
        </Box>
      </Grid>

      <MobileTableComponents.Item column="span 4">
        <Grid
          gridTemplateColumns="1fr 1fr 1.5fr 24px"
          gridColumnGap="20px"
          alignItems="center"
          gridTemplateRows="repeat(4, max-content)">
          <MobileTableComponents.ColumnHeader row={3}>
            {t('doorList:table.column.alerts')}
          </MobileTableComponents.ColumnHeader>
          <MobileTableComponents.Item row={4}>
            <Status alertCount={alertCount} isMobile isOffline={isOffline} />
          </MobileTableComponents.Item>
          <MobileTableComponents.ColumnHeader row={3} column={2}>
            {t('doorList:table.column.type')}
          </MobileTableComponents.ColumnHeader>
          <MobileTableComponents.Item truncate column={2} row={4}>
            <P size="tiny">{type}</P>
          </MobileTableComponents.Item>
          <MobileTableComponents.ColumnHeader row={3} column={3}>
            {t('doorList:table.column.last_update')}
          </MobileTableComponents.ColumnHeader>
          <MobileTableComponents.Item truncate row={4} column={3}>
            <P size="tiny">
              {lastUpdateTime === ColumnState.LOADING ? (
                <Skeleton width="40px" height="12px"></Skeleton>
              ) : (
                moment(lastUpdateTime).format('DD/MM/YYYY HH:mm:ss')
              )}
            </P>
          </MobileTableComponents.Item>
          <Grid
            onClick={() => {
              navigateOnRowClick(doorSummary);
            }}
            gridColumn={4}
            gridRow={'3 / span 2'}
            alignSelf="end"
            justifySelf="end">
            <IcomoonIcons icon="chevron-right" />
          </Grid>
        </Grid>
      </MobileTableComponents.Item>
    </MobileTableComponents.Wrapper>
  );
};

export default DoorListItem;
