import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Label,
} from 'recharts';
import { ChartToolTip, Paragraph as P, Flex } from 'components';
import DonutChartLabel from './DonutChartLabel/DonutChartLabel';
import DonutChartSkeleton from './Skeleton/Skeleton';
import { theme } from 'style';

interface LabelProps {
  countValue: any;
  i18nLabel: string;
}

interface DonutChartProps {
  isLoading: boolean;
  data?: any;
  nameKey: string;
  dataKey: string;
  label?: LabelProps;
  type?: 'donut' | 'pie';
}

const PieChartToolTip = ({
  active,
  payload,
}: {
  active?: any;
  payload?: any;
  label?: any;
  coordinate?: any;
}) => {
  if (active) {
    const tooltipData = payload[0];
    const tooltipValue = tooltipData?.value || '';
    let tooltipLegend = '';
    let tooltipValueSuffix = '';

    if (tooltipData.payload) {
      tooltipLegend =
        tooltipData.payload.displayName ||
        tooltipData.payload.name ||
        tooltipData.payload.alertId;
      tooltipValueSuffix = tooltipData.payload?.valueSuffix || '';
    }

    return (
      <ChartToolTip>
        <P fontSize="small" bold color={theme.colors.primary}>
          {tooltipLegend}: {tooltipValue}
          {tooltipValueSuffix}
        </P>
      </ChartToolTip>
    );
  } else {
    return null;
  }
};

const DonutChart = ({
  data,
  nameKey,
  dataKey,
  label,
  isLoading,
  type = 'donut',
}: DonutChartProps) => {
  const { t } = useTranslation();

  return (
    <ResponsiveContainer height={320} width="95%">
      {isLoading ? (
        <Flex alignItems="center" justifyContent="center">
          <DonutChartSkeleton type={type} />
        </Flex>
      ) : (
        <PieChart>
          <Pie
            isAnimationActive={false}
            nameKey={nameKey}
            dataKey={dataKey}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={type === 'donut' ? '70%' : 0}
            outerRadius={'100%'}
            stroke=""
            startAngle={90}
            endAngle={-270}
            fill="black">
            {data?.map((entry: any, index: number) => {
              const fillingColor = theme.colors[entry.color] || entry.color;
              return <Cell key={index} fill={fillingColor} />;
            })}
            {label && (
              <Label
                position="center"
                content={
                  <DonutChartLabel
                    countValue={label.countValue}
                    sublabel={t(label.i18nLabel)}
                  />
                }
              />
            )}
          </Pie>

          <Tooltip content={<PieChartToolTip />} isAnimationActive={false} />
        </PieChart>
      )}
    </ResponsiveContainer>
  );
};

export default DonutChart;
