import styled from '@emotion/styled';
import { theme, mq } from 'style';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 25px 25px 50px;
  background-color: ${theme.colors.aliceBlue};
  box-shadow: 0px 3px 24px 0px #0000000d;
  border: 1px solid ${theme.colors.lightBlueGrey};
  border-top: none;

  ${mq.mobile()} {
    padding: 10px 25px 20px 25px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ItemHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const Item = styled.div`
  padding: 0 25px;
  border-right: 2px solid ${theme.colors.lightBlueGrey};

  &:last-child {
    border-right: none;
  }

  ${mq.mobile()} {
    padding: 20px 0;
    border-right: none;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid ${theme.colors.lightBlueGrey};

    &:last-child {
      border-bottom: none;
    }
  }
`;

export const ItemCount = styled.p`
  font-size: 35px;
  line-height: 52.5px;
  font-weight: 200;
  color: ${theme.colors.charcoal};
`;

export const MobileFlare = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 2px;
  margin-right: 10px;
  background-color: ${theme.colors.primary};
`;
