import moment from 'moment';
import { SpaceTrafficHistory, HourlyData } from 'models';

const formatChartData = (data: SpaceTrafficHistory[]) => {
  const isLowestNegative = (value: number) => (value < 0 ? 0 : value);

  const mapped = data.map(({ date, hourlyData }) => {
    const dayString = moment(date).format('dddd');

    return hourlyData.map((item: HourlyData) => {
      const currentTime = moment(new Date()).toISOString();
      const isFuture = moment(item.startTime).isSameOrAfter(currentTime);

      return {
        default: item.startTime,
        from: moment(item.startTime).format('HH:mm:ss'),
        [dayString]: {
          average: isFuture ? null : isLowestNegative(item.average),
          high: isFuture ? null : isLowestNegative(item.high),
          low: isFuture ? null : isLowestNegative(item.low),
        },
      };
    });
  });

  // https://stackoverflow.com/a/38505639/1795856
  const merged: any = [];

  mapped.forEach(
    ((hash) => (a: any) =>
      a.forEach((b: any) => {
        if (b !== undefined) {
          if (!hash[b.from]) {
            hash[b.from] = {};
            merged.push(hash[b.from]);
          }
          Object.assign(hash[b.from], b);
        }
      }))(Object.create(null)),
  );

  return merged.sort((a: any, b: any) => a.from.localeCompare(b.from));
};

export default formatChartData;
