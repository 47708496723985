import React, { useState, useEffect } from 'react';
import { localPoint } from '@vx/event';

import { DataSetType } from '../SpaceHistoricOccupancy';
import { theme } from 'style';

import { generateBarPath, isTopBar, isBottomBar } from './utils';

type BarType = {
  x: number;
  y: number;
  height: number;
  color: string;
  index: number;
  key: string;
  width: number;
  bar: {
    0: number;
    1: number;
    data: DataSetType;
  };
};

type BarStackType = {
  index: number;
  key: string;
  bars: BarType[];
};

type BarProps = {
  bar: BarType;
  barStack: BarStackType;
  showTooltip: ({
    tooltipLeft,
    tooltipTop,
    tooltipData,
  }: {
    tooltipLeft: number;
    tooltipTop: number;
    tooltipData: BarType;
  }) => void;
  hideTooltip: () => void;
  tooltipOpen: boolean;
  tooltipData: any;
};

export const Bar = ({
  bar,
  barStack,
  showTooltip,
  hideTooltip,
  tooltipOpen,
  tooltipData,
}: BarProps) => {
  const [opacity, setOpacity] = useState<'initial' | number>('initial');

  useEffect(() => {
    if (tooltipOpen) {
      setOpacity(
        tooltipData.bar.data.startDate === bar.bar.data.startDate
          ? 'initial'
          : 0.5,
      );
    } else {
      setOpacity('initial');
    }
  }, [tooltipOpen]);

  const handleMouseOver = (event: any, bar: BarType) => {
    const coords = localPoint(event.target.ownerSVGElement, event!);
    showTooltip({
      tooltipLeft: coords!.x,
      tooltipTop: coords!.y,
      tooltipData: bar,
    });
  };

  return (
    <path
      onClick={() => console.log(bar)}
      key={`bar-stack-${barStack.index}-${bar.index}`}
      d={generateBarPath(
        bar.x,
        bar.y,
        10,
        bar.height,
        5,
        isTopBar(bar.key, bar.bar.data),
        isTopBar(bar.key, bar.bar.data),
        isBottomBar(bar.key, bar.bar.data),
        isBottomBar(bar.key, bar.bar.data),
      )}
      style={{ transition: 'all 0.25s ease-out' }}
      onMouseLeave={hideTooltip}
      onMouseOver={(event) => {
        handleMouseOver(event, bar);
      }}
      fillOpacity={bar.height === 0 ? 0 : opacity}
      strokeOpacity={bar.height === 0 ? 0 : opacity}
      fill={bar.color || 'transparent'}
      stroke={!bar.color ? theme.colors.lightBlueGrey : 'none'}
    />
  );
};
export default Bar;
