import { DataSetType } from '../SpaceHistoricOccupancy';

export const generateBarPath = (
  x: number,
  y: number,
  w: number,
  h: number,
  r: number,
  tl: boolean,
  tr: boolean,
  bl: boolean,
  br: boolean,
) => {
  let path;
  path = 'M' + (x + r) + ',' + y;
  path += 'h' + (w - 2 * r);
  if (tr) {
    path += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + r;
  } else {
    path += 'h' + r;
    path += 'v' + r;
  }
  path += 'v' + (h - 2 * r);
  if (br) {
    path += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + r;
  } else {
    path += 'v' + r;
    path += 'h' + -r;
  }
  path += 'h' + (2 * r - w);
  if (bl) {
    path += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + -r;
  } else {
    path += 'h' + -r;
    path += 'v' + -r;
  }
  path += 'v' + (2 * r - h);
  if (tl) {
    path += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + -r;
  } else {
    path += 'v' + -r;
    path += 'h' + r;
  }
  path += 'z';
  return path;
};

//functions that determine whether a bar needs a rounded top/bottom
export const isTopBar = (bar: string, dataSet: DataSetType) => {
  switch (bar) {
    case 'exceededPct':
      return (
        dataSet.withinPct === 0 &&
        dataSet.approachingPct === 0 &&
        dataSet.offlinePct === 0 &&
        dataSet.futurePct === 0 &&
        dataSet.notCommissionedPct === 0
      );
    case 'withinPct':
      return (
        dataSet.offlinePct === 0 &&
        dataSet.futurePct === 0 &&
        dataSet.notCommissionedPct === 0
      );
    case 'approachingPct':
      return (
        dataSet.approachingPct === 0 &&
        dataSet.offlinePct === 0 &&
        dataSet.futurePct === 0 &&
        dataSet.notCommissionedPct === 0
      );
    case 'offlinePct':
      return dataSet.futurePct === 0 && dataSet.notCommissionedPct === 0;
    case 'futurePct':
      return dataSet.notCommissionedPct === 0;
    default:
      return true;
  }
};
export const isBottomBar = (bar: string, dataSet: DataSetType) => {
  switch (bar) {
    case 'notCommissionedPct':
      return (
        dataSet.withinPct === 0 &&
        dataSet.approachingPct === 0 &&
        dataSet.exceededPct === 0 &&
        dataSet.offlinePct === 0 &&
        dataSet.futurePct === 0
      );
    case 'futurePct':
      return (
        dataSet.withinPct === 0 &&
        dataSet.approachingPct === 0 &&
        dataSet.exceededPct === 0 &&
        dataSet.offlinePct === 0
      );

    case 'offlinePct':
      return (
        dataSet.withinPct === 0 &&
        dataSet.approachingPct === 0 &&
        dataSet.exceededPct === 0
      );
    case 'withinPct':
      return dataSet.exceededPct === 0 && dataSet.approachingPct === 0;
    case 'approachingPct':
      return dataSet.exceededPct === 0;
    default:
      return true;
  }
};
