import { IdValue, NotificationType } from 'models';
import i18n from 'i18next';

export interface FilterTranslations {
  [key: string]: string;
}

export const statusTranslations: FilterTranslations = {
  exceeding: i18n.t('currentOccupancy:grid.exceeding'),
  approaching: i18n.t('currentOccupancy:grid.approaching'),
  within: i18n.t('currentOccupancy:grid.within'),
  offline: i18n.t('currentOccupancy:grid.offline'),
  notcommissioned: i18n.t('currentOccupancy:notCommissioned'),
};

export const typeTranslations: FilterTranslations = {
  'Trial / Test': i18n.t('spacesList:table.filters.type_filters.trial'),
  Library: i18n.t('spacesList:table.filters.type_filters.library'),
  Retail: i18n.t('spacesList:table.filters.type_filters.retail'),
  'Restaurant / Cafeteria': i18n.t(
    'spacesList:table.filters.type_filters.restaurant',
  ),
  'Place of Worship': i18n.t('spacesList:table.filters.type_filters.worship'),
  'Meeting Room': i18n.t('spacesList:table.filters.type_filters.meeting'),
  Office: i18n.t('spacesList:table.filters.type_filters.office'),
  'Workshop / Factory': i18n.t(
    'spacesList:table.filters.type_filters.workshop',
  ),
  Showroom: i18n.t('spacesList:table.filters.type_filters.showroom'),
  'Conference Room': i18n.t('spacesList:table.filters.type_filters.conference'),
  Other: i18n.t('spacesList:table.filters.type_filters.other'),
};

export const userStatusTranslations: FilterTranslations = {
  Active: i18n.t('userDetails:page_header.status.active'),
  Inactive: i18n.t('userDetails:page_header.status.inactive'),
  Pending: i18n.t('userDetails:page_header.status.pending'),
  VerificationRequired: i18n.t(
    'userDetails:page_header.status.verification_required',
  ),
};

export const roleTranslations: FilterTranslations = {
  Admin: i18n.t('userDetails:roles.admin'),
  Viewer: i18n.t('userDetails:roles.viewer'),
};

export const alertTranslations: FilterTranslations = {
  Active: i18n.t('alerts:status.active'),
  Inactive: i18n.t('alerts:status.inactive'),
};

export const notificationTypeTranslations: FilterTranslations = {
  'All Types': i18n.t('alerts:notification_type.all_types'),
  [NotificationType.Alert]: i18n.t('alerts:notification_type.all_alerts'),
  [NotificationType.Mode]: i18n.t('alerts:notification_type.mode'),
  [NotificationType.CriticalAlert]: i18n.t(
    'alerts:notification_type.criticalAlert',
  ),
  [NotificationType.GeneralAlert]: i18n.t(
    'alerts:notification_type.generalAlert',
  ),
};

export const applyFilterTranslations = (
  res: IdValue[],
  trans: FilterTranslations,
) => {
  return res.map((item: IdValue) => ({
    label: trans[item.id],
    value: item.value,
  }));
};

export const displaySpaceTypeTranslation = (spaceType: string) =>
  typeTranslations[spaceType] ??
  i18n.t('spacesList:table.filters.type_filters.other');

export const OIScopeTranslations: FilterTranslations = {
  All: i18n.t('forms:communication_preferences.typesOI.all'),
  Following: i18n.t('forms:communication_preferences.typesOI.following'),
  'N/A': i18n.t('forms:communication_preferences.frequencies.none'),
};

export const DIScopeTranslations: FilterTranslations = {
  All: i18n.t('forms:communication_preferences.typesDI.all'),
  Following: i18n.t('forms:communication_preferences.typesDI.following'),
  'N/A': i18n.t('forms:communication_preferences.frequencies.none'),
};
