import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Radio, FormComponents, NoMobileWarning } from 'components';
import { LabelValue } from 'models';

interface Props {
  notificationScopes: LabelValue[];
  doesUserWantNotifications: boolean;
  showMobileWarningOI: boolean;
}

export const Form = ({
  notificationScopes,
  doesUserWantNotifications,
  showMobileWarningOI,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FormComponents.Section
      layout="single"
      heading={t('profile:communication_preferences.heading')}>
      <Radio
        label={t('profile:communication_preferences.notifications.what')}
        name="notificationPreferences.what"
        radioItems={notificationScopes}
      />
      {doesUserWantNotifications && (
        <>
          <Checkbox
            label={t('profile:communication_preferences.notifications.when')}
            name="notificationPreferences.when"
            checkboxItems={[
              {
                label: t(
                  'forms:communication_preferences.frequencies.exceeding',
                ),
                value: 'alertExceeded',
              },
              {
                label: t(
                  'forms:communication_preferences.frequencies.approaching',
                ),
                value: 'alertApproaching',
              },
            ]}
          />

          <Checkbox
            label={t('profile:communication_preferences.notifications.how')}
            name="notificationPreferences.how"
            checkboxItems={[
              {
                label: t('forms:communication_preferences.methods.sms'),
                value: 'sms',
              },
              {
                label: t('forms:communication_preferences.methods.email'),
                value: 'email',
              },
            ]}
          />
          {showMobileWarningOI && <NoMobileWarning isProfile />}
        </>
      )}
    </FormComponents.Section>
  );
};
