import React from 'react';

import { Wrapper } from './basicErrorStyle';
import { Button, Heading as H, Paragraph as P } from 'components';

interface Props {
  heading: string;
  subheading: string;
  action?: any;
  actionLabel?: string;
  shrink?: boolean;
}

const BasicError = ({
  heading,
  subheading,
  action,
  actionLabel,
  shrink = false,
}: Props) => {
  return (
    <Wrapper shrink={shrink}>
      <H size="h3" color="primary" mb="13px">
        {heading}
      </H>
      <P color="charcoal" mb="38px">
        {subheading}
      </P>
      {action && <Button onClick={action}>{actionLabel}</Button>}
    </Wrapper>
  );
};

export default BasicError;
