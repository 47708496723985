import styled from '@emotion/styled';
import { ThemeType, flexVertical, mq } from 'style';

type ContainerProps = {
  theme: ThemeType;
};

export const Header = styled.div<ContainerProps>`
  ${flexVertical};
  padding: 40px;
  height: 110px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightBlueGrey};
  display: flex;
  justify-content: space-between;
  ${mq.mobile()} {
    height: auto;
    padding: 20px 25px;
  }
`;

export const Children = styled.div`
  padding: 40px;
  ${mq.mobile()} {
    padding: 20px 25px;
  }
`;
