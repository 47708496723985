import React from 'react';
import {
  Container,
  CriticalHeading,
  GeneralHeading,
  MobileFlare,
} from './gridItemStyle';
import { Paragraph as P, Grid, IcomoonIcons } from 'components';
import { NotificationType } from 'models';
import i18n from 'core/i18n/i18n';

interface Props {
  groupedAlert: {
    name: string;
    color: string;
    value: number;
    displayName: string;
    isCritical: boolean;
    type: NotificationType;
  };
  noBorder?: boolean;
  valueSuffix?: string;
  navigateOnRowClick: (id: any) => void;
}

const GridItem = ({
  groupedAlert,
  noBorder,
  valueSuffix,
  navigateOnRowClick,
}: Props) => {
  const { color, value, name, displayName, isCritical, type } = groupedAlert;

  return (
    <Container
      data-cypress="current-occupancy-chart-nav"
      style={{ border: noBorder ? 'none' : 'auto' }}
      onClick={() => {
        navigateOnRowClick(groupedAlert);
      }}>
      <Grid
        alignItems="center"
        gridTemplateColumns="min-content 1fr max-content min-content"
        width="100%">
        <MobileFlare color={color} />
        <P
          size="normal"
          color="primary"
          bold
          style={{ wordBreak: 'break-word' }}>
          {displayName?.toString() || name}{' '}
          {type === NotificationType.Alert &&
            (isCritical ? (
              <CriticalHeading>
                {i18n.t('alerts:names.criticalAlertTitle')}
              </CriticalHeading>
            ) : (
              <GeneralHeading>
                {i18n.t('alerts:names.generalAlertTitle')}
              </GeneralHeading>
            ))}
        </P>
        {value === 0 ? (
          <P size="normal" color="charcoal" mr="15px">
            {value}
            {valueSuffix && `${valueSuffix}`}
          </P>
        ) : (
          <P size="normal" color="charcoal" mr="15px" bold>
            {value}
            {valueSuffix && `${valueSuffix}`}
          </P>
        )}
        <IcomoonIcons icon="chevron-right" />
      </Grid>
    </Container>
  );
};

export default GridItem;
