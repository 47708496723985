import styled from '@emotion/styled';
import { theme, mq } from 'style';

export type ActionListButtonProps = {
  itemCount: number;
};

export const StyledWrapper = styled.div<ActionListButtonProps>`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: stretch;
  > div {
    z-index: 700;
    position: absolute;
    border-radius: 4px;
    background-color: ${theme.colors.white};
    /* top: calc(100% + 15px); */
    /* right: 0; */
    top: 60px;
    right: 0;
    padding: 20px 25px 0px 25px;
    white-space: nowrap;
    box-shadow: ${theme.boxShadow};
    cursor: pointer;

    ${mq.mobile()} {
      top: calc(-90% * ${(props) => props.itemCount} - 20px);
    }
  }
`;
