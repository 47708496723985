import { IcomoonIconNames } from 'components/IcomoonIcons/IcomoonIcons';
import { StatusEnum } from 'models';

interface Labels {
  current: string;
  historic: string;
}
interface Keys {
  limit: string;
  percent: string;
}
export interface CurrentOccupancyDataObject {
  name: string;
  filterName: StatusEnum | null;
  key: Keys;
  label: Labels;
  color: string;
  icon: IcomoonIconNames;
  count: number;
  doorDisplay: string;
}

export const occupancyChartConfig: CurrentOccupancyDataObject[] = [
  {
    name: 'exceed',
    filterName: 'Exceeding',
    key: {
      limit: 'exceedingLimit',
      percent: 'exceededPct',
    },
    label: {
      current: 'currentOccupancy:grid.exceeding',
      historic: 'historicOccupancy:legend.exceeded',
    },
    color: 'error',
    icon: 'error',
    count: 0,
    doorDisplay: 'doorDisplay:display_message.exceed',
  },
  {
    name: 'approach',
    filterName: 'Approaching',
    key: {
      limit: 'approachingLimit',
      percent: 'approachingPct',
    },
    label: {
      current: 'currentOccupancy:grid.approaching',
      historic: 'historicOccupancy:legend.approached',
    },
    color: 'warning',
    icon: 'alert-1',
    count: 0,
    doorDisplay: 'doorDisplay:display_message.approach',
  },
  {
    name: 'within',
    filterName: 'Within',
    key: {
      limit: 'withinLimit',
      percent: 'withinPct',
    },
    label: {
      current: 'currentOccupancy:grid.within',
      historic: 'historicOccupancy:legend.within',
    },
    color: 'success',
    icon: 'success',
    count: 0,
    doorDisplay: 'doorDisplay:display_message.within',
  },
  {
    name: 'offline',
    filterName: 'Offline',
    key: {
      limit: 'spacesOffline',
      percent: 'offlinePct',
    },
    label: {
      current: 'currentOccupancy:grid.offline',
      historic: 'historicOccupancy:legend.offline',
    },
    color: 'grey',
    icon: 'offline',
    count: 0,
    doorDisplay: 'doorDisplay:display_message.offline',
  },
  {
    name: 'future',
    filterName: null,
    key: {
      percent: 'futurePct',
      limit: 'futurePct',
    },
    label: {
      current: 'future',
      historic: 'future',
    },
    color: 'transparent',
    icon: 'offline',
    count: 0,
    doorDisplay: 'na',
  },
  {
    name: 'notCommissioned',
    filterName: null,
    key: {
      percent: 'notCommissionedPct',
      limit: 'notCommissionedPct',
    },
    label: {
      current: 'Not Commissioned',
      historic: 'historicOccupancy:legend.notCommissioned',
    },
    color: 'transparent',
    icon: 'offline',
    count: 0,
    doorDisplay: 'na',
  },
];
