import styled from '@emotion/styled';
import { flexCenter, theme } from 'style';

type StyledDivProps = {
  isInline: boolean;
}

export const StyledDiv = styled.div<StyledDivProps>`
  position: relative;
  cursor: pointer;
  ${({ isInline }) =>
    isInline ? 'display: inline': flexCenter};  
`;

type ContentPopupProps = {
  height: number;
  position: ClientRect;
};

export const ContentPopup = styled.div<ContentPopupProps>`
  position: absolute;
  left: ${({ position }) => position.left}px;
  top: ${({ position, height }) =>
    position.top + window.scrollY - height - 15}px;
  transform: ${({ position }) =>
    `translateX(calc(-50% + ${position.width / 2 - 2}px))`};
  padding: 9px 18px;
  font-size: 14px;
  line-height: 21px;
  opacity: ${({ height }) => (height === 0 ? 0 : 1)};
  color: white;
  z-index: 99;
  max-width: 250px;
  border-radius: 2px;
  background-color: ${theme.colors.primary};
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.05);
`;

export const Triangle = styled.div`
  height: 14px;
  width: 14px;
  left: 50%;
  transform: rotate(45deg) translateX(-50%);
  background: ${theme.colors.primary};
  position: absolute;
  bottom: -7px;
`;

export const SpaceBetweenPopupAndIcon = styled.div`
  height: 30px;
  width: 250px;
  left: 0;
  position: absolute;
  bottom: -15px;
`;
