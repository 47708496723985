import create from 'zustand';
import { fetchAlerts, fetchActiveAlerts } from 'services';
import { Alert, NotificationGroup, NotificationType, SelectedDates } from '../models';

interface AlertStore {
  alertData: Alert[];
  activeAlertData: NotificationGroup[];
  alertIsLoading: boolean;
  activeAlertIsLoading: boolean;
  activeAlertFetch: (followedOnly: boolean, doorId?: string) => void;
  alertFetch: (
    selectedDates: SelectedDates,
    followedOnly: boolean,
    doorId?: string,
    accountId?: string,
  ) => void;
}

const [useAlertStore] = create<AlertStore>((set) => ({
  alertData: [] as Alert[],
  activeAlertData: [] as NotificationGroup[],
  alertIsLoading: true,
  activeAlertIsLoading: true,
  alertFetch: async (selectedDates, followedOnly, doorId, accountId) => {
    try {
      set({ alertIsLoading: true });
      const res = await fetchAlerts(
        selectedDates.startDate as string,
        selectedDates.endDate as string,
        followedOnly,
        doorId,
        accountId,
      );
      const alerts = await res;

      set({
        alertData: alerts as Alert[],
        alertIsLoading: false,
      });
    } catch (err) {
      set({
        alertData: [] as Alert[],
        alertIsLoading: false,
      });
      throw err;
    }
  },
  activeAlertFetch: async (followedOnly, doorId) => {
    try {
      set({ activeAlertIsLoading: true });
      const res = await fetchActiveAlerts(followedOnly, doorId);
      const alerts = await res;

      set({
        activeAlertData: alerts?.filter(alert => alert.type === NotificationType.Alert) as NotificationGroup[],
        activeAlertIsLoading: false,
      });
    } catch (err) {
      set({
        activeAlertData: [] as NotificationGroup[],
        activeAlertIsLoading: false,
      });
      throw err;
    }
  },
}));

export default useAlertStore;
