import {
  ModeDetails,
  ModeDetailsChartData,
  NotificationDetails,
  NotificationGroup,
  NotificationType,
} from 'models';
import { theme } from 'style';
import i18n from 'i18next';
import moment from 'moment';

export const formatDIOperationalAlertsOverviewData = (
  alertData: NotificationGroup[],
) => {
  return alertData
    .filter((alert) => alert.type === NotificationType.Alert)
    .map((alert: any) => ({
      ...alert,
      color:
        theme.colors.alerts[alert.name] ||
        getRandomHexColour(),
    }));
};

export const formatDIOperationalModeChangesOverviewData = (
  modeData: NotificationGroup[],
  selectedDateTime: string,
) => {
  return modeData
    .filter((mode) => mode.type === NotificationType.Mode)
    .map((mode: NotificationGroup) => ({
      ...mode,
      value: +mode.value.toFixed(2),
      valueSuffix:
        selectedDateTime === 'now'
          ? ` ${i18n.t('operationalOverview:mode_change_doors')}`
          : `% ${i18n.t('operationalOverview:mode_change_doors')}`,
      color:
        theme.colors.modeChanges[mode.name] ||
        getRandomHexColour(),
    }));
};

export const formatDIOperationalModeChangesDetailsData = (
  modeData: NotificationDetails,
): ModeDetailsChartData[] => {
  const modeChanges = modeData?.modes
    ?.map((mode: ModeDetails) => ({
      ...mode,
      groupName: moment(mode.startTime).format('ddd'),
    }))
    .reduce((r, a) => {
      r[a.groupName] = r[a.groupName] || [];
      r[a.groupName].name = a.groupName;
      r[a.groupName].modeChanges = [...(r[a.groupName].modeChanges || []), a];
      return r;
    }, Object.create(null));

  return modeChanges ? Object.values(modeChanges) : [];
};

export const getRandomHexColour = () => {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;
}

