import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useTableStyle } from './tableStyle';

import { Spacer } from 'style';
import { IcomoonIcons, Paragraph as P, Truncate } from 'components';
import { theme } from 'style';
import { useDailyTrafficStore } from 'stores';
import Skeleton from 'components/Skeleton/Skeleton';
import EllipisWithTooltip from 'components/EllipsisWithTooltip/EllipsisWithTooltip';
import { ascending, descending } from 'utils';
import { IcomoonIconNames } from 'components/IcomoonIcons/IcomoonIcons';

export enum ColumnState {
  LOADING = 'LOADING',
}

interface Props {
  columns: string;
  getTableProps: () => void;
  getTableBodyProps: () => void;
  headerGroups: any[];
  rows: any[];
  prepareRow: (row: any) => void;
  sortBy?: (header: any) => void;
  onRowClick?: (id: any) => void;
  navigateOnRowClick?: (id: any) => void;
  showNavigateToArrow?: boolean;
  navigateIcon?: IcomoonIconNames;
  paginated?: boolean;
  total?: number;
  currentPage?: number;
  limit?: number;
  isLoadingMore?: boolean;
  onLoadMore?: (nextPage: number) => void;
  selectedRow?: any;
  selectedRowAction?: any;
  minWidth?: number;
  sortByColumn?: { columnId: string; order: string };
}

const Table = ({
  columns,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  sortBy,
  onRowClick,
  navigateOnRowClick,
  showNavigateToArrow = true,
  navigateIcon,
  paginated = false,
  total = 0,
  currentPage = 1,
  limit = 20,
  isLoadingMore = false,
  onLoadMore,
  selectedRow,
  selectedRowAction,
  minWidth = 0,
  sortByColumn = undefined,
}: Props) => {
  const { t } = useTranslation();
  const {
    MainWrapper,
    Wrapper,
    HeaderCell,
    TableRow,
    TableHead,
    TableHeadRow,
    BodyCell,
    TableBody,
    Table,
    Pagination,
    PaginationItem,
  } = useTableStyle(columns);

  const [activeRow, setActiveRow] = useState<number | null>(null);
  useEffect(() => {
    if (selectedRow === undefined) {
      setActiveRow(null);
    }
  }, [selectedRow]);

  return (
    <MainWrapper>
      <Wrapper>
        <Table {...getTableProps()} minWidth={minWidth}>
          <TableHead style={{ fontWeight: 600 }}>
            <TableHeadRow {...headerGroups[0].getHeaderGroupProps()}>
              {headerGroups[1].headers.map((column: any, i: number) => {
                return (
                  <HeaderCell
                    key={i}
                    style={
                      column.sortable
                        ? { cursor: 'pointer', textAlign: 'left' }
                        : { textAlign: 'left' }
                    }
                    {...column.getHeaderProps(
                      sortBy
                        ? {
                            onClick: () => {
                              sortBy(column.id);
                            },
                          }
                        : column.sortable
                        ? column.getSortByToggleProps()
                        : {},
                    )}>
                    {typeof column.Header === 'string'
                      ? t(column.render('Header'))
                      : column.Header()}
                    {column.sortable && (
                      <>
                        <Spacer width={5} />
                        {sortByColumn ? (
                          <>
                            {sortByColumn.columnId === column.id ? (
                              sortByColumn.order === ascending ? (
                                <IcomoonIcons
                                  icon="sort-up"
                                  size={12}
                                  color="grey"
                                />
                              ) : sortByColumn.order === descending ? (
                                <IcomoonIcons
                                  icon="sort-down"
                                  size={12}
                                  color="grey"
                                />
                              ) : (
                                <IcomoonIcons
                                  icon="sort"
                                  size={12}
                                  color="grey"
                                />
                              )
                            ) : (
                              <IcomoonIcons
                                icon="sort"
                                size={12}
                                color="grey"
                              />
                            )}
                          </>
                        ) : column.isSorted ? (
                          column.isSortedDesc ? (
                            <IcomoonIcons
                              icon="sort-down"
                              size={12}
                              color="grey"
                            />
                          ) : (
                            <IcomoonIcons
                              icon="sort-up"
                              size={12}
                              color="grey"
                            />
                          )
                        ) : (
                          <IcomoonIcons icon="sort" size={12} color="grey" />
                        )}
                      </>
                    )}
                  </HeaderCell>
                );
              })}
            </TableHeadRow>
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  data-testid={`table-row-${row.original.id}`}
                  key={i}
                  isActive={activeRow === i}
                  isClickable={onRowClick ? true : false}
                  {...row.getRowProps({
                    onClick: () => {
                      if (onRowClick) {
                        onRowClick(row.original);
                      }
                      if (selectedRowAction) {
                        if (activeRow && activeRow === i) {
                          setActiveRow(null);
                          selectedRowAction(undefined);
                        } else {
                          setActiveRow(row.index);
                          selectedRowAction(row);
                        }
                      }
                    },
                  })}>
                  {row.cells.map((cell: any, i: number) => {
                    return cell.value === ColumnState.LOADING ? (
                      <BodyCell
                        key={i}
                        {...cell.getCellProps()}
                        style={{ overflow: 'hidden', paddingRight: '10px' }}>
                        <Skeleton width="40" height="28" />
                      </BodyCell>
                    ) : typeof cell.value === 'string' ? (
                      <BodyCell
                        key={i}
                        {...cell.getCellProps()}
                        style={{ overflow: 'hidden', paddingRight: '10px' }}>
                        <EllipisWithTooltip content={cell.value}>
                          <P size="small" color="charcoal">
                            {cell.render('Cell')}
                          </P>
                        </EllipisWithTooltip>
                      </BodyCell>
                    ) : (
                      <BodyCell
                        key={i}
                        {...cell.getCellProps()}
                        style={{ overflow: 'hidden', paddingRight: '10px' }}>
                        {cell.render('Cell')}
                      </BodyCell>
                    );
                  })}
                  {showNavigateToArrow && (
                    <td
                      onClick={() => {
                        if (navigateOnRowClick) {
                          navigateOnRowClick(row.original);
                        }
                      }}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <IcomoonIcons
                        icon={navigateIcon ?? 'chevron-right'}
                        color="grey"
                        size={18}
                      />
                    </td>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Wrapper>
      {paginated && (
        <>
          {!isLoadingMore && (
            <Pagination>
              <P color="charcoal" size="small">
                {currentPage} {t('common:showing_total.of')}{' '}
                {Math.ceil(total / limit)}
              </P>

              <PaginationItem
                onClick={() => {
                  if (onLoadMore && currentPage > 1) {
                    onLoadMore(currentPage - 1);
                  }
                }}>
                <IcomoonIcons
                  icon="chevron-left"
                  color={
                    currentPage > 1 ? 'charcoal' : 'blueGrey'
                  }></IcomoonIcons>
              </PaginationItem>
              <PaginationItem
                onClick={() => {
                  if (onLoadMore && currentPage < total / limit) {
                    onLoadMore(currentPage + 1);
                  }
                }}>
                <IcomoonIcons
                  icon="chevron-right"
                  color={
                    currentPage < total / limit ? 'charcoal' : 'blueGrey'
                  }></IcomoonIcons>
              </PaginationItem>
            </Pagination>
          )}
          {isLoadingMore && (
            <Pagination>
              <Skeleton />
            </Pagination>
          )}
        </>
      )}
    </MainWrapper>
  );
};

export default Table;
