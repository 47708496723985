import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import config from 'config';
import { KeyValue } from 'stores/useDoorDetailsStore';
import useDoorStateWebSocketMessageStore from 'stores/useDoorStateWebSocketMessageStore';

const URL = config.doorStateWebSocket;

const useDoorStateWebsocketConnection = () => {
  const { setLastMessage } = useDoorStateWebSocketMessageStore();
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [readyToConnect, setReadyToConnect] = useState<boolean>(false);
  const [connectedToWebSocket, setConnectedToWebSocket] = useState(false);
  const [failedToConnectToWebSocket, setFailedToConnectToWebSocket] = useState(
    false,
  );
  const [params, setParams] = useState<{
    model: string;
    uuid: string;
    language: string;
  }>();

  const cleanUpConnection = () => {
    ws?.close();
    setWs(null);
    setReadyToConnect(false);
    console.log('Door State WS connection closed');
  };

  useEffect(() => {
    if (readyToConnect) {
      console.log('Connecting to Door State WS');
      connectToWebsocket();
    }
  }, [readyToConnect]);

  useEffect(() => {
    if (params && params?.language && params.model && params.uuid) {
      Auth.currentSession()
        .then((session) => {
          const isValid = session.isValid();
          setReadyToConnect(isValid);
        })
        .catch((err) => {
          console.log(err);
          cleanUpConnection();
        });
    }
  }, [params]);

  const connectToWebsocket = async () => {
    setWs(null);
    const session = await Auth.currentSession();
    if (session.isValid()) {
      const client = new WebSocket(
        `${URL}?model=${params?.model}&languageCode=${params?.language}&uuid=${params?.uuid}`,
      );
      setConnectedToWebSocket(true);
      setWs(client);
      setConnectedToWebSocket(true);
    } else {
      setFailedToConnectToWebSocket(true);
      setWs(null);
      setFailedToConnectToWebSocket(true);
    }
  };

  useEffect(() => {
    if (ws) {
      ws.onopen = () => {
        console.log('==Door State WS Open ==');
        const TIMEOUT = 9 * 60000;

        setInterval(() => {
          if (ws.readyState === 1) {
            console.log('Door State Returning to base...');
            ws.send('Door State Reporting In');
          }
        }, TIMEOUT);
      };
      ws.onclose = (message: any) => {
        console.log('Door State WS Closed == Reconnecting to WS', message);
        setConnectedToWebSocket(false);
        setFailedToConnectToWebSocket(false);
      };
      ws.onmessage = (message: MessageEvent) => {
        try {
          const data: KeyValue = JSON.parse(message.data);
          setLastMessage(data);
        } catch {
          console.log('Door State ws response: ', message.data);
        }
      };
      ws.onerror = (err: any) => {
        console.log('Door State WS error: ', err);
        cleanUpConnection();
      };
    }
  }, [ws]);

  return {
    connectToWebsocket,
    setReadyToConnect,
    cleanUpConnection,
    setParams,
    connectedToWebSocket,
    failedToConnectToWebSocket,
  };
};

export default useDoorStateWebsocketConnection;
