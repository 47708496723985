import styled from '@emotion/styled';
import { flexCenterAll, mq } from 'style';

export const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 2.5% 57.5%;

  ${mq.mobile} {
    display: block;
  }
`;

export const DonutContainer = styled.div`
  ${flexCenterAll};
  padding: 40px;
  ${mq.mobile} {
    padding: 20px;
    border-right: none;
  }
`;

export const BarContainer = styled.div`
  ${flexCenterAll};
  ${mq.mobile} {
    padding: 20px 0px;
    border-right: none;
  }
`;

export const SectionHeader = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 20px;

  ${mq.tablet} {
    display: flex;
    flex-direction: column;

    > div:nth-of-type(2) {
      margin-top: 10px;
      width: 350px;
    }
  }
  ${mq.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const DropdownContainer = styled.div`
  width: 100%;
  max-width: 350px;
  ${mq.mobile} {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
    z-index: 2;
  }
  ${mq.tablet} {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  ${mq.largeTablet} {
    width: 100%;
  }
`;
