import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form as FormikForm } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  Button,
  SmallFormContainer,
  ResponseHandler,
  Link,
  Flex,
  Paragraph as P,
} from 'components';
import { Spacer } from 'style';
import { statusByUsername } from 'services';

import { ForgotPassword, errorTranslate } from 'core/auth/auth';

import { PasswordSet } from 'pages/Activate/forms';

interface Props {
  headerSettings: PasswordSet.DefaultSettings;
  togglePasswordReset?: () => void;
  initialEmail?: string;
}

export const Form: React.FC<Props> = ({
  headerSettings,
  togglePasswordReset,
  initialEmail,
}) => {
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('validation:email'))
      .required(t('validation:required')),
  });

  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  let initialValues: ValidationSchema = {
    email: '',
  };
  useEffect(() => {
    if (initialEmail) {
      initialValues = { ...initialValues, email: initialEmail };
    }
  }, [initialEmail]);

  const onSubmit = async (
    values: ValidationSchema,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (fields: { [field: string]: string }) => void,
  ) => {
    try {
      const userStatus = await statusByUsername(values.email);

      switch (userStatus) {
        case 'Active':
          const res = await ForgotPassword(values.email);
          if (res) {
            setUsername(values.email);
            setIsEmailSent(true);
          }
          break;
        case 'Disabled':
        case 'Pending':
        case 'Unknown':
        case 'VerificationRequired':
          setErrors({
            email: t('loginActivate:forgot_password.errors.deactivated'),
          });
          break;
      }
    } catch (err) {
      const { code } = err;
      if (err.response.status === 404) {
        setErrors({
          email: t('loginActivate:forgot_password.errors.deactivated'),
        });
      } else {
        setErrors({ email: errorTranslate(code) });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const [isResending, setIsResending] = useState<boolean>(false);
  const resendEmail = async () => {
    setIsResending(true);
    try {
      if (username) {
        await ForgotPassword(username);
      }
    } catch (err) {
      throw err;
    } finally {
      setIsResending(false);
    }
  };

  const formRef = useRef<any>();

  useEffect(() => {
    formRef?.current?.validateForm();
  }, []);
  return isEmailSent ? (
    <ResponseHandler
      mainHeading={t('loginActivate:forgot_password.response_handler.heading')}
      subHeading={t(
        'loginActivate:forgot_password.response_handler.subheading',
      )}
      bottomText={
        togglePasswordReset && (
          <Link
            size="small"
            color="charcoal"
            onClick={togglePasswordReset}
            data-cypress="login-forgot-password">
            {t('loginActivate:forgot_password.return.link')}
          </Link>
        )
      }
      action={resendEmail}
      actionLabel={t(
        'loginActivate:forgot_password.response_handler.action_label',
      )}
      isLoading={isResending}
    />
  ) : (
    <SmallFormContainer
      mainHeading={headerSettings.mainHeading}
      subHeading={headerSettings.subHeading}>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          onSubmit(values, setSubmitting, setErrors);
        }}
        isInitialValid={validationSchema.isValidSync(initialValues)}
        validationSchema={validationSchema}>
        {(props) => (
          <FormikForm data-testid="forgot-password-request">
            <TextField
              name="email"
              label={t('forms:email')}
              type="email"
              placeholder={t('forms:email')}
              cypress="reset-password-email-input"
            />

            <Button
              type="submit"
              disabled={!(props.isValid && props.dirty) || props.isSubmitting}
              cypress="form-wrapper-submit"
              isLoading={props.isSubmitting}>
              {t('loginActivate:forgot_password.send_reset_instructions')}
            </Button>
          </FormikForm>
        )}
      </Formik>
      {togglePasswordReset && (
        <>
          <Spacer height={20} />
          <Flex
            justifyContent={['flex-start', 'center']}
            alignItems={['center', 'flex-start']}
            flexDirection={['column', 'row']}>
            <P size="small" color="charcoal">
              {t('loginActivate:forgot_password.return.copy')}
            </P>
            <Spacer width={5} />
            <Link
              size="small"
              color="charcoal"
              onClick={togglePasswordReset}
              data-cypress="login-forgot-password">
              {t('loginActivate:forgot_password.return.link')}
            </Link>
          </Flex>
        </>
      )}
    </SmallFormContainer>
  );
};
