import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

import { Skeleton } from 'components';
import { useAlertStore, useDoorDetailsStore } from 'stores';
import { useScreenSize } from 'hooks';

import NoLocationData from './EmptyState/NoLocationData';
import { mapContainerStyle, options } from './mapAttributes';
import greenMarker from './green-marker.svg';
import redMarker from './red-marker.svg';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

interface DoorMapProps {
  latitude: number;
  longitude: number;
}

const DoorMap = ({ latitude, longitude }: DoorMapProps) => {
  const { isMobile } = useScreenSize().screenSize();
  const { isLoading } = useDoorDetailsStore();
  const { activeAlertData } = useAlertStore();

  const marker = activeAlertData.length > 0 ? redMarker : greenMarker;

  useLoadScript({
    googleMapsApiKey,
  });

  const center = {
    lat: latitude,
    lng: longitude,
  };

  if (isLoading) return <Skeleton height="100%" width="100%" />;

  if (!isLoading && (!longitude || !latitude)) {
    return <NoLocationData />;
  }

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={isMobile ? 15 : 17}
      center={center}
      options={options}>
      <Marker position={center} icon={marker} />
    </GoogleMap>
  );
};

export default DoorMap;
