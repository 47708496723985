import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph as P, Flex, Link } from 'components';
import { Spacer } from 'style';
import { ReactComponent as DormaLogo } from './Logo.svg';
import {
  OuterContainer,
  LogoWrapper,
  InnerContainer,
} from './fullScreenContainerStyle';

import { useNavigation, useNavigationItems, useScreenSize } from 'hooks';
import { getCurrentProductName } from 'utils';
import HomeLogo from 'components/HomeLogo/HomeLogo';
import i18n from 'core/i18n/i18n';
import { getLanguage } from 'utils/getLanguage';

interface Props {
  /** Components to display in the inner window */
  children: any;
  /** Center inner window in the container */
  centered?: boolean;
  innerHeight?: number | string;
  innerWidth?: number | string;
  hideContactLink?: boolean;
}

/**
  A simple div that takes in props that correspond the the css flex values. Use inside a container to create the desired layout.
*/
const { screenSize } = useScreenSize();

const changeLanguageIfRequired = () => {
  const language = getLanguage();
  const translatorLanguage = i18n.language;

  if (language && language !== translatorLanguage) {
    i18n.changeLanguage(language);
  }
}

const FullscreenContainer = ({
  children,
  centered,
  hideContactLink = false,
}: Props) => {
  // Hide the menu items for Onboarding and Log In
  const { hideNavigation } = useNavigation();
  const { t } = useTranslation();
  useEffect(() => {
    hideNavigation();
    changeLanguageIfRequired();
  }, []);

  const { product } = useNavigationItems('Admin');
  const productName = getCurrentProductName();  

  // Responsive Settings
  const { isMobile } = screenSize();  

  return (
    <OuterContainer
      centered={centered}
      isMobile={isMobile}
      data-cypress="full-screen-outer-containter">
      <LogoWrapper
        style={{
          backgroundColor: 'white',
        }}>
        <HomeLogo productName={productName} variant='primary'/>
      </LogoWrapper>
      <div>
        <InnerContainer>{children}</InnerContainer>
        {!hideContactLink && (
          <div data-cypress="contact-support">
            <Spacer height={25} />
            <Flex alignItems="center">
              <P color="charcoal" size="small">
                {t('loginActivate:full_screen_container.contact_support.lead')}
              </P>
              <Spacer width={5} />
              <Link
                href="https://entrivo-helpandsupport.dormakaba.com/s/request-support"
                target="_blank"
                color="charcoal"
                size="small">
                {t('loginActivate:full_screen_container.contact_support.link')}
              </Link>
            </Flex>
          </div>
        )}
      </div>

      <LogoWrapper>
        <Link href="https://www.dormakaba.com/" target="_blank">
          <DormaLogo />
        </Link>
      </LogoWrapper>
    </OuterContainer>
  );
};

export default FullscreenContainer;