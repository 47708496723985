import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTable, useSortBy } from 'react-table';
import { Status, FollowToggle } from './CellComponents';
import { Table, Grid, Paragraph as P, IcomoonIcons } from 'components';
import { DoorListParams, DoorSummary } from 'models';
import { useScreenSize } from 'hooks';
import { useTranslation } from 'react-i18next';
import DoorListItem from './DoorListItem/DoorListItem';
import i18n, { Locale } from 'core/i18n/i18n';
import { useDoorsTableStyle } from './doorsTableStyle';
import { ColumnState } from 'components/Table/Table';
import { useDoorStore } from 'stores';
import { ascending, descending } from 'utils';

interface Props {
  simple?: boolean;
  onRowClick?: (row: any) => void;
  data: DoorSummary[];
  onLoadMore?: (nextPage: number) => void;
  paginated?: boolean;
  total?: number;
  currentPage?: number;
  limit?: number;
  onChange?: (value: any, columnName: string) => void;
}

export const alertCount = 'alertCount';

const DoorsTable = ({
  data,
  onRowClick,
  simple = false,
  onLoadMore,
  paginated,
  total = 0,
  currentPage = 1,
  limit = 20,
  onChange,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const locale = i18n.language;
  const isLocaleEnglish = locale === Locale.EN;
  const localeColumnFractions = '0.8fr 3.2fr 1fr 1.5fr 2fr 2fr 1.5fr 90px 60px';
  const localeSimpleColumnFractions = '0.8fr 2.2fr 1fr 2fr 90px 60px';
  const { Wrapper, Pagination, PaginationItem } = useDoorsTableStyle();
  const [isFollowing, setIsFollowing] = useState(false);
  const [doorId, setDoorId] = useState('');

  const { setListParams, listParams } = useDoorStore();

  data = useMemo(() => {
    return data.map((item: DoorSummary) => ({ ...item }));
  }, [data, doorId, isFollowing]);

  const updateFollowing = (id: string, isFollowingValue: string) => {
    if (onChange) {
      onChange(id, 'isFollowing');
      const newFollowing = !Boolean(isFollowingValue);
      setIsFollowing(newFollowing);
      setDoorId(id);
    }
  };

  const followButtonCell = (props: any) => (
    <FollowToggle
      onToggleFollowing={(id: string) => updateFollowing(id, props.cell.value)}
      isFollowing={props.cell.value}
      id={props.row.original.id}
      loading={props.row.original.lastUpdateTime === ColumnState.LOADING}
      simple={simple}
    />
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'doorList:table.column.alerts',
            accessor: alertCount,
            sortable: true,
            filterable: true,
            Cell: (props: any) => {
              return (
                <Status
                  alertCount={props.cell.value}
                  isOffline={props.row.original.isOffline}
                  isActive={props.row.original.isActive}
                />
              );
            },
          },
          {
            Header: 'doorList:table.column.name',
            accessor: 'name',
            sortable: true,
            filterable: true,
          },
          {
            Header: 'doorList:table.column.type',
            accessor: 'type',
            sortable: true,
            filterable: true,
          },
          {
            Header: 'doorList:table.column.city',
            accessor: 'location.city',
            sortable: true,
            filterable: true,
          },
          {
            Header: 'doorList:table.column.last_update',
            accessor: 'lastUpdateTime',
            sortable: true,
            filterable: true,
            Cell: (props: any) => {
              return props.cell.value
                ? moment(props.cell.value).format('DD/MM/YYYY HH:mm:ss')
                : '';
            },
          },
          {
            Header: 'doorList:table.column.account',
            accessor: 'account.name',
            sortable: true,
            filterable: true,
          },
          {
            Header: 'doorList:table.column.status',
            accessor: 'commissionedStatus',
            sortable: true,
            filterable: true,
          },
          {
            Header: 'doorList:table.column.following',
            accessor: 'isFollowing',
            sortable: true,
            filterable: false,
            Cell: followButtonCell,
          },
        ],
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: simple
          ? ['location.city', 'commissionedStatus', 'account.name'] // hide certain columns in simple mode
          : [],
      },
    },
    useSortBy,
  );

  const navigateOnRowClick = (row: DoorSummary) => {
    history.push({
      pathname: `/doors/${row.id}`,
      state: {
        fromPage: history.location.pathname,
        id: row.id,
        name: row.name,
      },
    });
  };

  const { isMobile } = useScreenSize().screenSize();
  if (isMobile) {
    return (
      <>
        <Grid gridAutoFlow="row" gridRowGap="15px">
          {rows.map((row: any, index: number) => (
            <DoorListItem
              key={index}
              row={row}
              navigateOnRowClick={navigateOnRowClick}
              onRowClick={onRowClick}
            />
          ))}
        </Grid>
        {paginated && (
          <Wrapper>
            <Pagination>
              <P color="charcoal" size="small">
                {currentPage} {t('common:showing_total.of')}{' '}
                {Math.ceil(total / limit)}
              </P>

              <PaginationItem
                onClick={() => {
                  if (onLoadMore && currentPage > 1) {
                    onLoadMore(currentPage - 1);
                  }
                }}>
                <IcomoonIcons
                  icon="chevron-left"
                  color={
                    currentPage > 1 ? 'charcoal' : 'blueGrey'
                  }></IcomoonIcons>
              </PaginationItem>
              <PaginationItem
                onClick={() => {
                  if (onLoadMore && currentPage < total / limit) {
                    onLoadMore(currentPage + 1);
                  }
                }}>
                <IcomoonIcons
                  icon="chevron-right"
                  color={
                    currentPage < total / limit ? 'charcoal' : 'blueGrey'
                  }></IcomoonIcons>
              </PaginationItem>
            </Pagination>
          </Wrapper>
        )}
      </>
    );
  }

  if (simple) {
    return (
      <Table
        columns={localeSimpleColumnFractions}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        navigateOnRowClick={navigateOnRowClick}
        onRowClick={onRowClick}
        minWidth={isLocaleEnglish ? 620 : 640}
        onLoadMore={onLoadMore}
        total={total}
        currentPage={currentPage}
        limit={limit}
        paginated={paginated}
        sortBy={(columnId: string) => {
          sortByColumnId(listParams, columnId, setListParams);
        }}
        sortByColumn={{
          columnId: listParams.sortBy ?? '',
          order: listParams.order ?? '',
        }}
      />
    );
  }

  return (
    <Table
      columns={localeColumnFractions}
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      rows={rows}
      prepareRow={prepareRow}
      onRowClick={navigateOnRowClick}
      navigateOnRowClick={navigateOnRowClick}
      minWidth={960}
      onLoadMore={onLoadMore}
      total={total}
      currentPage={currentPage}
      limit={limit}
      paginated={paginated}
      sortBy={(columnId: string) => {
        sortByColumnId(listParams, columnId, setListParams);
      }}
      sortByColumn={{
        columnId: listParams.sortBy ?? '',
        order: listParams.order ?? '',
      }}
    />
  );
};

function sortByColumnId(
  listParams: DoorListParams,
  columnId: string,
  setListParams: (listParams: DoorListParams) => void,
) {
  if (listParams.sortBy === columnId) {
    const order = listParams.order;
    const nextSortOrder =
      order === ascending ? descending : order === descending ? '' : ascending;
    setListParams({ ...listParams, sortBy: columnId, order: nextSortOrder });
  } else {
    setListParams({ ...listParams, sortBy: columnId, order: ascending });
  }
}

export default DoorsTable;
