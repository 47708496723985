import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Flex,
  Modal,
  Heading as H,
  Paragraph as P,
  Button,
  Grid,
  IcomoonIcons,
} from 'components';
import { useModalStore } from 'stores';
import { Header, Heading } from './remoteLockModalStyles';
import { Spacer } from 'style';
import { requestRemoteOpenDoor } from 'services';
import { DoorDetailsAccount } from 'models';
import { RemoteActionType } from 'models/RemoteActionType';

interface Props {
  deviceUUID: string;
  doorName: string;
  account: DoorDetailsAccount;
  toggleRefresh: () => void;
}

const RemoteLockModal = ({
  deviceUUID,
  doorName,
  account,
  toggleRefresh,
}: Props) => {
  const { t } = useTranslation();
  const { requestRemoteLockModal } = useModalStore();

  const closeAndClearModal = () => {
    setIsSuccess(false);
    setErrors(false);
    setIsSubmitting(false);
    requestRemoteLockModal.toggleModal();
  };

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const remoteLockDoor = async () => {
    try {
      await requestRemoteOpenDoor(deviceUUID, RemoteActionType.REMOTE_LOCK, '');
      toggleRefresh();
      setIsSubmitting(false);
      setIsSuccess(true);
    } catch (err) {
      setIsSubmitting(false);
      setErrors(true);
    }
  };

  return (
    <Modal
      isOpen={requestRemoteLockModal.isOpen}
      heading={''}
      showHeader={false}
      toggleModal={closeAndClearModal}>
      {isSuccess || errors ? (
        <Flex justifyContent="center" flexDirection="column">
          <Spacer height={24} />
          <Flex centered>
            {isSuccess && (
              <IcomoonIcons icon="success" color="success" size={90} />
            )}
            {errors && <IcomoonIcons icon="error" color="error" size={90} />}
          </Flex>
          <Spacer height={24} />
          <H color="primary" size="h5" marginBottom={30} textAlign="center">
            {isSuccess &&
              t('doorDetails:modals.request_remote_lock.success.heading')}
            {errors &&
              t('doorDetails:modals.request_remote_lock.error.heading')}
          </H>
          <Flex justifyContent="center">
            <Button onClick={closeAndClearModal}>{t('common:close')}</Button>
          </Flex>
          <Spacer height={24} />
        </Flex>
      ) : (
        <Flex justifyContent="center" flexDirection="column">
          <Header>
            <Heading>
              <P color="primary" bold size="large">
                {t('doorDetails:modals.request_remote_lock.confirm')}
              </P>
            </Heading>
            <P color="charcoal" bold>
              {doorName}
            </P>
            <P>{account?.name}</P>
            <P mt="15px" color="blueGrey">
              {t('doorDetails:modals.request_remote_lock.description')}
            </P>
          </Header>
          <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap={20}>
            <Button variant="outlined" onClick={closeAndClearModal}>
              {t('common:cancel')}
            </Button>
            <Button
              onClick={() => {
                setIsSubmitting(true);
                remoteLockDoor();
              }}
              isLoading={isSubmitting}
              disabled={isSubmitting}>
              {t('common:confirm')}
            </Button>
          </Grid>
        </Flex>
      )}
    </Modal>
  );
};

export default RemoteLockModal;
