import styled from "@emotion/styled";
import { flexCenterAll, flexCenter, theme, mq } from "style";
import { useScreenSize } from "hooks";

export const GenerateChartComponents = () => {
  const { isMobile } = useScreenSize().screenSize();
  const SIZE = isMobile ? "275px" : "450px";

  const Container = styled.div`
    height: 400px;
    ${mq.mobile()} {
      height: 200px;
    }
    position: relative;
    ${flexCenterAll};
  `;

  const ChartWrapper = styled.div`
    ${flexCenterAll};
    width: 100%;
    height: calc(${SIZE} / 2);
  `;

  const Chart = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    svg {
      height: 100%;
      width: 100%;
    }
  `;

  const PercentLabel = styled.div`
    position: absolute;
    height: 50%;
    width: 50%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    ${flexCenterAll};
    flex-direction: column;
    justify-content: flex-end;

    > p:first-of-type {
      font-size: 65px;
      color: ${theme.colors.primary};
      font-weight: 600;
      ${mq.mobile()} {
        font-size: 40px;
      }
    }
    > p:last-of-type {
  	padding-top: 12px;
      font-size: 20px;
      color: ${theme.colors.charcoal};
      font-weight: 600;
      ${mq.mobile()} {
        font-size: 14px;
      }
    }
  `;

  const IndicatorContainer = styled.div`
    position: absolute;
    height: calc(${SIZE} / 2);
    width: calc(${SIZE} / 2);
    transform: rotate(-90deg);
  `;

  interface IndicatorProps {
    rotation: number;
  }
  const Indicator = styled.div<IndicatorProps>`
    z-index: 2;
    position: absolute;
    height: calc(${SIZE} / 2);
    width: auto;
    bottom: 50%;
    left: 0%;
    transform-origin: 0% 100%;
    transform: ${(props) => `rotate(${props.rotation}deg) translate(-50%,0%)`};

    svg {
      position: absolute;
      top: 22.5%;
      left: 50%;
      transform: translateX(-50%);

      ${mq.mobile()} {
        height: 30px;
        width: 30px;
      }
    }
  `;

  return {
    Container,
    Chart,
    Indicator,
    IndicatorContainer,
    ChartWrapper,
    PercentLabel,
  };
};
