import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelValue } from 'models';

interface FilterSettings {
  options?: LabelValue[];
  name: string;
  label: string;
  placeholder?: string;
}
const useTableFilter = (
  params: any,
  setParams: any,
  settings: FilterSettings,
) => {
  const { t } = useTranslation();

  const filterHandler = (e: any, action: any) => {
    setParams({ ...params, [action.name]: e.value });
  };
  const initialState = [
    {
      label: settings.placeholder
        ? settings.placeholder
        : t('common:table.all'),
      value: '',
    },
  ];

  const [filters, setFilters] = useState<LabelValue[]>(initialState);

  useEffect(() => {
    if (settings.options) {
      setFilters([...initialState, ...settings.options]);
    }
  }, [settings.options]);

  const filter = {
    label: settings.label,
    options: filters,
    onChange: filterHandler,
    name: settings.name,
    value: params[settings.name],
  };

  return [filter];
};

export default useTableFilter;
