import { Wrapper, Container, Heading } from './maintenance.style';
import React from 'react';
import {
  Heading as H,
  Paragraph as P,
  Flex,
  IcomoonIcons,
  FullScreenContainer,
} from 'components';
import { Spacer } from 'style';

const Maintenance: React.FC = () => {
  return (
    <FullScreenContainer>
      <Wrapper data-cypress="maintenance-page">
        <Container>
          <Flex centered>
            <IcomoonIcons icon="info" color="warning" size={90} />
          </Flex>
          <Spacer height={24} />
            
          <Heading>
            <H
              size="h3"
              center
              color="primary"
              data-cypress="maintenance-page-heading">
              We&rsquo;ll be back soon!
            </H>
            
            <P size="normal" center color="charcoal">
              Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment, we&rsquo;ll be back online shortly!
            </P>
          </Heading>
        </Container>
      </Wrapper>
    </FullScreenContainer>
  )
}

export default Maintenance

