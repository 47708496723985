import React, { useState, useEffect } from 'react';
import i18n from 'i18next';

import { SlideToggle } from 'components';
import { updateUserLanguage, fetchUserMeDetails } from 'services';
import { SlideToggleItem } from 'components/SlideToggle/SlideToggle';
import { setLanguage } from 'utils/setLanguage';

const setItemHelper = (obj: any) => {
  window.localStorage.setItem('userMe', JSON.stringify(obj));
};

const LanguageToggle = () => {
  const lang = i18n.language;

  const action = async (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    await updateUserLanguage(lang);
    const res = await fetchUserMeDetails();
    if (res) {
      await setItemHelper(res);
      i18n.changeLanguage(res.language);

      window.location.reload();
    }
  };
  const languages: SlideToggleItem[] = [
    {
      label: 'EN',
      callback: () => action('en'),
    },
    {
      label: 'DE',
      callback: () => action('de'),
    },
    {
      label: 'FR',
      callback: () => action('fr'),
    },
  ];

  const [langIndex, setLangIndex] = useState<number>(0);
  useEffect(() => {
    const index = languages.map((x) => x.label).indexOf(lang.toUpperCase());
    setLangIndex(index);
  }, [lang]);

  return <SlideToggle indexOverride={langIndex} items={languages} />;
};

export default LanguageToggle;
