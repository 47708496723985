import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import NotificationLink from './NotificationLink/NotificationLink';
import { Button } from 'components';
import { followSpace, unfollowSpace } from 'services';
import { useNotificationPreferenceStore } from 'stores';
import { useScreenSize } from 'hooks';
import { SpaceDetails } from 'models';

interface Props {
  spaceDetailsData: SpaceDetails;
  isEditDetails: boolean;
  isLoading: boolean;
}

const FollowSpace = ({ spaceDetailsData, isEditDetails, isLoading }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize().screenSize();
  const [isFollowingLoading, setIsFollowingLoading] = useState<boolean>(false);
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const {
    notificationPreferenceFetch,
    notificationPreferenceData,
    notificationPreference,
  } = useNotificationPreferenceStore();

  const dismissNotificationPreference = async () => {
    setShowNotification(false);
  };

  useEffect(() => {
    if (!isLoading) {
      setIsFollowing(spaceDetailsData.details.isFollowing);
    }
  }, [spaceDetailsData, isLoading]);

  useEffect(() => {
    notificationPreferenceFetch();
  }, []);

  const toggleIsFollowing = () => {
    const { isSpaceDismissed } = notificationPreferenceData;
    setIsFollowingLoading(true);

    if (isSpaceDismissed === false && showNotification === false) {
      notificationPreference({
        ...notificationPreferenceData,
        isSpaceDismissed: true,
      });
      setShowNotification(true);
    }
    !isFollowing
      ? followSpace(spaceDetailsData.details.spaceId)
      : unfollowSpace(spaceDetailsData.details.spaceId);

      setIsFollowingLoading(false);
      setIsFollowing(!isFollowing);
  };

  return (
    <NotificationLink
      showNotification={showNotification}
      dismiss={() => dismissNotificationPreference()}>
      <Button
        stretch
        variant={
          !isFollowing || isFollowingLoading
            ? isMobile
              ? 'outlined'
              : 'whiteoutlined'
            : 'contained'
        }
        inverse={!isMobile}
        style={{
          paddingLeft: isMobile ? '15px' : '',
          paddingRight: isMobile ? '15px' : '',
        }}
        iconSize={20}
        data-testid="follow-space-toggle-btn"
        icon={isFollowing ? 'favourite-filled' : 'favourite'}
        disabled={isEditDetails}
        isLoading={isLoading || isFollowingLoading}
        onClick={toggleIsFollowing}>
        {isFollowing
          ? t('spaceDetails:page_header.buttons.favourited')
          : t('spaceDetails:page_header.buttons.favourite')}
      </Button>
    </NotificationLink>
  );
};

export default FollowSpace;
