import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useScreenSize } from 'hooks';
import { Table, StatusIndicator, Grid } from 'components';

import MobileItem from './MobileItem/MobileItem';
import { FormattedReturn, LINE_COLORS } from '../TrafficHistory';

interface Props {
  data: FormattedReturn[];
  setSelectedDay: (id: string) => void;
  selectedDay?: any;
  timePeriod: 'today' | 'week';
}

const TrafficHistoryTable = ({
  data,
  setSelectedDay,
  selectedDay,
  timePeriod,
}: Props) => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const { t } = useTranslation();
  // const { LINE_COLORS } = useLineColors();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: t('spaceDetails:charts.traffic_history_chart.day'),
            accessor: 'day',
            sortable: true,
            id: 'Day',
            Cell: (props: any) => {
              const today = moment().format('dddd');
              return (
                <StatusIndicator
                  label={
                    today === props.value ? t('common:today') : props.value
                  }
                  indicatorColor={LINE_COLORS[props.value]}
                />
              );
            },
          },
          {
            Header: t('spaceDetails:charts.traffic_history_chart.date'),
            accessor: 'date',
            sortable: true,
            id: 'Date',
            Cell: (props: any) => moment(props.value).format('DD/MM/YYYY'),
          },
          {
            Header: t('spaceDetails:charts.traffic_history_chart.average'),
            accessor: 'average',
            sortable: true,
            id: 'Average',
            Cell: (props: any) => Math.round(props.value),
          },
          {
            Header: t('spaceDetails:charts.traffic_history_chart.highest'),
            accessor: 'highest',
            sortable: true,
            id: 'Highest',
            Cell: (props: any) => {
              const formattedTime = moment(props.row.original.highestAt).format(
                'HH:MM',
              );
              return `${props.value} (${formattedTime})`;
            },
          },
          {
            Header: t('spaceDetails:charts.traffic_history_chart.lowest'),
            accessor: 'lowest',
            sortable: true,
            id: 'Lowest',
            Cell: (props: any) => {
              const formattedTime = moment(props.row.original.lowestAt).format(
                'HH:MM',
              );
              return `${props.value} (${formattedTime})`;
            },
          },
        ],
      },
    ],
    [],
  );

  const handleRowClick = (row: any) => {
    setSelectedDay(row.index);
  };
  const [filteredData, setFilteredData] = useState<any[]>(data);
  useEffect(() => {
    if (timePeriod === 'today') {
      const today = moment(new Date()).format('dddd');
      setFilteredData(data.filter((i: any) => i.day === today));
    } else {
      setFilteredData(data);
    }
  }, [timePeriod]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: filteredData }, useSortBy);

  if (isMobile) {
    return (
      <Grid gridAutoFlow="row" gridRowGap="15px">
        {rows.map((row: any, i: number) => (
          <MobileItem
            selectedRowAction={setSelectedDay}
            selectedRow={selectedDay}
            row={row}
            key={i}
          />
        ))}
      </Grid>
    );
  }

  return (
    <div data-testid="daily-traffic-table">
      <Table
        columns="2fr 140px 140px 140px 1fr"
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        showNavigateToArrow={false}
        selectedRow={selectedDay}
        selectedRowAction={setSelectedDay}
      />
    </div>
  );
};

export default TrafficHistoryTable;
