import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

import {
  ChartContainer,
  DonutChart,
  ChartSeparator,
  StackedBarChart,
} from 'components';
import { ConnectedDoorHealthAlerts } from 'models';
import {
  connectedDoorHealthDonutChartConfig,
  ConnectedDoorHealthDonutDataObject,
  renameObjKeys,
} from 'utils';
import { theme } from 'style';

import {
  ChartsContainer,
  DonutContainer,
  BarContainer,
} from '../connectedDoorHealthStyle';
import DonutChartSkeleton from '../Skeleton/DonutChartSkeleton';

interface FilterByAlertsProps {
  isLoading: boolean;
  connectedDoorHealthAlerts: ConnectedDoorHealthAlerts[];
}

const FilterByAlerts: React.FC<FilterByAlertsProps> = ({
  isLoading,
  connectedDoorHealthAlerts,
}) => {
  const { t } = useTranslation();

  const alertTranslated = t(
    'connectedDoorHealth:doors_by_alert_status.chart_legend_1',
  );
  const noAlertTranslated = t(
    'connectedDoorHealth:doors_by_alert_status.chart_legend_2',
  );

  const totalDoors =
    connectedDoorHealthAlerts?.length > 0
      ? connectedDoorHealthAlerts?.reduce((total: number, doorType: any) => {
          return total + doorType.withAlert + doorType.withoutAlert;
        }, 0)
      : 0;

  // *Methods
  const prepareDonutChartData = (details: any) => {
    return details.reduce(
      (updatedConfig: ConnectedDoorHealthDonutDataObject[], item: any) => {
        updatedConfig[0].count += item.withAlert;
        updatedConfig[1].count += item.withoutAlert;
        return updatedConfig;
      },
      cloneDeep(connectedDoorHealthDonutChartConfig),
    );
  };

  // data shape for bar chart, name corresponds to legend
  const dataShape = [
    {
      name: noAlertTranslated,
      color: theme.colors.success,
    },
    {
      name: alertTranslated,
      color: theme.colors.error,
    },
  ];

  const prepareBarChartData = (details: any) => {
    const mapping = {
      withAlert: alertTranslated,
      withoutAlert: noAlertTranslated,
    };

    // map object keys to name accepted by bar chart
    return details.map((item: any) => renameObjKeys(item, mapping));
  };

  return (
    <ChartsContainer>
      <ChartContainer
        elevated
        childrenStyleProps={{ padding: 0 }}
        title={t('connectedDoorHealth:all_doors:title')}>
        <DonutContainer>
          {isLoading || !connectedDoorHealthAlerts ? (
            <DonutChartSkeleton />
          ) : (
            <DonutChart
              isLoading={isLoading}
              data={
                connectedDoorHealthAlerts
                  ? prepareDonutChartData(connectedDoorHealthAlerts)
                  : undefined
              }
              dataKey="count"
              nameKey="label"
              label={{
                countValue: totalDoors,
                i18nLabel: t('connectedDoorHealth:all_doors:donut_label'),
              }}
            />
          )}
        </DonutContainer>
      </ChartContainer>

      <ChartSeparator />

      <ChartContainer
        elevated
        childrenStyleProps={{
          padding: isMobile ? '20px 20px 20px 10px' : '',
        }}
        title={t('connectedDoorHealth:doors_by_alert_status:title')}>
        <BarContainer>
          <StackedBarChart
            isLoading={isLoading}
            dataShape={dataShape}
            data={
              connectedDoorHealthAlerts
                ? prepareBarChartData(connectedDoorHealthAlerts)
                : undefined
            }
            dataKey="type"
          />
        </BarContainer>
      </ChartContainer>
    </ChartsContainer>
  );
};

export default FilterByAlerts;
