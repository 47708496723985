import create from 'zustand';

import { fetchCurrentOccupancy } from 'services';
import { CurrentOccupancy } from 'models';

interface CurrentOccupancyStore {
  currentOccupancyData: CurrentOccupancy;
  isLoading: boolean;
  currentOccupancyFetch: (props: {
    update?: boolean;
    followedOnly?: boolean;
  }) => void;
}

const [useCurrentOccupancyStore] = create<CurrentOccupancyStore>((set) => ({
  currentOccupancyData: {} as CurrentOccupancy,
  isLoading: true,
  currentOccupancyFetch: async ({ update = false, followedOnly = false }) => {
    try {
      if (!update) {
        set({ isLoading: true });
      }
      const res = await fetchCurrentOccupancy(followedOnly);
      set({ currentOccupancyData: await res, isLoading: false });
    } catch (err) {
      set({ currentOccupancyData: {} as CurrentOccupancy, isLoading: false });
      throw err;
    }
  },
}));

export default useCurrentOccupancyStore;
