import * as Yup from 'yup';

import i18n from 'core/i18n/i18n';

const passwordValidation = Yup.string()
  .min(8, i18n.t('forms:validation.password_minimum'))
  .matches(
    new RegExp('(?=.*[a-z])'),
    i18n.t('forms:validation.password_lowercase'),
  )
  .matches(
    new RegExp('(?=.*[A-Z])'),
    i18n.t('forms:validation.password_uppercase'),
  )
  .matches(
    new RegExp('(?=.*[0-9])'),
    i18n.t('forms:validation.password_number'),
  )
  .matches(new RegExp(/[^\w\s]/), i18n.t('forms:validation.password_special'))
  .required(i18n.t('forms:validation.password_required'));

export default passwordValidation;
