import styled from '@emotion/styled';
import { theme, inputDefaults } from 'style';

export const selectStyles = {
  option: (provided: any, { isSelected }: {isSelected: any}) => ({
    ...provided,
    fontSize: 14,
    textAlign: 'left',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    borderRadius: 0,
    color: isSelected ? '#FFF' : theme.colors.charcoal,
    paddingLeft: '17px',
    '&:hover': {
      color: theme.colors.charcoal,
      backgroundColor: theme.colors.lightBlueGrey,
    },
  }),
  container: (base: any) => ({
    ...base,
    minWidth: 240,
  }),
  menuList: (base: any) => ({
    ...base,
    border: theme.borders(1, 'lightBlueGrey'),
    borderTop: 'none',
    top: '0 !important',
    fontSize: 14,
    lineHeight: '21px',
    borderRadius: 0,
    boxShadow: '0px 3px 24px rgba(0, 0, 0, 0.05)',
    padding: 0,
  }),
  menu: (base: any) => ({
    ...base,
    marginTop: '0',
    boxShadow: 'none',
  }),
  control: (base: any, state: any) => ({
    ...base,
    ...inputDefaults,
    borderRadius: state.menuIsOpen ? '4px 4px 0 0' : '4px',
    minWidth: 240,
    marginBottom: 0,
    textAlign: 'left',
    fontSize: 14,
    cursor: 'pointer',
    border: state.isDisabled
      ? theme.borders(1, 'lightGrey')
      : theme.borders(1, 'lightBlueGrey'),
    '&:hover': { border: theme.borders(1, 'lightBlueGrey') },
    backgroundColor: state.isDisabled
      ? theme.colors.lightGrey
      : theme.colors.white,
    outline: 'none',
    boxShadow: 'none',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
    paddingLeft: 2,
  }),
};

export const Blanket = styled.div`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 100;
`;

export const Menu = styled.div`
  background-color: white;
  border-radius: 4px;
  margin-top: 8px;
  position: absolute;
  z-index: 200;
`;

interface InputProps {
  disabled?: boolean;
  error?: boolean;
}

export const Selector = styled.button<InputProps>`
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px 0 15px;
  &:focus {
    border-color: ${theme.colors.primary};
    outline: none;
  }
  border: ${(props) =>
    props.disabled
      ? `1px solid ${theme.colors.lightGrey}`
      : theme.borders(1, 'lightBlueGrey')};
  background-color: ${(props) =>
    props.disabled
      ? theme.colors.lightGrey
      : `${theme.colors.white} !important`};
`;
