import React, { useState, useEffect } from 'react';
import { Paragraph } from 'components';
import { User } from 'models';
import { userMeStorage } from 'utils';

const SpaceRequired = ({
  label,
  color,
  uppercase,
}: {
  label: string;
  color: string;
  uppercase?: boolean;
}) => {
  const [userMe, setUserMe] = useState<User>();
  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);
  const unitOfMeasure = userMe?.account?.oiUnitOfMeasure || '';

  const shortenUnit = (unit: string) => {
    switch (unit) {
      case 'Metres':
        return 'm';
      case 'Feet':
        return 'ft';
      default:
        return 'm';
    }
  };

  return (
    <Paragraph
      size="small"
      uppercase={uppercase ? true : false}
      color={color}
      mb="4px">
      {`${label} (${shortenUnit(unitOfMeasure)}`}
      <span
        style={{
          verticalAlign: 'top',
          position: 'relative',
          top: '-0.4em',
        }}>
        2
      </span>
      {')'}
    </Paragraph>
  );
};

export default SpaceRequired;
