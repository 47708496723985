import React, { useEffect } from 'react';
import { Box, IcomoonIcons, Paragraph as P, Spinner } from 'components';
import { Announcement } from 'models';
import {
  AnnouncementActionLink,
  AnnouncementContent,
  AnnouncementContentContainer,
  AnnouncementItem,
  AnnouncementsModalContainer,
  AnnouncementsTitle,
  AnnouncementsTitleContainer,
  AnnouncementUnreadIcon,
  Backdrop,
  CloseIcon,
  PastAnnouncementsLink,
} from './announcementsModalStyle';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from 'hooks';

interface AnnouncementsModalProps {
  announcements: Announcement[];
  unreadCount: number;
  isOpen: boolean;
  isLoading: boolean;
  markingAsRead: string;
  onClose?: () => void;
  onRead: (number: string) => void;
}

const AnnouncementsModal = ({
  announcements,
  isOpen,
  isLoading,
  markingAsRead,
  unreadCount,
  onClose,
  onRead,
}: AnnouncementsModalProps) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize().screenSize();
  const body = document.body;

  useEffect(() => {
    isOpen && isMobile
      ? (body.style.overflow = 'hidden')
      : (body.style.overflow = 'auto');
  }, [isOpen]);

  return (
    <>
      {isOpen ? (
        <>
          {isMobile && <Backdrop />}
          <AnnouncementsModalContainer>
            <Box p={'30px'} outlined={true} backgroundColor={'white'}>
              <AnnouncementsTitleContainer>
                <AnnouncementsTitle>
                  <IcomoonIcons icon="announcement" color="primary" size={24} />
                  <P color="primary" size={isMobile ? `normal` : `medium`} bold>
                    {t('dashboard:announcements.heading')}
                  </P>
                </AnnouncementsTitle>

                <CloseIcon onClick={onClose}>
                  <IcomoonIcons icon="close" color="blueGrey" size={16} />
                </CloseIcon>
              </AnnouncementsTitleContainer>

              {announcements.map((announcement, i) => (
                <AnnouncementItem
                  key={i}
                  gridColumnGap=".625rem"
                  gridTemplateColumns={
                    unreadCount > 0 ? `10px auto 100px` : `auto`
                  }>
                  {unreadCount > 0 && (
                    <div>
                      {!announcement.read ? <AnnouncementUnreadIcon /> : <></>}
                    </div>
                  )}
                  <AnnouncementContentContainer>
                    <P size={`small`} color={`charcoal`} bold>
                      {announcement.title}
                    </P>
                    <AnnouncementContent
                      color={`blueGrey`}
                      dangerouslySetInnerHTML={{ __html: announcement.content }}
                    />
                  </AnnouncementContentContainer>
                  {unreadCount > 0 && (
                    <AnnouncementActionLink size={`small`} color={`primary`}>
                      {!announcement.read ? (
                        <>
                          {markingAsRead !== announcement.number && (
                            <a
                              href="javascript:void(0)"
                              onClick={() => onRead(announcement.number)}>
                              {t('dashboard:announcements.mark_as_read')}
                            </a>
                          )}
                          {markingAsRead === announcement.number && (
                            <Spinner diameter="20px" />
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </AnnouncementActionLink>
                  )}
                </AnnouncementItem>
              ))}

              <PastAnnouncementsLink>
                <P size={`small`} color={`blueGrey`}>
                  <a
                    href="https://entrivo-helpandsupport.dormakaba.com/s/entrivo-announcements"
                    target="_blank"
                    rel="noopener noreferrer">
                    {t('dashboard:announcements.past_announcements_link')}
                    <IcomoonIcons
                      icon="external-link"
                      color="blueGrey"
                      size={16}
                    />
                  </a>
                </P>
              </PastAnnouncementsLink>
            </Box>
          </AnnouncementsModalContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AnnouncementsModal;
