import React from 'react';

import { Flex, Skeleton, Grid, Box } from 'components';
import { useScreenSize } from 'hooks';

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}

const SkeletonRow = () => (
  <Grid
    gridTemplateColumns="1fr 3fr 1fr 1fr 2fr 2fr 100px 60px"
    alignItems="center"
    height={65}>
    <Skeleton width={40} height={28} />
    <Skeleton width={100} height={28} />
    <Skeleton width={40} height={28} />
    <Skeleton width={80} height={28} />
    <Skeleton width={80} height={28} />
    <Skeleton width={100} height={28} />
    <Skeleton width={60} height={28} />
  </Grid>
);

const SimpleSkeletonRow = () => {
  return (
    <Grid
      gridTemplateColumns="1fr 3fr 1fr 100px"
      alignItems="center"
      height={65}>
      {' '}
      <Skeleton width={40} height={28} />
      <Skeleton width={100} height={28} />
      <Skeleton width={40} height={28} />
      <Skeleton width={60} height={28} />
    </Grid>
  );
};

// 1fr 2fr 1fr 2fr 2fr 2fr 100px 60px"

const DoorTableLoading = ({ children, isLoading, simple }: any) => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  if (isLoading) {
    if (isMobile) {
      return (
        <Grid gridRowGap="10px" gridAutoFlow="row">
          {Array.apply(0, Array(5)).map((el, i) => (
            <Skeleton key={i.toString()} width="100%" height={100} />
          ))}
        </Grid>
      );
    }

    if (simple) {
      return (
        <Box backgroundColor="white" outlined padding="18px 30px">
          <Flex flexDirection="column">
            <Skeleton width="100%" height={28} />
            <SimpleSkeletonRow />
            <SimpleSkeletonRow />
            <SimpleSkeletonRow />
            <SimpleSkeletonRow />
          </Flex>
        </Box>
      );
    }

    return (
      <Box backgroundColor="white" outlined padding="18px 30px">
        <Flex flexDirection="column">
          <Skeleton width="100%" height={28} />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
        </Flex>
      </Box>
    );
  }
  return children;
};

export default DoorTableLoading;
