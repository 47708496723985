import client from 'core/client/client';
import {
  Account,
  AccountUpdate,
  AccountSpaceUpdate,
  AccountPicklists,
  AccountList,
  AccountDoorUpdate,
} from 'models';

const ACCOUNTS_ENDPOINT = 'Accounts';

export const fetchAccountList = async () => {
  try {
    const request = await client.get(`${ACCOUNTS_ENDPOINT}`);
    const { status } = request;
    const data: AccountList[] = request.data;
    if (status === 200) {
      return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchAccount = async (id: string) => {
  try {
    const request = await client.get(`${ACCOUNTS_ENDPOINT}/${id}`);
    const { status } = request;
    const data: Account = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const updateAccount = async (id: string, payload: AccountUpdate) => {
  try {
    const request = await client.patch(`${ACCOUNTS_ENDPOINT}/${id}`, payload);
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export const updateAccountSpace = async (
  id: string,
  payload: AccountSpaceUpdate,
) => {
  try {
    const request = await client.patch(
      `${ACCOUNTS_ENDPOINT}/${id}/Space`,
      payload,
    );
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export const updateAccountDoor = async (
  id: string,
  payload: AccountDoorUpdate,
) => {
  try {
    const request = await client.patch(
      `${ACCOUNTS_ENDPOINT}/${id}/Door`,
      payload,
    );
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export const fetchAccountPicklists = async () => {
  try {
    const request = await client.get(`${ACCOUNTS_ENDPOINT}/picklists`);
    const { status } = request;
    const data: AccountPicklists = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};
