import { useLayoutEffect, useState, useEffect } from 'react';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  useLayoutEffect(() => {
    function updateWindowSize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', updateWindowSize);
    updateWindowSize();

    return () => window.removeEventListener('resize', updateWindowSize);
  }, []);

  const screenSizes = {
    mobileSml: 320,
    mobile: 480,
    tablet: 768,
    desktopSml: 1024,
    desktopLrg: 1200,
  };

  const [screenTypes, setScreenType] = useState<
    'mobile' | 'mobileSml' | 'tablet' | 'desktopSml' | 'desktopLrg'
  >();
  const { width } = windowSize;
  useEffect(() => {
    switch (true) {
      case width <= screenSizes.mobileSml:
        setScreenType('mobileSml');
        break;
      case width > screenSizes.mobileSml && width <= screenSizes.mobile:
        setScreenType('mobile');
        break;
      case width > screenSizes.mobile && width <= screenSizes.tablet:
        setScreenType('tablet');
        break;
      case width > screenSizes.tablet && width <= screenSizes.desktopSml:
        setScreenType('desktopSml');
        break;
      case width > screenSizes.desktopSml && width <= screenSizes.desktopLrg:
        setScreenType('desktopLrg');
        break;
    }
  }, [width]);

  return { windowSize, screenTypes, screenSizes };
};

export default useWindowSize;
