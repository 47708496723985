import styled from '@emotion/styled';

export const StyledDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  background: white;
  z-index: 4;
  width: 100%;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.05);
`;
