import React from 'react';

import { Paragraph as P, Grid } from 'components';
import { BarChart } from '../../CellComponents';

interface Props {
  row: any;
  navigateOnRowClick: (id: any) => void;
}

const HistoricListItem = ({ navigateOnRowClick, row }: Props) => {
  return (
    <Grid
      key={row.id}
      gridRowGap="10px"
      gridAutoFlow="row"
      onClick={() => {
        navigateOnRowClick(row.original);
      }}>
      <P bold color="charcoal" size="small">
        {row.original.space.name}
      </P>
      <BarChart dataSummary={row.original.space.dataSummary} />
    </Grid>
  );
};

export default HistoricListItem;
