import moment from 'moment';

export const DATE_PERIODS = ['Today', 'Yesterday'] as const;

export type DatePeriodT = typeof DATE_PERIODS[number];

export const generateDatePeriod = (datePeriod: DatePeriodT) => {
  if (datePeriod === 'Today') {
    return {
      startDate: moment()
        .startOf('day')
        .subtract(1, 'hour')
        .format(),
      endDate: moment()
        .startOf('hour')
        .format(),
    };
  } else {
    return {
      startDate: moment()
        .subtract(1, 'day')
        .startOf('day')
        .subtract(1, 'hour')
        .format(),
      endDate: moment()
        .subtract(1, 'day')
        .endOf('day')
        .format(),
    };
  }
};
