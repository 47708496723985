import styled from '@emotion/styled';
import { theme } from 'style';

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const RadioWrapper = styled.div`
  /* margin: 16px 0; */
  display: flex;
  align-items: center;

  input[type='radio'] {
    display: none;

    &:checked + label:before {
      border-color: ${theme.colors.primary};
    }

    &:checked + label:after {
      transform: translateY(-50%) scale(1);
    }
  }

  label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding-left: 40px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      box-sizing: border-box;
      position: absolute;
      content: '';
      top: 0;
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }

    &:before {
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border: 2px solid ${theme.colors.primary};
    }

    &:after {
      left: 5px;
      top: 50%;
      width: 10px;
      height: 10px;
      transform-origin: center;
      transform: translateY(-50%) scale(0);
      background: ${theme.colors.primary};
    }
  }
`;
