import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph as P, Flex, Spinner } from 'components';
import { Item } from './doorsStatsStyle';
import { useDoorsStatsStore } from 'stores';

interface Props {}

interface ItemWrapperProps {
  title: string;
  count: any;
  isLoading: boolean;
}

const ItemWrapper = ({ title, count, isLoading }: ItemWrapperProps) => (
  <Item data-cypress="doors-stats-item">
    {!isLoading ? (
      <>
        <P size="small" color="white" bold>
          {title.toUpperCase()}
        </P>
        <P size="large" color="white" bold>
          {count}
        </P>
      </>
    ) : (
      <Spinner color="white" />
    )}
  </Item>
);

const DoorsStats = ({}: Props) => {
  const { t } = useTranslation();
  const { doorsStatsData, isLoading } = useDoorsStatsStore();

  return (
    <Flex alignItems="center">
      <ItemWrapper
        isLoading={isLoading}
        title={t('common:status.commissioned')}
        count={doorsStatsData.commissionedCount}
      />
      <ItemWrapper
        isLoading={isLoading}
        title={t('common:status.decommissioned')}
        count={doorsStatsData.decommissionedCount}
      />
      <ItemWrapper
        isLoading={isLoading}
        title={t('common:status.offline')}
        count={doorsStatsData.offlineCount}
      />
    </Flex>
  );
};

export default DoorsStats;
