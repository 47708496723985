import Amplify, { Auth } from 'aws-amplify';
import i18n from 'i18next';

import config from 'config';
import {
  ConfirmTempPassword,
  Login,
  ForgotPasswordSubmit as ForgotPasswordSubmitModel,
  ChangePassword,
} from 'models';
import { getLanguage } from 'utils/getLanguage';
import { setLanguage } from 'utils/setLanguage';

Amplify.configure(config.cognito);

export const CheckSessionValid = async () => {
  try {
    const session = await Auth.currentSession();
    const isValid = await session.isValid();
    console.log('check session valid: ', isValid);
    return isValid;
  } catch (err) {
    throw err;
  }
};

export const SignIn = async (payload: Login) => {
  const { username, password } = payload;
  try {
    window.localStorage.clear();
    const user = await Auth.signIn(username, password);
    return user;
  } catch (err) {
    throw err;
  }
};

export const SignOut = async () => {
  try {
    const res = await Auth.signOut();
    const language = getLanguage();
    window.localStorage.clear();
    setLanguage(language);
    return res;
  } catch (err) {
    throw err;
  }
};

export const ActivateAccount = async (payload: ConfirmTempPassword) => {
  const { tempPassword, newPassword, username } = payload;
  try {
    const user = await Auth.signIn(username, tempPassword);

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const res = await Auth.completeNewPassword(user, newPassword, {});
      return res;
    }
  } catch (err) {
    throw err;
  }
};

export const ForgotPassword = async (username: string) => {
  try {
    const res = await Auth.forgotPassword(username);
    return res;
  } catch (err) {
    throw err;
  }
};

export const ForgotPasswordSubmit = async (
  payload: ForgotPasswordSubmitModel,
) => {
  const { username, new_password, code } = payload;
  try {
    const res = await Auth.forgotPasswordSubmit(username, code, new_password);
    return res;
  } catch (err) {
    throw err;
  }
};

export const changePassword = async (payload: ChangePassword) => {
  const { oldPassword, newPassword } = payload;
  try {
    const user = await Auth.currentAuthenticatedUser();
    const res = await Auth.changePassword(user, oldPassword, newPassword);
    return res;
  } catch (err) {
    throw err;
  }
};

export const errorTranslate = (err: string) => {
  switch (err) {
    case 'NotAuthorizedException':
      return i18n.t('cognitoErrors:NotAuthorizedException');
    case 'ExpiredCodeException':
      return i18n.t('cognitoErrors:ExpiredCodeException');
    case 'InvalidPasswordException':
      return i18n.t('cognitoErrors:InvalidPasswordException');
    case 'CodeMismatchException':
      return i18n.t('cognitoErrors:CodeMismatchException');
    case 'UsernameExists':
      return i18n.t('cognitoErrors:UsernameExists');
    case 'LimitExceededException':
      return i18n.t('cognitoErrors:LimitExceededException');
    default:
      return i18n.t('cognitoErrors:DefaultError');
  }
};
