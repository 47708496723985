import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import {
  FormComponents,
  Paragraph as P,
  IcomoonIcons,
  Button,
  Flex,
  ToolTip,
} from 'components';
import { useScreenSize } from 'hooks';
import { DoorDetails, DoorStatus } from 'models';
import { capitalizeFirstLetter, isDIS } from 'utils';
import { Container, StyledRow } from './detailsSectionStyle';
import { Spacer, theme } from 'style';
import { requestRefreshData } from 'services';
import { RemoteActionType } from 'models/RemoteActionType';
import { useDoorDetailsStore } from 'stores';

interface DetailsSectionProps {
  doorDetails: DoorDetails;
  doorStatus: DoorStatus;
  toggleEdit: (() => void) | undefined;
}

const DetailsSection = ({
  doorDetails,
  doorStatus,
  toggleEdit,
}: DetailsSectionProps) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize().screenSize();
  const {doorDetailsFetch, doorStatusFetch,  isLoading: isDetailsLoading, isDoorStatusLoading, } = useDoorDetailsStore();
  const isLoading = isEmpty(doorDetails) || isDetailsLoading;  
  const [isRefreshingData, setIsRefreshingData] = useState<boolean>(false); 

  let translatedSyncSchedule = doorDetails?.syncSchedule;

  if (translatedSyncSchedule) {
    translatedSyncSchedule = translatedSyncSchedule
      .replace('mins', t('settings:door_settings.refresh_cycle_field.mins'))
      .replace('Hourly', t('settings:door_settings.refresh_cycle_field.hourly'))
      .replace(
        'Daily (max)',
        t('settings:door_settings.refresh_cycle_field.daily_max'),
      );
  }

  const refreshData = async () => {
    setIsRefreshingData(true);
    await requestRefreshData(
      doorDetails.icNumber,
      RemoteActionType.REFRESH_DATA,
      '',
    )
      .then(() => {        
        doorDetailsFetch(doorDetails.id);
        doorStatusFetch(doorDetails.id);        
        setIsRefreshingData(false);
      })      
      .catch(() => {
        setIsRefreshingData(false);
      });
  };

  return (
    <FormComponents.Wrapper>
      <FormComponents.Header
        heading={t('doorDetails:details')}
        onClick={toggleEdit}
        paddingSize={toggleEdit ? 'small' : 'large'}
      />
      <Container>
        {isMobile ? (
          <>
            <StyledRow>
              <FormComponents.InActive
                border={false}
                loading={isLoading}
                title={t('doorDetails:edit_form.labels.type')}
                content={doorDetails?.type}
              />
            </StyledRow>
            <StyledRow>
              <FormComponents.InActive
                border={false}
                loading={isDoorStatusLoading}
                title={t('doorDetails:edit_form.labels.program_switch_mode')}
                content={capitalizeFirstLetter(doorStatus?.programSwitchMode)}
              />
            </StyledRow>
          </>
        ) : (
          <StyledRow border={!isMobile}>
            <FormComponents.InActive
              border={false}
              loading={isLoading}
              title={t('doorDetails:edit_form.labels.type')}
              content={doorDetails?.type}
            />
            <FormComponents.InActive
              border={false}
              loading={isDoorStatusLoading}
              title={t('doorDetails:edit_form.labels.program_switch_mode')}
              content={capitalizeFirstLetter(
                doorStatus.isOffline
                  ? t('common:status.unavailable')
                  : doorStatus?.programSwitchMode,
              )}
            />
          </StyledRow>
        )}

        {(isDIS() || doorDetails?.type !== 'EL301') && (
          <StyledRow border={!isMobile}>
            {isDIS() && (
              <FormComponents.InActive
                border={false}
                loading={isLoading}
                title={t('doorDetails:edit_form.labels.account')}
                content={doorDetails?.account?.name || ''}
              />
            )}
            {doorDetails?.type !== 'EL301' && (
              <FormComponents.InActive
                border={false}
                loading={isDoorStatusLoading}
                title={t('doorDetails:edit_form.labels.locking_status')}
                content={
                  doorStatus.isOffline
                    ? t('common:status.unavailable')
                    : capitalizeFirstLetter(doorStatus?.lockStatus)
                }
              />
            )}
          </StyledRow>
        )}

        <StyledRow border={!isMobile}>
          <FormComponents.InActive
            border={false}
            loading={isLoading}
            title={t('doorDetails:edit_form.labels.connector_installation')}
            content={moment(doorDetails?.installDate).format('DD/MM/YYYY')}
          />
          <FormComponents.InActive
            border={false}
            loading={isLoading}
            title={t('doorDetails:edit_form.labels.connectivity_solution')}
            content={doorDetails?.deviceType}
          />
        </StyledRow>
        <StyledRow border={!isMobile}>
          <FormComponents.InActive
            border={false}
            loading={isLoading}
            title={t('doorDetails:edit_form.labels.refresh_frequency')}
            content={translatedSyncSchedule}
            tooltip={t('doorDetails:edit_form.labels.type_tooltip')}
          />
        </StyledRow>
        <StyledRow border={!isMobile}>
          {doorStatus.isOffline ? (
            <FormComponents.InActive
              border={false}
              loading={isDoorStatusLoading}
              title={t('doorDetails:page_header.last_sync')}
              content={
                <Flex alignItems="center">
                  <IcomoonIcons icon="alert-triangle" size={16} color="error" />
                  <P color={theme.colors.error} pl={1} pt={1} fontWeight={600}>
                    {moment(doorStatus?.lastUpdate).format(
                      'DD/MM/YYYY HH:mm:ss',
                    )}
                  </P>
                </Flex>
              }
              tooltip={t('common:status_tooltip.offline')}
              tooltipIcon="offline"
            />
          ) : (
            <FormComponents.InActive
              border={false}
              loading={isDoorStatusLoading || isRefreshingData}
              title={t('doorDetails:page_header.last_sync')}
              content={<Flex><span>{moment(doorStatus?.lastUpdate).format(
                'DD/MM/YYYY HH:mm:ss',
              )}</span>
              <Spacer width={5} />
              <span style={{cursor: 'pointer'}} onClick={refreshData}>              
              <ToolTip
                content={t('doorDetails:page_header.refresh_data_tooltip')}
                iconSize={24}
                iconType={'refresh'}
                isInline={true}               
              />              
              </span></Flex>}
            />
          )}
        </StyledRow>
      </Container>
    </FormComponents.Wrapper>
  );
};

export default DetailsSection;
