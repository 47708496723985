import React, { useMemo, useEffect } from 'react';
import { useTable, CellProps, useSortBy } from 'react-table';
import i18n from 'core/i18n/i18n';
import { isIOS } from 'react-device-detect';

import { useScreenSize } from 'hooks';
import { Table, Paragraph, StatusIndicator, Grid, ToolTip } from 'components';
import { TrafficNode, TrafficDevice, DailyTraffic } from 'models';
import { theme } from 'style';
import { DeviceItem } from './DeviceItem/DeviceItem';
import { getRandomHexColour } from 'utils';

interface TableProps {
  data: DailyTraffic;
  lastRefresh: any;
  activeDevice?: string;
  setActiveDevice: any;
}
export const DEVICE_COLORS = [
  theme.colors.primary,
  theme.colors.charcoal,
  theme.colors.secondary,
  theme.colors.success,
  theme.colors.alerts.batteryError,
  theme.colors.pending,  
  theme.colors.alerts.sabotage,
  theme.colors.alerts.securityOpen,
];

export const getDeviceColor = (index: number) => {
  if(index < DEVICE_COLORS.length) {
    return DEVICE_COLORS[index];
  }

  const newColor = getRandomHexColour();
  DEVICE_COLORS.push(newColor);

  return newColor;
}

const formatLastRefresh = (lastRefresh: any) => {
  const split = lastRefresh.split(':');

  if (split.length > 1) {
    return split[0] + ':00';
  } else {
    return lastRefresh;
  }
};

export const DailyTrafficTable = ({
  data,
  setActiveDevice,
  activeDevice,
}: TableProps) => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: i18n.t('common:name'),
            accessor: 'deviceName',
            sortable: true,
            id: 'Name',
            Cell: (props: CellProps<{}, string>) => (
              <StatusIndicator
                label={props.value}
                indicatorColor={getDeviceColor(props.row.index)}
              />
            ),
          },
          {
            Header: i18n.t(
              'spaceDetails:charts.daily_traffic_chart.connectivity',
            ),
            accessor: 'isOnline',
            id: 'Connectivity',
            sortable: true,
            Cell: (props: CellProps<{}, string>) => (
              <Paragraph size="small">
                {props.value
                  ? i18n.t('spaceDetails:charts.daily_traffic_chart.online')
                  : i18n.t('spaceDetails:charts.daily_traffic_chart.offline')}
              </Paragraph>
            ),
          },
          {
            Header: i18n.t(
              'spaceDetails:charts.daily_traffic_chart.in_traffic',
            ),
            accessor: 'totalIn',
            sortable: true,

            id: 'InTraffic',
            Cell: (props: CellProps<{}, TrafficNode[]>) => (
              <Paragraph size="small">{props.value}</Paragraph>
            ),
          },
          {
            Header: i18n.t(
              'spaceDetails:charts.daily_traffic_chart.out_traffic',
            ),
            id: 'OutTraffic',
            accessor: 'totalOut',
            sortable: true,
            Cell: (props: CellProps<{}, TrafficNode[]>) => (
              <Paragraph size="small">{props.value}</Paragraph>
            ),
          },
          {
            Header: () => (
              <Grid
                alignItems="center"
                gridTemplateColumns="repeat(2,max-content)"
                gridColumnGap="10px">
                {i18n.t(
                  'spaceDetails:charts.daily_traffic_chart.last_refreshed',
                )}
                <ToolTip
                  content={i18n.t(
                    'spaceDetails:charts.daily_traffic_chart.last_refreshed_tooltip',
                  )}
                />
              </Grid>
            ),
            id: 'lastRefreshed',
            accessor: 'lastRefreshed',
            Cell: (props: any) => {
              return (
                <Paragraph size="small">
                  {/* {formatLastRefresh(lastRefresh)} */}
                  {formatLastRefresh(props.value)}
                </Paragraph>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  const handleRowClick = (row: TrafficDevice) => {
    setActiveDevice(row.id);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: data.devices }, useSortBy);

  if (isMobile || isIOS) {
    return (
      <Grid gridAutoFlow="row" gridRowGap="15px">
        {rows.map((row: any) => (
          <DeviceItem
            row={row}
            key={row.id}
            activeDevice={activeDevice}
            setActiveDevice={setActiveDevice}
          />
        ))}
      </Grid>
    );
  }

  return (
    <div data-testid="daily-traffic-table">
      <Table
        columns="2fr 140px 140px 140px 1fr"
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        showNavigateToArrow={false}
        selectedRow={activeDevice}
        selectedRowAction={setActiveDevice}
      />
    </div>
  );
};
export default DailyTrafficTable;
