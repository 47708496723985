import { mapStyles } from './mapStyles';

export const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

export const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  minZoom: 2,
  maxZoom: 22,
  scrollwheel: true,
  draggable: true,
  disableDoubleClickZoom: true,
  zoomControl: true,
};
