import {
  ActionBar,
  Container,
  DateSlideToggle,
  Flex,
  PageHeader,
  Paragraph as P,
} from 'components';
import {
  usePrevious,
  useScreenSize,
  useTableFilter,
  useTableSearch,
  useTableSortBy,
} from 'hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertListParams } from 'models/AlertListParams';
import { SearchBarProps } from 'components/SearchBar/SearchBar';
import { AlertsList } from 'models/AlertsList';
import AlertTableLoading from './AlertsTable/LoadingSkeleton/LoadingSkeleton';
import AlertsTable from './AlertsTable/AlertsTable';
import {
  Alert,
  DoorSummary,
  LabelValue,
  NotificationType,
  SelectedDates,
  SelectedDateTypeEnum,
  User,
} from 'models';
import {
  alertTranslations,
  applyFilterTranslations,
  isDIS,
  notificationTypeTranslations,
  shouldShowModeElement,
  shouldShowModeWarning,
} from 'utils';
import { DropdownSelectProps } from 'components/DropdownSelect/DropdownSelect';
import { SectionHeader } from './alertsStyles';
import {
  fetchAccountList,
  fetchAllAlerts,
  fetchAllTypes,
  fetchAvailableNotificationTypes,
  fetchAllDoors,
} from 'services';
import { OptionTypeBase } from 'react-select';
import moment from 'moment';
import ModeLabel from 'components/ModeLabel/ModeLabel';
import { getNotificationTypeOptions } from 'utils/getNotificationTypeOptions';

const TableError = ({
  heading,
  subheading,
}: {
  heading: string;
  subheading: string;
}) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    paddingTop={window.innerHeight / 2 - 350}>
    <P size="large" color="primary" bold mb="15px">
      {heading}
    </P>
    <P size="normal" color="charcoal">
      {subheading}
    </P>
  </Flex>
);

const formatAccess = (access: string[]) =>
  access.length > 1 ? access.join(';') : access[0];

export const getAlertTypeOptions = (
  alertTypesData: Alert[],
  filterBy?: NotificationType,
) => {
  const alertTypeOptions = alertTypesData
    ?.filter((alertType: any) => {
      if (!shouldShowModeElement()) {
        return alertType.notificationType !== NotificationType.Mode;
      } else {
        return true;
      }
    })
    .map(({ alertId, alertDisplayName }: Alert) => ({
      value: alertId,
      label: alertDisplayName,
    }));

  const result = filterBy
    ? alertTypeOptions.filter((a) => a.value === filterBy)
    : alertTypeOptions;

  result?.sort((a, b) => (a.label > b.label ? 1 : -1));
  return result;
};

const Alerts = () => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const { t } = useTranslation();
  const query = new URLSearchParams(window.location.search);

  const [productsCanAccess, setProductsCanAccess] = useState<string>('');

  useEffect(() => {
    const userMe = window.localStorage.getItem('userMe');
    if (userMe) {
      const userDetails: User = JSON.parse(userMe);
      const access = formatAccess(userDetails.productsCanAccess);
      setProductsCanAccess(access);
    }
  }, []);

  const [listParams, setListParams] = useState<AlertListParams>(
    {} as AlertListParams,
  );

  const [doorList, setDoorList] = useState<any[]>([
    {
      label: t('doorList:dropdown_toggle.all'),
      value: '',
    },
  ]);

  const { searchValue, searchHandler, clearSearch } = useTableSearch(
    listParams,
    setListParams,
    false,
  );

  const clearSearchWrapper = () => {
    clearSearch();
    setListParams({
      ...listParams,
      doorUUID: '',
    });
    fetch(1);
  };

  const searchBarSettings: SearchBarProps = {
    placeholder: t('doorList:dropdown_toggle.all'),
    searchValue: listParams.doorUUID || '',
    searchHandler,
    clearSearch: clearSearchWrapper,
    name: 'account-id',
    options: doorList,
  };

  const [filtersFromServer, setFiltersFromServer] = useState<any>({
    status: [],
    accountId: [],
    type: [],
    operatorType: [],
  });

  const [statusFilter] = useTableFilter(listParams, setListParams, {
    options: filtersFromServer.status,
    label: t('alerts:table.filters.status'),
    name: 'status',
  });
  const [typeFilter] = useTableFilter(listParams, setListParams, {
    options: filtersFromServer.type,
    label: t('alerts:table.filters.event'),
    name: 'type',
  });
  const [operatorTypeFilter] = useTableFilter(listParams, setListParams, {
    options: filtersFromServer.operatorType,
    label: t('alerts:table.filters.operator_type'),
    name: 'operatorType',
  });
  const [accountIdFilter] = useTableFilter(listParams, setListParams, {
    options: filtersFromServer.accountId,
    label: t('alerts:table.filters.account_name'),
    name: 'accountId',
  });

  const filters: DropdownSelectProps[] = [
    statusFilter,
    operatorTypeFilter,
    typeFilter,
  ];

  if (isDIS()) {
    filters.push(accountIdFilter);
  }

  const limit = 20;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [data, setData] = useState<AlertsList[]>([]);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [selectedDates, setSelectedDates] = useState<SelectedDates>({
    startDate:
      query.get('dateType') == 'now'
        ? null
        : query.get('startDate')
        ? (query.get('startDate') || '').replace(' ', '+')
        : moment()
            .startOf('isoWeek')
            .format(),
    endDate: query.get('endDate')
      ? (query.get('endDate') || '').replace(' ', '+')
      : moment()
          .endOf('isoWeek')
          .format(),
  });

  const [selectedDateType, setSelectedDateType] = useState<
    SelectedDateTypeEnum
  >(
    query.get('dateType')
      ? (query.get('dateType') as SelectedDateTypeEnum)
      : 'now',
  );

  const dropdownOptions = shouldShowModeElement()
    ? applyFilterTranslations(
        [
          {
            id: 'All Types',
            value: NotificationType.All,
          },
          {
            id: NotificationType.Alert,
            value: NotificationType.Alert,
          },
          {
            id: NotificationType.Mode,
            value: NotificationType.Mode,
          },
          {
            id: NotificationType.CriticalAlert,
            value: NotificationType.CriticalAlert,
          },
          {
            id: NotificationType.GeneralAlert,
            value: NotificationType.GeneralAlert,
          },
        ],
        notificationTypeTranslations,
      )
    : applyFilterTranslations(
        [
          {
            id: NotificationType.Alert,
            value: NotificationType.Alert,
          },
        ],
        notificationTypeTranslations,
      );

  const [dropdownValue, setDropdownValue] = useState<OptionTypeBase>(
    dropdownOptions[0],
  );

  const dropdownHandler = (e: typeof dropdownOptions[0]) => {
    // This handler is what sets the notification type to Mode
    console.log('dropdownHandler value: ', e);
    setListParams({
      ...listParams,
      notificationType: e.value as NotificationType,
    });
    setDropdownValue(e);

    fetchAvailableNotificationTypes().then((types) => {
      console.log('types: ', types);
      const filteredTypes = types?.filter((t) =>
        e.value === NotificationType.All
          ? true
          : t.notificationType === e.value,
      );
      setFiltersFromServer({
        ...filtersFromServer,
        type: getNotificationTypeOptions(filteredTypes ?? []),
      });
    });
  };

  const dropdownToggleSettings: DropdownSelectProps = {
    options: dropdownOptions,
    onChange: dropdownHandler,
    name: 'notificationType',
    value: dropdownValue,
    label: t('alerts:table.filters.notification_type'),
    clear: () => {
      setDropdownValue(dropdownOptions[0]);
    },
  };

  useEffect(() => {
    setListParams({
      ...listParams,
      startDate: encodeURIComponent((selectedDates.startDate as string) || ''),
      endDate: encodeURIComponent((selectedDates.endDate as string) || ''),
    });
  }, [selectedDates]);

  useEffect(() => {
    setListParams({
      ...listParams,
      doorUUID:
        searchValue === null && query.get('doorUUID')
          ? query.get('doorUUID') || ''
          : searchValue || '',
    });
  }, [searchValue]);

  useEffect(() => {
    setListParams({
      type: query.get('type') || '',
      status: query.get('status') || '',
      doorUUID:
        searchValue === null && query.get('doorUUID')
          ? query.get('doorUUID') || ''
          : searchValue || '',
      startDate: encodeURIComponent((selectedDates.startDate as string) || ''),
      endDate: encodeURIComponent((selectedDates.endDate as string) || ''),
      accountId: query.get('accountId') || '',
      followedOnly: query.get('followedOnly') === 'true' ? true : undefined,
      commissionedOrDecommissioned:
        query.get('commissionedOrDecommissioned') === 'true',
    });
  }, []);

  useEffect(() => {
    if (productsCanAccess) {
      fetch(1);
    }
  }, [productsCanAccess]);

  const previousParams = usePrevious(listParams);
  useEffect(() => {
    if (
      previousParams &&
      JSON.stringify(listParams) !== JSON.stringify(previousParams)
    ) {
      fetch(1);
    }
  }, [listParams]);

  const { sortByHandler } = useTableSortBy(listParams, setListParams);
  const sortWrapper = (sortBy: any) => {
    return sortByHandler(sortBy);
  };

  const fetch = async (page = 1) => {
    const { ...params } = listParams;
    const searchParams = {
      ...params,
      sortBy: params.sortBy ?? 'status',
      ascending: params.ascending ?? true,
      offset: (page - 1) * limit,
      limit,
    };

    setIsLoading(true);
    setCurrentPage(page);

    const alertsData: any = await fetchAllAlerts(searchParams);
    const alerts = alertsData.data;

    setData(alerts);
    setTotalNotifications(alertsData.total);
    setIsLoading(false);
  };

  const fetchFilters = async () => {
    const alertTypesData = await fetchAvailableNotificationTypes();

    const operatorTypesData: LabelValue[] = await fetchAllTypes(
      listParams.accountId || query.get('accountId') || '',
    );
    const operatorTypes = operatorTypesData.map(({ label, value }) => ({
      value,
      label: label,
    }));
    const accountListData = await fetchAccountList();
    const accountList = accountListData?.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
    setFiltersFromServer({
      status: applyFilterTranslations(
        [
          {
            id: 'Active',
            value: 'Active',
          },
          {
            id: 'Inactive',
            value: 'Inactive',
          },
        ],
        alertTranslations,
      ),
      accountId: accountList?.sort((a, b) => (a.label > b.label ? 1 : -1)),
      type: getNotificationTypeOptions(alertTypesData ?? []),
      operatorType: operatorTypes?.sort((a, b) => (a.label > b.label ? 1 : -1)),
    });
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    if (listParams.hasOwnProperty('accountId')) {
      fetchDoors();
    }
  }, [listParams.accountId]);

  const fetchDoors = async () => {
    const doorListData = await fetchAllDoors({
      params: {
        accountId: listParams.accountId,
      },
      followedOnly: listParams.followedOnly || false,
    });

    const doorListLabelValue: LabelValue[] =
      doorListData?.doors?.map((door: DoorSummary) => ({
        label: door.name,
        value: door.id,
      })) || [];

    setDoorList(
      [
        {
          label: t('doorList:dropdown_toggle.all'),
          value: '',
        },
      ].concat(doorListLabelValue.sort((a, b) => (a.label > b.label ? 1 : -1))),
    );
  };

  const onLoadMore = (nextPage: number) => {
    fetch(nextPage);
  };

  const paginated = totalNotifications > limit;

  const hasModeEvent = (data: AlertsList[]) => {
    return (
      data?.filter((d) => d.notificationType === NotificationType.Mode)
        ?.length > 0
    );
  };

  return (
    <>
      {!isMobile && <PageHeader heading={t('navigation:notifications')} />}

      <ActionBar
        listParams={listParams}
        dropdownToggle={dropdownToggleSettings} // TODO: remove dropdownToggle from ActionBar params
        setListParams={setListParams}
        searchBarSettings={searchBarSettings}
        searchFilter="doorUUID"
        filters={filters}
        isFiltersExpanded={false}
        toggleExpandedOnClear={false}
      />

      <Container pt={[0, '30px']} pb="30px">
        <SectionHeader>
          {totalNotifications !== 0 ? (
            <P color="charcoal">
              {t('common:showing_total.showing')}{' '}
              {currentPage === 1
                ? data.length
                : `${(currentPage - 1) * limit + 1} - ${(currentPage - 1) *
                    limit +
                    data.length}`}{' '}
              {t('common:showing_total.of')} {totalNotifications}{' '}
              {t('common:showing_total.notifications')}
            </P>
          ) : (
            <div></div>
          )}
          <DateSlideToggle
            defaultDates={selectedDates}
            setDateRange={setSelectedDates}
            dataType={query.get('dateType') || ''}
            setDateType={setSelectedDateType}
            options={['all', 'day', 'week', 'month', 'custom']}
          />
        </SectionHeader>
        {!isLoading && data.length === 0 ? (
          <TableError
            heading={t('alerts:table.errors.empty.heading')}
            subheading={t('alerts:table.errors.empty.subheading')}
          />
        ) : (
          <AlertTableLoading isLoading={isLoading}>
            {shouldShowModeWarning() && hasModeEvent(data) && (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: isMobile ? '5fr 3fr' : '4fr 1fr',
                  paddingBottom: '20px',
                }}>
                <div />
                <ModeLabel variant="small">
                  {t('common:mode_label.notifications')}
                </ModeLabel>
              </div>
            )}
            <AlertsTable
              sortBy={sortWrapper}
              data={data}
              showAccountName={isDIS()}
              isLoadingMore={isLoadingMore}
              onLoadMore={onLoadMore}
              total={totalNotifications}
              currentPage={currentPage}
              limit={limit}
              paginated={paginated}
              selectedDates={selectedDates}
              selectedDateType={selectedDateType}
            />
          </AlertTableLoading>
        )}
      </Container>
    </>
  );
};

export default Alerts;
