import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { isIE } from 'react-device-detect';
import { getCurrentProduct } from 'utils';

import App from './App';
import 'normalize.css';
import * as serviceWorker from './serviceWorker';

import 'core/i18n/i18n';

import BrowserNotSupported from 'utils/BrowserNotSupported';

const product = getCurrentProduct();

document.title =
  process.env.REACT_APP_ENV === 'production'
    ? 'EntriWorX Insights'
    : `[${process.env.REACT_APP_ENV}${
        product === 'OI'
          ? ' - OccupancyInsights'
          : product === 'DI' || product == 'DIS'
          ? ' - DoorInsights'
          : ''
      }] EntriWorX Insights`;

//Uncommment this if you want MSW to intercept and mock calls. Check in handlers file to check what calls MSW is handling
// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const { worker } = require('./mocks/browser');
//   worker.start();
// }

ReactDOM.render(
  isIE ? (
    <BrowserNotSupported />
  ) : (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  ),
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//comment below out if you want to use MSW
serviceWorker.unregister();
