import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  Radio,
  FormComponents,
  Paragraph as P,
  NoMobileWarning,
} from 'components';
import { LabelValue, NotificationType } from 'models';
import {
  isDI,
  isDIS,
  isDualProducts,
  isOI,
  shouldShowModeElement,
  shouldShowModeWarning,
} from 'utils';
import { Spacer } from 'style';
import ModeLabel from 'components/ModeLabel/ModeLabel';
import { fetchAvailableNotificationTypes } from 'services';
import NoAlertTypeWarning from 'components/NoAlertTypeWarning/NoAlertTypeWarning';
import { getNotificationTypeOptions } from 'utils/getNotificationTypeOptions';

interface Props {
  DINotificationScopes?: LabelValue[];
  OINotificationScopes?: LabelValue[];
  doesUserWantNotificationsDI: boolean;
  doesUserWantAlertsDI: boolean;
  removeOINotifications: boolean;
  showMobileWarningDI: boolean;
  showMobileWarningOI: boolean;
  showAlertTypesWarningDI: boolean;
}

export const Form = ({
  DINotificationScopes,
  OINotificationScopes,
  doesUserWantNotificationsDI,
  doesUserWantAlertsDI,
  removeOINotifications,
  showMobileWarningDI,
  showMobileWarningOI,
  showAlertTypesWarningDI,
}: Props) => {
  const { t } = useTranslation();

  const [generalAlertTypes, setGeneralAlertTypes] = useState<LabelValue[]>([]);
  const [criticalAlertTypes, setCriticalAlertTypes] = useState<LabelValue[]>(
    [],
  );

  const fetchAlertTypes = async () => {
    const alertTypesData = await fetchAvailableNotificationTypes();
    const generalAlertTypeOptions = getNotificationTypeOptions(
      alertTypesData ?? [],
      NotificationType.Alert,
      true,
    ).filter((a) => !a.isCritical);
    const criticalAlertTypeOptions = getNotificationTypeOptions(
      alertTypesData ?? [],
      NotificationType.Alert,
      true,
    ).filter((a) => a.isCritical);
    setGeneralAlertTypes(generalAlertTypeOptions);
    setCriticalAlertTypes(criticalAlertTypeOptions);
  };

  useEffect(() => {
    fetchAlertTypes();
  }, []);

  return (
    <FormComponents.Section layout="single">
      {(isDIS() || isDI() || isDualProducts()) && (
        <>
          {isDualProducts() && (
            <P color="primary" size={'medium'} bold mb="20px">
              {t('profile:communication_preferences.diHeading')}
            </P>
          )}
          <P size="small" color="charcoal" mb={`35px`}>
            {t('userOnboarding:section_info.notifications')}
          </P>

          <Radio
            label={t('profile:communication_preferences.notifications.which')}
            labelSize={`small`}
            labelColor={`charcoal`}
            labeltooltip={t(
              'profile:communication_preferences.notifications.which_tooltip',
            )}
            name="DINotificationPreferences.what"
            radioItems={DINotificationScopes || []}
          />
          <Spacer height={10} />

          {doesUserWantAlertsDI && (
            <>
              <Checkbox
                label={t(
                  'profile:communication_preferences.notifications.alert_types_critical',
                )}
                name="DINotificationPreferences.criticalAlertTypes"
                checkboxItems={criticalAlertTypes}
                labeltooltip={t(
                  'profile:communication_preferences.notifications.alert_types_tooltip',
                )}
                columns={3}
                selectAllItem={{
                  label: t('alerts:names.allCritical'),
                  value: NotificationType.AllCritical,
                }}
              />
              <Checkbox
                label={t(
                  'profile:communication_preferences.notifications.alert_types_general',
                )}
                name="DINotificationPreferences.generalAlertTypes"
                checkboxItems={generalAlertTypes}
                labeltooltip={t(
                  'profile:communication_preferences.notifications.alert_types_tooltip',
                )}
                columns={3}
                selectAllItem={{
                  label: t('alerts:names.allGeneral'),
                  value: NotificationType.AllGeneral,
                }}
              />
              {showAlertTypesWarningDI && <NoAlertTypeWarning />}
            </>
          )}

          {shouldShowModeElement() && (
            <>
              <Radio
                label={t(
                  'profile:communication_preferences.notifications.mode',
                )}
                labelSize={`small`}
                labelColor={`charcoal`}
                labeltooltip={t(
                  'profile:communication_preferences.notifications.mode_tooltip',
                )}
                name="DINotificationPreferences.mode"
                radioItems={DINotificationScopes || []}
                idPrefix="mode"
                labelWarning={
                  shouldShowModeWarning() && (
                    <ModeLabel variant="small">
                      {t('common:mode_label.onboarding')}
                    </ModeLabel>
                  )
                }
              />
              <Spacer height={10} />
            </>
          )}

          {doesUserWantNotificationsDI && (
            <>
              <Checkbox
                label={t('profile:communication_preferences.notifications.how')}
                labelSize={`small`}
                labelColor={`charcoal`}
                name="DINotificationPreferences.how"
                checkboxItems={[
                  {
                    label: t('forms:communication_preferences.methods.sms'),
                    value: 'sms',
                  },
                  {
                    label: t('forms:communication_preferences.methods.email'),
                    value: 'email',
                  },
                ]}
              />
              {showMobileWarningDI && <NoMobileWarning />}
            </>
          )}
        </>
      )}

      {isDualProducts() && <hr />}

      {(isOI() || isDualProducts()) && (
        <>
          {isDualProducts() && (
            <P color="primary" size={'medium'} bold mb="20px">
              {t('profile:communication_preferences.oiHeading')}
            </P>
          )}
          <P size="small" color="charcoal" mb={`35px`}>
            {t('userOnboarding:section_info.notifications')}
          </P>
          <Radio
            label={t('profile:communication_preferences.notifications.what')}
            labelSize={`small`}
            labelColor={`charcoal`}
            name="OINotificationPreferences.what"
            radioItems={OINotificationScopes || []}
            idPrefix="oi"
          />
          <Spacer height={10} />

          {removeOINotifications && (
            <>
              <Checkbox
                label={t(
                  'profile:communication_preferences.notifications.when',
                )}
                labelSize={`small`}
                labelColor={`charcoal`}
                name="OINotificationPreferences.when"
                checkboxItems={[
                  {
                    label: t(
                      'forms:communication_preferences.frequencies.exceeding',
                    ),
                    value: 'alertExceeded',
                  },
                  {
                    label: t(
                      'forms:communication_preferences.frequencies.approaching',
                    ),
                    value: 'alertApproaching',
                  },
                ]}
              />
              <Spacer height={10} />

              <Checkbox
                label={t('profile:communication_preferences.notifications.how')}
                labelSize={`small`}
                labelColor={`charcoal`}
                name="OINotificationPreferences.how"
                checkboxItems={[
                  {
                    label: t('forms:communication_preferences.methods.sms'),
                    value: 'sms',
                  },
                  {
                    label: t('forms:communication_preferences.methods.email'),
                    value: 'email',
                  },
                ]}
              />
              {showMobileWarningOI && <NoMobileWarning />}
            </>
          )}
        </>
      )}
    </FormComponents.Section>
  );
};
