import styled from '@emotion/styled';
import {
  layout,
  space,
  background,
  border,
  color,
  compose,
} from 'styled-system';

import { BoxProps } from './Box';

export const StyledDiv = styled.div<BoxProps>`
  background-color: white;
  position: relative;
  box-sizing: border-box;
  border-radius: ${(props) => (props.square ? 0 : '4px')};
  box-shadow: ${(props) => (props.elevated ? props.theme.boxShadow : '0')};
  border: ${({ outlined, theme: { borders } }) =>
    outlined ? `${borders(1, 'lightBlueGrey')}` : 'none'};
  ${compose(layout, space, background, color, border)}
`;
