import React from 'react';
import IcomoonReact from 'icomoon-react';
import iconSet from './icons.json';

import { theme } from 'style';

// LEAVE COMMENTED UNTIL I CAN FIGURE OUT HOW TO MAP THE RETURNED ARRAY TO A TYPE
// const IconNamesArray = iconSet.icons.map((item) => item.properties.name);
// console.log(IconNamesArray);
// type IconNames = typeof IconNamesArray[number];

export type IcomoonIconNames =
  | 'add'
  | 'alert-1'
  | 'alert'
  | 'alert-triangle'
  | 'assets'
  | 'check'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'close'
  | 'copy'
  | 'dashboard'
  | 'download'
  | 'dropdown'
  | 'edit'
  | 'error'
  | 'export'
  | 'favourite'
  | 'favourite-filled'
  | 'filter-'
  | 'filter'
  | 'help-circle'
  | 'info'
  | 'list'
  | 'log-out'
  | 'menu'
  | 'more-vertical1'
  | 'ok'
  | 'offline'
  | 'pen'
  | 'pencil'
  | 'profile'
  | 'reports'
  | 'resources'
  | 'search'
  | 'settings'
  | 'sort'
  | 'sort-up'
  | 'sort-down'
  | 'spaces'
  | 'success'
  | 'users'
  | 'upload'
  | 'refresh'
  | 'undo'
  | 'announcement'
  | 'notification'
  | 'external-link'
  | 'email'
  | 'phone';
interface Props {
  /** The name of the Icon from the Icon List */
  icon: IcomoonIconNames;
  /** Override the default size of the icon */
  size?: number | string;
  /** Override the default color of the icon */
  color?: string;
}

const IcomoonIcons: React.FC<Props> = ({
  icon,
  size = 24,
  color = 'blueGrey',
}) => {
  return (
    <IcomoonReact
      iconSet={iconSet}
      color={theme.colors[color]}
      size={size}
      icon={icon}
    />
  );
};

export default IcomoonIcons;
