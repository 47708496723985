import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from 'hooks';

import { SpaceStatus, SpaceDetails } from 'models';
import { isObjectEmpty } from 'utils';
import { Grid, Paragraph as P, StatusIndicator, LastUpdated } from 'components';

const HeaderStatus = ({ details }: { details: SpaceDetails }) => {
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  const settings = (
    obj: SpaceDetails,
  ): { color: 'error' | 'warning' | 'success' | 'offline'; label: string } => {
    if (!obj.details.commissionStatus) {
      return {
        color: 'error',
        label: t('common:status.uncommissioned'),
      };
    } else {
      if (obj.status.isOffline) {
        return {
          color: 'offline',
          label: t('common:status.offline'),
        };
      } else {
        return {
          color: 'success',
          label: t('common:status.online'),
        };
      }
    }
  };

  const x = settings(details);
  return isObjectEmpty<SpaceDetails>(details) ? null : (
    <Grid gridAutoColumns="max-content" horizontal gridColumnGap="8px">
      <StatusIndicator
        label={x.label}
        status={x.color}
        labelColor="white"
        labelSize={isMobile ? 'small' : 'normal'}
      />
      <P size={isMobile ? 'small' : 'normal'} color="white">
        /
      </P>
      <LastUpdated
        date={new Date(details.status.updated)}
        dateFormat="DD/MM/YYYY HH:mm:ss"
      />
    </Grid>
  );
};

export default HeaderStatus;
