import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

import {
  ChartContainer,
  DonutChart,
  ChartSeparator,
  StackedBarChartHorizontal,
} from 'components';
import { ConnectedDoorHealthConnectivity } from 'models';
import { theme } from 'style';
import {
  renameObjKeys,
  FilterByConnectivityDonutDataObject,
  filterByConnectivityDonutChartConfig,
} from 'utils';

import {
  ChartsContainer,
  DonutContainer,
  BarContainer,
} from '../connectedDoorHealthStyle';
import DonutChartSkeleton from 'components/DonutChart/Skeleton/Skeleton';
import { isArray, isNull } from 'lodash';

interface FilterByConnectivityProps {
  isLoading: boolean;
  connectedDoorHealthConnectivity: ConnectedDoorHealthConnectivity[];
}

const FilterByConnectivity: React.FC<FilterByConnectivityProps> = ({
  isLoading,
  connectedDoorHealthConnectivity,
}) => {
  const { t } = useTranslation();

  const commissionedTranslated = t(
    'connectedDoorHealth:doors_by_connectivity_status.chart_legend_1',
  );
  const decommissionedTranslated = t(
    'connectedDoorHealth:doors_by_connectivity_status.chart_legend_2',
  );
  const offlineTranslated = t(
    'connectedDoorHealth:doors_by_connectivity_status.chart_legend_3',
  );

  // data shape for bar chart, name corresponds to legend
  const dataShape = [
    {
      name: commissionedTranslated,
      color: theme.colors.success,
    },
    {
      name: decommissionedTranslated,
      color: theme.colors.error,
    },
    {
      name: offlineTranslated,
      color: theme.colors.grey,
    },
  ];

  // *Methods
  const prepareDonutChartData = (details: any) => {
    if (!isArray(details)) details = [details];

    return details.reduce(
      (updatedConfig: FilterByConnectivityDonutDataObject[], item: any) => {
        updatedConfig[0].count += item.commissionedCount;
        updatedConfig[1].count += item.decommissionedCount;
        updatedConfig[2].count += item.offlineCount;
        return updatedConfig;
      },
      cloneDeep(filterByConnectivityDonutChartConfig),
    );
  };

  const prepareBarChartData = (details: any) => {
    const mapping = {
      commissionedCount: commissionedTranslated,
      decommissionedCount: decommissionedTranslated,
      offlineCount: offlineTranslated,
    };

    if (!isArray(details)) details = [details];

    // map object keys to name accepted by bar chart
    return details.map((item: any) => renameObjKeys(item, mapping));
  };

  return (
    <ChartsContainer>
      <ChartContainer
        elevated
        childrenStyleProps={{ padding: 0 }}
        title={t('connectedDoorHealth:all_doors:title')}>
        <DonutContainer>
          {isLoading || !connectedDoorHealthConnectivity ? (
            <DonutChartSkeleton type="pie" />
          ) : (
            <DonutChart
              isLoading={isLoading}
              data={
                connectedDoorHealthConnectivity
                  ? prepareDonutChartData(connectedDoorHealthConnectivity)
                  : undefined
              }
              dataKey="count"
              nameKey="label"
              type="pie"
            />
          )}
        </DonutContainer>
      </ChartContainer>

      <ChartSeparator />

      <ChartContainer
        elevated
        childrenStyleProps={{
          padding: isMobile ? '20px 20px 20px 10px' : '',
        }}
        title={t('connectedDoorHealth:doors_by_connectivity_status.title')}>
        <BarContainer>
          <StackedBarChartHorizontal
            isLoading={isLoading}
            dataShape={dataShape}
            data={
              connectedDoorHealthConnectivity
                ? prepareBarChartData(connectedDoorHealthConnectivity)
                : undefined
            }
            dataKey="type"
          />
        </BarContainer>
      </ChartContainer>
    </ChartsContainer>
  );
};

export default FilterByConnectivity;
