import React, { useState, useEffect } from 'react';
import {
  Container,
  FormComponents,
  Paragraph as P,
  Heading as H,
} from 'components';
import {
  FormContainer,
  InnerContainer,
  LogoWrapper,
  UserOnboardingContainer,
} from './userOnboardingStyles';
import {
  Account,
  AccountSpaceUpdate,
  AccountUpdate,
  IdValue,
  LabelValue,
  LabelValueWithIsCriticalField,
  NotificationType,
  User,
  UserUpdate,
} from 'models';
import { Form, Formik } from 'formik';
import {
  AccountDetails,
  AccountSettings,
  CommunicationPreferences,
  ReportPreferences,
} from './forms';
import {
  fetchAvailableNotificationTypes,
  fetchNotificationScopes,
  fetchUserMeDetails,
  updateAccountSpace,
  updateUser,
} from 'services';
import {
  applyFilterTranslations,
  DIScopeTranslations,
  isDI,
  isDualProducts,
  isOI,
  isDIS,
  OIScopeTranslations,
  getCurrentProductName,
} from 'utils';
import { useScreenSize } from 'hooks';
import { useTranslation } from 'react-i18next';
import { userMeStorage } from 'utils';
import { fetchAccount, updateAccount } from 'services';
import i18n from 'core/i18n/i18n';
import { sortBy } from 'lodash';
import HomeLogo from 'components/HomeLogo/HomeLogo';
import { getNotificationTypeOptions } from 'utils/getNotificationTypeOptions';

export enum OnboardingStep {
  DETAILS = 'Details',
  NOTIFICATIONS = 'Notifications',
  SETTINGS = 'Settings',
}

const { screenSize } = useScreenSize();

const UserOnboarding = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [userMe, setUserMe] = useState<User>({} as User);
  const [submitIsDisabledDI, setSubmitIsDisabledDI] = useState<boolean>(false);
  const [submitIsDisabledOI, setSubmitIsDisabledOI] = useState<boolean>(false);
  const [showMobileWarningDI, setShowMobileWarningDI] = useState<boolean>(
    false,
  );
  const [showMobileWarningOI, setShowMobileWarningOI] = useState<boolean>(
    false,
  );

  const { isMobile } = screenSize();
  const { t } = useTranslation();

  useEffect(() => {
    const userMe = window.localStorage.getItem('userMe');

    if (userMe) {
      const userDetails: User = JSON.parse(userMe);

      setIsAdmin(userDetails.userRole === 'Admin');
      setUserMe({
        ...userDetails,
      });
    }
  }, []);

  const availableSteps = [OnboardingStep.DETAILS, OnboardingStep.NOTIFICATIONS];

  if (isAdmin) {
    availableSteps.push(OnboardingStep.SETTINGS);
  }

  const translatedStepHeading = (step: OnboardingStep) => {
    switch (step) {
      case OnboardingStep.NOTIFICATIONS:
        return t('userOnboarding:step_heading.notifications');
      case OnboardingStep.SETTINGS:
        return t('userOnboarding:step_heading.settings');
      default:
        return t('userOnboarding:step_heading.details');
    }
  };

  const [activeStep, setActiveStep] = useState<OnboardingStep>(
    OnboardingStep.DETAILS,
  );

  const [OINotificationScopes, setOINotificationScopes] = useState<
    LabelValue[]
  >();
  const [DINotificationScopes, setDINotificationScopes] = useState<
    LabelValue[]
  >();

  useEffect(() => {
    const fetchScopes = async () => {
      try {
        const notificationScopesRes = (await fetchNotificationScopes()) as IdValue[];
        const OINotificationScopesTranslated = await applyFilterTranslations(
          notificationScopesRes,
          OIScopeTranslations,
        );

        const DINotificationScopesTranslated = await applyFilterTranslations(
          notificationScopesRes,
          DIScopeTranslations,
        );

        setOINotificationScopes(
          sortBy(OINotificationScopesTranslated, ['label']),
        );
        setDINotificationScopes(
          sortBy(DINotificationScopesTranslated, ['label']),
        );
      } catch (err) {
        console.log(err);
      }
    };
    fetchAlertTypes();
    fetchScopes();
  }, []);

  const [alertTypes, setAlertTypes] = useState<LabelValueWithIsCriticalField[]>(
    [],
  );

  const fetchAlertTypes = async () => {
    const alertTypesData = await fetchAvailableNotificationTypes();
    const alertTypeOptions = getNotificationTypeOptions(
      alertTypesData ?? [],
      NotificationType.Alert,
      true,
    );
    setAlertTypes(alertTypeOptions);
  };

  const [isAccountLoading, setIsAccountLoading] = useState<boolean>(true);
  const [accountData, setAccountData] = useState<Account>({} as Account);

  const fetchAccountData = async (userId: string) => {
    try {
      const res = await fetchAccount(userId);
      if (res) {
        setAccountData(res);
        userMeStorage.set({ ...userMe, account: res });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsAccountLoading(false);
    }
  };

  useEffect(() => {
    if (userMe?.account?.id) {
      fetchAccountData(userMe.account.id);
    }
  }, [userMe]);

  const onSubmit = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(false);
    // window.scrollTo(0, 0);
    switch (activeStep) {
      case OnboardingStep.DETAILS:
        setActiveStep(OnboardingStep.NOTIFICATIONS);
        break;
      case OnboardingStep.NOTIFICATIONS:
        if (availableSteps.indexOf(OnboardingStep.SETTINGS) !== -1) {
          setActiveStep(OnboardingStep.SETTINGS);
        }
        break;
      default:
        console.log('Update via API');
        setSubmitting(true);
        try {
          const userUpdatePayload: UserUpdate = {
            firstName: values.firstName,
            lastName: values.lastName,
            salutation: values.salutation,
            honorific: values.honorific,
            mobileNumber: values.mobile,
            phoneNumber: values.phone,
            language: values.language,
            oiNotificationScope: values.OINotificationPreferences?.what,
            alertExceeded: values.OINotificationPreferences?.when?.includes(
              'alertExceeded',
            ),
            alertApproaching: values.OINotificationPreferences?.when?.includes(
              'alertApproaching',
            ),
            oiNotifyViaEmail: values.OINotificationPreferences?.how?.includes(
              'email',
            ),
            oiNotifyViaSms: values.OINotificationPreferences?.how?.includes(
              'sms',
            ),
            modeNotificationScope: values.DINotificationPreferences?.mode,
            diNotificationScope: values.DINotificationPreferences?.what,
            diNotifyViaEmail: values.DINotificationPreferences?.how?.includes(
              'email',
            ),
            diNotifyViaSms: values.DINotificationPreferences?.how?.includes(
              'sms',
            ),
            diNotificationAlertTypes:
              values.DINotificationPreferences?.what === 'N/A'
                ? []
                : (
                    values.DINotificationPreferences?.generalAlertTypes ?? []
                  ).concat(
                    values.DINotificationPreferences?.criticalAlertTypes ?? [],
                  ),
            subscribeDIMonthlyReport: values.diReportPreferences?.includes(
              'Monthly',
            ),
            subscribeDIYearlyReport: values.diReportPreferences?.includes(
              'Yearly',
            ),
          };
          const userUpdateRes = await updateUser(userUpdatePayload, alertTypes);

          if (isAdmin) {
            const accountUpdatePayload: AccountUpdate = {
              name: values.account.name,
              email: values.account.email,
              street: values.account.address?.street,
              city: values.account.address?.city,
              state: values.account.address?.state,
              postcode: values.account.address?.postalCode,
              country: values.account.address?.country,
              refreshCycle: values.account.refreshCycle,
              firmwareUpdateSchedule: values.account.firmwareUpdateSchedule,
              defaultAccountTimezone: values.account.defaultAccountTimezone,
            };

            await updateAccount(userMe?.account?.id, accountUpdatePayload);

            if (isOI() || isDualProducts()) {
              const spaceUpdatePayload: AccountSpaceUpdate = {
                oiUnitOfMeasure: values.account.oiUnitOfMeasure,
                oiPerPersonSpaceAllowance: Number(
                  values.account.oiPerPersonSpaceAllowance,
                ),
                defaultAccountTimezone: values.account.defaultAccountTimezone,
                oiEntranceDisplayMessage:
                  values.account.oiEntranceDisplayMessage,
              };

              await updateAccountSpace(userMe?.account?.id, spaceUpdatePayload);
            }
          }

          if (userUpdateRes) {
            const update = await fetchUserMeDetails();

            if (update) {
              setUserMe(update);
              await userMeStorage.set(update);
              i18n.changeLanguage(update.language);
              window.location.href = '/dashboard';
            }
          }
        } catch (err) {
          console.log(err);
        } finally {
          setSubmitting(false);
        }
    }
  };

  const previousStep = () => {
    switch (activeStep) {
      case OnboardingStep.SETTINGS:
        setActiveStep(OnboardingStep.NOTIFICATIONS);
        break;
      default:
        setActiveStep(OnboardingStep.DETAILS);
    }
  };

  const disableSubmitLogicOI = (props: any) => {
    if (
      props.values.OINotificationPreferences.what !== 'N/A' &&
      activeStep === OnboardingStep.NOTIFICATIONS
    ) {
      if (
        !props.values.OINotificationPreferences.how.includes('email') &&
        !props.values.OINotificationPreferences.how.includes('sms')
      ) {
        return setSubmitIsDisabledOI(true);
      }

      if (
        !props.values.OINotificationPreferences.when.includes(
          'alertApproaching',
        ) &&
        !props.values.OINotificationPreferences.when.includes('alertExceeded')
      ) {
        return setSubmitIsDisabledOI(true);
      }

      if (
        props.values.OINotificationPreferences.how.includes('sms') &&
        showMobileWarningOI
      ) {
        return setSubmitIsDisabledOI(true);
      }
    }
    setSubmitIsDisabledOI(false);
  };

  const disableSubmitLogicDI = (props: any) => {
    if (
      (props.values.DINotificationPreferences.what !== 'N/A' ||
        props.values.DINotificationPreferences.mode !== 'N/A') &&
      activeStep === OnboardingStep.NOTIFICATIONS
    ) {
      if (
        (!props.values.DINotificationPreferences.how.includes('sms') &&
          !props.values.DINotificationPreferences.how.includes('email')) ||
        (props.values.DINotificationPreferences.what !== 'N/A' &&
          (props.values.DINotificationPreferences.generalAlertTypes?.length ??
            0) +
            (props.values.DINotificationPreferences.criticalAlertTypes
              ?.length ?? 0) ===
            0)
      ) {
        return setSubmitIsDisabledDI(true);
      }

      if (
        props.values.DINotificationPreferences.how.includes('sms') &&
        showMobileWarningDI
      ) {
        return setSubmitIsDisabledDI(true);
      }
      if (!props?.values?.DINotificationPreferences.mode) {
        return setSubmitIsDisabledDI(true);
      }
    }
    setSubmitIsDisabledDI(false);
  };

  const noMobileWarningOI = (props: any) => {
    if (
      props.values.OINotificationPreferences.how.includes('sms') &&
      !props.values.mobile
    ) {
      setShowMobileWarningOI(true);
    } else {
      setShowMobileWarningOI(false);
    }
  };

  const noMobileWarningDI = (props: any) => {
    if (
      props.values.DINotificationPreferences.how.includes('sms') &&
      !props.values.mobile
    ) {
      setShowMobileWarningDI(true);
    } else {
      setShowMobileWarningDI(false);
    }
  };

  return (
    <UserOnboardingContainer>
      <LogoWrapper
        style={{
          backgroundColor: 'white',
        }}>
        <HomeLogo productName={getCurrentProductName()} variant={'primary'} />
      </LogoWrapper>

      <div>
        <InnerContainer>
          <Container>
            {userMe ? (
              <FormContainer>
                <FormComponents.Wrapper>
                  <FormComponents.Header
                    // preHeading={`Step ${availableSteps.indexOf(activeStep) +
                    //   1} of ${availableSteps.length}`}
                    preHeading={t(
                      'loginActivate:activate.form_header.step_of',
                      {
                        step: availableSteps.indexOf(activeStep) + 1,
                        count: availableSteps.length,
                      },
                    )}
                    heading={translatedStepHeading(activeStep)}
                    activeStep={activeStep}
                    headingSize={`h3`}
                  />
                  <Formik
                    enableReinitialize
                    initialValues={{
                      ...userMe,
                      DINotificationPreferences: {
                        what: userMe.diNotificationScope ?? 'All',
                        when: [
                          userMe.alertExceeded ? 'alertExceeded' : '',
                          userMe.alertApproaching ? 'alertApproaching' : '',
                        ],
                        mode: !isOI()
                          ? userMe.modeNotificationScope ?? 'N/A'
                          : undefined,
                        how: [
                          userMe.diNotifyViaEmail ? 'email' : '',
                          userMe.diNotifyViaSms ? 'sms' : '',
                        ],
                        generalAlertTypes: userMe.generalAlertTypes ?? [],
                        criticalAlertTypes: userMe.criticalAlertTypes ?? [],
                      },
                      OINotificationPreferences: {
                        what:
                          isOI() || isDualProducts()
                            ? userMe.oiNotificationScope ?? 'N/A'
                            : undefined,
                        when: [
                          userMe.alertExceeded ? 'alertExceeded' : '',
                          userMe.alertApproaching ? 'alertApproaching' : '',
                        ],
                        how: [
                          userMe.oiNotifyViaEmail ? 'email' : '',
                          userMe.oiNotifyViaSms && userMe.mobile ? 'sms' : '',
                        ],
                      },
                      diReportPreferences: [
                        userMe.subscribeDIYearlyReport ? 'Yearly' : undefined,
                        userMe.subscribeDIMonthlyReport ? 'Monthly' : undefined,
                      ],
                    }}
                    validationSchema={() => {
                      if (activeStep === OnboardingStep.SETTINGS)
                        return AccountSettings.Validation;
                      else if (activeStep === OnboardingStep.DETAILS)
                        return AccountDetails.Validation;
                      else {
                        const result = AccountDetails.Empty;
                        return result;
                      }
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      return onSubmit(values, setSubmitting);
                    }}>
                    {(props) => {
                      if (
                        props.values.DINotificationPreferences.what === 'N/A' &&
                        props.values.DINotificationPreferences.mode === 'N/A'
                      ) {
                        props.values.DINotificationPreferences.how = [];
                        props.values.DINotificationPreferences.when = [];
                      }

                      if (
                        props.values.OINotificationPreferences.what === 'N/A'
                      ) {
                        props.values.OINotificationPreferences.how = [];
                        props.values.OINotificationPreferences.when = [];
                      }

                      if (isDualProducts()) {
                        disableSubmitLogicOI(props);
                        disableSubmitLogicDI(props);
                        noMobileWarningOI(props);
                        noMobileWarningDI(props);
                      }

                      if (isOI()) {
                        disableSubmitLogicOI(props);
                        noMobileWarningOI(props);
                      }

                      if (isDI() || isDIS()) {
                        disableSubmitLogicDI(props);
                        noMobileWarningDI(props);
                        // for some reason when saved as 'N/A' you cannot select a different notification option.
                        userMe.diNotificationScope =
                          props?.values?.DINotificationPreferences?.what ?? '';
                        userMe.diNotifyViaEmail =
                          props?.values?.DINotificationPreferences.how.indexOf(
                            'email',
                          ) >= 0;
                        userMe.diNotifyViaSms =
                          props?.values?.DINotificationPreferences.how.indexOf(
                            'sms',
                          ) >= 0;
                        userMe.modeNotificationScope =
                          props?.values?.DINotificationPreferences.mode ?? '';
                        userMe.alertTypes = (
                          props?.values?.DINotificationPreferences
                            ?.generalAlertTypes ?? []
                        ).concat(
                          props?.values?.DINotificationPreferences
                            ?.criticalAlertTypes ?? [],
                        );
                        userMe.generalAlertTypes =
                          props?.values?.DINotificationPreferences
                            ?.generalAlertTypes ?? [];
                        userMe.criticalAlertTypes =
                          props?.values?.DINotificationPreferences
                            ?.criticalAlertTypes ?? [];
                      }

                      if (isDI() || isDualProducts()) {
                        userMe.subscribeDIYearlyReport = props?.values?.diReportPreferences.includes(
                          'Yearly',
                        );
                        userMe.subscribeDIMonthlyReport = props?.values?.diReportPreferences.includes(
                          'Monthly',
                        );
                      }

                      return (
                        <Form data-testid="edit-profile-form">
                          {activeStep === OnboardingStep.DETAILS && (
                            <AccountDetails.Form values={props.values} />
                          )}

                          {activeStep === OnboardingStep.NOTIFICATIONS && (
                            <>
                              <CommunicationPreferences.Form
                                DINotificationScopes={
                                  DINotificationScopes
                                    ? DINotificationScopes
                                    : ([] as LabelValue[])
                                }
                                OINotificationScopes={
                                  OINotificationScopes
                                    ? OINotificationScopes
                                    : ([] as LabelValue[])
                                }
                                doesUserWantNotificationsDI={
                                  (props.values.DINotificationPreferences
                                    .what &&
                                    props.values.DINotificationPreferences
                                      .what !== 'N/A') ||
                                  (props.values.DINotificationPreferences
                                    .mode &&
                                    props.values.DINotificationPreferences
                                      .mode !== 'N/A')
                                    ? true
                                    : false
                                }
                                doesUserWantAlertsDI={
                                  props.values.DINotificationPreferences.what
                                    ? props.values.DINotificationPreferences
                                        .what !== 'N/A'
                                    : false
                                }
                                removeOINotifications={
                                  props.values.OINotificationPreferences.what &&
                                  props.values.OINotificationPreferences
                                    .what !== 'N/A'
                                    ? true
                                    : false
                                }
                                showMobileWarningDI={showMobileWarningDI}
                                showMobileWarningOI={showMobileWarningOI}
                                showAlertTypesWarningDI={
                                  (props.values.DINotificationPreferences
                                    .generalAlertTypes?.length ?? 0) +
                                    (props.values.DINotificationPreferences
                                      .criticalAlertTypes?.length ?? 0) ===
                                  0
                                }
                              />
                              {(isDI() || isDualProducts()) && (
                                <ReportPreferences.Form />
                              )}
                            </>
                          )}

                          {activeStep === OnboardingStep.SETTINGS && (
                            <AccountSettings.Form values={accountData} />
                          )}

                          {isMobile && activeStep === OnboardingStep.DETAILS && (
                            <FormComponents.MobileLastSection layout="single">
                              <P size="small" color="charcoal">
                                {t('forms:required.fields')}
                              </P>
                            </FormComponents.MobileLastSection>
                          )}

                          <FormComponents.Submit
                            label={
                              availableSteps.indexOf(activeStep) ===
                              availableSteps.length - 1
                                ? t('profile:buttons.save_changes')
                                : t('profile:buttons.next')
                            }
                            cancelLabel={t('profile:buttons.previous')}
                            cancelAction={
                              activeStep !== OnboardingStep.DETAILS
                                ? previousStep
                                : undefined
                            }
                            showRequiredText={
                              activeStep === OnboardingStep.DETAILS
                            }
                            isDisabled={
                              submitIsDisabledDI || submitIsDisabledOI
                            }
                            {...props}
                          />
                        </Form>
                      );
                    }}
                  </Formik>
                </FormComponents.Wrapper>
              </FormContainer>
            ) : (
              <H>Error: No user data</H>
            )}
          </Container>
        </InnerContainer>
      </div>
    </UserOnboardingContainer>
  );
};

export default UserOnboarding;
