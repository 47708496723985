import styled from '@emotion/styled';
import { flexCenter } from 'style';

export const Wrapper = styled.div`
  display flex;
  min-height: calc(100vh - 216px);
  padding: 24px;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  ${flexCenter}
  justify-content: space-around;
  max-width: 620px;
  padding: 48px;
  flex-direction: column;
  background-color: white;
`;

export const Heading = styled.div`
  text-align: center;
  ${flexCenter}
  align-items: center;
  flex-direction: column;
`;
