import React from 'react';
import { StyledModeChange } from './modeLabelStyle';

export type ModeLabelProps =
  { variant: 'small' | 'normal', children: React.ReactNode; };

const ModeLabel: React.FC<ModeLabelProps> = ({ variant, children }) => {
  return <StyledModeChange variant={variant}>    
    <span>
      {children}
    </span>
  </StyledModeChange>;
};

export default ModeLabel;
