import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const useTableSearch = (params: any, setParams: any, shouldDebounce = true) => {
  const [debouncedCallback] = useDebouncedCallback(() => {
    setParams({ ...params, name: searchValue });
  }, shouldDebounce ? 600 : 0);

  const [searchValue, setSearchValue] = useState<string | null>(null);
  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target ? e.target.value : ((e as any).value || ''));
  };
  const clearSearch = () => {
    setSearchValue('');
    delete params.name;
    setParams({ ...params });
  };

  useEffect(() => {
    if (searchValue) {
      debouncedCallback();
    }
    if (searchValue?.length === 0) {
      clearSearch();
    }
  }, [searchValue]);

  return {
    searchValue,
    searchHandler,
    clearSearch,
  };
};

export default useTableSearch;
