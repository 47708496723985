import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PageHeader, Container } from 'components';

type Props = RouteComponentProps;

const Resources = ({}: Props) => {
  return (
    <>
      <PageHeader heading="Resources" />
      <Container>
        <h1>Resources</h1>
      </Container>
    </>
  );
};

export default Resources;
