import styled from '@emotion/styled';
import { mq } from 'style';

export const SectionUuid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 32px;
`;

export const SectionHeader = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-template-rows: 1fr;

  ${mq.tablet} {
    display: flex;
    flex-direction: column;
  }
  ${mq.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const SectionHeaderActions = styled.div`
  display: flex;
  ${mq.tablet} {
    margin-top: 15px;
  }
  ${mq.mobile} {
    margin-top: 15px;
  }

  ${mq.smallMobile} {
    display: block;
    button {
      width: 100%;
    }
    > p {
      margin-left: 0;
      margin-top: 15px;
    }
  }

  ${mq.largeTablet} {
    justify-content: flex-end;
  }
`;
