import React from 'react';

import { Indicator } from './statusIndicatorStyle';
import { Grid, Paragraph } from 'components';
import { ParagraphSizes } from 'components/Paragraph/Paragraph';

type CommonProps = {
  status?: 'error' | 'warning' | 'success' | 'offline';
  label?: string | React.ReactNode;
  labelColor?: string;
  labelSize?: ParagraphSizes;
  indicatorColor?: string;
};
export type Props =
  | (CommonProps & {
      status: 'error' | 'warning' | 'success' | 'offline';
    })
  | (CommonProps & {
      indicatorColor: string;
    });

const StatusIndicator = ({
  label,
  labelColor,
  status,
  labelSize,
  indicatorColor,
  ...rest
}: Props) => {
  return (
    <Grid
      gridColumnGap="8px"
      gridTemplateColumns="min-content 1fr"
      alignItems="center"
      {...rest}>
      <Indicator
        status={status}
        indicatorColor={indicatorColor}
        data-testid="indicator-light"
      />
      {typeof label === 'string' ? (
        <Paragraph
          color={labelColor}
          size={labelSize}
          data-testid="status-indicator-label">
          {label}
        </Paragraph>
      ) : (
        label
      )}
    </Grid>
  );
};

export default StatusIndicator;
