import React from 'react';
import { useTranslation } from 'react-i18next';
import { Legend as VXLegend, LegendItem } from '@vx/legend';
import { Grid, Paragraph, Box, Truncate } from 'components';
import { occupancyChartConfig } from 'utils';

type Props = {
  colorScale: any;
};

export const Legend = ({ colorScale }: Props) => {
  const { t } = useTranslation();
  return (
    <VXLegend scale={colorScale}>
      {(labels) => {
        return (
          <Grid
            horizontal
            gridColumnGap={['25px', '50px']}
            mt={[0, '35px']}
            mb={['35px', 0]}
            gridRowGap={['8px', 0]}
            gridTemplateRows={['repeat(2, max-content)', 0]}
            gridTemplateColumns={['repeat(2, 1fr)', 'repeat(4, max-content)']}>
            {labels
              .filter((label) => label.datum !== 'futurePct')
              .filter((label) => label.datum !== 'notCommissionedPct')
              .map((label, i) => {
                const text = occupancyChartConfig.find(
                  (key) => key.key.percent === label.datum,
                )!.label.historic;
                return (
                  <LegendItem key={i}>
                    <Box
                      backgroundColor={label.value as string}
                      size="12px"
                      outlined={false}
                      mr="10px"
                    />
                    <Paragraph
                      style={{ overflow: 'hidden' }}
                      color={(['charcoal', 'grey'] as unknown) as string}
                      size="small">
                      {t(text.toString())}
                    </Paragraph>
                  </LegendItem>
                );
              })}
          </Grid>
        );
      }}
    </VXLegend>
  );
};

export default Legend;
