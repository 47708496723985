import client from 'core/client/client';
import i18n from 'core/i18n/i18n';
import { Announcement } from 'models';

const ANNOUNCEMENTS_ENDPOINT = 'announcements';

// Handle the sucessful requests in the function
// Errors handled by the client and thrown down

export const fetchAnnouncements = async () => {
  const languageCode = i18n.language;
  try {
    const request = await client.get(
      `${ANNOUNCEMENTS_ENDPOINT}?languageCode=${languageCode}`,
    );

    console.log('announcement: ', request);
    const { status } = request;
    const data: Announcement[] = request.data.filter(
      (item: Announcement) => item.status === 'Active',
    );
    switch (status) {
      case 200:
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const markAnnoucementAsRead = async (number: string) => {
  try {
    const request = await client.post(
      `${ANNOUNCEMENTS_ENDPOINT}/${number}/markasread`,
    );

    const { data, status } = request;

    switch (status) {
      case 204:
        return true;
    }
  } catch (err) {
    throw err;
  }
};
