import React from 'react';
import { PrivateRoute } from 'pages';

import Doors from './Doors/Doors';
import DoorDetails from './DoorDetails/DoorDetails';
import Resources from './Resources/Resources';
import Dashboard from './Dashboard/Dashboard';
import Settings from './Settings/Settings';
import Profile from './Profile/Profile';
import Alerts from './Alerts/Alerts';
import Reports from './Reports/Reports';

const DoorInsightPages = () => (
  <>
    <PrivateRoute path="/doors" component={Doors} exact />
    <PrivateRoute path="/doors/:id" component={DoorDetails} exact />
    <PrivateRoute path="/resources" component={Resources} exact />
    <PrivateRoute path="/dashboard" component={Dashboard} exact />
    <PrivateRoute path="/settings" component={Settings} exact />
    <PrivateRoute path="/profile" component={Profile} exact />
    <PrivateRoute path="/notifications" component={Alerts} exact />
    <PrivateRoute path="/reports" component={Reports} exact />
  </>
);

export default DoorInsightPages;
