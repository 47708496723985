import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import {
  StyledModal,
  StyledHeader,
  StyledBody,
  StyledActions,
  Backdrop,
  Wrapper,
} from './modalStyle';

import { Flex, IcomoonIcons, Paragraph as P, ToolTip } from 'components';
import { Spacer } from 'style';

import { useScreenSize } from 'hooks';

interface ModalProps {
  children: React.ReactNode;
  actions?: React.ReactNode;
  naked?: boolean;
  heading: string;
  isOpen: boolean;
  width?: string;
  showHeader?: boolean;
  hideSeparator?: boolean;
  toggleModal: () => void;
  tooltip?: string;
  tooltipIcon?: 'question' | 'info' | 'offline';
  shouldPadContent?: boolean;
  minWidth?: number;
  maxWidth?: number;
  secondaryHeading?: React.ReactNode;
  stickToTop?: boolean;
}

const Modal = ({
  children,
  heading,
  naked = false,
  actions,
  isOpen,
  width,
  toggleModal,
  showHeader = true,
  tooltip,
  tooltipIcon,
  shouldPadContent = true,
  minWidth,
  maxWidth,
  secondaryHeading,
  hideSeparator,
  stickToTop = false,
}: ModalProps) => {
  const body = document.body;
  useEffect(() => {
    isOpen ? (body.style.overflow = 'hidden') : (body.style.overflow = 'auto');
  }, [isOpen]);

  const { isMobile } = useScreenSize().screenSize();

  const modal = (
    <>
      <Backdrop />
      <Wrapper
        stickToTop={stickToTop}
        width={width}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <StyledModal minWidth={minWidth} maxWidth={maxWidth}>
          {showHeader && (
            <StyledHeader
              style={{
                borderBottom: hideSeparator ? '0' : '',
                padding: hideSeparator ? '10px 25px' : '',
                right: hideSeparator ? '10px' : '',
                top: hideSeparator ? '20px' : '',
                position: hideSeparator ? 'absolute' : 'relative',
              }}>
              <Flex>
                <P color="primary" bold size="medium">
                  {heading}
                </P>
                {tooltip && (
                  <>
                    <Spacer width={5} />
                    <P mt="6px">
                      <ToolTip
                        content={tooltip}
                        iconSize={18}
                        iconType={tooltipIcon || 'info'}
                      />
                    </P>
                  </>
                )}
                {secondaryHeading && (
                  <>
                    <Spacer width={isMobile ? 5 : 16} />
                    <P>{secondaryHeading}</P>
                  </>
                )}
              </Flex>
              <div onClick={toggleModal} style={{ cursor: 'pointer' }}>
                <IcomoonIcons icon="close" color="lightBlueGrey" />
              </div>
            </StyledHeader>
          )}
          {naked ? (
            children
          ) : (
            <>
              <StyledBody shouldPadContent={shouldPadContent}>
                {children}
              </StyledBody>
              {actions && <StyledActions>{actions}</StyledActions>}
            </>
          )}
        </StyledModal>
      </Wrapper>
    </>
  );

  return isOpen ? ReactDOM.createPortal(modal, document.body) : null;
};

export default Modal;
