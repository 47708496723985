import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Formik, Form } from 'formik';

import ChangePasswordModal from './ChangePasswordModal/ChangePasswordModal';

import {
  FormComponents,
  Heading as H,
  Paragraph as P,
  Button,
  Container,
  PageHeader,
  Spinner,
  FloatingActionBar,
} from 'components';
import { AccountDetails, CommunicationPreferences } from './forms';
import { UserUpdate, LabelValue, IdValue, User } from 'models';
import {
  updateUser,
  fetchNotificationScopes,
  fetchUserMeDetails,
} from 'services';
import { useScreenSize } from 'hooks';
import { useModalStore } from 'stores';
import {
  FilterTranslations,
  applyFilterTranslations,
  isDualProducts,
  isOI,
  userMeStorage,
} from 'utils';
import { sortBy } from 'lodash';

const scopeTranslations: FilterTranslations = {
  Following: i18n.t('forms:communication_preferences.typesOI.following'),
  All: i18n.t('forms:communication_preferences.typesOI.all'),
  'N/A': i18n.t('forms:communication_preferences.frequencies.none'),
};

const languageSetting = (lang: string) => i18n.t('languages:' + lang);

const { screenSize } = useScreenSize();

const Profile = () => {
  const { t } = useTranslation();
  const { isMobile } = screenSize();

  const [userMe, setUserMe] = useState<User>({} as User);
  const [submitIsDisabledOI, setSubmitIsDisabledOI] = useState<boolean>(false);
  const [showMobileWarningOI, setShowMobileWarningOI] = useState<boolean>(
    false,
  );

  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const toggleEdit = () => {
    setIsEditable(!isEditable);
    window.scrollTo(0, 0);
  };

  const [notificationScopes, setNotificationScopes] = useState<LabelValue[]>();
  useEffect(() => {
    const fetchScopes = async () => {
      try {
        const notificationScopesRes = (await fetchNotificationScopes()) as IdValue[];
        const notificationScopesTranslated = await applyFilterTranslations(
          notificationScopesRes,
          scopeTranslations,
        );
        setNotificationScopes(sortBy(notificationScopesTranslated, ['label']));
      } catch (err) {
        console.log(err);
      }
    };
    fetchScopes();
  }, []);

  const disableSubmitLogicOI = (props: any) => {
    if (!props.values.notificationPreferences.what)
      return setSubmitIsDisabledOI(true);

    if (props.values.notificationPreferences.what !== 'N/A') {
      if (
        !props.values.notificationPreferences.how.includes('email') &&
        !props.values.notificationPreferences.how.includes('sms')
      ) {
        return setSubmitIsDisabledOI(true);
      }

      if (
        !props.values.notificationPreferences.when.includes(
          'alertApproaching',
        ) &&
        !props.values.notificationPreferences.when.includes('alertExceeded')
      ) {
        return setSubmitIsDisabledOI(true);
      }

      if (
        props.values.notificationPreferences.how.includes('sms') &&
        showMobileWarningOI
      ) {
        return setSubmitIsDisabledOI(true);
      }
    }
    setSubmitIsDisabledOI(false);
  };

  const noMobileWarningOI = (props: any) => {
    if (
      props.values.notificationPreferences.how.includes('sms') &&
      !props.values.mobile
    ) {
      setShowMobileWarningOI(true);
    } else {
      setShowMobileWarningOI(false);
    }
  };

  const onSubmit = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true);

    try {
      const userUpdatePayload: UserUpdate = {
        firstName: values.firstName,
        lastName: values.lastName,
        salutation: values.salutation,
        honorific: values.honorific,
        mobileNumber: values.mobile,
        phoneNumber: values.phone,
        language: values.language,
        oiNotificationScope: values.notificationPreferences?.what,
        alertExceeded: values.notificationPreferences?.when?.includes(
          'alertExceeded',
        ),
        alertApproaching: values.notificationPreferences?.when?.includes(
          'alertApproaching',
        ),
        oiNotifyViaEmail: values.notificationPreferences?.how?.includes(
          'email',
        ),
        oiNotifyViaSms: values.notificationPreferences?.how?.includes('sms'),
      };
      const res = await updateUser(userUpdatePayload, []);

      if (res) {
        const update = await fetchUserMeDetails();
        if (update) {
          setUserMe(update);
          await userMeStorage.set(update);
          i18n.changeLanguage(update.language);
          window.location.reload();
        }
        toggleEdit();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const { changePasswordModal } = useModalStore();

  return (
    <>
      <ChangePasswordModal />

      {!isMobile ? (
        <PageHeader
          heading={t('profile:heading')}
          actionComponents={
            <Button
              inverse
              onClick={changePasswordModal.toggleModal}
              disabled={isEditable}>
              {t('profile:change_password_form.button')}
            </Button>
          }
        />
      ) : (
        <FloatingActionBar>
          <Button
            onClick={changePasswordModal.toggleModal}
            disabled={isEditable}
            stretch>
            {t('profile:change_password_form.button')}
          </Button>
        </FloatingActionBar>
      )}

      <Container mb={isMobile ? 120 : 30}>
        {userMe ? (
          <FormComponents.Wrapper>
            <FormComponents.Header
              heading={t('profile:form_header.inactive')}
              onClick={!isEditable ? toggleEdit : undefined}
            />
            {isEditable ? (
              <Formik
                initialValues={{
                  ...userMe,
                  notificationPreferences: {
                    what:
                      isOI() || isDualProducts()
                        ? userMe.oiNotificationScope ?? 'N/A'
                        : undefined,
                    when: [
                      userMe.alertExceeded ? 'alertExceeded' : '',
                      userMe.alertApproaching ? 'alertApproaching' : '',
                    ],
                    how: [
                      userMe.oiNotifyViaEmail ? 'email' : '',
                      userMe.oiNotifyViaSms ? 'sms' : '',
                    ],
                  },
                }}
                validationSchema={AccountDetails.Validation}
                onSubmit={(values, { setSubmitting }) => {
                  onSubmit(values, setSubmitting);
                }}>
                {(props) => {
                  if (props.values.notificationPreferences.what === 'N/A') {
                    props.values.notificationPreferences.how = [];
                    props.values.notificationPreferences.when = [];
                  }

                  noMobileWarningOI(props);
                  disableSubmitLogicOI(props);

                  return (
                    <Form data-testid="edit-profile-form">
                      <AccountDetails.Form values={props.values} />
                      <CommunicationPreferences.Form
                        notificationScopes={
                          notificationScopes
                            ? notificationScopes
                            : ([] as LabelValue[])
                        }
                        doesUserWantNotifications={
                          props.values.notificationPreferences.what &&
                          props.values.notificationPreferences.what !== 'N/A'
                            ? true
                            : false
                        }
                        showMobileWarningOI={showMobileWarningOI}
                      />

                      {isMobile && (
                        <FormComponents.Section layout="single">
                          <P size="small" color="charcoal">
                            {t('forms:required.fields')}
                          </P>
                        </FormComponents.Section>
                      )}

                      <FormComponents.Submit
                        label={t('profile:buttons.save_changes')}
                        cancelAction={toggleEdit}
                        showRequiredText
                        isDisabled={submitIsDisabledOI}
                        {...props}
                      />
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <>
                <FormComponents.Section
                  heading={t('profile:personal_information.heading')}
                  layout={isMobile ? 'single' : 'inactive'}>
                  <FormComponents.InActive
                    title={t('forms:personal_details.salutation')}
                    content={userMe?.salutation}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.honorific')}
                    content={userMe?.honorific}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.first_name')}
                    content={userMe?.firstName}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.last_name')}
                    content={userMe?.lastName}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.mobile_no')}
                    content={userMe?.mobile}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.phone_no')}
                    content={userMe?.phone}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.email')}
                    content={userMe?.email}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.account')}
                    content={userMe?.account?.name}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.user_type')}
                    content={userMe?.userRole}
                  />
                  <FormComponents.InActive
                    title={t('forms:personal_details.language')}
                    content={languageSetting(userMe?.language)}
                  />
                </FormComponents.Section>
                <FormComponents.Section
                  heading={t('profile:communication_preferences.heading')}
                  layout={notificationScopes ? 'inactive' : 'single'}>
                  {notificationScopes ? (
                    <>
                      <FormComponents.InActive
                        title={t('forms:communication_preferences.type')}
                        content={
                          notificationScopes
                            ? notificationScopes.find(
                                (scope: LabelValue) =>
                                  scope.value == userMe?.oiNotificationScope,
                              )?.label
                            : ''
                        }
                      />
                      <FormComponents.InActive
                        title={t('forms:communication_preferences.frequency')}
                        content={
                          (userMe?.alertExceeded
                            ? t(
                                'forms:communication_preferences.frequencies.exceeding',
                              )
                            : '') +
                          (userMe?.alertExceeded && userMe?.alertApproaching
                            ? ', '
                            : '') +
                          (userMe?.alertApproaching
                            ? t(
                                'forms:communication_preferences.frequencies.approaching',
                              )
                            : '')
                        }
                      />
                      <FormComponents.InActive
                        title={t('forms:communication_preferences.method')}
                        content={
                          (userMe?.oiNotifyViaSms
                            ? t('forms:communication_preferences.methods.sms')
                            : '') +
                          (userMe?.oiNotifyViaSms && userMe?.oiNotifyViaEmail
                            ? ', '
                            : '') +
                          (userMe?.oiNotifyViaEmail
                            ? t('forms:communication_preferences.methods.email')
                            : '')
                        }
                      />
                    </>
                  ) : (
                    <Spinner color="lightBlueGrey" />
                  )}
                </FormComponents.Section>
              </>
            )}
          </FormComponents.Wrapper>
        ) : (
          <H>Error: No user data</H>
        )}
      </Container>
    </>
  );
};

export default Profile;
