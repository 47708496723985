import React from 'react';

import { StyledDiv } from './boxStyle';
import {
  LayoutProps,
  SpaceProps,
  BackgroundProps,
  BorderColorProps,
  BorderProps,
} from 'styled-system';
import { ThemeType } from 'style';

export interface BoxProps
  extends LayoutProps,
    SpaceProps,
    BackgroundProps,
    BorderColorProps,
    React.HTMLAttributes<HTMLDivElement>,
    BorderProps {
  square?: boolean;
  elevated?: boolean;
  theme?: ThemeType;
  outlined?: boolean;
  backgroundColor?: string;
}

const Box: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <StyledDiv data-testid="box" {...rest}>
      {children}
    </StyledDiv>
  );
};

Box.defaultProps = {
  outlined: true,
};

export default Box;
