import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Radio, FormComponents, NoMobileWarning } from 'components';
import { LabelValue, NotificationType } from 'models';
import { shouldShowModeElement, shouldShowModeWarning } from 'utils';
import ModeLabel from 'components/ModeLabel/ModeLabel';
import { fetchAvailableNotificationTypes } from 'services';
import NoAlertTypeWarning from 'components/NoAlertTypeWarning/NoAlertTypeWarning';
import { getNotificationTypeOptions } from 'utils/getNotificationTypeOptions';

interface Props {
  notificationScopes: LabelValue[];
  doesUserWantNotifications: boolean;
  showMobileWarningDI: boolean;
  doesUserWantAlerts: boolean;
  showAlertTypesWarningDI: boolean;
}

export const Form = ({
  notificationScopes,
  doesUserWantNotifications,
  showMobileWarningDI,
  doesUserWantAlerts,
  showAlertTypesWarningDI,
}: Props) => {
  const { t } = useTranslation();

  const [generalAlertTypes, setGeneralAlertTypes] = useState<LabelValue[]>([]);
  const [criticalAlertTypes, setCriticalAlertTypes] = useState<LabelValue[]>(
    [],
  );

  const fetchAlertTypes = async () => {
    const alertTypesData = await fetchAvailableNotificationTypes();
    const generalAlertTypeOptions = getNotificationTypeOptions(
      alertTypesData ?? [],
      NotificationType.Alert,
      true,
    ).filter((a) => !a.isCritical);
    const criticalAlertTypeOptions = getNotificationTypeOptions(
      alertTypesData ?? [],
      NotificationType.Alert,
      true,
    ).filter((a) => a.isCritical);
    setGeneralAlertTypes(generalAlertTypeOptions);
    setCriticalAlertTypes(criticalAlertTypeOptions);
  };

  useEffect(() => {
    fetchAlertTypes();
  }, []);

  return (
    <FormComponents.Section
      layout="single"
      heading={t('profile:communication_preferences.heading')}>
      <Radio
        label={t('profile:communication_preferences.notifications.which')}
        labeltooltip={t(
          'profile:communication_preferences.notifications.which_tooltip',
        )}
        name="notificationPreferences.what"
        radioItems={notificationScopes}
      />
      {doesUserWantAlerts && (
        <>
          <Checkbox
            label={t(
              'profile:communication_preferences.notifications.alert_types_critical',
            )}
            name="notificationPreferences.criticalAlertTypes"
            checkboxItems={criticalAlertTypes}
            labeltooltip={t(
              'profile:communication_preferences.notifications.alert_types_tooltip',
            )}
            columns={3}
            selectAllItem={{
              label: t('alerts:names.allCritical'),
              value: NotificationType.AllCritical,
            }}
          />
          <Checkbox
            label={t(
              'profile:communication_preferences.notifications.alert_types_general',
            )}
            name="notificationPreferences.generalAlertTypes"
            checkboxItems={generalAlertTypes}
            labeltooltip={t(
              'profile:communication_preferences.notifications.alert_types_tooltip',
            )}
            columns={3}
            selectAllItem={{
              label: t('alerts:names.allGeneral'),
              value: NotificationType.AllGeneral,
            }}
          />
          {showAlertTypesWarningDI && <NoAlertTypeWarning />}
        </>
      )}

      {shouldShowModeElement() && (
        <Radio
          label={t('profile:communication_preferences.notifications.mode')}
          labeltooltip={t(
            'profile:communication_preferences.notifications.mode_tooltip',
          )}
          name="notificationPreferences.mode"
          radioItems={notificationScopes}
          idPrefix="mode"
          labelWarning={
            shouldShowModeWarning() && (
              <ModeLabel variant="small">
                {t('common:mode_label.onboarding')}
              </ModeLabel>
            )
          }
        />
      )}

      {doesUserWantNotifications && (
        <>
          <Checkbox
            label={t('profile:communication_preferences.notifications.how')}
            name="notificationPreferences.how"
            checkboxItems={[
              {
                label: t('forms:communication_preferences.methods.sms'),
                value: 'sms',
              },
              {
                label: t('forms:communication_preferences.methods.email'),
                value: 'email',
              },
            ]}
          />
          {showMobileWarningDI && <NoMobileWarning isProfile />}
        </>
      )}
    </FormComponents.Section>
  );
};
