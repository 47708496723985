import React from 'react';

import { Skeleton } from 'components';
import { useScreenSize } from 'hooks';

const ChartSkeleton = () => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  return (
    <Skeleton
      size={isMobile ? '280px' : '320px'}
      variant="rectangle"
      width="100%"
    />
  );
};

export default ChartSkeleton;
