import React from "react"


const CheckmarkCircle = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_15149_36782)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.13692 2.20125C8.1028 1.74048 6.94745 1.62633 5.84316 1.87583C4.73888 2.12533 3.74482 2.72511 3.00926 3.58571C2.2737 4.44632 1.83603 5.52165 1.76154 6.65132C1.68705 7.78098 1.97973 8.90447 2.59592 9.85421C3.21211 10.804 4.1188 11.5291 5.18076 11.9214C6.24272 12.3137 7.40306 12.3523 8.48873 12.0313C9.57439 11.7103 10.5272 11.047 11.2051 10.1402C11.8829 9.23345 12.2495 8.13187 12.2502 6.99975V6.46342C12.2502 6.14125 12.5113 5.88008 12.8335 5.88008C13.1557 5.88008 13.4168 6.14125 13.4168 6.46342V7.00008C13.416 8.38379 12.968 9.7305 12.1395 10.8388C11.311 11.947 10.1464 12.7578 8.81952 13.1501C7.4926 13.5424 6.0744 13.4953 4.77645 13.0158C3.47849 12.5362 2.37032 11.65 1.6172 10.4892C0.864075 9.32841 0.506362 7.95526 0.597405 6.57455C0.688449 5.19385 1.22337 3.87956 2.12239 2.82771C3.02141 1.77585 4.23637 1.04279 5.58605 0.737845C6.93574 0.432901 8.34783 0.572417 9.61175 1.13558C9.90602 1.26671 10.0383 1.61156 9.90717 1.90583C9.77604 2.20011 9.43119 2.33237 9.13692 2.20125Z" fill="#5FA82A" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2458 1.92073C13.4737 2.14843 13.4739 2.51777 13.2462 2.74569L7.41285 8.58486C7.30347 8.69434 7.15507 8.75588 7.00031 8.75592C6.84555 8.75596 6.69712 8.6945 6.58768 8.58506L4.83768 6.83506C4.60988 6.60726 4.60988 6.23791 4.83768 6.01011C5.06549 5.7823 5.43484 5.7823 5.66264 6.01011L6.99996 7.34742L12.4208 1.92115C12.6485 1.69323 13.0178 1.69304 13.2458 1.92073Z" fill="#5FA82A" />
      </g>
      <defs>
        <clipPath id="clip0_15149_36782">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CheckmarkCircle
