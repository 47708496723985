import React from 'react';
import { useTranslation } from 'react-i18next';

import { StatusFlare } from './cellStyle';

import { Flex } from 'components';
import { SpacesList, StatusEnum } from 'models';

const StatusCell = ({ space }: { space: SpacesList }) => {
  const { t } = useTranslation();
  const { percent, status, isOffline } = space.status;
  const { commissionStatus } = space.summary;

  const flareBackground = (status: StatusEnum) => {
    if (!commissionStatus) {
      return 'error';
    } else if (isOffline) {
      return 'grey';
    } else {
      switch (status) {
        case 'Exceeded':
          return 'error';
        case 'Exceeding':
          return 'error';
        case 'Approaching':
          return 'warning';
        case 'Offline':
          return 'grey';
        case 'Within':
          return 'success';
        default:
          return 'success';
      }
    }
  };

  const label = () => {
    if (!commissionStatus) {
      return t('common:status.uncommissioned');
    } else if (isOffline) {
      return t('common:status.offline');
    } else {
      return `${percent < 0 ? 0 : percent}%`;
    }
  };

  return (
    <Flex alignItems="center">
      <StatusFlare backgroundColor={flareBackground(status)} />
      <span>{label()}</span>
    </Flex>
  );
};

export default StatusCell;
