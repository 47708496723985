export interface ReportRequest {
  userId: string;
  spaceId?: string;
  deviceUUID?: string;
  from: Date | string;
  to: Date | string;
  reportType: string;
}

export enum ReportType {
  All = 'All',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export interface ReportsList {
  title: string;
  period: string;
  type: ReportType;
  year: string;
  month?: string;
}

export interface DIReport {
  url: string;
  reportDate: string;
  accountName: string;
  accountId: string;
}
