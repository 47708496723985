import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';

import {
  Button,
  Paragraph as P,
  Grid,
  Modal,
  Checkbox,
  FormComponents,
  MonthPicker,
  Heading as H,
  Flex,
  IcomoonIcons,
} from 'components';
import { Spacer } from 'style';
import { useModalStore } from 'stores';
import { requestDIReport } from 'services';

interface Props {
  installDate: Date | string;
  userId: string;
  deviceUUID?: string;
  accountId?: string;
  isNetworkLevelReport: boolean;
}

const alertHistory = 'AlertHistory';
const dailyCyclesHistory = 'DailyCyclesHistory';
const remoteActionsHistory = 'RemoteActionHistory';
const validation = Yup.object({
  reportTypes: Yup.array()
    .min(1, 'At least one box must be ticked')
    .required('Required'),
  monthRange: Yup.array().when('reportTypes', (reportTypes: string[]) => {
    return reportTypes.includes(alertHistory) ||
      reportTypes.includes(dailyCyclesHistory)
      ? Yup.array()
          .of(Yup.string())
          .required()
      : Yup.array().notRequired();
  }),
});

const ExportReportModal = ({
  installDate,
  deviceUUID,
  userId,
  accountId,
  isNetworkLevelReport,
}: Props) => {
  const { t } = useTranslation();
  const { exportDoorReportModal } = useModalStore();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const [error, setError] = useState<null | string>(null);

  const applyMinDate = () => {
    const mayFirst = new Date('2021-05-01T00:00:00+00:00');

    if (!installDate) return mayFirst;

    const minDate = moment(installDate)
      .startOf('month')
      .toDate();

    if (minDate < mayFirst) {
      return mayFirst;
    } else {
      return minDate;
    }
  };

  const exportReportAction = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: any,
  ) => {
    const [from, to] = values.monthRange;
    const todaysDate = moment(new Date()).format();

    const payload = {
      deviceUUID,
      userId,
      from: from ? moment(from).format() : todaysDate,
      to: to
        ? moment(to)
            .endOf('month')
            .format()
        : todaysDate,
      accountId,
    };

    values.reportTypes.forEach(async (item: string) => {
      try {
        await requestDIReport({ ...payload, reportType: item });
        setSubmitting(false);
        setIsSuccess(true);
      } catch (err) {
        setSubmitting(false);
        setErrors(err);
      }
    });
  };

  const closeAndClearModal = () => {
    exportDoorReportModal.toggleModal();
    setIsSuccess(false);
    setError(null);
  };

  function isRemoteActionsHistoryOnly(reportTypes: never[]) {
    return reportTypes.length === 1 && reportTypes[0] === remoteActionsHistory;
  }

  return (
    <Modal
      isOpen={exportDoorReportModal.isOpen}
      heading={
        isNetworkLevelReport
          ? t('spaceDetails:modals.export_report.accountLevelTitle')
          : t('spaceDetails:modals.export_report.title')
      }
      toggleModal={closeAndClearModal}>
      {error && (
        <P mb="15px" color="error">
          {error}
        </P>
      )}
      {isSuccess ? (
        <Flex justifyContent="center" flexDirection="column">
          <H color="primary" size="h3" marginBottom={10} textAlign="center">
            {t('spaceDetails:modals.export_report.success.heading')}
          </H>
          <P color="charcoal" textAlign="center" marginBottom={10}>
            {t('spaceDetails:modals.export_report.success.sub_one')}
          </P>
          <P color="charcoal" textAlign="center" marginBottom={30}>
            {t('spaceDetails:modals.export_report.success.sub_two')}
          </P>
          <Flex justifyContent="center">
            <Button onClick={closeAndClearModal}>{t('common:close')}</Button>
          </Flex>
        </Flex>
      ) : (
        <Formik
          validateOnMount={true}
          enableReinitialize
          isInitialValid={false}
          initialValues={{ reportTypes: [], monthRange: [null, null] }}
          validationSchema={validation}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setError(null);
            if (
              isNetworkLevelReport &&
              values.monthRange[0] &&
              values.monthRange[1]
            ) {
              const date1 = values.monthRange[0];
              const date2 = values.monthRange[1];
              const monthsDiff = moment(date1).diff(moment(date2), 'months');
              if (Math.abs(monthsDiff) + 1 > 3) {
                setError(
                  t(
                    'spaceDetails:modals.export_report.selected_more_than_three_months',
                  ),
                );
                setSubmitting(false);
              } else {
                exportReportAction(values, setSubmitting, setErrors);
              }
            } else {
              exportReportAction(values, setSubmitting, setErrors);
            }
          }}>
          {(props) => {
            return (
              <Form>
                <FormComponents.Section layout="single" paddingSize="none">
                  <Checkbox
                    label={t(
                      'spaceDetails:modals.export_report.report_type.title',
                    )}
                    name="reportTypes"
                    checkboxItems={[
                      {
                        label: isNetworkLevelReport
                          ? t(
                              'doorDetails:modal.export_report.report_type.notification_history_all_doors.label',
                            )
                          : t(
                              'doorDetails:modal.export_report.report_type.notification_history.label',
                            ),
                        value: alertHistory,
                      },
                      {
                        label: isNetworkLevelReport
                          ? t(
                              'doorDetails:modal.export_report.report_type.daily_cycles_history_all_doors.label',
                            )
                          : t(
                              'doorDetails:modal.export_report.report_type.daily_cycles_history.label',
                            ),
                        value: dailyCyclesHistory,
                      },
                      {
                        label: isNetworkLevelReport
                          ? t(
                              'doorDetails:modal.export_report.report_type.remote_actions_history_all_doors.label',
                            )
                          : t(
                              'doorDetails:modal.export_report.report_type.remote_actions_history.label',
                            ),
                        value: remoteActionsHistory,
                      },
                    ]}
                    direction="vertical"
                    hideErrors
                  />
                </FormComponents.Section>
                {isRemoteActionsHistoryOnly(props.values.reportTypes) ? (
                  <></>
                ) : (
                  <FormComponents.Section layout="single" paddingSize="none">
                    <P color="charcoal" size="small" mb="15px" mt="15px" bold>
                      {t('spaceDetails:modals.export_report.date_label')}
                    </P>
                    <Field
                      name="monthRange"
                      render={({ field, form }: any) => {
                        const [start, end] = field.value;
                        return (
                          <MonthPicker
                            name="monthRange"
                            startDate={start}
                            endDate={end}
                            onChange={(dates: Date[]) => {
                              form.setFieldValue('monthRange', dates);
                              setError(null);
                            }}
                            minDate={applyMinDate()}
                          />
                        );
                      }}
                    />
                    <Flex mt={10}>
                      <IcomoonIcons icon="info" color="blueGrey" size={18} />
                      <Spacer width={5} />
                      <P size="small" color="charcoal">
                        {isNetworkLevelReport
                          ? t(
                              'doorDetails:modal.export_report.notification_all_doors',
                            )
                          : t('doorDetails:modal.export_report.notification')}
                      </P>
                    </Flex>
                  </FormComponents.Section>
                )}

                <Grid
                  gridTemplateColumns="repeat(2, 1fr)"
                  gridGap={20}
                  marginTop={20}>
                  <Button variant="outlined" onClick={closeAndClearModal}>
                    {t('common:close')}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!props.isValid || props.isSubmitting}
                    isLoading={props.isSubmitting}>
                    {t('common:submit')}
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

export default ExportReportModal;
