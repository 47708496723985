import styled from '@emotion/styled';
import { theme, flexVertical, flexCenter, mq } from 'style';

export const StyledDiv = styled.div`
  position: relative;
  ${flexVertical}
  background-color: white;
  display: flex;
  justify-content: space-between;
  box-shadow: ${theme.boxShadow};
  width: 100%;
  a {
    color: ${theme.colors.primary};
    text-decoration: none;
  }
`;

export const StyledList = styled.div`
  ${flexCenter}
  position: relative;
  /* box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.05); */
`;

export const ListOpen = styled.div`
  position: absolute;
  left: 0;
  z-index: 10;
  width: 100%;
  top: ${theme.vars.headerHeight};
  background-color: ${theme.colors.white};
`;

export const ListDefault = styled.div`
  position: relative;
  ${flexVertical};
  padding: 0px 30px;
  cursor: pointer;
  font-size: 14px !important;
  border-left: ${theme.borders(1, 'lightBlueGrey')};
`;

export const IsActive = styled.div<{ isMobile?: boolean }>`
  position: absolute;
  height: 3px;
  width: 100%;
  bottom: 0px;
  left: 0;
  background-color: ${theme.colors.secondary};

  ${mq.mobile} {
    height: 100%;
    width: 3px;
    bottom: 0px;
    left: 0px;
  }
`;

export const DashboardListWrapper = styled.div`
  width: 250px;
  background-color: white;
`;
