import styled from '@emotion/styled';

import { theme, flexCenterAll, flexVertical } from 'style';

export const useAlertsTableStyle = () => {
  const Wrapper = styled.div`
    width: 100%;
    height: auto;
    margin-top: 15px;
    border: 1px solid ${theme.colors.lightBlueGrey};
    background-color: ${theme.colors.white};
    border-radius: 4px;
    overflow-x: auto;
  `;

  const Pagination = styled.div`
    height: 65px;
    padding: 0 12px;
    ${flexVertical};
    text-align: right;
    justify-content: flex-end;
    background-color: ${theme.colors.white};
    > p:first-child {
      margin-right: 12px;
    }
  `;

  const PaginationItem = styled.button`
    margin-left: 12px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    appearance: none;
    border: none;
    background-color: transparent;
  `;

  const GeneralHeading = styled.text`
    color: #003594;
    font-size: 12px;
    background-color: #ebf2ff;
    border-radius: 8px;
    padding: 4px 12px;
    align-items: center;
    font-weight: 500;
    margin-left: 5px;
  `;

  const CriticalHeading = styled.text`
    color: #e3002a !important;
    font-size: 12px;
    background-color: #ffe9ed;
    border-radius: 8px;
    padding: 4px 12px;
    align-items: center;
    font-weight: 500;
    margin-left: 5px;
  `;

  return {
    Wrapper,
    Pagination,
    PaginationItem,
    GeneralHeading,
    CriticalHeading,
  };
};
