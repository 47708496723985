import React from 'react';
import { Flex, IcomoonIcons, Paragraph as P, ToolTip } from 'components';
import { IconContainer, IconCheckContainer } from './statusStyle';
import i18n from 'core/i18n/i18n';

interface Props {
  alertCount: number;
  isMobile?: boolean;
  isOffline?: boolean;
  isActive?: boolean;
}

const Status = ({ alertCount, isMobile, isOffline, isActive }: Props) => {
  return (
    <Flex alignItems="center">
      {isOffline === true || (isActive === false && !alertCount) ? (
        <IconContainer>
          <ToolTip
            content={i18n.t('common:status_tooltip.offline')}
            iconSize={18}
            iconType="offline"
          />
        </IconContainer>
      ) : (
        <React.Fragment>
          {alertCount === 0 ? (
            <IconContainer>
              <IcomoonIcons icon="success" size={16} color="success" />
            </IconContainer>
          ) : (
            <IconContainer>
              <IcomoonIcons icon="alert-triangle" size={16} color="error" />
            </IconContainer>
          )}
          <P size={isMobile ? 'tiny' : 'small'} color="charcoal">
            {alertCount}
          </P>
        </React.Fragment>
      )}
    </Flex>
  );
};

export default Status;
