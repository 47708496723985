import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { PasswordSet } from './forms';
import {
  ValidationSchema as PasswordSetSchema,
  ResponseSettings,
} from './forms/PasswordSet/PasswordSet';

import { ConfirmTempPassword } from 'models';
import { ActivateAccount, SignIn } from 'core/auth/auth';
import { activateCurrentUser, statusByUsername } from 'services';
import { Spinner, FullScreenContainer } from 'components';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}
// TODO: Removed this function after UAT
const replaceWithPlus = (query: string) => {
  return query.replace(' ', '+');
};

const Onboarding: React.FC = () => {
  const query = useQuery();
  const { t } = useTranslation();

  const [accountToActivate, setAccoutToActivate] = useState<any>({
    username: '',
    otp: '',
  });

  const username = replaceWithPlus(query.get('username') || '');
  const otp = query.get('otp' || '');
  const lang = query.get('lang' || '');

  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [successSettings, setSuccessSettings] = useState<ResponseSettings>(
    {} as ResponseSettings,
  );
  const [errorSettings, setErrorSettings] = useState<ResponseSettings>(
    {} as ResponseSettings,
  );
  const [formSettings, setFormSettings] = useState<PasswordSet.DefaultSettings>(
    {} as PasswordSet.DefaultSettings,
  );

  const checkUserStatus = async (username: string) => {
    try {
      const userStatus = await statusByUsername(username);
      switch (userStatus) {
        case 'Active':
          setErrorSettings({
            mainHeading: t(
              'loginActivate:activate.account_exists.main_heading',
            ),
            subHeading: t('loginActivate:activate.account_exists.sub_heading'),
            actionLabel: t(
              'loginActivate:activate.account_exists.action_label',
            ),
            action: () => history.push('/login'),
          });
          setFormState('error');

          setIsLoading(false);
          break;
        case 'Pending':
          if (otp) {
            fakeSignIn(username, otp);
          }
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fakeSignIn = async (username: string, password: string) => {
    try {
      await SignIn({ username: username, password: password });
      setIsLoading(false);
    } catch (err) {
      const { code } = err;
      if (code === 'NotAuthorizedException') {
        setFormState('expired');
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const browserLang = window.navigator.languages[0];
    console.log(`lang: ${lang}, browserLang: ${browserLang}`);
    i18n.changeLanguage(lang || browserLang);
    console.log('username: ', username);

    setFormSettings({
      mainHeading: t('loginActivate:activate.main_heading'),
      subHeading: t('loginActivate:activate.sub_heading'),
    });

    setErrorSettings({
      mainHeading: t('loginActivate:activate.error.main_heading'),
      subHeading: t('loginActivate:activate.error.sub_heading'),
      actionLabel: t('loginActivate:activate.error.action_label'),
      action: () => setFormState('default'),
    });
    
    setSuccessSettings({
      mainHeading: t('loginActivate:activate.success.main_heading'),
      subHeading: `${t('loginActivate:activate.success.sub_heading')} ${
        username
      }`,
      actionLabel: t('loginActivate:activate.success.action_label'),
      action: () => history.push('/loading?onboarding=true'),
    });

    if (!username || !otp) {
      history.push('/404');
    } else {
      checkUserStatus(username);

      setAccoutToActivate({
        username,
        otp,
      });
    }
  }, []);

  const [formState, setFormState] = useState<
    'default' | 'successful' | 'error' | 'expired'
  >('default');

  const onSubmit = async (
    values: PasswordSetSchema,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (fields: { [field: string]: string }) => void,
  ) => {
    setSubmitting(true);
    try {
      const payload: ConfirmTempPassword = {
        tempPassword: accountToActivate.otp,
        newPassword: values.password,
        username: accountToActivate.username,
      };

      await ActivateAccount(payload);
      await activateCurrentUser();
      return 'successful';
    } catch (err) {
      const { code, message } = err;
      console.log(code);

      if (code === 'InvalidPasswordException') {
        setErrorSettings({
          mainHeading: t('activate.error.main_heading'),
          subHeading: err.message,
          actionLabel: t('activate.error.action_label'),
          action: () =>
            'https://entrivo-helpandsupport.dormakaba.com/s/request-support',
        });
        return 'error';
      }
      if (code === 'ExpiredCodeException') {
        return 'expired';
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FullScreenContainer hideContactLink={isLoading}>
      {isLoading ? (
        <Spinner />
      ) : (
        <PasswordSet.Form
          formState={formState}
          setFormState={setFormState}
          onSubmit={onSubmit}
          formSettings={formSettings}
          successSettings={successSettings}
          errorSettings={errorSettings}
          showEmailInput={username}
        />
      )}
    </FullScreenContainer>
  );
};

export default Onboarding;
