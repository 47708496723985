import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18n from 'core/i18n/i18n';

import { TextField, SelectField, FormComponents, PhoneField } from 'components';
import { useScreenSize } from 'hooks';
import { LabelValue } from 'models';

const { screenSize } = useScreenSize();

export const Validation = Yup.object({
  salutation: Yup.string().ensure(),
  honorific: Yup.string().ensure(),
  firstName: Yup.string().required(i18n.t('forms:validation.required')),
  lastName: Yup.string().required(i18n.t('forms:validation.required')),
  email: Yup.string().email(i18n.t('forms:validation.email')),
  phone: Yup.string()
    .ensure()
    .matches(/^$|^\+(?:[0-9] ?){6,14}[0-9]$/, i18n.t('forms:validation.phone'))
    .nullable(),
  mobile: Yup.string()
    .matches(/^$|^\+(?:[0-9] ?){6,14}[0-9]$/, i18n.t('forms:validation.mobile'))
    .nullable()
    .ensure(),
  account: Yup.string(),
  userType: Yup.string(),
  language: Yup.string(),
});

export type AccountDetailsSchema = Yup.InferType<typeof Validation>;

// Will be populated by an API GET to the user details
export const initialAccountDetails: AccountDetailsSchema = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  mobile: '',
  honorific: '',
  salutation: '',
  account: '',
  userType: '',
  language: '',
};

const salutations: string[] = i18n.t('forms:personal_details.salutations', {
  returnObjects: true,
});
const salutations_options: LabelValue[] = salutations.map((s) => ({
  value: s,
  label: s,
}));

export const Form = ({ values }: any) => {
  const { t } = useTranslation();
  const { isMobile } = screenSize();

  return (
    <FormComponents.Section
      layout={isMobile ? 'single' : 'double'}
      heading="Personal Information">
      <SelectField
        name="salutation"
        label={t('forms:personal_details.salutation')}
        options={salutations_options}
      />
      <TextField
        name="honorific"
        label={t('forms:personal_details.honorific')}
      />
      <TextField
        required
        name="firstName"
        label={t('forms:personal_details.first_name')}
      />
      <TextField
        tooltip={t('forms:personal_details.tooltip')}
        name="lastName"
        label={t('forms:personal_details.last_name')}
        disabled
      />

      <PhoneField
        name="mobile"
        label={t('forms:personal_details.mobile_no')}
        required={values.notificationPreferences?.how?.includes('sms')}
      />
      <PhoneField name="phone" label={t('forms:personal_details.phone_no')} />

      <TextField
        name="email"
        label={t('forms:personal_details.email')}
        disabled
        tooltip={t('forms:personal_details.tooltip')}
        corporateEmailOnly
      />
      <TextField
        disabled
        name="account.name"
        label={t('forms:personal_details.account')}
        tooltip={t('forms:personal_details.tooltip')}
      />
      <TextField
        disabled
        name="userRole"
        label={t('forms:personal_details.user_type')}
        tooltip={t('forms:personal_details.tooltip')}
      />
      <SelectField
        name="language"
        label={t('forms:personal_details.language')}
        options={[
          { value: 'en', label: i18n.t('languages:en') },
          { value: 'de', label: i18n.t('languages:de') },
          { value: 'fr', label: i18n.t('languages:fr') },
        ]}
      />
    </FormComponents.Section>
  );
};
