import React, { useState, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';

import { Icon, Items, Divider } from '../mobileNavBarStyle';
import NavigationItem from 'components/Navigation/NavigationItem/NavigationItem';
import { SignOutItem } from 'components/Navigation/Navigation';
import { IcomoonIcons, LanguageToggle, Paragraph as P } from 'components';
import {
  Overlay,
  MenuHeader,
  HeaderIcon,
  ListDefault,
  ListOpen,
  DashboardListWrapper,
} from './hamburgerMenuStyles';
import {
  filterRoutes,
  NavigationRoute,
  RoutePageNames,
} from '../../navigationObjects';
import { useTopBar } from 'hooks';
import currentProduct from '../../../../utils/currentProduct';
import { SignOutButton } from 'components/Navigation/navigationStyle';
import { ReactComponent as LogoDI } from './Logo/DoorInsights.svg';
import { ReactComponent as LogoOI } from './Logo/OccupancyInsights.svg';
import { ReactComponent as Logo } from './Logo/EngageLogo.svg';
import {
  getActiveProduct,
  getCurrentProductName,
  isDualProducts,
  Product,
} from 'utils';
import { Spacer } from 'style';
import i18n from 'core/i18n/i18n';
import HomeLogo from 'components/HomeLogo/HomeLogo';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const { title } = useTopBar();
  const product = currentProduct();
  const productName = getCurrentProductName();

  useEffect(() => {
    setIsOpen(false);
  }, [title]);

  const menuTransition = useTransition(isOpen, null, {
    config: { duration: 150 },
    from: {
      transform: 'translateX(100%)',
      width: '80%',
      height: '100vh',
      right: '0%',
      top: 0,
      position: 'fixed',
      zIndex: 21,
    },
    enter: {
      transform: 'translateX(0%)',
    },
    leave: { transform: 'translateX(100%)' },
  });

  const OIRoutes: RoutePageNames[] = ['Dashboard', 'Spaces', 'Users'];
  const DIRoutes: RoutePageNames[] = [
    'Dashboard',
    'Doors',
    'Notifications',
    'Users',
    'Reports',
  ];

  const headerRoutes = isDualProducts()
    ? filterRoutes([
        'OccupancyInsights',
        'DoorInsights',
        'Spaces',
        'Doors',
        'Notifications',
        'Users',
        'Reports',
      ])
    : filterRoutes(product === Product.OI ? OIRoutes : DIRoutes);

  const hiddenRoutes = filterRoutes(['Profile', 'Settings', 'Support']);

  const [isDashboardListOpen, setIsDashboardListOpen] = useState<boolean>(
    false,
  );

  const toggleDashboardList = () => {
    setIsDashboardListOpen(!isDashboardListOpen);
  };

  const closeDashboardList = () => setIsDashboardListOpen(false);

  const isDashboardPage = () => {
    const pathname = window.location.pathname;
    return pathname === '/door-insights' || pathname === '/occupancy-insights';
  };

  return (
    <>
      {isOpen && <Overlay />}
      <Icon onClick={toggleOpen} data-testid="hamburger-button">
        <IcomoonIcons icon={isOpen ? 'close' : 'menu'} color="white" />
      </Icon>

      {menuTransition.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              <MenuHeader>
                <HomeLogo productName={productName} variant="secondary" />
                <HeaderIcon onClick={toggleOpen}>
                  <IcomoonIcons
                    icon={isOpen ? 'close' : 'menu'}
                    color="white"
                  />
                </HeaderIcon>
              </MenuHeader>
              <Items isOpen={isOpen}>
                {isDualProducts() && (
                  <>
                    <ListDefault
                      onClick={toggleDashboardList}
                      style={
                        isDashboardListOpen ? { borderBottom: 'none' } : {}
                      }>
                      <IcomoonIcons
                        icon="dashboard"
                        size={24}
                        color="primary"
                      />
                      <Spacer width={10} />
                      <P color="primary">{i18n.t('navigation:home')}</P>
                      <Spacer width={10} />
                      <IcomoonIcons
                        icon="chevron-down"
                        color="primary"
                        size={16}
                      />
                    </ListDefault>
                    {isDashboardListOpen && (
                      <ListOpen onClick={toggleDashboardList}>
                        <DashboardListWrapper>
                          {headerRoutes.map((route: NavigationRoute) => {
                            if (
                              route.routeName !== 'OccupancyInsights' &&
                              route.routeName !== 'DoorInsights'
                            ) {
                              return null;
                            }

                            return (
                              <NavigationItem
                                key={route.to}
                                to={route.to}
                                label={route.label}
                                isInList
                              />
                            );
                          })}
                        </DashboardListWrapper>
                      </ListOpen>
                    )}
                  </>
                )}
                {headerRoutes.map((route: NavigationRoute) => {
                  if (
                    route.routeName === 'OccupancyInsights' ||
                    route.routeName === 'DoorInsights'
                  ) {
                    return null;
                  }

                  const activeProduct = getActiveProduct();
                  if (
                    activeProduct === 'OccupancyInsights' &&
                    route.routeName === 'Doors'
                  ) {
                    return null;
                  }
                  if (
                    activeProduct === 'OccupancyInsights' &&
                    route.routeName === 'Reports'
                  ) {
                    return null;
                  }
                  if (
                    activeProduct === 'DoorInsights' &&
                    route.routeName === 'Spaces'
                  ) {
                    return null;
                  }
                  if (
                    activeProduct === 'OccupancyInsights' &&
                    route.routeName === 'Notifications'
                  ) {
                    return null;
                  }

                  return (
                    <NavigationItem
                      key={route.routeName}
                      to={route.to}
                      icon={route.icon}
                      iconSize={route.iconSize}
                      label={route.label}
                      onClick={() => toggleOpen()}
                    />
                  );
                })}

                <Divider />

                {hiddenRoutes.map((item) => (
                  <NavigationItem
                    key={item.to}
                    to={item.to}
                    label={item.label}
                    icon={item.icon}
                    iconSize={item.iconSize}
                    isInList
                    isHiddenRoute
                    onClick={() => toggleOpen()}
                  />
                ))}
                <SignOutButton>
                  <LanguageToggle />
                </SignOutButton>
                <SignOutItem isHiddenRoute />
              </Items>
            </animated.div>
          ),
      )}
    </>
  );
};

export default HamburgerMenu;
