import React from 'react';

import { BarChartWrapper, BarChartSegment } from './cellComponentStyle';

import { theme } from 'style';
import { occupancyChartConfig } from 'utils';
import { SpaceDataSummary } from 'models';

const BarChart = ({ dataSummary }: { dataSummary: SpaceDataSummary }) => {
  const generatePct = (
    pct:
      | 'exceededPct'
      | 'withinPct'
      | 'approachingPct'
      | 'offlinePct'
      | 'notCommissionedPct',
  ) => {
    return Number(
      ((dataSummary[pct] / (100 - dataSummary.futurePct)) * 100).toFixed(),
    );
  };
  return (
    <BarChartWrapper>
      <BarChartSegment
        width={generatePct('exceededPct')}
        backgroundColor={theme.colors[occupancyChartConfig[0].color]}
      />
      <BarChartSegment
        width={generatePct('approachingPct')}
        backgroundColor={theme.colors[occupancyChartConfig[1].color]}
      />
      <BarChartSegment
        width={generatePct('withinPct')}
        backgroundColor={theme.colors[occupancyChartConfig[2].color]}
      />
      <BarChartSegment
        width={generatePct('offlinePct')}
        backgroundColor={theme.colors[occupancyChartConfig[3].color]}
      />
      <BarChartSegment
        width={generatePct('notCommissionedPct')}
        backgroundColor={'white'}
      />
    </BarChartWrapper>
  );
};

export default BarChart;
