import i18n from 'core/i18n/i18n';
import {
  LabelValueWithIsCriticalField,
  NotifcationTypeFilter,
  NotificationType,
  User,
} from 'models';

export function getAlertTypeNames(
  userMe: User,
  alertTypes: LabelValueWithIsCriticalField[],
  filter: NotifcationTypeFilter,
): string {
  if (!userMe || !userMe.alertTypes) return '';
  if (
    filter === NotifcationTypeFilter.CriticalOnly &&
    userMe.alertTypes.includes(NotificationType.AllCritical)
  ) {
    return i18n.t('alerts:names.allCritical');
  }

  if (
    filter === NotifcationTypeFilter.GeneralOnly &&
    userMe.alertTypes.includes(NotificationType.AllGeneral)
  ) {
    return i18n.t('alerts:names.allGeneral');
  }

  let filteredAlertTypes: string[] = [];
  switch (filter) {
    case NotifcationTypeFilter.CriticalOnly:
      filteredAlertTypes = alertTypes
        .filter((a) => a.isCritical)
        .map((a) => a.value);
      break;
    case NotifcationTypeFilter.GeneralOnly:
      filteredAlertTypes = alertTypes
        .filter((a) => !a.isCritical)
        .map((a) => a.value);
      break;
  }

  let result = userMe.alertTypes.filter((a) => filteredAlertTypes.includes(a));

  result = result.sort((a, b) => (a > b ? 1 : -1));

  let alertTypesDictionary = Object.fromEntries(
    alertTypes.map((at) => [at.value, at.label]),
  );

  result = result.map((alertType) => {
    const name = alertTypesDictionary[alertType];
    return name ? name : alertType;
  });

  return result.join(', ');
}
