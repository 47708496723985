import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Container, Grid, DonutContainer } from './currentOccupancyStyle';
import GridItem from './GridItem/GridItem';

import { ChartContainer, DonutChart } from 'components';
import { useCurrentOccupancyStore, useWebSocketMessageStore } from 'stores';
import { CurrentOccupancy as CurrentOccupancyModel, StatusEnum } from 'models';
import { occupancyChartConfig, CurrentOccupancyDataObject } from 'utils';

const mapDataCount = (obj: any) => {
  return occupancyChartConfig
    .filter((item) => item.name !== 'future')
    .filter((item) => item.name !== 'notCommissioned')
    .map((item) => {
      return { ...item, count: obj[item.key.limit] };
    });
};

const mapWithoutOffline = (arr: any) => {
  return arr.filter(
    (item: any) => item.key !== 'spacesOffline' || item.key !== 'futurePct',
  );
};
const calculateCompliancy = (data: CurrentOccupancyModel) => {
  const { spacesCount, spacesOffline, withinLimit, approachingLimit } = data;
  const top = withinLimit + approachingLimit;
  const bottom = spacesCount - spacesOffline;

  if (top === 0 && bottom === 0) {
    return `--%`;
  } else {
    return `${Math.floor((top * 100) / bottom)}%`;
  }
};

interface Props {
  followedOnly: boolean;
}

const CurrentOccupancy = ({ followedOnly }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    isLoading,
    currentOccupancyData,
    currentOccupancyFetch,
  } = useCurrentOccupancyStore();

  useEffect(() => {
    currentOccupancyFetch({ update: false, followedOnly });
  }, [followedOnly]);

  const { lastMessage } = useWebSocketMessageStore();

  useEffect(() => {
    if (lastMessage) {
      if (
        lastMessage.Event === 'OccupancyStatusChange' ||
        lastMessage.Event === 'SpaceOffline' ||
        lastMessage.Event === 'SpaceOnline'
      ) {
        currentOccupancyFetch({ update: true, followedOnly });
      }
    }
  }, [lastMessage]);

  const navigate = (filter: StatusEnum | null) => {
    history.push({
      pathname: '/spaces',
      state: {
        followedOnly,
        filter,
      } as any,
    });
  };

  return (
    <ChartContainer
      childrenStyleProps={{ padding: 0 }}
      title={t('currentOccupancy:title')}
      tooltip={t('currentOccupancy:tooltip')}>
      <Container>
        <DonutContainer>
          <DonutChart
            isLoading={isLoading}
            data={
              currentOccupancyData
                ? mapWithoutOffline(mapDataCount(currentOccupancyData))
                : undefined
            }
            dataKey="count"
            nameKey="label"
            label={{
              countValue: calculateCompliancy(currentOccupancyData),
              i18nLabel: t('currentOccupancy:donut_label'),
            }}
          />
        </DonutContainer>
        <Grid>
          {mapDataCount(currentOccupancyData).map(
            (item: CurrentOccupancyDataObject, index: number) => (
              <GridItem key={index} item={item} navigate={navigate} />
            ),
          )}
        </Grid>
      </Container>
    </ChartContainer>
  );
};

export default CurrentOccupancy;
