import create from 'zustand';
import moment from 'moment';

import { fetchHistoricOccupancy } from 'services';
import { HistoricOccupancy, DateObject, HistoricParams } from 'models';

type DateRange = 'day' | 'week' | 'month';
interface HistoricOccupancyStore {
  historicOccupancyData: HistoricOccupancy[];
  isLoading: boolean;
  dateRangeParam: DateObject;
  setDateRangeParam: (obj: DateObject) => void;
  params?: HistoricParams;
  setParams: (update: HistoricParams) => void;
  historicOccupancyFetch: (props: {
    update?: boolean;
    followedOnly?: boolean;
  }) => void;
}

const [useHistoricOccupancyStore] = create<HistoricOccupancyStore>(
  (set, get) => ({
    historicOccupancyData: [] as HistoricOccupancy[],
    isLoading: true,
    dateRangeParam: {
      startDate: moment()
        .startOf('day')
        .format(),
      endDate: moment().format(),
    },
    setDateRangeParam: (dateObj: any) => {
      set({ dateRangeParam: dateObj });
    },
    params: undefined,
    setParams: (update: any) => {
      const previous = get().params;
      set({ params: { ...previous, ...update } });
    },
    historicOccupancyFetch: async ({
      update = false,
      followedOnly = false,
    }) => {
      if (!update) {
        set({ isLoading: true });
      }
      try {
        const dateRangeParam = await get().dateRangeParam;
        const res = await fetchHistoricOccupancy({
          followedOnly,
          dateObject: await dateRangeParam,
          params: get().params,
        });
        set({ historicOccupancyData: await res });
        set({ isLoading: false });
      } catch (err) {
        throw err;
      }
    },
  }),
);

export default useHistoricOccupancyStore;
