import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { CopyNotification } from '../statusDisplayModalStyle';

import { Spinner, IcomoonIcons, Paragraph as P, Flex } from 'components';
import { Spacer } from 'style';

interface Props {
  children: string;
}

const CopyItemWrapper = ({
  content,
  heading,
  refresh,
  isLoading,
  override,
}: {
  isLoading: boolean;
  content: string;
  heading: string;
  refresh?: () => void;
  override?: string;
}) => {
  const { t } = useTranslation();
  const [showCopyNotification, setShowCopyNotification] = useState<boolean>(
    false,
  );
  const addToClipboard = () => {
    navigator.clipboard
      .writeText(override ? override : content)
      .then(() => {
        setShowCopyNotification(true);
      })
      .then(() => {
        setTimeout(() => {
          setShowCopyNotification(false);
        }, 1000);
      });
  };

  return (
    <Container>
      <P bold color="primary" size="small">
        {heading}
      </P>
      {isLoading ? (
        <Spinner color="white" />
      ) : (
        <Flex flexDirection="row">
          <P bold color="charcoal" size="small">
            {content}
          </P>
          <Spacer width={8} />
          <CopyNotification
            show={showCopyNotification}
            onClick={addToClipboard}
            style={{ cursor: 'pointer' }}>
            <IcomoonIcons icon="copy" color="greyBlue" size={18} />
            <span>{t('spaceDetails:modals.status_display.clipboard')}</span>
          </CopyNotification>
          {refresh && (
            <>
              <Spacer width={8} />
              <div onClick={refresh} style={{ cursor: 'pointer' }}>
                <IcomoonIcons icon="refresh" color="greyBlue" size={18} />
              </div>
            </>
          )}
        </Flex>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export default CopyItemWrapper;
