import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChartContainer, Flex, SlideToggle } from 'components';
import { Spacer } from 'style';
import { SpaceDailyOccupancyStatus, HistoricOccupancyChart } from '..';

interface Props {}

type ChartTypes = 'Daily Occupancy' | 'Historic Occupancy';

const MobileCharts = ({}: Props) => {
  const { t } = useTranslation();

  const [displayedChart, setDisplayedChart] = useState<ChartTypes>(
    'Daily Occupancy',
  );

  return (
    <>
      <Flex justifyContent="stretch">
        <SlideToggle
          items={[
            {
              label: 'Daily Occupancy',
              callback: () => setDisplayedChart('Daily Occupancy'),
            },
            {
              label: 'Historic Occupancy',
              callback: () => setDisplayedChart('Historic Occupancy'),
            },
          ]}
          fontSize={14}
        />
      </Flex>
      <Spacer height={20} />
      {displayedChart === 'Daily Occupancy' && (
        <ChartContainer
          title={t('spaceDetails:charts.daily_occupancy')}
          tooltip={t('spaceDetails:charts.daily_occupancy_tooltip')}>
          <SpaceDailyOccupancyStatus />
        </ChartContainer>
      )}
      {displayedChart === 'Historic Occupancy' && <HistoricOccupancyChart />}
    </>
  );
};

export default MobileCharts;
