import React, { useEffect, useState } from 'react';
import { useLocation, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OptionTypeBase } from 'react-select';

import SpacesTable from './SpacesTable/SpacesTable';
import SpaceTableLoading from './SpacesTable/LoadingSkeleton/LoadingSkeleton';

import {
  Paragraph as P,
  Flex,
  PageHeader,
  ActionBar,
  Container,
} from 'components';
import { SpacesList, SpaceListParams } from 'models';
import { fetchSpacesList, fetchSpacesFilters } from 'services';
import {
  useTableSortBy,
  useTableSearch,
  useTableFilter,
  useScreenSize,
  usePrevious,
} from 'hooks';
import { useWebSocketMessageStore } from 'stores';
import {
  statusTranslations,
  typeTranslations,
  applyFilterTranslations,
} from 'utils';

import { SearchBarProps } from 'components/SearchBar/SearchBar';
import { DropdownSelectProps } from 'components/DropdownSelect/DropdownSelect';

const TableError = ({
  heading,
  subheading,
}: {
  heading: string;
  subheading: string;
}) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    paddingTop={window.innerHeight / 2 - 350}>
    <P size="large" color="primary" bold mb="15px">
      {heading}
    </P>
    <P size="normal" color="charcoal">
      {subheading}
    </P>
  </Flex>
);

const shouldTableUpdate = (spaceId: string, data: SpacesList[]) => {
  const ids = data.map((item: SpacesList) => item.summary.spaceId);
  if (ids.includes(spaceId)) {
    return true;
  } else {
    return false;
  }
};

const Spaces = () => {
  const location = useLocation<string>();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [data, setData] = useState<SpacesList[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const limit = 20;
  const currentPage = 1;

  const locationState = location.state as any;
  const [listParams, setListParams] = useState<SpaceListParams | undefined>(
    locationState ? { status: locationState.filter } : ({} as SpaceListParams),
  );
  const [followedOnly, setFollowedOnly] = useState<boolean>(
    locationState?.followedOnly || false,
  );

  // Searching ===============================================================
  const { searchValue, searchHandler, clearSearch } = useTableSearch(
    listParams,
    setListParams,
  );
  const clearSearchWrapper = () => {
    clearSearch();
    fetch(followedOnly);
  };
  const searchBarSettings: SearchBarProps = {
    placeholder: t('spacesList:search_bar_placeholder'),
    searchValue: searchValue || '',
    searchHandler,
    clearSearch: clearSearchWrapper,
  };

  // Dropdown Toggle ===============================================================
  const dropdownOptions = [
    { value: false, label: t('spacesList:dropdown_toggle.all') },
    { value: true, label: t('spacesList:dropdown_toggle.favorite') },
  ];
  const [dropdownValue, setDropdownValue] = useState<OptionTypeBase>(
    dropdownOptions[0],
  );

  const dropdownHandler = (e: typeof dropdownOptions[0]) => {
    setListParams({ ...listParams });
    setFollowedOnly(e.value);
    setDropdownValue(e);
  };
  const dropdownToggleSettings: DropdownSelectProps = {
    options: dropdownOptions,
    onChange: dropdownHandler,
    name: 'dropdown-toggle',
    value: dropdownValue,
    clear: () => setFollowedOnly(false),
  };

  // Filtering ===============================================================

  const [filtersFromServer, setFiltersFromServer] = useState<any>({
    statuses: [],
    types: [],
  });

  const fetchFilters = async () => {
    try {
      const res = await fetchSpacesFilters();
      if (res) {
        const statusRes = await applyFilterTranslations(
          res.statuses,
          statusTranslations,
        );
        const typeRes = await applyFilterTranslations(
          res.types,
          typeTranslations,
        );
        setFiltersFromServer({
          statuses: statusRes,
          types: typeRes,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchFilters();
  }, []);

  const [statusFilter] = useTableFilter(listParams, setListParams, {
    options: filtersFromServer.statuses,
    label: 'Status',
    name: 'status',
  });

  const [typeFilter] = useTableFilter(listParams, setListParams, {
    options: filtersFromServer.types,
    label: t('spacesList:table.filters.type'),
    name: 'type',
  });

  const filters: DropdownSelectProps[] = [statusFilter, typeFilter];

  const { sortByHandler } = useTableSortBy(listParams, setListParams);

  const fetch = async (followedOnly: boolean) => {
    setIsLoading(true);
    try {
      const res = await fetchSpacesList({ params: listParams, followedOnly });
      if (res) {
        setData(res.data);
        setTotalRows(res.totalSpaceCount);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch(followedOnly);
  }, [followedOnly]);

  const previousParams = usePrevious(listParams);
  useEffect(() => {
    if (
      previousParams &&
      JSON.stringify(listParams) !== JSON.stringify(previousParams)
    ) {
      fetch(followedOnly);
    }
  }, [listParams]);

  const { lastMessage } = useWebSocketMessageStore();

  useEffect(() => {
    if (lastMessage) {
      const identifier = lastMessage?.Target?.Identifier;
      switch (lastMessage.Event) {
        case 'OccupancyChange':
          fetch(followedOnly);
          break;
        case 'OccupancyStatusChange':
          fetch(followedOnly);
          break;
        case 'SpaceOffline':
          if (identifier && shouldTableUpdate(identifier, data)) {
            console.log('Update Status');
          }
          break;
        case 'SpaceOnline':
          if (identifier && shouldTableUpdate(identifier, data)) {
            console.log('Update Status');
          }
          break;
      }
    }
  }, [lastMessage]);

  return (
    <>
      {!isMobile && <PageHeader heading={t('spacesList:heading')} />}
      <ActionBar
        listParams={listParams}
        searchBarSettings={searchBarSettings}
        dropdownToggle={dropdownToggleSettings}
        filters={filters}
        setListParams={setListParams}
        // hasActiveFilters={Object.keys(listParams).some(
        //   (key) => (!!listParams as any)[key],
        // )}
      />
      <Container pt={[0, '30px']} pb="30px">
      {totalRows !== 0 ? (
          <P color="charcoal" mb={'24px'}>
            {t('common:showing_total.showing')}{' '}
            {currentPage === 1
              ? totalRows
              : `${(currentPage - 1) * limit + 1} - ${(currentPage - 1) *
                  limit +
                  totalRows}`}{' '}
            {t('common:showing_total.of')} {totalRows}{' '}
            {t('spacesList:heading').toLowerCase()}
          </P>
        ) : (
          <div></div>
        )}
        {!isLoading && data.length === 0 ? (
          <TableError
            heading={t('spacesList:table.errors.empty.heading')}
            subheading={t('spacesList:table.errors.empty.subheading')}
          />
        ) : (
          <SpaceTableLoading isLoading={isLoading}>
            <SpacesTable sortBy={sortByHandler} 
            data={data} />
          </SpaceTableLoading>
        )}
      </Container>
    </>
  );
};

export default Spaces;
