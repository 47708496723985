import styled from '@emotion/styled';
import { mq, theme } from 'style';

export const Container = styled.div`
  padding: 40px 65px;
  display: flex;

  > div {
    width: 50%;

    &:first-of-type {
      padding-right: 40px;
      ${mq.mobile()} {
        padding-right: 0;
      }
    }
    &:last-of-type {
      padding-left: 39px;
      border-left: ${theme.borders(1, 'lightBlueGrey')};
      ${mq.mobile()} {
        border-left: none;
        padding-left: 0;
      }
    }
  }
  ${mq.mobile()} {
    display: block;
    padding: 25px 30px;
    > div {
      width: 100%;
    }
  }
`;
