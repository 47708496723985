import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Truncate, IcomoonIcons, Paragraph as P, Box } from 'components';
import { isDIS } from 'utils';

interface Props {
  row: any;
  navigateOnRowClick: (id: any) => void;
  isAdmin: boolean;
}

const UserListItem = ({ row, navigateOnRowClick, isAdmin }: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      padding="15px 20px"
      key={row.id}
      onClick={() => {
        navigateOnRowClick(row.original);
      }}>
      <Grid gridTemplateColumns={isAdmin ? `1fr 1fr 2fr 20px ${isDIS() ? '2fr' : ''}` : `1fr 1fr 2fr ${isDIS() ? '2fr' : ''}`} gridTemplateRows="auto auto">
        <Truncate gridColumn="1 / span 7">
          <P size="small" color="charcoal" marginBottom="10px">
            {`${row.original.firstName} ${row.original.lastName}`}
          </P>
        </Truncate>
        <div>
          <P size="tiny" uppercase color="blueGrey" gridRow={3} gridColumn={2}>
            {t('usersList:table.column.role')}
          </P>
          <Truncate gridColumn={2} gridRow={4}>
            <P size="tiny">{row.values.userRole}</P>
          </Truncate>
        </div>
        <div>
          <P size="tiny" uppercase color="blueGrey" gridRow={3} gridColumn={2}>
            {t('usersList:table.column.status')}
          </P>
          <Truncate gridColumn={2} gridRow={4}>
            <P size="tiny">{row.values.status}</P>
          </Truncate>
        </div>
        <div>
          <P size="tiny" uppercase color="blueGrey" gridRow={3} gridColumn={2}>
            {t('usersList:table.column.account')}
          </P>
          <Truncate gridColumn={2} gridRow={4}>
            <P size="tiny">{row.values.accountName}</P>
          </Truncate>
        </div>
        
        { isAdmin && (
          <Grid
            gridColumn={4}
            gridRow={2}
            alignSelf="end"
            justifySelf="end">
            <IcomoonIcons icon="chevron-right" />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default UserListItem;
