import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledDiv } from './doorStateTabContentStyle';

import {} from 'components';
import { useScreenSize } from 'hooks';

interface Props {
  children: React.ReactNode;
}

const DoorStateTabContent = ({ children }: Props) => {
  useTranslation();
  const { isMobile } = useScreenSize().screenSize();
  return <StyledDiv isMobile={isMobile}>{children}</StyledDiv>;
};

export default DoorStateTabContent;
