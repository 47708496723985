import React, { useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleButton } from './cellStyle';
import { followSpace, unfollowSpace } from 'services';
import { Paragraph as P, IcomoonIcons, Spinner, Box } from 'components';
import { Spacer } from 'style';

const FollowToggle = ({
  id,
  isFollowing,
}: {
  id: string;
  isFollowing: boolean;
}) => {
  const { t } = useTranslation();
  const [following, setFollowing] = useState(isFollowing);
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsFollowing = async (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!isLoading) {
      setIsLoading(true);
      const status = !following
        ? await followSpace(id)
        : await unfollowSpace(id);

      if (status === 200) {
        setFollowing(!following);
      }
      setIsLoading(false);
    }
  };

  return (
    <ToggleButton status={following} onClick={toggleIsFollowing}>
      {isLoading ? (
        <Box
          width="42px"
          padding="0"
          backgroundColor="transparent"
          border="none">
          <Spinner
            diameter="20px"
            margin="0 auto"
            color={isFollowing ? 'primary' : 'charcoal'}
          />
        </Box>
      ) : (
        <>
          <IcomoonIcons
            icon={following ? 'favourite-filled' : 'favourite'}
            size={12}
            color={following ? 'primary' : 'charcoal'}
          />

          <>
            <Spacer width={5} />
            <P color={following ? 'primary' : 'charcoal'} size="tiny" bold>
              {t('spacesList:table.button.follow')}
            </P>
          </>
        </>
      )}
    </ToggleButton>
  );
};

export default FollowToggle;
