import create from 'zustand';

const [useNavigation] = create((set) => ({
  isNavigationHidden: false,
  showNavigation: () => {
    set({ isNavigationHidden: false });
  },
  hideNavigation: () => {
    set({ isNavigationHidden: true });
  },
}));

export default useNavigation;
