import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DesktopNavBar from './DesktopNavBar/DesktopNavBar';
import MobileNavBar from './MobileNavBar/MobileNavBar';
import { StyledDiv, SignOutButton, Icon } from './navigationStyle';

import { useWindowSize } from 'hooks';
import { IcomoonIcons } from 'components';
import { SignOut } from 'core/auth/auth';

interface Props {
  isHiddenRoute?: boolean;
}

export const SignOutItem: React.FC<Props> = ({ isHiddenRoute }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const logout = async () => {
    try {
      await SignOut();
    } catch (err) {
      throw err;
    } finally {
      history.push('/login');
    }
  };

  return (
    <SignOutButton onClick={logout} isHiddenRoute={isHiddenRoute}>
      <Icon>
        <IcomoonIcons icon="log-out" color="primary" size={16} />
      </Icon>
      <span>{t('navigation:log_out')}</span>
    </SignOutButton>
  );
};

const Navigation = () => {
  const { windowSize } = useWindowSize();

  const renderNav = () => {
    if (windowSize.width <= 1169) {
      return <MobileNavBar />;
    } else {
      return <DesktopNavBar />;
    }
  };

  return (
    <StyledDiv data-testid="navigation" data-cypress="navigation">
      {renderNav()}
    </StyledDiv>
  );
};

export default Navigation;
