import React from 'react';
import moment from 'moment';
import { UseTableRowProps } from 'react-table';
import { useTranslation } from 'react-i18next';

import { MobileTableComponents, StatusIndicator, Paragraph } from 'components';
import { SpaceTrafficHistory } from 'models';

import { LINE_COLORS } from '../../TrafficHistory';
interface Props {
  row: UseTableRowProps<SpaceTrafficHistory>;
  selectedRow: any;
  selectedRowAction: any;
}

const MobileItem = ({ row, selectedRow, selectedRowAction }: Props) => {
  const { t } = useTranslation();

  //@ts-ignore
  const highestAt = moment(row.original.highestAt).format('HH:MM');
  //@ts-ignore
  const lowestAt = moment(row.original.lowestAt).format('HH:MM');

  return (
    <MobileTableComponents.Wrapper
      key={row.id}
      backgroundColor={
        selectedRow && selectedRow.id === row.id ? 'lightBlueGrey' : 'white'
      }
      onClick={() => selectedRowAction(row)}>
      <MobileTableComponents.Item
        row={1}
        column="span 3"
        style={{ marginBottom: 10 }}>
        <StatusIndicator
          label={row.values.Day}
          indicatorColor={LINE_COLORS[row.values.Day]}
        />
      </MobileTableComponents.Item>

      <MobileTableComponents.ColumnHeader row={2} column={1}>
        {t('spaceDetails:charts.traffic_history_chart.average')}
      </MobileTableComponents.ColumnHeader>
      <MobileTableComponents.Item row={3} column={1}>
        <Paragraph size="small">{row.values.Average}</Paragraph>
      </MobileTableComponents.Item>
      <MobileTableComponents.ColumnHeader row={2} column={2}>
        {t('spaceDetails:charts.traffic_history_chart.highest')}
      </MobileTableComponents.ColumnHeader>
      <MobileTableComponents.Item row={3} column={2}>
        <Paragraph size="small">
          {row.values.Highest} ({highestAt})
        </Paragraph>
      </MobileTableComponents.Item>
      <MobileTableComponents.ColumnHeader row={2} column={3}>
        {t('spaceDetails:charts.traffic_history_chart.lowest')}
      </MobileTableComponents.ColumnHeader>
      <MobileTableComponents.Item row={3} column={3}>
        <Paragraph size="small">
          {row.values.Lowest} ({lowestAt})
        </Paragraph>
      </MobileTableComponents.Item>
    </MobileTableComponents.Wrapper>
  );
};

export default MobileItem;
