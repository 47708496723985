import styled from '@emotion/styled';

export const StyledMobileToggle = styled.div`
  z-index: 1;
  background: white;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 16px;
  height: 50px;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.05);
`;
