import styled from '@emotion/styled';
import { theme, inputDefaults } from 'style';

interface InputProps {
  disabled?: boolean;
}

export const StyledSelect = styled.select<InputProps>(
  {
    width: '100%',
    boxSizing: 'border-box',
    padding: '15px 18px',
    border: 'none',
    outline: 'none',
    borderRadius: 0,
  },
  (props) => ({
    backgroundColor: props.disabled ? theme.colors.lightGrey : 'white',

    border: props.disabled
      ? `1px solid ${theme.colors.lightGrey}`
      : theme.borders(1, 'lightBlueGrey'),
  }),
);

export const selectStyles = {
  option: (provided: any, { isSelected }: { isSelected: any }) => ({
    ...provided,
    fontSize: 14,
    textAlign: 'left',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    borderRadius: 0,
    color: theme.colors.charcoal,
    backgroundColor: isSelected ? theme.colors.lightBlueGrey : '#fff',
    paddingLeft: '17px',
    '&:hover': {
      color: theme.colors.charcoal,
      backgroundColor: theme.colors.lightBlueGrey,
    },
  }),
  container: (base: any) => ({
    ...base,
    width: '100%',
  }),
  menuList: (base: any) => ({
    ...base,
    border: theme.borders(1, 'lightBlueGrey'),
    borderTop: 'none',
    top: '0 !important',
    fontSize: 14,
    lineHeight: '21px',
    borderRadius: 0,
    boxShadow: '0px 3px 24px rgba(0, 0, 0, 0.05)',
    padding: 0,
  }),
  menu: (base: any) => ({
    ...base,
    marginTop: '0',
    boxShadow: 'none',
  }),
  control: (base: any, state: any) => ({
    ...base,
    ...inputDefaults,
    borderRadius: state.menuIsOpen ? '4px 4px 0 0' : '4px',
    width: '100%',
    marginBottom: 0,
    textAlign: 'left',
    fontSize: 14,
    cursor: 'pointer',
    border: state.isDisabled
      ? theme.borders(1, 'lightGrey')
      : theme.borders(1, 'lightBlueGrey'),
    '&:hover': { border: theme.borders(1, 'lightBlueGrey') },
    backgroundColor: state.isDisabled
      ? theme.colors.lightGrey
      : theme.colors.white,
    outline: 'none',
    boxShadow: 'none',
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transition: 'all .2s ease',
    marginRight: 2,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
    paddingLeft: 2,
    lineHeight: 1.4,
  }),
};
