import { PageHeader, Container, Flex, Paragraph as P, Grid } from 'components';
import DropdownSelect from 'components/DropdownSelect/DropdownSelect';
import { useScreenSize } from 'hooks';
import { ReportType, ReportsList } from 'models/Report';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownContainer, SectionHeader } from './reportStyles';
import ReportsTable from './ReportsTable/ReportsTable';
import { AccountSummary, User } from 'models';
import moment, { max } from 'moment';
import i18n from 'core/i18n/i18n';
import GenerateReportModal from './Modal/GenerateReportModal';
import { useModalStore } from 'stores';
import { useLocation } from 'react-router-dom';
import { isDIS } from 'utils';
import ReportListItem from './ReportListItem/ReportListItem';

const Reports = () => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const { t } = useTranslation();

  const { generateReportModal } = useModalStore();

  const [data, setData] = useState<ReportsList[]>([]);

  const [selectedAccount, setSelectedAccount] = useState({
    accountId: '',
    accountName: '',
  });

  const [reportToDownload, setReportToDownload] = useState<ReportsList>();

  const buildMonthlyReports = (
    primaryAccount: AccountSummary,
  ): ReportsList[] => {
    const firstComissiondDate = moment(
      moment(primaryAccount.firstComissiondDate)
        .startOf('month')
        .format('YYYY-MM-DD'),
    );

    const startDate = moment(
      moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
    ).subtract(24, 'months');

    const actualStartDate = max([firstComissiondDate, startDate]);

    const monthsDiff = moment(
      moment()
        .startOf('months')
        .format('YYYY-MM-DD'),
    ).diff(moment(actualStartDate), 'months', true);

    const result = new Array<ReportsList>();

    for (let i = 0; i < monthsDiff; i++) {
      const temp = actualStartDate
        .clone()
        .add(i, 'months')
        .format('MMMM YYYY');
      result.push({
        period: temp,
        title: t('reports:monthlyReport'),
        type: ReportType.Monthly,
        month: actualStartDate
          .clone()
          .add(i, 'months')
          .format('MM'),
        year: temp.split(' ')[1],
      });
    }
    return result
      .sort((x, y) => Number(y.month) - Number(x.month))
      .sort((x, y) => Number(y.year) - Number(x.year));
  };

  const buildYearlyReports = (
    primaryAccount: AccountSummary,
  ): ReportsList[] => {
    const firstComissiondDate = moment(
      moment(primaryAccount.firstComissiondDate)
        .startOf('year')
        .format('YYYY-MM-DD'),
    );

    const startDate = moment(
      moment()
        .startOf('year')
        .format('YYYY-MM-DD'),
    ).subtract(2, 'year');

    const actualStartDate = max([firstComissiondDate, startDate]);

    const yearsDiff = moment(
      moment()
        .startOf('years')
        .format('YYYY-MM-DD'),
    ).diff(moment(actualStartDate), 'years', true);

    const result = new Array<ReportsList>();

    for (let i = 0; i < yearsDiff; i++) {
      const temp = actualStartDate
        .clone()
        .add(i, 'years')
        .format('YYYY');
      result.push({
        period: temp,
        title: t('reports:yearlyReport'),
        type: ReportType.Monthly,
        year: temp,
      });
    }
    return result.sort((x, y) => Number(y.year) - Number(x.year));
  };

  useEffect(() => {
    moment.locale(i18n.language);
  }, []);

  const location = useLocation();
  useEffect(() => {
    buildReports(ReportType.All);
  }, [location]);

  const buildReports = (type: ReportType) => {
    const userMe = window.localStorage.getItem('userMe');

    if (userMe) {
      const userDetails: User = JSON.parse(userMe);
      let accountSummary: AccountSummary | undefined;
      if (isDIS()) {
        const account = window.localStorage.getItem('selectedAccount');
        const parsedAccount = account && JSON.parse(account);
        if (parsedAccount && parsedAccount['accountId'].length > 0) {
          setSelectedAccount(parsedAccount);
        }

        accountSummary = userDetails.accountSummary.find(
          (u) => u.id === parsedAccount['accountId'],
        );
      } else {
        accountSummary = userDetails.accountSummary.find((u) => u.isPrimary);
        setSelectedAccount({
          accountId: accountSummary?.id ?? '',
          accountName: accountSummary?.name ?? '',
        });
      }

      if (accountSummary) {
        const monthlyReports = buildMonthlyReports(accountSummary);
        const yearlyReports = buildYearlyReports(accountSummary);
        switch (type) {
          case ReportType.All:
            setData(
              monthlyReports.concat(
                yearlyReports.sort((x, y) => Number(y.year) - Number(x.year)),
              ),
            );
            break;
          case ReportType.Monthly:
            setData(monthlyReports);
            break;
          case ReportType.Yearly:
            setData(yearlyReports);
            break;
          default:
            break;
        }
      }
    }
  };

  const dropdownOptions = [
    {
      value: ReportType.All,
      label: 'All Reports',
    },
    {
      value: ReportType.Monthly,
      label: 'Monthly Reports',
    },
    {
      value: ReportType.Yearly,
      label: 'Yearly Reports',
    },
  ];

  const TableError = ({
    heading,
    subheading,
  }: {
    heading: string;
    subheading: string;
  }) => (
    <Flex
      alignItems="center"
      flexDirection="column"
      paddingTop={window.innerHeight / 2 - 350}>
      <P size="large" color="primary" bold mb="15px">
        {heading}
      </P>
      <P size="normal" color="charcoal">
        {subheading}
      </P>
    </Flex>
  );

  const [selectedFilter, setSelectedFilter] = useState(dropdownOptions[0]);
  useEffect(() => {
    buildReports(selectedFilter.value);
  }, [selectedFilter]);

  const downloadReport = (report: ReportsList) => {
    console.log('report: ', report);
    setReportToDownload(report);
    generateReportModal.toggleModal();
  };

  useEffect(() => {
    if (!generateReportModal.isOpen) {
      setReportToDownload(undefined);
    }
  }, [generateReportModal.isOpen]);

  return (
    <>
      {selectedAccount.accountId.length !== 0 && reportToDownload && (
        <GenerateReportModal
          accountId={selectedAccount.accountId}
          type={reportToDownload?.type}
          month={reportToDownload.month}
          year={reportToDownload.year}
        />
      )}
      {!isMobile && <PageHeader heading={t('navigation:reports')} />}

      <Container pt={[0, '30px']} pb="30px">
        {isMobile ? (
          <SectionHeader>
            <DropdownContainer>
              <DropdownSelect
                value={selectedFilter.value}
                name="reportType"
                onChange={(filterType: any) => setSelectedFilter(filterType)}
                options={dropdownOptions}
              />
            </DropdownContainer>
            <P color="charcoal">
              {t('common:showing_total.showing')} {data.length}{' '}
              {t('common:showing_total.of')} {data.length}{' '}
              {t('common:showing_total.reports')}{' '}
              {isDIS() && selectedAccount.accountName}
            </P>
          </SectionHeader>
        ) : (
          <SectionHeader>
            <P color="charcoal">
              {t('common:showing_total.showing')} {data.length}{' '}
              {t('common:showing_total.of')} {data.length}{' '}
              {t('common:showing_total.reports')}{' '}
              {isDIS() && selectedAccount.accountName}
            </P>
            <DropdownContainer>
              <DropdownSelect
                value={selectedFilter.value}
                name="reportType"
                onChange={(filterType: any) => setSelectedFilter(filterType)}
                options={dropdownOptions}
              />
            </DropdownContainer>
          </SectionHeader>
        )}

        {isDIS() && selectedAccount.accountId.length === 0 ? (
          <TableError
            heading={t('reports:noAccountSelected')}
            subheading={t('reports:emptySubheading')}
          />
        ) : data.length === 0 ? (
          <TableError
            heading={t('reports:emptyHeading')}
            subheading={t('reports:emptySubheading')}
          />
        ) : isMobile ? (
          <Grid gridAutoFlow="row" gridRowGap="15px">
            {data.map((row, index) => (
              <ReportListItem
                key={index}
                row={row}
                navigateOnRowClick={downloadReport}
              />
            ))}
          </Grid>
        ) : (
          <ReportsTable
            data={data}
            sortBy={(column) => {
              console.log('column: ', column);
            }}
            onClick={(report) => {
              downloadReport(report);
            }}
          />
        )}
      </Container>
    </>
  );
};

export default Reports;
