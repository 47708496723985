import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Directory imports
import { ConnectedDoorHealth, InsightsDashboard } from './charts';
import { DashboardHeader, Alert } from './components';

// Src imports
import {
  SectionHeader,
  DropdownContainer,
  AccountsContainer,
} from './dashboardStyles';
import {
  Heading as H,
  SlideToggle,
  Container,
  DropdownSelect,
  Paragraph as P,
  Announcements,
  Button,
} from 'components';
import { useScreenSize } from 'hooks';
import {
  useAccountStore,
  useDoorDetailsStore,
  useDoorsStatsStore,
  useModalStore,
  useWebSocketMessageStore,
} from 'stores';
import { User } from 'models';
import { Spacer } from 'style';
import moment from 'moment';
import { isDI, isDIS, isDualProducts } from 'utils';
import {
  SectionHeader as ButtonSectionHeader,
  SectionHeaderActions,
} from '../DoorDetails/components/Alert/alertStyle';
import { ExportReportModal } from '../DoorDetails/modals';

type Props = RouteComponentProps;

const Dashboard: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [userMe, setUserMe] = useState<User>({} as User);

  const { lastMessage } = useWebSocketMessageStore();
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const { exportDoorReportModal } = useModalStore();

  const {
    selectedAccount,
    setSelectedAccount,
    accountList,
    fetchAccounts,
    accountListCount,
  } = useAccountStore();

  useEffect(() => {
    if (lastMessage) {
      // only re-render if last message belongs to current account, and it's about door insights
      if (
        (lastMessage.AccountId === userMe?.account?.id ||
          (selectedAccount.value &&
            lastMessage.AccountId === selectedAccount.value)) &&
        lastMessage.DoorUUID
      ) {
        setTriggerUpdate(!triggerUpdate);
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
      if (isDI()) {
        window.localStorage.setItem(
          'selectedAccount',
          JSON.stringify({
            accountId: JSON.parse(x).accountSummary[0].id,
            accountName: JSON.parse(x).accountSummary[0].name,
          }),
        );
      }
    }

    fetchAccounts();
  }, []);

  const { doorDetailsData } = useDoorDetailsStore();

  const [followedOnly, setFollowedOnly] = useState<boolean>(false);
  const toggleFollowedOnly = (status: boolean) => {
    setFollowedOnly(status);
  };

  const slideDoorToggleItems = [
    {
      label: t('dashboard:slide_toggle.allDoors'),
      callback: () => toggleFollowedOnly(false),
    },
    {
      label: t('dashboard:slide_toggle.followingDoors'),
      callback: () => toggleFollowedOnly(true),
    },
  ];

  const { doorsStatsFetch } = useDoorsStatsStore();

  //DIS user needs to select an account to see this button, DI user doesn't need this
  const getAccountId = () => {
    if (isDI() && userMe?.account) {
      return userMe.account.id;
    }
    if (isDIS() && selectedAccount.value.length > 0) {
      return selectedAccount.value;
    }
    return undefined;
  };

  useEffect(() => {
    doorsStatsFetch({
      update: triggerUpdate,
      followedOnly,
      accountId: selectedAccount.value,
    });
  }, [followedOnly, triggerUpdate, selectedAccount]);

  useEffect(() => {
    if (isDIS()) {
      window.localStorage.setItem(
        'selectedAccount',
        JSON.stringify({
          accountId: selectedAccount.value,
          accountName: selectedAccount.label,
        }),
      );
    }
  }, [selectedAccount]);

  const { isMobile } = useScreenSize().screenSize();

  return (
    <>
      {!isMobile && (
        <DashboardHeader
          lastUpdate={moment(doorDetailsData?.updatedAt).toDate()}
        />
      )}

      <Announcements />

      <Container>
        <SectionHeader isDIS={isDIS()}>
          <H color="primary" size="h3" mb={['15px', '15px', '15px', 0]}>
            {isDIS() ? selectedAccount.label : userMe?.account?.name}{' '}
            {t('dashboard:network_health_status_heading')}
          </H>

          <SlideToggle
            items={slideDoorToggleItems}
            indexOverride={followedOnly ? 1 : 0}
          />
        </SectionHeader>

        <ExportReportModal
          userId={userMe.id}
          installDate={doorDetailsData.installDate}
          accountId={getAccountId()}
          isNetworkLevelReport={true}
        />

        {isDIS() && (
          <AccountsContainer>
            <DropdownContainer>
              <DropdownSelect
                value={selectedAccount.value}
                name="account"
                onChange={(account: any) => setSelectedAccount(account)}
                options={[...accountList]}
              />
            </DropdownContainer>
            {accountListCount > 0 ? (
              <P color="charcoal">
                {t('dashboard:select_account.showing')}{' '}
                {selectedAccount.value ? `1` : accountListCount}{' '}
                {t('dashboard:select_account.of')} {accountListCount}{' '}
                {t('dashboard:select_account.accounts')}
              </P>
            ) : (
              <P color="charcoal">&nbsp;</P>
            )}
          </AccountsContainer>
        )}

        {((isDIS() && selectedAccount.value.length > 0) ||
          isDI() ||
          isDualProducts()) && (
          <SectionHeaderActions>
            {userMe.userRole === 'Admin' && (
              <P ml="15px" mt="25px" mb={0}>
                <Button
                  variant="contained"
                  icon="edit"
                  onClick={exportDoorReportModal.toggleModal}>
                  {t('spaceDetails:modals.export_report.title')}
                </Button>
              </P>
            )}
          </SectionHeaderActions>
        )}

        <ConnectedDoorHealth
          followedOnly={followedOnly}
          triggerUpdate={triggerUpdate}
          accountId={selectedAccount.value}
        />
        <Spacer height={30} />
        <ButtonSectionHeader>
          <H color="primary" size="h3" mb={['15px', 0]}>
            {t('dashboard:current_alert')}
          </H>
        </ButtonSectionHeader>
        {!isMobile && <Spacer height={30} />}
        <Alert followedOnly={followedOnly} triggerUpdate={triggerUpdate} />
        <Spacer height={60} />
        <InsightsDashboard
          followedOnly={followedOnly}
          triggerUpdate={triggerUpdate}
          accountId={selectedAccount.value}
        />
      </Container>
    </>
  );
};

export default Dashboard;
