import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import OperationalOverview from '../OperationalOverview/OperationalOverview';
import DoorCycles from '../DoorCycles/DoorCycles';
import { SectionHeader } from './insightsStyles';

import { Spacer } from 'style';
import { Heading as H, DateSlideToggle } from 'components';
import { SelectedDates, SelectedDateTypeEnum } from 'models';

interface InsightsProps {
  doorId: string;
  triggerUpdate: boolean;
  installDate: Date | string;
  defaultSelectedDates: SelectedDates;
  defaultSelectedDateType: SelectedDateTypeEnum;
  isOffline: boolean;
  isFollowing: boolean;
}

const InsightsDoorDetails = ({
  doorId,
  triggerUpdate,
  installDate,
  defaultSelectedDates,
  defaultSelectedDateType,
  isOffline,
  isFollowing,
}: InsightsProps) => {
  const { t } = useTranslation();
  const [selectedDates, setSelectedDates] = useState<SelectedDates>({
    startDate:
      defaultSelectedDates.startDate ||
      moment()
        .startOf('day')
        .format(),
    endDate:
      defaultSelectedDates.endDate ||
      moment()
        .endOf('day')
        .format(),
  });

  const [selectedDateType, setSelectedDateType] = useState<
    SelectedDateTypeEnum
  >(defaultSelectedDateType);

  // check if selected date is this month (to be used in summary to display correct tooltip)
  const dateChecker = (dateType: 'day' | 'week' | 'month') => {
    return (
      selectedDates.startDate ===
        moment()
          .startOf(dateType === 'week' ? 'isoWeek' : dateType)
          .format() &&
      selectedDates.endDate ===
        moment()
          .endOf(dateType === 'week' ? 'isoWeek' : dateType)
          .format()
    );
  };

  return (
    <>
      <SectionHeader>
        <H color="primary" size="h3" mb={['15px', 0]}>
          {t('dashboard:insights')}
        </H>
        <DateSlideToggle
          options={['day', 'week', 'month', 'custom']}
          defaultDates={
            defaultSelectedDates.startDate && defaultSelectedDates.endDate
              ? selectedDates
              : undefined
          }
          setDateRange={setSelectedDates}
          setDateType={setSelectedDateType}
          earliestDate={installDate}
        />
      </SectionHeader>
      <OperationalOverview
        isFollowing={isFollowing}
        followedOnly={false}
        selectedDates={selectedDates}
        selectedDateType={selectedDateType}
        triggerUpdate={triggerUpdate}
        doorId={doorId}
        isOffline={isOffline}
      />
      <Spacer height={30} />
      <DoorCycles
        isFollowing={isFollowing}
        followedOnly={false}
        selectedDates={selectedDates}
        isToday={dateChecker('day')}
        isThisWeek={dateChecker('week')}
        isThisMonth={dateChecker('month')}
        doorId={doorId}
        isOffline={isOffline}
      />
    </>
  );
};

export default InsightsDoorDetails;
