import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Heading as H, Paragraph as P, IcomoonIcons } from 'components';
import { Spacer } from 'style';

import icon from './icon.svg';

const NoLocationData = () => {
  const { t } = useTranslation();

  return (
    <Flex centered flexDirection="column" height="100%">
      <img src={icon} alt="icon" />
      <Spacer height={25} />
      <H size="h6" color="primary" mr="8px">
        {t('doorDetails:map.no_location.title')}
      </H>
      <Spacer height={10} />
      <P size="normal" color="charcoal" textAlign="center">
        {t('doorDetails:map.no_location.content')}
      </P>
    </Flex>
  );
};

export default NoLocationData;
