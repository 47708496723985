import create from 'zustand';

// each modal needs a open state and a toggle
interface ModalSettings {
  isOpen: boolean;
  toggleModal: () => void;
}

// modals are defined here for the entire app
interface ModalStore {
  globalIsModalOpen: boolean;
  changePasswordModal: ModalSettings;
  statusDisplayModal: ModalSettings;
  calibrateOccupancyModal: ModalSettings;
  exportReportModal: ModalSettings;
  exportDoorReportModal: ModalSettings;
  alertDetailModal: ModalSettings;
  followingConfirmationModal: ModalSettings;
  viewCurrentDoorStateModal: ModalSettings;
  doorStateModal: ModalSettings;
  requestRemoteOpenModal: ModalSettings;
  requestRemoteLockModal: ModalSettings;
  requestRemoteUnlockModal: ModalSettings;
  resetIoTDeviceModal: ModalSettings;
  resetOperatorModal: ModalSettings;
  clearOperatorErrorLogModal: ModalSettings;
  generateReportModal: ModalSettings;
}

const [useModalStore] = create<ModalStore>((set, get) => ({
  globalIsModalOpen: false,
  changePasswordModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().changePasswordModal;
      const update = { isOpen: !previous.isOpen };
      set({ changePasswordModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  statusDisplayModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().statusDisplayModal;
      const update = { isOpen: !previous.isOpen };
      set({ statusDisplayModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  calibrateOccupancyModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().calibrateOccupancyModal;
      const update = { isOpen: !previous.isOpen };
      set({ calibrateOccupancyModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  exportReportModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().exportReportModal;
      const update = { isOpen: !previous.isOpen };
      set({ exportReportModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  exportDoorReportModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().exportDoorReportModal;
      const update = { isOpen: !previous.isOpen };
      set({ exportDoorReportModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  alertDetailModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().alertDetailModal;
      const update = { isOpen: !previous.isOpen };
      set({ alertDetailModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  followingConfirmationModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().followingConfirmationModal;
      const update = { isOpen: !previous.isOpen };
      set({ followingConfirmationModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  viewCurrentDoorStateModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().viewCurrentDoorStateModal;
      const update = { isOpen: !previous.isOpen };
      set({ viewCurrentDoorStateModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  doorStateModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().doorStateModal;
      const update = { isOpen: !previous.isOpen };
      set({ doorStateModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  requestRemoteOpenModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().requestRemoteOpenModal;
      const update = { isOpen: !previous.isOpen };
      set({ requestRemoteOpenModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  requestRemoteLockModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().requestRemoteLockModal;
      const update = { isOpen: !previous.isOpen };
      set({ requestRemoteLockModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  requestRemoteUnlockModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().requestRemoteUnlockModal;
      const update = { isOpen: !previous.isOpen };
      set({ requestRemoteUnlockModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  resetIoTDeviceModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().resetIoTDeviceModal;
      const update = { isOpen: !previous.isOpen };
      set({ resetIoTDeviceModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  resetOperatorModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().resetOperatorModal;
      const update = { isOpen: !previous.isOpen };
      set({ resetOperatorModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  clearOperatorErrorLogModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().clearOperatorErrorLogModal;
      const update = { isOpen: !previous.isOpen };
      set({ clearOperatorErrorLogModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
  generateReportModal: {
    isOpen: false,
    toggleModal: async () => {
      const previous = get().generateReportModal;
      const update = { isOpen: !previous.isOpen };
      set({ generateReportModal: { ...previous, ...update } });
      set({ globalIsModalOpen: update.isOpen });
    },
  },
}));

export default useModalStore;
