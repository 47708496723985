import styled from '@emotion/styled';

export const Wrapper = styled.div`
  padding-bottom: 150px;
  overflow-y: scroll;
  position: fixed;
  top: calc(45px + 35px);
  background-color: white;
  padding-top: 30px;
  width: 100%;
  left: 0;
  height: calc(100vh - 45px + 35px);
  z-index: 5;
  & > div:nth-child(1) {
    padding-bottom: 140px !important;
  }
`;
