import React, { useEffect, useState } from 'react';
import {
  AnnouncementsToggle,
  AnnouncementsModal,
  IcomoonIcons,
} from 'components';
import {
  DashboardContainer,
  AnnouncementsAction,
  UnreadAnnouncementIndicator,
} from './announcementsStyle';
import { useAnnouncementStore } from 'stores';
import { useScreenSize, useTopBar } from 'hooks';

const Announcements = () => {
  const {
    isAnnouncementsLoading,
    announcementsFetch,
    markAsRead,
    announcements,
    unreadCount,
  } = useAnnouncementStore();

  async function getAnnouncements() {
    await announcementsFetch();
  }

  useEffect(() => {
    getAnnouncements();
  }, []);

  const [showAnnouncements, setShowAnnouncements] = useState<boolean>(false);

  const onAnnouncementsToggle = () => {
    setShowAnnouncements(!showAnnouncements);
  };

  const [markingAsRead, setMarkingAsRead] = useState<string>('');
  const onMarkAnnouncementAsRead = async (number: string) => {
    setMarkingAsRead(number);
    await markAsRead(number);
    setMarkingAsRead('');
  };

  const { isMobile } = useScreenSize().screenSize();
  const { setLeftAction } = useTopBar();

  useEffect(() => {
    if (isMobile) {
      setLeftAction(
        <AnnouncementsAction onClick={() => onAnnouncementsToggle()}>
          <IcomoonIcons icon="announcement" color="white" />
          {unreadCount > 0 && <UnreadAnnouncementIndicator />}
        </AnnouncementsAction>,
      );
    }
    return () => {
      setLeftAction(null);
    };
  }, [announcements, showAnnouncements]);

  return (
    <DashboardContainer>
      {!isMobile && (
        <AnnouncementsToggle
          count={unreadCount}
          onClick={onAnnouncementsToggle}
          isOpen={showAnnouncements}
        />
      )}

      <AnnouncementsModal
        announcements={announcements}
        isOpen={showAnnouncements}
        onClose={onAnnouncementsToggle}
        onRead={onMarkAnnouncementAsRead}
        isLoading={isAnnouncementsLoading}
        markingAsRead={markingAsRead}
        unreadCount={unreadCount}
      />
    </DashboardContainer>
  );
};

export default Announcements;
