export const theme: { [key: string]: any } = {
  colors: {
    primary: '#003594',
    secondary: '#E4002B',
    black: '#000000',
    blue: '#003594',
    grey: '#A1A1A1',
    greyBlue: '#6789C6',
    lightBlue: '#F1F6FF',
    charcoal: '#424242',
    darkBlue: '#052052',
    lightGreyBlue: '#F5F5F7',
    lightBlueGrey: '#D7E1F1',
    blueGrey: '#99A8B8',
    lightGrey: '#E3E3E3',
    aliceBlue: '#F7F9FC',
    axisGrey: '#E9E9EA',
    success: '#5FA82A',
    pending: '#FFDD00',
    error: '#E4002B',
    pink: '#FFF3F6',
    warning: '#FFBB00',
    white: '#FFFFFF',
    gradients: {
      primary: `linear-gradient(0deg, #FCFDFF 0%, #F8F9FF 100%);`,
      alert:
        'linear-gradient(180deg, rgba(252, 253, 255, 0.0001) -80.71%, #FAEBEF 100%);',
    },
    alerts: {
      lockingError: '#3B7212',
      batteryError: '#7F32E1',
      sabotage: '#4FAEAD',
      securityOpen: '#E6A0C9',
      presenceFail: '#C95BEA',
      presencefail: '#C95BEA',
      presenceFail_Parent: '#C95BEA',
      generalFault: '#E4002B',
      emergencyStopActivated: '#608ADB',
      emergencyLock: '#8AA0BA',
      lowBattery: '#003594',
      mainsPower: '#FFBB00',
      mainsPowerOff: '#80E5C1',
      mainsPower_Parent: '#E1AB14',
      obstruction: '#5FA72A',
      learningCycleNecessary: '#E1720C',
      breakOpenAttempt: '#C2DB5C',
      lockForced: '#D162A5',
      batteryRecharging: '#1589CB',
      offline: '#679AB2',
      emergencyExit: '#EDE387',
      motionSensorInputPermanentlyEngaged: '#23286D',
      safetySensorInputPermanentlyEngaged: '#30AD45',
      nightBankInputPermanentlyEngaged: '#5BCD22',
      safetySensorInputHinderingDoorOperation: '#6A2B28',
      emergencyStop: '#608ADB',
      smokeDetectorInputEngaged: '#8F0660',
      fireAlarmInputEngaged: '#C10400',
    },
    modeChanges: {
      modeAutomatic: '#003594',
      modePermanentOpen: '#FFBB00',
      modePartialOpen: '#5FA82A',
      modeExitOnly: '#7F32E1',
      modeClosed: '#4FAEAD',
      modeOff: '#1589CB',
      modeLock: '#A6B4FF',
      modeNight: '#C22C47',
      modePharmacy: '#E17C32',
      modeOther: '#577A46',
      modeFuture: '#FFFFFF',
      modeNotAvailable: '#A1A1A1',
    },
    withAlerts: '#ff2828',
    withoutAlerts: '#35e05e',
  },

  typography: {
    fontFamily: `Poppins, sans-serif`,
    fontWeight: {
      body: 'normal',
      heading: 'bold',
    },
  },
  vars: {
    headerHeight: '70px',
  },
  borders: (width: any, color: any) =>
    color !== 'transparent'
      ? `${width}px solid ${theme.colors[color]}`
      : `${width}px solid transparent`,
  boxShadow: '0px 2px 24px rgba(0,0,0,0.0507246)',
} as const;

export type ColorsType =
  | keyof typeof theme.colors
  | 'alerts.lockingError'
  | 'alerts.batteryError'
  | 'alerts.sabotage'
  | 'alerts.presencefai'
  | 'alerts.generalFault'
  | 'alerts.emergencyStopActivated'
  | 'alerts.emergencyLock'
  | 'alerts.lowBattery'
  | 'alerts.mainsPower'
  | 'alerts.Obstruction'
  | 'alerts.learningCycleNecessary'
  | 'alerts.breakOpenAttempt'
  | 'alerts.lockForced'
  | 'alerts.batteryRecharging'
  | 'alerts.emergencyExit'
  | 'inherit';

export interface AlertColors {
  lockingerror: string;
  batteryerror: string;
  sabotage: string;
  presenceFault: string;
  generalFault: string;
  emergencyStopActivated: string;
  emergencyLock: string;
  lowBattery: string;
  mainsPower: string;
  Obstruction: string;
  learningCycleNecessary: string;
  breakOpenAttempt: string;
  lockForced: string;
  batteryRecharging: string;
  emergencyExit: string;
  presenceFail_Parent: string;
}

export interface GradientColors {
  primary: string;
  alert: string;
}

export interface Colors {
  primary: string;
  secondary: string;
  black: string;
  grey: string;
  charcoal: string;
  greyBlue: string;
  darkBlue: string;
  lightGreyBlue: string;
  lightBlueGrey: string;
  blueGrey: string;
  lightGrey: string;
  success: string;
  pending: string;
  error: string;
  white: string;
  alerts: AlertColors;
  gradients: GradientColors;
  withAlerts: string;
  withoutAlerts: string;
}
export interface ThemeType {
  colors: Colors;
  typography: {
    fontFamily: string;
    fontWeight: {
      body: string;
      heading: string;
    };
  };
  vars: {
    headerHeight: string;
  };
  borders: (
    width: number,
    color: keyof typeof theme.colors | 'transparent',
  ) => string;
  boxShadow: string;
}
