import React from 'react';
import { useTranslation } from 'react-i18next';

import { Filters, ActiveCount } from './filterSelectStyle';
import { useScreenSize } from 'hooks';
import { IcomoonIcons, Paragraph as P } from 'components';
import { Spacer, theme } from 'style';

interface Props {
  toggleExpanded: () => void;
  isExpanded: boolean;
  filterCount: number;
}

const FilterSelect = ({ toggleExpanded, isExpanded, filterCount }: Props) => {
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  if (isMobile) {
    return (
      <Filters
        onClick={toggleExpanded}
        isExpanded={isExpanded}
        hasActiveFilters={filterCount > 0}>
        <IcomoonIcons
          icon="filter"
          size={20}
          color={filterCount > 0 ? 'white' : theme.colors.charcoal}
        />
      </Filters>
    );
  }

  return (
    <Filters onClick={toggleExpanded} isExpanded={isExpanded}>
      {filterCount > 0 ? (
        <ActiveCount>{filterCount}</ActiveCount>
      ) : (
        <IcomoonIcons icon="filter" size={20} />
      )}
      <Spacer width={5} />
      <P size="small" color="charcoal">
        {t('common:table.filters')}
      </P>
    </Filters>
  );
};

export default FilterSelect;
