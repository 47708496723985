import styled from '@emotion/styled';

export const Icon = styled.img`
  max-height: 50px;
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
`;
