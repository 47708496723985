import styled from "@emotion/styled";
import {
  ThemeType,
  flexCenter,
  theme,
  contentBody,
  mq,
  flexVertical,
} from "style";
import { color, layout } from "styled-system";

type OuterProps = {
  theme: ThemeType;
  color?: string;
  headerBackgroundImage?: string;
  backgroundColor?: boolean | string;
};

export const BackgroundImage = styled.div<{ headerBackgroundImage?: string }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: ${(props) => `url(${props.headerBackgroundImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
`;

export const OuterContainer = styled.div<OuterProps>`
  z-index: 1;
  position: relative;
  ${flexCenter};
  width: 100%;
  height: 160px;
  background-color: ${theme.colors.primary};
  ${color};
  ${layout};
  ${mq.tablet()} {
    padding: 12px 19px 19px;
    height: auto;
  }
`;

//not sure what to do about these settings props
export const InnerContainer = styled.div`
  z-index: 2;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
  ${contentBody};
  > div {
    ${flexVertical};
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
  > div:first-of-type {
      align-items: flex-start;
  }
  > div:last-of-type {
    align-items: flex-end;
  }
  ${mq.tablet()} {
    /* grid-template-columns: 1fr; */
    /* grid-template-rows: repeat(2, 1fr); */
    overflow: hidden;

  }
`;
