import React from 'react';

import { IcomoonIcons, Paragraph as P } from 'components';
import { theme, Spacer } from 'style';

import { WarningBoxContainer, IconContainer } from './warningBoxStyle';

export interface WarningBoxProps {
  variant?: 'primary' | 'primaryWhite';
  style?: any;
}

const WarningBox: React.FC<WarningBoxProps> = ({ children, style, variant }) => {
  return (
    <WarningBoxContainer style={style} variant={variant}>
      <IconContainer variant={variant}>
        <IcomoonIcons icon="alert-1" color="error" size={18} />
      </IconContainer>
      <P color={theme.colors.error} size="tiny" ml="7px">
        {children}
      </P>
    </WarningBoxContainer>
  );
};

export default WarningBox;
