import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import {
  Switch,
  Route,
  Redirect,
  RouteProps,
  useLocation,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { ErrorBoundary } from '@sentry/react';
import { getCurrentProduct, isDualProducts, Product } from 'utils';

// Shared Pages
import LoadingPage from './LoadingPage/LoadingPage';
import Activate from './Activate/Activate';

import Users from './Users/Users';
import UserDetails from './UserDetails/UserDetails';
import UserCreate from './UserCreate/UserCreate';
import Login from './Login/Login';
import PasswordReset from './PasswordReset/PasswordReset';
import Error404 from './Error404/Error404';

// Product Pages
import DoorInsightPages from 'products/door-insights';
import OccupancyInsightPages from 'products/occupancy-insights';

import { BasicError, Navigation, Spinner } from 'components';
import { useWebsocketConnection } from 'hooks';
import { setInterval } from 'timers';
import DualInsightPages from 'products/dual-insights';
import UserOnboarding from './UserOnboarding/UserOnboarding';
import { captureActiveProduct } from 'utils/captureActiveProduct';

const ProductPages = () => {
  const [product, setProduct] = useState<string | undefined>(undefined);
  const intervalId = useRef<any>();

  useEffect(() => {
    if (!product) {
      intervalId.current = setInterval(() => {
        setProduct(getCurrentProduct());
      }, 1000);
    }
    return () => clearInterval(intervalId.current);
  }, []);

  switch (product) {
    case Product.DIOI:
      return <DualInsightPages />;
    case Product.DI:
    case Product.DIS:
      return <DoorInsightPages />;
    case 'OI':
      return <OccupancyInsightPages />;
    default:
      return <PrivateRoute component={Spinner}></PrivateRoute>;
  }
};

interface PrivateProps extends RouteProps {
  component: any;
}

export const PrivateRoute: React.FC<PrivateProps> = (props) => {
  const { component: Component, ...rest } = props;

  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const fetchSessionData = async () => {
    try {
      const session = await Auth.currentSession();
      const isValid = await session.isValid();
      setIsAuthenticated(isValid);
    } catch (err) {
      throw err;
    } finally {
      setIsAuthLoading(false);
    }
  };
  useLayoutEffect(() => {
    fetchSessionData();
  }, []);

  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated ? (
          <Component {...props} />
        ) : isAuthLoading ? (
          <Spinner color="white" />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const parseRouteName = (path: string) =>
  path.split('/').filter((item: any) => item !== '')[0];

const Pages = () => {
  const location = useLocation();
  const pathsWithNoNav = [
    'activate',
    'door-display',
    'login',
    'password-reset',
    'loading',
    'onboarding',
  ];

  const { setReadyToConnect, connectToWebsocket } = useWebsocketConnection();

  const dashboardPath = isDualProducts() ? '/occupancy-insights' : '/dashboard';

  const authenticateSession = async () => {
    try {
      const session = await Auth.currentSession();
      const isValid = await session.isValid();
      setReadyToConnect(isValid);
    } catch (err) {
      throw err;
    }
  };

  captureActiveProduct();

  useLayoutEffect(() => {
    authenticateSession();
  }, []);

  useEffect(() => {
    setInterval(() => {
      connectToWebsocket();
    }, 1000 * 60 * 110); // Reconnect web socket after 110 mins to avoid the hard limit of 2 hours auto disconnection.
  }, []);

  return (
    <>
      {!pathsWithNoNav.includes(parseRouteName(location.pathname)) && (
        <Navigation />
      )}

      <ErrorBoundary
        fallback={
          <BasicError
            heading="An unexpected error has occured"
            subheading="An bug report has been logged and will be adressed shortly"
          />
        }>
        <Switch>
          {/* Shared Pages */}
          <Redirect from="/" to={dashboardPath} exact />
          <Redirect from="/home" to={dashboardPath} exact />
          <Redirect from="/homepage" to={dashboardPath} exact />
          <Route path="/activate" component={Activate} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/password-reset" component={PasswordReset} exact />
          <PrivateRoute path="/loading" component={LoadingPage} exact />
          <PrivateRoute path="/users" component={Users} exact />
          <PrivateRoute path="/users/new" component={UserCreate} exact />
          <PrivateRoute path="/users/:id" component={UserDetails} exact />
          <PrivateRoute path="/onboarding" component={UserOnboarding} exact />

          {/* Product specific pages */}
          {ProductPages()}

          <Route path="/404" component={Error404} />
          <Redirect from="*" to="/404" />
        </Switch>
      </ErrorBoundary>
    </>
  );
};

export default Pages;
