import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18n from 'core/i18n/i18n';

import {
  Heading as H,
  Paragraph as P,
  SelectField,
  FormComponents,
  Grid,
  Spinner,
} from 'components';
import { useDoorDetailsStore } from 'stores';
import { fetchAccountPicklists } from 'services';
import { AccountPicklists, IdValue, LabelValue } from 'models';

export const Validation = Yup.object({
  defaultAccountTimezone: Yup.string().required(
    i18n.t('forms:validation.required'),
  ),
});

export type SpaceSettingsSchema = Yup.InferType<typeof Validation>;

// Will be populated by an API GET to the user details
export const initialAccountDetails: SpaceSettingsSchema = {
  defaultAccountTimezone: '',
};

export const Form = () => {
  const { t } = useTranslation();
  const {
    refreshFrequencyFetch,
    firmwareUpdateOptionsFetch,
  } = useDoorDetailsStore();

  const [picklists, setPicklists] = useState<any>();

  useEffect(() => {
    const fetchPicklists = async () => {
      try {
        const picklistsRes = (await fetchAccountPicklists()) as AccountPicklists;
        setPicklists({
          timezones: picklistsRes.timezones.map((item: IdValue) => ({
            label: item.id,
            value: item.value,
          })),
        });
      } catch (err) {
        console.log(err);
      }
    };
    fetchPicklists();
  }, []);

  useEffect(() => {
    refreshFrequencyFetch();
  }, []);

  useEffect(() => {
    firmwareUpdateOptionsFetch();
  }, []);

  return (
    <FormComponents.Section layout="single">
      <P size="small" color="charcoal">
        {t('settings:door_settings.description')}
      </P>
      {/* Hiding until required */}
      {/* <Flex alignItems="center">
        <H size="h6" color="primary" mt="40px" mb="30px">
          {t('settings:door_settings.refresh_cycle_field.label')}
        </H>
        <Spacer width={5} />
      </Flex>
      <P size="normal" color="charcoal" mb="30px">
        {t('settings:door_settings.refresh_cycle_field.description')}
      </P>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr']}>
        {isLoading ? (
          <Spinner color="lightBlueGrey" />
        ) : (
          <SelectField
            name="refreshCycle"
            label={t('settings:door_settings.refresh_cycle')}
            options={translatedRefreshFrequencyData}
            disabled
          />
        )}
      </Grid>
      <Flex alignItems="center">
        <P size="small" color="charcoal" mb="30px" bold>
          {t('settings:door_settings.refresh_cycle_field.data_comsuption_estimate')}
        </P>
        <P size="small" color="charcoal" mb="30px" ml="5px">
          {t('settings:door_settings.refresh_cycle_field.days_using')}
        </P>
      </Flex>
      <Divider />
      <H size="h6" color="primary" mt="40px" mb="30px">
        {t('settings:door_settings.update_schedule_field.label')}
      </H>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr']}>
        {isLoading ? (
          <Spinner color="lightBlueGrey" />
        ) : (
          <SelectField
            name="firmwareUpdateSchedule"
            label={t('settings:door_settings.updated_schedule')}
            options={firmwareUpdateOptions}
            disabled
          />
        )}
      </Grid> */}

      <H size="h6" color="primary" mt="40px" mb="30px">
        {t('settings:space_settings.time_zone.description')}
      </H>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr']}>
        {picklists ? (
          <SelectField
            // required
            name="defaultAccountTimezone"
            label={t('settings:space_settings.time_zone.label')}
            options={picklists ? (picklists.timezones as LabelValue[]) : []}
            disabled
            tooltip={t('forms:personal_details.tooltip')}
          />
        ) : (
          <Spinner color="lightBlueGrey" />
        )}
      </Grid>
    </FormComponents.Section>
  );
};
