import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import i18n from 'core/i18n/i18n';

import {
  StyledList,
  ListDefault,
  ListOpen,
  IsActive,
  DashboardListWrapper,
} from './desktopNavBarStyle';
import NavigationItem from '../NavigationItem/NavigationItem';
import { SignOutItem } from '../Navigation';
import { NavigationRoute } from 'hooks/useNavigationItems';
import { SignOutButton } from '../navigationStyle';

import { Spacer } from 'style';
import { Flex, IcomoonIcons, Paragraph as P, LanguageToggle } from 'components';
import { useOutsideClick, useNavigationItems } from 'hooks';
import { User } from 'models';

import { IcomoonIconNames } from 'components/IcomoonIcons/IcomoonIcons';
import { isDualProducts, getActiveProduct, getCurrentProductName } from 'utils';
import HomeLogo from 'components/HomeLogo/HomeLogo';

const DesktopNavBar = () => {
  const [userMe, setUserMe] = useState<User | null>(null);
  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  const location = useLocation();

  const refDashboardList = useRef<HTMLDivElement>(null);
  const refProfileList = useRef<HTMLDivElement>(null);

  const [isDashboardListOpen, setIsDashboardListOpen] = useState<boolean>(
    false,
  );
  const [isProfileListOpen, setIsProfileListOpen] = useState<boolean>(false);

  const toggleDashboardList = () => {
    setIsDashboardListOpen(!isDashboardListOpen);
  };
  const closeDashboardList = () => setIsDashboardListOpen(false);

  const toggleProfileList = () => {
    setIsProfileListOpen(!isProfileListOpen);
  };
  const closeProfileList = () => setIsProfileListOpen(false);
  useOutsideClick(refProfileList, closeProfileList); // TODO: Pass a callback with arguments to a hook. useCallback?
  useOutsideClick(refDashboardList, closeDashboardList);

  const routesIncludeLocation = (routes: NavigationRoute[]) => {
    return routes.map((x: NavigationRoute) => x.to).includes(location.pathname);
  };
  const isDashboardPage = () => {
    const pathname = window.location.pathname;
    return pathname === '/door-insights' || pathname === '/occupancy-insights';
  };

  const { aboveTheFold, belowTheFold, product } = useNavigationItems(
    userMe?.userRole || 'Admin',
  );

  const productName = getCurrentProductName();

  return (
    <Flex
      justifyContent="space-between"
      stretch
      cypress="navigation-is-showing">
      <Link to="/dashboard">
        <div style={{ paddingTop: 20, paddingLeft: 20 }}>
          <HomeLogo productName={productName} variant="primary" />
        </div>
      </Link>
      <Flex>
        {isDualProducts() && (
          <StyledList ref={refDashboardList} data-testid="profile-dropdown-btn">
            <ListDefault
              onClick={toggleDashboardList}
              style={isDashboardListOpen ? { borderBottom: 'none' } : {}}>
              <IcomoonIcons icon="dashboard" size={24} color="primary" />
              <Spacer width={10} />
              <P color="primary">{i18n.t('navigation:home')}</P>
              <Spacer width={10} />
              <IcomoonIcons icon="chevron-down" color="primary" size={16} />
              {isDashboardPage() && <IsActive />}
            </ListDefault>
            {isDashboardListOpen && (
              <ListOpen onClick={toggleDashboardList}>
                <DashboardListWrapper>
                  {aboveTheFold.map((route: NavigationRoute) => {
                    if (
                      route.routeName !== 'OccupancyInsights' &&
                      route.routeName !== 'DoorInsights'
                    ) {
                      return null;
                    }

                    return (
                      <NavigationItem
                        key={route.to}
                        to={route.to}
                        label={route.label}
                        isInList
                      />
                    );
                  })}
                </DashboardListWrapper>
              </ListOpen>
            )}
          </StyledList>
        )}

        {aboveTheFold.map((route: NavigationRoute) => {
          if (
            route.routeName === 'OccupancyInsights' ||
            route.routeName === 'DoorInsights'
          ) {
            return null;
          }

          const activeProduct = getActiveProduct();
          if (
            activeProduct === 'OccupancyInsights' &&
            route.routeName === 'Doors'
          ) {
            return null;
          }
          if (
            activeProduct === 'DoorInsights' &&
            route.routeName === 'Spaces'
          ) {
            return null;
          }
          if (
            activeProduct === 'OccupancyInsights' &&
            route.routeName === 'Reports'
          ) {
            return null;
          }
          if (
            activeProduct === 'OccupancyInsights' &&
            route.routeName === 'Notifications'
          ) {
            return null;
          }

          return (
            <NavigationItem
              key={route.routeName}
              to={route.to}
              icon={route.icon}
              iconSize={route.iconSize}
              label={route.label}
            />
          );
        })}

        <StyledList ref={refProfileList} data-testid="profile-dropdown-btn">
          <ListDefault
            onClick={toggleProfileList}
            data-testid="list-toggle"
            style={isProfileListOpen ? { borderBottom: 'none' } : {}}>
            <IcomoonIcons icon="profile" color="primary" />
            <Spacer width={10} />
            <P bold color="primary" size="small">
              {userMe ? `${userMe.firstName} ${userMe.lastName}` : ''}
            </P>
            <Spacer width={10} />
            <IcomoonIcons icon="chevron-down" color="primary" size={16} />
            {routesIncludeLocation(belowTheFold) && !isProfileListOpen && (
              <IsActive />
            )}
          </ListDefault>
          {isProfileListOpen && (
            <ListOpen onClick={toggleProfileList} data-testid="list-open">
              {belowTheFold.map((item) => (
                <NavigationItem
                  key={item.to}
                  to={item.to}
                  label={item.label}
                  icon={item.icon}
                  iconSize={item.iconSize}
                  isInList
                />
              ))}

              <SignOutButton>
                <LanguageToggle />
              </SignOutButton>
              <SignOutItem />
            </ListOpen>
          )}
        </StyledList>
      </Flex>
    </Flex>
  );
};

export default DesktopNavBar;
