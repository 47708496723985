import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChartContainer,
  IcomoonIcons,
  Paragraph as P,
  Heading as H,
} from 'components';
import { EmptyContainer } from './emptyStateStyles';
import { TextContainer } from '../../OperationalOverview/NoNotification/noNotificationStyle';

interface EmptyStateProps {
  isOffline: boolean;
  isFollowing?: boolean;
}

const EmptyState = ({ isOffline, isFollowing }: EmptyStateProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ChartContainer
        title={t('doorCycles:title')}
        tooltip={t('doorCycles:title_description')}
        greenBorder={!isOffline}>
        <EmptyContainer>
          <div>
            {!isOffline && (
              <TextContainer>
                <IcomoonIcons icon="success" size={92} color="success" />
              </TextContainer>
            )}
            <TextContainer>
              <H size="h6" color="primary" mt={16}>
                {isOffline
                  ? t('doorCycles:cycles_unavailable.heading')
                  : t('doorCycles:no_cycles.heading')}
              </H>
            </TextContainer>
            <TextContainer>
              <P size="normal" color="charcoal" textAlign="center">
                {isOffline
                  ? t('common:status_tooltip.offline_unavailable')
                  : t('doorCycles:no_cycles.description_detail')}
              </P>
            </TextContainer>
          </div>
        </EmptyContainer>
      </ChartContainer>
    </>
  );
};

export default EmptyState;
