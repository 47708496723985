import styled from '@emotion/styled';
import { theme } from 'style';

export const StyledDiv = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '14px 0px',
  borderBottom: `1px solid ${theme.colors.lightBlueGrey} `,
});

export const DoorStateKeyDiv = styled.div({
  color: theme.colors.blue,
  fontWeight: 600,
});
