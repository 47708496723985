import create from 'zustand';
import i18next from 'i18next';
import {
  fetchSingleDoor,
  fetchDoorStatus,
  fetchRefreshFrequency,
  fetchfirmwareUpdateOptions,
  fetchDoorCurrentState,
  fetchDoorRemoteFunction,
  fetchTabbedDoorState,
  dataStreamingOperation,
} from 'services/doors';
import { fetchDoorErrorCodeMapping } from 'services/notifications';
import { DoorDetails, DoorStatus, RefreshFrequency, LabelValue } from 'models';
import { RemoteFunction } from 'models/DoorRemoteFunction';
import { userMeStorage } from 'utils';
import doorProfilesStorage from 'utils/doorProfilesStorage';

export interface ChildKeyValue {
  label: string;
  value: string | number | boolean | ChildKeyValue; // Recursively define nested structure
}

export interface KeyValue {
  [key: string]: {
    label: string;
    value: string | number | boolean | ChildKeyValue;
  };
}

interface DoorDetailsStore {
  doorDetailsData: DoorDetails;
  doorStatusData: DoorStatus;
  refreshFrequencyData: RefreshFrequency[];
  firmwareUpdateOptions: LabelValue[];
  availableFunctions: RemoteFunction[];
  doorDetailsError: string | null;
  isLoading: boolean;
  isCurrentStateLoading: boolean;
  isDoorStatusLoading: boolean;
  isTabbedDoorStateLoading: boolean;
  currentStateData: {
    [key: string]: any;
  };
  tabbedDoorStateData: KeyValue;
  tabbedDoorStateError: string | null;
  setTabbedDoorStateData: (data: KeyValue, doorType: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  doorDetailsFetch: (id: string) => void;
  doorStatusFetch: (id: string) => void;
  cleanUpDoorDetailsData: () => void;
  refreshFrequencyFetch: () => void;
  cleanUpRefreshFrequencyData: () => void;
  firmwareUpdateOptionsFetch: () => void;
  currentStateFetch: (id: string, doorType: string) => void;
  tabbedDoorStateFetch: (
    id: string,
    doorType: string,
    languageCode: string,
  ) => void;
  doorRemoteFunctionFetch: (model: string, doorId: string) => void;
  setIsCurrentStateLoading: (isLoading: boolean) => void;
  setIsTabbedDoorStateLoading: (isLoading: boolean) => void;
  startDataStreaming: (uuid: string) => Promise<boolean>;
  stopDataStreaming: (uuid: string) => Promise<boolean>;
}

const [useDoorDetailsStore] = create<DoorDetailsStore>((set, get) => ({
  setIsLoading: (isLoading: boolean) => {
    set({ isLoading });
  },
  setIsCurrentStateLoading: (isLoading: boolean) => {
    set({ isCurrentStateLoading: isLoading });
  },
  setIsTabbedDoorStateLoading: (isLoading: boolean) => {
    set({ isTabbedDoorStateLoading: isLoading });
  },
  setTabbedDoorStateData: (data: KeyValue, doorType: string) => {
    // format tabbedDoorStateData error
    console.log('data: ', data);
    if (
      data['errors'] &&
      data['errors'].value &&
      typeof data['errors'].value === 'object'
    ) {
      const result = doorProfilesStorage.get.diDoorErrorCodes;

      Object.values(data['errors'].value).forEach((code) => {
        const doorTypeCode = `${doorType} ${code.value}`;
        const mappedCode = result[doorTypeCode]
          ? `${code.value}(${result[doorTypeCode]})`
          : `${code.value}`;
        code.value = mappedCode;
      });
    }
    set({ tabbedDoorStateData: data });
  },
  doorDetailsData: {} as DoorDetails,
  doorStatusData: {} as DoorStatus,
  refreshFrequencyData: [] as RefreshFrequency[],
  firmwareUpdateOptions: [] as LabelValue[],
  doorDetailsError: null,
  isLoading: true,
  isCurrentStateLoading: true,
  isDoorStatusLoading: true,
  isTabbedDoorStateLoading: true,
  currentStateData: {},
  tabbedDoorStateData: {},
  tabbedDoorStateError: null,
  availableFunctions: [] as RemoteFunction[],
  startDataStreaming: async (uuid: string) =>
    await dataStreamingOperation(uuid, 'Start'),
  stopDataStreaming: async (uuid: string) =>
    await dataStreamingOperation(uuid, 'Stop'),
  doorDetailsFetch: async (id: string) => {
    set({ isLoading: true });

    fetchSingleDoor(id)
      .then((details) => {
        set({
          doorDetailsData: details,
          isLoading: false,
        });
        get().doorStatusFetch(id);
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          set({
            doorDetailsError: '403: This door does not belong to this account',
            isLoading: false,
          });
        }
      });
  },
  doorRemoteFunctionFetch: async (model: string, doorId: string) => {
    try {
      const res = await fetchDoorRemoteFunction(model, doorId);
      set({
        availableFunctions: await res,
      });
    } catch (err) {
      throw err;
    }
  },
  doorStatusFetch: async (id: string) => {
    set({ isDoorStatusLoading: true });

    fetchDoorStatus(id)
      .then((status) => {
        set({
          doorStatusData: status || {
            programSwitchMode: '',
            lockStatus: '',
            lastUpdate: '',
            isOffline: false,
          },
          isDoorStatusLoading: false,
        });
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          set({
            doorDetailsError: '403: This door does not belong to this account',
          });
        }
      });
  },
  cleanUpDoorDetailsData: () => {
    set({
      doorDetailsData: {} as DoorDetails,
      doorStatusData: {} as DoorStatus,
      isLoading: true,
      doorDetailsError: null,
    });
  },
  refreshFrequencyFetch: async () => {
    try {
      const res = await fetchRefreshFrequency();
      set({
        isLoading: false,
        refreshFrequencyData: await res,
      });
    } catch (err) {
      throw err;
    }
  },
  firmwareUpdateOptionsFetch: async () => {
    try {
      const res = await fetchfirmwareUpdateOptions();
      set({
        isLoading: false,
        firmwareUpdateOptions: await res,
      });
    } catch (err) {
      throw err;
    }
  },
  cleanUpRefreshFrequencyData: () => {
    set({
      refreshFrequencyData: [] as RefreshFrequency[],
      isLoading: true,
    });
  },
  currentStateFetch: async (id: string, doorType: string) => {
    try {
      const res = await fetchDoorCurrentState(id);

      const {
        UUID,
        timeStamp,
        ethmac,
        baudRate,
        errorCodes,
        revolutionErrors,
        information,
        ...rest
      } = res;

      if (Array.isArray(errorCodes) && errorCodes.length > 0) {
        const result = doorProfilesStorage.get.diDoorErrorCodes;
        // result looks like this {'noError': '0x0', 'rechargeableBatteryPack': '0x06'}
        errorCodes.forEach((code, index) => {
          const doorTypeCode = `${doorType} ${code}`;
          errorCodes[index] = result[doorTypeCode]
            ? `${code}(${result[doorTypeCode]})`
            : `${code}`;
        });
      }

      const formattedErrorCodes = getFormattedErrors(errorCodes, 'Error');

      const formattedRevolutionErrors = revolutionErrors
        ? getFormattedErrors(
            Object.values(revolutionErrors),
            'Revolution error ',
          )
        : [];
      const formattedInformation = getFormattedErrors(
        information,
        'Information',
      );

      set({
        isCurrentStateLoading: false,
        currentStateData: {
          UUID,
          timeStamp,
          ethmac,
          baudRate,
          ...rest,
          ...formattedInformation,
          ...formattedErrorCodes,
          ...formattedRevolutionErrors,
        },
      });
    } catch (err) {
      throw err;
    }
  },
  tabbedDoorStateFetch: async (
    id: string,
    doorType: string,
    languageCode: string,
  ) => {
    try {
      const res: KeyValue = await fetchTabbedDoorState(id, languageCode);
      if (res) {
        // format error
        if (
          res['errors'] &&
          res['errors'].value &&
          typeof res['errors'].value === 'object'
        ) {
          const result = doorProfilesStorage.get.diDoorErrorCodes;

          Object.values(res['errors'].value).forEach((code) => {
            const doorTypeCode = `${doorType} ${code.value}`;
            const mappedCode = result[doorTypeCode]
              ? `${code.value}(${result[doorTypeCode]})`
              : `${code.value}`;
            code.value = mappedCode;
          });
        }
        set({
          tabbedDoorStateData: res,
          isTabbedDoorStateLoading: false,
          tabbedDoorStateError: null,
        });
      } else {
        set({
          tabbedDoorStateData: res,
          tabbedDoorStateError: i18next.t('reports:liveDataError'),
          isTabbedDoorStateLoading: false,
        });
      }
    } catch (err) {
      throw err;
    }
  },
}));

function getFormattedErrors(errors: string[], prefix: string) {
  const formattedErrors: Record<string, string> = {};
  errors.forEach((code: string, index: number) => {
    formattedErrors[`${prefix} ${index}`] = code;
  });

  return formattedErrors;
}

export default useDoorDetailsStore;
