import create from 'zustand';
import moment from 'moment';

import { fetchDoorActivity } from 'services';
import { DailyTraffic } from 'models';
export type TrafficDirectionT = 'In' | 'Out';
export type DatePeriodT = 'Today' | 'Yesterday';

export const generateDatePeriod = (datePeriod: DatePeriodT) => {
  if (datePeriod === 'Today') {
    return {
      startDate: moment()
        .startOf('day')
        .subtract(1, 'hour')
        .format(),
      endDate: moment()
        .startOf('hour')
        .format(),
    };
  } else {
    return {
      startDate: moment()
        .subtract(1, 'day')
        .startOf('day')
        .subtract(1, 'hour')
        .format(),
      endDate: moment()
        .subtract(1, 'day')
        .endOf('day')
        .format(),
    };
  }
};

export type DailyTrafficStoreT = {
  trafficDirection: TrafficDirectionT;
  dailyTrafficError: null | string;
  datePeriod: DatePeriodT;
  setDatePeriod: (_: DatePeriodT) => void;
  setTrafficDirection: (_: TrafficDirectionT) => void;
  isLoading: boolean;
  setIsLoading: () => void;
  fetchDoorActivity: (id: string) => void;
  fetchYesterdaysTraffic: (id: string) => void;
  fetchTodaysTraffic: (id: string) => void;
  dailyTrafficData: DailyTraffic;
  todaysTraffic: DailyTraffic | undefined;
  yesterdaysTraffic: DailyTraffic | undefined;
  lastRefresh: string;
  activeDevice: null | string;
  setActiveDevice: (_: string | null) => void;
  cleanup: () => void;
  isChartLoading: boolean;
};

const [useDailyTrafficStore] = create<DailyTrafficStoreT>((set, get) => ({
  dailyTrafficData: {} as DailyTraffic,
  yesterdaysTraffic: undefined,
  todaysTraffic: undefined,
  dailyTrafficError: null,
  isLoading: true,
  setIsLoading: () => set({ isLoading: !get().isLoading }),
  datePeriod: 'Today',
  trafficDirection: 'In',
  lastRefresh: '',
  //handles chart only loading animation, so the whole block doesn't reload on sort
  isChartLoading: true,
  activeDevice: null,
  cleanup: () =>
    set({
      activeDevice: null,
      dailyTrafficData: {} as DailyTraffic,
      isLoading: true,
      isChartLoading: true,
    }),
  setActiveDevice: (_) => set({ activeDevice: _ }),
  setDatePeriod: (datePeriod) => {
    set({
      datePeriod: datePeriod,
      isChartLoading: true,
    });
    setTimeout(() => {
      set({ isChartLoading: false });
    }, 250);
  },
  setTrafficDirection: (direction) => set({ trafficDirection: direction }),
  fetchDoorActivity: async (id) => {
    try {
      const today = await fetchDoorActivity(id, generateDatePeriod('Today'));
      set({
        dailyTrafficData: today as DailyTraffic,
        //alex said this is an acceptable way of generating refresh time
        lastRefresh:
          get().datePeriod === 'Today'
            ? moment().format('HH:mm:ss')
            : moment()
                .subtract(1, 'day')
                .format('DD/MM/YYYY'),
        isLoading: false,
        isChartLoading: false,
        dailyTrafficError: null,
      });
    } catch (err) {
      set({
        dailyTrafficError: err,
        isLoading: false,
        isChartLoading: false,
      });
      throw err;
    }
  },
  fetchTodaysTraffic: async (id: string) => {
    try {
      const request = await fetchDoorActivity(id, generateDatePeriod('Today'));
      set({
        todaysTraffic: request,
      });
    } catch (err) {
      throw err;
    }
  },
  fetchYesterdaysTraffic: async (id: string) => {
    try {
      const request = await fetchDoorActivity(
        id,
        generateDatePeriod('Yesterday'),
      );
      set({
        yesterdaysTraffic: request,
      });
    } catch (err) {
      throw err;
    }
  },
}));

export default useDailyTrafficStore;
