import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import { useTranslation } from 'react-i18next';

import {
  Wrapper,
  InnerContainer,
  ClearListFiltersOI,
  ClearListFiltersDI,
} from './actionBarStyle';

import { useScreenSize, useNavigationItems } from 'hooks';
import { Grid, SearchBar, DropdownSelect, IcomoonIcons, Box } from 'components';

import { SearchBarProps } from 'components/SearchBar/SearchBar';
import { MobileFiltersMenu } from './MobileFiltersMenu';
import { DropdownSelectProps } from 'components/DropdownSelect/DropdownSelect';
import FilterSelect from './FilterSelect/FilterSelect';

export interface ActionBarProps {
  searchBarSettings: SearchBarProps;
  dropdownToggle?: DropdownSelectProps;
  filters: DropdownSelectProps[];
  searchFilter?: string | null;
  listParams: any;
  setListParams: (_: any) => void;
  ListMapToggle?: React.ReactNode;
  isFiltersExpanded?: boolean;
  toggleExpandedOnClear?: boolean;
}

const ActionBar = ({
  searchBarSettings,
  dropdownToggle,
  filters,
  setListParams,
  listParams,
  ListMapToggle,
  searchFilter = null,
  isFiltersExpanded = false,
  toggleExpandedOnClear = true,
}: ActionBarProps) => {
  const { pathname } = useLocation();
  const { isMobile } = useScreenSize().screenSize();
  const { product } = useNavigationItems('Admin');
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState<boolean>(
    isMobile ? false : isFiltersExpanded,
  );
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const height = filters.length > 2 ? 216 : 110;
  const transitions = useTransition(isExpanded, null, {
    from: { height: 0, opacity: 0 },
    enter: { height: height, opacity: 1 },
    leave: { opacity: 0, height: 0 },
  });

  const activeParams = Object.keys(listParams);
  let availableFilters = searchFilter
    ? filters.map((item: any) => item.name).concat([searchFilter])
    : filters.map((item: any) => item.name);

  if (dropdownToggle) {
    availableFilters = availableFilters.concat([dropdownToggle.name]);
  }

  const [activeFilterCount, setActiveFilterCount] = useState<number>(0);

  useEffect(() => {
    const count = activeParams.filter(
      (v) => availableFilters.includes(v) && listParams[v] !== '',
    );
    setActiveFilterCount(count.length);
  }, [listParams]);

  const clearListFilters = () => {
    setListParams({});

    if (toggleExpandedOnClear) {
      setIsExpanded(false);
    }

    if (dropdownToggle?.clear) {
      dropdownToggle.clear();
    }
  };

  const clearParam = (name: string) => {
    setListParams({
      ...listParams,
      [name]: '',
    });
  };

  useEffect(() => {
    const body = document.body;
    isMobile && isExpanded
      ? (body.style.overflow = 'hidden')
      : (body.style.overflow = 'auto');
  }, [isExpanded]);

  return (
    <Wrapper>
      <InnerContainer>
        <Grid
          style={{ position: 'relative' }}
          gridTemplateColumns={[
            `1fr ${
              pathname === '/spaces' && !isMobile
                ? '170px min-content'
                : 'min-content'
            } ${ListMapToggle ? 'min-content' : ''}`,
            `auto ${dropdownToggle ? '170px' : ''} 110px ${
              ListMapToggle ? '100px' : ''
            }`,
          ]}
          gridGap={20}>
          <SearchBar {...searchBarSettings} />
          {!isMobile && dropdownToggle && (
            <DropdownSelect {...dropdownToggle} hideLabel />
          )}
          <FilterSelect
            filterCount={activeFilterCount}
            isExpanded={isExpanded}
            toggleExpanded={toggleExpanded}
          />
          {ListMapToggle && ListMapToggle}
        </Grid>
        {transitions.map(
          ({ item, key, props }) =>
            !isMobile &&
            item && (
              <animated.div style={{ overflow: 'visible', ...props }} key={key}>
                <Grid
                  gridTemplateColumns={
                    activeFilterCount > 0 ? `auto 24px` : `auto`
                  }
                  gridColumnGap={30}>
                  <Grid
                    gridTemplateColumns="repeat(2, 1fr)"
                    gridColumnGap={20}
                    pt="30px">
                    {filters.map((item, index) => (
                      <div
                        style={{ paddingTop: 16 }}
                        key={`${item.value}_${index}`}>
                        <DropdownSelect
                          key={`${item.value}_${index}`}
                          value={item.value}
                          name={item.name}
                          label={item.label}
                          onChange={item.onChange}
                          options={item.options}
                          actionLinkLabel={t('common:clear_filters_di')}
                          onActionLink={clearParam}
                        />
                      </div>
                    ))}
                  </Grid>
                  {activeFilterCount > 0 && (
                    <ClearListFiltersDI onClick={clearListFilters}>
                      <IcomoonIcons icon={'refresh'} size={24}></IcomoonIcons>
                    </ClearListFiltersDI>
                  )}
                </Grid>
              </animated.div>
            ),
        )}
        {isMobile && isExpanded && (
          <MobileFiltersMenu
            setListParams={setListParams}
            listParams={listParams}
            setIsOpen={setIsExpanded}
            filters={[dropdownToggle!, ...filters]}
          />
        )}
      </InnerContainer>
    </Wrapper>
  );
};

export default ActionBar;
