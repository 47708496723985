import create from 'zustand';

import { fetchAnnouncements, markAnnoucementAsRead } from 'services';
import { Announcement } from 'models';

interface AnnouncementStore {
  announcements: Announcement[];
  unreadCount: number;
  isAnnouncementsLoading: boolean;
  announcementsFetch: () => void;
  markAsRead: (number: string) => void;
}

const [useAnnouncementStore] = create<AnnouncementStore>((set, get) => ({
  announcements: [] as Announcement[],
  unreadCount: 0,
  isAnnouncementsLoading: true,
  announcementsFetch: async () => {
    console.log('running fetch');
    try {
      set({ isAnnouncementsLoading: true });
      const res = await fetchAnnouncements();
      set({
        announcements: res?.filter((item) => item.status === 'Active'),
        unreadCount: res?.filter(
          (item) => item.status === 'Active' && !item.read,
        ).length,
        isAnnouncementsLoading: false,
      });
    } catch (err) {
      set({
        announcements: [],
        isAnnouncementsLoading: false,
        unreadCount: 0,
      });
      throw err;
    }
  },
  markAsRead: async (number: string) => {
    try {
      set({ isAnnouncementsLoading: true });
      await markAnnoucementAsRead(number);
      await get().announcementsFetch();
    } catch (err) {
      set({ isAnnouncementsLoading: false });
      throw err;
    }
  },
}));

export default useAnnouncementStore;
