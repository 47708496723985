import React, { useEffect } from 'react';

import { useTopBar } from 'hooks';
import { IconButton } from './actionBarStyle';
import {
  IcomoonIcons,
  DropdownSelect,
  Container,
  Grid,
  Button,
} from 'components';
import upperFirst from 'lodash/upperFirst';
import { DropdownSelectProps } from 'components/DropdownSelect/DropdownSelect';
import { Wrapper } from './mobileFiltersMenuStyle';

import FloatingActionBar from 'components/FloatingActionBar/FloatingActionBar';

type Props = {
  setIsOpen: (_: false) => void;
  filters: DropdownSelectProps[];
  listParams?: any;
  setListParams?: (_: any) => void;
  clear?: () => void;
};

export const MobileFiltersMenu = ({
  setIsOpen,
  filters,
  listParams,
  setListParams,
}: Props) => {
  const { setRightAction, setLeftAction, setTitle } = useTopBar();

  useEffect(() => {
    setRightAction(
      <IconButton onClick={() => setIsOpen(false)}>
        <IcomoonIcons icon="close" color="white" />
      </IconButton>,
    );
    setTitle('Filter');
    setLeftAction(
      <IconButton
        onClick={() => {
          if (setListParams) {
            setListParams({ followedOnly: false });
          }
        }}>
        <IcomoonIcons icon="undo" color="white" size={18} />
      </IconButton>,
    );
    return () => {
      setRightAction(null);
      setLeftAction(null);
      setTitle(upperFirst(window.location.pathname.split('/')[1]));
    };
  }, []);

  return (
    <Wrapper>
      <Container>
        <Grid gridAutoFlow="row" gridRowGap="20px">
          {filters.map((filter, i) => {
            if (!filter) return null;

            return (
              <DropdownSelect
                key={`${filter.value}_${i}`}
                label={filter.label}
                options={filter.options}
                onChange={filter.onChange}
                value={
                  (listParams && listParams[filter.name as any]) ||
                  filter.options[0]
                }
                name={filter.name}
              />
            );
          })}
        </Grid>
      </Container>
      <FloatingActionBar
        primaryAction={<Button onClick={() => setIsOpen(false)}>Update</Button>}
      />
    </Wrapper>
  );
};

export default MobileFiltersMenu;
