import { User } from "models";
import { DoorProfiles } from "models/DoorProfiles";
import { fetchAllTypes } from "./doors";
import { Product } from "utils";
import { fetchDoorErrorCodeMapping } from "./notifications";
import { hasSingleProductAccess } from "./users";


export const fetchDoorProfiles = async (user: User) => {
  try {    
    const diDoorErrorCodes = await getDIDoorErrorCodes(user);
    const data: DoorProfiles = { diDoorErrorCodes: diDoorErrorCodes };
    return data;
  } catch (err) {
    throw err;
  }
};

export async function getDIDoorErrorCodes(data: User) {
  if (hasSingleProductAccess(data, Product.DIS)) {
    const operatorTypesData = await fetchAllTypes();
    const operatorTypes = operatorTypesData.map(({ value }: { value: string; }) => value);
    const errorCodeMapping = await fetchDoorErrorCodeMapping(operatorTypes);
    return errorCodeMapping;
  }
}