export const formatLastUpdatedTime = (lastUpdate: Date) => {
  return new Date(lastUpdate).toLocaleTimeString(undefined, {
    hour12: false,
    hour: 'numeric',
    minute: 'numeric',
  });
};

export const formatLastUpdatedDate = (lastUpdate: Date) => {
  return new Date(lastUpdate).toLocaleTimeString(undefined, {
    hour12: false,
    month: 'numeric',
    day: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
};
