import moment from 'moment';

const generateDomains = (datePeriod: 'Today' | 'Yesterday') => {
  if (datePeriod === 'Today') {
    return [
      moment()
        .startOf('day')
        .unix(),
      moment()
        .endOf('day')
        .unix(),
    ];
  } else {
    return [
      moment()
        .startOf('day')
        .subtract(1, 'day')
        .unix(),
      moment()
        .endOf('day')
        .subtract(1, 'day')
        .unix(),
    ];
  }
};

export default generateDomains;
