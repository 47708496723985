import React from 'react';
import moment from 'moment';
import { Box, Paragraph } from 'components';
import { useTranslation, getInitialProps } from 'react-i18next';

const destructurePayload = (payload: any) => {
  const {initialLanguage} = getInitialProps();
  const payloadData = payload[0].payload;
  const timestamp = payloadData.timestamp;
  const cycleCount = payloadData.cycleCount.toLocaleString(initialLanguage);
  const cycleTotal = payloadData.cycleTotal.toLocaleString(initialLanguage);

  return { timestamp, cycleCount, cycleTotal };
};

// render common content
const renderContent = (cycleCount: string, cycleTotal: string, t: any) => (
  <>
    <Paragraph size="small" color="charcoal">
      {t('doorCycles:charts.tooltip.cycles')}
      {': '}
      <span style={{ fontWeight: 'bold' }}>{cycleCount}</span>
    </Paragraph>
    <Paragraph size="small" color="charcoal">
      {t('doorCycles:charts.tooltip.total_cycles')}
      {': '}
      <span style={{ fontWeight: 'bold' }}>{cycleTotal}</span>
    </Paragraph>
  </>
);

export const CustomTooltipSingleDay = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  const { t } = useTranslation();

  if (!active || !payload || payload.length === 0) return null;

  const { timestamp, cycleCount, cycleTotal } = destructurePayload(payload);

  return (
    <Box p={15} square>
      <Paragraph color="grey" size="small">
        {moment(timestamp).format('ddd D MMM YYYY, h:mmA')}
      </Paragraph>

      {renderContent(cycleCount, cycleTotal, t)}
    </Box>
  );
};

export const CustomTooltipMultipleDays = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  const { t } = useTranslation();

  if (!active || !payload) return null;

  const { timestamp, cycleCount, cycleTotal } = destructurePayload(payload);

  return (
    <Box p={15} square>
      <Paragraph color="grey" size="small">
        {moment(timestamp).format('dddd D MMMM yyyy')}
      </Paragraph>

      {renderContent(cycleCount, cycleTotal, t)}
    </Box>
  );
};

export const CustomTooltipMultipleWeeks = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  const { t } = useTranslation();

  if (!active || !payload) return null;

  const payloadData = payload[0].payload;
  const startDate = payloadData.startDate;
  const endDate = payloadData.endDate;
  const { cycleCount, cycleTotal } = destructurePayload(payload);

  return (
    <Box p={15} square>
      <Paragraph color="grey" size="small">
        {`${moment(startDate).format('dddd D')} - ${moment(endDate)
          .format('dddd D MMMM yyyy')}`}
      </Paragraph>

      {renderContent(cycleCount, cycleTotal, t)}
    </Box>
  );
};
