import styled from '@emotion/styled';
import { mq } from 'style';

export const SectionHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 3.5fr;
  grid-template-rows: 1fr;
  margin-bottom: 20px;
  align-items: center;

  ${mq.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      width: 100%;
      text-align: left;
    }
    
    > *:last-child {
      margin-top: 10px;
      width: 560px;
    }
  }
  ${mq.mobile} {
    display: flex;
    flex-direction: column;
    
    > p {
      width: 100%;
      text-align: left;
    }

    > *:last-child {
      margin-top: 10px;
    }
  }
`;