import { SpaceTrafficHistory } from 'models';

const getYDomainValue = (data: SpaceTrafficHistory[]) => {
  const val = data
    .map((item: SpaceTrafficHistory) => item.high)
    .sort((a, b) => b - a)[0];
  return Math.ceil(val / 10) * 10;
};

export default getYDomainValue;
