import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  StateItem,
  StateItemKey,
  StateItemValue,
  StatusFlare,
} from './viewCurrentStateModalStyle';

import { Flex, Modal, Skeleton, Pill, Paragraph as P } from 'components';
import { useDoorDetailsStore, useModalStore } from 'stores';
import { theme, Spacer } from 'style';
import { useScreenSize } from 'hooks';

interface Props {
  deviceUUID: string;
  lastUpdate: string;
  isOffline: boolean;
  doorType: string;
  shouldFetchData?: boolean;
  testData?: { [key: string]: any}
}

const ViewCurrentStateModal = ({
  deviceUUID,
  lastUpdate,
  isOffline,
  doorType,
  shouldFetchData = true,
  testData,
}: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize().screenSize();
  const { viewCurrentDoorStateModal } = useModalStore();
  const {
    setIsCurrentStateLoading,
    currentStateFetch,
    currentStateData,
    isCurrentStateLoading,
  } = useDoorDetailsStore();

  const closeAndClearModal = () => {
    setIsCurrentStateLoading(true);
    viewCurrentDoorStateModal.toggleModal();
  };

  useEffect(() => {
    if (viewCurrentDoorStateModal.isOpen && deviceUUID && shouldFetchData) {
      currentStateFetch(deviceUUID, doorType);
    }

    if(testData && testData.length > 0) {
      setIsCurrentStateLoading(false);
    }
    else {
      setIsCurrentStateLoading(true);
    }
  }, [viewCurrentDoorStateModal.isOpen, deviceUUID]);

  return (
    <Modal
      isOpen={viewCurrentDoorStateModal.isOpen}
      heading={t('doorDetails:modals.current_state.title')}
      tooltip={
        isOffline
          ? t('doorDetails:modals.current_state.offline_tooltip')
          : undefined
      }
      tooltipIcon="offline"
      toggleModal={closeAndClearModal}>
      {!isCurrentStateLoading && isOffline && (
        <Flex alignItems="center" pb={30}>
          <StatusFlare backgroundColor="lightBlueGrey" />
          <P size={isMobile ? 'small' : 'normal'} color="charcoal">
            {t('common:status.offline')}
          </P>
          <Spacer width={5} />
          <Pill
            text={`${t('doorDetails:page_header.last_received')} ${moment(
              lastUpdate,
            ).fromNow()}`}
            textColor="white"
            backgroundColor={theme.colors.error}
          />
        </Flex>
      )}
      {isCurrentStateLoading &&
        Array.from(Array(5).keys()).map((index) => (
          <StateItem key={index}>
            <StateItemKey>
              <Skeleton height="15px" width="14ch" />
            </StateItemKey>
            <StateItemValue>
              <Skeleton height="15px" width="14ch" />
            </StateItemValue>
          </StateItem>
        ))}
      {!isCurrentStateLoading && (
        <>
          <StateItem key="lastUpdate">
            <StateItemKey>
              {t('doorDetails:page_header.last_received')}
            </StateItemKey>
            <StateItemValue>
              {moment(lastUpdate).format('DD/MM/YYYY HH:mm:ss')}
            </StateItemValue>
          </StateItem>
          {Object.keys(currentStateData).map((key, index) => (
            <StateItem key={index}>
              <StateItemKey>{key}</StateItemKey>
              <StateItemValue>
                {currentStateData[key].toString()}
              </StateItemValue>
            </StateItem>
          ))}
        </>
      )}
    </Modal>
  );
};

export default ViewCurrentStateModal;
