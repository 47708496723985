import React, { MouseEventHandler, useState } from 'react';
import { Field, useField, } from 'formik';
import { StyledInput, CheckboxWrapper, TickIcon } from './checkboxStyle';
import { Paragraph as P, ToolTip, } from 'components';
import { Spacer } from 'style';
import { CheckboxItems } from './Checkbox';
import Divider from 'components/Divider/Divider';

interface CheckboxProps {
  name: string;
  item: CheckboxItems;
  allOptionsWithoutAll: string[];
}

const SelectAllCheckbox: React.FC<CheckboxProps> = ({
  item,
  ...props
}: CheckboxProps) => {
  const [options, setOptions] = useState([item.value]);
  let selectAll = { name: props.name, type: "checkbox" };
  const [field] = useField(selectAll);
  return (
    <Field name={props.name} key={item.value}>
    {({ form }: { form: any }) =>
      field.value ? (
        <>
          <CheckboxWrapper>
            <label // style the label to replace the hidden input
              htmlFor={`${field.name}.${item.value}`}
              style={{
                height: 18,
                width: 18,
                marginRight: 15,
              }}>
              <StyledInput
                checked={field.value.includes(item.value)}
                disabled={item.disabled || false}
                data-testid="checkbox-input">
                <TickIcon
                  viewBox="0 0 24 24"
                  checked={field.value.includes(item.value)}
                  data-testid="check-icon">
                  <polyline points="20 6 9 17 4 12" />
                </TickIcon>
              </StyledInput>
              <input
                type="checkbox"
                {...field}
                {...props}
                id={`${field.name}.${item.value}`}
                checked={field.value.includes(item.value)}
                onChange={() => {
                  setFieldValue(field, item, form, props);
                }}
                disabled={item.disabled}
                style={{
                  // hide the default input
                  border: 0,
                  clip: 'rect(0 0 0 0)',
                  height: '1px',
                  margin: '-1px',
                  overflow: 'hidden',
                  padding: 0,
                  position: 'absolute',
                  whiteSpace: 'nowrap',
                  width: '1px',
                }}
              />
            </label>
            <P
              size="small"
              color={item.disabled ? 'blueGrey' : `charcoal`}
              margin={0}>
              {item.label}
            </P>
            {item.tooltip && (
              <>
                <Spacer width={5} />
                <ToolTip
                  content={item.tooltip}
                  iconType="question"
                  iconSize={15.5}
                />
              </>
            )}
          </CheckboxWrapper>
          <Divider margin='10px'/>
        </>
      ) : (
        ''
      )
    }
  </Field>
  );

  function setFieldValue(field: any, item: CheckboxItems, form: any, props: { name: string; allOptionsWithoutAll: string[] }) {
    if (field.value.includes(item.value)) {      
      const nextValue: string[] = [];
      setOptions(nextValue);
      form.setFieldValue(props.name, nextValue);
    } else {
      const nextValue = [item.value, ...props.allOptionsWithoutAll];
      setOptions(nextValue);
      form.setFieldValue(props.name, nextValue);
    }
  }
};

export default SelectAllCheckbox;