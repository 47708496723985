import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ErrorMessage,
  SuccessHeading,
  TabContent,
  WarningHeading,
} from './doorStateModalStyle';

import {
  DoorStateTab,
  DoorStateTabContent,
  Modal,
  Skeleton,
  TabContentHeading,
  TabContentLineItem,
} from 'components';
import CheckmarkCircle from './CheckmarkCircle';
import AlertCircle from './AlertCircle';
import useScreenSize from 'hooks/useScreenSize';
import { useDoorDetailsStore, useModalStore } from 'stores';
import i18n from 'core/i18n/i18n';
import { KeyValue } from 'stores/useDoorDetailsStore';
import useDoorStateWebsocketConnection from 'hooks/userDoorStateWebsocketConnection';
import useDoorStateWebSocketMessageStore from 'stores/useDoorStateWebSocketMessageStore';

type SecondaryHeadingType = 'success' | 'warning' | 'nodata';

export type DoorStateLineItem = {
  doorStateKey: string;
  doorStateValue?: string;
  isHeading?: boolean;
  tabId?: string;
};

export type DoorStateData = {
  tabsData: {
    id: string;
    label: string;
  }[];
  tabContentsData: DoorStateLineItem[];
};

interface Props {
  deviceUUID: string;
  deviceModel: string;
}

const DoorStateModal = ({ deviceUUID, deviceModel }: Props) => {
  const [currentActiveTab, setCurrentActiveTab] = useState('all');
  const [secondaryHeadingType, setSecondaryHeadingType] = useState<
    SecondaryHeadingType
  >();
  const { t } = useTranslation();
  const { isMobile } = useScreenSize().screenSize();
  const {
    tabbedDoorStateFetch,
    setTabbedDoorStateData,
    tabbedDoorStateData,
    isTabbedDoorStateLoading,
    tabbedDoorStateError,
    setIsTabbedDoorStateLoading,
    startDataStreaming,
    stopDataStreaming,
  } = useDoorDetailsStore();

  const { doorStateModal } = useModalStore();
  const {
    setParams,
    cleanUpConnection,
    connectedToWebSocket,
    failedToConnectToWebSocket,
  } = useDoorStateWebsocketConnection();
  const { lastMessage } = useDoorStateWebSocketMessageStore();

  useEffect(() => {
    if (doorStateModal.isOpen && deviceUUID) {
      tabbedDoorStateFetch(deviceUUID, deviceModel, i18n.language);
      setIsTabbedDoorStateLoading(true);
    }
  }, [doorStateModal.isOpen, deviceUUID]);

  useEffect(() => {
    if (!isTabbedDoorStateLoading) {
      setParams({
        model: deviceModel.replace(' ', ''),
        language: i18n.language,
        uuid: deviceUUID,
      });
    }
  }, [isTabbedDoorStateLoading]);

  useEffect(() => {
    if (connectedToWebSocket) {
      startDataStreaming(deviceUUID)
        .then((res) => {
          setSecondaryHeadingType(
            !res || failedToConnectToWebSocket ? 'warning' : 'nodata',
          );
        })
        .catch((_) => setSecondaryHeadingType('warning'));
    }
  }, [connectedToWebSocket, failedToConnectToWebSocket]);

  useEffect(() => {
    if (
      lastMessage &&
      typeof lastMessage === 'string' &&
      lastMessage.toLowerCase() === 'session closed'
    ) {
      setSecondaryHeadingType('warning');
      cleanUpConnection();
    } else if (lastMessage && typeof lastMessage === 'object') {
      setSecondaryHeadingType('success');
      setTabbedDoorStateData(lastMessage, deviceModel);
    }
  }, [lastMessage]);

  function getTabSkeletonsHtml() {
    const tabs = ['all', 'devices', 'operations', 'status'];
    const tabsHtml = tabs.map((tab) => {
      return (
        <DoorStateTab
          label={<Skeleton height="15px" width="12ch" />}
          to={tab}
          key={tab}
          currentActiveTab={currentActiveTab}
          onClick={() => setCurrentActiveTab(tab)}
        />
      );
    });
    return tabsHtml;
  }

  function getTabsHtml() {
    let tabs: { id: string; label: string }[] = [];
    if (Object.keys(tabbedDoorStateData).length > 0) {
      tabs = [{ id: 'all', label: 'All' }];
      for (let i = 0; i < Object.keys(tabbedDoorStateData).length; i++) {
        if (
          Object.keys(tabbedDoorStateData)[i] &&
          Object.values(tabbedDoorStateData)[i]?.value
        ) {
          tabs.push({
            id: Object.keys(tabbedDoorStateData)[i],
            label:
              Object.values(tabbedDoorStateData)[i]?.label ??
              Object.keys(tabbedDoorStateData)[i],
          });
        }
      }
    }

    const tabsHtml = tabs.map((tab) => {
      return (
        <DoorStateTab
          label={tab.label}
          to={tab.id}
          key={tab.id}
          currentActiveTab={currentActiveTab}
          onClick={() => setCurrentActiveTab(tab.id)}
        />
      );
    });

    return tabsHtml;
  }

  function getTabContentSkeletons() {
    const lineItemSkeletons = Array.from(Array(4).keys()).map((index) => {
      return (
        <TabContentLineItem
          doorStateKey={
            <Skeleton height="15px" width={isMobile ? '8ch' : '12ch'} />
          }
          doorStateValue={<Skeleton height="15px" width="8ch" />}
          key={index}
        />
      );
    });

    return <DoorStateTabContent>{lineItemSkeletons}</DoorStateTabContent>;
  }

  function getTabContentHtml(currentActiveTab: string) {
    let tabContentData: DoorStateLineItem[] = [];

    if (Object.keys(tabbedDoorStateData).length > 0) {
      tabContentData = [];
      if (currentActiveTab === 'all') {
        tabContentData = getAllTabContentData(tabbedDoorStateData);
      } else {
        tabContentData = getTabContentData(
          tabbedDoorStateData,
          currentActiveTab,
        );
      }
    }

    const tabContentHtml = tabContentData.map((tabContentLineItem, index) => {
      if (tabContentLineItem.isHeading) {
        return (
          <TabContentHeading key={`TabContentHeading-${index}`}>
            {tabContentLineItem.doorStateKey}
          </TabContentHeading>
        );
      }
      return (
        <TabContentLineItem
          key={`TabContentLineItem-${index}`}
          doorStateKey={tabContentLineItem.doorStateKey}
          doorStateValue={tabContentLineItem.doorStateValue}
        />
      );
    });

    return <DoorStateTabContent>{tabContentHtml}</DoorStateTabContent>;
  }

  const closeAndClearModal = () => {
    setIsTabbedDoorStateLoading(true);
    setSecondaryHeadingType(undefined);
    doorStateModal.toggleModal();
    stopDataStreaming(deviceUUID);
    cleanUpConnection();
  };

  return (
    <Modal
      isOpen={doorStateModal.isOpen}
      heading={t('doorDetails:modals.current_state.title')}
      toggleModal={closeAndClearModal}
      shouldPadContent={false}
      minWidth={940}
      stickToTop={isMobile}
      secondaryHeading={
        secondaryHeadingType === 'success' ? (
          <SuccessHeading>
            <CheckmarkCircle />
            {t('reports:liveData')}
          </SuccessHeading>
        ) : secondaryHeadingType === 'warning' ? (
          <WarningHeading>
            <AlertCircle />
            {t('reports:refreshLiveData')}
          </WarningHeading>
        ) : secondaryHeadingType === 'nodata' ? (
          <WarningHeading>{t('reports:noLiveData')}</WarningHeading>
        ) : (
          <></>
        )
      }>
      {!isTabbedDoorStateLoading && tabbedDoorStateError && (
        <ErrorMessage>Error: {tabbedDoorStateError}</ErrorMessage>
      )}
      <div
        style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
        <div
          style={{
            flex: isMobile ? '' : '1 2 0%',
            flexShrink: isMobile ? 0 : 'unset',
            display: isMobile ? 'flex' : 'unset',
            height: isMobile ? '30px' : 'auto',
          }}>
          {isTabbedDoorStateLoading ? (
            <>{getTabSkeletonsHtml()}</>
          ) : (
            <>{getTabsHtml()}</>
          )}
        </div>
        <TabContent>
          {isTabbedDoorStateLoading
            ? getTabContentSkeletons()
            : getTabContentHtml(currentActiveTab)}
        </TabContent>
      </div>
    </Modal>
  );
};

function getAllTabContentData(tabbedDoorStateData: KeyValue) {
  let allTabContentData: DoorStateLineItem[] = [];
  for (let i = 0; i < Object.keys(tabbedDoorStateData).length; i++) {
    const partOfTabContentData =
      tabbedDoorStateData[Object.keys(tabbedDoorStateData)[i]];
    if (partOfTabContentData && partOfTabContentData.label) {
      allTabContentData = [
        ...allTabContentData,
        {
          doorStateKey: partOfTabContentData.label,
          isHeading: true,
        },
      ];
    }

    if (partOfTabContentData && partOfTabContentData.value) {
      const lineItemParent = partOfTabContentData.value;
      for (let j = 0; j < Object.keys(lineItemParent).length; j++) {
        const lineItem: { label: string; value: string } = Object.values(
          lineItemParent,
        )[j];

        allTabContentData = [
          ...allTabContentData,
          {
            doorStateKey: convertES2020(lineItem.label),
            doorStateValue: convertES2020(lineItem.value),
          },
        ];
      }
    }
  }

  return allTabContentData;
}

function getTabContentData(
  tabbedDoorStateData: KeyValue,
  currentActiveTab: string,
) {
  let currentTabContentData: DoorStateLineItem[] = [];
  const currentActiveTabData = tabbedDoorStateData[currentActiveTab];

  if (currentActiveTabData && currentActiveTabData.value) {
    for (let i = 0; i < Object.keys(currentActiveTabData.value).length; i++) {
      const lineItem: { label: string; value: string } = Object.values(
        currentActiveTabData.value,
      )[i];

      currentTabContentData = [
        ...currentTabContentData,
        {
          doorStateKey: convertES2020(lineItem.label),
          doorStateValue: convertES2020(lineItem.value),
        },
      ];
    }
  }

  return currentTabContentData;
}

const convertES2020 = (label: string) => {
  if (label.toLowerCase() === 'es2020') return 'ES Proline';
  return label;
};

export default DoorStateModal;
