import React from 'react';
import {
  Bar,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';

import { Flex } from 'components';
import { useScreenSize } from 'hooks';

import StackedBarChartLegend from './StackedBarChartLegend/StackedBarChartLegend';
import StackedBarChartTooltip from './StackedBarChartTooltip/StackedBarChartTooltip';
import StackedBarChartSkeleton from './Skeleton/Skeleton';

import { theme } from 'style';

// specify names of stacked bars and their colors
interface DataShape {
  name: string;
  color: string;
}

interface StackedBarChartProps {
  isLoading: boolean;
  dataShape: DataShape[]; // describe properties of bars, will be displayed as legends
  data?: any; // actual data according to dataShape
  dataKey: string;
}

const StackedBarChart = ({
  isLoading,
  dataShape,
  data,
  dataKey,
}: StackedBarChartProps) => {
  const { screenSize } = useScreenSize();
  const { isMobile, isTablet } = screenSize();

  const verticalTicks = {
    fontSize: isMobile ? '12px' : '14px',
    color: theme.colors.charcoal,
    angle: 90,
    textAnchor: 'start',
    dominantBaseline: 'ideographic',
  };

  const horizontalTicks = {
    fontSize: isMobile ? '12px' : '14px',
    color: theme.colors.charcoal,
  };

  return (
    <ResponsiveContainer height={isMobile ? 280 : 320} width="100%">
      {isLoading ? (
        <Flex alignItems="center" justifyContent="center">
          <StackedBarChartSkeleton />
        </Flex>
      ) : (
        <BarChart data={data}>
          <CartesianGrid vertical={false} stroke={theme.colors.axisGrey} />
          <XAxis
            dataKey={dataKey}
            axisLine={false}
            tickLine={false}
            padding={{ left: isMobile ? 10 : 20, right: isMobile ? 10 : 20 }}
            tickSize={8}
            interval={0}
            tick={
              (isTablet || isMobile) && data?.length > 4
                ? verticalTicks
                : horizontalTicks
            }
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            width={40}
            tick={{
              fontSize: isMobile ? '12px' : '14px',
              color: theme.colors.charcoal,
            }}
          />
          {dataShape?.map((entry) => (
            <Bar
              key={entry.name}
              name={entry.name}
              dataKey={entry.name}
              barSize={90}
              fill={entry.color}
              stackId="a" // same id so they can stack on top of each other
            />
          ))}
          <Tooltip cursor={false} content={<StackedBarChartTooltip />} />
          <Legend
            wrapperStyle={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: (isTablet || isMobile) && data?.length > 4 ? 30 : 0,
            }}
            content={<StackedBarChartLegend />}
          />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default StackedBarChart;
