import styled from '@emotion/styled';
import { ThemeType } from 'style';

type ContainerProps = {
  theme: ThemeType;
};

export const Container = styled.div<ContainerProps>`
  padding: 15px 5px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightBlueGrey};
  cursor: pointer;
`;

export const ContainerNoBorder = styled.div<ContainerProps>`
  padding: 15px 5px;
  width: 100%;
`;

type ColorProps = {
  color: string;
  theme: any;
  isLoading?: boolean;
};

export const MobileFlare = styled.div<ColorProps>`
  height: 16px;
  width: 16px;
  border-radius: 2px;
  margin-right: 10px;
  background-color: ${({ color }) => color};
`;
export const GeneralHeading = styled.text`
  font-size: 12px;
  background-color: #ebf2ff;
  border-radius: 8px;
  padding: 4px 12px;
  align-items: center;
  font-weight: 500;
  margin-left: 12px;
`;

export const CriticalHeading = styled.text`
  color: #e3002a !important;
  font-size: 12px;
  background-color: #ffe9ed;
  border-radius: 8px;
  padding: 4px 12px;
  align-items: center;
  font-weight: 500;
  margin-left: 12px;
`;
