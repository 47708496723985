import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertToLocale } from 'utils';

import SummaryItem from './SummaryItem';
import { Container, MobileFlare } from './summaryStyle';
import icon from './totalCyclesIcon.svg';

interface SummaryProps {
  cycleCount: number;
  cycleCountSinceConnection: number;
  cycleCountAverage: number;
  timeframe: string;
  isToday: boolean;
  isThisWeek: boolean;
  isThisMonth: boolean;
}

const Summary = ({
  cycleCount,
  cycleCountAverage,
  cycleCountSinceConnection,
  timeframe,
  isToday,
  isThisWeek,
  isThisMonth,
}: SummaryProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      {/* average cycles */}
      {timeframe === 'Single day' && typeof cycleCountAverage === 'number' && (
        <SummaryItem
          icon={<MobileFlare />}
          title={t('doorsCycles:charts.summary.single_day.hourly')}
          description={
            isToday
              ? t('doorsCycles:charts.summary.today.hourly_description')
              : t(
                  'doorsCycles:charts.summary.selected_timeframe.hourly_description',
                )
          }
          count={convertToLocale(Math.round(cycleCountAverage))}
        />
      )}

      {timeframe === 'Multiple days' &&
        typeof cycleCountAverage === 'number' && (
          <SummaryItem
            icon={<MobileFlare />}
            title={t('doorsCycles:charts.summary.multiple_days.daily')}
            description={
              isThisWeek
                ? t('doorsCycles:charts.summary.this_week.daily_description')
                : isThisMonth
                ? t('doorsCycles:charts.summary.this_month.daily_description')
                : t(
                    'doorsCycles:charts.summary.selected_timeframe.daily_description',
                  )
            }
            count={convertToLocale(Math.round(cycleCountAverage))}
          />
        )}

      {timeframe === 'Multiple weeks' &&
        typeof cycleCountAverage === 'number' && (
          <SummaryItem
            icon={<MobileFlare />}
            title={t('doorsCycles:charts.summary.multiple_weeks.weekly')}
            description={t(
              'doorsCycles:charts.summary.selected_timeframe.weekly_description',
            )}
            count={convertToLocale(Math.round(cycleCountAverage))}
          />
        )}

      {/* total cycles */}
      {timeframe === 'Single day' && typeof cycleCount === 'number' && (
        <SummaryItem
          icon={<img src={icon} style={{ marginRight: '5px' }} />}
          title={t('doorsCycles:charts.tooltip.total_cycles')}
          description={
            isToday
              ? t('doorsCycles:charts.summary.today.total_description')
              : t(
                  'doorsCycles:charts.summary.selected_timeframe.total_description',
                )
          }
          count={convertToLocale(cycleCount)}
        />
      )}

      {timeframe === 'Multiple days' && typeof cycleCount === 'number' && (
        <SummaryItem
          icon={<img src={icon} style={{ marginRight: '5px' }} />}
          title={t('doorsCycles:charts.tooltip.total_cycles')}
          description={
            isThisWeek
              ? t('doorsCycles:charts.summary.this_week.total_description')
              : isThisMonth
              ? t('doorsCycles:charts.summary.this_month.total_description')
              : t(
                  'doorsCycles:charts.summary.selected_timeframe.total_description',
                )
          }
          count={convertToLocale(cycleCount)}
        />
      )}

      {timeframe === 'Multiple weeks' && typeof cycleCount === 'number' && (
        <SummaryItem
          icon={<img src={icon} style={{ marginRight: '5px' }} />}
          title={t('doorsCycles:charts.tooltip.total_cycles')}
          description={t(
            'doorsCycles:charts.summary.selected_timeframe.total_description',
          )}
          count={convertToLocale(cycleCount)}
        />
      )}

      {/* total cycles (life-time) */}
      {typeof cycleCountSinceConnection === 'number' && (
        <SummaryItem
          title={t('doorsCycles:charts.tooltip.total_life_time')}
          description={t(
            'doorsCycles:charts.tooltip.total_life_time_description',
          )}
          count={convertToLocale(cycleCountSinceConnection)}
        />
      )}
    </Container>
  );
};

export default Summary;
