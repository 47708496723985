import styled from '@emotion/styled';
import { theme, flexCenterAll, mq } from 'style';

interface FilterProps {
  isExpanded: boolean;
  hasActiveFilters?: boolean;
}
export const Filters = styled.div<FilterProps>`
  z-index: 1;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${(props) =>
    props.isExpanded ? theme.colors.lightGreyBlue : theme.colors.white};
  ${flexCenterAll};
  border: 1px solid
    ${(props) =>
      props.isExpanded
        ? theme.colors.lightGreyBlue
        : theme.colors.lightBlueGrey};
  ${mq.mobile()} {
    border: none;
    padding: 16px;
    height: 50px;
    box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.05);
    background-color: ${(props) =>
      props.hasActiveFilters ? theme.colors.primary : theme.colors.white};
  }
`;

export const ActiveCount = styled.span`
  background-color: ${theme.colors.primary};
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 12px;
  color: white;
  ${flexCenterAll};
  padding-left: 1px;
`;
