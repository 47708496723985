import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import NotificationLink from './NotificationLink/NotificationLink';
import { Button } from 'components';
import { followDoor, unfollowDoor } from 'services';
import { useNotificationPreferenceStore } from 'stores';
import { useScreenSize } from 'hooks';
import { DoorDetails } from 'models';

interface Props {
  doorDetailsData: DoorDetails;
  isEditDetails: boolean;
  isLoading: boolean;
}

const FollowDoor = ({ doorDetailsData, isEditDetails, isLoading }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize().screenSize();
  const [isFollowingLoading, setIsFollowingLoading] = useState<boolean>(false);
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const {
    notificationPreferenceFetch,
    notificationPreferenceData,
    notificationPreference,
  } = useNotificationPreferenceStore();

  const dismissNotificationPreference = async () => {
    setShowNotification(false);
  };

  useEffect(() => {
    if (!isLoading) {
      setIsFollowing(doorDetailsData.isFollowing);
    }
  }, [doorDetailsData, isLoading]);

  useEffect(() => {
    notificationPreferenceFetch();
  }, []);

  const toggleIsFollowing = async () => {
    const { isDoorDismissed } = notificationPreferenceData;
    setIsFollowingLoading(true);

    if (isDoorDismissed === false && showNotification === false) {
      notificationPreference({
        ...notificationPreferenceData,
        isDoorDismissed: true,
      });
      setShowNotification(true);
    }
    !isFollowing
      ? await followDoor(doorDetailsData.icNumber)
      : await unfollowDoor(doorDetailsData.icNumber);

    setIsFollowingLoading(false);
    setIsFollowing(!isFollowing);
  };

  return (
    <NotificationLink
      showNotification={showNotification}
      dismiss={() => dismissNotificationPreference()}>
      <Button
        stretch
        variant={
          !isFollowing || isFollowingLoading
            ? isMobile
              ? 'outlined'
              : 'whiteoutlined'
            : 'contained'
        }
        style={{
          paddingLeft: isMobile ? '15px' : '',
          paddingRight: isMobile ? '15px' : '',
        }}
        inverse={!isMobile}
        iconSize={20}
        data-testid="follow-door-toggle-btn"
        icon={isFollowing ? 'favourite-filled' : 'favourite'}
        disabled={isEditDetails}
        isLoading={isLoading || isFollowingLoading}
        onClick={toggleIsFollowing}>
        {isFollowing
          ? `${t('doorDetails:page_header.buttons.favourited')}`
          : `${t('doorDetails:page_header.buttons.favourite')}`}
      </Button>
    </NotificationLink>
  );
};

export default FollowDoor;
