import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTable } from 'react-table';
import { useTranslation } from 'react-i18next';

import { Status, FollowToggle } from './CellComponents';

import { Table, Grid } from 'components';
import { SpacesList } from 'models';
import { formatLastUpdatedDate, displaySpaceTypeTranslation } from 'utils';
import { useScreenSize } from 'hooks';

import SpaceListItem from './SpaceListItem/SpaceListItem';

interface Props {
  data: SpacesList[];
  sortBy: (
    sortBy: 'exceeded' | 'approaching' | 'within' | 'offline' | 'name',
  ) => void;
}

const SpacesTable = ({ data, sortBy }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'spacesList:table.column.status',
            accessor: 'status.percent',
            sortable: true,
            filterable: true,
            Cell: (props: any) => <Status space={props.row.original} />,
          },
          {
            Header: 'spacesList:table.column.name',
            accessor: 'summary.spaceDisplayName',
            sortable: true,
            filterable: true,
          },
          {
            Header: 'spacesList:table.column.type',
            accessor: 'summary.spaceType',
            sortable: true,
            filterable: true,
            Cell: ({ value }) => displaySpaceTypeTranslation(value),
          },

          {
            Header: 'spacesList:table.column.last_sync',
            accessor: 'status.updated',
            sortable: true,
            filterable: true,
            Cell: (props: any) => {
              return formatLastUpdatedDate(props.cell.value);
            },
          },
          {
            Header: 'spacesList:table.column.account',
            accessor: 'summary.account.name',
            sortable: true,
            filterable: true,
          },
          {
            Header: '',
            accessor: 'summary.isFollowing',
            sortable: false,
            filterable: false,
            Cell: (props: any) => (
              <FollowToggle
                isFollowing={props.cell.value}
                id={props.row.original.summary.spaceId}
              />
            ),
          },
        ],
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const navigateOnRowClick = (row: SpacesList) => {
    history.push({
      pathname: `/spaces/${row.summary.spaceId}`,
      state: {
        fromPage: history.location.pathname,
        id: row.summary.spaceId,
        name: row.summary.spaceDisplayName,
      },
    });
  };

  const { isMobile } = useScreenSize().screenSize();
  if (isMobile) {
    return (
      <Grid gridAutoFlow="row" gridRowGap="15px">
        {rows.map((row: any, index: number) => (
          <SpaceListItem
            key={index}
            row={row}
            navigateOnRowClick={navigateOnRowClick}
          />
        ))}
      </Grid>
    );
  }

  return (
    <Table
      columns="170px 3fr 1fr 2fr 2fr 100px 60px"
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      rows={rows}
      prepareRow={prepareRow}
      navigateOnRowClick={navigateOnRowClick}
      onRowClick={navigateOnRowClick}
      sortBy={sortBy}
    />
  );
};

export default SpacesTable;
