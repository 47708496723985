import React from 'react';
import moment from 'moment';
import { TooltipPayload } from 'recharts';
import snakeCase from 'lodash/snakeCase';
import upperFirst from 'lodash/upperFirst';

import { Box, Paragraph, StatusIndicator } from 'components';
import { useDailyTrafficStore } from 'stores';

export const Tooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: TooltipPayload[];
}) => {
  const { activeDevice, dailyTrafficData } = useDailyTrafficStore();

  if (active && payload) {
    if (!activeDevice) {
      return (
        <Box p={15} square>
          <Paragraph size="small" color="blueGrey">
            {moment
              .unix(payload[0].payload.from)
              .format('ddd DD MMM YYYY, HH:mm')}
          </Paragraph>
          {payload.map((payload) => (
            <Paragraph size="small" key={payload.name}>
              {payload.name}:{' '}
              <span style={{ fontWeight: 'bold' }}>{payload.value}</span>
            </Paragraph>
          ))}
        </Box>
      );
    } else {
      return (
        <Box p={15} square>
          <Paragraph size="small" bold>
            {
              //@ts-ignore
              dailyTrafficData?.devices?.find(
                (device) => device.id === activeDevice,
              ).deviceName
            }
          </Paragraph>
          <Paragraph size="small" color="blueGrey">
            {moment
              .unix(payload[0].payload.from)
              .format('ddd DD MMM YYYY, HH:mm')}
          </Paragraph>
          {payload.map((payload) => (
            <StatusIndicator
              key={payload.name}
              indicatorColor={payload.color!}
              label={
                <Paragraph size="small">
                  {upperFirst(snakeCase(payload.name).split('_')[0])}:{' '}
                  <span style={{ fontWeight: 'bold' }}>{payload.value}</span>
                </Paragraph>
              }
            />
          ))}
        </Box>
      );
    }
  } else {
    return null;
  }
};

export default Tooltip;
