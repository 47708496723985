import React from 'react';
import {
  ResponsiveContainer as RechartsResponsiveContainer,
  CartesianGrid as RechartsCartesianGrid,
  XAxis as RechartsXAxis,
  YAxis as RechartsYAxis,
} from 'recharts';
import moment from 'moment';
import { theme } from 'style';

const xTickFormatter = (time: string) => moment(time).format('h');

const CartesianGrid = () => (
  <RechartsCartesianGrid stroke="#D7E1F1" vertical={false} />
);

//not super sure if these components can actually be reused. Felt cute, might delete soon tho

const TimeXAxis = (dataKey: string) => (
  <RechartsXAxis
    dataKey={dataKey}
    xAxisId={0}
    tick={{
      fontSize: '14px',
      lineHeight: '21px',
      fill: theme.colors.charcoal,
    }}
    axisLine={false}
    tickLine={false}
    tickMargin={19}
    // ticks={Array.apply(0, Array(24)).map((el, i) =>
    //   moment()
    //     .startOf('day')
    //     .add(i, 'hours')
    //     .unix(),
    // )}
    tickFormatter={xTickFormatter}
  />
);

const PeriodXAxis = (dataKey: string) => (
  <RechartsXAxis
    dataKey={dataKey}
    xAxisId={1}
    tick={{
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'bold',
      textAlign: 'left',
      fill: theme.colors.charcoal,
    }}
    tickMargin={10}
    tickFormatter={(time) => moment(time).format('A')}
    axisLine={false}
    tickLine={false}
    interval={11}
  />
);

const YAxis = () => (
  <RechartsYAxis
    axisLine={false}
    // domain={[0, 3]}
    allowDecimals={false}
    tickMargin={10}
    // tickFormatter={yTickFormatter}
    tick={{
      fontSize: '14px',
      fill: theme.colors.charcoal,
    }}
    tickLine={false}
  />
);

const ChartComponents = {
  CartesianGrid,
  TimeXAxis,
  PeriodXAxis,
  YAxis,
};

export default ChartComponents;
