import React, { useState } from 'react';

import { SlideToggle } from 'components';
import { SlideToggleItem } from 'components/SlideToggle/SlideToggle';
import { useScreenSize } from 'hooks';

import listInactive from './list_inactive.svg';
import listActive from './list_active.svg';
import mapInactive from './map_inactive.svg';
import mapActive from './map_active.svg';
import { StyledMobileToggle } from './listMapToggleStyle';

interface Props {
  setView: (option: 'List' | 'Map') => void;
}

const ListMapToggle = ({ setView }: Props) => {
  const [optionIndex, setOptionIndex] = useState<number>(0);
  const { isMobile } = useScreenSize().screenSize();

  // css used to change color of svg into charcoal
  const svgFilter = {
    filter:
      'brightness(0) saturate(100%) invert(23%) sepia(5%) saturate(13%) hue-rotate(349deg) brightness(92%) contrast(84%)',
  };

  const options: SlideToggleItem[] = [
    {
      icon: isMobile ? (
        <img src={mapInactive} style={svgFilter} />
      ) : (
        <img
          src={optionIndex === 0 ? listActive : listInactive}
          alt="listIcon"
        />
      ),
      callback: () => {
        setOptionIndex(0);
        setView('List');
      },
    },
    {
      icon: isMobile ? (
        <img src={listInactive} style={svgFilter} />
      ) : (
        <img src={optionIndex === 0 ? mapInactive : mapActive} alt="mapIcon" />
      ),
      callback: () => {
        setOptionIndex(1);
        setView('Map');
      },
    },
  ];

  const handleMobileToggleClick = () => {
    if (optionIndex === 0) {
      setOptionIndex(1);
      setView('Map');
    } else {
      setOptionIndex(0);
      setView('List');
    }
  };

  if (isMobile) {
    return (
      <StyledMobileToggle onClick={handleMobileToggleClick}>
        {options[optionIndex].icon}
      </StyledMobileToggle>
    );
  }

  return <SlideToggle indexOverride={optionIndex} items={options} />;
};

export default ListMapToggle;
