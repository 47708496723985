import { mapStyles } from './mapStyles';

export const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

export const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  scrollwheel: false,
  disableDoubleClickZoom: true,
  draggable: false,
};
