import React from 'react';

import { Paragraph as P } from 'components';

import { BasePill } from './pillStyle';

export interface PillProps {
  text?: string;
  textColor?: string;
  backgroundColor?: string;
}

const Pill: React.FC<PillProps> = ({ text, textColor, backgroundColor }) => {
  return (
    <BasePill backgroundColor={backgroundColor}>
      <P size="tiny" color={textColor} fontWeight={600}>
        {text}
      </P>
    </BasePill>
  );
};

export default Pill;
