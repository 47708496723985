import create from 'zustand';
import { WebsocketEvent } from 'models';

type WebsocketMessageStore = {
  lastMessage: WebsocketEvent | null;
  setLastMessage: (data: WebsocketEvent) => void;
};

const [useWebSocketMessageStore] = create<WebsocketMessageStore>((set) => ({
  lastMessage: null,
  setLastMessage: (data: WebsocketEvent) => {
    set({ lastMessage: data });
  },
}));

export default useWebSocketMessageStore;
