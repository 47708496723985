import styled from '@emotion/styled';
import { theme } from 'style';

export const StyledDiv = styled.div`
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translateY(-50%);
  outline: 1px solid black;
  background-color: white;
  text-align: center;

  ul,
  li {
    list-style: none;
    padding: 5px;
    margin: 0;
    cursor: pointer;
  }
  li {
    &:hover {
      font-weight: bold;
    }
  }
  p {
    padding: 5px;
  }
`;
