import create from "zustand";

import {
  fetchSpaceDetails,
  fetchSpaceHistoricOccupancy,
  fetchSpaceOccupancyEvents,
} from "services";
import {
  SpaceDetails,
  SpaceDailyOccupancyStatus,
  SpaceHistoricOccupancy,
} from "models";
import { formatStartAndEnd } from "utils";

interface SpaceData {
  details: SpaceDetails | undefined;
  daily: SpaceDailyOccupancyStatus | undefined;
  historic: SpaceHistoricOccupancy | undefined;
}

interface IsLoading {
  details: boolean;
  daily: boolean;
  historic: boolean;
}
interface SpaceDataStore {
  spaceId: string | null;
  setSpaceId: (id: string) => void;
  spaceData: SpaceData;
  isLoading: IsLoading;
  spaceDetailsFetch: (id: string) => void;
  dailyOccupancyFetch: () => void;
  historicOccupancyFetch: (obj: any) => void;
  spaceDataDefaultFetch: () => void;
  cleanUpSpaceDetailsData: () => void;
}

const [useSpaceDataStore] = create<SpaceDataStore>((set, get) => ({
  spaceId: null,
  setSpaceId: (id: string | null) => {
    set({ spaceId: id });
  },
  spaceData: {} as SpaceData,
  isLoading: {
    details: true,
    daily: true,
    historic: true,
  },
  spaceDetailsFetch: async (id: string) => {
    const data = get().spaceData;
    try {
      const details = await fetchSpaceDetails(id);
      set({ spaceData: { ...data, details: await details } });
      set({ isLoading: { ...get().isLoading, details: false } });
    } catch (err) {
      throw console.error();
    }
  },
  dailyOccupancyFetch: async () => {
    set({ isLoading: { ...get().isLoading, daily: true } });
    const spaceId = get().spaceId || "";
    const data = get().spaceData;
    try {
      const daily = await fetchSpaceOccupancyEvents(spaceId);
      set({ spaceData: { ...data, daily: await daily } });
      set({ isLoading: { ...get().isLoading, daily: false } });
    } catch (err) {
      throw err;
    }
  },
  historicOccupancyFetch: async ({
    startDate,
    endDate,
  }: {
    startDate: any;
    endDate: any;
  }) => {
    set({ isLoading: { ...get().isLoading, historic: true } });
    const spaceId = get().spaceId;
    const data = get().spaceData;
    try {
      const historic = await fetchSpaceHistoricOccupancy({
        id: spaceId || "",
        startDate,
        endDate,
      });
      set({ spaceData: { ...data, historic: await historic } });
      set({ isLoading: { ...get().isLoading, historic: false } });
    } catch (err) {
      throw err;
    }
  },
  spaceDataDefaultFetch: async () => {
    const defaultDates = formatStartAndEnd("week");
    try {
      await get().spaceDetailsFetch(get().spaceId || "");
      await get().dailyOccupancyFetch();
      await get().historicOccupancyFetch(defaultDates);
    } catch (err) {
      throw err;
    }
  },
  cleanUpSpaceDetailsData: () => {
    set({
      spaceData: {} as SpaceData,
      isLoading: {
        details: true,
        daily: true,
        historic: true,
      },
    });
  },
}));

export default useSpaceDataStore;
