import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  ContentPopup,
  EllipsisDiv,
  StyledTooltip,
  Triangle,
} from './ellipsisWithTooltipStyle';
import ReactHtmlParser from 'react-html-parser';

// const ellipsisDefaultStyle = {
//   overflow: 'hidden',
//   overflowWrap: 'break-word',
//   textOverflow: 'ellipsis',
//   whiteSpace: 'nowrap',
//   wordBreak: 'break-all',
// };

interface Props {
  content: string;
  children: React.ReactNode;
}

const EllipisWithTooltip: React.FC<Props> = ({ content, children }) => {
  const WrapperRef = useRef(document.createElement('div'));
  const PopupRef = useRef(document.createElement('div'));

  const [isShown, setIsShown] = useState(false);
  const [popupHeight, setPopupHeight] = useState(0);

  const updateOverflow = (e: any) => {
    const elem = e.target;
    if (elem.scrollWidth > elem.clientWidth) {
      setHover(true);
    } else {
      setHover(false);
    }
  };

  const [hoverStatus, setHover] = useState(false);

  useEffect(() => {
    isShown &&
      PopupRef?.current?.clientHeight &&
      setPopupHeight(PopupRef.current.clientHeight);
  }, [isShown]);

  return (
    <StyledTooltip
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      ref={WrapperRef}
      data-testid="tooltip">
      <EllipsisDiv onMouseEnter={updateOverflow}>{children}</EllipsisDiv>
      {hoverStatus &&
        isShown &&
        ReactDOM.createPortal(
          <ContentPopup
            ref={PopupRef}
            height={popupHeight}
            position={WrapperRef.current.getBoundingClientRect()}
            data-testid="tooltip-content">
            {ReactHtmlParser(content)}
          </ContentPopup>,
          document.body,
        )}
    </StyledTooltip>
  );
};

export default EllipisWithTooltip;
