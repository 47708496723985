import React from 'react';
import emotionReset from 'emotion-reset';
import { Global, css } from '@emotion/core';

const GlobalStyle = () => {
  return (
    <Global
      styles={css`
        ${emotionReset}
        @import url("https://fonts.googleapis.com/css?family=Poppins:200,400,500,600, 700&display=swap");
        * {
          box-sizing: border-box;
        }
        html {
          font-family: 'Poppins';
          /* width: 100vw; */
        }
        a {
          color: inherit;
        }
        body {
          font-size: 16px;
          background-color: #f5f5f7;
          -webkit-overflow-scrolling: touch;
        }
        @media screen and (max-width: 767px) {
          input,
          select,
          textarea {
            font-size: 16px;
          }
        }
      `}
    />
  );
};

export default GlobalStyle;
