import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import i18n from 'core/i18n/i18n';

import {
  TextField,
  Button,
  Flex,
  Paragraph as P,
  SmallFormContainer,
  ResponseHandler,
} from 'components';
import { Spacer } from 'style';
import { PasswordResetRequest } from 'pages/Login/forms';
import EULA from './EULA';

export interface DefaultSettings {
  mainHeading: string;
  subHeading?: string;
}
export interface ResponseSettings extends DefaultSettings {
  actionLabel: string;
  action: () => void;
}

interface Props {
  showEmailInput?: string;
  formSettings: DefaultSettings;
  successSettings: ResponseSettings;
  errorSettings: ResponseSettings;
  onSubmit: any;
  formState: 'default' | 'successful' | 'error' | 'expired';
  setFormState: (state: 'default' | 'successful' | 'error' | 'expired') => void;
}

// Form Setup ===================================

export const validationSchema = Yup.object({
  password: Yup.string()
    // .matches(passwordRegex, i18n.t('form_validation.criteria'))
    .min(8, i18n.t('forms:validation.password_minimum'))
    .matches(
      new RegExp('(?=.*[a-z])'),
      i18n.t('forms:validation.password_lowercase'),
    )
    .matches(
      new RegExp('(?=.*[A-Z])'),
      i18n.t('forms:validation.password_uppercase'),
    )
    .matches(
      new RegExp('(?=.*[0-9])'),
      i18n.t('forms:validation.password_number'),
    )
    .matches(new RegExp(/[^\w\s]/), i18n.t('forms:validation.password_special'))
    .required(i18n.t('forms:validation.password_required')),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      i18n.t('forms:validation.password_match'),
    )
    .required(i18n.t('forms:validation.password_confirm')),
  eula: Yup.boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});
export type ValidationSchema = Yup.InferType<typeof validationSchema>;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const replaceWithPlus = (query: string) => {
  return query.replace(' ', '+');
};
export const Form = ({
  onSubmit,
  formSettings,
  successSettings,
  errorSettings,
  showEmailInput,
  formState,
  setFormState,
}: Props) => {
  const { t } = useTranslation();

  const query = useQuery();

  const initialValues: any = {
    username: replaceWithPlus(query.get('username') || ''),
    password: '',
    confirmPassword: '',
    eula: false,
  };

  const [validation, setValidation] = useState<any>({} as any);
  useEffect(() => {
    setValidation(
      Yup.object({
        password: Yup.string()
          // .matches(passwordRegex, i18n.t('form_validation.criteria'))
          .min(8, i18n.t('forms:validation.password_minimum'))
          .matches(
            new RegExp('(?=.*[a-z])'),
            i18n.t('forms:validation.password_lowercase'),
          )
          .matches(
            new RegExp('(?=.*[A-Z])'),
            i18n.t('forms:validation.password_uppercase'),
          )
          .matches(
            new RegExp('(?=.*[0-9])'),
            i18n.t('forms:validation.password_number'),
          )
          .matches(
            new RegExp(/[^\w\s]/),
            i18n.t('forms:validation.password_special'),
          )
          .required(i18n.t('forms:validation.password_required')),
        confirmPassword: Yup.string()
          .oneOf(
            [Yup.ref('password'), null],
            i18n.t('forms:validation.password_match'),
          )
          .required(i18n.t('forms:validation.password_confirm')),
        eula: Yup.boolean()
          .required('The terms and conditions must be accepted.')
          .oneOf([true], 'The terms and conditions must be accepted.'),
      }),
    );
  }, []);

  const RenderState = () => {
    switch (formState) {
      case 'default':
        return (
          <SmallFormContainer
            mainHeading={formSettings.mainHeading}
            subHeading={formSettings.subHeading}>
            <Formik
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                onSubmit(values, setSubmitting, setErrors)
                  .then((res: any) => {
                    console.log('pw set form: ', res);
                    if (res === 'successful') {
                      setFormState('successful');
                    }
                    if (res === 'error') {
                      setFormState('error');
                    }
                    if (res === 'expired') {
                      setFormState('expired');
                    }
                  })
                  .catch((err: any) => console.log(err));
              }}>
              {(props) => {
                return (
                  <FormikForm data-cypress="confirm-password-form">
                    <Flex
                      stretch
                      justifyContent="center"
                      flexDirection="column">
                      {showEmailInput && (
                        <TextField
                          name="username"
                          label={t('forms:email')}
                          type="email"
                          placeholder={t('forms:email')}
                          disabled
                        />
                      )}
                      <TextField
                        name="password"
                        label={t('loginActivate:set_password.password')}
                        type="password"
                        placeholder={t(
                          'loginActivate:set_password.placeholder.password',
                        )}
                        cypress="onboarding-password-input"
                      />

                      <TextField
                        name="confirmPassword"
                        label={t('loginActivate:set_password.confirm_password')}
                        type="password"
                        placeholder={t(
                          'loginActivate:set_password.placeholder.confirm_password',
                        )}
                        cypress="onboarding-password-confirm"
                      />

                      <P size="small" color="charcoal">
                        {t('loginActivate:set_password.password_requirements')}
                      </P>

                      <EULA />

                      <Spacer height={25} />
                      <Button
                        type="submit"
                        disabled={
                          !(props.isValid && props.dirty) || props.isSubmitting
                        }
                        isLoading={props.isSubmitting}
                        cypress="form-wrapper-submit">
                        {t('loginActivate:set_password.set_password')}
                      </Button>
                    </Flex>
                  </FormikForm>
                );
              }}
            </Formik>
          </SmallFormContainer>
        );
      case 'successful':
        return (
          <ResponseHandler
            cypress="confirm-password-success"
            mainHeading={successSettings.mainHeading}
            subHeading={successSettings.subHeading}
            status="success"
            action={successSettings.action}
            actionLabel={successSettings.actionLabel}
          />
        );
      case 'error':
        return (
          <ResponseHandler
            cypress="confirm-password-error"
            mainHeading={errorSettings.mainHeading}
            subHeading={errorSettings.subHeading}
            status="error"
            action={errorSettings.action}
            actionLabel={errorSettings.actionLabel}
          />
        );
      case 'expired':
        return (
          <SmallFormContainer
            mainHeading={t('cognitoErrors:ExpiredCode')}
            subHeading={t('cognitoErrors:ExpiredCodeContact')}
          />
        );
    }
  };

  return RenderState();
};
