import styled from '@emotion/styled';
import { DESKTOP_PADDING, MOBILE_PADDING } from 'components/Modal/modalStyle';
import { mq, theme } from 'style';

export const TabContent = styled.div`
  ${mq.wideDesktop()} {
    min-width: 670px;
    max-height: 70vh;
  }
  flex: 2 1 0%;
`;

export const SuccessHeading = styled.div`
  color: ${theme.colors.success};
  font-size: 12px;
  background-color: #e5fbd5;
  border-radius: 8px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
`;

export const WarningHeading = styled.div`
  color: ${theme.colors.error};
  font-size: 12px;
  background-color: #ffe9ed;
  border-radius: 8px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
`;

export const ErrorMessage = styled.div`
  ${DESKTOP_PADDING};
  ${mq.mobile()} {
    ${MOBILE_PADDING};
  }
  color: ${theme.colors.error};
  font-weight: 500;
`;
