import styled from '@emotion/styled';
import { flexCenterAll, mq } from 'style';

export const EmptyContainer = styled.div`
  min-height: 400px;
  padding: 10% 5px;
  width: 100%;
  ${flexCenterAll};
  ${mq.mobile()} {
    min-height: 300px;
  }
`;