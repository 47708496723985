import React from 'react';
import { useTranslation } from 'react-i18next';
import { WarningBox } from 'components';

const NoAlertTypeWarning = ({ isProfile = false }: { isProfile?: boolean }) => {
  const { t } = useTranslation();

  return (
    <WarningBox style={{ marginTop: '-20px', marginBottom: '20px', marginLeft: '-10px' }} variant="primaryWhite">
      {t('userOnboarding:warning.no_alert_type')}
    </WarningBox>
  );
};

export default NoAlertTypeWarning;

