import React from 'react';
import { LabelProps } from 'recharts';

import { theme } from 'style';

interface Props extends LabelProps {
  countValue: number;
  sublabel: string;
}
const DonutChartLabel = ({ countValue, sublabel, viewBox }: any) => {
  const { cx, cy } = viewBox;
  return (
    <>
      <text
        x={cx}
        y={cy - 5}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="bottom">
        <tspan
          alignmentBaseline="middle"
          fontSize="65px"
          fontFamily="Poppins"
          fontWeight="700"
          fill={theme.colors.primary}>
          {countValue}
        </tspan>
      </text>
      <text
        x={cx}
        y={cy + 65 / 2}
        fill="rgba(0, 0, 0, 0.54)"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="top">
        <tspan
          fontSize="16px"
          fontFamily="Poppins"
          fill={theme.colors.primary}
          fontWeight="700">
          {sublabel}
        </tspan>
      </text>
    </>
  );
};

export default DonutChartLabel;
