import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { FullScreenContainer, Spinner, BasicError } from 'components';

import { User } from 'models';
import { lastLogin } from 'services';
import { fetchAccount, fetchUserMeDetails } from 'services';
import { useWebsocketConnection } from 'hooks';
import { SignOut } from 'core/auth/auth';
import { setLanguage } from 'utils/setLanguage';
import usePageLoad from 'hooks/usePageLoad';
import { fetchDoorProfiles } from 'services/doorProfiles';
import doorProfilesStorage from 'utils/doorProfilesStorage';

interface Props {}

const addUserMeToSession = (user: User) => {
  window.localStorage.setItem('userMe', JSON.stringify(user));
};

const LoadingPage = ({}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setReadyToConnect } = useWebsocketConnection();

  const [accountError, setAccountError] = useState<boolean>(false);
  const query = new URLSearchParams(window.location.search);

  const { isFirstLoad, setPageHasLoaded } = usePageLoad();

  const reloadWindowIfFirstLoad = () => {
    if (isFirstLoad) {
      window.location.reload();
      setPageHasLoaded();
    }
  };

  const sessionSetup = async () => {
    try {
      const user = await fetchUserMeDetails();
      if (user) {
        const account = await fetchAccount(user.account.id);
        account
          ? addUserMeToSession({ ...user, account })
          : addUserMeToSession(user);
        i18n.changeLanguage(user.language);
        setLanguage(user.language);
        const doorProfiles = await fetchDoorProfiles(user);
        doorProfilesStorage.set(doorProfiles);
      }
      setReadyToConnect(true);
      if (query.get('onboarding')) {
        history.push('/onboarding');
      } else {
        history.push('/dashboard');
      }

      reloadWindowIfFirstLoad();
    } catch (err) {
      console.log(err);
      const { code, status } = err.response;
      console.log(status, code);

      if (err) {
        setAccountError(true);
      }
    }
  };

  useEffect(() => {
    sessionSetup();
    lastLogin();
  }, []);

  return (
    <FullScreenContainer hideContactLink>
      {accountError ? (
        <BasicError
          heading={t('common:account_level.heading')}
          subheading={t('common:account_level.subheading')}
          action={() => {
            SignOut();
            history.push('/login');
          }}
          actionLabel={t('common:account_level.action_label')}
        />
      ) : (
        <Spinner />
      )}
    </FullScreenContainer>
  );
};

export default LoadingPage;
