import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import i18n from 'core/i18n/i18n';

import {
  FormComponents,
  ResponseHandler,
  Modal,
  TextField,
  Paragraph as P,
} from 'components';
import { ChangePassword as ChangePasswordModel } from 'models';
import { useModalStore } from 'stores';
import { changePassword, errorTranslate } from 'core/auth/auth';
import { passwordValidation } from 'utils';

interface Props {}

export const Validation = Yup.object({
  oldPassword: Yup.string().required(i18n.t('forms:validation.old_password')),
  newPassword: passwordValidation,
  newPasswordConfirm: Yup.string()
    .oneOf(
      [Yup.ref('newPassword'), null],
      i18n.t('forms:validation.password_match'),
    )
    .required(i18n.t('forms:validation.password_confirm')),
});

export type ChangePasswordValidation = Yup.InferType<typeof Validation>;

export const initialValues: any = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
};

const ChangePasswordModal = ({}: Props) => {
  const { t } = useTranslation();

  const [passwordChangeState, setPasswordChangeState] = useState<
    'default' | 'successful' | 'error'
  >('default');

  const [errorFromService, setErrorFromService] = useState<string>('');
  const onSubmitChangePassword = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true);
    try {
      const changePasswordPayload: ChangePasswordModel = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      const res = await changePassword(changePasswordPayload);
      if (res) {
        setPasswordChangeState('successful');
      }
    } catch (err) {
      const { code } = err;

      setErrorFromService(code === 'NotAuthorizedException' ? t('profile:change_password_form:error.subheading') : errorTranslate(code));
      setPasswordChangeState('error');
    } finally {
      setSubmitting(false);
    }
  };

  const { changePasswordModal } = useModalStore();

  const toggleWrapper = () => {
    changePasswordModal.toggleModal();
    setPasswordChangeState('default');
  };

  const RenderState = () => {
    switch (passwordChangeState) {
      case 'default':
        return (
          <>
            <Formik
              validateOnMount={true}
              initialValues={initialValues}
              validationSchema={Validation}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitChangePassword(values, setSubmitting);
              }}>
              {(props) => {
                return (
                  <Form>
                    <FormComponents.Section layout="single" paddingSize="small">
                      <TextField
                        name="oldPassword"
                        label={t('forms:password_change.old_password')}
                        type="password"
                        placeholder={t('forms:password_change.old_password')}
                        cypress="onboarding-old-password"
                      />
                      <TextField
                        name="newPassword"
                        label={t('forms:password_change.new_password')}
                        type="password"
                        placeholder={t('forms:password_change.new_password')}
                        cypress="onboarding-new-password"
                      />
                      <TextField
                        name="newPasswordConfirm"
                        label={t('forms:password_change.new_password_confirm')}
                        type="password"
                        placeholder={t(
                          'forms:password_change.new_password_confirm',
                        )}
                        cypress="onboarding-new-password-confirm"
                      />
                      <P size="small" color="charcoal" style={{ width: 420 }}>
                        {t('loginActivate:set_password.password_requirements')}
                      </P>
                    </FormComponents.Section>
                    <FormComponents.Submit
                      paddingSize="small"
                      label={t('profile:buttons.save_changes')}
                      cancelAction={toggleWrapper}
                      {...props}
                    />
                  </Form>
                );
              }}
            </Formik>
          </>
        );
      case 'successful':
        return (
          <ResponseHandler
            cypress="confirm-password-success"
            mainHeading={t('profile:change_password_form.success.heading')}
            status="success"
            action={toggleWrapper}
            actionLabel={t('profile:change_password_form.success.action_label')}
          />
        );
      case 'error':
        return (
          <ResponseHandler
            cypress="confirm-password-error"
            mainHeading={t('profile:change_password_form.error.heading')}
            subHeading={errorFromService}
            status="error"
            action={() => {
              toggleWrapper();
            }}
            actionLabel={t('profile:change_password_form.error.action_label')}
          />
        );
    }
  };

  return (
    <Modal
      heading={t('profile:change_password_form.title')}
      naked
      isOpen={changePasswordModal.isOpen}
      toggleModal={toggleWrapper}>
      {RenderState()}
    </Modal>
  );
};

export default ChangePasswordModal;
