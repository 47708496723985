/**
 * Default media breakpoints
 * @type {Object}
 */
export const defaultBreakpoints = {
  xhuge: '1800px',
  huge: '1440px',
  extralargeDown: '1299px',
  extralarge: '1300px',
  largeDown: '1169px',
  large: '1170px',
  medium: '768px',
  small: '450px',
  largeTablet: '920px',
};

export type Breakpoints = keyof typeof defaultBreakpoints;

export const mq = {
  lessThan: (breakpoint: Breakpoints) =>
    `@media (max-width: ${defaultBreakpoints[breakpoint]})`,
  greaterThan: (breakpoint: Breakpoints) =>
    `@media (min-width: ${defaultBreakpoints[breakpoint]})`,
  between: (first: Breakpoints, second: Breakpoints) =>
    `@media (min-width: ${defaultBreakpoints[first]}) and (max-width: ${defaultBreakpoints[second]})}) `,
  mobile: () => `@media (max-width: ${defaultBreakpoints.medium})`,
  smallMobile: () => `@media (max-width: ${defaultBreakpoints.small})`,
  largeDown: () => `@media (max-width: ${defaultBreakpoints.largeDown})`,
  wideDesktop: () => `@media (min-width: ${defaultBreakpoints.large})`,
  huge: () => `@media (min-width: ${defaultBreakpoints.huge})`,
  xhuge: () => `@media (min-width: ${defaultBreakpoints.xhuge})`,
  tablet: () => `@media (min-width: 768px) and (max-width: 919px)`,
  largeTablet: () => `@media (min-width: ${defaultBreakpoints.largeTablet})`,
  desktop: () => `@media (min-width: 920px) and (max-width: 1024px)`,
  tabletLandscape: () =>
    `@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)`,
};
