import styled from '@emotion/styled';
import { theme, flexVertical, mq } from 'style';

export const Wrapper = styled.div`
  background-color: ${theme.colors.lightGreyBlue};
  padding: 0px 25px;
  border-radius: 4px;
  margin-bottom: 25px;

  > div {
    height: 60px;
    ${flexVertical};
    justify-content: space-between;
  }
`;

interface Show {
  show: boolean;
}
export const CopyNotification = styled.div<Show>`
  position: relative;

  > span {
    pointer-events: none;
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: opacity 0.5s linear;
    white-space: nowrap;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(0% - 30px);
    background-color: ${theme.colors.white};
    box-shadow: ${theme.boxShadow};
    border: ${theme.borders(1, 'lightBlueGrey')};
    border-radius: 4px;
    padding: 15px;
    color: ${theme.colors.charcoal};
    font-size: 12px;
  }
`;
