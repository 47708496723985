import create from 'zustand';

import {
  fetchSpaceHistoricOccupancy,
  fetchSpaceOccupancyEvents,
} from 'services';
import { SpaceDailyOccupancyStatus, SpaceHistoricOccupancy } from 'models';

interface SpaceOccupancy {
  daily: SpaceDailyOccupancyStatus | undefined;
  historic: SpaceHistoricOccupancy | undefined;
}
interface IsLoading {
  daily: boolean;
  historic: boolean;
}
interface SpaceOccupancyStore {
  spaceOccupancyData: SpaceOccupancy;
  isLoading: IsLoading;
  dailyOccupancyFetch: (id: string) => void;
  historicOccupancyFetch: (id: string, dates: any) => void;
}

const [useSpaceOccupancyStore] = create<SpaceOccupancyStore>((set, get) => ({
  spaceOccupancyData: {
    daily: {} as SpaceDailyOccupancyStatus,
    historic: {} as SpaceHistoricOccupancy,
  } as SpaceOccupancy,
  isLoading: {
    daily: true,
    historic: true,
  },
  dailyOccupancyFetch: async (id: string) => {
    set({ isLoading: { ...get().isLoading, daily: true } });
    try {
      const daily = await fetchSpaceOccupancyEvents(id);

      if (daily === 204) {
        set({
          spaceOccupancyData: { ...get().spaceOccupancyData, daily: undefined },
        });
      } else {
        set({
          spaceOccupancyData: { ...get().spaceOccupancyData, daily: daily },
        });
      }

      set({ isLoading: { ...get().isLoading, daily: false } });
    } catch (err) {
      throw err;
    }
  },
  historicOccupancyFetch: async (id: string, dates: any) => {
    set({ isLoading: { ...get().isLoading, historic: true } });
    const { startDate, endDate } = dates;
    try {
      const historic = await fetchSpaceHistoricOccupancy({
        id,
        startDate,
        endDate,
      });
      if (historic === 204) {
        set({
          spaceOccupancyData: {
            ...get().spaceOccupancyData,
            historic: undefined,
          },
        });
      } else {
        set({
          spaceOccupancyData: {
            ...get().spaceOccupancyData,
            historic: historic,
          },
        });
      }

      set({ isLoading: { ...get().isLoading, historic: false } });
    } catch (err) {
      throw err;
    }
  },
}));

export default useSpaceOccupancyStore;
