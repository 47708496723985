import styled from '@emotion/styled';

import { theme } from 'style/theme';
import { WarningBoxProps } from './WarningBox';

export const WarningBoxContainer = styled.div<WarningBoxProps>`
  display: flex;
  background: ${(props) => props.variant === 'primaryWhite' ? 'inherit' : theme.colors.pink};
  border: 1px solid ${(props) => props.variant === 'primaryWhite' ? 'inherit' : theme.colors.error};
  border-radius: 4px;
  max-width: 350px;
  padding: 10px;
`;

export const IconContainer = styled.div<WarningBoxProps>`
  padding-top:  ${(props) => props.variant === 'primaryWhite' ? 'initial' : '3px'};
`;
