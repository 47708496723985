import styled from '@emotion/styled';
import { flexVertical, theme } from 'style';

interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
}

export const TickIcon = styled.svg<CheckboxProps>`
  fill: none;
  stroke: white;
  stroke-width: 4px;
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
`;

export const StyledInput = styled.div<CheckboxProps>`
  display: inline-block;
  width: 18px !important;
  height: 18px;
  border-radius: 2px;
  transition: all 150ms;
  cursor: pointer;
  box-sizing: border-box;
  background: ${(props) => (props.checked ? theme.colors.primary : 'white')};
  border: ${(props) =>
    props.disabled
      ? `2px solid ${theme.colors.blueGrey}`
      : `2px solid ${theme.colors.primary}`};
`;

export const CheckboxWrapper = styled.label`
  position: relative;
  ${flexVertical}
`;
