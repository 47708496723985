import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  SectionHeader,
  SectionHeaderActions,
  SectionUuid,
} from './doorDetailsStyle';
import isEmpty from 'lodash/isEmpty';

import {
  PageHeader,
  Grid,
  BreadCrumbs,
  IcomoonIcons,
  Paragraph as P,
  Container,
  BasicError,
  Heading as H,
  Button,
  DoorStateModal,
} from 'components';
import { Spacer } from 'style';
import {
  useAlertStore,
  useDoorDetailsStore,
  useModalStore,
  useWebSocketMessageStore,
} from 'stores';
import { useScreenSize, useTopBar } from 'hooks';

import { SelectedDates, SelectedDateTypeEnum, User } from 'models';
import Status from './Status/Status';
import EditDoorDetails from './forms/EditDoorDetails';
import FollowDoor from './components/FollowDoor/FollowDoor';
import DoorMapDetails from './DoorMapDetails/DoorMapDetails';
import InsightsDoorDetails from './charts/Insights/InsightsDoorDetails';
import Alert from './components/Alert/Alert';
import { ExportReportModal } from './modals';
import ActionsListButton from './components/ActionsListButton/ActionsListButton';
import ViewCurrentStateModal from './modals/ViewCurrentStateModal/ViewCurrentStateModal';
import RequestRemoteOpenModal from './modals/RequestRemoteOpenModal/RequestRemoteOpenModal';
import { isDI, isDIS, isDualProducts } from 'utils';
import ResetIoTDeviceModal from './modals/ResetIoTDeviceModal/ResetIoTDeviceModal';
import ResetOperatorModal from './modals/ResetOperatorModal/ResetOperatorModal';
import ClearOperatorErrorLogModal from './modals/ClearOperatorErrorLogModal/ClearOperatorErrorLogModal';
import RemoteLockModal from './modals/RemoteLockModal/RemoteLockModal';
import RemoteUnlockModal from './modals/RemoteUnlockModal/RemoteUnlockModal';
import { RemoteFunction } from 'models/DoorRemoteFunction';

type UseParamsProps = {
  id: string;
};

const DoorDetails = () => {
  const history = useHistory();
  const { id: doorId } = useParams<UseParamsProps>();
  const { t } = useTranslation();
  const { setLeftAction } = useTopBar();

  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  const [userMe, setUserMe] = useState<User>({} as User);
  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  const [isEditDetails, setIsEditDetails] = useState(false);
  const toggleEditDetails = () => {
    setIsEditDetails(!isEditDetails);
    window.scrollTo(0, 0);
  };

  const {
    doorDetailsData,
    doorStatusData,
    isLoading,
    doorDetailsFetch,
    doorStatusFetch,
    doorDetailsError,
    availableFunctions,
    doorRemoteFunctionFetch,
    cleanUpDoorDetailsData,
  } = useDoorDetailsStore();

  const { exportDoorReportModal } = useModalStore();

  const { activeAlertData } = useAlertStore();

  const [isFollowing, setIsFollowing] = useState<boolean>(false);

  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [reloadRemoteFunctions, setReloadRemoteFunctions] = useState<boolean>(
    false,
  );
  const { lastMessage } = useWebSocketMessageStore();

  const query = new URLSearchParams(window.location.search);
  const [selectedDates, setSelectedDates] = useState<SelectedDates>({
    startDate: query.get('startDate')
      ? (query.get('startDate') || '').replace(' ', '+')
      : null,
    endDate: query.get('endDate')
      ? (query.get('endDate') || '').replace(' ', '+')
      : null,
  });

  const [selectedDateType, setSelectedDateType] = useState<
    SelectedDateTypeEnum
  >(
    query.get('dateType')
      ? (query.get('dateType') as SelectedDateTypeEnum)
      : 'day',
  );

  useEffect(() => {
    setLeftAction(
      <div onClick={() => history.goBack()}>
        <IcomoonIcons icon="chevron-left" color="white" />
      </div>,
    );

    return () => {
      setLeftAction(null);
    };
  }, []);

  useEffect(() => {
    if (lastMessage) {
      if (lastMessage.DoorUUID === doorDetailsData?.uuid) {
        setTriggerUpdate(!triggerUpdate);
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (!isLoading && doorDetailsData) {
      setIsFollowing(doorDetailsData.isFollowing);
    }

    if (doorDetailsData && doorDetailsData.type) {
      doorRemoteFunctionFetch(doorDetailsData.type, doorDetailsData.id);
    }
  }, [doorDetailsData, isLoading]);

  useEffect(() => {
    if (doorDetailsData && doorDetailsData.type) {
      setTimeout(() => {
        doorRemoteFunctionFetch(doorDetailsData.type, doorDetailsData.id);
        doorStatusFetch(doorDetailsData.id);
      }, 5000);
    }
  }, [reloadRemoteFunctions]);

  useEffect(() => {
    doorDetailsFetch(doorId);
    return () => cleanUpDoorDetailsData();
  }, [doorId]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      doorDetailsFetch(doorId);
    }, 5 * 60 * 1000);

    return () => {
      cleanUpDoorDetailsData();
      clearInterval(refreshInterval);
    };
  }, []);

  return doorDetailsError ? (
    <BasicError
      heading="Error 403: Unauthorized"
      subheading={t('doorDetails:errors.unauthorized')}
      action={() => history.push('/home')}
      actionLabel={t('doorDetails:errors.action_label')}
    />
  ) : (
    <>
      {!isMobile && (
        <BreadCrumbs>
          <Grid
            onClick={() => history.push('/doors')}
            gridTemplateColumns="repeat(2, min-content)"
            gridColumnGap="8px">
            <IcomoonIcons icon="chevron-left" color="white" />
            <P fontWeight="bold" color="white">
              {t('doorList:heading')}
            </P>
          </Grid>
          <P color="white">{doorDetailsData?.name}</P>
        </BreadCrumbs>
      )}
      <PageHeader
        heading={isLoading ? '' : doorDetailsData?.name}
        isHeadingLoading={
          isLoading || isEmpty(doorDetailsData) || isEmpty(doorStatusData)
        }
        fabActions={true}
        subComponent={
          <Grid gridAutoColumns="max-content" horizontal gridColumnGap="8px">
            <Status
              isActive={doorDetailsData?.isActive}
              isOffline={doorStatusData.isOffline || false}
              lastUpdate={doorStatusData.lastUpdate}
            />
          </Grid>
        }
        actionComponents={
          <Grid
            gridTemplateColumns={
              availableFunctions.length > 0 &&
              (isDIS() || isDI() || isDualProducts())
                ? '1fr 1fr'
                : '1fr'
            }
            gridGap={20}>
            <FollowDoor
              doorDetailsData={doorDetailsData}
              isEditDetails={isEditDetails}
              isLoading={isLoading}
            />
            {availableFunctions.length > 0 &&
              (isDIS() || isDI() || isDualProducts()) && (
                <ActionsListButton
                  itemCount={availableFunctions?.length ?? 0}
                  allowRemoteOpen={availableFunctions.includes(
                    RemoteFunction.REMOTE_OPEN,
                  )}
                  allowClearErrorLog={availableFunctions.includes(
                    RemoteFunction.CLEAR_ERROR_LOG,
                  )}
                  allowResetDevice={availableFunctions.includes(
                    RemoteFunction.RESET_DEVICE,
                  )}
                  allowResetOperator={availableFunctions.includes(
                    RemoteFunction.RESET_OPERATOR,
                  )}
                  allowViewDoorState={availableFunctions.includes(
                    RemoteFunction.VIEW_DOOR_STATE,
                  )}
                  allowRemoteLock={availableFunctions.includes(
                    RemoteFunction.REMOTE_LOCK,
                  )}
                  allowRemoteUnlock={availableFunctions.includes(
                    RemoteFunction.REMOTE_UNLOCK,
                  )}
                  isDisabled={isEditDetails || isLoading}
                />
              )}
          </Grid>
        }
      />
      <Container>
        <SectionHeader>
          <H color="primary" size="h3" mb={0}>
            {t('doorDetails:overview')}
          </H>
          <SectionHeaderActions>
            {userMe.userRole === 'Admin' && (
              <P ml="15px" mb={0}>
                <Button
                  variant="contained"
                  icon="edit"
                  onClick={exportDoorReportModal.toggleModal}>
                  {t('spaceDetails:modals.export_report.title')}
                </Button>
              </P>
            )}
          </SectionHeaderActions>
        </SectionHeader>
        <Spacer height={20} />
        {isEditDetails ? (
          <EditDoorDetails
            data={doorDetailsData}
            toggleEditDetails={toggleEditDetails}
          />
        ) : (
          <>
            <DoorMapDetails
              doorDetails={doorDetailsData}
              doorStatus={doorStatusData}
              toggleEdit={
                !isLoading && userMe?.userRole === 'Admin'
                  ? toggleEditDetails
                  : undefined
              }
            />
            <Spacer height={60} />
            <Alert
              doorId={doorId}
              triggerUpdate={triggerUpdate}
              isOffline={doorStatusData.isOffline || !doorDetailsData.isActive}
            />
            <Spacer height={60} />
            <InsightsDoorDetails
              isFollowing={doorDetailsData.isFollowing}
              doorId={doorId}
              installDate={doorDetailsData.installDate}
              triggerUpdate={triggerUpdate}
              defaultSelectedDates={selectedDates}
              defaultSelectedDateType={selectedDateType}
              isOffline={doorStatusData.isOffline || !doorDetailsData.isActive}
            />
          </>
        )}
        {!isEditDetails && (
          <SectionUuid>
            <P fontWeight="small" color="blueGrey">
              {doorDetailsData?.uuid}
            </P>
          </SectionUuid>
        )}
      </Container>

      <ExportReportModal
        deviceUUID={doorId}
        userId={userMe.id}
        installDate={doorDetailsData.installDate}
        isNetworkLevelReport={false}
      />
      {(isDIS() || isDI() || isDualProducts()) && (
        <>
          <DoorStateModal
            deviceUUID={doorDetailsData.uuid}
            deviceModel={doorDetailsData?.type}></DoorStateModal>
          <ViewCurrentStateModal
            deviceUUID={doorId}
            lastUpdate={doorStatusData.lastUpdate}
            isOffline={doorStatusData.isOffline || !doorDetailsData.isActive}
            doorType={doorDetailsData.type}></ViewCurrentStateModal>
          <RequestRemoteOpenModal
            deviceUUID={doorId}
            doorName={doorDetailsData.name}
            location={doorDetailsData.location}
            account={doorDetailsData.account}
            isDisabled={
              doorStatusData.isOffline ||
              !doorDetailsData.isActive ||
              activeAlertData.length > 0
            }
            isInOffMode={
              (doorDetailsData.type === 'ED 100' ||
                doorDetailsData.type === 'ED 250') &&
              doorStatusData?.programSwitchMode?.toLocaleLowerCase() === 'off'
            }></RequestRemoteOpenModal>
          <ResetIoTDeviceModal
            deviceUUID={doorId}
            doorName={doorDetailsData.name}
            location={doorDetailsData.location}
            account={doorDetailsData.account}
            isDisabled={!doorDetailsData.isActive}></ResetIoTDeviceModal>
          <RemoteLockModal
            deviceUUID={doorId}
            doorName={doorDetailsData.name}
            account={doorDetailsData.account}
            toggleRefresh={() =>
              setReloadRemoteFunctions(!reloadRemoteFunctions)
            }></RemoteLockModal>
          <RemoteUnlockModal
            deviceUUID={doorId}
            doorName={doorDetailsData.name}
            account={doorDetailsData.account}
            toggleRefresh={() =>
              setReloadRemoteFunctions(!reloadRemoteFunctions)
            }></RemoteUnlockModal>
          <ResetOperatorModal
            deviceUUID={doorId}
            doorName={doorDetailsData.name}
            operator={doorDetailsData.type}
            location={doorDetailsData.location}
            account={doorDetailsData.account}
            isDisabled={!doorDetailsData.isActive}></ResetOperatorModal>
          <ClearOperatorErrorLogModal
            deviceUUID={doorId}
            doorName={doorDetailsData.name}
            operator={doorDetailsData.type}
            location={doorDetailsData.location}
            account={doorDetailsData.account}
            isDisabled={
              doorStatusData.isOffline || !doorDetailsData.isActive
            }></ClearOperatorErrorLogModal>
        </>
      )}
    </>
  );
};

export default DoorDetails;
