import styled from '@emotion/styled';
import { theme } from 'style';

interface LinkProps {
  isMobile: boolean;
}

export const StyledDiv = styled.div<LinkProps>`
padding: ${(props) =>
  props.isMobile ? '10px 8px' : '40px 48px'};
font-size: 14px;
`;