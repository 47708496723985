import styled from '@emotion/styled';
import { theme } from 'style';
import { ModeLabelProps } from './ModeLabel';

export const StyledModeChange = styled.div<ModeLabelProps>`
  padding: ${(props) =>
    props.variant === 'normal' ? '14px 18px' : '4px 10px'};
  background: #ebf2ff;
  border: 1px solid ${theme.colors['primary']};
  border-radius: 10px;
  color: ${theme.colors['primary']};
  font-size: ${(props) => (props.variant === 'small' ? '14px' : 'inherit')};
  text-align: center;
  padding-top: 5px;
  padding-bottom: ${(props) => (props.variant === 'small' ? '5px' : 'inherit')};
`;
