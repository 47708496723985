import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  Paragraph as P,
  Box,
  IcomoonIcons,
  MobileTableComponents,
} from 'components';
import { NotificationType } from 'models';
import { CriticalHeading, GeneralHeading } from './alertListItemStyles';

interface Props {
  row: any;
  showAccountName: boolean;
  navigateOnRowClick: (id: any) => void;
}

const AlertListItem = ({ row, showAccountName, navigateOnRowClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      padding="0 20px"
      key={row.id}
      onClick={() => {
        navigateOnRowClick(row.original);
      }}>
      <P
        size="small"
        fontWeight={400}
        marginTop="15px"
        style={{ overflow: 'visible' }}>
        {row.original.type}
      </P>
      <Grid
        gridTemplateColumns="1fr 1fr 1fr 20px"
        gridTemplateRows={'auto auto auto auto'}
        gridColumnGap="15px">
        <MobileTableComponents.ColumnHeader row={2} column={1} marginTop="15px">
          {t('alerts:table.column.notification_type')}
        </MobileTableComponents.ColumnHeader>
        <MobileTableComponents.Item truncate row={3} column={1}>
          <P size="tiny" marginBottom="15px">
            {row.original.notificationType === NotificationType.Alert
              ? t('alerts:notification_type.alert')
              : t('alerts:notification_type.mode')}
            {row?.original?.notificationType === NotificationType.Alert &&
              (row?.original?.isCritical ? (
                <CriticalHeading>
                  {t('alerts:names.criticalAlertTitle')}
                </CriticalHeading>
              ) : (
                <GeneralHeading>
                  {t('alerts:names.generalAlertTitle')}
                </GeneralHeading>
              ))}
          </P>
        </MobileTableComponents.Item>

        <MobileTableComponents.ColumnHeader row={2} column={2} marginTop="15px">
          {t('alerts:table.column.status')}
        </MobileTableComponents.ColumnHeader>
        <MobileTableComponents.Item truncate row={3} column={2}>
          <P size="tiny" marginBottom="15px">
            {row.original.status}
          </P>
        </MobileTableComponents.Item>

        <MobileTableComponents.ColumnHeader row={2} column={3} marginTop="15px">
          {t('alerts:table.column.door_name')}
        </MobileTableComponents.ColumnHeader>
        <MobileTableComponents.Item truncate row={3} column={3}>
          <P size="tiny" marginBottom="15px">
            {row.original.doorName}
          </P>
        </MobileTableComponents.Item>

        {showAccountName && (
          <>
            {/* <MobileTableComponents.ColumnHeader row={3} column={1}>
              {t('alerts:table.column.account_name')}
            </MobileTableComponents.ColumnHeader>
            <MobileTableComponents.Item truncate row={4} column={1}>
              <P size="tiny" marginBottom="15px">
                {row.original.accountName}
              </P>
            </MobileTableComponents.Item> */}

            {/* <MobileTableComponents.ColumnHeader row={3} column={3}>
              {t('alerts:table.column.operator_type')}
            </MobileTableComponents.ColumnHeader>
            <MobileTableComponents.Item truncate row={4} column={3}>
              <P size="tiny" marginBottom="15px">
                {row.original.operatorType}
              </P>
            </MobileTableComponents.Item> */}

            {/* <MobileTableComponents.ColumnHeader row={5} column={1}>
              {t('alerts:table.column.open_date_time')}
            </MobileTableComponents.ColumnHeader>
            <MobileTableComponents.Item truncate row={6} column={1}>
              <P size="tiny" marginBottom="15px">
                {row.original.openDateTime
                  ? moment(row.original.openDateTime).format(
                      'DD/MM/YYYY - HH:mm',
                    )
                  : ``}
              </P>
            </MobileTableComponents.Item> */}

            {/* <MobileTableComponents.ColumnHeader row={5} column={2}>
              {t('alerts:table.column.closed_date_time')}
            </MobileTableComponents.ColumnHeader>
            <MobileTableComponents.Item truncate row={6} column={2}>
              <P size="tiny" marginBottom="15px">
                {row.original.closedDateTime
                  ? moment(row.original.closedDateTime).format(
                      'DD/MM/YYYY - HH:mm',
                    )
                  : ``}
              </P>
            </MobileTableComponents.Item> */}

            {/* <MobileTableComponents.ColumnHeader row={5} column={3}>
              {t('alerts:table.column.duration')}
            </MobileTableComponents.ColumnHeader>
            <MobileTableComponents.Item truncate row={6} column={3}>
              <P size="tiny" marginBottom="15px">
                {toDuration(row.original.duration) || ``}
              </P>
            </MobileTableComponents.Item> */}
          </>
        )}

        {!showAccountName && (
          <>
            {/* <MobileTableComponents.ColumnHeader row={3} column={2}>
              {t('alerts:table.column.operator_type')}
            </MobileTableComponents.ColumnHeader>
            <MobileTableComponents.Item truncate row={4} column={2}>
              <P size="tiny" marginBottom="15px">
                {row.original.operatorType}
              </P>
            </MobileTableComponents.Item> */}

            {/* <MobileTableComponents.ColumnHeader row={3} column={3}>
              {t('alerts:table.column.open_date_time')}
            </MobileTableComponents.ColumnHeader>
            <MobileTableComponents.Item truncate row={4} column={3}>
              <P size="tiny" marginBottom="15px">
                {row.original.openDateTime
                  ? moment(row.original.openDateTime).format(
                      'DD/MM/YYYY - HH:mm',
                    )
                  : ``}
              </P>
            </MobileTableComponents.Item> */}

            {/* <MobileTableComponents.ColumnHeader row={5} column={1}>
              {t('alerts:table.column.closed_date_time')}
            </MobileTableComponents.ColumnHeader>
            <MobileTableComponents.Item truncate row={6} column={1}>
              <P size="tiny" marginBottom="15px">
                {row.original.closedDateTime
                  ? moment(row.original.closedDateTime).format(
                      'DD/MM/YYYY - HH:mm',
                    )
                  : ``}
              </P>
            </MobileTableComponents.Item> */}

            {/* <MobileTableComponents.ColumnHeader row={5} column={2}>
              {t('alerts:table.column.duration')}
            </MobileTableComponents.ColumnHeader>
            <MobileTableComponents.Item truncate row={6} column={2}>
              <P size="tiny" marginBottom="15px">
                {toDuration(row.original.duration) || ``}
              </P>
            </MobileTableComponents.Item> */}
          </>
        )}

        <Grid
          gridColumn={4}
          gridRow={`1 / span 6`}
          alignSelf="center"
          justifySelf="end">
          <IcomoonIcons icon="chevron-right" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlertListItem;
