import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from 'components';
import { StyledDiv } from './floatingActionBarStyle';

import {} from 'components';

interface CommonProps {
  /**The main action of the FAB. Uses blue contained button */
  primaryAction?: React.ReactElement;
  /**The secondary, optional action. Uses outlined button */
  secondaryAction?: React.ReactElement;
  children?: React.ReactNode;
}

type Props =
  | (CommonProps & {
      primaryAction: React.ReactElement;
      secondaryAction?: React.ReactElement;
    })
  | (CommonProps & {
      children: React.ReactNode;
    });

const FloatingActionBar = ({
  secondaryAction,
  primaryAction,
  children,
}: Props) => {
  return (
    <StyledDiv>
      {!children ? (
        <Grid gridAutoColumns="1fr" gridColumnGap="15px" gridAutoFlow="column">
          {secondaryAction &&
            React.cloneElement(secondaryAction, { width: 160 })}
          {primaryAction &&
            React.cloneElement(primaryAction, {
              width: secondaryAction ? 160 : 'auto',
              stretch: secondaryAction ? false : true,
            })}
        </Grid>
      ) : (
        children
      )}
    </StyledDiv>
  );
};

export default FloatingActionBar;
