import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import i18n from 'core/i18n/i18n';

import { Container } from './editSpaceDetailsStyle';
import { SpaceRequired } from '../../components';

import { FormComponents, SelectField, TextField, Paragraph } from 'components';
import { SpaceDetails } from 'models';
import { useSpaceTypes } from 'hooks';
import { editSpaceDetails } from 'services';
import { useSpaceDetailsStore } from 'stores';
import { InputLabel } from 'style';

type Props = {
  data: SpaceDetails;
  toggleEditDetails: () => void;
};

type ValuesT = {
  currentOccupancy: number;
  customMaxOccupancy: number;
  defaultMaxOccupancy: number;
  name: string;
  notificationThreshold: number;
  occupancyOffset: number;
  size: number;
  spaceType: string;
  defaultCapacityPP: number;
};

const Validation = Yup.object({
  name: Yup.string().required(i18n.t('forms:validation.required')),
  spaceType: Yup.string().required(i18n.t('forms:validation.required')),
  size: Yup.number()
    .positive(i18n.t('forms:validation.number_positive'))
    .required(i18n.t('forms:validation.required'))
    .typeError(i18n.t('forms:validation.number_positive')),
  notificationThreshold: Yup.number()
    .positive(i18n.t('forms:validation.number_positive'))
    .required(i18n.t('forms:validation.required'))
    .typeError(i18n.t('forms:validation.number_positive')),
  customMaxOccupancy: Yup.number()
    .positive(i18n.t('forms:validation.number_positive'))
    .max(
      Yup.ref('defaultMaxOccupancy'),
      i18n.t('spaceDetails:edit_form.validation.custom_max_occupancy'),
    )
    .typeError(i18n.t('forms:validation.number_positive')),
});

export const EditSpaceDetailsForm = ({ data, toggleEditDetails }: Props) => {
  const { details, status } = data;

  const { t } = useTranslation();
  const { spaceDetailsFetch } = useSpaceDetailsStore();
  const [error, setError] = useState('');
  const onSubmit = async (
    values: ValuesT,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true);
    try {
      await editSpaceDetails({
        id: details.spaceId,
        editParams: {
          spaceDisplayName: values.name,
          spaceType: values.spaceType,
          spaceSize: Number(values.size),
          customMaxOccupancy: Number(values.customMaxOccupancy),
          approachingThresholdOccupancy: Number(values.notificationThreshold),
        },
      });
      await spaceDetailsFetch(details.spaceId);
      toggleEditDetails();
    } catch (err) {
      setError(t('spaceDetails:modals.error'));
    } finally {
      setSubmitting(false);
    }
  };

  const { spaceTypes } = useSpaceTypes();
  return (
    <FormComponents.Wrapper>
      <FormComponents.Header heading={t('spaceDetails:edit_form.heading')} />
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
        validationSchema={Validation}
        initialValues={
          details && {
            name: details.spaceDisplayName,
            spaceType: details.spaceType,
            size: details.spaceSize,
            currentOccupancy: status.currentOccupancy,
            defaultMaxOccupancy: details.defaultMaxOccupancy,
            customMaxOccupancy: details.customMaxOccupancy,
            occupancyOffset: details.occupancyOffset,
            notificationThreshold: status.approachingOccupancy,
            defaultCapacityPP: details.defaultCapacityPP,
          }
        }>
        {(props) => (
          <Form>
            <Container>
              <div>
                <Paragraph bold color="primary" mb={20}>
                  {t('spaceDetails:edit_form.general_heading')}
                </Paragraph>
                <TextField
                  label={t('spaceDetails:edit_form.labels.name')}
                  name="name"
                />
                <SelectField
                  label={t('spaceDetails:edit_form.labels.type')}
                  name="spaceType"
                  options={spaceTypes}
                />
                <TextField
                  label={
                    <SpaceRequired
                      color="primary"
                      label={t('spaceDetails:edit_form.labels.size')}
                    />
                  }
                  name="size"
                />
                <TextField
                  tooltip={t(
                    'spaceDetails:edit_form.tooltips.notification_threshold',
                  )}
                  label={`${t(
                    'spaceDetails:edit_form.labels.approaching_occupancy',
                  )} (%)`}
                  name="notificationThreshold"
                />
              </div>
              <div>
                <Paragraph bold color="primary" mb={20}>
                  {t('spaceDetails:edit_form.occupancy_heading')}
                </Paragraph>
                <TextField
                  label={t('spaceDetails:edit_form.labels.current_occupancy')}
                  name="currentOccupancy"
                  disabled
                />
                <TextField
                  label={
                    <SpaceRequired
                      color="primary"
                      label={t(
                        'spaceDetails:edit_form.labels.space_required_pp',
                      )}
                    />
                  }
                  name="defaultCapacityPP"
                  disabled
                />
                <TextField
                  label={t(
                    'spaceDetails:edit_form.labels.default_max_occupancy',
                  )}
                  name="defaultMaxOccupancy"
                  disabled
                  tooltip={t('spaceDetails:edit_form.tooltips.max_occupancy')}
                />
                <TextField
                  tooltip={t(
                    'spaceDetails:edit_form.validation.custom_max_occupancy',
                  )}
                  label={t(
                    'spaceDetails:edit_form.labels.custom_max_occupancy',
                  )}
                  name="customMaxOccupancy"
                />
              </div>
            </Container>
            <FormComponents.Submit
              style={{ paddingRight: 0 }}
              label={t('spaceDetails:edit_form.buttons.save')}
              cancelAction={toggleEditDetails}
              errorText={error || null}
              {...props}
            />
          </Form>
        )}
      </Formik>
    </FormComponents.Wrapper>
  );
};

export default EditSpaceDetailsForm;
