import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { Box, Heading as H, ChartSeparator, DropdownSelect } from 'components';
import { useConnectedDoorHealthStore } from 'stores';

import {
  ChartsContainer,
  DropdownContainer,
  SectionHeader,
} from './connectedDoorHealthStyle';
import NoFollowedDoors from './NoFollowedDoors/NoFollowedDoors';
import FilterByAlerts from './FilterByAlerts/FilterByAlerts';
import FilterByConnectivity from './FilterByConnectivity/FilterByConnectivity';

const connectedDoorHealthFilters = [
  { value: 'alert', label: i18next.t('connectedDoorHealth:filters.alerts') },
  {
    value: 'connectivity',
    label: i18next.t('connectedDoorHealth:filters.connectivity'),
  },
];

interface Props {
  followedOnly: boolean;
  triggerUpdate?: boolean;
  accountId?: string;
}

const ConnectedDoorHealth = ({
  followedOnly,
  triggerUpdate,
  accountId,
}: Props) => {
  const { t } = useTranslation();

  const [selectedFilter, setSelectedFilter] = useState(
    connectedDoorHealthFilters[0],
  );

  const {
    isLoading,
    connectedDoorHealthAlerts,
    connectedDoorHealthConnectivity,
    connectedDoorHealthAlertsFetch,
    connectedDoorHealthConnectivityFetch,
  } = useConnectedDoorHealthStore();

  useEffect(() => {
    connectedDoorHealthAlertsFetch({
      update: false,
      followedOnly,
      accountId: accountId,
    });
  }, [followedOnly, triggerUpdate, accountId]);

  useEffect(() => {
    if (selectedFilter) {
      if (selectedFilter.value === 'alert') {
        connectedDoorHealthAlertsFetch({
          update: false,
          followedOnly,
          accountId: accountId,
        });
      }

      if (selectedFilter.value === 'connectivity') {
        connectedDoorHealthConnectivityFetch({
          group: true,
          update: false,
          followedOnly,
          accountId: accountId,
        });
      }
    }
  }, [selectedFilter]);

  if (
    !isLoading &&
    selectedFilter.value === 'alert' &&
    connectedDoorHealthAlerts.length === 0
  ) {
    const heading = followedOnly ? "connectedDoorHealth:no_followed_doors.title" : "connectedDoorHealth:no_doors.title"
    const content = followedOnly ? "connectedDoorHealth:no_followed_doors.content": "connectedDoorHealth:no_doors.content"
    return (
      <>
        <Box p={'30px 0'} outlined={false} backgroundColor={'transparent'}>
          <H color="primary" size="h3" mb={'20px'}>
            {t('connectedDoorHealth:section_title')}
          </H>

          <ChartsContainer>
            <NoFollowedDoors chartTitle="connectedDoorHealth:all_doors:title" heading={heading}  
              content={content} 
            />

            <ChartSeparator />
            <NoFollowedDoors chartTitle="connectedDoorHealth:doors_by_alert_status.title" heading={heading}  
              content={content} 
            />
          </ChartsContainer>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box p={'30px 0'} outlined={false} backgroundColor={'transparent'}>
        <SectionHeader>
          <H color="primary" size="h3" mb={'20px'}>
            {t('connectedDoorHealth:section_title')}
          </H>
          <DropdownContainer>
            <DropdownSelect
              value={selectedFilter.value}
              name="account"
              onChange={(filterType: any) => setSelectedFilter(filterType)}
              options={connectedDoorHealthFilters}
            />
          </DropdownContainer>
        </SectionHeader>

        {selectedFilter.value === 'alert' && connectedDoorHealthAlerts && (
          <FilterByAlerts
            isLoading={isLoading}
            connectedDoorHealthAlerts={connectedDoorHealthAlerts}
          />
        )}

        {selectedFilter.value === 'connectivity' &&
          connectedDoorHealthConnectivity && (
            <FilterByConnectivity
              isLoading={isLoading}
              connectedDoorHealthConnectivity={connectedDoorHealthConnectivity}
            />
          )}
      </Box>
    </>
  );
};

export default ConnectedDoorHealth;
