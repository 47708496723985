import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import {
  FormComponents,
  ResponseHandler,
  Modal,
  TextField,
  Paragraph as P,
} from 'components';
import { useModalStore } from 'stores';
import { ReportType } from 'models';
import { downloadDIReport } from 'services';

interface Props {
  accountId: string;
  type: ReportType;
  year: string;
  month?: string;
}

const GenerateReportModal = ({ accountId, type, year, month }: Props) => {
  const { t } = useTranslation();

  const [generateReportState, setGenerateReportState] = useState<
    'generating' | 'successful' | 'error'
  >('generating');

  const [reportUrl, setReportUrl] = useState('');

  const { generateReportModal } = useModalStore();

  const toggleWrapper = (newState?: 'generating' | 'successful' | 'error') => {
    if (newState) setGenerateReportState(newState);
    generateReportModal.toggleModal();
  };

  const getUrl = async () => {
    try {
      const result = await downloadDIReport(accountId, type, year, month);
      if (result?.url) {
        setReportUrl(result.url);
        setGenerateReportState('successful');
      } else {
        setReportUrl('');
        setGenerateReportState('error');
      }
    } catch {
      setReportUrl('');
      setGenerateReportState('error');
    }
  };

  useEffect(() => {
    if (generateReportState === 'generating') {
      setTimeout(() => {
        getUrl();
      }, 2000);
    }
  }, [generateReportState]);

  useEffect(() => {
    setTimeout(() => {
      getUrl();
    }, 2000);
  }, []);

  const RenderState = () => {
    switch (generateReportState) {
      case 'generating':
        return (
          <ResponseHandler
            mainHeading={t('reports:generatingReport')}
            status="loading"
            action={() => {}}
            hideActionLabel={true}
            actionLabel={t('reports:generatingReport')}
          />
        );
      case 'successful':
        return (
          <ResponseHandler
            mainHeading={t('reports:generatedHeading')}
            subHeading={t('reports:generatedSubHeading')}
            status="success"
            action={() => {
              if (reportUrl.length > 0) {
                window.open(reportUrl, '_blank');
                setReportUrl('');
              }
              toggleWrapper('generating');
            }}
            actionLabel={t('reports:downloadReport')}
          />
        );
      case 'error':
        return (
          <ResponseHandler
            mainHeading={t('reports:reportDoesNotExist')}
            status="error"
            action={() => {
              toggleWrapper('generating');
            }}
            actionLabel={t('reports:closeModal')}
          />
        );
    }
  };

  return (
    <Modal
      heading={''}
      naked
      isOpen={generateReportModal.isOpen}
      toggleModal={toggleWrapper}
      showHeader={true}
      hideSeparator={false}>
      {RenderState()}
    </Modal>
  );
};

export default GenerateReportModal;
