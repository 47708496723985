import styled from '@emotion/styled';
import { Box } from 'components';
import { theme, flexVertical, mq } from 'style';

export const StyledDiv = styled(Box)`
  position: relative;
  width: 100%;
  z-index: 1;

  height: 50px;
  display: grid;
  grid-auto-flow: column;
  ${mq.mobile()} {
    width: 100%;
  }
`;

interface ButtonProps {
  isActive: boolean;
}
export const Button = styled.div<ButtonProps>`
  ${flexVertical};
  padding: 9px 14px;
  width: auto;
  cursor: pointer;
  border-radius: 2px;
  background-color: transparent;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  justify-content: center;
  font-weight: ${({ isActive }) => (isActive ? 600 : 'normal')};
  color: ${(props) =>
    props.isActive ? theme.colors.white : theme.colors.primary};
  ${mq.mobile()} {
    padding: 6px 15px;
    justify-content: center;
    font-size: 14px;
  }
`;

interface IndicatorType {
  width: number;
  translate: any;
  transition: any;
}

export const Indicator = styled.div<IndicatorType>`
  transition: all ${(props) => props.transition} ease-in-out;
  position: absolute;
  top: 50%;
  transform: ${(props) =>
    `translateY(-50%) translateX(${props.translate + 5}px)`};
  background: ${theme.colors.primary};
  z-index: 0;
  height: calc(100% - 10px);
  border-radius: 4px;
  width: ${(props) => `${props.width - 10}px}`};
`;

export const ExternalComponentWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 5px;
`;
