import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  StyledWrapper,
  Popout,
  Backdrop,
  Dismiss,
} from './notificationLinkStyle';

import { Button, Flex, Paragraph as P } from 'components';

interface Props {
  children: any;
  showNotification: boolean;
  dismiss: () => void;
}

const NotificationLink = ({ children, showNotification, dismiss }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      {showNotification && <Backdrop />}
      <StyledWrapper>
        {children}
        {showNotification && (
          <Popout>
            <P>{t('doorDetails:modal.notification')}</P>
            <Flex justifyContent="space-between" alignItems="center" mt={15}>
              <Dismiss onClick={dismiss}>{t('common:modal.dismiss')}</Dismiss>
              <Button
                style={{ height: 24, padding: 14 }}
                onClick={() => history.push('/profile')}>
                {t('common:modal.view_profile')}
              </Button>
            </Flex>
          </Popout>
        )}
      </StyledWrapper>
    </>
  );
};

export default NotificationLink;
