import create from 'zustand';
import { fetchAllDoors, fetchDoorsLastUpdateTime } from 'services';
import { DoorSummary, DoorListParams } from '../models';
import { ColumnState } from 'components/Table/Table';
import { ascending, byPropertiesOf } from 'utils';
import DoorMapDetails from 'products/door-insights/DoorDetails/DoorMapDetails/DoorMapDetails';
import _ from 'lodash';
import i18n from 'i18next';
import { alertCount } from 'products/door-insights/Doors/DoorsTable/DoorsTable';

interface DoorStore {
  pagedDoorData: DoorSummary[];
  filteredDoorData: DoorSummary[];
  allDoorData: DoorSummary[];
  totalDoors: number;
  isLoading: boolean;
  fetchDoors: ({
    params,
    followedOnly,
  }: {
    params: DoorListParams;
    followedOnly: boolean;
    forceLoadFromApi: boolean;
  }) => Promise<any>;
  setDoorState?: any;
  fetchDoorsLastUpdateTime: (doors: DoorSummary[]) => Promise<any>;
  listParams: DoorListParams;
  setListParams: (listParams: DoorListParams) => void;
}

const [useDoorStore] = create<DoorStore>((set, get) => ({
  pagedDoorData: [] as DoorSummary[],
  filteredDoorData: [] as DoorSummary[],
  allDoorData: [] as DoorSummary[],
  listParams: {} as DoorListParams,
  setListParams: (listParams: DoorListParams) => {
    set({ listParams: listParams });
  },
  isLoading: true,
  forceLoadFromApi: false,
  totalDoors: 0,
  fetchDoors: async ({ params, followedOnly, forceLoadFromApi }) => {
    try {
      set({ isLoading: true });
      const shouldLoadFromApi =
        get().allDoorData.length === 0 || forceLoadFromApi;
      const allDoors = shouldLoadFromApi
        ? await getAllDoorsFromApi()
        : get().allDoorData;

      if (shouldLoadFromApi) {
        allDoors?.map((door: DoorSummary) => {
          door.lastUpdateTime = ColumnState.LOADING;
          door.commissionedStatus = door.isActive
            ? i18n.t('common:status.commissioned')
            : i18n.t('common:status.decommissioned');
        });

        await get().fetchDoorsLastUpdateTime(allDoors || []);
      }

      const {
        filteredDoors,
        totalDoors,
        pagedFilteredDoors,
      } = getFilteredDoorsAndCount(get().allDoorData, params, followedOnly);

      set({
        pagedDoorData: pagedFilteredDoors as DoorSummary[],
        filteredDoorData: filteredDoors as DoorSummary[],
        totalDoors: totalDoors,
        isLoading: false,
      });
    } catch (err) {
      set({
        pagedDoorData: [] as DoorSummary[],
        filteredDoorData: [] as DoorSummary[],
        totalDoors: 0,
        isLoading: false,
        allDoorData: [] as DoorSummary[],
      });
      throw err;
    }
  },
  fetchDoorsLastUpdateTime: async (doors: DoorSummary[]) => {
    try {
      if (doors.length > 0) {
        const updatedDoors = await fetchDoorsLastUpdateTime(doors);

        const sortedDoors = getSortedDoors(updatedDoors);

        const uuids = doors.map((item: any) => item.id).sort();
        const sortedUuids = sortedDoors?.map((item: any) => item.id).sort();

        if (JSON.stringify(sortedUuids) === JSON.stringify(uuids)) {
          set({
            allDoorData: sortedDoors,
          });
        }
      } else {
        set({
          allDoorData: [],
        });
      }
    } catch (err) {
      throw err;
    }
  },
  setDoorState: set,
}));

export function getSortedDoors(doors: DoorSummary[] | undefined) {
  return doors?.sort(
    byPropertiesOf([
      'isOffline',
      '-isActive',
      '-alertCount',
      '-lastUpdateTime',
    ]),
  );
}

async function getAllDoorsFromApi() {
  const res = await fetchAllDoors({ params: {}, followedOnly: false });
  const allDoors = await res?.doors;
  return allDoors;
}

function getFilteredDoorsAndCount(
  allDoors: DoorSummary[] | undefined,
  params: DoorListParams,
  followedOnly: boolean,
) {
  let filteredDoors = allDoors;

  if (params.name) {
    filteredDoors = filteredDoors?.filter((door) =>
      door.name.toLowerCase().includes(params.name?.toLowerCase() || ''),
    );
  }

  if (params.type) {
    filteredDoors = filteredDoors?.filter((door) =>
      door.type.toLowerCase().includes(params.type?.toLowerCase() || ''),
    );
  }

  if (params.alerts) {
    if (params.alerts === 'active') {
      filteredDoors = filteredDoors?.filter((door) => door.alertCount > 0);
    } else if (params.alerts === 'noactive') {
      filteredDoors = filteredDoors?.filter((door) => door.alertCount === 0);
    }
  }

  if (params.accountId) {
    filteredDoors = filteredDoors?.filter(
      (door) => door.account.id === params.accountId,
    );
  }

  if (followedOnly) {
    filteredDoors = filteredDoors?.filter((door) => door.isFollowing);
  }

  if (params.sortBy && params.order) {
    if (params.sortBy === alertCount) {
      const newFilteredDoors = filteredDoors?.slice();
      if (params.order === ascending) {
        filteredDoors = getSortedDoors(newFilteredDoors)?.reverse();
      } else {
        filteredDoors = getSortedDoors(newFilteredDoors);
      }
    } else {
      filteredDoors = getSortedDoorsByColumn(filteredDoors, params);
    }
  }

  const totalDoors = filteredDoors?.length;
  const pagedFilteredDoors = getPagedDoors(params, filteredDoors);

  return { filteredDoors, pagedFilteredDoors, totalDoors };
}

export default useDoorStore;

export function getPagedDoors(
  params: DoorListParams,
  filteredDoors: DoorSummary[] | undefined,
) {
  let pagedFilteredDoors = filteredDoors;

  if (params.offset) {
    const offset = params.offset;
    pagedFilteredDoors = pagedFilteredDoors?.filter(
      (door, index) => index >= offset,
    );
  }

  if (params.limit) {
    pagedFilteredDoors = pagedFilteredDoors?.slice(0, params.limit);
  }
  return pagedFilteredDoors;
}

function getSortedDoorsByColumn(
  filteredDoors: DoorSummary[] | undefined,
  params: DoorListParams,
) {
  const newFilteredDoors = filteredDoors?.slice();

  newFilteredDoors?.sort((doorA, doorB) => {
    const doorAValue =
      _.get(doorA, params.sortBy ?? '') === null
        ? ''
        : _.get(doorA, params.sortBy ?? '');
    const doorBValue =
      _.get(doorB, params.sortBy ?? '') === null
        ? ''
        : _.get(doorB, params.sortBy ?? '');
    const isDoorAValueString = typeof doorAValue === 'string';

    if (params.order === ascending) {
      if (isDoorAValueString) {
        return doorAValue.toLowerCase().localeCompare(doorBValue.toLowerCase());
      }

      return doorAValue > doorBValue ? 1 : -1;
    }

    if (isDoorAValueString) {
      return doorBValue.toLowerCase().localeCompare(doorAValue.toLowerCase());
    }

    return doorAValue > doorBValue ? -1 : 1;
  });

  return newFilteredDoors;
}
