import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  XAxis,
  YAxis,
  ComposedChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import groupBy from 'lodash/groupBy';

import { ChartContainer } from 'components';
import { theme } from 'style';
import { useDoorCyclesStore } from 'stores';
import { useScreenSize } from 'hooks';
import {
  DataSeries,
  DoorCycles as DoorCyclesInterface,
  SelectedDates,
} from 'models';

import {
  CustomTooltipSingleDay,
  CustomTooltipMultipleDays,
  CustomTooltipMultipleWeeks,
} from './Tooltip';
import Summary from './Summary/Summary';
import ChartSkeleton from './Skeleton/Skeleton';
import EmptyState from './EmptyState/EmptyState';
import NoCycles from './NoCycles/NoCycles';
import { getTicksValue } from 'utils/chartHelpers';
import { isCurrentDayWeekOrMonthDates } from 'utils';
import { addMissingHours } from 'utils/addMissingHours';
import { addMissingDays } from 'utils/addMissingDays';

interface DataProps {
  cycleCount: number | null;
  cycleTotal?: number | null;
  timestamp: string;
}

interface DoorCyclesProps {
  selectedDates: SelectedDates;
  followedOnly: boolean;
  isToday: boolean;
  isThisWeek: boolean;
  isThisMonth: boolean;
  doorId?: string | undefined;
  isOffline: boolean;
  isFollowing: boolean;
}

export type DataSetType = {
  startDate: string;
  endDate: string;
  timeScale: string;
};

const DoorCycles = ({
  selectedDates,
  followedOnly,
  isToday,
  isThisWeek,
  isThisMonth,
  doorId,
  isOffline,
  isFollowing,
}: DoorCyclesProps) => {
  const { t } = useTranslation();
  const {
    doorCyclesData,
    doorCyclesFetch,
    cleanUpDoorCycleData,
    pendingRequests,
    isLoading,
  } = useDoorCyclesStore();
  const [chartData, setChartData] = useState(doorCyclesData?.dataSeries || []);
  const [cycleCountTicks, setCycleCountTicks] = useState<number[]>([
    0,
    1,
    2,
    3,
    4,
  ]);
  const [cycleTotalTicks, setCycleTotalTicks] = useState<number[]>([
    0,
    1,
    2,
    3,
    4,
  ]);
  const [hasNegetive, sethasNegetive] = useState(false);
  const [timeframe, setTimeframe] = useState('');

  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  /*
   ** Component Functions
   */

  // format XAxis according to timeframe
  const xAxisTickFormatter = (date: string) => {
    if (timeframe === 'Single day') {
      return moment(date).format('h');
    } else if (timeframe === 'Multiple days') {
      return moment(date).format('D MMM');
    } else {
      return date;
    }
  };

  // format XAxis interval according to timeframe
  const xAxisIntervalFormatter = () => {
    if (timeframe === 'Single day') {
      return 'preserveStart';
    } else if (timeframe === 'Multiple days' && chartData.length > 14) {
      return 6;
    } else if (timeframe === 'Multiple weeks' && chartData.length > 6) {
      return 2;
    } else {
      return 0;
    }
  };

  const yAxisTickFormatter = (tick: number) =>
    Math.abs(tick) > 10000 ? `${tick / 1000}K` : tick;

  // add cyclesTotal to chart data for line chart
  const aggregateCycleData = (data: DataProps[]) => {
    let total: any = 0;
    return data.map((item: DataProps) => {
      if (typeof item.cycleCount === 'number') {
        total += item.cycleCount as number;
      } else {
        total = null;
      }
      return { ...item, cycleTotal: total };
    });
  };

  // group days into weeks if timeframe is > 31 days
  const groupIntoWeeks = (data: DataProps[]) => {
    const weeklyData = Object.values(
      groupBy(data, (data) => moment(data.timestamp).isoWeek()),
    );

    return (
      weeklyData
        .map((week: DataProps[]) => {
          const startDate = week[0].timestamp;
          const endDate = week[week.length - 1].timestamp;
          const xAxisLabel = `${moment(week[0].timestamp).format('D')}-${moment(
            week[week.length - 1].timestamp,
          ).format('D MMM')}`;

          let accumulatedCycleCount = 0;

          return week.reduce((acc: any, data: DataProps) => {
            accumulatedCycleCount += data.cycleCount as number;

            return {
              ...acc,
              cycleCount: accumulatedCycleCount,
              startDate,
              endDate,
              xAxisLabel,
            };
          }, {});
        })
        .sort((a: any, b: any) => (a.startDate > b.startDate ? 1 : -1)) || []
    );
  };

  // render custom tooltip
  const renderTooltip = (timeframe: string) => {
    switch (timeframe) {
      case 'Single day':
        return <CustomTooltipSingleDay />;
      case 'Multiple days':
        return <CustomTooltipMultipleDays />;
      case 'Multiple weeks':
        return <CustomTooltipMultipleWeeks />;
      default:
        return;
    }
  };

  /*
   ** useEffects
   */

  useEffect(() => {
    doorCyclesFetch(selectedDates, followedOnly, doorId);

    return () => {
      cleanUpDoorCycleData();
    };
  }, [selectedDates, followedOnly]);

  useEffect(() => {
    let refreshInterval: any;
    if (isCurrentDayWeekOrMonthDates(selectedDates)) {
      refreshInterval = setInterval(() => {
        doorCyclesFetch(selectedDates, followedOnly, doorId);
      }, 5 * 60 * 1000);
    }

    return () => {
      cleanUpDoorCycleData();
      clearInterval(refreshInterval);
    };
  }, [selectedDates]);

  useEffect(() => {
    let formattedData;
    if (doorCyclesData?.dataSeries?.length > 0) {
      if (doorCyclesData?.dataSeries?.length > 31) {
        setTimeframe('Multiple weeks');
        formattedData = aggregateCycleData(
          groupIntoWeeks(
            doorCyclesData?.dataSeries.sort((a, b) =>
              a.timestamp > b.timestamp ? 1 : -1,
            ) || [],
          ),
        );
      } else if (doorCyclesData?.timeScale === 'Hours') {
        setTimeframe('Single day');
        formattedData = aggregateCycleData(
          doorCyclesData.dataSeries.sort((a, b) =>
            moment(a.timestamp).local() > moment(b.timestamp).local() ? 1 : -1,
          ),
        );
      } else {
        setTimeframe('Multiple days');
        formattedData = aggregateCycleData(
          doorCyclesData?.dataSeries.sort((a, b) =>
            a.timestamp > b.timestamp ? 1 : -1,
          ) || [],
        );
      }
    }

    const hasNegetiveCycleCount = formattedData
      ?.map((item) => item.cycleCount)
      .find((item) => (item || 0) < 0);
    const hasNegetiveCycleTotal = formattedData
      ?.map((item) => item.cycleTotal)
      .find((item) => (item || 0) < 0);

    sethasNegetive(hasNegetiveCycleCount || hasNegetiveCycleTotal);
    setCycleCountTicks(
      getTicksValue(
        formattedData as DataSeries[],
        'cycleCount',
        hasNegetiveCycleCount || hasNegetiveCycleTotal,
      ),
    );
    setCycleTotalTicks(
      getTicksValue(
        formattedData as DataSeries[],
        'cycleTotal',
        hasNegetiveCycleCount || hasNegetiveCycleTotal,
      ),
    );

    setChartData(formattedData || []);
  }, [doorCyclesData]);

  if (chartData.length === 0 && !isLoading) {
    return <EmptyState isOffline={isOffline} isFollowing={isFollowing} />;
  }

  if (chartData.length === 0 || pendingRequests > 0)
    return (
      <ChartContainer
        title={t('doorCycles:title')}
        tooltip={t('doorDetails:chart:cycles_description')}>
        <ChartSkeleton />
      </ChartContainer>
    );

  // extract cycle count data
  const cycleCount = doorCyclesData?.summary?.cycleCount;
  const cycleCountAverage = doorCyclesData?.summary?.cycleCountAverage;
  const cycleCountSinceConnection =
    doorCyclesData?.summary?.cycleCountSinceConnection;

  return (
    <>
      <ChartContainer
        title={t('doorCycles:title')}
        tooltip={t('doorDetails:chart:cycles_description')}
        greenBorder>
        {cycleCount === 0 ? (
          <NoCycles />
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              data={chartData.length > 0 ? chartData : undefined}
              margin={{ left: 20 }}>
              <Tooltip
                content={renderTooltip(timeframe)}
                cursor={{ stroke: theme.colors.blueGrey }}
                allowEscapeViewBox={{ x: !isMobile, y: true }}
              />
              <CartesianGrid
                vertical={false}
                stroke={theme.colors.lightBlueGrey}
                strokeWidth={1}
              />
              <Bar
                yAxisId="left"
                dataKey="cycleCount"
                fill={theme.colors.primary}
                radius={[4, 4, 0, 0]}
                barSize={25}
              />
              <Line
                yAxisId="right"
                dataKey="cycleTotal"
                fill={theme.colors.blueGrey}
                stroke={theme.colors.blueGrey}
                strokeWidth={2}
                dot={{ r: 2 }}
              />
              <XAxis
                dataKey={
                  doorCyclesData?.dataSeries?.length <= 31
                    ? 'timestamp'
                    : 'xAxisLabel'
                }
                tickFormatter={xAxisTickFormatter}
                tickLine={false}
                axisLine={false}
                tick={{
                  fontSize: '14px',
                  lineHeight: '25px',
                  fill: theme.colors.charcoal,
                }}
                tickMargin={12}
                xAxisId={0}
                interval={xAxisIntervalFormatter()}
                padding={{ left: 10, right: 10 }}
                height={32}
              />
              {/* only show 2nd XAxis if timescale is hourly */}
              {timeframe === 'Single day' && (
                <XAxis
                  dataKey="timestamp"
                  // only return "AM" or "PM" for 12AM | 12PM
                  tickFormatter={(date) => {
                    const hour = parseInt(moment(date).format('h'));
                    if (hour % 12 === 0) {
                      return moment(date).format('A');
                    } else {
                      return '';
                    }
                  }}
                  tickLine={false}
                  axisLine={false}
                  tick={{
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    fill: theme.colors.charcoal,
                  }}
                  xAxisId={1}
                  interval={0}
                />
              )}
              <YAxis
                yAxisId="left"
                axisLine={false}
                tickLine={false}
                tick={{ fontSize: isMobile ? '12px' : '14px' }}
                width={40}
                domain={
                  hasNegetive
                    ? [cycleCountTicks[0], cycleCountTicks[4]]
                    : undefined
                }
                ticks={hasNegetive ? [...cycleCountTicks] : undefined}
                tickFormatter={yAxisTickFormatter}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                type="number"
                axisLine={false}
                tickLine={false}
                domain={
                  hasNegetive
                    ? [cycleTotalTicks[0], cycleTotalTicks[4]]
                    : undefined
                }
                ticks={hasNegetive ? [...cycleTotalTicks] : undefined}
                tick={{ fontSize: isMobile ? '12px' : '14px' }}
                tickFormatter={yAxisTickFormatter}
              />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </ChartContainer>

      <Summary
        cycleCount={cycleCount}
        cycleCountAverage={
          // calculate average cycles manually if data is grouped into weeks
          timeframe === 'Multiple weeks'
            ? Math.floor(cycleCount / chartData?.length)
            : cycleCountAverage
        }
        cycleCountSinceConnection={cycleCountSinceConnection}
        timeframe={timeframe}
        isToday={isToday}
        isThisWeek={isThisWeek}
        isThisMonth={isThisMonth}
      />
    </>
  );
};

export default DoorCycles;
