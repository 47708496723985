import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledWrapper } from './actionListButtonStyle';

import { Button, Paragraph as P } from 'components';
import { useModalStore, useSpaceDetailsStore } from 'stores';
import { useScreenSize, useOutsideClick } from 'hooks';
import { User } from 'models';

interface Props {
  isDisabled: boolean;
}

const ActionsListButton = ({ isDisabled }: Props) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const toggleIsVisible = () => setIsVisible(!isVisible);
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const { spaceDetailsData } = useSpaceDetailsStore();
  const { statusDisplayModal, calibrateOccupancyModal } = useModalStore();

  const [userMe, setUserMe] = useState<User>({} as User);
  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, toggleIsVisible); // TODO:

  return (
    <StyledWrapper>
      <Button
        inverse={!isMobile}
        width={isMobile ? 160 : 200}
        iconSize={20}
        stretch
        icon="more-vertical1"
        disabled={isDisabled}
        onClick={toggleIsVisible}>
        {t('spaceDetails:page_header.buttons.actions')}
      </Button>
      {isVisible && (
        <div ref={ref}>
          {userMe.userRole === 'Admin' && (
            <P
              color={spaceDetailsData.status.isOffline ? 'grey' : 'charcoal'}
              onClick={() => {
                if (!spaceDetailsData.status.isOffline) {
                  calibrateOccupancyModal.toggleModal();
                  toggleIsVisible();
                }
              }}
              mb={10}>
              {t('spaceDetails:page_header.actions.calibrate')}
            </P>
          )}

          <P
            color="charcoal"
            onClick={() => {
              statusDisplayModal.toggleModal();
              toggleIsVisible();
            }}>
            {t('spaceDetails:page_header.actions.status')}
          </P>
        </div>
      )}
    </StyledWrapper>
  );
};

export default ActionsListButton;
