import styled from '@emotion/styled';
import { mq, theme } from 'style';

export const AnnouncementsToggleContainer = styled.div<{isOpen: boolean}>`
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 2;

  ${mq.huge()} {
    top: 30px;
  }

  ${({isOpen}) => isOpen ? `
    button {
      background-color: ${theme.colors.primary};
      svg path { 
        fill: ${theme.colors.white} !important;
      }
    }
  ` : ``}
`;

export const AnnouncementsCount = styled.span`
  display: block;
  min-width: 18px;
  padding: 2px 4px;
  position: absolute;
  left: -6px;
  top: -5px;
  font-size: 10px;
  line-height: 1.4;
  font-weight: ${theme.typography.fontWeight.heading};
  color: ${theme.colors.white};
  background-color: ${theme.colors.error};
  text-align: center;
  border-radius: 18px;
`;