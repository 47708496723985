import { DoorProfiles } from 'models/DoorProfiles';

const doorProfilesStorage = {
  set: (obj: DoorProfiles) => {
    window.localStorage.setItem('doorProfiles', JSON.stringify(obj));
  },
  get: JSON.parse(window.localStorage.getItem('doorProfiles') || '{}'),
};

export default doorProfilesStorage;
