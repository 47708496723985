import moment from 'moment';
import { DailyTraffic as DailyTrafficType } from 'models';
import { TrafficDirectionT } from '../DailyTraffic/DailyTraffic';

const generateChartData = (
  data: DailyTrafficType,
  direction: TrafficDirectionT,
  activeDevice?: any,
) => {
  if (!activeDevice) {
    const deviceData = data.devices.map(({ deviceName, dataSeries }) => {
      return dataSeries.map((data) => {
        return {
          from: moment(data.to).unix(),
          [`${deviceName}`]: data[
            `${direction.toLowerCase()}Count` as 'inCount' | 'outCount'
          ],
        };
      });
    });
    const combinedData = deviceData[0];
    deviceData
      .slice(1)
      .map((array) =>
        array.map(
          (device, i) => (combinedData[i] = { ...combinedData[i], ...device }),
        ),
      );
    return combinedData;
  } else {
    return data.devices
      .find((device) => device.id === activeDevice.id)
      ?.dataSeries.map((data) => {
        return { ...data, from: moment(data.to).unix() };
      });
  }
};

export default generateChartData;
