import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GenerateChartComponents } from './spaceCurrentOccupancyStyle';
import { ReactComponent as IndicatorIcon } from './indicator.svg';

import { Skeleton } from 'components';
import { SpaceStatus, SpaceDetails } from 'models';
import { theme } from 'style';
import { useScreenSize } from 'hooks';

interface Props {
  data: SpaceDetails;
  isLoading: boolean;
}

const SpaceCurrentOccupancy = ({ data, isLoading }: Props) => {
  const { t } = useTranslation();
  const { status, details } = data;

  const [rotation, setRotation] = useState<number>(0);
  const [chartData, setChartData] = useState({
    total: 142,
    green: 0,
    yellow: 0,
    red: 0,
  });

  const {
    ChartWrapper,
    Chart,
    Indicator,
    PercentLabel,
    IndicatorContainer,
    Container,
  } = GenerateChartComponents();

  useEffect(() => {
    if (status && !status.isOffline) {
      // Max percent on chart
      const max = 110;
      /* // Grow red section when more than 110% capacity
      if (status.currentOccupancy/status.maxOccupancy > 1.1) {
        max = (status.currentOccupancy/status.maxOccupancy)*100;

        // Clamp to 250% over on the chart
        max = max > 250 ? 250 : max;
      }
      */

      const rotate = (status.percent / max) * 180;

      if (status.percent >= max) {
        setRotation(179.5);
      } else if (status.percent <= 0) {
        setRotation(0.5);
      } else {
        setRotation(rotate);
      }

      // Chart
      const green = (status.approachingOccupancy / max) * chartData.total;
      const yellow =
        ((100 - status.approachingOccupancy) / max) * chartData.total;
      setChartData({
        ...chartData,
        green: green,
        yellow: yellow,
        red: chartData.total - (green + yellow),
      });
    }
  }, [status]);

  const { isMobile } = useScreenSize().screenSize();

  return (
    <Container>
      {isLoading ? (
        <Skeleton
          height={isMobile ? '200px' : '250px'}
          width={isMobile ? '100%' : '80%'}
        />
      ) : (
        <ChartWrapper>
          <Chart>
            <svg viewBox="0 0 100 54">
              <g fillOpacity="0" strokeWidth="18">
                {status.isOffline || !details.spaceIsActive ? (
                  <path
                    d="M5 54a45 45 0 1 1 90 0"
                    stroke={theme.colors.grey}
                    strokeDasharray={`${chartData.total} 0`}
                  />
                ) : (
                  <>
                    <path
                      d="M5 54a45 45 0 1 1 90 0"
                      stroke={theme.colors.error}
                      strokeDasharray={`0 ${chartData.green +
                        chartData.yellow} ${chartData.red} ${chartData.total}`}
                    />
                    <path
                      d="M5 54a45 45 0 1 1 90 0"
                      stroke={theme.colors.warning}
                      strokeDasharray={`0 ${chartData.green} ${chartData.yellow} ${chartData.total}`}
                    />
                    <path
                      d="M5 54a45 45 0 1 1 90 0"
                      stroke={theme.colors.success}
                      strokeDasharray={`${chartData.green} ${chartData.total}`}
                    />
                  </>
                )}
              </g>
            </svg>
            <PercentLabel>
              <p>
                {status.isOffline || !details.spaceIsActive
                  ? t('common:status.offline')
                  : `${status.percent}%`}
              </p>
              <p>
                {status.isOffline || !details.spaceIsActive
                  ? ''
                  : `${status.currentOccupancy} / ${status.maxOccupancy}`}
              </p>
            </PercentLabel>
          </Chart>
          <IndicatorContainer>
            <Indicator rotation={rotation}>
              {!status.isOffline &&
                details.spaceIsActive && (
                  <IndicatorIcon data-testid="indicator" />
                )}
            </Indicator>
          </IndicatorContainer>
        </ChartWrapper>
      )}
    </Container>
  );
};

export default SpaceCurrentOccupancy;
