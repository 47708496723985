import create from 'zustand';
import { fetchAllTypes } from 'services';
import { LabelValue } from '../models';

interface DoorTypeStore {
  doorTypeData: LabelValue[];
  isLoading: boolean;
  fetchDoorTypes: () => void;
}

const [useDoorTypeStore] = create<DoorTypeStore>((set) => ({
  doorTypeData: [] as LabelValue[],
  isLoading: true,
  fetchDoorTypes: async () => {
    try {
      set({ isLoading: true });
      const res = await fetchAllTypes();
      set({
        doorTypeData: res as LabelValue[],
        isLoading: false,
      });
    } catch (err) {
      set({
        doorTypeData: [] as LabelValue[],
        isLoading: false,
      });
      throw err;
    }
  },
}));

export default useDoorTypeStore;
