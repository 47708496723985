import styled from '@emotion/styled';
import { space } from 'styled-system';

import { theme } from 'style';

export const StyledDiv = styled.div`
  padding: 2px 10px;
  background-color: ${theme.colors.lightBlueGrey};
  box-shadow: 0px 4px 4px 0px #0000001a;
  border-radius: 6px;
  color: ${theme.colors.primary};
  font-size: 20px;
  line-height: 30px;
  ${space}
`;
