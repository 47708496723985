import styled from '@emotion/styled';
import { mq, theme } from 'style';

export const DashboardContainer = styled.div`
  position: relative;
`;

export const AnnouncementsAction = styled.div`
  position: relative;
`;

export const UnreadAnnouncementIndicator = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: ${theme.colors.error};
  border-radius: 8px;
  top: 0px;
  right: -8px;
`;
