import React from 'react';
import { Link } from 'react-router-dom';

import { StyledLink } from './doorStateTabStyle';

import { useScreenSize } from 'hooks';
import { IcomoonIconNames } from 'components/IcomoonIcons/IcomoonIcons';
interface DoorStateTabProps {
  /** pathname for the item to navigate too on click */
  to: string;
  /** If the item needs an icon, pass the name */
  icon?: IcomoonIconNames;
  /** Label for the link. Should be the page name */
  label: React.ReactNode;
  /** Boolean if the item appears in the dropdown menu on desktop */
  isInList?: boolean;
  onClick?: () => void;
  iconSize?: number;
  isHiddenRoute?: boolean;
  currentActiveTab: string;
}

const { screenSize } = useScreenSize();
const DoorStateTab: React.FC<DoorStateTabProps> = ({
  to,
  icon,
  label,
  isInList,
  iconSize,
  isHiddenRoute,
  currentActiveTab,
  ...rest
}) => {
  const { isMobile } = screenSize();

  return (
    <StyledLink
      isInList={isInList || false}
      isMobile={isMobile}
      isHiddenRoute={isHiddenRoute}
      isActive={to === currentActiveTab}
      isBolded
      data-cypress="navigation-item"
      {...rest}
      data-testid="navigation-item">
      <Link to="#" style={{ textDecoration: 'none' }}>
        <span>{label}</span>
      </Link>
    </StyledLink>
  );
};

export default DoorStateTab;
