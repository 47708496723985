import React, { useState } from 'react';
import { Paragraph as P, IcomoonIcons, Flex, ToolTip } from 'components';

import { Container, CriticalHeading, GeneralHeading } from './alertRowStyle';
import { useScreenSize } from 'hooks';
import { useTranslation } from 'react-i18next';
import AlertDetailModal from '../../AlertDetailModal/AlertDetailModal';
import { NotificationGroup, NotificationType } from 'models';

interface AlertProps {
  alert: NotificationGroup; // will be delete
  doorId: string;
  isOffline: boolean;
}

const AlertRow = ({ alert, doorId, isOffline }: AlertProps) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize().screenSize();
  const { name, displayName, isCritical, type } = alert;
  const [isOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Container
        onClick={() => setIsModalOpen(true)}
        isOffline={isOffline}
        isCritical={isCritical}>
        <Flex alignItems="center">
          {/* <div style={{ paddingRight: '15px' }}>
            <IcomoonIcons size={32} color="error" icon="alert-triangle" />
          </div> */}
          <Flex alignItems="center">
            <P
              size="medium"
              color={isCritical ? 'error' : 'primary'}
              bold
              paddingRight={10}>
              {displayName || name}
              {'   '}
              {type === NotificationType.Alert &&
                (isCritical ? (
                  <CriticalHeading>
                    {t('alerts:names.criticalAlertTitle')}
                  </CriticalHeading>
                ) : (
                  <GeneralHeading>
                    {t('alerts:names.generalAlertTitle')}
                  </GeneralHeading>
                ))}
            </P>
          </Flex>
        </Flex>
        <Flex alignItems="center">
          {!isMobile && (
            <P size="small" color={isCritical ? 'error' : 'primary'} bold>
              {t('doorDetails:view_alert')}
            </P>
          )}
          <IcomoonIcons
            icon="chevron-right"
            color={isCritical ? 'error' : 'primary'}
          />
        </Flex>
      </Container>

      {/* MODALS */}
      <AlertDetailModal
        doorId={doorId}
        alert={alert}
        isOpen={isOpen}
        onClose={() => setIsModalOpen(false)}
        isOffline={isOffline}
      />
    </>
  );
};

export default AlertRow;
