import { User } from 'models';

const userMeStorage = {
  set: (obj: User) => {
    window.localStorage.setItem('userMe', JSON.stringify(obj));
  },
  get: JSON.parse(window.localStorage.getItem('userMe') || '{}'),
};

export default userMeStorage;
