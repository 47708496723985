import create from 'zustand';
import { fetchAlertsHistory } from 'services';
import { AlertHistory } from '../models';

interface AlertHistoryStore {
  alertHistoryData: AlertHistory;
  isLoading: boolean;
  alertHistoryFetch: ( doorId: string, alertId: string) => void;
}

const [useAlertHistoryStore] = create<AlertHistoryStore>((set) => ({
  alertHistoryData: {} as AlertHistory,
  isLoading: true,
  alertHistoryFetch: async (doorId, alertId) => {
    try {
      set({ isLoading: true, alertHistoryData: {} as AlertHistory});
      const res = await fetchAlertsHistory(
        doorId, alertId
      );
      set({
        alertHistoryData: res,
        isLoading: false,
      });
    } catch (err) {
      set({
        alertHistoryData: {} as AlertHistory,
        isLoading: false,
      });
      throw err;
    }
  },
}));

export default useAlertHistoryStore;
