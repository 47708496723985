import React from 'react';

import { useScreenSize } from 'hooks';
import { Header, Children } from './chartContainerStyle';
import { theme } from 'style';

import { Heading as H, ToolTip, Box, Flex } from 'components';

interface Props {
  title: string;
  tooltip?: string;
  toggle?: React.ReactNode;
  mobileToggle?: React.ReactNode;
  headerStyleProps?: { [key: string]: any };
  childrenStyleProps?: { [key: string]: any };
  elevated?: boolean;
  hideHeader?: boolean;
  greenBorder?: boolean;
  titleSecondaryContent?: React.ReactNode;
}

const ChartContainer: React.FC<Props> = ({
  title,
  children,
  toggle,
  tooltip,
  headerStyleProps,
  childrenStyleProps,
  mobileToggle,
  elevated,
  hideHeader = false,
  greenBorder,
  titleSecondaryContent,
}) => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  return (
    <Box
      data-cypress="chart-container"
      elevated={elevated}
      style={{
        border: greenBorder ? `1px solid ${theme.colors.success}` : '',
      }}>
      {!hideHeader && (
        <Header style={headerStyleProps}>
          <Flex alignItems="center">
            <H size="h6" color="primary" mr="8px">
              {title}
            </H>
            {tooltip && <ToolTip content={tooltip} iconSize={20} />}            
          </Flex>
          {!isMobile && toggle && toggle}
          {isMobile && mobileToggle && mobileToggle}
          {titleSecondaryContent} 
        </Header>
      )}
      <Children style={childrenStyleProps}>
        {isMobile && toggle && toggle}
        {children}
      </Children>
    </Box>
  );
};

export default ChartContainer;
