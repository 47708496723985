import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

interface Props {
  children: string;
}

const BrowserNotSupported = () => {
  const { t } = useTranslation();
  return (
    <OuterContainer>
      <OILogo>
        <img src="/OccupancyInsights.png" />
      </OILogo>
      <Center>
        <h3>{t('common:not_supported.heading')}</h3>
        <p>{t('common:not_supported.subheading')}</p>
      </Center>
      <DormaLogo>
        <img src="/Logo.png" />
      </DormaLogo>
    </OuterContainer>
  );
};

export const OuterContainer = styled.div`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  font-size: 16px;
  background-color: #f5f5f7;
  -webkit-overflow-scrolling: touch;
`;

const OILogo = styled.div`
  width: 100%;
  height: 70px;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const DormaLogo = styled.div`
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  h3 {
    font-size: 28px;
    line-height: 42px;
    font-weight: bold;
    color: #003594;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #424242;
  }
`;

export default BrowserNotSupported;
