import type { HTMLAttributes } from 'react';
import React from 'react';
import { StyledDiv } from './DividerStyle';

type Props = HTMLAttributes<HTMLDivElement> & {
  margin?: number | string;
  colour?: string;
};

export default function Divider({ margin, colour }: Props) {
  return (
    <StyledDiv      
      style={{ marginTop: margin, marginBottom: margin, backgroundColor: colour }}
      aria-hidden="true"
      data-testid="divider"
    ></StyledDiv>
  );
}
