import styled from '@emotion/styled';
import {
  variant,
  textAlign,
  color,
  fontWeight,
  space,
  compose,
  lineHeight,
} from 'styled-system';

import { ThemeType, mq } from 'style';
import { HeadingSizes } from './Heading';

interface HeadingProps {
  size?: HeadingSizes;
  theme?: ThemeType;
  center?: boolean;
  uppercase?: boolean;
}

export const BaseHeading = styled.p<HeadingProps>`
  color: ${(props) => props.theme.colors.black};
  font-family: ${(props) => props.theme.typography.fontFamily};
  padding: 0;
  font-weight: bold;
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
  ${variant({
    prop: 'size',
    variants: {
      hero: {
        fontSize: 52,
        lineHeight: '78px',
      },
      h1: {
        fontSize: 50,
        lineHeight: '75px',
      },
      h2: {
        fontSize: 36,
        lineHeight: '54px',
      },
      h3: {
        fontSize: 28,
        lineHeight: '42px',
      },
      h4: {
        fontSize: 26,
        lineHeight: '39px',
        fontWeight: 'normal',
      },
      h5: {
        fontSize: 22,
        lineHeight: '33px',
      },
      h6: {
        fontSize: 20,
        lineHeight: '27px',
      },
    },
  })};
  ${mq.mobile()} {
    ${variant({
      prop: 'size',
      variants: {
        h2: {
          fontSize: 26,
        },
        h3: {
          fontSize: 24,
          lineHeight: '36px',
        },
        h6: {
          fontSize: 16,
          lineHeight: '24px',
        },
      },
    })}
  }
  ${compose(textAlign, color, fontWeight, space, lineHeight)};
`;
BaseHeading.defaultProps = {
  size: 'h1',
};
