import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import moment from 'moment';

import {
  Flex,
  Paragraph as P,
  Modal,
  Skeleton,
  ToolTip,
  IcomoonIcons,
  Link,
} from 'components';
import {
  AlertActionInfo,
  CriticalHeading,
  Footer,
  GeneralHeading,
} from './alertStyle';
import { useAlertHistoryStore } from 'stores';
import { Spacer, theme } from 'style';
import { NotificationGroup, User } from 'models';
import { DESKTOP_HORIZONTAL_PADDING } from 'components/Modal/modalStyle';

interface Props {
  alert: NotificationGroup;
  doorId: string;
  isOpen: boolean;
  isOffline: boolean;
  onClose: () => void;
}

const AlertDetailModal = ({
  alert,
  doorId,
  isOpen,
  isOffline,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dateFormat = 'DD/MM/YYYY HH:mm';
  const {
    alertHistoryFetch,
    alertHistoryData,
    isLoading,
  } = useAlertHistoryStore();
  const { name, displayName } = alert;
  const {
    information,
    history,
    troubleshooting,
    openDateTime,
    isCritical,
  } = alertHistoryData;

  const sortedHistory = sortBy(history, 'openDateTime').reverse();

  const [userMe, setUserMe] = useState<User>({} as User);
  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      alertHistoryFetch(doorId, name);
    }
  }, [isOpen]);

  const CONTACT_HORIZONTAL_PADDING = DESKTOP_HORIZONTAL_PADDING / 2;
  const contactRowCss = {
    backgroundColor: theme.colors.lightGreyBlue,
    paddingLeft: CONTACT_HORIZONTAL_PADDING,
  };

  return (
    <Modal
      isOpen={isOpen}
      heading={t('doorDetails:modal.title')}
      toggleModal={onClose}
      width="20ch">
      <Flex>
        <P size="normal" color="primary" bold>
          {t('doorDetails:modal.date_time_notified')}
        </P>
        {isOffline && (
          <>
            <Spacer width={5} />
            <ToolTip
              content={t('common:status_tooltip.offline')}
              iconSize={20}
              iconType={`offline`}
            />
          </>
        )}
      </Flex>
      {isLoading ? (
        <Skeleton
          height="14px"
          width="20ch"
          style={{ opacity: 0.5, marginTop: 25 }}
        />
      ) : (
        <P size="small" color="charcoal" mt="10px">
          {moment(openDateTime).format(dateFormat)}
        </P>
      )}
      <P size="normal" color="primary" mt="25px" bold>
        {t('doorDetails:modal.alert_type')}
      </P>
      {isLoading ? (
        <Skeleton
          height="14px"
          width="20ch"
          style={{ opacity: 0.5, marginTop: 25 }}
        />
      ) : (
        <P size="small" color="charcoal" mt="10px">
          {displayName || name}
          {isCritical ? (
            <CriticalHeading>
              {t('alerts:names.criticalAlertTitle')}
            </CriticalHeading>
          ) : (
            <GeneralHeading>
              {t('alerts:names.generalAlertTitle')}
            </GeneralHeading>
          )}
        </P>
      )}
      <P size="normal" color="primary" mt="25px" bold>
        {t('doorDetails:modal.alert_information')}
      </P>
      {isLoading ? (
        <Skeleton
          height="14px"
          width="20ch"
          style={{ opacity: 0.5, marginTop: 25 }}
        />
      ) : (
        <AlertActionInfo dangerouslySetInnerHTML={{ __html: information }} />
      )}
      <P size="normal" color="primary" mt="25px" bold>
        {t('doorDetails:modal.alert_action')}
      </P>
      {isLoading ? (
        <Skeleton
          height="14px"
          width="20ch"
          style={{ opacity: 0.5, marginTop: 25 }}
        />
      ) : (
        <AlertActionInfo
          dangerouslySetInnerHTML={{ __html: troubleshooting }}
        />
      )}
      {isLoading ? (
        <Skeleton
          height="14px"
          width="20ch"
          style={{ opacity: 0.5, marginTop: 25 }}
        />
      ) : (
        <>
          <P
            size="normal"
            color="primary"
            mt="25px"
            bold
            style={{
              ...contactRowCss,
              paddingTop: CONTACT_HORIZONTAL_PADDING,
            }}>
            {t('doorDetails:modal.contact_dormakaba')}
          </P>
          <Flex flexDirection="column">
            <div
              style={{
                ...contactRowCss,
                paddingBottom: CONTACT_HORIZONTAL_PADDING,
              }}>
              <P size="small" color="charcoal" mt="10px">
                <IcomoonIcons icon="email" color="primary" size={16} />
                &nbsp;&nbsp;{' '}
                <Link
                  color={theme.colors.primary}
                  href={`mailto:${userMe.supportingEmail}`}
                  target="_blank">
                  {userMe.supportingEmail}
                </Link>
              </P>
              <P size="small" color="charcoal" mt="10px">
                <IcomoonIcons icon="phone" color="primary" size={16} />
                &nbsp;&nbsp;
                <Link
                  color={theme.colors.primary}
                  href={`tel:${userMe.supportingPhoneNumber}`}>
                  {userMe.supportingPhoneNumber}
                </Link>
              </P>
            </div>
          </Flex>
        </>
      )}
      <Footer>
        <P size="normal" color="primary" mt="27px" bold>
          {t('doorDetails:modal.alert_history')}
        </P>
        {isLoading && (
          <Skeleton
            height="14px"
            width="25ch"
            style={{ opacity: 0.5, marginTop: 10 }}
          />
        )}
        {!isLoading &&
          sortedHistory &&
          sortedHistory.map((row, index) => (
            <Flex key={`${row}_${index}`} mt="10px">
              <P size="small" color="black" bold mr="6px">
                {t('doorDetails:modal.active_from')}
              </P>
              <P size="small" color="charcoal" mr="8px">
                {`${
                  row.openDateTime
                    ? moment(row.openDateTime).format(dateFormat)
                    : ''
                }`}
              </P>
              <P size="small" color="black" bold mr="8px">
                {t('doorDetails:modal.to')}
              </P>
              <P size="small" color="charcoal">
                {`${
                  row.closeDateTime
                    ? moment(row.closeDateTime).format(dateFormat)
                    : ''
                }`}
              </P>
            </Flex>
          ))}
      </Footer>
    </Modal>
  );
};

export default AlertDetailModal;
