import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  OuterContainer,
  InnerContainer,
  BackgroundImage,
} from './dashboardHeaderStyle';
import { Heading as H, Grid, LastUpdated } from 'components';
import Background from './Background.png';
import DoorsStats from '../DoorsStats/DoorsStats';

interface Props {
  lastUpdate: Date;
}

const EngageLogo = () => (
  <Grid
    gridAutoColumns="min-content"
    gridColumnGap="8px"
    alignItems="center"
    gridAutoFlow="column">
    <H size="h2" color="white">
      EntriWorX
    </H>
    <H size="h2" color="white" fontWeight="normal">
      Insights
    </H>
    <div />
  </Grid>
);

const DashboardHeader = ({ lastUpdate }: Props) => {
  const { t } = useTranslation();
  return (
    <OuterContainer>
      <BackgroundImage headerBackgroundImage={Background} />
      <InnerContainer>
        <div>
          <EngageLogo />
          <LastUpdated label={t('dashboard:last_update')} date={lastUpdate} />
        </div>
        <div>
          <DoorsStats />
        </div>
      </InnerContainer>
    </OuterContainer>
  );
};

export default DashboardHeader;
