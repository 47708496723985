import React from 'react';
import { Grid } from 'components';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import { useTopBar } from 'hooks';
import { Container, PageHeading } from './mobileNavBarStyle';

const MobileNavBar = () => {
  const { title, rightAction, leftAction } = useTopBar();

  return (
    <Container>
      <Grid
        width="100%"
        gridTemplateColumns="60px 1fr 60px"
        alignItems="center">
        <Grid justifyContent="start" marginLeft="10px">
          {leftAction ? leftAction : <div />}
        </Grid>
        <Grid justifyContent="center">
          <PageHeading>{title === 'Dashboard' ? 'Home' : title}</PageHeading>
        </Grid>
        <Grid justifyContent="end" marginRight="10px">
          {rightAction ? rightAction : <HamburgerMenu />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default MobileNavBar;
