import React, { useEffect, useState, useLayoutEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UsersTable from './UsersTable/UsersTable';
import UserTableLoading from './UsersTable/LoadingSkeleton/LoadingSkeleton';

import {
  Paragraph as P,
  Flex,
  PageHeader,
  ActionBar,
  Container,
  Button,
  FloatingActionBar,
} from 'components';
import { UsersList, UserListParams, User } from 'models';
import { fetchUsersList, fetchAccountList } from 'services';
import {
  useTableSortBy,
  useTableSearch,
  useTableFilter,
  useScreenSize,
  usePrevious,
} from 'hooks';
import {
  userStatusTranslations,
  roleTranslations,
  applyFilterTranslations,
  getCurrentProduct,
} from 'utils';

import { SearchBarProps } from 'components/SearchBar/SearchBar';
import { DropdownSelectProps } from 'components/DropdownSelect/DropdownSelect';

type Props = RouteComponentProps;
const TableError = ({
  heading,
  subheading,
}: {
  heading: string;
  subheading: string;
}) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    paddingTop={window.innerHeight / 2 - 350}>
    <P size="large" color="primary" bold mb="15px">
      {heading}
    </P>
    <P size="normal" color="charcoal">
      {subheading}
    </P>
  </Flex>
);

const formatAccess = (access: string[]) =>
  access.length > 1 ? access.join(';') : access[0];
const Users = ({}: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [productsCanAccess, setProductsCanAccess] = useState<string>('');
  useEffect(() => {
    const userMe = window.localStorage.getItem('userMe');

    if (userMe) {
      const userDetails: User = JSON.parse(userMe);
      const access = formatAccess(userDetails.productsCanAccess);
      console.log('access: ', access);
      setProductsCanAccess(access);
      setIsAdmin(userDetails.userRole === 'Admin');
    }
  }, []);

  const [listParams, setListParams] = useState<UserListParams>(
    {} as UserListParams,
  );

  const [data, setData] = useState<UsersList[]>([]);
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const history = useHistory();

  const limit = 20;
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Searching ===============================================================
  const { searchValue, searchHandler, clearSearch } = useTableSearch(
    listParams,
    setListParams,
    // firstRender.current,
  );
  const clearSearchWrapper = () => {
    clearSearch();
    fetch(1);
  };
  const searchBarSettings: SearchBarProps = {
    placeholder: t('usersList:search_bar_placeholder'),
    searchValue: searchValue || '',
    searchHandler,
    clearSearch: clearSearchWrapper,
  };

  // Filtering ===============================================================

  const [filtersFromServer, setFiltersFromServer] = useState<any>({
    roles: [],
    accounts: [],
    statuses: [],
  });

  const fetchFilters = async () => {
    try {
      const res = await fetchAccountList();

      if (res) {
        const roleRes = await applyFilterTranslations(
          [
            {
              id: 'Admin',
              value: 'Admin',
            },
            {
              id: 'Viewer',
              value: 'Viewer',
            },
          ],
          roleTranslations,
        );
        const accountsRes = res.map((account: any) => ({
          label: account.name,
          value: account.id,
        }));
        const statusRes = await applyFilterTranslations(
          [
            {
              id: 'Active',
              value: 'Active',
            },
            {
              id: 'Inactive',
              value: 'Inactive',
            },
            {
              id: 'Pending',
              value: 'Pending',
            },
            {
              id: 'VerificationRequired',
              value: 'Verification Required',
            },
          ],
          userStatusTranslations,
        );
        setFiltersFromServer({
          roles: roleRes,
          accounts: accountsRes,
          statuses: statusRes,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchFilters();
  }, []);

  const [roleFilter] = useTableFilter(listParams, setListParams, {
    options: filtersFromServer.roles,
    label: t('usersList:table.filters.role'),
    name: 'role',
  });
  const [statusFilter] = useTableFilter(listParams, setListParams, {
    options: filtersFromServer.statuses,
    label: t('usersList:table.filters.status'),
    name: 'status',
  });

  const filters: DropdownSelectProps[] = [statusFilter];

  if (isAdmin) {
    filters.unshift(roleFilter);
  }

  const { sortByHandler } = useTableSortBy(listParams, setListParams);
  const sortWrapper = (sortBy: any) => {
    switch (sortBy) {
      case 'userRole':
        return sortByHandler('Role');
      default:
        return sortByHandler(sortBy);
    }
  };

  const fetch = async (page = 1) => {
    setIsLoading(true);
    setCurrentPage(page);

    try {
      const res: any = await fetchUsersList({
        ...listParams,
        Platform: productsCanAccess,
        offset: (page - 1) * limit,
        limit,
      });
      if (res) {
        // const usersWithoutMe = res.filter((item: UsersList) => {
        //   const userMe: User = JSON.parse(
        //     window.localStorage.getItem('userMe') || '',
        //   );
        //   return item.id !== userMe.id;
        // });
        setData(res.users);
        setTotalUsers(res.total);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('productsCanAccess: ', productsCanAccess);
    if (productsCanAccess) {
      fetch(1);
    }
  }, [productsCanAccess]);

  const previousParams = usePrevious(listParams);
  useEffect(() => {
    if (
      previousParams &&
      JSON.stringify(listParams) !== JSON.stringify(previousParams)
    ) {
      fetch(1);
    }
  }, [listParams]);

  const onLoadMore = (nextPage: number) => {
    fetch(nextPage);
  };

  const paginated = totalUsers > limit;

  return (
    <>
      {!isMobile ? (
        <PageHeader
          heading={t('usersList:heading')}
          actionComponents={
            <>
              {isAdmin && (
                <Button
                  icon="add"
                  inverse
                  onClick={() => history.push('/users/new')}>
                  {t('usersList:button.add_user')}
                </Button>
              )}
            </>
          }
        />
      ) : (
        <>
          {isAdmin && (
            <FloatingActionBar>
              <Button
                icon="add"
                onClick={() => history.push('/users/new')}
                stretch>
                {t('usersList:button.add_user')}
              </Button>
            </FloatingActionBar>
          )}
        </>
      )}
      <ActionBar
        listParams={listParams}
        setListParams={setListParams}
        searchBarSettings={searchBarSettings}
        filters={filters}
      />
      <Container pb="30px">
        {!isAdmin && (
          <P color="charcoal" size="small" mb={30}>
            {t('usersList:table.viewer_message')}
          </P>
        )}
        {totalUsers !== 0 ? (
          <P color="charcoal" mb={'24px'}>
            {t('common:showing_total.showing')}{' '}
            {currentPage === 1
              ? data.length
              : `${(currentPage - 1) * limit + 1} - ${(currentPage - 1) *
                  limit +
                  data.length}`}{' '}
            {t('common:showing_total.of')} {totalUsers}{' '}
            {t('usersList:heading').toLowerCase()}
          </P>
        ) : (
          <div></div>
        )}
        {!isLoading && data.length === 0 ? (
          <TableError
            heading={t('usersList:table.errors.empty.heading')}
            subheading={t('usersList:table.errors.empty.subheading')}
          />
        ) : (
          <UserTableLoading isLoading={isLoading}>
            <UsersTable
              sortBy={sortWrapper}
              data={data}
              isAdmin={isAdmin}
              onLoadMore={onLoadMore}
              total={totalUsers}
              currentPage={currentPage}
              limit={limit}
              paginated={paginated}
            />
          </UserTableLoading>
        )}
      </Container>
    </>
  );
};

export default Users;
