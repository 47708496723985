import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Paragraph as P,
  Flex,
  Grid,
  Modal,
  IcomoonIcons,
} from 'components';
import { InputContainer, InputLabel, InputError } from 'style';
import { useSpaceDetailsStore, useModalStore } from 'stores';
import { recalibrate } from 'services';
import {
  InputFieldWrapper,
  StyledInput,
} from 'components/TextField/textFieldStyle';

interface Props {
  spaceId: string;
}

const CalibrateOccupancyModal = ({ spaceId }: Props) => {
  const { t } = useTranslation();
  const { setIsLoading, spaceDetailsData } = useSpaceDetailsStore();
  const { calibrateOccupancyModal } = useModalStore();
  const [actualOccupancy, setActualOccupancy] = useState<number | undefined>(
    undefined,
  );

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const updateActualOccupancy = async () => {
    setIsUpdating(true);
    try {
      await recalibrate({
        id: spaceId,
        editParams: {
          newOccupancy: Number(actualOccupancy),
        },
      });
      setIsLoading(true);
      calibrateOccupancyModal.toggleModal();
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
      console.log(err);
      setError(t('spaceDetails:modals.calibrate_occupancy.error'));
    }
  };

  // Run the clean up function on the mounted status
  useEffect(() => {
    return () => {
      setActualOccupancy(undefined);
    };
  }, [calibrateOccupancyModal.isOpen]);

  const Actions = () => {
    return (
      <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap={20}>
        <Button
          variant="outlined"
          onClick={calibrateOccupancyModal.toggleModal}>
          {t('spaceDetails:modals.calibrate_occupancy.buttons.cancel')}
        </Button>
        <Button
          disabled={!actualOccupancy}
          isLoading={isUpdating}
          onClick={updateActualOccupancy}>
          {t('spaceDetails:modals.calibrate_occupancy.buttons.save')}
        </Button>
      </Grid>
    );
  };

  return (
    <Modal
      isOpen={calibrateOccupancyModal.isOpen}
      heading={t('spaceDetails:modals.calibrate_occupancy.title')}
      toggleModal={calibrateOccupancyModal.toggleModal}
      actions={<Actions />}>
      <P color="charcoal" mb={20}>
        {t('spaceDetails:modals.calibrate_occupancy.heading')}
      </P>
      <Grid
        gridTemplateColumns={['repeat(1, 100%)', 'repeat(2, 1fr)']}
        gridGap={[10, 20]}>
        <InputContainer>
          <Flex>
            <InputLabel>
              {t('spaceDetails:modals.calibrate_occupancy.input_label.current')}
            </InputLabel>
          </Flex>
          <InputFieldWrapper>
            <StyledInput
              type={'text'}
              name="oneTimePin"
              value={spaceDetailsData?.status?.currentOccupancy}
              disabled
            />
          </InputFieldWrapper>
          <InputError>{error && error}</InputError>
        </InputContainer>
        <InputContainer>
          <Flex>
            <InputLabel>
              {t('spaceDetails:modals.calibrate_occupancy.input_label.actual')}
            </InputLabel>
          </Flex>
          <InputFieldWrapper>
            <StyledInput
              type="number"
              disabled={spaceDetailsData?.status?.isOffline}
              name="actualOccupancy"
              placeholder={t(
                'spaceDetails:modals.calibrate_occupancy.input_label.actual',
              )}
              value={actualOccupancy ? actualOccupancy : ''}
              onChange={(e: any) => setActualOccupancy(e.target.value)}
            />
          </InputFieldWrapper>
          <InputError></InputError>
        </InputContainer>
      </Grid>
      <Flex alignItems="flex-start">
        <IcomoonIcons icon="info" size={24} />
        <P color="charcoal" size="small" margin={0} ml={10}>
          {t('spaceDetails:modals.calibrate_occupancy.tooltip')}
        </P>
      </Flex>
    </Modal>
  );
};

export default CalibrateOccupancyModal;
