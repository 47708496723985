import React from 'react';

import { Wrapper, Heading } from './responseHandler.style';
import {
  Heading as H,
  Paragraph as P,
  Flex,
  IcomoonIcons,
  Button,
  Spinner,
} from 'components';
import { Spacer } from 'style';

interface Props {
  status?: 'success' | 'error' | 'loading';
  mainHeading: string;
  subHeading?: string;
  bottomText?: string | React.ReactNode;
  action: () => void;
  actionLabel: string;
  isLoading?: boolean;
  cypress?: string;
  hideActionLabel?: boolean;
}

const ResponseHandler: React.FC<Props> = ({
  status,
  mainHeading,
  subHeading,
  bottomText,
  action,
  actionLabel,
  isLoading,
  cypress,
  hideActionLabel,
}) => {
  return (
    <Wrapper data-cypress={cypress}>
      {status && (
        <>
          <Flex centered>
            {status === 'success' && (
              <IcomoonIcons icon="success" color="success" size={90} />
            )}
            {status === 'error' && (
              <IcomoonIcons icon="error" color="error" size={90} />
            )}
            {status === 'loading' && <Spinner diameter="90px" />}
          </Flex>
          <Spacer height={24} />
        </>
      )}

      <Heading>
        <H
          size="h3"
          center
          color="primary"
          data-cypress="response-handler-heading">
          {mainHeading}
        </H>
        {subHeading && (
          <P size="normal" center color="charcoal">
            {subHeading}
          </P>
        )}
      </Heading>
      <Spacer height={25} />

      {!hideActionLabel && (
        <Flex centered>
          <Button onClick={action} isLoading={isLoading}>
            {actionLabel}
          </Button>
        </Flex>
      )}
      {bottomText && (
        <>
          <Spacer height={25} />
          <Flex centered>
            <P size="small" center textAlign="center" color="charcoal">
              {bottomText}
            </P>
          </Flex>
        </>
      )}
    </Wrapper>
  );
};

export default ResponseHandler;
