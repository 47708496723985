import React from 'react';
import moment from 'moment';
import { useScreenSize } from 'hooks';
import { useTranslation } from 'react-i18next';

import { Flex, Paragraph as P } from 'components';
import { Spacer } from 'style';

interface Props {
  date: Date;
  dateFormat?: string;
  label?: string;
}

const LastUpdated = ({
  date,
  label,
  dateFormat = 'DD/MM/YYYY HH:mm:ss',
}: Props) => {
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  return (
    <Flex>
      <P size={isMobile ? 'small' : 'normal'} color="white" bold>
        {label ? label : t('common:last_update')}
      </P>
      <Spacer width={5} />
      <P
        size={isMobile ? 'small' : 'normal'}
        color="white"
        data-testid="last-update">
        {date ? moment(date).format(dateFormat) : t('common:loading')}
      </P>
    </Flex>
  );
};

export default LastUpdated;
