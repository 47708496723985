import React from 'react';

import { Flex, IcomoonIcons, Paragraph as P } from 'components';
import { theme, Spacer } from 'style';

import { InfoBoxContainer } from './infoBoxStyle';

interface InfoBoxProps {
  title: string;
  description: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ title, description }) => {
  return (
    <InfoBoxContainer>
      <Flex alignItems="center">
        <IcomoonIcons icon="alert-1" size={20} color="primary" />
        <Spacer width={5} />
        <P color={theme.colors.primary} fontWeight={600}>
          {title}
        </P>
      </Flex>
      <Spacer height={10} />
      <Flex>
        <P color={theme.colors.charcoal} size="small">
          {description}
        </P>
      </Flex>
    </InfoBoxContainer>
  );
};

export default InfoBox;
