import styled from '@emotion/styled';
import { theme, flexCenterAll, mq } from 'style';

interface ContainerProps {
  clickable?: boolean;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 32px 40px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: ${theme.colors.primary};
  &:hover {
    cursor: ${(props) => (props.clickable ? 'pointer' : '')};
  }
`;

export const NoAlertContainer = styled.div<ContainerProps>`
  padding: 32px 40px;
  border: 1px solid #5fa82a;
  width: 100%;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.05);
  ${flexCenterAll};
  height: 100%;
  text-align: center;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${mq.mobile()} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const NotificationContainer = styled.div`
  height: 56px;
  width: 56px;
  position: relative;
  border-radius: 56px;
  ${flexCenterAll};
  background-color: white;
`;
