import create from 'zustand';

const [usePageLoad] = create((set) => ({
  isFirstLoad: true,  
  setPageHasLoaded: () => {
    set({ isFirstLoad: false });
  },
}));

export default usePageLoad;
