import styled from '@emotion/styled';
import { theme, mq } from 'style';

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: stretch;
  > div {
    z-index: 700;
    position: absolute;
    border-radius: 4px;
    background-color: ${theme.colors.white};
    /* top: calc(100% + 15px);
    right: 0; */
    top: 0;
    right: 190px;
    padding: 20px 25px;
    white-space: nowrap;
    box-shadow: ${theme.boxShadow};
    cursor: pointer;

    ${mq.mobile()} {
      top: -200%;
    }
  }
`;
