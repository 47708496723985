import React from 'react';
import moment from 'moment';
import { TooltipPayload } from 'recharts';
import { useTranslation } from 'react-i18next';

import { Paragraph, Box } from 'components';

const CustomToolTip = ({
  payload,
  showSingleDay,
}: {
  payload?: TooltipPayload[];
  showSingleDay: boolean;
}) => {
  const { t } = useTranslation();
  const renderWeek = (item: any) => {
    const name = item?.name?.split('.')[0];
    return (
      <Paragraph size="small" key={name}>
        {name}:{' '}
        <span style={{ fontWeight: 'bold' }}>{Math.round(item?.value)}</span>
      </Paragraph>
    );
  };

  const renderSingleDay = (item: any) => {
    const name = item?.name?.split('.')[0];
    const vals = item?.payload[name];
    return (
      <>
        <Paragraph size="small">
          {t('spaceDetails:charts.traffic_history_chart.average')}:{' '}
          <span style={{ fontWeight: 'bold' }}>
            {Math.round(vals?.average)}
          </span>
        </Paragraph>
        <Paragraph size="small">
          {t('spaceDetails:charts.traffic_history_chart.toggle.high')}:{' '}
          <span style={{ fontWeight: 'bold' }}>{vals?.high}</span>
        </Paragraph>
        <Paragraph size="small">
          {t('spaceDetails:charts.traffic_history_chart.toggle.low')}:{' '}
          <span style={{ fontWeight: 'bold' }}>{vals?.low}</span>
        </Paragraph>
      </>
    );
  };

  if (payload) {
    return (
      <Box p={15} square>
        <Paragraph size="small" color="blueGrey">
          {moment(payload[0]?.payload.default).format('ddd DD MMM YYYY, HH:mm')}
        </Paragraph>
        {showSingleDay
          ? renderSingleDay(payload && payload[0])
          : payload?.map((item: any) => renderWeek(item))}
      </Box>
    );
  } else {
    return null;
  }
};

export default CustomToolTip;
