import styled from '@emotion/styled';
import { theme } from 'style';

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 84px;
  margin: 20px auto 20px;
  border-radius: 84px;
  background-color: ${theme.colors.lightBlueGrey};
`;

export const Header = styled.div`
  max-width: 390px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 40px;
  text-align: center;

  p {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const Heading = styled.div`
  margin-bottom: 20px;
`