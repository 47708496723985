import create from 'zustand';

interface LanguageI {
  lng: 'en' | 'de' | 'fr';
  toggleLng: (lang: 'en' | 'de' | 'fr') => void;
}

const [useLanguageStore] = create<LanguageI>((set, get) => ({
  lng: 'en',
  toggleLng: (lng: 'en' | 'de' | 'fr') => {
    set({ lng });
  },
}));

export default useLanguageStore;
