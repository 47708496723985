import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Paragraph as P, Heading as H, IcomoonIcons } from 'components';
import { Container, TextContainer } from './noNotificationStyle';
import { NotificationType } from 'models';
import { Spacer } from 'style';

const NoNotification = ({
  type = NotificationType.Alert,
  isOffline,
  isFollowing,
}: {
  type: NotificationType;
  isOffline?: boolean;
  isFollowing?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        {type === NotificationType.EntriWorX ? <></> :
          <IcomoonIcons
            icon={isOffline ? 'offline' : 'success'}
            color={isOffline ? 'grey' : 'success'}
            size={90}
          />}        
        <Spacer height={20} />
        <TextContainer>
          <H size="h6" color="primary" mr="8px">
            {isOffline
              ? t('operationalOverview:alerts_unavailable')
              : type === NotificationType.Alert
              ? t('operationalOverview:no_alert')
              : type === NotificationType.EntriWorX
              ? t('operationalOverview:no_mode_for_EntriWorX')
              : t('operationalOverview:no_mode_changes')}
          </H>
        </TextContainer>
        <TextContainer>
          <P size="normal" color="charcoal" textAlign="center" m="10px">
            {isOffline
              ? t('common:status_tooltip.offline_unavailable')
              : type === NotificationType.Alert
              ? t('operationalOverview:alerts_details')
              : type === NotificationType.EntriWorX
              ? t('operationalOverview:no_mode_for_EntriWorX_details')              
              : t('operationalOverview:mode_change_door_detail')}
          </P>
        </TextContainer>
      </Flex>
    </Container>
  );
};

export default NoNotification;
