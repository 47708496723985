import styled from '@emotion/styled';

import { theme, flexVertical } from 'style';

export const useDoorsTableStyle = () => {
  const Wrapper = styled.div`
    width: 100%;
    height: auto;
    margin-top: 15px;
    border: 1px solid ${theme.colors.lightBlueGrey};
    background-color: ${theme.colors.white};
    border-radius: 4px;
    overflow-x: auto;
  `;

  const Pagination = styled.div`
    height: 65px;
    padding: 0 12px;
    ${flexVertical};
    text-align: right;
    justify-content: flex-end;
    background-color: ${theme.colors.white};
    > p:first-child {
      margin-right: 12px;
    }
  `;

  const PaginationItem = styled.button`
    margin-left: 12px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    appearance: none;
    border: none;
    background-color: transparent;
  `;

  return {
    Wrapper,
    Pagination,
    PaginationItem
  };
};
