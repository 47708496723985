import React from 'react';
import { MarginProps } from 'styled-system';

import { ButtonVariants, BaseButton, LoadingWrapper } from './buttonStyle';
import { IcomoonIcons, Spinner, Grid } from 'components';
import { IcomoonIconNames } from 'components/IcomoonIcons/IcomoonIcons';

export interface ButtonProps extends MarginProps {
  /** Label for the Button to display */
  children: React.ReactNode;
  /** Function when the button is clicked */
  onClick?: () => void;
  /** The different button styles available */
  variant?: ButtonVariants;
  /** Type of button. Submit is for the FormWrapper */
  type?: 'submit' | 'reset' | 'button';
  /** Disabled the button  */
  disabled?: boolean;
  /** If true, button will fill container. If false, button will fit content */
  stretch?: boolean;
  /** Name of the Icon to use. Will inherit color. */
  icon?: IcomoonIconNames;
  iconSize?: number;
  /** Set the data attribute for cypress testing */
  cypress?: string;
  /** Boolean to show a button spinner */
  isLoading?: boolean;
  /** Override the button width */
  width?: number;
  /**Whether the colors of the buttons are inverted. Inverted buttons are designed to work on darker colored backgrounds */
  inverse?: boolean;
  style?: any;
}

/** The Basic Button */

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'contained',
  cypress,
  icon,
  iconSize,
  isLoading,
  inverse = false,
  ...rest
}: ButtonProps) => {
  return (
    <BaseButton
      isLoading={isLoading}
      variant={variant}
      hasChildren={!!children}
      inverse={inverse}
      {...rest}
      data-cypress={cypress}>
      {isLoading && (
        <LoadingWrapper>
          <Spinner />
        </LoadingWrapper>
      )}
      {/* DOES NOT RENDER PROPERLY ON MOBILE */}
      {/* <Grid 
        gridAutoFlow="column"
        gridAutoColumns={['', 'min-content']}
        gridColumnGap="7px">
        {icon && (
          <IcomoonIcons
            // color={rest.disabled ? iconDisabled(variant) : iconColor(variant)}
            size={iconSize}
            icon={icon}
          />
        )}
        {children}
      </Grid> */}
      {icon && (
        <span>
          <IcomoonIcons size={iconSize} icon={icon} />
        </span>
      )}
      <span>{children}</span>
    </BaseButton>
  );
};

export default Button;
