import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';

import { SpaceTrafficHistory } from 'models';
import {
  ChartContainer,
  Grid,
  SlideToggle,
  IcomoonIcons,
  Paragraph,
  BasicError,
} from 'components';

import TrafficHistoryChart from './TrafficHistoryChart/TrafficHistoryChart';
import TrafficHistoryTable from './TrafficHistoryTable/TrafficHistoryTable';

const generateTranslatedLineColors = (): any => {
  const locale = window.localStorage.getItem('i18nextLng') || 'en';
  const startOfWeek = moment()
    .locale(locale)
    .startOf('week');
  const endOfWeek = moment()
    .locale(locale)
    .endOf('week');
  const dates = [];
  const LINE_COLORS: string[] = [
    '#FFBB00',
    '#5FA82A',
    '#E4002B',
    '#003594',
    '#000000',
    '#A1A1A1',
    '#6789C6',
  ];

  for (let m = moment(startOfWeek); m.isBefore(endOfWeek); m.add(1, 'days')) {
    dates.push(m.format('dddd'));
  }

  const obj = {};
  dates.forEach((item: any, i: number) => {
    Object.assign(obj, { [item]: LINE_COLORS[i] });
  });
  return obj;
};

export const LINE_COLORS: any = generateTranslatedLineColors();
interface Props {
  data: SpaceTrafficHistory[];
  isLoading: boolean;
}
export interface FormattedReturn {
  date: Date | string;
  day: string;
  average: number;
  highest: number;
  highestAt: Date;
  lowest: number;
  lowestAt: Date;
}
const formattedData = (data: SpaceTrafficHistory[]): FormattedReturn[] => {
  const arr = data.map((item: SpaceTrafficHistory) => {
    const { average, high, highestTrafficAt, low, lowestTrafficAt } = item;

    const format = moment(item.date).format('dddd');

    return {
      date: item.date,
      day: format,
      average,
      highest: high,
      highestAt: highestTrafficAt,
      lowest: low,
      lowestAt: lowestTrafficAt,
    };
  });

  return arr;
};

const TrafficHistory = ({ data, isLoading }: Props) => {
  const { t } = useTranslation();
  const [dataScale, setDataScale] = useState<'low' | 'high' | 'average'>(
    'average',
  );
  const [timePeriod, setTimePeriod] = useState<'today' | 'week'>('today');
  const [selectedDay, setSelectedDay] = useState<any>();

  const ClearSelection = () => (
    <Grid
      gridTemplateColumns="min-content max-content"
      alignContent="center"
      gridColumnGap="10px"
      style={{ cursor: 'pointer', textDecoration: 'underline' }}
      onClick={() => setSelectedDay(undefined)}>
      <IcomoonIcons icon="refresh" color="primary" size={20} />
      <Paragraph size="small" color="primary" as="a">
        {t('spaceDetails:charts.daily_traffic_chart.clear_selection')}
      </Paragraph>
    </Grid>
  );

  return (
    <ErrorBoundary
      fallback={
        <BasicError
          heading="This tab has timed-out"
          subheading="Please refresh the page"
          shrink
        />
      }>
      <ChartContainer
        title={t('spaceDetails:charts.traffic_history')}
        toggle={
          <Grid
            gridTemplateColumns={
              !selectedDay
                ? ['1fr', 'repeat(2, 1fr)']
                : ['1fr', 'max-content 1fr']
            }
            gridAutoFlow={['row', 'column']}
            gridAutoRows="1fr"
            gridRowGap="10px"
            mb={['20px', 0]}
            gridColumnGap="35px">
            {selectedDay && <ClearSelection />}
            <SlideToggle
              items={[
                {
                  label: t(
                    'spaceDetails:charts.traffic_history_chart.toggle.average',
                  ),
                  callback: () => setDataScale('average'),
                },
                {
                  label: t(
                    'spaceDetails:charts.traffic_history_chart.toggle.high',
                  ),
                  callback: () => setDataScale('high'),
                },
                {
                  label: t(
                    'spaceDetails:charts.traffic_history_chart.toggle.low',
                  ),
                  callback: () => setDataScale('low'),
                },
              ]}
            />
            <SlideToggle
              items={[
                {
                  label: t(
                    'spaceDetails:charts.traffic_history_chart.toggle.today',
                  ),
                  callback: () => {
                    setTimePeriod('today');
                    setSelectedDay(undefined);
                  },
                },
                {
                  label: t(
                    'spaceDetails:charts.traffic_history_chart.toggle.week',
                  ),
                  callback: () => {
                    setTimePeriod('week');
                    setSelectedDay(undefined);
                  },
                },
              ]}
            />
          </Grid>
        }>
        <TrafficHistoryChart
          data={data}
          isLoading={isLoading}
          dataScale={dataScale}
          timePeriod={timePeriod}
          selectedDay={selectedDay?.values?.Day}
        />
        <TrafficHistoryTable
          data={formattedData(data)}
          timePeriod={timePeriod}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
        />
      </ChartContainer>
    </ErrorBoundary>
  );
};

export default TrafficHistory;
