import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import OperationalOverview from '../OperationalOverview/OperationalOverview';
import DoorCycles from '../DoorCycles/DoorCycles';
import { SectionHeader } from './insightsStyles';

import { Spacer } from 'style';
import { Heading as H, DateSlideToggle } from 'components';
import { SelectedDates, SelectedDateTypeEnum } from 'models';

interface InsightsProps {
  followedOnly: boolean;
  triggerUpdate?: boolean;
  accountId?: string;
}

const Insights = ({ followedOnly, triggerUpdate, accountId }: InsightsProps) => {
  const { t } = useTranslation();
  const [selectedDates, setSelectedDates] = useState<SelectedDates>({
    startDate: moment()
      .startOf('day')
      .format(),
    endDate: moment()
      .endOf('day')
      .format(),
  });

  const [selectedDateType, setSelectedDateType] = useState<SelectedDateTypeEnum>('now');

  // check if selected date is this month (to be used in summary to display correct tooltip)
  const dateChecker = (dateType: 'day' | 'week' | 'month') => {
    return (
      selectedDates.startDate ===
        moment()
          .startOf(dateType === 'week' ? 'isoWeek' : dateType)
          .format() &&
      selectedDates.endDate ===
        moment()
          .endOf(dateType === 'week' ? 'isoWeek' : dateType)
          .format()
    );
  };

  return (
    <>
      <SectionHeader>
        <H color="primary" size="h3" mb={['15px', 0]}>
          {t('dashboard:insights')}
        </H>
        <DateSlideToggle setDateRange={setSelectedDates} setDateType={setSelectedDateType} options={['now', 'day', 'week', 'month', 'custom']} />
      </SectionHeader>
      <OperationalOverview
        followedOnly={followedOnly}
        selectedDates={selectedDates}
        selectedDateType={selectedDateType}
        triggerUpdate={triggerUpdate}
        accountId={accountId}
      />
      <Spacer height={30} />
      <DoorCycles
        selectedDates={selectedDates}
        followedOnly={followedOnly}
        accountId={accountId}
        isToday={dateChecker('day')}
        isThisWeek={dateChecker('week')}
        isThisMonth={dateChecker('month')}
      />
    </>
  );
};

export default Insights;
