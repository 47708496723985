import styled from '@emotion/styled';
import { flexVertical, flexCenter, theme } from 'style';

interface PercentageCellProps {
  isZero: boolean;
}

export const PercentageCell = styled.span<PercentageCellProps>`
  ${flexCenter};
  width: 35px;
  padding: 6px 18px;

  font-size: 14px;

  color: ${({ isZero }) =>
    isZero ? theme.colors.grey : theme.colors.charcoal};
`;

export const BarChartWrapper = styled.span`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  border: 1px solid ${theme.colors.lightBlueGrey};
`;

interface BarChartSegmentProps {
  width: number;
  backgroundColor: string;
}
export const BarChartSegment = styled.div<BarChartSegmentProps>`
  height: '100%';
  width: ${(props) => `${props.width}%`};
  background-color: ${(props) => props.backgroundColor};
`;
