import React, { useEffect } from 'react';
import moment from 'moment';
import i18n from 'i18next';

import { Box, Paragraph } from 'components';
import { statusArray } from './SpaceDailyOccupancyStatus';
import { useTranslation } from 'react-i18next';

type StatusTuple = typeof statusArray;

export const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    moment.locale(i18n.language);
  }, []);

  if (active && payload) {
    const generateStatus = (status: StatusTuple[number]) => {
      switch (status) {
        case 'Offline':
          return t('spaceDetails:charts.daily_occupancy_chart.unavailable');
        case 'NotCommissioned':
          return 'Not Commissioned';
        default:
          return t(`spaceDetails:charts.daily_occupancy_chart.${status}`);
      }
    };

    return (
      <Box p={15} square>
        <Paragraph color="grey" size="small">
          {moment
            .unix(payload[0].payload.created)
            .format('ddd D MMM YYYY, HH:mm:ss')}
        </Paragraph>

        <Paragraph size="small" color="charcoal">
          {t('spaceDetails:charts.daily_occupancy_chart.status_from')}{' '}
          <span style={{ fontWeight: 'bold' }}>
            {generateStatus(payload[0].payload.statusFrom)}
          </span>
        </Paragraph>
        <Paragraph size="small" color="charcoal">
          {t('spaceDetails:charts.daily_occupancy_chart.status_to')}{' '}
          <span style={{ fontWeight: 'bold' }}>
            {generateStatus(statusArray[payload[0].payload.statusTo])}
          </span>
        </Paragraph>
        {payload[0].payload.data && (
          <>
            <Paragraph size="small" color="charcoal">
              {t('spaceDetails:charts.daily_occupancy_chart.occupancy')}{' '}
              <span style={{ fontWeight: 'bold' }}>
                {payload[0].payload.data.occupancyCount}
              </span>
            </Paragraph>
            <Paragraph size="small" color="charcoal">
              {t('spaceDetails:charts.daily_occupancy_chart.max_occupancy')}{' '}
              <span style={{ fontWeight: 'bold' }}>
                {payload[0].payload.data.maxOccupancyCount}
              </span>
            </Paragraph>
          </>
        )}
      </Box>
    );
  } else {
    return null;
  }
};
