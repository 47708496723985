import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { flexVertical, theme, mq, defaultBreakpoints } from 'style';

const border = theme.borders(1, 'lightBlueGrey');

interface LinkProps {
  isInList: boolean;
  borderRight?: boolean;
  isMobile: boolean;
  isActive: boolean;
  isHiddenRoute?: boolean;
  isBolded: boolean;
}

const applyIsActiveStyle = ({
  isActive,
  isBolded,
  isMobile,
}: {
  isActive: boolean;
  isBolded: boolean;
  isMobile: boolean;
}) => {
  if (isActive) {
    return css`
      @media (max-width: ${defaultBreakpoints.largeDown}) {
        background-color: ${theme.colors.lightGreyBlue};
        font-weight: ${isBolded && 'bold'};
      }
      border-right: ${isMobile ? '' : `4px solid ` + theme.colors.secondary};
      border-bottom: ${isMobile ? `4px solid ` + theme.colors.secondary : ''};
      font-weight: bold;
    `;
  }
};

const applyIsActiveInList = ({ isInList }: { isInList: boolean }) => {
  if (isInList) {
    return css`
      > a {
        justify-content: flex-start;
      }
      border-top: ${border};

      &:after {
        height: 100%;
        width: 3px;
        bottom: 0px;
      }
    `;
  }
};
export const StyledLink = styled.div<LinkProps>`
  box-sizing: border-box;
  position: relative;
  ${flexVertical}  
  padding: ${(props) => (props.isMobile ? '0 2px' : '0 10%')};
  border: ${border};  
  height: ${(props) => (props.isMobile ? '100%' : theme.vars.headerHeight)};
  cursor: pointer;  
  font-size: 14px;
  overflow-wrap: ${(props) => (props.isMobile ? '' : 'anywhere')}
  > a {
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    color: ${(props) =>
      props.isHiddenRoute
        ? `${theme.colors.charcoal} !important`
        : `${theme.colors.primary} !important`};
    ${flexVertical};
    > span {
      margin-left: 10px;
    }
  }

  ${applyIsActiveStyle};
  ${applyIsActiveInList};
`;

export const Icon = styled.div`
  width: 25px;
`;
