import { DataSeries } from "models";

const closestInteger = (value: number, devider = 4) => {
    const c1 = value - (value % devider);
    const c2 = (value + devider) - (value % devider);

    const precision = value.toString().length - (value < 100 ? 1 : 2);
    if (value - c1 > c2 - value) {
      return Math.ceil(c1/Math.pow(20, precision)) * Math.pow(20, precision);
    } else {
      return Math.ceil(c2/Math.pow(20, precision)) * Math.pow(20, precision);;
    }
  }

export const getTicksValue = (data: DataSeries[], key: string, hasNegetive: boolean) => {
    const maxValue = Math.max(...data?.map((item: any) => Math.abs(item[key] || 0)) || [0]);
    const valueLength = maxValue.toString().length - (maxValue < 100 ? 1 : 2);
    const maxCeil = Math.ceil(maxValue/Math.pow(10, valueLength)) * Math.pow(10, valueLength);
    const closestMaxCeil = closestInteger(maxCeil);

    return hasNegetive ? [-closestMaxCeil, -closestMaxCeil/2, 0, closestMaxCeil/2, closestMaxCeil] : [0, closestMaxCeil*.25, closestMaxCeil*.5, closestMaxCeil*.75, closestMaxCeil];
}