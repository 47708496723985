import React from 'react';
import * as Yup from 'yup';
import { useScreenSize } from "hooks";
import { useTranslation } from "react-i18next";
import { FormComponents, SelectField } from 'components';
import i18n from 'core/i18n/i18n';
import { DetailsContainer } from 'pages/UserDetails/userDetailsStyles';

const { screenSize } = useScreenSize();

export const Validation = Yup.object({
  userRole: Yup.string()
    .ensure()
    .required(i18n.t('forms:validation.required')),
});

export const Form = () => {
    const { t } = useTranslation();
    const { isMobile } = screenSize();
    return (
      <>
        <FormComponents.Section
          layout={isMobile ? 'single' : 'double'}
          heading={t('userDetails:details_section.personal_information.heading')}>
          <SelectField
            name="userRole"
            label={t('forms:personal_details.user_type')}
            options={[
              { value: 'Admin', label: i18n.t('userDetails:roles.admin') },
              { value: 'Viewer', label: i18n.t('userDetails:roles.viewer') },
            ]}
          />
        </FormComponents.Section>
      </>
    );
  };