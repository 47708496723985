import styled from '@emotion/styled';
import { mq, theme } from 'style';

export const DashboardContainer = styled.div`
  position: relative;
`;

export const SectionHeader = styled.div<{ isDIS: boolean }>`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 20px;

  ${mq.tablet} {
    display: flex;
    flex-direction: column;

    > div:nth-of-type(2) {
      margin-top: 10px;
      width: 350px;
    }
  }
  ${mq.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const RightHeader = styled.div`
  display: flex;
  ${mq.mobile} {
    display: block;
  }
  ${mq.tablet} {
    display: block;
  }
`;

export const DropdownContainer = styled.div`
  width: 40%;
  min-width: 154px;
  margin-right: 30px;
  ${mq.mobile} {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
    z-index: 2;
  }
  ${mq.tablet} {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  ${mq.largeTablet} {
    min-width: 450px;
  }
`;

export const AccountsContainer = styled.div`
  padding-bottom: 30px;
  border-bottom: ${theme.borders(1, `blueGrey`)};
  ${mq.largeTablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
  }
`;

export const AnnouncementsAction = styled.div`
  position: relative;
`;

export const UnreadAnnouncementIndicator = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: ${theme.colors.error};
  border-radius: 8px;
  top: 0px;
  right: -8px;
`;
