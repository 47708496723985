import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { StyledLink, Icon } from './navigationItemStyle';

import { useScreenSize } from 'hooks';
import { IcomoonIcons } from 'components';
import { IcomoonIconNames } from 'components/IcomoonIcons/IcomoonIcons';
interface NavigationItemProps {
  /** pathname for the item to navigate too on click */
  to: string;
  /** If the item needs an icon, pass the name */
  icon?: IcomoonIconNames;
  /** Label for the link. Should be the page name */
  label: string;
  /** Boolean if the item appears in the dropdown menu on desktop */
  isInList?: boolean;
  onClick?: () => void;
  iconSize?: number;
  isHiddenRoute?: boolean;
}

const { screenSize } = useScreenSize();
const NavigationItem: React.FC<NavigationItemProps> = ({
  to,
  icon,
  label,
  isInList,
  iconSize,
  isHiddenRoute,
  ...rest
}) => {
  const location = useLocation();
  const { isMobile } = screenSize();

  return (
    <StyledLink
      isInList={isInList || false}
      isMobile={isMobile}
      isHiddenRoute={isHiddenRoute}
      isActive={location.pathname === to}
      isBolded
      data-cypress="navigation-item"
      {...rest}
      data-testid="navigation-item">
      {to.startsWith('http') ? (
        <a href={to} target="_blank" rel="noopener noreferrer">
          {icon && (
            <Icon>
              {' '}
              <IcomoonIcons icon={icon} color="primary" size={iconSize} />
            </Icon>
          )}
          <span>{label}</span>
        </a>
      ) : (
        <Link to={to}>
          {icon && (
            <Icon>
              <IcomoonIcons icon={icon} color="primary" size={iconSize} />
            </Icon>
          )}
          <span>{label}</span>
        </Link>
      )}
    </StyledLink>
  );
};

export default NavigationItem;
