import styled from '@emotion/styled';
import { Grid } from 'components';
import { theme } from 'style';
import { Props } from './StatusIndicator';
const generateColor = (status: string) => {
  switch (status) {
    case 'error':
      return theme.colors.error;
    case 'warning':
      return theme.colors.warning;
    case 'success':
      return theme.colors.success;
    case 'offline':
      return theme.colors.grey;
  }
};

export const Indicator = styled.div<Pick<Props, 'status' | 'indicatorColor'>>`
  background-color: ${(props) =>
    props.status ? generateColor(props.status) : props.indicatorColor};
  height: 11px;
  width: 11px;
  border-radius: 100%;
`;
