import styled from '@emotion/styled';
import { theme, inputDefaults } from 'style';

interface InputProps {
  disabled?: boolean;
}

export const StyledSelect = styled.select<InputProps>(
  {
    width: '100%',
    boxSizing: 'border-box',
    padding: '15px 18px',
    border: 'none',
    outline: 'none',
    borderRadius: 0,
  },
  (props) => ({
    backgroundColor: props.disabled ? theme.colors.lightGrey : 'white',

    border: props.disabled
      ? `1px solid ${theme.colors.lightBlueGrey}`
      : theme.borders(1, 'blueGrey'),
  }),
);

export const selectStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    backgroundColor: state.isSelected ? theme.colors.lightBlueGrey : '',
    textAlign: 'left',
    cursor: 'pointer',
    fontFamily: 'Poppins',
    borderRadius: 0,
    color: theme.colors.charcoal,
    padding: '15px 20px',
    '&:hover': {
      backgroundColor: theme.colors.lightBlueGrey,
    },
  }),
  container: (base: any) => ({
    ...base,
    width: '100%',
  }),
  menuList: (base: any) => ({
    ...base,
    border: theme.borders(1, 'lightBlueGrey'),
    borderTop: 'none',
    top: '0 !important',
    fontSize: '14px',
    lineHeight: '21px',
    borderRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    padding: 0,
  }),
  menu: (base: any) => ({
    ...base,
    marginTop: '0',
    boxShadow: 'none',
  }),
  control: (base: any, state: any) => ({
    ...base,
    ...inputDefaults,
    borderRadius: state.selectProps.menuIsOpen ? '4px 4px 0px 0px' : 4,
    marginBottom: 0,
    width: '100%',
    fontSize: '14px',
    textAlign: 'left',
    cursor: 'pointer',
    border: state.isDisabled
      ? theme.borders(1, 'lightGrey')
      : theme.borders(1, 'lightBlueGrey'),
    '&:hover': { border: theme.borders(1, 'lightBlueGrey') },
    backgroundColor: state.isDisabled
      ? theme.colors.lightGrey
      : theme.colors.white,
    outline: 'none',
    boxShadow: 'none',
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transition: 'all .2s ease',
    marginRight: 2,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none',
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: 0,
    paddingLeft: 2,
    paddingTop: 2,
    paddingBottom: 2,
    lineHeight: 1.4,
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};
