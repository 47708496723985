import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { ThemeProvider } from 'emotion-theming';
import { useTopBar } from 'hooks';
import upperFirst from 'lodash/upperFirst';
import { useIdleTimer } from 'react-idle-timer';

import Pages from 'pages';

import { theme, GlobalStyle } from 'style';
import { useScreenSize } from 'hooks';
import { useWebSocketMessageStore } from 'stores';
import { SignOut } from 'core/auth/auth';
import Maintenance from 'pages/Maintenance/Maintenance';

// Add screen resize event listener
const { screenSizeStore } = useScreenSize();
window.addEventListener(
  'resize',
  debounce(
    () => {
      screenSizeStore.getState().updateValues();
    },
    100,
    { leading: true, maxWait: 500 },
  ),
);

setTimeout(() => {
  screenSizeStore.getState().updateValues();
}, 500);

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE || false;

const App = () => {
  const { pathname } = useLocation();
  const { setTitle } = useTopBar();

  const handleOnIdle = () => {
    SignOut();
    window.location.reload();
  };

  const handleOnAction = () => {
    reset();
  };
  const TIMEOUT = 1000 * 60 * 120;

  const { reset } = useIdleTimer({
    timeout: TIMEOUT,
    events: ['click', 'keydown'],
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
  });

  useEffect(() => {
    setTitle(upperFirst(pathname.split('/')[1]).replace('-', ' '));
  }, [pathname]);

  const { lastMessage } = useWebSocketMessageStore();
  useEffect(() => {
    if (lastMessage?.Event === 'UserDeactivated') {
      SignOut();
      window.location.reload();
    }
  }, [lastMessage]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ScrollToTop />
      { maintenanceMode ? <Maintenance/> : <Pages />}
    </ThemeProvider>
  );
};

export default App;
