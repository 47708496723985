import styled from '@emotion/styled';
import { theme, flexVertical, mq } from 'style';
import { TabList } from 'react-tabs';

export const SpaceOccupancyWrapper = styled.div`
  padding: 50px;
`;

export const SpaceOccupancyGradient = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  background: linear-gradient(90deg, #5fa82a 0%, #ffbb00 85%, #e4002b 100%);
`;

interface PercentageLayerProps {
  width: number;
}
export const PercentageLayer = styled.div<PercentageLayerProps>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: ${(props) => `${props.width}%`};
  background-color: ${theme.colors.lightGrey};
`;

export const ActionListButton = styled.div`
  position: relative;
  > div {
    position: absolute;
    border-radius: 4px;
    background-color: ${theme.colors.white};
    /* top: calc(100% + 15px);
    right: 0; */
    top: 0;
    right: 190px;
    padding: 15px;
    white-space: nowrap;
    box-shadow: ${theme.boxShadow};
  }
`;

export const TabsWrapper = styled.div`
  .react-tabs__tab {
    list-style: none;
    margin: 0;
    padding: 0 0 13px 0;
    color: ${theme.colors.charcoal};
    font-weight: 600;
    cursor: pointer;
    ${flexVertical};
    margin-right: 35px;
    /* &:first-of-type {
      margin-right: 35px;
    } */
  }

  .react-tabs__tab-list {
    display: flex;
    border-bottom: ${theme.borders(1, 'lightBlueGrey')};
    margin-bottom: 30px;
  }

  .react-tabs__tab--selected {
    border-bottom: 3px solid ${theme.colors.primary};
    color: ${theme.colors.primary};
  }
`;

export const NotCommissioned = styled.div`
  color: white;
  background-color: ${theme.colors.error};
  height: 28px;
  border-radius: 14px;
`;

export const SectionHeader = styled.div`
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-template-rows: 1fr;

  ${mq.tablet} {
    display: flex;
    flex-direction: column;
  }
  ${mq.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const SectionHeaderActions = styled.div`
  display: flex;
  ${mq.tablet} {
    margin-top: 15px;
  }
  ${mq.mobile} {
    margin-top: 15px;
  }

  ${mq.smallMobile} {
    display: block;
    button {
      width: 100%;
    }
    > p {
      margin-left: 0;
      margin-top: 15px;
    }
  }

  ${mq.largeTablet} {
    justify-content: flex-end;
  }
`;
