import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BasicError } from 'components';

interface Props {}

const Error404: React.FC<Props> = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <BasicError
      heading={t('common:error404.heading')}
      subheading={t('common:error404.subheading')}
      action={() => history.push('/dashboard')}
      actionLabel={t('common:error404.action_label')}
    />
  );
};

export default Error404;
