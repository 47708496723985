import styled from '@emotion/styled';
import { flexCenter } from 'style';

export const Wrapper = styled.div`
  ${flexCenter}
  /* justify-content: space-around; */
  width: 460px;
  /* height: calc(490px - 110px); */
  margin: 0 auto;
  padding: 55px;
  flex-direction: column;
  background-color: white;
`;

export const Heading = styled.div`
  text-align: center;
  ${flexCenter}
  align-items: center;
  flex-direction: column;
  p:first-of-type {
    margin-bottom: 6px;
  }
  p:last-child {
    /* width: 240px; */
  }
`;
