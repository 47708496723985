import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserState } from 'models';
import { isObjectEmpty } from 'utils';
import { Grid, Paragraph as P, StatusIndicator, LastUpdated } from 'components';

const HeaderStatus = ({ state }: { state: UserState }) => {
  const { t } = useTranslation();
  return isObjectEmpty<UserState>(state) ? null : (
    <Grid gridAutoColumns="max-content" horizontal gridColumnGap="8px">
      <StatusIndicator
        label={
          state.status == 'Active'
            ? t('userDetails:page_header.status.active')
            : state.status == 'Pending'
            ? t('userDetails:page_header.status.pending')
            : state.status === 'VerificationRequired'
            ? t('userDetails:page_header.status.verification_required')
            : t('userDetails:page_header.status.inactive')
        }
        status={
          state.status === 'Active'
            ? 'success'
            : state.status === 'Pending' ||
              state.status === 'VerificationRequired'
            ? 'warning'
            : 'offline'
        }
        labelColor="white"
      />
    </Grid>
  );
};

export default HeaderStatus;
