import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';

import { PasswordSet } from 'pages/Activate/forms';

import { ForgotPasswordSubmit } from 'core/auth/auth';
import { ForgotPasswordSubmit as ForgotPasswordSubmitModel } from 'models';
import { errorTranslate } from 'core/auth/auth';
import {
  Button,
  Flex,
  ResponseHandler,
  SmallFormContainer,
  Spinner,
  TextField,
  Paragraph as P,
  FullScreenContainer,
} from 'components';

import { PasswordResetRequest } from 'pages/Login/forms';
import { Spacer } from 'style';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const validationSchema = Yup.object({
  username: Yup.string().required('Email for your account is required'),
  password: Yup.string()
    // .matches(passwordRegex, i18n.t('form_validation.criteria'))
    .min(8, i18n.t('forms:validation.password_minimum'))
    .matches(
      new RegExp('(?=.*[a-z])'),
      i18n.t('forms:validation.password_lowercase'),
    )
    .matches(
      new RegExp('(?=.*[A-Z])'),
      i18n.t('forms:validation.password_uppercase'),
    )
    .matches(
      new RegExp('(?=.*[0-9])'),
      i18n.t('forms:validation.password_number'),
    )
    .matches(new RegExp(/[^\w\s]/), i18n.t('forms:validation.password_special'))
    .required(i18n.t('forms:validation.password_required')),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      i18n.t('forms:validation.password_match'),
    )
    .required(i18n.t('forms:validation.password_confirm')),
});
export type ValidationSchema = Yup.InferType<typeof validationSchema>;
export let initialValues: any = {
  username: '',
  password: '',
};

const PasswordReset: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const history = useHistory();
  const { t } = useTranslation();

  const query = useQuery();
  const code = query.get('code') || '';
  const lang = query.get('lang') || '';
  const username = query.get('username') || '';

  useEffect(() => {
    if (username) {
      initialValues = { ...initialValues, username: username };
    }

    i18n.changeLanguage(lang);
    setFormSettings({
      mainHeading: t('loginActivate:reset_password.heading'),
      subHeading: t('loginActivate:reset_password.subheading'),
    });

    setIsLoading(false);
  }, [lang]);

  const [errorSettings, setErrorSettings] = useState<
    PasswordSet.ResponseSettings
  >({
    mainHeading: t('loginActivate:reset_password.error.heading'),
    subHeading: t('loginActivate:reset_password.error.subheading'),
    actionLabel: t('loginActivate:reset_password.error.action_label'),
    action: () => console.log('Support'),
  });

  const [formSettings, setFormSettings] = useState<PasswordSet.DefaultSettings>(
    {} as PasswordSet.DefaultSettings,
  );

  const successSettings: PasswordSet.ResponseSettings = {
    mainHeading: t('loginActivate:reset_password.success.heading'),
    actionLabel: t('loginActivate:reset_password.success.action_label'),
    action: () => history.push('/loading'),
  };

  const [formState, setFormState] = useState<
    'default' | 'successful' | 'error' | 'request'
  >('default');

  const onSubmit = async (
    values: any, // The schema exported is not dynamic...
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (fields: { [field: string]: string }) => void,
  ) => {
    setSubmitting(true);
    try {
      const payload: ForgotPasswordSubmitModel = {
        username: username,
        code: code,
        new_password: values.confirmPassword,
      };
      await ForgotPasswordSubmit(payload);
      return 'successful';
    } catch (err) {
      console.log(err);
      const { code } = err;
      if (code === 'NotAuthorizedException') {
        setErrors({ confirmPassword: errorTranslate(code) });
      }
      if (code === 'InvalidPasswordException') {
        setErrors({ confirmPassword: errorTranslate(code) });
      }
      if (code === 'ExpiredCodeException') {
        setFormSettings({
          mainHeading: t('cognitoErrors:ExpiredCode'),
          subHeading: t('cognitoErrors:ExpiredCodeResend'),
        });
        return 'request';
      }
      if (code === 'CodeMismatchException') {
        setErrorSettings({
          mainHeading: t('loginActivate:reset_password.error.heading'),
          subHeading: errorTranslate(code),
          actionLabel: t('loginActivate:reset_password.error.action_label'),
          action: () => setFormState('default'),
        });
        return 'error';
      }
    } finally {
      setSubmitting(false);
    }
  };
  const RenderState = () => {
    switch (formState) {
      case 'default':
        return (
          <SmallFormContainer
            mainHeading={formSettings.mainHeading}
            subHeading={formSettings.subHeading}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                onSubmit(values, setSubmitting, setErrors)
                  .then((res: any) => {
                    console.log('pw set form: ', res);
                    if (res === 'successful') {
                      setFormState('successful');
                    }
                    if (res === 'error') {
                      setFormState('error');
                    }
                    if (res === 'request') {
                      setFormState('request');
                    }
                  })
                  .catch((err: any) => console.log(err));
              }}>
              {(props) => {
                return (
                  <FormikForm data-cypress="confirm-password-form">
                    <Flex
                      stretch
                      justifyContent="center"
                      flexDirection="column">
                      <TextField
                        name="username"
                        label={t('forms:email')}
                        type="email"
                        placeholder={t('forms:email')}
                        disabled
                      />
                      <TextField
                        name="password"
                        label={t('loginActivate:set_password.password')}
                        type="password"
                        placeholder={t(
                          'loginActivate:set_password.placeholder.password',
                        )}
                        cypress="onboarding-password-input"
                      />

                      <TextField
                        name="confirmPassword"
                        label={t('loginActivate:set_password.confirm_password')}
                        type="password"
                        placeholder={t(
                          'loginActivate:set_password.placeholder.confirm_password',
                        )}
                        cypress="onboarding-password-confirm"
                      />

                      <P size="small" color="charcoal">
                        {t('loginActivate:set_password.password_requirements')}
                      </P>

                      <Spacer height={25} />
                      <Button
                        type="submit"
                        disabled={
                          !(props.isValid && props.dirty) || props.isSubmitting
                        }
                        isLoading={props.isSubmitting}
                        cypress="form-wrapper-submit">
                        {t('loginActivate:set_password.set_password')}
                      </Button>
                    </Flex>
                  </FormikForm>
                );
              }}
            </Formik>
          </SmallFormContainer>
        );
      case 'successful':
        return (
          <ResponseHandler
            cypress="confirm-password-success"
            mainHeading={successSettings.mainHeading}
            subHeading={successSettings.subHeading}
            status="success"
            action={successSettings.action}
            actionLabel={successSettings.actionLabel}
          />
        );
      case 'error':
        return (
          <ResponseHandler
            cypress="confirm-password-error"
            mainHeading={errorSettings.mainHeading}
            subHeading={errorSettings.subHeading}
            status="error"
            action={errorSettings.action}
            actionLabel={errorSettings.actionLabel}
          />
        );
      case 'request':
        return <PasswordResetRequest.Form headerSettings={formSettings} />;
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <FullScreenContainer>{RenderState()}</FullScreenContainer>
  );
};

export default PasswordReset;
