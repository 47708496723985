import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'config';

import { Wrapper } from './statusDisplayModalStyle';
import { CopyItemWrapper, Instructions } from './components';
import createMailTo from './utils/createMailTo';

import {
  Button,
  Paragraph as P,
  Flex,
  Modal,
  IcomoonIcons,
  Grid,
} from 'components';
import { fetchExternalDisplayPin } from 'services';
import { useModalStore, useSpaceDetailsStore } from 'stores';
import { User } from 'models';

interface Props {
  spaceId: string;
}

const StatusDisplayModal = ({ spaceId }: Props) => {
  const { t } = useTranslation();
  const { statusDisplayModal } = useModalStore();
  const { spaceDetailsData } = useSpaceDetailsStore();
  const [userMe, setUserMe] = useState<User>();
  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  const [pinIsLoading, setPinIsLoading] = useState<boolean>(true);
  const [oneTimePin, setOneTimePin] = useState<string>('');
  const generatePin = async () => {
    setPinIsLoading(true);
    try {
      const res = await fetchExternalDisplayPin(spaceId);
      if (res) {
        setOneTimePin(res.pin);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setPinIsLoading(false);
    }
  };

  useEffect(() => {
    if (statusDisplayModal.isOpen && spaceId) {
      generatePin();
    }
  }, [statusDisplayModal.isOpen, spaceId]);

  const Actions = () => {
    return (
      <Grid
        gridTemplateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        gridGap={20}>
        <Button
          icon="upload"
          variant="outlined"
          iconSize={20}
          disabled={oneTimePin === '' ? true : false}
          onClick={() =>
            createMailTo({
              name: spaceDetailsData.details.spaceDisplayName,
              identifier: spaceId,
              pin: oneTimePin,
              email: userMe?.email || '',
            })
          }>
          {t('spaceDetails:modals.status_display.buttons.email')}
        </Button>
        <Button onClick={statusDisplayModal.toggleModal}>
          {t('spaceDetails:modals.status_display.buttons.close')}
        </Button>
      </Grid>
    );
  };

  return (
    <Modal
      isOpen={statusDisplayModal.isOpen}
      heading={t('spaceDetails:modals.status_display.title')}
      toggleModal={statusDisplayModal.toggleModal}
      actions={<Actions />}>
      <P color="charcoal" mb={20} size="small">
        {t('spaceDetails:modals.status_display.heading')}
      </P>

      <Wrapper>
        <CopyItemWrapper
          isLoading={false}
          heading={t('spaceDetails:modals.status_display.identifier')}
          content={spaceId}
        />
        <CopyItemWrapper
          isLoading={pinIsLoading}
          heading={t('spaceDetails:modals.status_display.code')}
          content={oneTimePin}
          refresh={generatePin}
        />
      </Wrapper>

      <Flex alignItems="center" mb={25}>
        <IcomoonIcons icon="info" size={16} />
        <P color="charcoal" size="small" margin={0} ml={10}>
          {t('spaceDetails:modals.status_display.tooltip')}
        </P>
      </Flex>

      <Instructions />
    </Modal>
  );
};

export default StatusDisplayModal;
