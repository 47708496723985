import create from 'zustand';
import { fetchDoorCycles } from 'services';
import { DoorCycles, SelectedDates } from '../models';
import axios from 'axios';

interface DoorCyclesStore {
  doorCyclesData: DoorCycles;
  isLoading: boolean;
  pendingRequests: number;
  doorCyclesFetch: (
    selectedDates: SelectedDates,
    followedOnly: boolean,
    doorId: string | undefined,
    accountId?: string,
  ) => void;
  cleanUpDoorCycleData: () => void;
}

const [useDoorCyclesStore] = create<DoorCyclesStore>((set, get) => ({
  doorCyclesData: {} as DoorCycles,
  isLoading: true,
  pendingRequests: 0,
  doorCyclesFetch: async (selectedDates, followedOnly, doorId, accountId) => {
    try {
      set({ isLoading: true, pendingRequests: get().pendingRequests + 1 });
      const res = await fetchDoorCycles(
        selectedDates.startDate as string,
        selectedDates.endDate as string,
        followedOnly,
        doorId,
        accountId,
      );

      const doorCycles = await res;

      set({
        doorCyclesData: doorCycles as DoorCycles,
        isLoading: false,
        pendingRequests: get().pendingRequests - 1,
      });
    } catch (err) {
      set({
        doorCyclesData: {} as DoorCycles,
        isLoading: axios.isCancel(err) ? true : false,
        pendingRequests: get().pendingRequests - 1,
      });
      throw err;
    }
  },
  cleanUpDoorCycleData: () => {
    set({
      doorCyclesData: {} as DoorCycles,
      isLoading: true,
    });
  },
}));

export default useDoorCyclesStore;
