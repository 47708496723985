import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledWrapper } from './actionListButtonStyle';

import { Button, Paragraph as P } from 'components';
import { useModalStore } from 'stores';
import { useScreenSize } from 'hooks';
import { isDI, isDIS, isDualProducts } from 'utils';

interface Props {
  allowRemoteOpen: boolean;
  isDisabled: boolean;
  allowResetDevice: boolean;
  allowViewDoorState: boolean;
  allowClearErrorLog: boolean;
  allowResetOperator: boolean;
  allowRemoteLock: boolean;
  allowRemoteUnlock: boolean;
  itemCount: number;
}

const ActionsListButton = ({
  allowRemoteOpen,
  isDisabled,
  allowResetDevice,
  allowClearErrorLog,
  allowResetOperator,
  allowViewDoorState,
  allowRemoteLock,
  allowRemoteUnlock,
  itemCount,
}: Props) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const toggleIsVisible = () => setIsVisible(!isVisible);
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const {
    doorStateModal,
    requestRemoteOpenModal,
    resetIoTDeviceModal,
    resetOperatorModal,
    clearOperatorErrorLogModal,
    requestRemoteLockModal,
    requestRemoteUnlockModal,
  } = useModalStore();

  const ref = useRef<HTMLDivElement>(null);

  return (
    <StyledWrapper itemCount={itemCount}>
      <Button
        inverse={!isMobile}
        width={isMobile ? 160 : 200}
        iconSize={20}
        stretch
        icon="more-vertical1"
        disabled={isDisabled}
        style={{
          paddingLeft:
            isDIS() || isDI() || isDualProducts() ? (isMobile ? 10 : 15) : '',
          paddingRight:
            isDIS() || isDI() || isDualProducts() ? (isMobile ? 10 : 15) : '',
        }}
        onClick={toggleIsVisible}>
        {isDIS() || isDI() || isDualProducts()
          ? t('doorDetails:page_header.buttons.remote_actions')
          : t('doorDetails:page_header.buttons.actions')}
      </Button>
      {isVisible && (
        <div ref={ref}>
          {(isDIS() || isDI() || isDualProducts()) && (
            <>
              {allowRemoteOpen && (
                <P
                  color={allowRemoteOpen ? 'charcoal' : 'grey'}
                  onClick={() => {
                    if (!allowRemoteOpen) return;
                    requestRemoteOpenModal.toggleModal();
                    toggleIsVisible();
                  }}
                  pb={20}>
                  {t('doorDetails:page_header.actions.open_door')}
                </P>
              )}
              {allowResetDevice && (
                <P
                  color={allowResetDevice ? 'charcoal' : 'grey'}
                  onClick={() => {
                    if (!allowResetDevice) return;
                    resetIoTDeviceModal.toggleModal();
                    toggleIsVisible();
                  }}
                  pb={20}>
                  {t('doorDetails:page_header.actions.reset_iot_device')}
                </P>
              )}
              {allowRemoteLock && (
                <P
                  color={allowRemoteLock ? 'charcoal' : 'grey'}
                  onClick={() => {
                    if (!allowRemoteLock) return;
                    requestRemoteLockModal.toggleModal();
                    toggleIsVisible();
                  }}
                  pb={20}>
                  {t('doorDetails:page_header.actions.remote_lock')}
                </P>
              )}
              {allowRemoteUnlock && (
                <P
                  color={allowRemoteUnlock ? 'charcoal' : 'grey'}
                  onClick={() => {
                    if (!allowRemoteUnlock) return;
                    requestRemoteUnlockModal.toggleModal();
                    toggleIsVisible();
                  }}
                  pb={20}>
                  {t('doorDetails:page_header.actions.remote_unlock')}
                </P>
              )}
              {allowResetOperator && (
                <P
                  color={allowResetOperator ? 'charcoal' : 'grey'}
                  onClick={() => {
                    if (!allowResetOperator) return;
                    resetOperatorModal.toggleModal();
                    toggleIsVisible();
                  }}
                  pb={20}>
                  {t('doorDetails:page_header.actions.reset_operator')}
                </P>
              )}
              {allowViewDoorState && (
                <P
                  color={allowViewDoorState ? 'charcoal' : 'grey'}
                  onClick={() => {
                    if (!allowViewDoorState) return;
                    doorStateModal.toggleModal();
                    toggleIsVisible();
                  }}
                  pb={20}>
                  {t('doorDetails:page_header.actions.view_door_state')}
                </P>
              )}
              {allowClearErrorLog && (
                <P
                  color={allowClearErrorLog ? 'charcoal' : 'grey'}
                  onClick={() => {
                    if (!allowClearErrorLog) return;
                    clearOperatorErrorLogModal.toggleModal();
                    toggleIsVisible();
                  }}
                  pb={20}>
                  {t('doorDetails:page_header.actions.clear_error_log')}
                </P>
              )}
            </>
          )}
        </div>
      )}
    </StyledWrapper>
  );
};

export default ActionsListButton;
