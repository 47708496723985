import styled from "@emotion/styled";
import { ThemeType, flexCenterAll, mq, theme } from "style";
type ContainerProps = {
  theme: ThemeType;
  isZero: boolean;
};

export const Container = styled.div<ContainerProps>`
  padding: 40px;
  cursor: pointer;
  pointer-events: ${({ isZero }) => (isZero ? "none" : "auto")};
  &:hover {
    box-shadow: ${theme.boxShadow};
  }

  &:nth-of-type(-n + 2) {
    border-bottom: 1px solid ${(props) => props.theme.colors.lightBlueGrey};
    ${mq.mobile()} {
      border-bottom: none;
    }
  }
  &:nth-of-type(odd) {
    border-right: 1px solid ${(props) => props.theme.colors.lightBlueGrey};
    ${mq.mobile()} {
      border-right: none;
    }
  }

  ${mq.mobile()} {
    display: flex;
    padding: 16px;
    border-left: none;
    border-top: 1px solid ${(props) => props.theme.colors.lightBlueGrey};
  }
`;

type ColorProps = {
  color: string;
  theme: any;
  isLoading?: boolean;
};

export const MobileFlare = styled.div<ColorProps>`
  height: 16px;
  width: 16px;
  border-radius: 2px;
  margin-right: 10px;
  background-color: ${({ color, theme }) => theme.colors[color]};
`;

export const IconContainer = styled.div<ColorProps>`
  position: relative;
  ${flexCenterAll};
  height: 68px;
  width: 68px;
  border-radius: 15px;
  background-color: ${({ color, theme }) => theme.colors[color] + 30};
`;
