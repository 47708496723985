import styled from '@emotion/styled';
import {
  space,
  layout,
  variant,
  border,
  SpaceProps,
  LayoutProps,
  BorderProps,
  VariantArgs,
} from 'styled-system';
import { theme } from 'style';

export type T = React.HTMLAttributes<HTMLElement> &
  SpaceProps &
  LayoutProps &
  BorderProps &
  VariantArgs & { variant?: 'rectangle' | 'circle'; elevated?: boolean };

const Pulse = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #f5f5f7 0%, #e3e3e3 50%, #f5f5f7 100%);
  background-size: 400% 400%;

  animation: animate 1.2s ease-in-out infinite;
  @keyframes animate {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

const Skeleton = styled(Pulse)<T>`
  height: 32px;
  box-shadow: ${(props) => (props.elevated ? theme.boxShadow : 'none')};
  ${variant({
    variants: {
      rectangle: {
        borderRadius: '4px',
      },
      circle: {
        borderRadius: '100%',
        width: '32px',
      },
    },
  })};
  ${space};
  ${layout};
  ${border};
`;

Skeleton.defaultProps = {
  variant: 'rectangle',
};

export default Skeleton;
