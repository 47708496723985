import styled from '@emotion/styled';
import { mq } from 'style';

export const SectionHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 20px;

  ${mq.tablet} {
    display: flex;
    flex-direction: column;

    > div:nth-of-type(2) {
      margin-top: 10px;
      width: 350px;
    }
  }
  ${mq.mobile} {
    display: flex;
    flex-direction: column;
  }
`;
