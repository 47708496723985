import styled from '@emotion/styled';
import { flexCenterAll, mq, theme } from 'style';

export const UserOnboardingContainer = styled.div`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: none;
  }
  display: grid;
  grid-template-rows: 70px auto 100px;

  > div:nth-of-type(2) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
`;

export const LogoWrapper = styled.div`
  ${flexCenterAll}
  width: 100%;
  height: 70px;
`;

export const InnerContainer = styled.div`
  ${flexCenterAll}
  width: 100%;
  margin-top: 20px;
`;

export const FormContainer = styled.div`
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  box-shadow: ${theme.boxShadow};

  ${mq.mobile} {
    /* padding: 25px; */
  }
`;
