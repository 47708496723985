import React from 'react';
import { Skeleton } from 'components';
import { useScreenSize } from 'hooks';

const DonutChartSkeleton = () => {
  const { screenSize } = useScreenSize();
  const { isWideDesktop } = screenSize();
  return (
    <Skeleton
      size={isWideDesktop ? '320px' : '280px'}
      variant="circle"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          background: 'white',
          height: isWideDesktop ? '220px' : '180px',
          width: isWideDesktop ? '220px' : '180px',
          borderRadius: '100%',
        }}></div>
    </Skeleton>
  );
};
export default DonutChartSkeleton;
