import styled from '@emotion/styled';
import { gridColumn, gridRow } from 'styled-system';

import { flexVertical, flexCenter, theme, flexCenterAll } from 'style';

export const useTableStyle = (columns: string) => {
  const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    border: 1px solid ${theme.colors.lightBlueGrey};
    background-color: ${theme.colors.white};
    border-radius: 4px;
  `;

  const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: auto;
  `;

  const Table = styled.table<{ minWidth: number }>`
    width: 100%;
    min-width: ${(props) => props.minWidth ? props.minWidth + 'px' : '0'};
    border-radius: 4px;
  `;

  const TableHead = styled.thead`
    background-color: 'transparent';
    border-radius: 4px;
  `;

  const TableHeadRow = styled.tr`
    display: grid;
    grid-template-columns: ${columns};
    padding: 18px 0px 18px 30px;
    border-bottom: 1px solid ${theme.colors.lightBlueGrey};
    border-radius: 4px 4px 0px 0px;
  `;

  const TableBody = styled.tbody`
    background-color: ${theme.colors.white};
  `;

  const TableRow = styled.tr<{ isActive: boolean; isClickable: boolean }>`
    height: 70px;
    cursor: ${({isClickable}) => isClickable  ? 'pointer' : 'initial'};
    display: grid;
    grid-template-columns: ${columns};
    padding: 0px 0px 0px 30px;
    border-bottom: 1px solid ${theme.colors.lightBlueGrey};
    background-color: ${(props) =>
      props.isActive ? theme.colors.lightBlueGrey : theme.colors.white};

    &:hover {
      background-color: ${({isClickable}) => isClickable  ? theme.colors.lightGreyBlue : theme.colors.white};
    }
  `;

  const HeaderCell = styled.th`
    text-transform: uppercase;
    font-size: 14px;
    ${flexVertical};
    color: ${theme.colors.grey};
  `;

  const BodyCell = styled.td`
    color: ${theme.colors.charcoal};
    font-size: 14px;
    ${flexVertical};
  `;

  const Pagination = styled.div`
    height: 65px;
    padding: 0 12px;
    ${flexVertical};
    text-align: right;
    justify-content: flex-end;
    background-color: ${theme.colors.white};
    > p:first-child {
      margin-right: 12px;
    }
  `;

  const PaginationItem = styled.button`
    margin-left: 12px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    appearance: none;
    border: none;
    background-color: transparent;
  `

  return {
    MainWrapper,
    Wrapper,
    Table,
    TableHead,
    TableHeadRow,
    TableBody,
    TableRow,
    HeaderCell,
    BodyCell,
    Pagination,
    PaginationItem,
  };
};
