import axios, { CancelTokenSource } from 'axios';
import axiosRetry from 'axios-retry';
import { SignOut } from 'core/auth/auth';
import { Auth } from 'aws-amplify';
import config from 'config';

export const instance = axios.create({
  baseURL: config.API,
  timeout: 1000 * 300,
  headers: {},
});

const signOutRedirect = () => {
  SignOut();
  window.location.reload();
};
// Request interceptor
instance.interceptors.request.use(
  async (config) => {
    const session = await Auth.currentSession();
    if (!session.isValid()) {
      console.log('Signing Out On request...');
      signOutRedirect();
    }
    const token = await session.getAccessToken().getJwtToken();
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function(error) {
    // If the token that was applied above is expried, catch the 401 and sign out
    console.log('[RESPONSE INTERCEPT] error: ', error);
    // debugger;

    if (error === 'No current user') {
      console.log('Signing Out In the Response Intercept');
      signOutRedirect();
    }

    if (error.code === 'NotAuthorizedException') {
      console.log('Signing Out In the Response Intercept');
      signOutRedirect();
    }

    if (error.response && error.response.status === 401) {
      console.log('Signing Out In 401');
      signOutRedirect();
    }

    return Promise.reject(error);
  },
);

const handleError = (error: any): any => {
  if (error.response && error.response.status) {
    console.log('[ERROR HANLDER] res: ', error.response);
  }
  return Promise.reject(error);
};

const client = {
  get: async (
    endpoint: string,
    cancelToken?: CancelTokenSource,
  ): Promise<any> => {
    try {
      const response = await instance.get(endpoint, {
        cancelToken: cancelToken?.token,
      });
      return Promise.resolve(response);
    } catch (error) {
      return handleError(error);
    }
  },
  post: async (
    endpoint: string,
    data?: any,
    cancelToken?: CancelTokenSource,
  ): Promise<any> => {
    try {
      const response = await instance.post(endpoint, data, {
        cancelToken: cancelToken?.token,
      });
      return Promise.resolve(response);
    } catch (error) {
      return handleError(error);
    }
  },
  put: async (endpoint: string, data: any): Promise<any> => {
    try {
      const response = await instance.put(endpoint, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleError(error);
    }
  },
  patch: async (endpoint: string, data: any): Promise<any> => {
    try {
      const response = await instance.patch(endpoint, data);
      return Promise.resolve(response);
    } catch (error) {
      return handleError(error);
    }
  },
};

export default client;
