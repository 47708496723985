import React from 'react';

import { StyledFlex } from './flexStyle';
import { FlexboxProps, LayoutProps, SpaceProps } from 'styled-system';

export interface FlexProps extends FlexboxProps, LayoutProps, SpaceProps {
  top?: string;
  bottom?: string;
  position?: string;
  /** Children */
  children: any;
  /** Center content right in the middle */
  centered?: boolean;
  /** Data attribute for cypress testing */
  cypress?: string;
  /** Function to use the div event */
  /** Expand the container to fill all the space that it can */
  stretch?: boolean;
  rest?: any;
  onClick?: () => void;
}

const Flex: React.FC<FlexProps> = ({
  top,
  bottom,
  position,
  children,
  cypress,
  stretch,
  centered,
  ...rest
}: FlexProps) => {
  return (
    <StyledFlex
      top={top}
      bottom={bottom}
      position={position}
      stretch={stretch}
      centered={centered}
      data-cypress={cypress}
      {...rest}>
      {children}
    </StyledFlex>
  );
};

export default Flex;
