import React from 'react';

import {
  LegendContainer,
  MobileFlare,
  LegendItem,
} from './stackedBarChartLegendStyle';
import { useScreenSize } from 'hooks';
import { Paragraph as P } from 'components';
import { theme } from 'style';

const StackedBarChartLegend = ({ payload }: any) => {
  const { isMobile } = useScreenSize().screenSize();
  const stackLegends = isMobile && payload?.length > 2;

  return (
    <LegendContainer stackLegends={stackLegends}>
      {payload.reverse().map((entry: any, index: number) => (
        <LegendItem key={`item-${index}`} stackLegends={stackLegends}>
          <MobileFlare color={entry.color} size={20} />
          <P
            color={theme.colors.primary}
            fontWeight={600}
            style={{ fontSize: isMobile ? '14px' : '16px' }}>
            {entry.value}
          </P>
        </LegendItem>
      ))}
    </LegendContainer>
  );
};

export default StackedBarChartLegend;
