import create from 'zustand';
import { fetchNotificationsDetails, fetchNotificationsSummary } from 'services';
import { SelectedDates, SelectedDateTypeEnum } from '../models';
import {
  NotificationDetails,
  NotificationGroup,
  NotificationType,
} from 'models/Notifications';

interface NotificationStore {
  notificationsSummaryData: NotificationGroup[];
  entrivoDoorsCount: number;
  notificationsDetailsData: NotificationDetails;
  notificationsIsLoading: boolean;
  notificationsDetailsIsLoading: boolean;
  modeChangeAlertNames: { [key: string]: string };
  notificationsSummaryFetch: (
    selectedDates: SelectedDates,
    selectedDateType: SelectedDateTypeEnum,
    followedOnly: boolean,
    doorId?: string,
    accountId?: string,
  ) => void;
  notificationsDetailsFetch: (
    selectedDates: SelectedDates,
    selectedDateType: SelectedDateTypeEnum,
    followedOnly: boolean,
    doorId: string,
  ) => void;
}

const [useAlertStore] = create<NotificationStore>((set) => ({
  notificationsSummaryData: [] as NotificationGroup[],
  entrivoDoorsCount: 0,
  notificationsDetailsData: {} as NotificationDetails,
  notificationsIsLoading: true,
  notificationsDetailsIsLoading: true,
  modeChangeAlertNames: {},
  notificationsSummaryFetch: async (
    selectedDates,
    selectedDateType,
    followedOnly,
    doorId,
    accountId,
  ) => {
    try {
      set({ notificationsIsLoading: true });
      const res = await fetchNotificationsSummary(
        selectedDates.startDate as string,
        selectedDates.endDate as string,
        selectedDateType,
        followedOnly,
        doorId,
        accountId,
      );
      let notifications = await res?.notificationGroups;
      const entrivoDoorsCount = await res?.entrivoDoorsCount;

      notifications = notifications
        ?.map((notification) => {
          if (notification.type === NotificationType.Mode) {
            notification = {
              ...notification,
              value:
                notification.value < 1
                  ? +notification.value.toFixed(2)
                  : Math.round(notification.value),
            };
          }
          return notification;
        })
        .sort((n1, n2) => Number(n2.isCritical) - Number(n1.isCritical));

      set({
        notificationsSummaryData: notifications as NotificationGroup[],
        entrivoDoorsCount: entrivoDoorsCount,
        notificationsIsLoading: false,
      });
    } catch (err) {
      const isCancelled =
        err && err?.message === 'Operation canceled due to new request.';

      set({
        notificationsSummaryData: [] as NotificationGroup[],
        notificationsDetailsIsLoading: isCancelled ? true : false,
      });
      throw err;
    }
  },
  notificationsDetailsFetch: async (
    selectedDates,
    selectedDateType,
    followedOnly,
    doorId,
  ) => {
    set({ notificationsDetailsIsLoading: true });
    try {
      const res = await fetchNotificationsDetails(
        selectedDates.startDate as string,
        selectedDates.endDate as string,
        selectedDateType,
        followedOnly,
        doorId,
      );
      const notifications = await res?.data;
      const alertNames = await res?.alertNames;

      set({
        notificationsDetailsData: notifications as NotificationDetails,
        notificationsDetailsIsLoading: false,
        modeChangeAlertNames: alertNames.reduce(
          (
            r: { [key: string]: string },
            a: { alertId: string; alertDisplayName: string },
          ) => {
            r[a.alertId] = a.alertDisplayName;
            return r;
          },
          {},
        ),
      });
    } catch (err) {
      const isCancelled =
        err && err?.message === 'Operation canceled due to new request.';

      set({
        notificationsDetailsData: {} as NotificationDetails,
        notificationsDetailsIsLoading: isCancelled ? true : false,
        modeChangeAlertNames: {},
      });
      throw err;
    }
  },
}));

export default useAlertStore;
