export interface DoorRemoteFunction {
  availableFunctions: RemoteFunction[];
  deviceModel: string;
}

export enum RemoteFunction {
  REMOTE_OPEN = 'RemoteOpen',
  RESET_DEVICE = 'DeviceReset',
  RESET_OPERATOR = 'OperatorReset',
  CLEAR_ERROR_LOG = 'ClearErrorLog',
  VIEW_DOOR_STATE = 'ViewDoorState',
  REMOTE_LOCK = 'RemoteLock',
  REMOTE_UNLOCK = 'RemoteUnlock',
}
