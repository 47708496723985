import styled from '@emotion/styled';
import { Grid, Paragraph } from 'components';
import { mq, theme } from 'style';

export const AnnouncementsModalContainer = styled.div`
  position: absolute;
  max-width: 500px;
  
  width: 100%;
  top: 30px;
  right: 74px;
  margin-left: 20px;
  z-index: 98;

  > div {
    overflow-y: auto;
    max-height: 380px;
  }

  ${mq.mobile()} {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    margin-left: 0;
    max-width: 100%;

    > div {
      border-radius: 0;
      max-height: calc(100vh - 80px);
    }
  }

  ${mq.tablet()} {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    margin-left: 0;
    max-width: 100%;

    > div {
      border-radius: 0;
      max-height: calc(100vh - 80px);
    }
  }
`;

export const AnnouncementsTitleContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${theme.borders(1, 'lightBlueGrey')};
`;

export const AnnouncementsTitle = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

export const AnnouncementItem = styled(Grid)`
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const AnnouncementContent = styled.div`
  font-size: 14px;
  line-height: 1.4;
  color: ${theme.colors.blueGrey};
`;

export const PastAnnouncementsLink = styled.div`
  svg {
    margin-left: 6px;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 97;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
`;

export const AnnouncementUnreadIcon = styled.span`
  display: block;
  width: 10px;
  height: 10px;
  margin-top: 5px;
  background-color: ${theme.colors.error};
  border-radius: 10px;
`

export const AnnouncementContentContainer = styled.div`
  flex: 1 0 auto;
`

export const AnnouncementActionLink = styled(Paragraph)`
  text-align: right;
`