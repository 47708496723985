import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { flexVertical, theme, mq, defaultBreakpoints } from 'style';

const border = theme.borders(1, 'lightBlueGrey');

interface LinkProps {
  isInList: boolean;
  borderRight?: boolean;
  isMobile: boolean;
  isActive: boolean;
  isHiddenRoute?: boolean;
  isBolded: boolean;
}

const applyIsActiveStyle = ({
  isActive,
  isBolded,
}: {
  isActive: boolean;
  isBolded: boolean;
}) => {
  if (isActive) {
    return css`
      @media (max-width: ${defaultBreakpoints.largeDown}) {
        background-color: ${theme.colors.lightGreyBlue};
        font-weight: ${isBolded && 'bold'};
      }
      &:after {
        content: '';
        position: absolute;
        height: 3px;
        width: calc(100% + 2px);
        bottom: 0px;
        left: -1px;
        background-color: ${theme.colors.secondary};
        @media (max-width: ${defaultBreakpoints.largeDown}) {
          height: 100%;
          width: 3px;
          bottom: 0px;
          left: 0px;
        }
      }
    `;
  }
};

const applyIsActiveInList = ({ isInList }: { isInList: boolean }) => {
  if (isInList) {
    return css`
      > a {
        justify-content: flex-start;
      }
      border-top: ${border};

      &:after {
        height: 100%;
        width: 3px;
        bottom: 0px;
      }
    `;
  }
};
export const StyledLink = styled.div<LinkProps>`
  box-sizing: border-box;
  position: relative;
  ${flexVertical}
  padding: 0px 30px;
  border-left: ${border};
  height: ${theme.vars.headerHeight};
  cursor: pointer;
  font-size: ${(props) =>
    props.isHiddenRoute ? '14px !important' : '16px !important'};
  border-right: ${(props) => (props.borderRight ? border : 'none')};
  > a {
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    color: ${(props) =>
      props.isHiddenRoute
        ? `${theme.colors.charcoal} !important`
        : `${theme.colors.primary} !important`};
    ${flexVertical}
    > span {
      margin-left: 10px;
    }
  }

  ${mq.largeDown} {
    border: none;
  }
  ${applyIsActiveStyle};
  ${applyIsActiveInList};
`;

export const Icon = styled.div`
  width: 25px;
`;
