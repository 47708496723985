import i18n from 'core/i18n/i18n';
import { IcomoonIconNames } from 'components/IcomoonIcons/IcomoonIcons';

export type RoutePageNames =
  | 'Dashboard'
  | 'OccupancyInsights'
  | 'DoorInsights'
  | 'Spaces'
  | 'Doors'
  | 'Alerts'
  | 'Notifications'
  | 'Reports'
  | 'Users'
  | 'Resources'
  | 'Profile'
  | 'Settings'
  | 'Users'
  | 'Support';

export interface NavigationRoute {
  routeName: RoutePageNames;
  to: string;
  label: string;
  icon: IcomoonIconNames;

  adminOnly: boolean;
  aboveTheFold: boolean;
  iconSize?: number;
}

export const filterRoutes = (routes?: RoutePageNames[]) => {
  const NavItems: { [key: string]: NavigationRoute } = {
    Dashboard: {
      routeName: 'Dashboard',
      to: '/dashboard',
      label: i18n.t('navigation:dashboard'),
      icon: 'dashboard',
      adminOnly: false,
      aboveTheFold: true,
    },
    OccupancyInsights: {
      routeName: 'OccupancyInsights',
      to: '/occupancy-insights',
      label: i18n.t('navigation:occupancy_insights'),
      icon: 'dashboard',
      adminOnly: false,
      aboveTheFold: true,
    },
    DoorInsights: {
      routeName: 'DoorInsights',
      to: '/door-insights',
      label: i18n.t('navigation:door_insights'),
      icon: 'dashboard',
      adminOnly: false,
      aboveTheFold: true,
    },
    Spaces: {
      routeName: 'Spaces',
      to: '/spaces',
      label: i18n.t('navigation:spaces'),
      icon: 'spaces',
      iconSize: 20,
      adminOnly: false,
      aboveTheFold: true,
    },
    Doors: {
      routeName: 'Doors',
      to: '/doors',
      label: i18n.t('navigation:doors'),
      icon: 'assets',
      iconSize: 24,
      adminOnly: false,
      aboveTheFold: true,
    },
    Notifications: {
      routeName: 'Notifications',
      to: '/notifications',
      label: i18n.t('navigation:notifications'),
      icon: 'notification',
      iconSize: 20,
      adminOnly: false,
      aboveTheFold: true,
    },
    Reports: {
      routeName: 'Reports',
      to: '/reports',
      label: i18n.t('navigation:reports'),
      icon: 'reports',
      iconSize: 20,
      adminOnly: false,
      aboveTheFold: true,
    },
    Users: {
      routeName: 'Users',
      to: '/users',
      label: i18n.t('navigation:users'),
      icon: 'users',
      iconSize: 20,
      adminOnly: true,
      aboveTheFold: true,
    },
    Resources: {
      routeName: 'Resources',
      to: '/resources',
      label: i18n.t('navigation:resources'),
      icon: 'resources',
      adminOnly: false,
      aboveTheFold: false,
    },
    Profile: {
      routeName: 'Profile',
      to: '/profile',
      label: i18n.t('navigation:profile'),
      icon: 'profile',
      iconSize: 20,
      adminOnly: false,
      aboveTheFold: false,
    },
    Settings: {
      routeName: 'Settings',
      to: '/settings',
      label: i18n.t('navigation:settings'),
      icon: 'settings',
      iconSize: 16,
      adminOnly: true,
      aboveTheFold: false,
    },
    Support: {
      routeName: 'Support',
      to: 'https://entrivo-helpandsupport.dormakaba.com/s/',
      label: i18n.t('navigation:support'),
      icon: 'help-circle',
      iconSize: 20,
      adminOnly: false,
      aboveTheFold: false,
    },
  };
  if (routes) {
    return routes.map((route) => NavItems[route]);
  }
  return Object.keys(NavItems).map((key) => NavItems[key]);
};
