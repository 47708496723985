import React from 'react';
import { SpaceProps } from 'styled-system';

import { StyledDiv } from './chartToolTipStyle';

interface Props extends SpaceProps {
  children: React.ReactNode | string;
}

const ChartToolTip: React.FC<Props> = ({ children, ...rest }) => {
  return <StyledDiv {...rest}>{children}</StyledDiv>;
};

export default ChartToolTip;
