import React from 'react';

import { SpinnerWrapper } from './spinnerStyle';

interface Props {
  color?: string;
  diameter?: string;
  margin?: string;
}

const Spinner = ({
  color = 'primary',
  diameter = '40px',
  margin = '10px auto',
}: Props) => {
  return (
    <SpinnerWrapper color={color} diameter={diameter} margin={margin}>
      <div className="sk-circle">
        <div className="sk-circle1 sk-child"></div>
        <div className="sk-circle2 sk-child"></div>
        <div className="sk-circle3 sk-child"></div>
        <div className="sk-circle4 sk-child"></div>
        <div className="sk-circle5 sk-child"></div>
        <div className="sk-circle6 sk-child"></div>
        <div className="sk-circle7 sk-child"></div>
        <div className="sk-circle8 sk-child"></div>
        <div className="sk-circle9 sk-child"></div>
        <div className="sk-circle10 sk-child"></div>
        <div className="sk-circle11 sk-child"></div>
        <div className="sk-circle12 sk-child"></div>
      </div>
    </SpinnerWrapper>
  );
};

export default Spinner;
