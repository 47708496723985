import React from 'react';

import { Box, ChartSeparator } from 'components';
import { useScreenSize } from 'hooks';
import { DoorDetails, DoorStatus } from 'models';

import DoorMap from './DoorMap/DoorMap';
import DetailsSection from './DetailsSection/DetailsSection';
import { SectionContainer } from './doorMapDetailsStyle';

interface DoorMapDetailsProps {
  doorDetails: DoorDetails;
  doorStatus: DoorStatus;
  toggleEdit: (() => void) | undefined;
}

const DoorMapDetails = ({
  doorDetails,
  doorStatus,
  toggleEdit,
}: DoorMapDetailsProps) => {
  const { isMobile } = useScreenSize().screenSize();
  const latitude = doorDetails?.location?.latitude;
  const longitude = doorDetails?.location?.longitude;

  return (
    <SectionContainer>
      <Box elevated height={isMobile ? '275px' : '550px'}>
        <DoorMap latitude={latitude} longitude={longitude} />
      </Box>
      <ChartSeparator />
      <DetailsSection
        doorDetails={doorDetails}
        doorStatus={doorStatus}
        toggleEdit={toggleEdit}
      />
    </SectionContainer>
  );
};

export default DoorMapDetails;
