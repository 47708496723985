import { useState, useEffect } from "react";
import i18n from "i18next";

import { IdValue } from "models";

export interface FilterTranslations {
  [key: string]: { label: string };
}

const useSpaceTypes = () => {
  const spaceTypes: { label: string; value: string }[] = [
    {
      label: i18n.t("spacesList:table.filters.type_filters.trial"),
      value: "Trial / Test",
    },
    {
      label: i18n.t("spacesList:table.filters.type_filters.library"),
      value: "Library",
    },
    {
      label: i18n.t("spacesList:table.filters.type_filters.retail"),
      value: "Retail",
    },
    {
      label: i18n.t("spacesList:table.filters.type_filters.restaurant"),
      value: "Restaurant / Cafeteria",
    },
    {
      label: i18n.t("spacesList:table.filters.type_filters.worship"),
      value: "Place of Worship",
    },
    {
      label: i18n.t("spacesList:table.filters.type_filters.meeting"),
      value: "Meeting Room",
    },
    {
      label: i18n.t("spacesList:table.filters.type_filters.office"),
      value: "Office",
    },
    {
      label: i18n.t("spacesList:table.filters.type_filters.workshop"),
      value: "Workshop / Factory",
    },
    {
      label: i18n.t("spacesList:table.filters.type_filters.showroom"),
      value: "Showroom",
    },
    {
      label: i18n.t("spacesList:table.filters.type_filters.conference"),
      value: "Conference Room",
    },
    {
      label: i18n.t("spacesList:table.filters.type_filters.other"),
      value: "Other",
    },
  ];

  return {
    spaceTypes,
  };
};

export default useSpaceTypes;
