import styled from '@emotion/styled';
import { mq, theme } from 'style';

export const MapContainer = styled.div`
  min-width: 55%;
  min-height: 600px;
  height: calc(
    100vh - 110px - 140px - 70px
  );
  ${mq.xhuge} {
    min-width: 65%;
  }

  .cluster {
    > div {
      margin-top: -3px;
    }
  }

  .marker-label {
    width: 19px;
    height: 19px;
    background-color: ${theme.colors.primary};
    line-height: 19px;
    text-align: center;
    border-radius: 19px;
    transform: translate(18px, -12px);
  }
`;

export const MobileMapContainer = styled.div`
  height: calc(
    100% - 260px
  ); // vh minus top nav and bottom table list
  width: 100%;
  position: relative;

  .cluster {
    > div {
      margin-top: -3px;
    }
  }

  .marker-label {
    width: 19px;
    height: 19px;
    background-color: ${theme.colors.primary};
    line-height: 19px;
    text-align: center;
    border-radius: 19px;
    transform: translate(18px, -12px);
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  min-height: 600px;
  height: calc(
    100vh - 110px - 140px - 70px
  );
  overflow-y: auto;

  > div {
    height: auto;
  }
`;

interface MobileTableContainerProps {
  mode: string;
}

export const MobileTableContainer = styled.div<MobileTableContainerProps>`
  position: fixed;
  bottom: 0;
  background: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: 100%;
  height: ${({ mode }) =>
    mode === 'Expanded' ? 'calc(100% - 80px - 110px)' : '260px'};
  overflow-y: scroll;
  padding: 0 20px 40px 20px;
  transition: all 0.3s linear;
`;

export const MobileTableToggleContainer = styled.div`
  z-index: 1;
  position: sticky;
  padding: 10px 0;
  top: 0px;
  left: 0;
  width: 100%;
  background: white;
`;

export const MobileTableToggle = styled.div`
  width: 50px;
  height: 8px;
  background-color: lightgray;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 10px;
`;
