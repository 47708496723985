import styled from '@emotion/styled';

import { theme } from 'style';

// overall container for the tooltip section
export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 5px 10px;
  background-color: ${theme.colors.lightBlueGrey};
  box-shadow: 0px 4px 4px 0px #0000001a;
  border-radius: 6px;
`;

// container for MobileFlare and text
export const TooltipItem = styled.div`
  margin: 5px;
  display: flex;
  align-items: center;
`;
