import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import moment from 'moment';

import {
  Button,
  Paragraph as P,
  Grid,
  Modal,
  Checkbox,
  FormComponents,
  MonthPicker,
  Heading as H,
  Flex,
  IcomoonIcons,
} from 'components';
import { Spacer } from 'style';
import { useModalStore } from 'stores';
import { requestReport } from 'services';

interface Props {
  spaceId?: string;
  userId: string;
  commissionedAt?: Date | string;
  accountId?: string;
  isNetworkLevelReport: boolean;
}

const validation = Yup.object({
  reportTypes: Yup.array()
    .min(1, 'At least one box must be ticked')
    .required('Required'),
  monthRange: Yup.array().of(Yup.string().required()),
});

const ExportReportModal = ({
  commissionedAt,
  spaceId,
  userId,
  accountId,
  isNetworkLevelReport,
}: Props) => {
  const { t } = useTranslation();
  const { exportReportModal } = useModalStore();

  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const [error, setError] = useState<null | string>(null);

  const applyMinDate = () => {
    const mayFirst = new Date('2021-05-01T00:00:00+00:00');

    if (!commissionedAt) return mayFirst;

    const commission = moment(commissionedAt)
      .startOf('month')
      .toDate();

    if (commission < mayFirst) {
      return mayFirst;
    } else {
      return commission;
    }
  };

  const exportReportAction = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: any,
  ) => {
    const [from, to] = values.monthRange;

    const payload = {
      spaceId,
      userId,
      from: moment(from).format(),
      to: moment(to)
        .endOf('month')
        .format(),
      accountId,
    };

    values.reportTypes.forEach(async (item: string) => {
      try {
        await requestReport({ ...payload, reportType: item });
        setSubmitting(false);
        setIsSuccess(true);
      } catch (err) {
        setSubmitting(false);
        setErrors(err);
      }
    });
  };

  const closeAndClearModal = () => {
    exportReportModal.toggleModal();
    setIsSuccess(false);
    setError(null);
  };

  return (
    <Modal
      isOpen={exportReportModal.isOpen}
      heading={
        accountId
          ? t('spaceDetails:modals.export_report.accountLevelTitle')
          : t('spaceDetails:modals.export_report.title')
      }
      toggleModal={closeAndClearModal}>
      {error && (
        <P mb="15px" color="error">
          {error}
        </P>
      )}
      {isSuccess ? (
        <Flex justifyContent="center" flexDirection="column">
          <H color="primary" size="h3" marginBottom={10} textAlign="center">
            {t('spaceDetails:modals.export_report.success.heading')}
          </H>
          <P color="charcoal" textAlign="center" marginBottom={10}>
            {t('spaceDetails:modals.export_report.success.sub_one')}
          </P>
          <P color="charcoal" textAlign="center" marginBottom={30}>
            {t('spaceDetails:modals.export_report.success.sub_two')}
          </P>
          <Flex justifyContent="center">
            <Button onClick={closeAndClearModal}>{t('common:close')}</Button>
          </Flex>
        </Flex>
      ) : (
        <Formik
          validateOnMount={true}
          enableReinitialize
          isInitialValid={false}
          initialValues={{ reportTypes: [], monthRange: [null, null] }}
          validationSchema={validation}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setError(null);
            if (
              isNetworkLevelReport &&
              values.monthRange[0] &&
              values.monthRange[1]
            ) {
              const date1 = values.monthRange[0];
              const date2 = values.monthRange[1];
              const monthsDiff = moment(date1).diff(moment(date2), 'months');
              if (Math.abs(monthsDiff) + 1 > 3) {
                setError(
                  t(
                    'spaceDetails:modals.export_report.selected_more_than_three_months',
                  ),
                );
                setSubmitting(false);
              } else {
                exportReportAction(values, setSubmitting, setErrors);
              }
            } else {
              exportReportAction(values, setSubmitting, setErrors);
            }
          }}>
          {(props) => {
            return (
              <Form>
                <FormComponents.Section layout="single" paddingSize="none">
                  <Checkbox
                    label={t(
                      'spaceDetails:modals.export_report.report_type.title',
                    )}
                    name="reportTypes"
                    checkboxItems={[
                      {
                        label: isNetworkLevelReport
                          ? t(
                              'spaceDetails:modals.export_report.report_type.compliance_all_spaces.label',
                            )
                          : t(
                              'spaceDetails:modals.export_report.report_type.compliance.label',
                            ),
                        value: 'Compliance',
                        tooltip: t(
                          'spaceDetails:modals.export_report.report_type.compliance.tooltip',
                        ),
                      },
                      {
                        label: isNetworkLevelReport
                          ? t(
                              'spaceDetails:modals.export_report.report_type.occupancy_all_spaces.label',
                            )
                          : t(
                              'spaceDetails:modals.export_report.report_type.occupancy.label',
                            ),
                        value: 'Occupancy',
                        tooltip: t(
                          'spaceDetails:modals.export_report.report_type.occupancy.tooltip',
                        ),
                      },
                      {
                        label: isNetworkLevelReport
                          ? t(
                              'spaceDetails:modals.export_report.report_type.traffic_all_spaces.label',
                            )
                          : t(
                              'spaceDetails:modals.export_report.report_type.traffic.label',
                            ),
                        value: 'DoorTraffic',
                        tooltip: t(
                          'spaceDetails:modals.export_report.report_type.traffic.tooltip',
                        ),
                      },
                    ]}
                    direction="vertical"
                    hideErrors
                  />
                </FormComponents.Section>
                <FormComponents.Section layout="single" paddingSize="none">
                  <P color="charcoal" size="small" mb="15px" mt="15px" bold>
                    {t('spaceDetails:modals.export_report.date_label')}
                  </P>
                  <Field
                    name="monthRange"
                    render={({ field, form }: any) => {
                      const [start, end] = field.value;
                      return (
                        <MonthPicker
                          name="monthRange"
                          startDate={start}
                          endDate={end}
                          onChange={(dates: Date[]) => {
                            form.setFieldValue('monthRange', dates);
                            setError(null);
                          }}
                          minDate={applyMinDate()}
                        />
                      );
                    }}
                  />
                  <Flex mt={10}>
                    <IcomoonIcons icon="info" color="blueGrey" size={18} />
                    <Spacer width={5} />
                    <P size="small" color="charcoal">
                      {isNetworkLevelReport
                        ? t(
                            'spaceDetails:modals.export_report.notification_all_spaces',
                          )
                        : t('spaceDetails:modals.export_report.notification')}
                    </P>
                  </Flex>
                </FormComponents.Section>
                <Grid
                  gridTemplateColumns="repeat(2, 1fr)"
                  gridGap={20}
                  marginTop={20}>
                  <Button variant="outlined" onClick={closeAndClearModal}>
                    {t('common:close')}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!props.isValid || props.isSubmitting}
                    isLoading={props.isSubmitting}>
                    {t('common:submit')}
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

export default ExportReportModal;
