import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { IcomoonIcons, Paragraph as P, Flex } from 'components';
import { Spacer } from 'style';
import { CopyItemWrapper } from '.';
import { Wrapper } from '../statusDisplayModalStyle';
import config from 'config';

interface Props {}

const DeviceInstructions = ({
  platform,
  content,
}: {
  platform: string;
  content: string;
}) => (
  <Flex flexDirection="row">
    <P bold size="small" mr="5px" color="charcoal">
      {platform}:
    </P>
    <P size="small" color="charcoal">
      {content}
    </P>
  </Flex>
);

const Instructions = ({}: Props) => {
  const { t } = useTranslation();
  const [isInstuctionVisible, setIsInstructionVisible] = useState<boolean>(
    false,
  );

  return (
    <Container>
      <Heading onClick={() => setIsInstructionVisible(!isInstuctionVisible)}>
        <P color="primary" bold size="small">
          {t('spaceDetails:modals.status_display.instructions.heading')}
        </P>
        <IcomoonIcons
          icon={isInstuctionVisible ? 'chevron-up' : 'chevron-down'}
          color="primary"
        />
      </Heading>
      {isInstuctionVisible && (
        <Content>
          <P color="charcoal" mb="5px" size="small">
            {t('spaceDetails:modals.status_display.instructions.expanded')}
          </P>
          <DeviceInstructions
            platform="iOS"
            content={t('spaceDetails:modals.status_display.instructions.ios')}
          />
          <DeviceInstructions
            platform="Android"
            content={t(
              'spaceDetails:modals.status_display.instructions.android',
            )}
          />
          <Spacer height={20} />
          <Wrapper>
            <CopyItemWrapper
              isLoading={false}
              heading="App Link"
              content={`<a href="${config.externalDisplay}" target="_blank">${config.externalDisplay}</a>`}
              override={config.externalDisplay}
            />
          </Wrapper>
        </Content>
      )}
    </Container>
  );
};

const Container = styled.div``;
const Heading = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  margin-bottom: 11px;
`;
const Content = styled.div``;
export default Instructions;
