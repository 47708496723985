import styled from '@emotion/styled';
import { flexVertical } from 'style';

export const Container = styled.div``;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 75px;
  padding: 0 15px;
  text-align: center;
  border-right: 1px solid ${(props: any) => props.theme.colors.greyBlue};

  &:last-child {
    border-right: none;
  }
`;
