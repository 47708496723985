import moment from 'moment';
import i18n from 'i18next';
import client from 'core/client/client';
import { find } from 'lodash';
import { NotificationDetails, NotificationsSummaryChart } from 'models/Notifications';
import { SelectedDateTypeEnum } from 'models';
import { createStartEndParam } from 'utils';
import axios, { CancelTokenSource } from 'axios';

const NOTIFICATIONS_ENDPOINT = 'notifications';
const DOORS_ENDPOINT = 'doors';

let notificationsSummaryCancelToken: CancelTokenSource;
let notificationsDetailsCancelToken: CancelTokenSource;

export const fetchDoorErrorCodeMapping = async (  
  operatorTypes: string[],
) => {
  try {
    const request = await client.get(
      `${NOTIFICATIONS_ENDPOINT}/errorCode?operatorTypes=${operatorTypes.join(
        ',',
      )}`,
    );
    const { data } = request;
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchNotificationsSummary = async (
  startDate: string,
  endDate: string,
  selectedDateType: SelectedDateTypeEnum,
  followedOnly: boolean,
  doorId?: string,
  accountId?: string,
) => {
  const encodedStartDate = encodeURIComponent(
    moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
  );
  const encodedEndDate = encodeURIComponent(
    moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
  );
  const isFollowing = followedOnly ? 'true' : 'false';
  try {
    if (typeof notificationsSummaryCancelToken != typeof undefined) {
      notificationsSummaryCancelToken.cancel(
        'Operation canceled due to new request.',
      );
    }

    notificationsSummaryCancelToken = axios.CancelToken.source();

    const response = await client.get(
      `${NOTIFICATIONS_ENDPOINT}/${doorId ? `${doorId}/` : ''}${
        selectedDateType === 'now' ? `current` : `summary`
      }/?StartDate=${encodedStartDate}&EndDate=${encodedEndDate}&FollowedOnly=${isFollowing}&accountId=${accountId}`,
      notificationsSummaryCancelToken,
    );

    const notificationSummaries = doorId ? response?.data : response?.data?.engageNotificationSummaries;
    // get proper alert names if there are alert data
    if (notificationSummaries.length > 0) {
      const ids: string[] = [];
      const languageCode = i18n.language;
      for (const alert of notificationSummaries) {
        const { name } = alert;
        if (!ids.includes(name)) ids.push(name);
      }

      const alertNames = await client.post(`${DOORS_ENDPOINT}/alertName`, {
        ids,
        languageCode,
      });

      notificationSummaries.map((alert: any) => {
        const { data } = alertNames || {};
        const alertName = find(data, (alertName) => {
          return alert.name === alertName.alertId;
        });
        if (alertName) {
          alert.displayName = alertName.alertDisplayName;
        }
        return alert;
      });
    }

    const { status } = response;
    const entrivoDoorsCount = doorId ? 0 : response.data.entrivoDoorsCount;
    const data: NotificationsSummaryChart = {
      entrivoDoorsCount: entrivoDoorsCount,
      notificationGroups: notificationSummaries
    };

    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchNotificationsDetails = async (
  startDate: string,
  endDate: string,
  selectedDateType: SelectedDateTypeEnum,
  followedOnly: boolean,
  doorId: string,
) => {
  const updatedStartDate = moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ');
  const updatedEndDate = moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ');
  const isFollowing = followedOnly ? 'true' : 'false';

  const dateString = createStartEndParam({
    startDate: updatedStartDate,
    endDate: updatedEndDate,
  });

  try {
    if (typeof notificationsDetailsCancelToken != typeof undefined) {
      notificationsDetailsCancelToken.cancel(
        'Operation canceled due to new request.',
      );
    }

    notificationsDetailsCancelToken = axios.CancelToken.source();

    const notificationsArray = await client.get(
      `${NOTIFICATIONS_ENDPOINT}/${doorId}/details/?${dateString}&FollowedOnly=${isFollowing}`,
      notificationsDetailsCancelToken,
    );

    const allNotifications = [
      ...notificationsArray.data.alerts,
      ...notificationsArray.data.modes,
    ];

    let alertNames: any = {};

    // get proper alert names if there are alert data
    if (allNotifications.length > 0) {
      const ids: string[] = [];
      const languageCode = i18n.language;
      for (const alert of allNotifications) {
        const { name } = alert;
        if (!ids.includes(name)) ids.push(name);
      }

      alertNames = await client.post(`${DOORS_ENDPOINT}/alertName`, {
        ids,
        languageCode,
      });

      Object.keys(notificationsArray.data).map((notificationType: string) => {
        notificationsArray.data[notificationType].map((alert: any) => {
          const { data } = alertNames || {};
          const alertName = find(data, (alertName) => {
            return alert.name === alertName.alertId;
          });
          if (alertName) {
            alert.displayName = alertName.alertDisplayName;
          }
          return alert;
        });
      });
    }

    const { status } = notificationsArray;
    const data: NotificationDetails = notificationsArray.data;
    switch (status) {
      case 200:
      case 204:
        return {
          data,
          alertNames: alertNames?.data || [],
        };
    }
  } catch (err) {
    throw err;
  }
};
