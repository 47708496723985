import React from 'react';
import { SpaceProps } from 'styled-system';

import { Component } from './containerStyle';

interface Props extends SpaceProps {
  children: React.ReactNode;
}

const Container = ({ children, ...rest }: Props) => {
  return (
    <Component {...rest} data-testid="container">
      {children}
    </Component>
  );
};

export default Container;
