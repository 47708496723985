import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  XAxis,
  YAxis,
  ComposedChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import {
  ChartContainer,
  IcomoonIcons,
  Paragraph as P,
  Heading as H,
} from 'components';
import { theme } from 'style';

import Summary from '../Summary/Summary';
import { TextContainer } from '../../OperationalOverview/NoNotification/noNotificationStyle';
import { EmptyContainer } from './emptyStateStyles';

interface EmptyStateProps {
  isToday: boolean;
  isThisWeek: boolean;
  isThisMonth: boolean;
  followedOnly?: boolean;
}

const EmptyState = ({
  isToday,
  isThisWeek,
  isThisMonth,
  followedOnly,
}: EmptyStateProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ChartContainer
        title={t('doorsCycles:title')}
        tooltip={t('doorsCycles:title_description')}
        greenBorder>
        <EmptyContainer>
          <div>
            <TextContainer>
              <IcomoonIcons icon="success" size={92} color="success" />
            </TextContainer>
            <TextContainer>
              <H size="h6" color="primary" mt={16}>
                {t('doorsCycles:no_cycles.heading')}
              </H>
            </TextContainer>
            <TextContainer>
              <P size="normal" color="charcoal" textAlign="center">
                {followedOnly
                  ? t('doorsCycles:no_cycles.description_follow')
                  : t('doorsCycles:no_cycles.description')}
              </P>
            </TextContainer>
          </div>
        </EmptyContainer>
      </ChartContainer>
    </>
  );
};

export default EmptyState;
