import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

// Src imports
import {
  Grid,
  BreadCrumbs,
  Paragraph as P,
  PageHeader,
  Container,
  FormComponents,
  IcomoonIcons,
  Spinner,
} from 'components';
import { User, UserCreate as UserCreateModel } from 'models';
import { createUser, fetchUserMeDetails } from 'services';
import { useScreenSize } from 'hooks';
import { UserDetails } from './forms';
import i18n from 'core/i18n/i18n';

type Props = RouteComponentProps;

const setItemHelper = (obj: any) => {
  window.localStorage.setItem('userMe', JSON.stringify(obj));
};

const UserCreate = ({}: Props) => {
  const history = useHistory();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userMe, setUserMe] = useState<User>();
  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);
  useEffect(() => {
    if (userMe) {
      setIsLoading(false);
    }
  }, [userMe]);

  const refreshUserMe = async () => {
    const res = await fetchUserMeDetails();
    if (res) {
      await setItemHelper(res);
      i18n.changeLanguage(res.language);
    }
  };

  useEffect(() => {
    refreshUserMe();
  }, []);

  const [serverErrorMessage, setServerErrorMessage] = useState<string>('');

  const onSubmit = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: any) => void,
  ) => {
    setSubmitting(true);
    setServerErrorMessage('');

    try {
      const userCreatePayload: UserCreateModel = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobileNumber: values.mobile,
        phoneNumber: values.phone,
        languageCode: values.language,
        role: values.userRole,
        accountId: values.account.id,
      };
      const res = await createUser(userCreatePayload);

      if (res) {
        history.push(`/users/${res.id}`);
      }
    } catch (err: any) {
      console.log(err.response.data);

      if ( !err.response.data.field && err.response.data.type === 'General' ) {
        setServerErrorMessage(err.response.data.message)
      }

      if (err.response.data.field) {
        setErrors({
          [err.response.data.field.toLowerCase()]: err.response.data.message,
        });
      }
      if (err.response.data.type === 'InvalidParameter') {
        setErrors({
          firstname: 'Name cannot be longer than 255 chars',
          lastName: 'Name cannot be longer than 255 chars',
        });
      }
      if (err.response.data.type === 'UsernameExists') {
        setErrors({ email: t('cognitoErrors:UsernameExists') });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {!isMobile && (
        <BreadCrumbs>
          <Grid
            onClick={() => history.push('/users')}
            gridTemplateColumns="repeat(2, min-content)"
            gridColumnGap="8px">
            <IcomoonIcons icon="chevron-left" color="white" />
            <P fontWeight="bold" color="white">
              {t('userDetails:page_header.title')}
            </P>
          </Grid>
          <P color="white">{t('userCreate:page_header.title')}</P>
        </BreadCrumbs>
      )}

      <PageHeader heading={t('userCreate:page_header.title')} />

      {isLoading ? (
        <Spinner />
      ) : (
        <Container>
          <FormComponents.Wrapper>
            <FormComponents.Header
              heading={t('userCreate:form.title')}
              paddingSize="large"
            />
            <Formik
              initialValues={{ account: userMe?.account, language: 'en' }}
              validationSchema={UserDetails.Validation}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                onSubmit(values, setSubmitting, setErrors);
              }}>
              {(props) => {
                return (
                  <Form>
                    <UserDetails.Form />

                    {serverErrorMessage && (
                      <FormComponents.Section layout="error">
                        <p>{serverErrorMessage}</p>
                      </FormComponents.Section>
                    )}
                    
                    {isMobile && (
                      <FormComponents.Section layout="single">
                        <P size="small" color="charcoal">
                          {t('forms:required.fields')}
                        </P>
                      </FormComponents.Section>
                    )}

                    <FormComponents.Submit
                      label={t('profile:buttons.save_changes')}
                      cancelAction={() => history.push('/users')}
                      showRequiredText
                      {...props}
                    />
                  </Form>
                );
              }}
            </Formik>
          </FormComponents.Wrapper>
        </Container>
      )}
    </>
  );
};

export default UserCreate;
