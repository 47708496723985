import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  Paragraph as P,
  Box,
  IcomoonIcons,
  MobileTableComponents,
} from 'components';
import { ReportsList } from 'models';

interface Props {
  row: ReportsList;
  navigateOnRowClick: (id: any) => void;
}

const AlertListItem = ({ row, navigateOnRowClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      padding="0 20px"
      key={row.year + row.month ?? ''}
      onClick={() => {
        navigateOnRowClick(row);
      }}>
      <Grid
        gridTemplateColumns="1fr 20px"
        gridTemplateRows={'auto auto auto auto'}
        gridColumnGap="15px">
        <P
          gridColumn={1}
          size="small"
          fontWeight={400}
          marginTop="15px"
          style={{ overflow: 'visible' }}>
          {row.title}
        </P>
        <P
          gridColumn={1}
          size="small"
          fontWeight={400}
          marginTop="15px"
          color="grey"
          style={{ overflow: 'visible', marginBottom: '15px' }}>
          {row.period}
        </P>
        <Grid
          gridColumn={4}
          gridRow={`1 / span 6`}
          alignSelf="center"
          justifySelf="end">
          <IcomoonIcons icon="download" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AlertListItem;
