import React from 'react';

import { StyledGrid } from './gridStyle';
import {
  GridProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
} from 'styled-system';

export interface Props
  extends GridProps,
    FlexboxProps,
    LayoutProps,
    SpaceProps,
    React.HTMLAttributes<HTMLDivElement> {
  /** Data attribute for cypress testing */
  cypress?: string;
  /** Function to use the div event */
  onClick?: () => void;
  /** Children */
  children: any;
  /**whether the grid flows horizontally */
  horizontal?: boolean;
  /**whether the grid flows vertically */
  vertical?: boolean;
  ref?: any;
}

const Grid: React.FC<Props> = ({ children, ...rest }: Props) => {
  return <StyledGrid {...rest}>{children}</StyledGrid>;
};

export default Grid;
