import React, { useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import {
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import { ErrorBoundary } from '@sentry/react';

import { useScreenSize } from 'hooks';
import { CustomTooltip } from './Tooltip';
import { SpaceDailyOccupancyStatus as ISpaceDailyOccupancyStatus } from 'models';
import { theme } from 'style';
import { useSpaceOccupancyStore, useSpaceDetailsStore } from 'stores';
import {
  Box,
  Skeleton,
  Paragraph,
  BasicError,
  ChartContainer,
} from 'components';

export const statusArray = [
  'Offline',
  'Within',
  'Approaching',
  'Exceeded',
  'NotCommissioned',
] as const;

const chartData = (
  data: ISpaceDailyOccupancyStatus,
  currentOccupancy: number,
  maxOccupancy: number,
) => {
  if (data.events.length === 0) {
    return [];
  }
  const formattedData = data.events
    .filter(
      (event) =>
        event.statusTo !== 'NotCommissioned' &&
        event.statusFrom !== 'NotCommissioned',
    )
    .map((event) => {
      if (moment(event.created).isBefore(moment().startOf('day'))) {
        return {
          ...event,
          created: moment()
            .startOf('day')
            .unix(),
          statusTo: statusArray.findIndex(
            (status) => status === event.statusTo,
          ),
        };
      } else {
        return {
          ...event,
          created: moment(event.created).unix(),
          statusTo: statusArray.findIndex(
            (status) => status === event.statusTo,
          ),
        };
      }
    });

  if (formattedData.length === 0) {
    return [];
  }

  return [
    // this is so the chart is able to chart the beginning of day in the absence of data
    !data.events.some(
      (event) =>
        event.statusFrom === 'NotCommissioned' ||
        event.statusTo === 'NotCommissioned',
    )
      ? {
          statusFrom: formattedData[0].statusFrom,
          statusTo: statusArray.findIndex(
            (status) => status === formattedData[0].statusFrom,
          ),
          created: moment()
            .startOf('day')
            .unix(),
        }
      : {},
    // {
    //   statusFrom: formattedData[0].statusFrom,
    //   statusTo: statusArray.findIndex(
    //     (status) => status === formattedData[0].statusFrom,
    //   ),
    //   created: moment()
    //     .startOf('day')
    //     .unix(),
    // },
    ...formattedData,
    {
      statusFrom: statusArray[formattedData[formattedData.length - 1].statusTo],
      statusTo: formattedData[formattedData.length - 1].statusTo,
      created: moment().unix(),
      data: {
        maxOccupancyCount: maxOccupancy,
        occupancyCount: currentOccupancy,
      },
    },
  ];
};

const yTickFormatter = (tick: number) =>
  i18n.t(`spaceDetails:charts.daily_occupancy_chart.${statusArray[tick]}`);
const xTickFormatter = (time: number) => moment.unix(time).format('H');

export const SpaceDailyOccupancyStatus = () => {
  const { t } = useTranslation();
  const { isLoading, spaceOccupancyData } = useSpaceOccupancyStore();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const [tooltipPayload, setTooltipPayload] = useState<any>(null);
  const {
    isLoading: detailsStoreLoading,
    spaceDetailsData,
  } = useSpaceDetailsStore();

  if (isLoading.daily || detailsStoreLoading) {
    return <Skeleton height={275} />;
  }
  const data = spaceOccupancyData?.daily!;

  return (
    <ErrorBoundary
      fallback={
        <BasicError
          heading="An unexpected error has occured"
          subheading="This tab has timed-out. Please refresh the page"
          shrink
        />
      }>
      <ChartContainer
        title={t('spaceDetails:charts.daily_occupancy')}
        tooltip={t('spaceDetails:charts.daily_occupancy_tooltip')}>
        {isMobile && !isLoading && spaceOccupancyData && (
          <Box outlined={false} mb={20}>
            {tooltipPayload ? (
              <>
                <Paragraph color="grey" size="small">
                  {moment
                    .unix(tooltipPayload![0].payload.created)
                    .format('ddd D MMM YYYY, hh:mm:ss')}
                </Paragraph>
                <Paragraph size="small" color="charcoal">
                  Status From:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {tooltipPayload![0].payload.statusFrom}
                  </span>
                </Paragraph>
                <Paragraph size="small" color="charcoal">
                  Status To:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {statusArray[tooltipPayload![0].payload.statusTo]}
                  </span>
                </Paragraph>
              </>
            ) : (
              <>
                <Paragraph color="grey" size="small">
                  {moment().format('ddd D MMM YYYY, hh:mm:ss')}
                </Paragraph>
                <Paragraph size="small" color="charcoal">
                  Status From:{' '}
                  {spaceOccupancyData!.daily!.events.length > 0 && (
                    <span style={{ fontWeight: 'bold' }}>
                      {
                        spaceOccupancyData.daily?.events[
                          spaceOccupancyData.daily.events.length - 1
                        ].statusFrom
                      }
                    </span>
                  )}
                </Paragraph>
                <Paragraph size="small" color="charcoal">
                  Status To:{' '}
                  {spaceOccupancyData!.daily!.events.length > 0 && (
                    <span style={{ fontWeight: 'bold' }}>
                      {
                        spaceOccupancyData.daily?.events[
                          spaceOccupancyData.daily.events.length - 1
                        ].statusTo
                      }
                    </span>
                  )}
                </Paragraph>
              </>
            )}
          </Box>
        )}
        <ResponsiveContainer height={275}>
          {spaceOccupancyData ? (
            <AreaChart
              data={chartData(
                data,
                spaceDetailsData.status.currentOccupancy,
                spaceDetailsData.status.maxOccupancy,
              )}
              margin={{ top: 5, left: 50, right: 5, bottom: 5 }}>
              <CartesianGrid stroke="#D7E1F1" vertical={false} />
              <XAxis
                domain={[
                  moment()
                    .startOf('day')
                    .unix(),
                  moment()
                    .endOf('day')
                    .unix(),
                ]}
                dataKey="created"
                xAxisId={0}
                tick={{
                  fontSize: '14px',
                  lineHeight: '21px',
                  fill: theme.colors.charcoal,
                }}
                axisLine={false}
                tickLine={false}
                type="number"
                tickMargin={19}
                ticks={Array.apply(0, Array(24)).map((el, i) =>
                  moment()
                    .startOf('day')
                    .add(i, 'hours')
                    .unix(),
                )}
                tickFormatter={xTickFormatter}
              />
              <XAxis
                dataKey="created"
                xAxisId={1}
                domain={[
                  moment()
                    .startOf('day')
                    .unix(),
                  moment()
                    .endOf('day')
                    .unix(),
                ]}
                type="number"
                tick={{
                  fontSize: '14px',
                  lineHeight: '21px',
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fill: theme.colors.charcoal,
                }}
                tickMargin={10}
                tickFormatter={(time) => moment.unix(time).format('A')}
                axisLine={false}
                tickLine={false}
                ticks={[
                  moment()
                    .startOf('day')
                    .unix(),
                  moment()
                    .startOf('day')
                    .add(12, 'hours')
                    .unix(),
                ]}
              />
              <YAxis
                axisLine={false}
                domain={[0, 3]}
                allowDecimals={false}
                tickMargin={10}
                tickFormatter={yTickFormatter}
                tick={{
                  fontSize: '14px',
                  fill: theme.colors.charcoal,
                }}
                tickLine={false}
              />
              <Tooltip
                content={<CustomTooltip />}
                cursor={
                  isMobile
                    ? { stroke: theme.colors.blueGrey, strokeWidth: 1 }
                    : false
                }
                allowEscapeViewBox={{ x: !isMobile, y: true }}
              />
              <Legend />
              <Area
                type="stepAfter"
                dataKey="statusTo"
                stroke={theme.colors.primary}
                strokeWidth={2}
                fill={theme.colors.lightBlueGrey}
                legendType="none"
                dot={false}
              />
            </AreaChart>
          ) : (
            <span>No dat</span>
          )}
        </ResponsiveContainer>
      </ChartContainer>
    </ErrorBoundary>
  );
};
export default SpaceDailyOccupancyStatus;
