import React from 'react';

import { Flex, Skeleton, Grid, Box } from 'components';
import { useScreenSize } from 'hooks';

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}

const SkeletonRow = () => (
  <Grid
    gridTemplateColumns="3fr 1.5fr 3fr 2fr 2fr 60px"
    alignItems="center"
    height={65}>
    <Skeleton width={200} height={28} />
    <Skeleton width={100} height={28} />
    <Skeleton width={200} height={28} />
    <Skeleton width={100} height={28} />
    <Skeleton width={100} height={28} />
  </Grid>
);

const UserTableLoading = ({ children, isLoading }: any) => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  if (isLoading) {
    if (isMobile) {
      return (
        <Grid gridRowGap="10px" gridAutoFlow="row">
          {Array.apply(0, Array(5)).map((el, i) => (
            <Skeleton key={i.toString()} width="100%" height={100} />
          ))}
        </Grid>
      );
    }
    return (
      <Box backgroundColor="white" outlined padding="18px 30px">
        <Flex flexDirection="column">
          <Skeleton width="100%" height={28} />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
        </Flex>
      </Box>
    );
  }
  return children;
};

export default UserTableLoading;
