import styled from '@emotion/styled';
import { theme, flexCenterAll } from 'style';

interface StatusFlareProps {
  backgroundColor: string;
}
export const StatusFlare = styled.div<StatusFlareProps>`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${(props) => theme.colors[props.backgroundColor]};
  margin-right: 8px;
`;

interface ToggleProps {
  status: boolean;
}
export const ToggleButton = styled.button<ToggleProps>`
  cursor: pointer;
  border: none;
  outline: none;
  ${flexCenterAll};
  background-color: transparent;
  color: ${(props) =>
    props.status ? theme.colors.primary : theme.colors.charcoal};
  height: 30px;
  width: 30px;
  border-radius: 8px;
`;