import styled from '@emotion/styled';
import { mq } from 'style';

export const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: 48.75% 2.5% 48.75%;

  ${mq.mobile} {
    display: block;
  }
`;
