import styled from '@emotion/styled';
import { flexCenterAll, theme, inputDefaults, mq } from 'style';

interface WrapperProps {
  style?: { [key: string]: string };
}
export const SearchBarWrapper = styled.div<WrapperProps>`
  position: relative;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 45px;
  ${flexCenterAll}
`;

export const SearchBarInput = styled.input`
  ${inputDefaults};
  border: 1px solid ${theme.colors.lightGreyBlue};
  background-color: ${theme.colors.lightGreyBlue};
  border-radius: 4px;
  padding: 15px 0px;
  width: 100%;
  padding-left: 45px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 0;
  line-height: normal;
  &:focus {
    border-color: ${theme.colors.primary};
    outline: none;
  }
  color: ${theme.colors.charcoal};
  ${mq.mobile()} {
    background: white;
    box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.05);
    border-style: solid;
  }
`;

export const CloseWrapper = styled.div`
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 45px;
  ${flexCenterAll}
`;
