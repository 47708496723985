import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import { Paragraph as P, PageHeader, FormComponents } from 'components';
import { FormContainer, DetailsContainer } from './settingsStyle';
import { useScreenSize } from 'hooks';
import { AccountSettings, DoorSettings } from './forms';
import { fetchAccount, updateAccount } from 'services';
import { Account, AccountUpdate, User } from 'models';
import { userMeStorage } from 'utils';

const Settings = () => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const { t } = useTranslation();
  const isDefaultDoorSettingsEnabled = true;

  const [isEditAccount, setIsEditAccount] = useState<boolean>(false);
  const [isEditDoor, setIsEditDoor] = useState<boolean>(false);
  const [userMe, setUserMe] = useState<User>({} as User);

  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  const [isAccountLoading, setIsAccountLoading] = useState<boolean>(true);
  const [accountData, setAccountData] = useState<Account>({} as Account);

  const fetchAccountData = async (userId: string) => {
    try {
      const res = await fetchAccount(userId);
      if (res) {
        setAccountData(res);
        userMeStorage.set({ ...userMe, account: res });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsAccountLoading(false);
    }
  };

  useEffect(() => {
    if (userMe?.account?.id) {
      fetchAccountData(userMe.account.id);
    }
  }, [userMe]);

  const onAccountSubmit = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true);

    try {
      const accountUpdatePayload: AccountUpdate = {
        name: values.name,
        email: values.email,
        street: values.address?.street,
        city: values.address?.city,
        state: values.address?.state,
        postcode: values.address?.postalCode,
        country: values.address?.country,
        refreshCycle: values.refreshCycle,
        firmwareUpdateSchedule: values.firmwareUpdateSchedule,
        defaultAccountTimezone: values.defaultAccountTimezone,
      };
      const res = await updateAccount(
        userMe?.account?.id,
        accountUpdatePayload,
      );
      if (res === 200) {
        fetchAccountData(userMe.account.id).then(() => {
          setIsEditDoor(false);
          setIsEditAccount(false);
          window.scrollTo(0, 0);
        });
      } else {
        //TODO: error
        console.error(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {!isMobile && <PageHeader heading={t('settings:heading')} />}
      {!isEditDoor && (
        <FormContainer>
          <FormComponents.Wrapper>
            <FormComponents.Header
              heading={
                !isEditAccount
                  ? t('settings:form_header.account_settings.inactive')
                  : t('settings:form_header.account_settings.active')
              }
              onClick={
                !isEditAccount ? () => setIsEditAccount(true) : undefined
              }
              paddingSize="large"
            />
            {isEditAccount ? (
              <Formik
                initialValues={accountData}
                validationSchema={AccountSettings.Validation}
                onSubmit={(values, { setSubmitting }) => {
                  onAccountSubmit(values, setSubmitting);
                }}>
                {(props) => {
                  return (
                    <Form>
                      <AccountSettings.Form />

                      {isMobile && (
                        <FormComponents.Section layout="single">
                          <P size="small" color="charcoal">
                            {t('forms:required.fields')}
                          </P>
                        </FormComponents.Section>
                      )}

                      <FormComponents.Submit
                        label={t('settings:buttons.save_changes')}
                        cancelAction={() => setIsEditAccount(false)}
                        showRequiredText
                        {...props}
                      />
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <DetailsContainer>
                <FormComponents.Section
                  layout={isMobile ? 'single' : 'inactive'}>
                  <FormComponents.InActive
                    title={t('forms:account_settings.name')}
                    content={accountData?.name}
                    loading={isAccountLoading}
                  />
                  <FormComponents.InActive
                    title={t('forms:account_settings.email')}
                    content={accountData?.email}
                    loading={isAccountLoading}
                  />
                  <FormComponents.InActive
                    title={t('forms:account_settings.address_street')}
                    content={accountData?.address?.street}
                    loading={isAccountLoading}
                  />
                  <FormComponents.InActive
                    title={t('forms:account_settings.address_city')}
                    content={accountData?.address?.city}
                    loading={isAccountLoading}
                  />
                  <FormComponents.InActive
                    title={t('forms:account_settings.address_state')}
                    content={accountData?.address?.state}
                    loading={isAccountLoading}
                  />
                  <FormComponents.InActive
                    title={t('forms:account_settings.address_postalcode')}
                    content={accountData?.address?.postalCode}
                    loading={isAccountLoading}
                  />
                  <FormComponents.InActive
                    title={t('forms:account_settings.address_country')}
                    content={accountData?.address?.country}
                    loading={isAccountLoading}
                  />
                </FormComponents.Section>
              </DetailsContainer>
            )}
          </FormComponents.Wrapper>
        </FormContainer>
      )}

      {!isEditAccount && isDefaultDoorSettingsEnabled && (
        <FormContainer>
          <FormComponents.Wrapper>
            <FormComponents.Header
              heading={
                !isEditDoor
                  ? t('settings:form_header.door_settings.inactive')
                  : t('settings:form_header.door_settings.active')
              }
              onClick={!isEditDoor ? () => setIsEditDoor(true) : undefined}
              paddingSize="large"
            />
            {isEditDoor ? (
              <Formik
                initialValues={accountData}
                validationSchema={DoorSettings.Validation}
                onSubmit={(values, { setSubmitting }) => {
                  onAccountSubmit(values, setSubmitting);
                }}>
                {(props) => {
                  return (
                    <Form>
                      <DoorSettings.Form />

                      {isMobile && (
                        <FormComponents.Section layout="single">
                          <P size="small" color="charcoal">
                            {t('forms:required.fields')}
                          </P>
                        </FormComponents.Section>
                      )}

                      <FormComponents.Submit
                        label={t('settings:buttons.save_changes')}
                        cancelAction={() => setIsEditDoor(false)}
                        {...props}
                      />
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <DetailsContainer>
                <FormComponents.Section
                  layout={isMobile ? 'single' : 'inactive'}>
                  {/* Hiding until required */}
                  {/* <FormComponents.InActive
                    title={t('settings:door_settings.refresh_cycle')}
                    content={accountData?.refreshCycle}
                    loading={isAccountLoading}
                  />
                  <FormComponents.InActive
                    title={t('settings:door_settings.updated_schedule')}
                    content={accountData?.firmwareUpdateSchedule}
                    loading={isAccountLoading}
                  /> */}
                  <FormComponents.InActive
                    title={t('settings:space_settings.time_zone.label')}
                    content={accountData?.defaultAccountTimezone}
                    loading={isAccountLoading}
                  />
                </FormComponents.Section>
              </DetailsContainer>
            )}
          </FormComponents.Wrapper>
        </FormContainer>
      )}
    </>
  );
};

export default Settings;
