import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { WebsocketEvent } from 'models';
import { useWebSocketMessageStore } from 'stores';
import config from 'config';

const URL = config.websocket;

const useWebsocketConnection = () => {
  const { setLastMessage } = useWebSocketMessageStore();
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [readyToConnect, setReadyToConnect] = useState<boolean>(false);

  const cleanUpConnection = () => {
    ws?.close();
    setWs(null);
    setReadyToConnect(false);
    console.log('WS connection closed');
  };

  Auth.currentSession()
    .then((session) => {
      const isValid = session.isValid();
      setReadyToConnect(isValid);
    })
    .catch((err) => {
      console.log(err);
      cleanUpConnection();
    });

  useEffect(() => {
    if (readyToConnect) {
      console.log('Connecting to WS');
      connectToWebsocket();
    }
  }, [readyToConnect]);

  const connectToWebsocket = async () => {
    setWs(null);
    const session = await Auth.currentSession();
    if (session.isValid()) {
      const token = await session.getAccessToken().getJwtToken();
      const client = new WebSocket(`${URL}?token=${token}`);
      setWs(client);
    } else {
      setWs(null);
    }
  };

  useEffect(() => {
    if (ws) {
      ws.onopen = () => {
        console.log('== WS Open ==');
        const TIMEOUT = 9 * 60000;

        setInterval(() => {
          if (ws.readyState === 1) {
            console.log('Returning to base...');
            ws.send('Reporting In');
          }
        }, TIMEOUT);
      };
      ws.onclose = (message: any) => {
        console.log('WS Closed == Reconnecting to WS', message);

        // if (readyToConnect) {
        //   connectToWebsocket();
        // }
      };
      ws.onmessage = (message: MessageEvent) => {
        try {
          const data: WebsocketEvent = JSON.parse(message.data);
          setLastMessage(data);
        } catch {
          console.log('ws response: ', message.data);
        }
      };
      ws.onerror = (err: any) => {
        console.log('WS error: ', err);
        cleanUpConnection();
      };
    }
  }, [ws]);

  return {
    connectToWebsocket,
    setReadyToConnect,
    cleanUpConnection,
  };
};

export default useWebsocketConnection;
