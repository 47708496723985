import React from 'react';
import parse from 'html-react-parser';
import { BaseHeading } from './headingStyle';
import {
  TextAlignProps,
  FontWeightProps,
  SpaceProps,
  FontSizeProps,
  LineHeightProps,
} from 'styled-system';
import { ColorsType } from 'style/theme';

export type HeadingSizes = 'hero' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface Props
  extends TextAlignProps,
    FontWeightProps,
    SpaceProps,
    LineHeightProps,
    React.HTMLAttributes<HTMLHeadingElement>,
    FontSizeProps {
  /** Size of element reflecting HTML markup */
  size?: HeadingSizes;
  /** Color of the heading text */
  color?: string;
  /** Text align center */
  center?: boolean;
  /**text is uppercase */
  uppercase?: boolean;
}

/**
 * Component to render any Heading text found throught the app
 * Sizes are relative to the HTML syntax, but they will render a p tag. The sizes are just to stay in sync with the Figma design system.
 * Strings are parsed for html for extra control
 */

const Heading: React.FC<Props> = ({ children, color, ...rest }) => {
  return (
    <BaseHeading color={color} {...rest}>
      {typeof children === 'string' ? parse(children) : children}
    </BaseHeading>
  );
};

export default Heading;
