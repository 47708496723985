import { useState, useEffect } from 'react';

export type TSortBy =
  | 'exceeded'
  | 'approaching'
  | 'within'
  | 'offline'
  | 'name';

const useTableSortBy = (params: any, setParams: any) => {
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  const [sortByValue, setSortByValue] = useState<any | null>(null);

  useEffect(() => {
    if (sortByValue) {
      setParams({
        ...params,
        sortBy: sortByValue,
        ascending: sortAscending,
      });
    }
  }, [sortByValue, sortAscending]);

  const sortByHandler = (sortBy: any) => {
    if (sortBy === sortByValue) {
      setSortAscending(!sortAscending);
      setSortByValue(sortBy);
    } else {
      setSortByValue(sortBy);
    }
  };

  return {
    sortByHandler,
  };
};

export default useTableSortBy;
