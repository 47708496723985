import React from 'react';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import { de, fr } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { Wrapper, InputWrapper } from './monthPickerStyle';

import { IcomoonIcons } from 'components';
import { InputLabel } from 'style';

interface Props {
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  onChange: (e: any) => void;
  label?: string;
  placeholder?: string;
  minDate?: Date | string;
  maxDate?: Date;
}

const CustomInput = (props: any) => (
  <InputWrapper>
    <input {...props} />
    <IcomoonIcons icon="chevron-down" color="blueGrey" />
  </InputWrapper>
);

const MonthPicker = ({
  startDate,
  endDate,
  onChange,
  label,
  name,
  placeholder,
  minDate,
  maxDate,
}: Props) => {
  registerLocale('de', de);
  registerLocale('fr', fr);
  const { t } = useTranslation();
  return (
    <Wrapper>
      {label && <InputLabel>{label}</InputLabel>}
      <DatePicker
        locale={i18n.language}
        name={name}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        dateFormat="MMMM"
        showMonthYearPicker
        selectsRange
        shouldCloseOnSelect={false}
        placeholderText={placeholder ? placeholder : t('common:select')}
        minDate={minDate ? new Date(minDate) : undefined}
        maxDate={maxDate}
        value={
          startDate
            ? `${moment(startDate).format('MMMM')} - ${
                endDate !== null ? moment(endDate).format('MMMM') : ''
              }`
            : placeholder
            ? placeholder
            : t('common:select')
        }
        customInput={<CustomInput />}
        autoComplete="off"
      />
    </Wrapper>
  );
};

export default MonthPicker;
