import common from './common.json';
import currentOccupancy from './current_occupancy.json';
import historicOccupancy from './historic_occupancy.json';
import loginActivate from './login_activate.json';
import pageDashboard from './page_dashboard.json';
import operationalOverview from './operational_overview.json';
import pageError from './page_error.json';
import navigation from './navigation.json';
import page_profile from './page_profile.json';
import page_settings from './page_settings.json';
import page_spaces_list from './page_spaces_list.json';
import page_users_list from './page_users_list.json';
import page_space_details from './page_space_details.json';
import page_door_list from './page_door_list.json';
import page_user_details from './page_user_details.json';
import page_user_create from './page_user_create.json';
import page_door_display from './page_door_display.json';
import forms from './forms.json';
import validation from './validation.json';
import countries from './countries.json';
import languages from './languages.json';
import cognitoErrors from './cognito_errors.json';
import connected_door_health from './connected_door_health.json';
import page_doors_cycles from './page_doors_cycles.json';
import page_door_cycles from './page_door_cycles.json';
import page_door_details from './page_door_details.json';
import alerts from './alerts.json';
import page_user_onboarding from './page_user_onboarding.json';
import reports from './reports.json';

export default {
  common,
  currentOccupancy,
  historicOccupancy,
  operationalOverview,
  loginActivate,
  validation,
  countries,
  languages,
  doorList: page_door_list,
  dashboard: pageDashboard,
  error: pageError,
  navigation,
  profile: page_profile,
  settings: page_settings,
  spacesList: page_spaces_list,
  usersList: page_users_list,
  spaceDetails: page_space_details,
  userDetails: page_user_details,
  userCreate: page_user_create,
  doorDisplay: page_door_display,
  forms,
  cognitoErrors,
  connectedDoorHealth: connected_door_health,
  doorsCycles: page_doors_cycles,
  doorCycles: page_door_cycles,
  doorDetails: page_door_details,
  alerts: alerts,
  userOnboarding: page_user_onboarding,
  reports: reports,
};
