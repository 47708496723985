import axios from 'axios';
import client from 'core/client/client';
import {
  User,
  UserUpdate,
  CognitoUser,
  UserResponse,
  UserCreate,
  NotificationType,
  LabelValue,
  LabelValueWithIsCriticalField,
} from 'models';
import currentProduct from '../utils/currentProduct';
import config from 'config';

const IDM_ENDPOINT = '/IdM';

export const createUser = async (payload: UserCreate) => {
  payload.engageUserProfile = currentProduct();
  try {
    const request = await client.post(`${IDM_ENDPOINT}/user`, payload);
    const { status } = request;

    if (status === 200) {
      const res: User = request.data;
      return res;
    }
  } catch (err) {
    throw err;
  }
};

export const updateUser = async (
  payload: UserUpdate,
  alertTypeOptions: LabelValueWithIsCriticalField[],
) => {
  payload.engageUserProfile = currentProduct();
  try {
    if (
      payload.diNotificationAlertTypes &&
      (payload.diNotificationAlertTypes.indexOf(NotificationType.All) >= 0 ||
        (payload.diNotificationAlertTypes.indexOf(
          NotificationType.AllCritical,
        ) >= 0 &&
          payload.diNotificationAlertTypes.indexOf(
            NotificationType.AllGeneral,
          ) >= 0))
    ) {
      payload.diNotificationAlertTypes = [NotificationType.All];
    } else if (
      payload.diNotificationAlertTypes &&
      payload.diNotificationAlertTypes.indexOf(NotificationType.AllCritical) >=
        0
    ) {
      // Remove all critical alert types and add AllCritical
      const allGeneralAlerts = alertTypeOptions
        .filter((a) => !a.isCritical)
        .filter((a) => payload.diNotificationAlertTypes?.includes(a.value))
        .map((a) => a.value);
      payload.diNotificationAlertTypes = [
        NotificationType.AllCritical,
        ...allGeneralAlerts,
      ];
    } else if (
      payload.diNotificationAlertTypes &&
      payload.diNotificationAlertTypes.indexOf(NotificationType.AllGeneral) >= 0
    ) {
      // Remove all general alert types and add AllCritical
      const allCriticalAlerts = alertTypeOptions
        .filter((a) => a.isCritical)
        .filter((a) => payload.diNotificationAlertTypes?.includes(a.value))
        .map((a) => a.value);
      payload.diNotificationAlertTypes = [
        NotificationType.AllGeneral,
        ...allCriticalAlerts,
      ];
    }
    const request = await client.patch(`/Users/me`, payload);
    const { status } = request;
    const data: User = request.data;
    // TODO: API seems to be returning 200 with content of 'NoStatus'
    switch (status) {
      case 200:
        return data;
      case 204:
        return status;
    }
  } catch (err) {
    throw err;
  }
};

export const cognitoUser = async (payload: CognitoUser) => {
  try {
    const request = await client.post(`${IDM_ENDPOINT}/cognitoUser`, payload);
    const { status } = request;

    if (status === 200) {
      const res: UserResponse = request.data;
      return res;
    }
  } catch (err) {
    throw err;
  }
};

export const lastLogin = async () => {
  try {
    await client.post(`${IDM_ENDPOINT}/lastLogin`, {});
  } catch (err) {
    console.log('err');
  }
};

export const resendInvite = async (username: string) => {
  try {
    const request = await client.post(
      `${IDM_ENDPOINT}/resendInvite?Username=${encodeURIComponent(username)}`,
      {},
    );
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export const deactivate = async (username: string) => {
  try {
    const request = await client.post(
      `${IDM_ENDPOINT}/deactivate?Username=${encodeURIComponent(username)}`,
      {},
    );
    const { status } = request;

    if (status === 200) {
      return status;
    }
  } catch (err) {
    throw err;
  }
};

export const activate = async (username: string) => {
  try {
    const request = await client.post(
      `${IDM_ENDPOINT}/activate?Username=${encodeURIComponent(username)}`,
      {},
    );
    const { status } = request;

    if (status === 200) {
      return status;
    }
  } catch (err) {
    throw err;
  }
};

export const checkIfUserExists = async (username: string) => {
  try {
    const encoded = encodeURIComponent(username);
    const request = await axios.get(
      `${config.API}IdM/usernameCheck?Username=${encoded}`,
    );
    const { status } = request;
    return status;
  } catch (err) {
    const { status } = err.response;
    return status;
  }
};
