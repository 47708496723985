import React, { useState, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { generateStatus, displaySpaceTypeTranslation } from 'utils';
import { followSpace, unfollowSpace } from 'services';
import {
  Box,
  Grid,
  IcomoonIcons,
  StatusIndicator,
  Paragraph as P,
  MobileTableComponents,
  Spinner,
} from 'components';

interface Props {
  row: any;
  navigateOnRowClick: (id: any) => void;
}

const SpaceListItem = ({ navigateOnRowClick, row }: Props) => {
  const { t } = useTranslation();
  const spaceSummary: any = row.original;
  const { isFollowing, spaceId: id } = spaceSummary.summary;

  const [isLoading, setIsLoading] = useState(false);
  const [following, setFollowing] = useState(isFollowing);

  const toggleIsFollowing = async (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!isLoading) {
      setIsLoading(true);
      const status = !following
        ? await followSpace(id)
        : await unfollowSpace(id);

      if (status === 200) {
        setFollowing(!following);
      }
      setIsLoading(false);
    }
  };

  return (
    <MobileTableComponents.Wrapper
      key={row.id}
      onClick={() => {
        navigateOnRowClick(row.original);
      }}>
      <MobileTableComponents.Item truncate column="1 / span 3">
        <P size="small" color="charcoal" marginBottom="10px">
          {row.values['summary.spaceDisplayName']}
        </P>
      </MobileTableComponents.Item>

      <Grid gridColumn={4} gridRow={1}>
        <Box
          onClick={toggleIsFollowing}
          outlined={false}
          backgroundColor={following ? 'lightBlueGrey' : 'lightGrey'}
          p="2px 6px">
          <Grid
            gridTemplateColumns="repeat(2, min-content)"
            alignItems="center"
            gridColumnGap="3px">
            {isLoading ? (
              <Box
                width="42px"
                padding="0"
                backgroundColor="transparent"
                border="none">
                <Spinner
                  diameter="13px"
                  margin="0 auto"
                  color={isFollowing ? 'primary' : 'charcoal'}
                />
              </Box>
            ) : (
              <>
                <IcomoonIcons
                  icon={following ? 'favourite-filled' : 'favourite'}
                  size={10}
                  color={following ? 'primary' : 'charcoal'}
                />
                <P
                  color={following ? 'primary' : 'charcoal'}
                  fontWeight="bold"
                  style={{ fontSize: '10px', lineHeight: '15px' }}>
                  Follow
                </P>
              </>
            )}
          </Grid>
        </Box>
      </Grid>

      <MobileTableComponents.ColumnHeader row={3}>
        {t('spacesList:table.column.status')}
      </MobileTableComponents.ColumnHeader>
      <MobileTableComponents.Item row={4}>
        <StatusIndicator
          status={generateStatus(
            row.values['status.offline'],
            row.original.status.currentOccupancy,
            row.original.status.maxOccupancy,
            row.original.status.approachingOccupancy,
          )}
          label={`${row.values['status.percent']}%`}
          labelSize="tiny"
        />
      </MobileTableComponents.Item>
      <MobileTableComponents.ColumnHeader row={3} column={2}>
        {t('spacesList:table.column.type')}
      </MobileTableComponents.ColumnHeader>
      <MobileTableComponents.Item truncate column={2} row={4}>
        <P size="tiny">
          {displaySpaceTypeTranslation(row.values['summary.spaceType'])}
        </P>
      </MobileTableComponents.Item>
      <MobileTableComponents.ColumnHeader row={3} column={3}>
        {t('spacesList:table.column.account')}
      </MobileTableComponents.ColumnHeader>
      <MobileTableComponents.Item truncate row={4} column={3}>
        <P size="tiny">{row.values['summary.account.name']}</P>
      </MobileTableComponents.Item>
      <Grid
        gridColumn={4}
        gridRow={'3 / span 2'}
        alignSelf="end"
        justifySelf="end">
        <IcomoonIcons icon="chevron-right" />
      </Grid>
    </MobileTableComponents.Wrapper>
  );
};

export default SpaceListItem;
