import styled from '@emotion/styled';
import { mq } from 'style';

export const Container = styled.div`
  margin: 35px 0;

  ${mq.mobile()} {
    display: block;
    margin: 30px 0;
  }
`;

export const RowContainer = styled.div`
  margin: 10px 65px;
  display: flex;
  max-width: 500px;

  ${mq.mobile()} {
    display: block;
    margin: 10px 30px;
  }
`;
