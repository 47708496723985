import React, { useState, SyntheticEvent } from 'react';
import { ToggleButton } from './cellStyle';
import { followDoor, unfollowDoor } from 'services';
import { IcomoonIcons, Spinner, Box } from 'components';

const FollowToggle = ({
  id,
  isFollowing,
  simple = false,
  onToggleFollowing,
  loading,
}: {
  id: string;
  isFollowing: boolean;
  simple?: boolean;
  loading?: boolean;
  onToggleFollowing?: (id: string) => void;
}) => {
  const [following, setFollowing] = useState(isFollowing);
  const [isLoading, setIsLoading] = useState(loading);

  const toggleIsFollowing = async (e: SyntheticEvent) => {
    if (!isLoading) {
      e.stopPropagation();
      if (!isLoading) {
        setIsLoading(true);
        if (onToggleFollowing) onToggleFollowing(id);
        const status = !following
          ? await followDoor(id)
          : await unfollowDoor(id);

        if (status === 200) {
          setFollowing(!following);
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <ToggleButton status={following} onClick={toggleIsFollowing}>
      {isLoading ? (
        <Box
          width="42px"
          padding="0"
          backgroundColor="transparent"
          border="none">
          <Spinner
            diameter="20px"
            margin="0 auto"
            color={isFollowing ? 'primary' : 'charcoal'}
          />
        </Box>
      ) : (
        <>
          <IcomoonIcons
            icon={following ? 'favourite-filled' : 'favourite'}
            size={14}
            color={following ? 'primary' : 'charcoal'}
          />
        </>
      )}
    </ToggleButton>
  );
};

export default FollowToggle;
