import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// Directory imports
import { HeaderStatus } from './components';

// Src imports
import {
  Grid,
  Button,
  BreadCrumbs,
  Paragraph as P,
  PageHeader,
  Container,
  FormComponents,
  IcomoonIcons,
  Modal,
  Spinner,
} from 'components';
import { useScreenSize, useTopBar } from 'hooks';
import {
  fetchUser,
  resendInvite,
  activate,
  deactivate,
  fetchNotificationScopes,
  userRoleUpdate,
  fetchAvailableNotificationTypes,
} from 'services';
import {
  applyFilterTranslations,
  FilterTranslations,
  isDI,
  isDIS,
  isDualProducts,
  isOI,
  shouldShowModeElement,
} from 'utils';

import {
  User,
  IdValue,
  LabelValue,
  NotificationType,
  LabelValueWithIsCriticalField,
  NotifcationTypeFilter,
} from 'models';
import { Form, Formik } from 'formik';
import { UserDetailsForm } from './forms';
import { DetailsContainer } from './userDetailsStyles';
import { getAlertTypeNames } from 'utils/getAlertTypeNames';
import { getNotificationTypeOptions } from 'utils/getNotificationTypeOptions';

type Props = RouteComponentProps;

type UseParamsProps = {
  id: string;
};

const languageSetting = (lang: string) => i18n.t('languages:' + lang);
const scopeTranslations: FilterTranslations = {
  Following: i18n.t('forms:communication_preferences.typesDI.following'),
  All: i18n.t('forms:communication_preferences.typesDI.all'),
  'N/A': i18n.t('forms:communication_preferences.frequencies.none'),
};

const activateErrorHandler = (err: any) => {
  const { type } = err;
  switch (type) {
    case 'NoDeactiveAdmin':
      return 'userDetails:errors.NoDeactiveAdmin';
    case 'UnsuppportedUserState':
      return 'userDetails:errors.UnsuppportedUserState';
    default:
      return 'userDetails:errors.default';
  }
};

const UserDetails = ({}: Props) => {
  const history = useHistory();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const { setLeftAction } = useTopBar();
  useEffect(() => {
    setLeftAction(
      <div onClick={() => history.goBack()}>
        <IcomoonIcons icon="chevron-left" color="white" />
      </div>,
    );
    return () => {
      setLeftAction(null);
    };
  }, []);

  const [notificationScopes, setNotificationScopes] = useState<LabelValue[]>();
  useEffect(() => {
    const fetchScopes = async () => {
      try {
        const notificationScopesRes = (await fetchNotificationScopes()) as IdValue[];
        const notificationScopesTranslated = await applyFilterTranslations(
          notificationScopesRes,
          scopeTranslations,
        );
        setNotificationScopes(notificationScopesTranslated);
      } catch (err) {
        console.log(err);
      }
    };
    fetchScopes();
  }, []);

  const { t } = useTranslation();

  const { id } = useParams<UseParamsProps>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isActionLoading, setIsActionLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<any>({
    isOpen: false,
    heading: '',
    content: '',
  });
  const [userData, setUserData] = useState<User>({} as User);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [userMe, setUserMe] = useState<User>({} as User);

  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  const fetch = async (id: string) => {
    try {
      const res = await fetchUser(id);
      if (res) {
        if (res?.alertTypes.includes('All')) {
          res.alertTypes.push(NotificationType.AllCritical);
          res.alertTypes.push(NotificationType.AllGeneral);
          res.generalAlertTypes = [NotificationType.AllCritical];
          res.criticalAlertTypes = [NotificationType.AllGeneral];
        }
        setUserData(res);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const action = async () => {
    setIsActionLoading(true);
    try {
      const username: string = userData.email;
      if (
        userData.state.status == 'Active' ||
        userData.state.status === 'VerificationRequired'
      ) {
        await deactivate(username);
      } else if (userData.state.status == 'Pending') {
        const res = await resendInvite(username);
        if (res === 200) {
          setModal({
            isOpen: true,
            heading: t('userDetails:invite.success.heading'),
            content: t('userDetails:invite.success.content'),
          });
        }
      } else {
        await activate(username);
      }
    } catch (err) {
      setModal({
        isOpen: true,
        heading: 'Error',
        content: t(activateErrorHandler((err as any).response.data)),
      });
      console.log(err);
    } finally {
      setIsActionLoading(false);
      setIsLoading(true);
      fetch(id);
    }
  };

  useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, [id]);

  const onUserDetailsSubmit = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true);

    try {
      const res = await userRoleUpdate(id, values.userRole);
      if (res === 204) {
        fetch(id).then(() => {
          setIsEdit(false);
          window.scrollTo(0, 0);
        });
      } else {
        console.error(res);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getButtonText = () => {
    switch (userData?.state?.status) {
      case 'Active':
      case 'VerificationRequired':
        return t('userDetails:page_header.buttons.deactivate');
      case 'Pending':
        return t('userDetails:page_header.buttons.resend_invite');
      default:
        return t('userDetails:page_header.buttons.activate');
    }
  };

  const [alertTypes, setAlertTypes] = useState<LabelValueWithIsCriticalField[]>(
    [],
  );

  const fetchAlertTypes = async () => {
    const alertTypesData = await fetchAvailableNotificationTypes();
    const alertTypeOptions = getNotificationTypeOptions(
      alertTypesData ?? [],
      NotificationType.Alert,
      true,
    );
    setAlertTypes(alertTypeOptions);
  };

  useEffect(() => {
    fetchAlertTypes();
  }, []);

  return (
    <>
      {!isMobile && (
        <BreadCrumbs>
          <Grid
            onClick={() => history.push('/users')}
            gridTemplateColumns="repeat(2, min-content)"
            gridColumnGap="8px">
            <IcomoonIcons icon="chevron-left" color="white" />
            <P fontWeight="bold" color="white">
              {t('userDetails:page_header.title')}
            </P>
          </Grid>
          {!isLoading && (
            <P color="white">
              {userData.firstName} {userData.lastName}
            </P>
          )}
        </BreadCrumbs>
      )}

      <Modal
        heading={modal.heading}
        isOpen={modal.isOpen}
        toggleModal={() => setModal({ ...modal, isOpen: !modal.isOpen })}>
        {modal.content}
      </Modal>

      <PageHeader
        heading={isLoading ? '' : `${userData.firstName} ${userData.lastName}`}
        isHeadingLoading={isLoading}
        subComponent={<HeaderStatus state={userData?.state || {}} />}
        fabActions={true}
        actionComponents={
          <>
            {!isEdit && (
              <Button
                inverse={!isMobile}
                disabled={isLoading || isActionLoading}
                isLoading={isLoading || isActionLoading}
                stretch={isMobile}
                onClick={action}>
                {getButtonText()}
              </Button>
            )}
          </>
        }
      />

      <Container>
        <FormComponents.Wrapper>
          <FormComponents.Header
            heading={t('userDetails:details_section.title')}
            paddingSize="large"
            onClick={
              !isEdit && userMe.userRole === 'Admin'
                ? () => setIsEdit(true)
                : undefined
            }
          />
          {isEdit ? (
            <Formik
              initialValues={userData}
              validationSchema={UserDetailsForm.Validation}
              onSubmit={(values, { setSubmitting }) => {
                onUserDetailsSubmit(values, setSubmitting);
              }}>
              {(props) => {
                return (
                  <Form>
                    <DetailsContainer>
                      <UserDetailsForm.Form />

                      {isMobile && (
                        <FormComponents.Section layout="single">
                          <P size="small" color="charcoal">
                            {t('forms:required.fields')}
                          </P>
                        </FormComponents.Section>
                      )}
                    </DetailsContainer>

                    <FormComponents.Submit
                      label={t('settings:buttons.save_changes')}
                      cancelAction={() => setIsEdit(false)}
                      {...props}
                    />
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <DetailsContainer>
              <FormComponents.Section
                heading={t(
                  'userDetails:details_section.personal_information.heading',
                )}
                layout={isMobile ? 'single' : 'inactive'}>
                <FormComponents.InActive
                  title={t('forms:personal_details.salutation')}
                  content={userData?.salutation}
                  loading={isLoading}
                />
                <FormComponents.InActive
                  title={t('forms:personal_details.honorific')}
                  content={userData?.honorific}
                  loading={isLoading}
                />
                <FormComponents.InActive
                  title={t('forms:personal_details.first_name')}
                  content={userData?.firstName}
                  loading={isLoading}
                />
                <FormComponents.InActive
                  title={t('forms:personal_details.last_name')}
                  content={userData?.lastName}
                  loading={isLoading}
                />
                <FormComponents.InActive
                  title={t('forms:personal_details.mobile_no')}
                  content={userData?.mobile}
                  loading={isLoading}
                />
                <FormComponents.InActive
                  title={t('forms:personal_details.phone_no')}
                  content={userData?.phone}
                  loading={isLoading}
                />
                <FormComponents.InActive
                  title={t('forms:personal_details.email')}
                  content={userData?.email}
                  loading={isLoading}
                />
                <FormComponents.InActive
                  title={t('forms:personal_details.account')}
                  content={userData?.account?.name}
                  loading={isLoading}
                />
                <FormComponents.InActive
                  title={t('forms:personal_details.user_type')}
                  content={
                    userData?.userRole == 'Viewer'
                      ? t('userDetails:roles.viewer')
                      : t('userDetails:roles.admin')
                  }
                  loading={isLoading}
                />
                <FormComponents.InActive
                  title={t('forms:personal_details.language')}
                  content={languageSetting(userData?.language)}
                  loading={isLoading}
                />
              </FormComponents.Section>
              <FormComponents.Section
                heading={t('profile:communication_preferences.heading')}
                layout={notificationScopes ? 'inactive' : 'single'}>
                {isDualProducts() && (
                  <>
                    <FormComponents.SectionHeading
                      heading={t('profile:communication_preferences.diHeading')}
                    />
                    <FormComponents.SectionHeading heading="" />
                  </>
                )}

                {notificationScopes ? (
                  <>
                    {(isDIS() || isDI() || isDualProducts()) && (
                      <>
                        <FormComponents.InActive
                          title={t('forms:communication_preferences.alerts')}
                          content={
                            notificationScopes
                              ? notificationScopes.find(
                                  (scope: LabelValue) =>
                                    scope.value ==
                                    userData?.diNotificationScope,
                                )?.label
                              : ''
                          }
                        />
                        <FormComponents.InActive
                          title={t(
                            'forms:communication_preferences.critical_alerts_selected',
                          )}
                          content={getAlertTypeNames(
                            userData,
                            alertTypes,
                            NotifcationTypeFilter.CriticalOnly,
                          )}
                        />
                        <FormComponents.InActive
                          title={t(
                            'forms:communication_preferences.general_alerts_selected',
                          )}
                          content={getAlertTypeNames(
                            userData,
                            alertTypes,
                            NotifcationTypeFilter.GeneralOnly,
                          )}
                        />
                        {shouldShowModeElement() && (
                          <FormComponents.InActive
                            title={t(
                              'forms:communication_preferences.mode_changes',
                            )}
                            content={
                              notificationScopes
                                ? notificationScopes.find(
                                    (scope: LabelValue) =>
                                      scope.value ==
                                      userData?.modeNotificationScope,
                                  )?.label
                                : ''
                            }
                          />
                        )}
                        <FormComponents.InActive
                          title={t('forms:communication_preferences.method')}
                          content={
                            (userData?.diNotifyViaSms
                              ? t('forms:communication_preferences.methods.sms')
                              : '') +
                            (userData?.diNotifyViaSms &&
                            userData?.diNotifyViaEmail
                              ? ', '
                              : '') +
                            (userData?.diNotifyViaEmail
                              ? t(
                                  'forms:communication_preferences.methods.email',
                                )
                              : '')
                          }
                        />
                      </>
                    )}

                    {isDualProducts() && (
                      <>
                        <FormComponents.SectionHeading
                          heading={t(
                            'profile:communication_preferences.oiHeading',
                          )}
                          shouldHaveMarginTop={true}
                        />
                        <FormComponents.SectionHeading heading="" />
                      </>
                    )}

                    {(isOI() || isDualProducts()) && (
                      <>
                        <FormComponents.InActive
                          title={t('forms:communication_preferences.type')}
                          content={
                            notificationScopes
                              ? notificationScopes.find(
                                  (scope: LabelValue) =>
                                    scope.value ==
                                    userData?.oiNotificationScope,
                                )?.label
                              : ''
                          }
                        />
                        <FormComponents.InActive
                          title={t('forms:communication_preferences.frequency')}
                          content={
                            (userData?.alertExceeded
                              ? t(
                                  'forms:communication_preferences.frequencies.exceeding',
                                )
                              : '') +
                            (userData?.alertExceeded &&
                            userData?.alertApproaching
                              ? ', '
                              : '') +
                            (userData?.alertApproaching
                              ? t(
                                  'forms:communication_preferences.frequencies.approaching',
                                )
                              : '')
                          }
                        />
                        <FormComponents.InActive
                          title={t('forms:communication_preferences.method')}
                          content={
                            (userData?.oiNotifyViaSms
                              ? t('forms:communication_preferences.methods.sms')
                              : '') +
                            (userData?.oiNotifyViaSms &&
                            userData?.oiNotifyViaEmail
                              ? ', '
                              : '') +
                            (userData?.oiNotifyViaEmail
                              ? t(
                                  'forms:communication_preferences.methods.email',
                                )
                              : '')
                          }
                        />
                      </>
                    )}
                  </>
                ) : (
                  <Spinner color="lightBlueGrey" />
                )}
              </FormComponents.Section>
              <>
                {' '}
                {(isDI() || isDualProducts()) && (
                  <FormComponents.Section
                    heading={t(
                      'profile:communication_preferences.reportsHeading',
                    )}
                    layout={isMobile ? 'single' : 'inactive'}>
                    <FormComponents.InActive
                      title={t(
                        'profile:communication_preferences.reportsSelected',
                      )}
                      content={
                        (userData?.subscribeDIYearlyReport
                          ? t(
                              'profile:communication_preferences.reports.yearly',
                            )
                          : '') +
                        (userData?.subscribeDIYearlyReport &&
                        userData?.subscribeDIMonthlyReport
                          ? ', '
                          : '') +
                        (userData?.subscribeDIMonthlyReport
                          ? t(
                              'profile:communication_preferences.reports.monthly',
                            )
                          : '')
                      }
                    />
                  </FormComponents.Section>
                )}
              </>
            </DetailsContainer>
          )}
        </FormComponents.Wrapper>
      </Container>
    </>
  );
};

export default UserDetails;
