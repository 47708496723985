import styled from '@emotion/styled';
import { ThemeType, flexCenterAll, mq } from 'style';

type ContainerProps = {
  theme: ThemeType;
};

export const Container = styled.div`
  min-height: 400px;
  padding: 10% 5px;
  width: 100%;
  ${flexCenterAll};
  ${mq.mobile()} {
    min-height: 300px;
  }
`;

export const TextContainer = styled.div`
  margin-top: 10px;
  ${flexCenterAll};
  &:first-child {
    margin-top: 0;
  }
`;

export const IconCheckContainer = styled.div`
  margin-right: 6px;
  padding: 5px;
  width: 91px;
  border-radius: 91px;
  ${flexCenterAll};
  border: 6px solid #73ad21;
`;

export const IconContainer = styled.div`
  ${flexCenterAll};
  margin-bottom: 30px;
`;
