import React from 'react';
import styled from '@emotion/styled';
import { Box, Grid, Truncate, Paragraph as P } from 'components';

type WrapperProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  key: string;
  backgroundColor?: string;
};

const StyledWrapper = styled(Box)`
  overflow: hidden;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Wrapper = ({
  children,
  key,
  backgroundColor,
  ...rest
}: WrapperProps) => (
  <StyledWrapper
    padding="15px 20px"
    key={key}
    backgroundColor={backgroundColor}
    {...rest}>
    <Grid
      gridTemplateColumns="repeat(3, 1fr)"
      gridColumnGap="20px"
      alignItems="center"
      gridTemplateRows="repeat(4, max-content)">
      {children}
    </Grid>
  </StyledWrapper>
);

type ItemProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  row?: number | string;
  column?: number | string;
  truncate?: boolean;
};

export const Item = ({ row, column, children, truncate }: ItemProps) => {
  if (truncate) {
    return (
      <Truncate gridRow={row} gridColumn={column}>
        {children}
      </Truncate>
    );
  } else {
    return (
      <Grid gridRow={row} gridColumn={column}>
        {children}
      </Grid>
    );
  }
};

type ColumnHeaderProps = ItemProps & {
  children: string;
  marginTop?: string;
};
export const ColumnHeader = ({ children, row, column, marginTop }: ColumnHeaderProps) => (
  <P size="tiny" uppercase color="blueGrey" gridRow={row} gridColumn={column} marginTop={marginTop}>
    {children}
  </P>
);

const MobileTableComponents = {
  Wrapper,
  Item,
  ColumnHeader,
};
export default MobileTableComponents;
