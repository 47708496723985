import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18n from 'core/i18n/i18n';

import {
  TextField,
  FormComponents,
  SelectField,
  Spinner,
  Flex,
  Heading as H,
  ToolTip,
  Grid,
  Paragraph as P,
} from 'components';
import { useScreenSize } from 'hooks';
import { AccountPicklists, IdValue, LabelValue } from 'models';
import { fetchAccountPicklists } from 'services';
import {
  applyFilterTranslations,
  FilterTranslations,
  isDI,
  isDualProducts,
  isOI,
} from 'utils';
import { Spacer } from 'style';

const { screenSize } = useScreenSize();

export const DIValidation = Yup.object().shape({
  account: Yup.object({
    name: Yup.string()
      .ensure()
      .required(i18n.t('forms:validation.required')),
    email: Yup.string()
      .ensure()
      .email(i18n.t('forms:validation.email')),
    address: Yup.object({
      street: Yup.string()
        .ensure()
        .required(i18n.t('forms:validation.required')),
      city: Yup.string()
        .ensure()
        .required(i18n.t('forms:validation.required')),
      state: Yup.string().ensure(),
      postalCode: Yup.string()
        .ensure()
        .required(i18n.t('forms:validation.required')),
      country: Yup.string()
        .ensure()
        .required(i18n.t('forms:validation.required')),
    }),
    defaultAccountTimezone: Yup.string()
      .ensure()
      .required(i18n.t('forms:validation.required')),
  }),
});

export const OIValidation = Yup.object().shape({
  account: Yup.object({
    name: Yup.string()
      .ensure()
      .required(i18n.t('forms:validation.required')),
    email: Yup.string()
      .ensure()
      .email(i18n.t('forms:validation.email')),
    address: Yup.object({
      street: Yup.string()
        .ensure()
        .required(i18n.t('forms:validation.required')),
      city: Yup.string()
        .ensure()
        .required(i18n.t('forms:validation.required')),
      state: Yup.string().ensure(),
      postalCode: Yup.string()
        .ensure()
        .required(i18n.t('forms:validation.required')),
      country: Yup.string()
        .ensure()
        .required(i18n.t('forms:validation.required')),
    }),
    defaultAccountTimezone: Yup.string()
      .ensure()
      .required(i18n.t('forms:validation.required')),
    oiPerPersonSpaceAllowance: Yup.number().when([], {
      is: () => isOI() || isDualProducts(),
      then: Yup.number()
        .positive(i18n.t('forms:validation.number_positive'))
        .required(i18n.t('forms:validation.required'))
        .typeError(i18n.t('forms:validation.must_be_number')),
    }),
    oiUnitOfMeasure: Yup.string().when([], {
      is: () => isOI() || isDualProducts(),
      then: Yup.string()
        .ensure()
        .required(i18n.t('forms:validation.required')),
    }),
    oiEntranceDisplayMessage: Yup.string()
      .nullable()
      .when([], {
        is: () => isOI() || isDualProducts(),
        then: Yup.string()
          .required(i18n.t('forms:validation.required'))
          .max(255, i18n.t('forms:validation.max_255')),
      }),
  }),
});

export const Validation =
  isOI() || isDualProducts() ? OIValidation : DIValidation;

export type AccountSettingsSchema = Yup.InferType<any>;

// Will be populated by an API GET to the user details
export const initialAccountDetails: AccountSettingsSchema = {
  account: {
    name: '',
    email: '',
    address: {
      street: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
    defaultAccountTimezone: '',
    oiPerPersonSpaceAllowance: 0,
    oiUnitOfMeasure: 'Metre',
    oiEntranceDisplayMessage: '',
  },
};

const unitsTranslations: FilterTranslations = {
  Metres: 'm2',
  Feet: 'ft2',
};

export const Form = ({ values }: any) => {
  const { t } = useTranslation();
  const { isMobile } = screenSize();

  const [picklists, setPicklists] = useState<any>();

  useEffect(() => {
    const fetchPicklists = async () => {
      try {
        const picklistsRes = (await fetchAccountPicklists()) as AccountPicklists;

        const unitsTranslated = await applyFilterTranslations(
          picklistsRes.unitsOfMeasure,
          unitsTranslations,
        );

        setPicklists({
          timezones: picklistsRes.timezones.map((item: IdValue) => ({
            label: item.id,
            value: item.value,
          })),
          unitsOfMeasure: unitsTranslated,
        });
      } catch (err) {
        console.log(err);
      }
    };
    fetchPicklists();
  }, []);

  return (
    <>
      <FormComponents.Section
        layout={isMobile ? 'single' : 'double'}
        heading={t('userOnboarding:section_heading.company_information')}
        headingSize={`large`}>
        <TextField
          disabled
          name="account.name"
          label={t('forms:account_settings.name')}
          required
          tooltip={t('forms:personal_details.tooltip')}
        />
        <TextField
          disabled
          name="account.address.street"
          label={t('forms:account_settings.address_street')}
          required
          tooltip={t('forms:personal_details.tooltip')}
        />
        <TextField
          disabled
          name="account.address.city"
          label={t('forms:account_settings.address_city')}
          required
          tooltip={t('forms:personal_details.tooltip')}
        />
        <TextField
          disabled
          name="account.address.state"
          label={t('forms:account_settings.address_state')}
          tooltip={t('forms:personal_details.tooltip')}
        />
        <TextField
          disabled
          name="account.address.postalCode"
          label={t('forms:account_settings.address_postalcode')}
          required
          tooltip={t('forms:personal_details.tooltip')}
        />
        <TextField
          disabled
          name="account.address.country"
          label={t('forms:account_settings.address_country')}
          required
          tooltip={t('forms:personal_details.tooltip')}
        />

        {picklists ? (
          <SelectField
            disabled
            required
            name="account.defaultAccountTimezone"
            label={t('settings:space_settings.time_zone.label')}
            options={picklists ? (picklists.timezones as LabelValue[]) : []}
            tooltip={t('forms:personal_details.tooltip')}
          />
        ) : (
          <Spinner color="lightBlueGrey" />
        )}
      </FormComponents.Section>

      {(isOI() || isDualProducts()) && (
        <FormComponents.Section
          layout="single"
          heading={t('userOnboarding:section_heading.space_settings')}
          headingSize={`large`}>
          <P size="small" color="charcoal">
            {t('settings:space_settings.description')}
          </P>
          <Flex alignItems="center" width="100%">
            <H size="h6" color="primary" mt="40px" mb="30px">
              {t('settings:space_settings.space_required.description')}
            </H>
            <Spacer width={5} />
            <ToolTip
              iconSize={14}
              content={t('settings:space_settings.space_required.tooltip')}
            />
          </Flex>

          <Grid gridTemplateColumns={['1fr', '1fr 1fr']}>
            <Grid gridTemplateColumns="1fr 30%" gridColumnGap="14px">
              <TextField required name="account.oiPerPersonSpaceAllowance" />
              {picklists ? (
                <SelectField
                  required
                  name="account.oiUnitOfMeasure"
                  options={
                    picklists ? (picklists.unitsOfMeasure as LabelValue[]) : []
                  }
                />
              ) : (
                <Spinner color="lightBlueGrey" />
              )}
            </Grid>
          </Grid>

          <hr />

          <H size="h6" color="primary" mt="40px" mb="30px">
            {t('settings:space_settings.default_message.description')}
          </H>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr']}>
            <TextField
              required
              type="textarea"
              name="account.oiEntranceDisplayMessage"
              label={t('settings:space_settings.default_message.label')}
            />
          </Grid>
        </FormComponents.Section>
      )}
    </>
  );
};
