import styled from '@emotion/styled';
import { theme } from 'style';

export const Container = styled.div<{
  isOffline: boolean;
  isCritical: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 32px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid
    ${(props) =>
      props.isOffline
        ? theme.colors.lightBlueGrey
        : props.isCritical
        ? theme.colors.error
        : theme.colors.primary};
  border-radius: 4px;
  background-color: white;
`;

export const GeneralHeading = styled.text`
  font-size: 12px;
  background-color: #ebf2ff;
  border-radius: 8px;
  padding: 4px 12px;
  align-items: center;
  font-weight: 500;
  margin-left: 12px;
`;

export const CriticalHeading = styled.text`
  color: #e3002a !important;
  font-size: 12px;
  background-color: #ffe9ed;
  border-radius: 8px;
  padding: 4px 12px;
  align-items: center;
  font-weight: 500;
  margin-left: 12px;
`;
