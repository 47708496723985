// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarStack } from '@vx/shape';
import { scaleLinear, scaleOrdinal, scaleBand } from '@vx/scale';
import { AxisBottom } from '@vx/axis';
import { ParentSize } from '@vx/responsive';
import { useTooltip, TooltipWithBounds, defaultStyles } from '@vx/tooltip';
import moment from 'moment';

import Bar from './Bar/Bar';
import Legend from './Legend';
import { Paragraph, Skeleton } from 'components';
import { occupancyChartConfig } from 'utils';
import { theme } from 'style';
import { useSpaceOccupancyStore } from 'stores';
import { useScreenSize } from 'hooks';

export type DataSetType = {
  startDate: string;
  endDate: string;
  exceededPct: number;
  withinPct: number;
  approachingPct: number;
  offlinePct: number;
  futurePct: number;
  notCommissionedPct: number;
  elapsedTimeHours: number;
};

interface Props {
  parentWidth: number;
}

const Chart = ({ parentWidth }: Props) => {
  const { t } = useTranslation();
  const { isLoading, spaceOccupancyData } = useSpaceOccupancyStore();
  const { historic } = spaceOccupancyData;
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  if (isLoading.historic) {
    return <Skeleton height={300} />;
  }

  const getDate = (d: DataSetType) => d.startDate;

  //variables
  const margin = { top: 20, bottom: 20, left: isMobile ? -25 : 20, right: 20 };
  const chartHeight = isMobile ? 250 : 320;
  //max length of x axis
  const xMax = parentWidth - margin.left - margin.right;
  //max length of y axis
  const yMax = chartHeight - 10 - margin.top - margin.bottom;

  //formats dates for ticks
  const formatDate = (date: string) => {
    if (historic.scale === 'day') {
      if (historic.space.dataSeries.length > 7) {
        //this chunk returns every 6th event, with exception to the first and last ticks
        const eventIndex = historic.space.dataSeries.findIndex(
          (event) => event.startDate === date,
        );
        return moment(date).day() === 1 &&
          eventIndex !== 0 &&
          eventIndex !== historic.space.dataSeries.length - 1
          ? (moment(date).format('D MMM') as string)
          : null;
      } else {
        return moment(date).format('D MMM') as string;
      }
    } else if (historic.scale === 'hour') {
      return moment(date).format('H');
    } else {
      //scale is weekly
      const event = historic.space.dataSeries.find((e) => e.startDate === date);
      if (historic?.space.dataSeries.length > 12) {
        const eventIndex = historic.space.dataSeries.findIndex(
          (event) => event.startDate === date,
        );
        return eventIndex % 2
          ? `${moment(event?.startDate).format('D')}-${moment(
              event?.endDate,
            ).format('D MMM')}`
          : null;
      } else {
        return `${moment(event?.startDate).format('D')}-${moment(
          event?.endDate,
        ).format('D MMM')}`;
      }
    }
  };

  //a function that tries to ensure that bars remain 10px wide.
  //pulled this formula out of thin air, so may not be super pixel perfect, but looks right eyeballing it
  const generatePadding = () => {
    return 1 - Math.round((historic.space.dataSeries.length / xMax) * 100) / 10;
  };

  //Scales, functions that plot data points to physical locations
  const dateScale = scaleBand({
    domain: historic.space.dataSeries.map(getDate),
    rangeRound: [0, xMax],
    padding: generatePadding(),
  });

  const pctScale = scaleLinear<number>({
    domain: [
      0,
      Math.max(
        ...historic.space.dataSeries.map(
          (el) =>
            el.exceededPct +
            el.approachingPct +
            el.offlinePct +
            el.withinPct +
            el.notCommissionedPct +
            el.futurePct,
        ),
      ),
    ],
    range: [yMax, 0],
  });

  // replaced the key array with a glboal config
  const colorScale = scaleOrdinal<any, string>({
    domain: occupancyChartConfig.map((key) => key.key.percent),
    range: occupancyChartConfig.map((key) => theme.colors[key.color]),
  });

  return (
    <div style={{ position: 'relative' }}>
      {isMobile && <Legend colorScale={colorScale} />}
      <svg height={chartHeight} width={parentWidth}>
        <BarStack
          data={historic.space.dataSeries}
          keys={occupancyChartConfig.map((key) => key.key.percent)}
          x={getDate}
          xScale={dateScale}
          yScale={pctScale}
          color={colorScale}>
          {(barStacks) =>
            barStacks.map((barStack) =>
              barStack.bars.map((bar) => (
                <Bar
                  bar={bar}
                  barStack={barStack}
                  showTooltip={showTooltip}
                  hideTooltip={hideTooltip}
                  tooltipData={tooltipData}
                  tooltipOpen={tooltipOpen}
                  key={`bar-stack-${barStack.index}-${bar.index}`}
                />
              )),
            )
          }
        </BarStack>
        <AxisBottom
          top={chartHeight - (isMobile ? 60 : 45)}
          scale={dateScale}
          tickFormat={formatDate}
          tickStroke="FFF"
          numTicks={historic.space.dataSeries.length}
          strokeWidth={0}
          tickLabelProps={(tickValue) => ({
            fill: theme.colors.charcoal,
            fontSize: isMobile ? 10 : 14,
            textAnchor: 'middle',
            fontWeight:
              tooltipOpen && tooltipData.bar.data.startDate === tickValue
                ? 'bold'
                : 'normal',
          })}
        />

        {historic?.scale === 'hour' && (
          <AxisBottom
            top={chartHeight - 25}
            scale={dateScale}
            tickFormat={(d) => moment(d).format('A')}
            tickStroke="FFF"
            numTicks={2}
            strokeWidth={0}
            tickLabelProps={(tickValue) => ({
              fill: theme.colors.charcoal,
              fontSize: isMobile ? 10 : 14,
              textAnchor: 'middle',
              fontWeight: 'bold',
            })}
          />
        )}
      </svg>
      {tooltipOpen && (
        <TooltipWithBounds
          key={Math.random()}
          top={tooltipTop}
          style={{
            ...defaultStyles,
            border: theme.borders(1, 'grey'),
            padding: '15px',
          }}
          left={tooltipLeft}>
          <Paragraph color="grey" size="small">
            {historic?.scale === 'week'
              ? `${moment(tooltipData.bar.data.startDate).format(
                  'ddd D',
                )} - ${moment(tooltipData.bar.data.endDate).format(
                  'ddd D MMM YYYY',
                )}`
              : moment(tooltipData.bar.data.startDate).format('ddd D MMM YYYY')}
          </Paragraph>
          {occupancyChartConfig
            .filter((key) => key.name !== 'future')
            .map((key) => (
              <Paragraph size="small" color="charcoal" key={key.key.percent}>
                {t(key.label.historic.toString())}:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {tooltipData.bar.data[key.key.percent]}%
                </span>
              </Paragraph>
            ))}
        </TooltipWithBounds>
      )}
      {!isMobile && <Legend colorScale={colorScale} />}
    </div>
  );
};

const SpaceHistoricOccupancyChart = () => (
  <ParentSize>{(parent) => <Chart parentWidth={parent.width} />}</ParentSize>
);
export default SpaceHistoricOccupancyChart;
