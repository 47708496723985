import React, { useState, useEffect } from 'react';
import { LineChart, Tooltip, Line, ResponsiveContainer, XAxis } from 'recharts';
import moment from 'moment';

import { ChartComponents, Skeleton } from 'components';
import { useScreenSize } from 'hooks';
import { theme } from 'style';
import { DailyTraffic as DailyTrafficType } from 'models';
import CustomTooltip from './Tooltip/Tooltip';
import {
  generateChartData,
  generateDomains,
  xTickFormatter,
} from '../../utils';
import { getDeviceColor } from '../DailyTrafficTable/DailyTrafficTable';

interface DailyTraffic {
  data: DailyTrafficType;
  datePeriod: 'Today' | 'Yesterday';
  isLoading: boolean;
  activeDevice?: any;
  trafficDirection: 'In' | 'Out';
}

export const DailyTrafficChart = ({
  data,
  datePeriod,
  isLoading,
  activeDevice,
  trafficDirection,
}: DailyTraffic) => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  const activeDeviceIndex =
    activeDevice &&
    data.devices.findIndex((device) => device.id === activeDevice.id);
  const activeStroke = getDeviceColor(activeDeviceIndex);
  const opacityStroke = `${getDeviceColor(activeDeviceIndex)}40`;

  return (
    <ResponsiveContainer
      height={275}
      width="100%"
      data-testid="daily-traffic-chart">
      {!isLoading ? (
        <LineChart
          data={generateChartData(data, trafficDirection, activeDevice)}
          margin={{ top: 5, left: -15, bottom: 5, right: 10 }}>
          {ChartComponents.CartesianGrid()}

          {activeDevice
            ? ['In', 'Out'].map((item: any) => (
                <Line
                  key={item}
                  isAnimationActive={false}
                  type="linear"
                  dataKey={`${item.toLowerCase()}Count`}
                  dot={{ strokeWidth: 3 }}
                  stroke={
                    item === trafficDirection ? activeStroke : opacityStroke
                  }
                />
              ))
            : data.devices.map((device, i) => {
                return (
                  <Line
                    isAnimationActive={false}
                    key={i}
                    type="linear"
                    dataKey={device.deviceName}
                    dot={{ strokeWidth: 3 }}
                    stroke={getDeviceColor(i)}
                  />
                );
              })}

          <Tooltip
            content={<CustomTooltip />}
            cursor={{ stroke: theme.colors.primary }}
            allowEscapeViewBox={{ x: !isMobile, y: !isMobile }}
          />
          {ChartComponents.YAxis()}
          <XAxis
            domain={generateDomains(datePeriod) as any}
            dataKey="from"
            xAxisId={0}
            tick={{
              fontSize: '14px',
              lineHeight: '21px',
              fill: theme.colors.charcoal,
            }}
            axisLine={false}
            tickLine={false}
            type="number"
            tickMargin={19}
            ticks={Array.apply(0, Array(24)).map((el, i) =>
              moment()
                .subtract(datePeriod === 'Today' ? 0 : 1, 'day')
                .startOf('day')
                .add(i, 'hours')
                .unix(),
            )}
            tickFormatter={xTickFormatter}
          />
          <XAxis
            dataKey="from"
            xAxisId={1}
            domain={generateDomains(datePeriod) as any}
            type="number"
            tick={{
              fontSize: '14px',
              lineHeight: '21px',
              fontWeight: 'bold',
              textAlign: 'left',
              fill: theme.colors.charcoal,
            }}
            tickMargin={10}
            tickFormatter={(time) => moment.unix(time).format('A')}
            axisLine={false}
            tickLine={false}
            ticks={[
              moment()
                .subtract(datePeriod === 'Today' ? 0 : 1, 'day')
                .startOf('day')
                .unix(),
              moment()
                .subtract(datePeriod === 'Today' ? 0 : 1, 'day')
                .startOf('day')
                .add(12, 'hours')
                .unix(),
            ]}
          />
        </LineChart>
      ) : (
        <Skeleton height={275} />
      )}
    </ResponsiveContainer>
  );
};

export default DailyTrafficChart;
