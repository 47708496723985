import create from 'zustand';

import { fetchSpaceDetails } from 'services';
import { SpaceDetails } from 'models';

interface SpaceDetailsStore {
  spaceDetailsData: SpaceDetails;
  spaceDetailsError: string | null;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  spaceDetailsFetch: (id: string) => void;
  cleanUpSpaceDetailsData: () => void;
}

const [useSpaceDetailsStore] = create<SpaceDetailsStore>((set, get) => ({
  setIsLoading: (isLoading: boolean) => {
    set({ isLoading });
  },
  spaceDetailsData: {} as SpaceDetails,
  spaceDetailsError: null,
  isLoading: true,
  spaceDetailsFetch: async (id: string) => {
    try {
      const res = await fetchSpaceDetails(id);
      set({ spaceDetailsData: await res, isLoading: false });
      return res;
    } catch (err) {
      if (err?.response?.status === 403) {
        set({
          spaceDetailsError: '403: This spaces does not belong to this account',
          isLoading: false,
        });
      }
    }
  },
  cleanUpSpaceDetailsData: () => {
    set({
      spaceDetailsData: {} as SpaceDetails,
      isLoading: true,
      spaceDetailsError: null,
    });
  },
}));

export default useSpaceDetailsStore;
