import styled from '@emotion/styled';
import { Paragraph } from 'components';
import { HeadingProps } from 'components/Paragraph/Paragraph';

interface LinkProps extends HeadingProps {
  href?: string;
}

export const StyledLink = styled(Paragraph)<LinkProps>`
  /* color: black; */
  text-decoration: underline;
  cursor: pointer;
`;
