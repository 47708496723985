import React from 'react';
import { GridRowProps, GridColumnProps } from 'styled-system';

import { StyledDiv } from './truncateStyle';

interface Props extends GridRowProps, GridColumnProps {
  children: React.ReactNode;
}

const Truncate = ({ children, ...rest }: Props) => {
  return <StyledDiv {...rest}>{children}</StyledDiv>;
};

export default Truncate;
