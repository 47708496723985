import create from 'zustand';

import { fetchDoorsStats } from 'services';
import { DoorsStats } from 'models/DoorsStats';

interface DoorsStatsStore {
  doorsStatsData: DoorsStats;
  isLoading: boolean;
  doorsStatsFetch: (props: {
    update?: boolean;
    followedOnly?: boolean;
    accountId?: string;
  }) => void;
}

const [useDoorsStatsStore] = create<DoorsStatsStore>((set) => ({
  doorsStatsData: {} as DoorsStats,
  isLoading: true,
  doorsStatsFetch: async ({ update = false, followedOnly = false, accountId = '' }) => {
    try {
      if (!update) {
        set({ isLoading: true });
      }
      const res = await fetchDoorsStats(followedOnly, accountId);
      set({ doorsStatsData: await res, isLoading: false });
    } catch (err) {
      set({ doorsStatsData: {} as DoorsStats, isLoading: false });
      throw err;
    }
  },
}));

export default useDoorsStatsStore;
