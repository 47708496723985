import React, { useEffect, useState } from 'react';
import { Paragraph as P, Heading as H, Skeleton, Button } from 'components';
import { Spacer } from 'style';
import { useAlertStore } from 'stores';
import {
  NoAlertContainer,
  SectionHeader,
  SectionHeaderActions,
} from './alertStyle';
import AlertRow from './AlertRow/AlertRow';
import { useTranslation } from 'react-i18next';
import { User } from 'models';
import { useHistory } from 'react-router';

interface AlertProps {
  doorId: string;
  triggerUpdate: boolean;
  isOffline: boolean;
}

const Alert = ({ doorId, triggerUpdate, isOffline }: AlertProps) => {
  const {
    activeAlertData,
    activeAlertFetch,
    activeAlertIsLoading,
  } = useAlertStore();
  const { t } = useTranslation();
  const history = useHistory();

  const [userMe, setUserMe] = useState<User>({} as User);
  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  useEffect(() => {
    activeAlertFetch(false, doorId);
  }, [doorId, triggerUpdate]);

  const viewAllAlerts = () => {
    history.push(`/notifications?doorUUID=${doorId}`);
  };

  return (
    <>
      <SectionHeader>
        <H color="primary" size="h3" mb={0}>
          {t('dashboard:current_alert')}
        </H>
        <SectionHeaderActions>
          <Button
            variant="outlined"
            icon="alert-triangle"
            onClick={viewAllAlerts}>
            {t('alerts:actions.view_all')}
          </Button>
        </SectionHeaderActions>
      </SectionHeader>
      <Spacer height={20} />

      {activeAlertIsLoading && <Skeleton width="100%" height={64} />}

      {activeAlertData.length === 0 && !activeAlertIsLoading && (
        <NoAlertContainer isOffline={isOffline}>
          <div>
            <H size="h6" color="primary" mr="8px" textAlign="center">
              {isOffline
                ? t('common:status.unavailable')
                : t('doorDetails:no_current_alerts')}
            </H>
            <P size="normal" color="charcoal" textAlign="center" mt="10px">
              {isOffline
                ? t('common:status_tooltip.offline_unavailable')
                : t('doorDetails:no_alert_subtitle')}
            </P>
          </div>
        </NoAlertContainer>
      )}

      {activeAlertData.length > 0 &&
        !activeAlertIsLoading &&
        activeAlertData
          .sort((a1, a2) => Number(a2.isCritical) - Number(a1.isCritical))
          .map((alert, index) => {
            const { name } = alert;
            return (
              <AlertRow
                doorId={doorId}
                alert={alert}
                isOffline={isOffline}
                key={`${name}_${index}}`}
              />
            );
          })}
    </>
  );
};

export default Alert;
