import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { IcomoonIcons } from 'components';
import ReactHtmlParser from 'react-html-parser';

import { StyledDiv, ContentPopup, Triangle, SpaceBetweenPopupAndIcon } from './toolTipStyle';
import { IcomoonIconNames } from 'components/IcomoonIcons/IcomoonIcons';

interface Props {
  tooltipId?: string;
  content: string;
  iconSize?: string | number;
  iconType?: 'question' | 'info' | 'offline' | 'refresh';
  isInline?: boolean;
  shouldToggleVisibilityOnClick?: boolean;
}

const ToolTip: React.FC<Props> = ({
  tooltipId,
  content,
  iconType = 'info',
  iconSize,
  isInline = false,
  shouldToggleVisibilityOnClick = false,
}) => {
  const WrapperRef = useRef(document.createElement('div'));
  const PopupRef = useRef(document.createElement('div'));

  const [isShown, setIsShown] = useState(false);
  const [popupHeight, setPopupHeight] = useState(0);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    isShown && setPopupHeight(PopupRef.current.clientHeight);
  }, [isShown]);

  return (
    <StyledDiv
      isInline = {isInline}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(isFixed)}
      onClick={() => {
        if(shouldToggleVisibilityOnClick) {
          setIsFixed(!isFixed);
          setIsShown(!isFixed);
        }        
      }
    }
      ref={WrapperRef}
      data-testid="tooltip">
      {getIconName(iconType).length > 0 && (
        <IcomoonIcons icon={getIconName(iconType)} color={getIconColour(iconType)} size={iconSize} />
      )}
      
      {isShown &&
        ReactDOM.createPortal(          
          <ContentPopup
            ref={PopupRef}
            height={popupHeight}
            position={WrapperRef.current.getBoundingClientRect()}
            data-testid="tooltip-content"
            >
            <Triangle />
            <SpaceBetweenPopupAndIcon/>
            {ReactHtmlParser(content)}            
          </ContentPopup>
          ,
          document.body,
        )}
    </StyledDiv>
  );
};

type IconType = IcomoonIconNames | 'question';

const getIconColour = (iconType: IconType) => iconType === 'refresh' ? 'success' : 'blueGrey'

const getIconName = (iconType: IconType): IcomoonIconNames => iconType === 'question' ? 'help-circle' : iconType;

export default ToolTip;