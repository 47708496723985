import styled from '@emotion/styled';
import { ThemeType, flexCenter, theme, contentBody, mq } from 'style';
import { color, layout } from 'styled-system';

type OuterProps = {
  theme: ThemeType;
  color?: string;
  headerBackgroundImage?: string;
  backgroundColor?: boolean | string;
};

export const BackgroundImage = styled.div<{ headerBackgroundImage?: string }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: ${(props) => `url(${props.headerBackgroundImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
`;

export const OuterContainer = styled.div<OuterProps>`
  z-index: 1;
  position: relative;
  ${flexCenter};
  width: 100%;
  height: auto;
  min-height: 140px;
  background-color: ${theme.colors.primary};
  ${color};
  ${layout};
  ${mq.mobile()} {
    min-height: 0;
    padding: 12px 19px 19px;
  }
`;

//not sure what to do about these settings props
export const InnerContainer = styled.div`
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${contentBody};
  ${mq.mobile()} {
    grid-template-columns: 1fr;
    overflow: hidden;
    padding-left: 5px;
  }
`;

export const Headings = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;
