import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Formik, Form } from 'formik';

import ChangePasswordModal from './ChangePasswordModal/ChangePasswordModal';

import {
  FormComponents,
  Heading as H,
  Paragraph as P,
  Button,
  Container,
  PageHeader,
  Spinner,
  FloatingActionBar,
} from 'components';
import {
  AccountDetails,
  CommunicationPreferences,
  ReportPreferences,
} from './forms';
import {
  UserUpdate,
  LabelValue,
  IdValue,
  User,
  NotificationType,
  LabelValueWithIsCriticalField,
  NotifcationTypeFilter,
} from 'models';
import {
  updateUser,
  fetchNotificationScopes,
  fetchUserMeDetails,
  fetchAvailableNotificationTypes,
} from 'services';
import { useScreenSize } from 'hooks';
import { useModalStore } from 'stores';
import {
  FilterTranslations,
  applyFilterTranslations,
  userMeStorage,
  isDualProducts,
  isDI,
  isDIS,
  isOI,
  shouldShowModeElement,
} from 'utils';
import { sortBy } from 'lodash';
import { getAlertTypeNames } from 'utils/getAlertTypeNames';
import { getNotificationTypeOptions } from 'utils/getNotificationTypeOptions';

const OIScopeTranslations: FilterTranslations = {
  Following: i18n.t('forms:communication_preferences.typesOI.following'),
  All: i18n.t('forms:communication_preferences.typesOI.all'),
  'N/A': i18n.t('forms:communication_preferences.frequencies.none'),
};

const DIScopeTranslations: FilterTranslations = {
  Following: i18n.t('forms:communication_preferences.typesDI.following'),
  All: i18n.t('forms:communication_preferences.typesDI.all'),
  'N/A': i18n.t('forms:communication_preferences.frequencies.none'),
};

const languageSetting = (lang: string) => i18n.t('languages:' + lang);

const { screenSize } = useScreenSize();

const Profile = () => {
  const { t } = useTranslation();
  const { isMobile } = screenSize();

  const [userMe, setUserMe] = useState<User>({} as User);
  const [showMobileWarningDI, setShowMobileWarningDI] = useState<boolean>(
    false,
  );
  const [showMobileWarningOI, setShowMobileWarningOI] = useState<boolean>(
    false,
  );
  const [submitIsDisabledDI, setSubmitIsDisabledDI] = useState<boolean>(false);
  const [submitIsDisabledOI, setSubmitIsDisabledOI] = useState<boolean>(false);

  useEffect(() => {
    const x = window.localStorage.getItem('userMe');
    if (x) {
      setUserMe(JSON.parse(x));
    }
  }, []);

  const [isEditUser, setIsEditUser] = useState<boolean>(false);
  const [isEditNotifications, setIsEditNotifications] = useState<boolean>(
    false,
  );
  const toggleEditUser = () => {
    setIsEditUser(!isEditUser);
    window.scrollTo(0, 0);
  };

  const toggleEditNotifications = () => {
    setIsEditNotifications(!isEditNotifications);
    window.scrollTo(0, 0);
  };

  const disableSubmitLogicOI = (props: any) => {
    if (!props.values.OINotificationPreferences.what)
      return setSubmitIsDisabledOI(true);

    if (props.values.OINotificationPreferences.what !== 'N/A') {
      if (
        !props.values.OINotificationPreferences.how.includes('email') &&
        !props.values.OINotificationPreferences.how.includes('sms')
      ) {
        return setSubmitIsDisabledOI(true);
      }

      if (
        !props.values.OINotificationPreferences.when.includes(
          'alertApproaching',
        ) &&
        !props.values.OINotificationPreferences.when.includes('alertExceeded')
      ) {
        return setSubmitIsDisabledOI(true);
      }

      if (
        props.values.OINotificationPreferences.how.includes('sms') &&
        showMobileWarningOI
      ) {
        return setSubmitIsDisabledOI(true);
      }
    }
    setSubmitIsDisabledOI(false);
  };

  const disableSubmitLogicDI = (props: any) => {
    if (
      !props.values.DINotificationPreferences.what ||
      !props.values.DINotificationPreferences.mode
    ) {
      return setSubmitIsDisabledDI(true);
    }

    if (
      props.values.DINotificationPreferences.what !== 'N/A' ||
      props.values.DINotificationPreferences.mode !== 'N/A'
    ) {
      if (
        (!props.values.DINotificationPreferences?.how?.includes('sms') &&
          !props.values.DINotificationPreferences?.how?.includes('email')) ||
        (props.values.DINotificationPreferences.what !== 'N/A' &&
          (props.values.DINotificationPreferences.generalAlertTypes?.length ??
            0) +
            (props.values.DINotificationPreferences.criticalAlertTypes
              ?.length ?? 0) ===
            0)
      ) {
        return setSubmitIsDisabledDI(true);
      }

      if (
        props.values.DINotificationPreferences.how.includes('sms') &&
        showMobileWarningDI
      ) {
        return setSubmitIsDisabledDI(true);
      }
    }
    setSubmitIsDisabledDI(false);
  };

  const noMobileWarningOI = (props: any) => {
    if (
      props.values.OINotificationPreferences.how.includes('sms') &&
      !props.values.mobile
    ) {
      setShowMobileWarningOI(true);
    } else {
      setShowMobileWarningOI(false);
    }
  };

  const noMobileWarningDI = (props: any) => {
    if (
      props.values.DINotificationPreferences.how.includes('sms') &&
      !props.values.mobile
    ) {
      setShowMobileWarningDI(true);
    } else {
      setShowMobileWarningDI(false);
    }
  };

  const [OINotificationScopes, setOINotificationScopes] = useState<
    LabelValue[]
  >();
  const [DINotificationScopes, setDINotificationScopes] = useState<
    LabelValue[]
  >();
  useEffect(() => {
    const fetchScopes = async () => {
      try {
        const notificationScopesRes = (await fetchNotificationScopes()) as IdValue[];
        const OINotificationScopesTranslated = await applyFilterTranslations(
          notificationScopesRes,
          OIScopeTranslations,
        );

        const DINotificationScopesTranslated = await applyFilterTranslations(
          notificationScopesRes,
          DIScopeTranslations,
        );

        setOINotificationScopes(
          sortBy(OINotificationScopesTranslated, ['label']),
        );
        setDINotificationScopes(
          sortBy(DINotificationScopesTranslated, ['label']),
        );
      } catch (err) {
        console.log(err);
      }
    };
    fetchScopes();
  }, []);

  const onSubmit = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true);

    try {
      const userUpdatePayload: UserUpdate = {
        firstName: values.firstName,
        lastName: values.lastName,
        salutation: values.salutation,
        honorific: values.honorific,
        mobileNumber: values.mobile,
        phoneNumber: values.phone,
        language: values.language,
        oiNotificationScope: values.OINotificationPreferences?.what,
        alertExceeded: values.OINotificationPreferences?.when?.includes(
          'alertExceeded',
        ),
        alertApproaching: values.OINotificationPreferences?.when?.includes(
          'alertApproaching',
        ),
        oiNotifyViaEmail: values.OINotificationPreferences?.how?.includes(
          'email',
        ),
        oiNotifyViaSms: values.OINotificationPreferences?.how?.includes('sms'),
        modeNotificationScope: values.DINotificationPreferences?.mode,
        diNotificationScope: values.DINotificationPreferences?.what,
        diNotifyViaEmail: values.DINotificationPreferences?.how?.includes(
          'email',
        ),
        diNotifyViaSms: values.DINotificationPreferences?.how?.includes('sms'),
        diNotificationAlertTypes:
          values.DINotificationPreferences?.what === 'N/A'
            ? []
            : (
                values.DINotificationPreferences?.generalAlertTypes ?? []
              ).concat(
                values.DINotificationPreferences?.criticalAlertTypes ?? [],
              ),
        subscribeDIMonthlyReport: values.diReportPreferences?.includes(
          'Monthly',
        ),
        subscribeDIYearlyReport: values.diReportPreferences?.includes('Yearly'),
      };
      const res = await updateUser(userUpdatePayload, alertTypes);

      if (res) {
        const update = await fetchUserMeDetails();
        if (update) {
          setUserMe(update);
          await userMeStorage.set(update);
          i18n.changeLanguage(update.language);
          window.location.reload();
        }
        setIsEditUser(false);
        setIsEditNotifications(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const { changePasswordModal } = useModalStore();

  const [alertTypes, setAlertTypes] = useState<LabelValueWithIsCriticalField[]>(
    [],
  );

  const fetchAlertTypes = async () => {
    const alertTypesData = await fetchAvailableNotificationTypes();
    const alertTypeOptions = getNotificationTypeOptions(
      alertTypesData ?? [],
      NotificationType.Alert,
      true,
    );
    setAlertTypes(alertTypeOptions);
  };

  useEffect(() => {
    fetchAlertTypes();
  }, []);

  return (
    <>
      <ChangePasswordModal />

      {!isMobile ? (
        <PageHeader
          heading={t('profile:heading')}
          actionComponents={
            <Button
              inverse
              onClick={changePasswordModal.toggleModal}
              disabled={isEditUser || isEditNotifications}>
              {t('profile:change_password_form.button')}
            </Button>
          }
        />
      ) : (
        <FloatingActionBar>
          <Button
            onClick={changePasswordModal.toggleModal}
            disabled={isEditUser}
            stretch>
            {t('profile:change_password_form.button')}
          </Button>
        </FloatingActionBar>
      )}

      <Container>
        {!isEditNotifications &&
          (userMe ? (
            <FormComponents.Wrapper>
              <FormComponents.Header
                heading={t('profile:form_header.inactive')}
                onClick={!isEditUser ? toggleEditUser : undefined}
              />
              {isEditUser ? (
                <Formik
                  initialValues={{
                    ...userMe,
                    DINotificationPreferences: {
                      what: userMe.diNotificationScope ?? 'All',
                      how: [
                        userMe.diNotifyViaEmail ? 'email' : '',
                        userMe.diNotifyViaSms ? 'sms' : '',
                      ],
                      generalAlertTypes: userMe.generalAlertTypes ?? [],
                      criticalAlertTypes: userMe.criticalAlertTypes ?? [],
                    },
                    OINotificationPreferences: {
                      what: userMe.oiNotificationScope,
                      when: [
                        userMe.alertExceeded ? 'alertExceeded' : '',
                        userMe.alertApproaching ? 'alertApproaching' : '',
                      ],
                      how: [
                        userMe.oiNotifyViaEmail ? 'email' : '',
                        userMe.oiNotifyViaSms ? 'sms' : '',
                      ],
                    },
                  }}
                  validationSchema={AccountDetails.Validation}
                  onSubmit={(values, { setSubmitting }) => {
                    onSubmit(values, setSubmitting);
                  }}>
                  {(props) => {
                    if (props.values.DINotificationPreferences.what === 'N/A') {
                      props.values.DINotificationPreferences.how = [];
                    }

                    if (props.values.OINotificationPreferences.what === 'N/A') {
                      props.values.OINotificationPreferences.how = [];
                      props.values.OINotificationPreferences.when = [];
                    }

                    return (
                      <Form data-testid="edit-profile-form">
                        <AccountDetails.Form values={props.values} />

                        {isMobile && (
                          <FormComponents.Section layout="single">
                            <P size="small" color="charcoal">
                              {t('forms:required.fields')}
                            </P>
                          </FormComponents.Section>
                        )}

                        <FormComponents.Submit
                          label={t('profile:buttons.save_changes')}
                          cancelAction={toggleEditUser}
                          showRequiredText
                          {...props}
                        />
                      </Form>
                    );
                  }}
                </Formik>
              ) : (
                <>
                  <FormComponents.Section
                    heading={t('profile:personal_information.heading')}
                    layout={isMobile ? 'single' : 'inactive'}>
                    <FormComponents.InActive
                      title={t('forms:personal_details.salutation')}
                      content={userMe?.salutation}
                    />
                    <FormComponents.InActive
                      title={t('forms:personal_details.honorific')}
                      content={userMe?.honorific}
                    />
                    <FormComponents.InActive
                      title={t('forms:personal_details.first_name')}
                      content={userMe?.firstName}
                    />
                    <FormComponents.InActive
                      title={t('forms:personal_details.last_name')}
                      content={userMe?.lastName}
                    />
                    <FormComponents.InActive
                      title={t('forms:personal_details.mobile_no')}
                      content={userMe?.mobile}
                    />
                    <FormComponents.InActive
                      title={t('forms:personal_details.phone_no')}
                      content={userMe?.phone}
                    />
                    <FormComponents.InActive
                      title={t('forms:personal_details.email')}
                      content={userMe?.email}
                    />
                    <FormComponents.InActive
                      title={t('forms:personal_details.account')}
                      content={userMe?.account?.name}
                    />
                    <FormComponents.InActive
                      title={t('forms:personal_details.user_type')}
                      content={userMe?.userRole}
                    />
                    <FormComponents.InActive
                      title={t('forms:personal_details.language')}
                      content={languageSetting(userMe?.language)}
                    />
                  </FormComponents.Section>
                </>
              )}
            </FormComponents.Wrapper>
          ) : (
            <H>Error: No user data</H>
          ))}
      </Container>

      <Container mb={isMobile ? 120 : 30}>
        {!isEditUser &&
          (userMe ? (
            <FormComponents.Wrapper>
              <FormComponents.Header
                heading={t('profile:communication_preferences.manage_heading')}
                onClick={
                  !isEditNotifications ? toggleEditNotifications : undefined
                }
              />

              {isEditNotifications ? (
                <Formik
                  initialValues={{
                    ...userMe,
                    DINotificationPreferences: {
                      what: userMe.diNotificationScope,
                      when: [
                        userMe.alertExceeded ? 'alertExceeded' : '',
                        userMe.alertApproaching ? 'alertApproaching' : '',
                      ],
                      mode: !isOI()
                        ? userMe.modeNotificationScope ?? 'N/A'
                        : undefined,
                      how: [
                        userMe.diNotifyViaEmail ? 'email' : '',
                        userMe.diNotifyViaSms && userMe.mobile ? 'sms' : '',
                      ],
                      generalAlertTypes: userMe.generalAlertTypes ?? [],
                      criticalAlertTypes: userMe.criticalAlertTypes ?? [],
                    },
                    OINotificationPreferences: {
                      what:
                        isOI() || isDualProducts()
                          ? userMe.oiNotificationScope ?? 'N/A'
                          : undefined,
                      when: [
                        userMe.alertExceeded ? 'alertExceeded' : '',
                        userMe.alertApproaching ? 'alertApproaching' : '',
                      ],
                      how: [
                        userMe.oiNotifyViaEmail ? 'email' : '',
                        userMe.oiNotifyViaSms && userMe.mobile ? 'sms' : '',
                      ],
                    },
                    diReportPreferences: [
                      userMe.subscribeDIYearlyReport ? 'Yearly' : undefined,
                      userMe.subscribeDIMonthlyReport ? 'Monthly' : undefined,
                    ],
                  }}
                  validationSchema={AccountDetails.Validation}
                  onSubmit={(values, { setSubmitting }) => {
                    onSubmit(values, setSubmitting);
                  }}>
                  {(props) => {
                    if (
                      props.values.DINotificationPreferences.what === 'N/A' &&
                      props.values.DINotificationPreferences.mode === 'N/A'
                    ) {
                      props.values.DINotificationPreferences.how = [];
                      props.values.DINotificationPreferences.when = [];
                    }

                    if (props.values.OINotificationPreferences.what === 'N/A') {
                      props.values.OINotificationPreferences.how = [];
                      props.values.OINotificationPreferences.when = [];
                    }

                    userMe.diNotifyViaEmail =
                      props?.values?.DINotificationPreferences.how.indexOf(
                        'email',
                      ) >= 0;
                    userMe.diNotifyViaSms =
                      props?.values?.DINotificationPreferences.how.indexOf(
                        'sms',
                      ) >= 0;

                    disableSubmitLogicOI(props);
                    disableSubmitLogicDI(props);
                    noMobileWarningOI(props);
                    noMobileWarningDI(props);

                    return (
                      <Form data-testid="edit-profile-form">
                        <CommunicationPreferences.Form
                          DINotificationScopes={
                            DINotificationScopes
                              ? DINotificationScopes
                              : ([] as LabelValue[])
                          }
                          OINotificationScopes={
                            OINotificationScopes
                              ? OINotificationScopes
                              : ([] as LabelValue[])
                          }
                          doesUserWantNotificationsDI={
                            (props.values.DINotificationPreferences.what &&
                              props.values.DINotificationPreferences.what !==
                                'N/A') ||
                            (props.values.DINotificationPreferences.mode &&
                              props.values.DINotificationPreferences.mode !==
                                'N/A')
                              ? true
                              : false
                          }
                          doesUserWantAlertsDI={
                            props.values.DINotificationPreferences.what
                              ? props.values.DINotificationPreferences.what !==
                                'N/A'
                              : false
                          }
                          removeOINotifications={
                            props.values.OINotificationPreferences.what &&
                            props.values.OINotificationPreferences.what !==
                              'N/A'
                              ? true
                              : false
                          }
                          showMobileWarningDI={showMobileWarningDI}
                          showMobileWarningOI={showMobileWarningOI}
                          showNoAlertTypeWarningDI={
                            (props.values.DINotificationPreferences
                              .generalAlertTypes?.length ?? 0) +
                              (props.values.DINotificationPreferences
                                .criticalAlertTypes?.length ?? 0) ===
                            0
                          }
                        />

                        {(isDI() || isDualProducts()) && (
                          <ReportPreferences.Form />
                        )}

                        {isMobile && (
                          <FormComponents.Section layout="single">
                            <P size="small" color="charcoal">
                              {t('forms:required.fields')}
                            </P>
                          </FormComponents.Section>
                        )}

                        <FormComponents.Submit
                          label={t('profile:buttons.save_changes')}
                          cancelAction={toggleEditNotifications}
                          showRequiredText={!isDualProducts}
                          isDisabled={submitIsDisabledOI || submitIsDisabledDI}
                          {...props}
                        />
                      </Form>
                    );
                  }}
                </Formik>
              ) : (
                <>
                  <FormComponents.Section
                    heading={t('profile:communication_preferences.diHeading')}
                    layout={DINotificationScopes ? 'inactive' : 'single'}>
                    {DINotificationScopes ? (
                      <>
                        <FormComponents.InActive
                          title={t('forms:communication_preferences.alerts')}
                          content={
                            DINotificationScopes
                              ? DINotificationScopes.find(
                                  (scope: LabelValue) =>
                                    scope.value == userMe?.diNotificationScope,
                                )?.label
                              : ''
                          }
                          contentVerticalLayout="block"
                        />
                        <FormComponents.InActive
                          title={t(
                            'forms:communication_preferences.critical_alerts_selected',
                          )}
                          content={getAlertTypeNames(
                            userMe,
                            alertTypes,
                            NotifcationTypeFilter.CriticalOnly,
                          )}
                        />
                        <FormComponents.InActive
                          title={t(
                            'forms:communication_preferences.general_alerts_selected',
                          )}
                          content={getAlertTypeNames(
                            userMe,
                            alertTypes,
                            NotifcationTypeFilter.GeneralOnly,
                          )}
                        />
                        {shouldShowModeElement() && (
                          <FormComponents.InActive
                            title={t(
                              'forms:communication_preferences.mode_changes',
                            )}
                            content={
                              DINotificationScopes
                                ? DINotificationScopes.find(
                                    (scope: LabelValue) =>
                                      scope.value ==
                                      userMe?.modeNotificationScope,
                                  )?.label
                                : ''
                            }
                          />
                        )}
                        <FormComponents.InActive
                          title={t('forms:communication_preferences.method')}
                          content={
                            (userMe?.diNotifyViaSms && userMe.mobile
                              ? t('forms:communication_preferences.methods.sms')
                              : '') +
                            (userMe?.diNotifyViaSms && userMe?.diNotifyViaEmail
                              ? ', '
                              : '') +
                            (userMe?.diNotifyViaEmail
                              ? t(
                                  'forms:communication_preferences.methods.email',
                                )
                              : '')
                          }
                        />
                      </>
                    ) : (
                      <Spinner color="lightBlueGrey" />
                    )}
                  </FormComponents.Section>
                  <FormComponents.Section
                    heading={t('profile:communication_preferences.oiHeading')}
                    layout={OINotificationScopes ? 'inactive' : 'single'}>
                    {OINotificationScopes ? (
                      <>
                        <FormComponents.InActive
                          title={t('forms:communication_preferences.type')}
                          content={
                            OINotificationScopes
                              ? OINotificationScopes.find(
                                  (scope: LabelValue) =>
                                    scope.value == userMe?.oiNotificationScope,
                                )?.label
                              : ''
                          }
                        />
                        <FormComponents.InActive
                          title={t('forms:communication_preferences.frequency')}
                          content={
                            (userMe?.alertExceeded
                              ? t(
                                  'forms:communication_preferences.frequencies.exceeding',
                                )
                              : '') +
                            (userMe?.alertExceeded && userMe?.alertApproaching
                              ? ', '
                              : '') +
                            (userMe?.alertApproaching
                              ? t(
                                  'forms:communication_preferences.frequencies.approaching',
                                )
                              : '')
                          }
                        />
                        <FormComponents.InActive
                          title={t('forms:communication_preferences.method')}
                          content={
                            (userMe?.oiNotifyViaSms && userMe.mobile
                              ? t('forms:communication_preferences.methods.sms')
                              : '') +
                            (userMe?.oiNotifyViaSms && userMe?.oiNotifyViaEmail
                              ? ', '
                              : '') +
                            (userMe?.oiNotifyViaEmail
                              ? t(
                                  'forms:communication_preferences.methods.email',
                                )
                              : '')
                          }
                        />
                      </>
                    ) : (
                      <Spinner color="lightBlueGrey" />
                    )}
                  </FormComponents.Section>
                  <>
                    {' '}
                    {(isDI() || isDualProducts()) && (
                      <FormComponents.Section
                        heading={t(
                          'profile:communication_preferences.reportsHeading',
                        )}
                        layout={isMobile ? 'single' : 'inactive'}>
                        <FormComponents.InActive
                          title={t(
                            'profile:communication_preferences.reportsSelected',
                          )}
                          content={
                            (userMe?.subscribeDIYearlyReport
                              ? t(
                                  'profile:communication_preferences.reports.yearly',
                                )
                              : '') +
                            (userMe?.subscribeDIYearlyReport &&
                            userMe?.subscribeDIMonthlyReport
                              ? ', '
                              : '') +
                            (userMe?.subscribeDIMonthlyReport
                              ? t(
                                  'profile:communication_preferences.reports.monthly',
                                )
                              : '')
                          }
                        />
                      </FormComponents.Section>
                    )}
                  </>
                </>
              )}
            </FormComponents.Wrapper>
          ) : (
            <H>Error: No user data</H>
          ))}
      </Container>
    </>
  );
};

export default Profile;
