import styled from '@emotion/styled';
import { gridRow, gridColumn } from 'styled-system';

export const StyledDiv = styled.div`
  overflow: hidden;
  > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  ${gridRow}
  ${gridColumn}
`;
