import styled from '@emotion/styled';
import {
  variant,
  textAlign,
  color,
  fontWeight,
  space,
  compose,
  gridRow,
  gridColumn,
} from 'styled-system';
import { ThemeType } from 'style';

import { ParagraphSizes } from './Paragraph';

type ParagraphProps = {
  color?: string;
  size?: ParagraphSizes;
  theme?: ThemeType;
  center?: boolean;
  bold?: boolean;
  uppercase?: boolean;
};

export const StyledParagraph = styled.p<ParagraphProps>`
  color: ${(props) => (props.color ? props.color : props.theme.colors.black)};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: 0;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  padding: 0;
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
  ${variant({
    prop: 'size',
    variants: {
      large: {
        fontSize: 24,
        lineHeight: '36px',
      },
      medium: {
        fontSize: 20,
        lineHeight: '30px',
      },
      normal: {
        fontSize: 16,
        lineHeight: '24px',
      },
      small: {
        fontSize: 14,
        lineHeight: '21px',
      },
      tiny: {
        fontSize: 12,
        lineHeight: '18px',
      },
    },
  })}
  ${compose(textAlign, color, fontWeight, space, gridRow, gridColumn)};
`;

StyledParagraph.defaultProps = {
  size: 'normal',
};
