import React from 'react';

import { MobileFlare } from '../StackedBarChartLegend/stackedBarChartLegendStyle';
import { TooltipContainer, TooltipItem } from './stackedBarChartTooltipStyle';

import { Paragraph as P } from 'components';
import { theme } from 'style';

const StackedBarChartTooltip = ({ payload }: any) => {
  return (
    <TooltipContainer>
      {payload.reverse().map((entry: any, index: number) => (
        <TooltipItem key={`item-${index}`}>
          <MobileFlare color={entry.color} size={16} />
          <P paddingTop={'2px'} fontSize={'small'} color={theme.colors.blue}>
            {entry.value}
          </P>
        </TooltipItem>
      ))}
    </TooltipContainer>
  );
};

export default StackedBarChartTooltip;
