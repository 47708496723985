import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';

import SpaceHistoricOccupancy from './SpaceHistoricOccupancy/SpaceHistoricOccupancy';

import { useScreenSize } from 'hooks';
import { ChartContainer, Box, DateSlideToggle, BasicError } from 'components';
import { formatStartAndEnd } from 'utils';
import {
  useSpaceOccupancyStore,
  useWebSocketMessageStore,
  useSpaceDetailsStore,
} from 'stores';
import { useParams } from 'react-router-dom';

type Props = {};

export const HistoricOccupancyChart = () => {
  const { t } = useTranslation();
  const [queryDates, setQueryDates] = useState(formatStartAndEnd('week'));
  const { historicOccupancyFetch } = useSpaceOccupancyStore();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();
  const { lastMessage } = useWebSocketMessageStore();
  const { spaceDetailsData } = useSpaceDetailsStore();
  //@ts-expect-error
  const { id } = useParams();

  useEffect(() => {
    historicOccupancyFetch(id, queryDates);
  }, [id, queryDates]);

  useEffect(() => {
    if (lastMessage) {
      const identifier = lastMessage?.Target?.Identifier;

      if (identifier && id === identifier) {
        switch (lastMessage.Event) {
          case 'OccupancyChange':
            historicOccupancyFetch(id, queryDates);
            break;
          case 'OccupancyStatusChange':
            historicOccupancyFetch(id, queryDates);
            break;
          case 'SpaceOffline':
            historicOccupancyFetch(id, queryDates);
            break;
          case 'SpaceOnline':
            historicOccupancyFetch(id, queryDates);
            break;
        }
      }
    }
  }, [lastMessage]);

  return (
    <ErrorBoundary
      fallback={
        <BasicError
          heading="An unexpected error has occured"
          subheading="This tab has timed-out. Please refresh the page"
        />
      }>
      <ChartContainer
        headerStyleProps={{ padding: !isMobile && '30px 40px' }}
        title={t('spaceDetails:charts.historic_occupancy')}
        toggle={
          <div style={{width: '50%'}}>
            <DateSlideToggle
              earliestDate={spaceDetailsData?.details?.commissionedAt}
              setDateRange={setQueryDates}
              fontSize={isMobile ? 12 : 14}
              options={['week', 'month', 'custom']}
              untilNow={false}
            />
          </div>
        }
        tooltip={t('spaceDetails:charts.historic_occupancy_tooltip')}>
        <Box p={['20px 0 0 0', '40px']} outlined={false}>
          <SpaceHistoricOccupancy />
        </Box>
      </ChartContainer>
    </ErrorBoundary>
  );
};

export default HistoricOccupancyChart;
