import styled from '@emotion/styled';
import { ThemeType, flexCenterAll, mq } from 'style';

interface OuterProps {
  centered?: boolean;
  isMobile?: boolean;
  theme: ThemeType;
}
export const OuterContainer = styled.div<OuterProps>`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: none;
  }
  display: grid;
  grid-template-rows: 70px auto 100px;

  > div:nth-of-type(2) {
    ${flexCenterAll};
    flex-direction: column;
  }
`;

export const InnerContainer = styled.div`
  ${flexCenterAll}
  flex-direction: column;

  ${mq.mobile} {
    width: 90%;
  }

  ${mq.tablet} {
    width: 60%;
  }
`;

export const LogoWrapper = styled.div`
  ${flexCenterAll}
  width: 100%;
  height: 70px;
`;
