import { mq } from 'style';

export const flexCenterAll = () => `
    display: flex;
    justify-content: center;
    align-items: center;
  `;

export const flexCenter = () => `
    display: flex;
    justify-content: center;
  `;

export const flexVertical = () => `
    display: flex;
    align-items: center;
  `;

export const contentWidths = {
  mobile: '90%',
  desktop: '1140px',
  wideDesktop: '1500px',
};

export const contentBody = () => `
    padding: 0 30px;
    margin: 0 auto;
    width: 100%;
    max-width: ${contentWidths.desktop};
    ${mq.wideDesktop()} {
      max-width: ${contentWidths.wideDesktop};
    }

  `;
