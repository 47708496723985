import React from 'react';

import { PrivateRoute } from 'pages';

import Spaces from 'products/occupancy-insights/Spaces/Spaces';
import SpaceDetails from 'products/occupancy-insights/SpaceDetails/SpaceDetails';
import OccupancyInsights from 'products/occupancy-insights/Dashboard/Dashboard';
import Settings from 'products/occupancy-insights/Settings/Settings';
import Doors from 'products/door-insights/Doors/Doors';
import DoorDetails from 'products/door-insights/DoorDetails/DoorDetails';
import Resources from 'products/door-insights/Resources/Resources';
import DoorInsights from 'products/door-insights/Dashboard/Dashboard';
import { Redirect, Switch } from 'react-router-dom';
import Profile from './Profile/Profile';
import Alerts from 'products/door-insights/Alerts/Alerts';
import Reports from 'products/door-insights/Reports/Reports';

export const DualInsightPages = () => (
  <>
    <Switch>
      <Redirect from="/dashboard" to="/occupancy-insights" exact />
      <PrivateRoute path="/spaces" component={Spaces} exact />
      <PrivateRoute path="/spaces/:id" component={SpaceDetails} exact />
      <PrivateRoute path="/settings" component={Settings} exact />
      <PrivateRoute
        path="/occupancy-insights"
        component={OccupancyInsights}
        exact
      />
      <PrivateRoute path="/profile" component={Profile} exact />

      <PrivateRoute path="/doors" component={Doors} exact />
      <PrivateRoute path="/doors/:id" component={DoorDetails} exact />
      <PrivateRoute path="/notifications" component={Alerts} exact />
      <PrivateRoute path="/reports" component={Reports} exact />
      <PrivateRoute path="/resources" component={Resources} exact />
      <PrivateRoute path="/door-insights" component={DoorInsights} exact />
    </Switch>
  </>
);

export default DualInsightPages;
