import { theme } from './theme';

const border = `1px solid ${theme.colors.blueGrey}`;
const headerHeight = 70;
const boxShadow = '0px 4px 14px rgba(157, 166, 181, 0.09);';
const formPadding = ` 36px 50px`;

const vars = {
  border,
  headerHeight,
  boxShadow,
  formPadding,
};

export default vars;
