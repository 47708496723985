import React from 'react';
import * as Yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';
import i18n from 'core/i18n/i18n';

import {
  TextField,
  SelectField,
  FormComponents,
  PhoneField,
  Paragraph as P,
} from 'components';
import { useScreenSize } from 'hooks';
import { LabelValue } from 'models';
import { theme } from 'style';

const { screenSize } = useScreenSize();

export const Empty = Yup.object({});

export const Validation = Yup.object({
  salutation: Yup.string().ensure(),
  honorific: Yup.string().ensure(),
  firstName: Yup.string().required(i18n.t('forms:validation.required')),
  lastName: Yup.string().required(i18n.t('forms:validation.required')),
  email: Yup.string().email(i18n.t('forms:validation.email')),
  phone: Yup.string()
    .ensure()
    .matches(/^$|^\+(?:[0-9] ?){6,14}[0-9]$/, i18n.t('forms:validation.phone'))
    .nullable(),
  mobile: Yup.string()
    .matches(/^$|^\+(?:[0-9] ?){6,14}[0-9]$/, i18n.t('forms:validation.mobile'))
    .nullable()
    .ensure(),
  account: Yup.string(),
  userType: Yup.string(),
  language: Yup.string().required(i18n.t('forms:validation.required')),
});

export type AccountDetailsSchema = Yup.InferType<typeof Validation>;

// Will be populated by an API GET to the user details
export const initialAccountDetails: AccountDetailsSchema = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  mobile: '',
  honorific: '',
  salutation: '',
  account: '',
  userType: '',
  language: '',
};

const salutations: string[] = i18n.t('forms:personal_details.salutations', {
  returnObjects: true,
});
const salutations_options: LabelValue[] = salutations.map((s) => ({
  value: s,
  label: s,
}));

export const Form = ({ values }: any) => {
  const { t } = useTranslation();
  const { isMobile } = screenSize();

  return (
    <FormComponents.Section
      layout={isMobile ? 'single' : 'double'}
      heading={t('userOnboarding:section_heading.confirm_details')}
      headingSize={`medium`}>
      <SelectField
        name="salutation"
        label={t('forms:personal_details.salutation')}
        options={salutations_options}
      />
      <TextField
        name="honorific"
        label={t('forms:personal_details.honorific')}
      />
      <TextField
        required
        name="firstName"
        label={t('forms:personal_details.first_name')}
      />
      <TextField
        tooltip={t('forms:personal_details.tooltip')}
        name="lastName"
        label={t('forms:personal_details.last_name')}
        disabled
      />

      <hr></hr>

      <PhoneField
        name="mobile"
        label={t('forms:personal_details.mobile_no')}
        required={false}
      />
      <PhoneField name="phone" label={t('forms:personal_details.phone_no')} />

      <TextField
        name="email"
        label={t('forms:personal_details.email')}
        disabled
        tooltip={t('forms:personal_details.tooltip')}
        corporateEmailOnly
      />
      <TextField
        disabled
        name="account.name"
        label={t('forms:personal_details.account')}
        tooltip={t('forms:personal_details.tooltip')}
      />

      <hr></hr>

      <SelectField
        required
        name="language"
        label={t('forms:personal_details.language')}
        options={[
          { value: 'en', label: i18n.t('languages:en') },
          { value: 'de', label: i18n.t('languages:de') },
          { value: 'fr', label: i18n.t('languages:fr') },
        ]}
      />

      <hr></hr>

      <FormComponents.SectionFullWidthContainer>
        <P color="charcoal" mb={`5px`}>
          {t('userOnboarding:disclaimer.heading')}
        </P>
        <P size="small" color="charcoal">
          <Trans i18nKey="userOnboarding:disclaimer.description">
            dormakaba takes your data privacy seriously. We use information
            captured here to administer your access to, and the features of, the
            Entrivo Engage DI solution only. We will never sell your information
            to a third party and you may request us to update, delete or share a
            copy of your data with you at any time. See the full dormakaba
            privacy disclosure statement{' '}
            <a
              href="https://www.dormakaba.com/au-en/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: theme.colors.primary }}>
              here
            </a>
            , or{' '}
            <a
              href="https://entrivo-helpandsupport.dormakaba.com/s/request-support"
              target="blank"
              rel="noopener noreferrer"
              style={{ color: theme.colors.primary }}>
              contact us
            </a>{' '}
            for more information.
          </Trans>
        </P>
      </FormComponents.SectionFullWidthContainer>
    </FormComponents.Section>
  );
};
