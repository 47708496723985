import axios from 'axios';
import config from 'config';

import client from 'core/client/client';
import {
  UserMeDetails,
  UserListParams,
  NotificationPreference,
  UsersList,
  User,
  IdValue,
  NotificationType,
} from 'models';
import currentProduct from '../utils/currentProduct';
import { fetchAllTypes, fetchAvailableNotificationTypes } from './doors';
import { Product } from 'utils';
import { getNotificationTypeOptions } from 'utils/getNotificationTypeOptions';
import { fetchDoorErrorCodeMapping } from './notifications';

const USERS_ENDPOINT = 'Users';

const createParamsString = (params: any) => {
  return Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((key) => key + '=' + params[key])
    .join('&');
};

// Handle the sucessful requests in the function
// Errors handled by the client and thrown down

export const fetchUserMeDetails = async () => {
  try {
    const request = await client.get(`${USERS_ENDPOINT}/me`);
    const { status } = request;
    const data: User = request.data;
    await setAlertTypes(data);

    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

async function setAlertTypes(data: User) {
  if (hasSingleProductAccess(data, Product.OI)) {
    return;
  }

  const notificationTypes = await fetchAvailableNotificationTypes();
  if (notificationTypes) {
    const alertTypes = getNotificationTypeOptions(
      notificationTypes,
      NotificationType.Alert,
      true,
      hasSingleProductAccess(data, Product.DIS),
    );

    if (
      data.alertTypes?.includes(NotificationType.All) ||
      (data.alertTypes?.length === 0 && data.diNotificationScope !== 'N/A')
    ) {
      data.alertTypes = alertTypes.map((a) => a.value);
      data.alertTypes.push(NotificationType.All);
      data.alertTypes.push(NotificationType.AllCritical);
      data.alertTypes.push(NotificationType.AllGeneral);

      data.criticalAlertTypes = alertTypes
        .filter((a) => a.isCritical)
        .map((a) => a.value);
      data.criticalAlertTypes.push(NotificationType.AllCritical);

      data.generalAlertTypes = alertTypes
        .filter((a) => !a.isCritical)
        .map((a) => a.value);
      data.generalAlertTypes.push(NotificationType.AllGeneral);
    } else if (data.alertTypes?.includes(NotificationType.AllCritical)) {
      const allCriticalAlerts = alertTypes
        .filter((a) => a.isCritical)
        .map((a) => a.value);
      allCriticalAlerts.push(NotificationType.AllCritical);
      const selectedGeneralAlerts = alertTypes
        .filter((a) => !a.isCritical)
        .map((a) => a.value)
        .filter((a) => data.alertTypes.includes(a));
      data.alertTypes = allCriticalAlerts.concat(selectedGeneralAlerts);

      data.criticalAlertTypes = allCriticalAlerts.concat([
        NotificationType.AllCritical,
      ]);
      data.generalAlertTypes = selectedGeneralAlerts;
    } else if (data.alertTypes?.includes(NotificationType.AllGeneral)) {
      const allGeneralAlerts = alertTypes
        .filter((a) => !a.isCritical)
        .map((a) => a.value);
      allGeneralAlerts.push(NotificationType.AllGeneral);
      const selectedCriticalAlerts = alertTypes
        .filter((a) => a.isCritical)
        .map((a) => a.value)
        .filter((a) => data.alertTypes.includes(a));
      data.alertTypes = allGeneralAlerts.concat(selectedCriticalAlerts);

      data.generalAlertTypes = allGeneralAlerts.concat([
        NotificationType.AllGeneral,
      ]);
      data.criticalAlertTypes = selectedCriticalAlerts;
    } else {
      data.criticalAlertTypes = alertTypes
        .filter((a) => a.isCritical)
        .map((a) => a.value)
        .filter((a) => data.alertTypes.includes(a));

      data.generalAlertTypes = alertTypes
        .filter((a) => !a.isCritical)
        .map((a) => a.value)
        .filter((a) => data.alertTypes.includes(a));
    }

    // remove duplicate values
    data.alertTypes = [...new Set(data.alertTypes)];
    data.generalAlertTypes = [...new Set(data.generalAlertTypes)];
    data.criticalAlertTypes = [...new Set(data.criticalAlertTypes)];
  }
}

export const updateUserLanguage = async (language: string) => {
  try {
    const request = await client.patch(`${USERS_ENDPOINT}/me/language`, {
      language,
    });
    const { status } = request;
    const data: UserMeDetails = request.data;
    return status;
    // switch (status) {
    //   case 200:
    //     i18n.changeLanguage(data.language);
    //     fetchUserMeDetails();
    //     break;
    //   case 204:
    //     i18n.changeLanguage(data.language);
    //     fetchUserMeDetails();
    //     break;
    // }
  } catch (err) {
    throw err;
  }
};

export const fetchNotificationScopes = async () => {
  const product = currentProduct();
  try {
    const request = await client.get(
      `${USERS_ENDPOINT}/notificationScopes?platform=${product}`,
    );
    const { status } = request;
    const data: IdValue[] = request.data;
    switch (status) {
      case 200:
        return data.filter(
          (item, index, array) =>
            array.findIndex((t) => t.id == item.id) === index,
        );
      case 204:
        return data.filter(
          (item, index, array) =>
            array.findIndex((t) => t.id == item.id) === index,
        );
    }
  } catch (err) {
    throw err;
  }
};

export const fetchUsersList = async (params: UserListParams) => {
  const paramsString = createParamsString(params);
  try {
    const request = await client.get(`${USERS_ENDPOINT}?${paramsString}`);
    const { status } = request;
    const data: UsersList[] = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchUser = async (id: string) => {
  try {
    const request = await client.get(`${USERS_ENDPOINT}/${id}`);
    const { status } = request;
    const data: User = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const activateCurrentUser = async () => {
  try {
    const request = await client.patch(
      `${USERS_ENDPOINT}/activeCurrentUser`,
      {},
    );
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export const fetchNotificationPreference = async (): Promise<NotificationPreference> => {
  try {
    const request = await client.get(
      `${USERS_ENDPOINT}/notificationPreference`,
    );
    const { data } = request;
    delete data['userId'];
    return data;
  } catch (err) {
    throw err;
  }
};

export const notificationPreference = async (
  notificationPreference: NotificationPreference,
) => {
  try {
    const request = await client.post(
      `${USERS_ENDPOINT}/notificationPreference`,
      notificationPreference,
    );
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export const statusByUsername = async (username: string) => {
  try {
    const request = await axios.get(
      `${config.API}${USERS_ENDPOINT}/${username}/status`,
    );
    const { status } = request;
    const data: any = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const userRoleUpdate = async (
  id: string,
  userRole: 'Admin' | 'Viewer',
) => {
  try {
    const request = await client.patch(
      `${USERS_ENDPOINT}/${id}/userRole?newUserRole=${userRole}`,
      {},
    );
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export function hasSingleProductAccess(data: User, productCode: string) {
  return (
    data.productsCanAccess?.length === 1 &&
    data.productsCanAccess[0] === productCode
  );
}
