import React from 'react';
import { Paragraph as P, ToolTip } from 'components';

import { Item, ItemHeader, ItemCount } from './summaryStyle';

interface SummaryItemProps {
  icon?: any;
  title: string;
  description: string;
  count: string;
}

const SummaryItem = ({ icon, title, description, count }: SummaryItemProps) => {
  return (
    <Item>
      <ItemHeader>
        {icon && icon}
        <P size="small" color="primary" bold paddingTop="3px">
          {title}
        </P>
        <div style={{ width: '5px' }} />
        <ToolTip content={description} iconSize={16} iconType="question" />
      </ItemHeader>

      <ItemCount>{count}</ItemCount>
    </Item>
  );
};

export default SummaryItem;
