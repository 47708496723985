import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledDiv } from './errorBoundaryStyle';

import {} from 'components';

interface Props {}
interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    console.log('getDerivedStateFromError: ', error);

    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log('catch: ', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Custom Fallback</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
