import styled from '@emotion/styled';
import { contentWidths, mq } from 'style';
import { space } from 'styled-system';

export const Component = styled.div`
  height: auto;
  padding: 0 30px;
  margin: 30px auto;
  width: 100%;
  max-width: ${contentWidths.desktop};
  ${mq.wideDesktop()} {
    max-width: ${contentWidths.wideDesktop};
  }
  ${mq.mobile()} {
    padding: 0;
    max-width: ${contentWidths.mobile};
  }
  ${space};
`;
