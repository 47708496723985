import React from 'react';
import { Skeleton, Flex } from 'components';
import { useScreenSize } from 'hooks';

const StackedBarChartSkeleton = () => {
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  const legendSkeleton = (marginLeft: string) => (
    <Skeleton
      width={isMobile ? 80 : 100}
      height={isMobile ? 30 : 40}
      style={{
        marginTop: '-60px',
        marginLeft: marginLeft,
      }}
    />
  );

  return (
    <Flex flexDirection="column">
      <Skeleton
        width={isMobile ? 220 : 500}
        height={isMobile ? 250 : 250}
        variant="rectangle"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '80px',
          marginTop: isMobile ? '15px' : 0,
        }}></Skeleton>
      <Flex justifyContent="center">
        {legendSkeleton('0px')} {legendSkeleton('20px')}
      </Flex>
    </Flex>
  );
};

export default StackedBarChartSkeleton;
