import styled from '@emotion/styled';
import { theme } from 'style';

interface StatusFlareProps {
  backgroundColor: string;
}

export const StatusFlare = styled.div<StatusFlareProps>`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${(props) => theme.colors[props.backgroundColor]};
  margin-right: 8px;
`;