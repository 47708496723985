import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { SlideToggle, DatePicker } from 'components';
import { formatStartAndEnd, isSameSelectedDates } from 'utils';
import { SelectedDates, SelectedDateTypeEnum } from 'models';

interface Props {
  setDateRange: (obj: any) => void;
  setDateType?: (obj: any) => void;
  defaultDates?: SelectedDates;
  options?: string[];
  fontSize?: string | number;
  untilNow?: boolean;
  earliestDate?: Date | string;
  dataType?: string;
}

const DateSlideToggle = ({
  setDateRange,
  setDateType,
  defaultDates,
  options,
  fontSize,
  untilNow,
  earliestDate,
  dataType,
}: Props) => {
  const { t } = useTranslation();
  const [isDatePickerVisible, setIsDatePickerVisible] = useState<boolean>(
    false,
  );

  const [selectedDate, setSelectedDate] = useState<SelectedDates>({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    setSelectedDate({
      startDate: defaultDates ? defaultDates.startDate : null,
      endDate: defaultDates ? defaultDates.endDate : null,
    });
  }, []);

  useEffect(() => {
    const dates = [
      formatDateLabel(selectedDate.startDate),
      moment(selectedDate.endDate).isValid()
        ? formatDateLabel(selectedDate.endDate)
        : '',
    ];

    if (selectedDate.startDate || selectedDate.endDate) {
      if (
        defaultDates !== selectedDate &&
        indexOverride(selectedDate) === customToggleIndex()
      ) {
        setCustomLabel(dates.join(' - '));
      } else {
        setCustomLabel(t('common:custom'));
      }
      setDateRange(selectedDate);
    }
  }, [selectedDate]);

  const [customLabel, setCustomLabel] = useState<string>('common:custom');
  const slideToggleAction = (range: SelectedDateTypeEnum) => {
    setCustomLabel(t('common:custom'));
    if (setDateType) {
      setDateType(range);
    }
    setSelectedDate({ startDate: null, endDate: null });
    setIsDatePickerVisible(false);
    if (range === 'all') {
      setDateRange({
        startDate: undefined,
        endDate: undefined,
      });
    } else {
      setDateRange(
        formatStartAndEnd(range === 'now' ? 'day' : range, untilNow),
      );
    }
  };

  const slideToggleOptions = () => {
    const items = [
      {
        id: 'now',
        callback: () => slideToggleAction('now'),
        label: t('common:now'),
      },
      {
        id: 'all',
        callback: () => slideToggleAction('all'),
        label: t('common:all_time'),
      },
      {
        id: 'day',
        callback: () => slideToggleAction('day'),
        label: t('common:today'),
      },
      {
        id: 'week',
        callback: () => slideToggleAction('week'),
        label: t('common:this_week'),
      },
      {
        id: 'month',
        callback: () => slideToggleAction('month'),
        label: t('common:this_month'),
      },
      {
        id: 'custom',
        label: t(customLabel),
        callback: () => {
          slideToggleAction('all');
          setIsDatePickerVisible(true);
        },
      },
    ];
    if (options) {
      return items.filter((item) => options.includes(item.id));
    } else {
      return items.slice(1);
    }
  };

  const indexOverride = (dates: SelectedDates) => {
    if (dates) {
      if (isSameSelectedDates(dates, formatStartAndEnd('day', untilNow))) {
        return slideToggleOptions().findIndex((item) => item.id === 'day');
      }

      if (isSameSelectedDates(dates, formatStartAndEnd('week', untilNow))) {
        return slideToggleOptions().findIndex((item) => item.id === 'week');
      }

      if (isSameSelectedDates(dates, formatStartAndEnd('month', untilNow))) {
        return slideToggleOptions().findIndex((item) => item.id === 'month');
      }
    }

    if (
      !defaultDates?.startDate &&
      !defaultDates?.endDate &&
      slideToggleOptions().findIndex((item) => item.id === 'all') !== -1
    ) {
      return slideToggleOptions().findIndex((item) => item.id === 'all');
    }

    if (
      dataType === 'now' &&
      !defaultDates?.startDate &&
      defaultDates?.endDate &&
      slideToggleOptions().findIndex((item) => item.id === 'all') !== -1
    ) {
      return slideToggleOptions().findIndex((item) => item.id === 'all');
    }

    return customToggleIndex();
  };

  const customToggleIndex = () => {
    return slideToggleOptions().findIndex((item) => item.id === 'custom');
  };

  const formatDateLabel = (date: any) => {
    return date ? `${moment(date).format('D MMM')}` : null;
  };

  return (
    <SlideToggle
      items={slideToggleOptions()}
      fontSize={fontSize}
      data-testid="date-slide-toggle"
      indexOverride={
        defaultDates && indexOverride(defaultDates)
          ? indexOverride(defaultDates)
          : 0
      }
      component={
        <DatePicker
          isVisible={isDatePickerVisible}
          onOutsideClick={() => setIsDatePickerVisible(false)}
          startDate={moment(selectedDate.startDate) || null}
          endDate={moment(selectedDate.endDate) || null}
          onDateChange={({ startDate, endDate }: SelectedDates) => {
            if (moment(startDate).isValid() && moment(endDate).isValid()) {
              setSelectedDate({
                startDate: moment(startDate)
                  .startOf('day')
                  .format(),
                endDate: moment(endDate)
                  .endOf('day')
                  .format(),
              });
            } else if (
              moment(startDate).isValid() &&
              !moment(endDate).isValid()
            ) {
              setSelectedDate({
                startDate: moment(startDate)
                  .startOf('day')
                  .format(),
                endDate: moment(startDate)
                  .endOf('day')
                  .format(),
              });
            } else if (
              !moment(startDate).isValid() &&
              moment(endDate).isValid()
            ) {
              setSelectedDate({
                startDate: moment(endDate)
                  .startOf('day')
                  .format(),
                endDate: moment(endDate)
                  .endOf('day')
                  .format(),
              });
            }
          }}
          earliestDate={earliestDate}
        />
      }
    />
  );
};

export default DateSlideToggle;
