import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { StyledLink } from './linkStyle';
import { HeadingProps } from 'components/Paragraph/Paragraph';

type BaseProps = HeadingProps & {
  onClick?: (_: any) => void;
  to?: string;
  href?: string;
  target?: string;
};

type LinkProps =
  | (BaseProps & { to: string })
  | (BaseProps & { href: string })
  | (BaseProps & { onClick: (_: any) => void });

const Link: React.FC<LinkProps> = ({ children, href, to, ...rest }) => {
  if (href) {
    return (
      <StyledLink as="a" {...rest} href={href}>
        {children}
      </StyledLink>
    );
  }
  if (to) {
    return (
      <RouterLink to={to}>
        <StyledLink as="span" {...rest}>
          {' '}
          {children}
        </StyledLink>
      </RouterLink>
    );
  } else {
    return <StyledLink {...rest}>{children}</StyledLink>;
  }
};

export default Link;
