export const generateStatus = (
  isOffline: boolean,
  currentOccupany: number,
  maxOccupancy: number,
  approachingOccupancy: number,
) => {
  if (isOffline) {
    return 'offline';
  }
  if (currentOccupany > maxOccupancy) {
    return 'error';
  }
  if ((currentOccupany / maxOccupancy) * 100 > approachingOccupancy) {
    return 'warning';
  }
  return 'success';
};

export const generateOccupancyPercentage = (
  currentOccupany: number,
  maxOccupancy: number,
) => {
  return `${(currentOccupany / maxOccupancy) * 100}%`;
};
