import client from 'core/client/client';
import i18n from 'core/i18n/i18n';

import { DIReport, ReportRequest, ReportType } from 'models';

const REPORTS_ENDPOINT = 'Reports';
const DI_REPORTS_ENDPOINT = 'Reports/diReport';

export const requestReport = async (payload: ReportRequest) => {
  try {
    const request = await client.post(`${REPORTS_ENDPOINT}`, payload);
    const { status } = request;
    const data: any[] = request.data;
    if (status === 200) {
      return data;
    }
  } catch (err) {
    throw err;
  }
};

export const requestDIReport = async (payload: ReportRequest) => {
  try {
    const request = await client.post(`${DI_REPORTS_ENDPOINT}`, payload);
    const { status } = request;
    const data: any[] = request.data;
    if (status === 200) {
      return data;
    }
  } catch (err) {
    throw err;
  }
};

export const downloadDIReport = async (
  accountId: string,
  type: ReportType,
  year: string,
  month?: string,
) => {
  try {
    const request = await client.get(
      `${REPORTS_ENDPOINT}/accountReport?AccountId=${accountId}&StartDate=${year}${
        month ? '/' + month : ''
      }&Type=${
        type == ReportType.Monthly && month ? 'Month' : 'Year'
      }&LanguageCode=${i18n.language}`,
    );
    const { status } = request;
    const data: DIReport = request.data;
    if (status === 200) {
      return data;
    }
  } catch (err) {
    throw err;
  }
};
