import React from 'react';

import { Grid, Box, Container } from 'components';
import { theme } from 'style';

interface Props {
  children: React.ReactNode[];
}
const BreadCrumbs: React.FC<Props> = ({ children }) => {
  return (
    <Box
      elevated={false}
      backgroundColor="primary"
      py="22px"
      ml="0"
      square
      color="white"
      data-testid="breadcrumbs"
      borderBottom={theme.borders(1, 'greyBlue')}
      outlined={false}>
      <Container margin="0 auto" paddingLeft="30px">
        <Grid
          gridAutoColumns={['1fr', 'max-content']}
          gridColumnGap="10px"
          horizontal
          alignItems="center">
          {children.map((element, i, arr) => {
            return (
              element && (
                <React.Fragment key={i}>
                  <div style={{ cursor: 'pointer' }}>{element}</div>
                  {i !== children.length - 1 && arr[i + 1] && <p>/</p>}
                </React.Fragment>
              )
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default BreadCrumbs;
