import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTable } from 'react-table';
import { useTranslation } from 'react-i18next';

import { Table, Grid, IcomoonIcons, Paragraph as P } from 'components';
import { UsersList } from 'models';
import {
  userStatusTranslations,
  roleTranslations,
  isDIS,
} from 'utils';
import { useScreenSize } from 'hooks';

import UserListItem from './UserListItem/UserListItem';
import { useUsersTableStyle } from './usersTableStyles';

interface Props {
  data: UsersList[];
  sortBy: (sortBy: 'name') => void;
  isAdmin: boolean;
  onLoadMore: (nextPage: number) => void;
  paginated: boolean;
  total?: number;
  currentPage?: number;
  limit?: number;
}

const cleanUserRole = (role: any) => {
  if (role === 'Admin' || role === 'Viewer') {
    return roleTranslations[role];
  } else {
    return '';
  }
};

const UsersTable = ({ data, sortBy, isAdmin, onLoadMore, paginated, total = 0, currentPage = 1, limit = 20 }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    Wrapper,
    Pagination,
    PaginationItem
  } = useUsersTableStyle();
  const tableColumns = [
    {
      id: 'name',
      Header: 'usersList:table.column.name',
      accessor: 'firstName',
      sortable: true,
      filterable: true,
      Cell: (props: any) =>
        `${props?.row?.original?.firstName} ${props?.row?.original?.lastName}`,
    },
    {
      Header: 'usersList:table.column.role',
      accessor: 'userRole',
      sortable: true,
      filterable: true,
      Cell: (props: any) => cleanUserRole(props.row.original.userRole),
    },
    {
      Header: 'usersList:table.column.email',
      accessor: 'email',
      sortable: true,
      filterable: true,
    },
    {
      Header: 'usersList:table.column.status',
      accessor: 'status',
      sortable: true,
      filterable: true,
      Cell: (props: any) => {
        console.log(props.row.original.status);
        return props.row.original.status && userStatusTranslations[props.row.original.status]
          ? userStatusTranslations[props.row.original.status]
          : ''
      }
    },
  ]
  
  if (isDIS()) {
    tableColumns.splice(3, 0, {
      Header: 'usersList:table.column.account',
      accessor: 'accountName',
      sortable: true,
      filterable: true,
    })
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: tableColumns
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const navigateOnRowClick = (row: UsersList) => {
    if ( ! isAdmin ) return;
    history.push({
      pathname: `/users/${row.id}`,
      state: {
        fromPage: history.location.pathname,
        id: row.id,
        name: `${row.firstName} ${row.lastName}`,
      },
    });
  };

  const { isMobile } = useScreenSize().screenSize();
  if (isMobile) {
    return (
      <>
        <Grid gridAutoFlow="row" gridRowGap="15px">
          {rows.map((row: any, index: number) => (
            <UserListItem
              key={index}
              row={row}
              navigateOnRowClick={navigateOnRowClick}
              isAdmin={isAdmin}
            />
          ))}
        </Grid>
        { paginated && 
          <Wrapper>
            <Pagination >
              <P color="charcoal" size="small">{currentPage} {t('common:showing_total.of')} {Math.ceil(total/limit)}</P>
  
              <PaginationItem onClick={() => {
                if ( onLoadMore && currentPage > 1 ) {
                  onLoadMore(currentPage-1)
                }
              }}>
                <IcomoonIcons icon="chevron-left" color={currentPage > 1 ? 'charcoal' : 'blueGrey'}></IcomoonIcons>
              </PaginationItem>
              <PaginationItem onClick={() => {
                if ( onLoadMore && currentPage < total/limit) {
                  onLoadMore(currentPage+1)
                }
              }}>
                <IcomoonIcons icon="chevron-right" color={currentPage < total/limit ? 'charcoal' : 'blueGrey'}></IcomoonIcons>
              </PaginationItem>
            </Pagination>
          </Wrapper>
        }
      </>
    );
  }

  return (
    <Table
      columns={isAdmin ? `2.5fr 1.5fr 3fr 1.5fr ${isDIS() ? '1.5fr' : ''} 60px` : `2.5fr 1.5fr 3fr 1.5fr ${isDIS() ? '1.5fr' : ''}`}
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      rows={rows}
      prepareRow={prepareRow}
      onRowClick={isAdmin ? navigateOnRowClick : undefined}
      navigateOnRowClick={navigateOnRowClick}
      sortBy={sortBy}
      paginated={paginated}
      onLoadMore={onLoadMore}
      total={total}
      currentPage={currentPage}
      limit={limit}
      showNavigateToArrow={isAdmin}
      minWidth={1100}
    />
  );
};

export default UsersTable;
