import React from 'react';
import {
  Paragraph as P,
  Heading as H,
  IcomoonIcons,
  Skeleton,
} from 'components';
import { useConnectedDoorHealthStore } from 'stores';
import { useHistory } from 'react-router-dom';
import {
  Container,
  NotificationContainer,
  LeftContainer,
  RightContainer,
  NoAlertContainer,
} from './alertStyle';
import { useTranslation } from 'react-i18next';
import { sumBy } from 'lodash';

interface AlertProps {
  followedOnly: boolean;
  triggerUpdate?: boolean;
}

const Alert = ({ followedOnly, triggerUpdate }: AlertProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    isLoading,
    connectedDoorHealthAlerts,
  } = useConnectedDoorHealthStore();
  const totalActiveAlert = sumBy(connectedDoorHealthAlerts, 'activeAlertCount');

  const handleClickAlertContainer = (followedOnly: boolean) => {
    const isFollowing = followedOnly ? 'true' : 'false';
    history.push(`/doors?alerts=active&followedOnly=${isFollowing}`);
  };

  if (isLoading) {
    return <Skeleton width="100%" height={120} />;
  }

  return totalActiveAlert === 0 ? (
    <NoAlertContainer>
      <div>
        <H size="h6" color="primary" mr="8px">
          {t('dashboard:no_current_alert.title')}
        </H>
        <P size="normal" color="charcoal" textAlign="center" m="10px">
          {followedOnly
            ? t('dashboard:no_current_alert.description_followed_only')
            : t('dashboard:no_current_alert.description')}
        </P>
      </div>
    </NoAlertContainer>
  ) : (
    <Container
      clickable
      onClick={() => handleClickAlertContainer(followedOnly)}>
      <LeftContainer>
        <NotificationContainer>
          <H size="h3" color="primary">
            {totalActiveAlert}
          </H>
        </NotificationContainer>
      </LeftContainer>
      <RightContainer>
        <P size="medium" color="white" bold>
          {t('dashboard:alert_require_attention')}
        </P>
        <P size="small" color="white" bold>
          {t('dashboard:view_alerts')}
          <IcomoonIcons icon="chevron-right" />
        </P>
      </RightContainer>
    </Container>
  );
};

export default Alert;
