// @ts-nocheck
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { I18n as AmplifyI18n } from "@aws-amplify/core";

import en from "./locales/en";
import de from "./locales/de";
import fr from "./locales/fr";

export enum Locale {
  EN = 'en',
  DE = 'de',
  FR = 'fr'
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: Locale.EN,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources: {
      de,
      en,
      fr,
    },
    whitelist: [Locale.EN, Locale.DE, Locale.FR],
  });

AmplifyI18n.setLanguage(i18n.language);

export default i18n;
