import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormComponents } from 'components';
import { useScreenSize } from 'hooks';
import { SpaceDetails, SpaceStatus } from 'models';
import { displaySpaceTypeTranslation } from 'utils';

import { SpaceRequired } from '..';

type Props = {
  data: SpaceDetails;
  toggleEdit?: () => void;
  isLoading: boolean;
};

export const DetailsSection = ({ data, toggleEdit, isLoading }: Props) => {
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  const currentOccupancyDisplay = (data: SpaceDetails | undefined) => {
    if (data?.status?.isOffline || !data?.details?.spaceIsActive) {
      return t('common:status.unavailable');
    } else {
      if (data?.status?.currentOccupancy === 0) {
        return '0';
      } else {
        return data?.status?.currentOccupancy;
      }
    }
  };

  return (
    <FormComponents.Wrapper>
      <FormComponents.Header
        heading={t('spaceDetails:details')}
        onClick={toggleEdit}
        paddingSize={toggleEdit ? 'small' : 'large'}
      />
      <FormComponents.Section
        layout={isMobile ? 'single' : 'double'}
        paddingSize="small">
        <FormComponents.InActive
          columns={2}
          border={!isMobile}
          loading={isLoading}
          title={t('spaceDetails:edit_form.labels.type')}
          content={displaySpaceTypeTranslation(data?.details?.spaceType)}
        />
        <FormComponents.InActive
          columns={2}
          border={!isMobile}
          loading={isLoading}
          title={t('spaceDetails:edit_form.labels.account')}
          content={data?.details?.account.name}
        />
        <FormComponents.InActive
          columns={2}
          border={!isMobile}
          loading={isLoading}
          title={
            <SpaceRequired
              color="blueGrey"
              uppercase
              label={t('spaceDetails:edit_form.labels.size')}
            />
          }
          content={data?.details?.spaceSize}
        />
        <FormComponents.InActive
          columns={2}
          border={!isMobile}
          loading={isLoading}
          title={t('spaceDetails:edit_form.labels.doors')}
          content={data?.details?.noOfEntrances}
        />
        <FormComponents.InActive
          columns={2}
          border={!isMobile}
          loading={isLoading}
          title={t('spaceDetails:edit_form.labels.current_occupancy')}
          content={currentOccupancyDisplay(data)}
        />
        <FormComponents.InActive
          columns={2}
          border={!isMobile}
          loading={isLoading}
          title={
            <SpaceRequired
              color="blueGrey"
              uppercase
              label={t('spaceDetails:edit_form.labels.space_required')}
            />
          }
          content={data?.details?.defaultCapacityPP}
        />
        <FormComponents.InActive
          columns={2}
          border={!isMobile}
          loading={isLoading}
          tooltip={t('spaceDetails:edit_form.validation.custom_max_occupancy')}
          title={
            isLoading
              ? t('spaceDetails:edit_form.labels.max_occupancy')
              : data.details.customMaxOccupancy
              ? t('spaceDetails:edit_form.labels.max_occupancy_custom')
              : t('spaceDetails:edit_form.labels.max_occupancy')
          }
          content={data?.details?.customMaxOccupancy}
        />
        <FormComponents.InActive
          columns={2}
          border={!isMobile}
          loading={isLoading}
          tooltip={t('spaceDetails:edit_form.tooltips.notification_threshold')}
          title={t('spaceDetails:edit_form.labels.approaching_occupancy')}
          content={`${data?.status?.approachingOccupancy}%`}
        />
      </FormComponents.Section>
    </FormComponents.Wrapper>
  );
};

export default DetailsSection;
