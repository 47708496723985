import moment from 'moment';
import i18n from 'i18next';
import axios, { CancelTokenSource } from 'axios';
import client from 'core/client/client';
import {
  DoorSummary,
  Alert,
  AlertHistory,
  DoorDetails,
  DoorStatus,
  ConnectedDoorHealthAlerts,
  DoorListParams,
  RefreshFrequency,
  LabelValue,
  EditDoorParams,
  NotificationGroup,
  NotificationType,
  DoorsResponse,
} from 'models';
import { find } from 'lodash';
import { createParamsString, createStartEndParam } from 'utils';
import { AlertListParams } from 'models/AlertListParams';
import { AlertsList } from 'models/AlertsList';
import { DoorsStats } from 'models/DoorsStats';
import { RemoteActionType } from 'models/RemoteActionType';
import { DoorRemoteFunction } from 'models/DoorRemoteFunction';

const DOORS_ENDPOINT = 'doors';
const NOTIFICATIONS_ENDPOINT = 'notifications';
const DEVICE_ENDPOINT = 'devices';

let cyclesCancelToken: CancelTokenSource;
let alertsCancelToken: CancelTokenSource;
let doorsCancelToken: CancelTokenSource;
let doorsLastUpdateTimeCancelToken: CancelTokenSource;

export const fetchAllDoors = async ({
  params,
  followedOnly,
}: {
  params: DoorListParams;
  followedOnly: boolean;
}) => {
  const paramsString = params ? '&' + createParamsString(params) : '';

  try {
    if (typeof doorsCancelToken != typeof undefined) {
      doorsCancelToken.cancel('Operation canceled due to new request.');
    }

    doorsCancelToken = axios.CancelToken.source();

    const doorRequest = await client.get(
      `${DOORS_ENDPOINT}?FollowedOnly=${followedOnly}${paramsString}`,
      doorsCancelToken,
    );

    // filter active and inactive alerts
    // if (params.alerts) {
    //   const type = params.alerts;
    //   if (type === 'active') {
    //     doorRequest.data.doors = doorRequest.data.doors.filter(
    //       (item: any) => item.alertCount > 0,
    //     );
    //   } else {
    //     doorRequest.data.doors = doorRequest.data.doors.filter(
    //       (item: any) => item.alertCount === 0,
    //     );
    //   }
    // }

    const { status } = doorRequest;
    const data: DoorsResponse = doorRequest.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchDoorsLastUpdateTime = async (doors: DoorSummary[]) => {
  // get last Update Time
  try {
    const uuids = doors.map((item: any) => item.id);

    if (typeof doorsLastUpdateTimeCancelToken != typeof undefined) {
      doorsLastUpdateTimeCancelToken.cancel(
        'Operation canceled due to new request.',
      );
    }

    doorsLastUpdateTimeCancelToken = axios.CancelToken.source();

    const lastUpdateTimeRequest = await client.post(
      `${DOORS_ENDPOINT}/lastUpdateTime`,
      { uuids: uuids },
      doorsLastUpdateTimeCancelToken,
    );

    if (lastUpdateTimeRequest.data.length) {
      doors = doors.map((item: any) => {
        const { lastUpdate, timestamp, isOffline } =
          find(lastUpdateTimeRequest.data, { uuid: item.id }) || {};
        if (timestamp && lastUpdate) {
          item.lastUpdateTime = lastUpdate;
        } else {
          item.lastUpdateTime = '';
        }
        item.isOffline = isOffline || false;
        return item;
      });
    } else {
      doors = doors.map((item: any) => {
        item.lastUpdateTime = '';
        return item;
      });
    }

    const { status } = lastUpdateTimeRequest;
    const data: DoorSummary[] = doors;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const dataStreamingOperation = async (
  uuid: string,
  operation: 'Start' | 'Stop',
) => {
  try {
    const response = await client.post(`${DOORS_ENDPOINT}/dataStreaming`, {
      DoorId: uuid,
      Operation: operation,
    });

    const { status } = response;
    return status === 200;
  } catch (err) {
    throw err;
  }
};

export const fetchSingleDoor = async (id: string) => {
  try {
    const request = await client.get(`${DOORS_ENDPOINT}/${id}`);

    const { status } = request;
    const data: DoorDetails = request.data;

    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchTabbedDoorState = async (
  id: string,
  languageCode: string,
) => {
  try {
    const request = await client.get(
      `${DOORS_ENDPOINT}/${id}/doorState?languageCode=${languageCode}`,
    );

    const { status } = request;
    const data: any = request.data;

    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchDoorStatus = async (id: string) => {
  try {
    const request = await client.get(`${DOORS_ENDPOINT}/${id}/status`);

    const { status } = request;
    const data: DoorStatus = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchDoorRemoteFunction = async (
  model: string,
  doorId: string,
) => {
  try {
    const request = await client.get(
      `${DEVICE_ENDPOINT}/availableFunctions?model=${model}&doorId=${doorId}`,
    );

    const { status } = request;
    const data: DoorRemoteFunction = request.data;
    switch (status) {
      case 200:
        return data.availableFunctions;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchRefreshFrequency = async () => {
  try {
    const request = await client.get(`${DOORS_ENDPOINT}/refreshFrequency`);

    const { status } = request;
    const data: RefreshFrequency[] = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchfirmwareUpdateOptions = async () => {
  try {
    const request = await client.get(`${DOORS_ENDPOINT}/firmwareUpdateOptions`);
    const { status } = request;
    const data: LabelValue[] = request.data;

    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const editDoorDetails = async ({
  id,
  editParams,
}: {
  id: string;
  editParams: EditDoorParams;
}) => {
  try {
    const request = await client.patch(`${DOORS_ENDPOINT}/${id}`, editParams);
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

// TODO: @deprecated
export const fetchAlerts = async (
  startDate: string,
  endDate: string,
  followedOnly: boolean,
  doorId?: string,
  accountId?: string,
) => {
  const encodedStartDate = encodeURIComponent(
    moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
  );
  const encodedEndDate = encodeURIComponent(
    moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
  );
  const isFollowing = followedOnly ? 'true' : 'false';
  try {
    const alertsArray = await client.get(
      `${DOORS_ENDPOINT}/${
        doorId ? `${doorId}/` : ''
      }alerts?StartDate=${encodedStartDate}&EndDate=${encodedEndDate}&FollowedOnly=${isFollowing}&accountId=${accountId}`,
    );

    // get proper alert names if there are alert data
    if (alertsArray.data.length > 0) {
      const ids: string[] = [];
      const languageCode = i18n.language;
      for (const alert of alertsArray.data) {
        const { alertId } = alert;
        if (!ids.includes(alertId)) ids.push(alertId);
      }

      const alertNames = await client.post(`${DOORS_ENDPOINT}/alertName`, {
        ids,
        languageCode,
      });

      alertsArray.data.map((alert: any) => {
        const { data } = alertNames || {};
        const alertName = find(data, (alertName) => {
          return alert.alertId === alertName.alertId;
        });
        if (alertName) {
          alert.alertDisplayName = alertName.alertDisplayName;
        }
        return alert;
      });
    }

    const { status } = alertsArray;
    const data: Alert[] = alertsArray.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchActiveAlerts = async (
  followedOnly: boolean,
  doorId?: string,
) => {
  const isFollowing = followedOnly ? 'true' : 'false';
  try {
    const request = await client.get(
      `${
        doorId
          ? `${NOTIFICATIONS_ENDPOINT}/${doorId}`
          : `${NOTIFICATIONS_ENDPOINT}`
      }/current?followedOnly=${isFollowing}`,
    );

    const { status } = request;
    const alertsData: NotificationGroup[] = request.data;
    const languageCode = i18n.language;

    const ids: string[] = alertsData.map((alert) => {
      return alert.name;
    });

    if (ids.length > 0) {
      const alertNameData = await client.post(`${DOORS_ENDPOINT}/alertName`, {
        ids,
        languageCode,
      });

      alertsData.map((alert) => {
        const { data } = alertNameData || {};
        const alertName = find(data, (alertName) => {
          return alert.name === alertName.alertId;
        });
        if (alertName) {
          alert.displayName = alertName.alertDisplayName;
        }
        return alert;
      });
    }

    switch (status) {
      case 200:
        return alertsData;
      case 204:
        return alertsData;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchAlertsHistory = async (doorId: string, alertId: string) => {
  try {
    const languageCode = i18n.language;

    const request = await client.get(
      `${DOORS_ENDPOINT}/${doorId}/alertHistory/${alertId}?languageCode=${languageCode}`,
    );

    const { status } = request;
    const data: AlertHistory = request.data;

    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchAvailableNotificationTypes = async () => {
  try {
    const languageCode = i18n.language;

    const request = await client.get(
      `${DOORS_ENDPOINT}/availableAlerts?languageCode=${languageCode}`,
    );

    const { status } = request;
    const data: Alert[] = request.data;

    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchAllAlerts = async (params: AlertListParams) => {
  try {
    const languageCode = i18n.language;
    params.startDate = params.startDate
      ? encodeURIComponent(
          moment(decodeURIComponent(params.startDate)).format(
            'YYYY-MM-DDTHH:mm:ssZ',
          ),
        )
      : undefined;
    params.endDate = params.endDate
      ? encodeURIComponent(
          moment(decodeURIComponent(params.endDate)).format(
            'YYYY-MM-DDTHH:mm:ssZ',
          ),
        )
      : encodeURIComponent(
          moment()
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ssZ'),
        );

    // TODO: remove line below when releasing (force notification to Alert only as per DERF-972 requirement)
    //params.notificationType = NotificationType.Alert;

    // Convert the hard code type to notification type due to a requirement from https://conducthq.atlassian.net/browse/DERF-901
    // TODO: uncomment when releasing
    // if (params.type === NotificationType.Mode) {
    //   params.type = undefined
    //   params.notificationType = NotificationType.Mode
    // };
    const paramsString = createParamsString(params);

    if (typeof alertsCancelToken != typeof undefined) {
      alertsCancelToken.cancel('Operation canceled due to new request.');
    }

    alertsCancelToken = axios.CancelToken.source();

    const request = await client.get(
      `${NOTIFICATIONS_ENDPOINT}?languageCode=${languageCode}&${paramsString}`,
      alertsCancelToken,
    );

    const { status } = request;
    const data: any = request.data;

    if (data.data.length > 0) {
      const alertTypes = await fetchAvailableNotificationTypes();

      data.data.map((alert: AlertsList) => {
        const alertType = alertTypes?.find((alertType) => {
          return alertType.alertId === alert.type;
        });
        if (alertType) {
          alert.type = alertType.alertDisplayName;
        }
        return alert;
      });
    }

    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchAllTypes = async (accountId = '') => {
  try {
    const request = await client.get(
      `${DOORS_ENDPOINT}/types?accountid=${accountId}`,
    );
    const { status } = request;
    const data: LabelValue[] = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
      case 400:
        return request;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchConnectedDoorHealthAlerts = async (
  followedOnly: boolean,
  accountId = '',
) => {
  try {
    const request = await client.get(
      `${DOORS_ENDPOINT}/health?followedOnly=${followedOnly}&accountId=${accountId}`,
    );
    const { status } = request;
    const data: ConnectedDoorHealthAlerts[] = request.data;

    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
      case 400:
        return request;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchDoorCycles = async (
  startDate: string,
  endDate: string,
  followedOnly: boolean,
  doorId: string | undefined,
  accountId?: string,
) => {
  const updatedStartDate = moment(startDate)
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss');
  const updatedEndDate = moment(endDate)
    .utc()
    .format('YYYY-MM-DDTHH:mm:ss');

  const dateString = createStartEndParam({
    startDate: updatedStartDate,
    endDate: updatedEndDate,
  });
  // const dateString = createStartEndParam({ startDate, endDate });
  const isFollowing = followedOnly ? 'true' : 'false';

  const baseUrl = !doorId
    ? `${DOORS_ENDPOINT}/cycles`
    : `${DOORS_ENDPOINT}/${doorId}/cycles`;

  try {
    if (typeof cyclesCancelToken != typeof undefined) {
      cyclesCancelToken.cancel('Operation canceled due to new request.');
    }

    cyclesCancelToken = axios.CancelToken.source();

    const request = await client.get(
      `${baseUrl}?${dateString}&FollowedOnly=${isFollowing}`,
      cyclesCancelToken,
    );

    const { status } = request;
    const data = request.data;

    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const followDoor = async (id: string) => {
  try {
    const request = await client.post(`${DOORS_ENDPOINT}/${id}/follow`);
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export const getLastUpdateTime = async (uuids: string[]) => {
  try {
    const request = await client.post(`${DOORS_ENDPOINT}/lastUpdateTime`, {
      uuids: uuids,
    });
    const { data } = request;
    return data;
  } catch (err) {
    throw err;
  }
};

export const unfollowDoor = async (id: string) => {
  try {
    const request = await client.post(`${DOORS_ENDPOINT}/${id}/unfollow`);
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export const fetchDoorCurrentState = async (id: string) => {
  try {
    const request = await client.get(`${DOORS_ENDPOINT}/${id}/currentState`);
    const { data } = request;
    return data;
  } catch (err) {
    throw err;
  }
};

export const requestRemoteOpenDoor = async (
  id: string,
  remoteActionType: RemoteActionType,
  reason: string,
  requestedBy?: string,
) => {
  try {
    const request = await client.post(`${DOORS_ENDPOINT}/${id}/remoteOpen`, {
      reason,
      requestedBy: requestedBy || undefined,
      actionType: remoteActionType,
    });
    const { data } = request;
    return data;
  } catch (err) {
    throw err;
  }
};

export const requestRefreshData = async (
  id: string,
  reason: string,
  requestedBy?: string,
) => {
  try {
    const request = await client.post(`${DOORS_ENDPOINT}/${id}/refreshData`, {
      reason,
      requestedBy: requestedBy || undefined,
    });
    const { data } = request;
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchDoorsStats = async (
  followedOnly: boolean,
  accountId = '',
  group = false,
) => {
  try {
    const request = await client.get(
      `${DOORS_ENDPOINT}/status?followedOnly=${followedOnly}&accountId=${accountId}&group=${group}`,
    );
    const { status } = request;
    const data: DoorsStats = request.data;

    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
      case 400:
        return request;
    }
  } catch (err) {
    throw err;
  }
};
