import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  List,
  DonutContainer,
  ListContainer,
  TabsWrapper,
} from './operationalOverviewStyle';
import GridItem from './GridItem/GridItem';
import NoNotification from './NoNotification/NoNotification';
import {
  Box,
  ChartContainer,
  DonutChart,
  Grid,
  Paragraph as P,
  Skeleton,
  ToolTip,
} from 'components';
import {
  EntriWorX,
  formatDIOperationalAlertsOverviewData,
  formatDIOperationalModeChangesDetailsData,
  formatDIOperationalModeChangesOverviewData,
  isCurrentDayWeek7days,
  isCurrentDayWeekOrMonthDates,
  shouldShowModeElement,
} from 'utils';
import { useDoorDetailsStore, useNotificationStore } from 'stores';
import { SelectedDates, SelectedDateTypeEnum } from 'models';
import { useHistory } from 'react-router-dom';
import { useScreenSize } from 'hooks';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { NotificationType } from 'models';
import {
  Bar,
  ComposedChart,
  Legend,
  LegendProps,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { theme, Spacer } from 'style';
import moment from 'moment';
import {
  LegendContainer,
  LegendItem,
  MobileFlare,
} from 'components/StackedBarChart/StackedBarChartLegend/stackedBarChartLegendStyle';
import ChartSkeleton from 'products/door-insights/Dashboard/charts/DoorCycles/Skeleton/Skeleton';

interface Props {
  followedOnly: boolean;
  selectedDates: SelectedDates;
  selectedDateType: SelectedDateTypeEnum;
  triggerUpdate?: boolean;
  doorId: string;
  isOffline: boolean;
  isFollowing: boolean;
}

const ListSkeletonRow = () => (
  <Grid gridTemplateColumns="2fr 40px" alignItems="center" height={65}>
    <Skeleton width={120} height={28} />
    <Skeleton width={40} height={28} />
  </Grid>
);

const ListSkeleton = ({ children, alertIsLoading }: any) =>
  alertIsLoading ? (
    <>
      <ListSkeletonRow />
      <ListSkeletonRow />
      <ListSkeletonRow />
    </>
  ) : (
    children
  );

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  if (!active || !payload || payload.length === 0) return null;

  const data = payload.payload.data[payload.name.split('_')[1]];

  return (
    <Box p={15} square>
      <P color="charcoal" bold size="small">
        {data.displayName || data.name}
      </P>
      {data && (
        <P color="charcoal" size="small">
          {moment(data.startTime).format('h:mm A')} -{' '}
          {moment(data.endTime).format('h:mm A')}
        </P>
      )}
    </Box>
  );
};

const OperationalOverview = ({
  followedOnly,
  selectedDates,
  selectedDateType,
  triggerUpdate,
  doorId,
  isOffline,
  isFollowing,
}: Props) => {
  const { t } = useTranslation();
  const {
    notificationsIsLoading,
    notificationsSummaryData,
    notificationsDetailsData,
    notificationsSummaryFetch,
    notificationsDetailsFetch,
    notificationsDetailsIsLoading,
    modeChangeAlertNames,
  } = useNotificationStore();
  const totalAlert = notificationsSummaryData
    .filter((notification) => notification.type === NotificationType.Alert)
    .reduce((acc, alert) => acc + alert.value, 0);
  const groupedAlerts = formatDIOperationalAlertsOverviewData(
    notificationsSummaryData,
  );
  const totalModeChanges = notificationsSummaryData.filter(
    (notification) => notification.type === NotificationType.Mode,
  ).length;
  const groupedModeChanges = formatDIOperationalModeChangesOverviewData(
    notificationsSummaryData,
    selectedDateType,
  );
  const modeChangeDetails = formatDIOperationalModeChangesDetailsData(
    notificationsDetailsData,
  );

  const { doorDetailsData } = useDoorDetailsStore();
  const history = useHistory();
  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  const navigateOnRowClick = (row: any) => {
    history.push({
      pathname: `/notifications`,
      search: `?type=${row.name}&doorUUID=${doorId}&startDate=${selectedDates.startDate}&endDate=${selectedDates.endDate}&followedOnly=${followedOnly}&dateType=${selectedDateType}`,
      state: {
        fromPage: history.location.pathname,
        id: row.name,
        name: `${row.displayName}`,
      },
    });
  };

  useEffect(() => {
    let refreshInterval: any;
    if (isCurrentDayWeekOrMonthDates(selectedDates)) {
      refreshInterval = setInterval(() => {
        notificationsSummaryFetch(
          selectedDates,
          selectedDateType,
          followedOnly,
          doorId,
        );
      }, 5 * 60 * 1000);
    }

    return () => {
      clearInterval(refreshInterval);
    };
  }, [selectedDates]);

  // filter relevant configs for current alerts
  useEffect(() => {
    notificationsSummaryFetch(
      selectedDates,
      selectedDateType,
      followedOnly,
      doorId,
    );

    if (isCurrentDayWeek7days(selectedDates)) {
      notificationsDetailsFetch(
        selectedDates,
        selectedDateType,
        followedOnly,
        doorId,
      );
    }
  }, [selectedDates, triggerUpdate, followedOnly]);

  const convertModeChangeDetails = (data: any) => {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      const obj: any = {
        name: data[i].name,
        data: data[i].modeChanges,
      };
      // loop throgh comodities
      for (let j = 0; j < data[i].modeChanges.length; j++) {
        const modeChange = data[i].modeChanges[j];
        obj[`${i}_${j}_${modeChange.name}`] = modeChange.duration / (60 * 60);
      }
      arr.push(obj);
    }
    return arr;
  };

  const renderLegend = (props: LegendProps) => {
    const { payload } = props;
    const uniquePayload = payload
      ?.map((item) => {
        return {
          ...item,
          value: item.value.split('_')[2],
          color: theme.colors.modeChanges[item.value.split('_')[2]],
          stroke: item.value.includes('modeFuture')
            ? theme.colors.lightBlueGrey
            : theme.colors.modeChanges[item.value.split('_')[2]],
        };
      })
      .filter(
        (value, index, self) =>
          index === self.findIndex((item) => item.value === value.value),
      );

    const legendText = (value: string) =>
      value === 'modeNotAvailable'
        ? 'Mode unavailable'
        : modeChangeAlertNames[value] || value;

    return (
      <LegendContainer>
        {uniquePayload
          ?.sort((a, b) => (a.value > b.value ? -1 : 1))
          .map((entry, index) => (
            <LegendItem key={`item-${index}`}>
              <MobileFlare
                color={entry.color}
                stroke={entry.stroke}
                size={12}
              />
              <P
                color={theme.colors.primary}
                fontWeight={600}
                style={{ fontSize: isMobile ? '14px' : '16px' }}>
                {legendText(entry.value)}
              </P>
            </LegendItem>
          ))}
      </LegendContainer>
    );
  };

  const [activeTooltip, setActiveTooltip] = useState<string>();

  const renderTooltip = (props: TooltipProps) => {
    const { payload, active } = props;
    if (!active || !activeTooltip) return null;

    let tooltip = null;

    payload?.forEach((bar) => {
      if (bar.name === activeTooltip) {
        tooltip = <CustomTooltip payload={bar} active={true} />;
      }
    });

    return tooltip;
  };

  const getBarRadius = (keys: string[], index: number) => {
    if (index === 0 && keys.length === 1) {
      return [6, 6, 6, 6];
    } else if (index === 0) {
      return [6, 0, 0, 6];
    } else if (index === keys.length - 1) {
      return [0, 6, 6, 0];
    }
  };

  const stackedBarHourTicks = Array.from(Array(25).keys());
  return (
    <>
      <TabsWrapper>
        <Tabs>
          <TabList style={{ marginBottom: isMobile ? '20px' : '30px' }}>
            <Tab>{t('common:tabs.alerts')}</Tab>
            {shouldShowModeElement() && (
              <Tab>{t('common:tabs.mode_changes')}</Tab>
            )}
          </TabList>
          <TabPanel>
            {notificationsIsLoading ? (
              <ChartContainer
                childrenStyleProps={{ padding: 0 }}
                title={t('operationalOverview:title')}
                hideHeader={true}>
                <Container>
                  <DonutContainer>
                    <DonutChart
                      dataKey="value"
                      nameKey="label"
                      label={{
                        countValue: totalAlert,
                        i18nLabel: t('operationalOverview:donut_label'),
                      }}
                      isLoading={notificationsIsLoading}
                    />
                  </DonutContainer>
                  <ListContainer>
                    <List>
                      <ListSkeleton alertIsLoading />
                    </List>
                  </ListContainer>
                </Container>
              </ChartContainer>
            ) : (
              <ChartContainer
                childrenStyleProps={{ padding: 0 }}
                title={t('operationalOverview:title')}
                hideHeader={true}
                greenBorder={
                  totalAlert === 0 && !notificationsIsLoading && !isOffline
                }>
                {totalAlert === 0 && !notificationsIsLoading ? (
                  <NoNotification
                    type={NotificationType.Alert}
                    isOffline={isOffline}
                    isFollowing={isFollowing}
                  />
                ) : (
                  <Container>
                    <DonutContainer>
                      <DonutChart
                        isLoading={notificationsIsLoading}
                        data={totalAlert ? groupedAlerts : undefined}
                        dataKey="value"
                        nameKey="label"
                        label={{
                          countValue: totalAlert,
                          i18nLabel: t('operationalOverview:donut_label'),
                        }}
                      />
                    </DonutContainer>
                    <ListContainer>
                      <List>
                        <ListSkeleton alertIsLoading={notificationsIsLoading}>
                          {groupedAlerts.map(
                            (groupedAlert: any, index: number) => (
                              <GridItem
                                key={`${groupedAlert}_${index}}`}
                                groupedAlert={groupedAlert}
                                noBorder={index === groupedAlerts.length - 1}
                                navigateOnRowClick={navigateOnRowClick}
                              />
                            ),
                          )}
                        </ListSkeleton>
                      </List>
                    </ListContainer>
                  </Container>
                )}
              </ChartContainer>
            )}
          </TabPanel>
          <TabPanel>
            {isCurrentDayWeek7days(selectedDates) ? (
              <>
                {notificationsDetailsIsLoading ? (
                  <ChartContainer title={''} hideHeader={true}>
                    <ChartSkeleton />
                  </ChartContainer>
                ) : (
                  <ChartContainer
                    childrenStyleProps={{ padding: 0 }}
                    title={t('operationalOverview:title')}
                    hideHeader={false}
                    greenBorder={
                      modeChangeDetails.length === 0 &&
                      !notificationsDetailsIsLoading
                    }>
                    {modeChangeDetails.length === 0 &&
                    !notificationsDetailsIsLoading ? (
                      <NoNotification
                        type={NotificationType.Mode}
                        isFollowing={isFollowing}
                      />
                    ) : doorDetailsData.deviceType === EntriWorX ? (
                      <NoNotification
                        type={NotificationType.EntriWorX}
                        isFollowing={isFollowing}
                      />
                    ) : (
                      <ResponsiveContainer
                        width="100%"
                        height={
                          isMobile
                            ? 164 + modeChangeDetails.length * 54
                            : 236 + modeChangeDetails.length * 54
                        }>
                        <ComposedChart
                          data={
                            modeChangeDetails.length > 0
                              ? convertModeChangeDetails(modeChangeDetails)
                              : undefined
                          }
                          margin={{
                            left: isMobile ? 20 : 40,
                            right: isMobile ? 20 : 40,
                            top: isMobile ? 30 : 62,
                            bottom: isMobile ? 30 : 60,
                          }}
                          layout="vertical">
                          <XAxis
                            type="number"
                            tickLine={false}
                            axisLine={false}
                            xAxisId={0}
                            scale={'linear'}
                            interval={'preserveStart'}
                            ticks={stackedBarHourTicks}
                            tickFormatter={(hour) => {
                              if (hour === 24) return '';
                              return moment()
                                .set('hour', hour)
                                .format('h');
                            }}
                            tick={{
                              fontSize: '14px',
                              lineHeight: '25px',
                              fill: theme.colors.charcoal,
                            }}
                            tickMargin={12}
                            padding={{ left: 10, right: 10 }}
                            height={32}
                          />

                          <XAxis
                            type="number"
                            tickLine={false}
                            axisLine={false}
                            xAxisId={1}
                            interval={0}
                            scale={'linear'}
                            ticks={stackedBarHourTicks}
                            tickFormatter={(hour) => {
                              if (hour === 24) return '';
                              if (hour % 12 === 0) {
                                return moment()
                                  .set('hour', hour)
                                  .format('A');
                              } else {
                                return '';
                              }
                            }}
                            tick={{
                              fontSize: '14px',
                              lineHeight: '21px',
                              fontWeight: 'bold',
                              textAlign: 'left',
                              fill: theme.colors.charcoal,
                            }}
                          />
                          <YAxis
                            type="category"
                            dataKey="name"
                            tickLine={false}
                            axisLine={false}
                            tick={{ fontSize: isMobile ? '12px' : '14px' }}
                          />
                          <Tooltip content={renderTooltip} />
                          <Legend
                            wrapperStyle={{
                              bottom: isMobile ? 25 : 45,
                              left: isMobile ? 52 : 72,
                            }}
                            content={renderLegend}
                          />

                          {convertModeChangeDetails(modeChangeDetails).map(
                            (item, i) => {
                              const keys = Object.keys(item).filter(
                                (key) => ['name', 'data'].indexOf(key) === -1,
                              );
                              return keys.map((keyItem, j) => {
                                return (
                                  <Bar
                                    key={`${i}_${j}`}
                                    dataKey={keyItem}
                                    stackId="a"
                                    barSize={12}
                                    radius={getBarRadius(keys, j)}
                                    fill={
                                      theme.colors.modeChanges[
                                        keyItem.split('_')[2]
                                      ]
                                    }
                                    stroke={
                                      keyItem.includes('_modeFuture')
                                        ? theme.colors.lightBlueGrey
                                        : theme.colors.modeChanges[
                                            keyItem.split('_')[2]
                                          ]
                                    }
                                    onMouseOver={() =>
                                      setActiveTooltip(`${keyItem}`)
                                    }
                                    onMouseOut={() => setActiveTooltip('')}
                                  />
                                );
                              });
                            },
                          )}
                        </ComposedChart>
                      </ResponsiveContainer>
                    )}
                  </ChartContainer>
                )}
              </>
            ) : (
              <>
                {notificationsIsLoading ? (
                  <ChartContainer
                    childrenStyleProps={{ padding: 0 }}
                    title={t('operationalOverview:title')}
                    hideHeader={true}>
                    <Container>
                      <DonutContainer>
                        <DonutChart
                          dataKey="value"
                          nameKey="label"
                          isLoading={notificationsIsLoading}
                          type="pie"
                        />
                      </DonutContainer>
                      <ListContainer>
                        <List>
                          <ListSkeleton alertIsLoading />
                        </List>
                      </ListContainer>
                    </Container>
                  </ChartContainer>
                ) : (
                  <ChartContainer
                    childrenStyleProps={{ padding: 0 }}
                    title={t('operationalOverview:title')}
                    hideHeader={true}>
                    {totalModeChanges === 0 && !notificationsIsLoading ? (
                      <NoNotification
                        type={NotificationType.Mode}
                        isFollowing={isFollowing}
                      />
                    ) : (
                      <Container>
                        <DonutContainer>
                          <DonutChart
                            isLoading={notificationsIsLoading}
                            data={
                              totalModeChanges ? groupedModeChanges : undefined
                            }
                            dataKey="value"
                            nameKey="label"
                            type="pie"
                          />
                        </DonutContainer>
                        <ListContainer>
                          <List>
                            <ListSkeleton
                              alertIsLoading={notificationsIsLoading}>
                              {groupedModeChanges.map(
                                (groupedModeChange: any, index: number) => (
                                  <GridItem
                                    key={`${groupedModeChange}_${index}}`}
                                    groupedAlert={groupedModeChange}
                                    noBorder={
                                      index === groupedModeChanges.length - 1
                                    }
                                    navigateOnRowClick={navigateOnRowClick}
                                    valueSuffix={`%`}
                                  />
                                ),
                              )}
                              <Spacer height={10} />
                              <P
                                size="small"
                                color={theme.colors.blueGrey}
                                style={{ textAlign: 'right' }}>
                                {t('operationalOverview:rounding_disclaimer')}
                              </P>
                            </ListSkeleton>
                          </List>
                          <div style={{ height: '100%', paddingTop: '10%' }}>
                            <ToolTip
                              content={t(
                                'common:mode_label.utilisation_tooltip',
                              )}
                              iconSize={20}
                              isInline={true}
                            />
                          </div>
                        </ListContainer>
                      </Container>
                    )}
                  </ChartContainer>
                )}
              </>
            )}
          </TabPanel>
        </Tabs>
      </TabsWrapper>
    </>
  );
};

export default OperationalOverview;
