import styled from '@emotion/styled';
import { mq, theme } from 'style';

export const StateItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 14px;
  border-bottom: ${theme.borders(1, 'lightBlueGrey')};
`;

export const StateItemKey = styled.div`
  font-weight: 500;
  color: ${theme.colors.primary};
  ${mq.mobile()} {
    max-width: 48%;
    word-break: break-all;
  }
`;

export const StateItemValue = styled.div`
  margin-left: 20px;
  ${mq.mobile()} {
    max-width: 48%;
    margin-right: 4%;
    text-align: right;
    word-break: break-all;
  }
`;

interface StatusFlareProps {
  backgroundColor: string;
}

export const StatusFlare = styled.div<StatusFlareProps>`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${(props) => theme.colors[props.backgroundColor]};
  margin-right: 8px;
`;
