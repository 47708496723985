import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph as P, Flex, Spinner } from 'components';
import { Item } from './occupancyStatsStyle';
import { useCurrentOccupancyStore } from 'stores';

interface Props {}

interface ItemWrapperProps {
  title: string;
  count: any;
  isLoading: boolean;
}

const ItemWrapper = ({ title, count, isLoading }: ItemWrapperProps) => (
  <Item data-cypress="occupancy-stats-item">
    {!isLoading ? (
      <>
        <P size="small" color="white" bold>
          {title.toUpperCase()}
        </P>
        <P size="large" color="white" bold>
          {count}
        </P>
      </>
    ) : (
      <Spinner color="white" />
    )}
  </Item>
);

const OccupancyStats = ({}: Props) => {
  const { t } = useTranslation();
  const { currentOccupancyData, isLoading } = useCurrentOccupancyStore();
  const [percentage, setPercentage] = useState<number>(0);

  useEffect(() => {
    const { spacesCount, spacesOffline } = currentOccupancyData;
    const perc = ((spacesCount - spacesOffline) / spacesCount) * 100;
    setPercentage(Math.floor(perc));
  }, [currentOccupancyData]);

  return (
    <Flex alignItems="center">
      <ItemWrapper
        isLoading={isLoading}
        title={t('dashboard:spaces')}
        count={currentOccupancyData.spacesCount}
      />
      <ItemWrapper
        isLoading={isLoading}
        title={t('dashboard:doors')}
        count={currentOccupancyData.doorsCount}
      />
      <ItemWrapper
        isLoading={isLoading}
        title={t('dashboard:connectivity')}
        count={percentage? `${percentage}%` : ''}
      />
    </Flex>
  );
};

export default OccupancyStats;
