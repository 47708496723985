import React, { useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';
import moment from 'moment';

import {
  Grid,
  Skeleton,
  Table,
  Paragraph as P,
  IcomoonIcons,
} from 'components';
import { AlertsList } from 'models/AlertsList';
import { useScreenSize } from 'hooks';
import AlertListItem from './AlertListItem/AlertListItem';
import { useHistory } from 'react-router-dom';
import { useAlertsTableStyle } from './alertsTablestyles';
import { useTranslation } from 'react-i18next';
import { SelectedDates, SelectedDateTypeEnum } from 'models';
import { NotificationType } from 'models/Notifications';

interface Props {
  data: AlertsList[];
  sortBy: (sortBy: 'name') => void;
  showAccountName: boolean;
  isLoadingMore: boolean;
  onLoadMore: (nextPage: number) => void;
  paginated: boolean;
  total?: number;
  currentPage?: number;
  limit?: number;
  selectedDates: SelectedDates;
  selectedDateType: SelectedDateTypeEnum;
}

export const toDuration = (input: string) => {
  const re = /(?:(\d+)d ?)?(?:(\d+)h ?)?(?:(\d+)m ?)?(?:(\d+)s ?)?/;
  const [, days, hours, minutes, seconds] = input.match(re) as any;
  let duration = days > 0 ? `${days}d ` : ``;
  duration += hours > 0 ? `${hours}h ` : ``;
  duration += minutes > 0 ? `${minutes}m ` : ``;
  duration += seconds > 0 ? `${seconds}s` : ``;

  return duration;
};

const AlertsTable = ({
  data,
  sortBy,
  showAccountName,
  isLoadingMore,
  onLoadMore,
  paginated,
  total = 0,
  currentPage = 1,
  limit = 20,
  selectedDates,
  selectedDateType,
}: Props) => {
  const {
    Wrapper,
    Pagination,
    PaginationItem,
    GeneralHeading,
    CriticalHeading,
  } = useAlertsTableStyle();
  const { t } = useTranslation();
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        columns: [
          {
            id: 'notificationType',
            Header: 'alerts:table.column.notification_type',
            accessor: 'notificationType',
            sortable: true,
            filterable: true,
            Cell: (props: any) => {
              const text = props?.row?.original?.notificationType
                ? props?.row?.original?.notificationType ===
                  NotificationType.Alert
                  ? t('alerts:notification_type.alert')
                  : t('alerts:notification_type.mode')
                : ``;
              return (
                <>
                  {
                    <P style={{ fontSize: '14px' }}>
                      {text}{' '}
                      {props?.row?.original?.notificationType ===
                        NotificationType.Alert &&
                        (props?.row?.original?.isCritical ? (
                          <CriticalHeading>
                            {t('alerts:names.criticalAlertTitle')}
                          </CriticalHeading>
                        ) : (
                          <GeneralHeading>
                            {t('alerts:names.generalAlertTitle')}
                          </GeneralHeading>
                        ))}
                    </P>
                  }
                </>
              );
            },
          },
          {
            id: 'status',
            Header: 'alerts:table.column.status',
            accessor: 'status',
            sortable: true,
            filterable: true,
            Cell: (props: any) => `${props?.row?.original?.status || ``}`,
          },
          {
            id: 'type',
            Header: 'alerts:table.column.event',
            accessor: 'type',
            sortable: true,
            filterable: true,
            Cell: (props: any) => `${props?.row?.original?.type}`,
          },
          showAccountName
            ? {
                id: 'accountName',
                Header: 'alerts:table.column.account_name',
                accessor: 'accountName',
                sortable: true,
                filterable: true,
                Cell: (props: any) =>
                  `${props?.row?.original?.accountName || ``}`,
              }
            : undefined,
          {
            id: 'doorName',
            Header: 'alerts:table.column.door_name',
            accessor: 'doorName',
            sortable: true,
            filterable: true,
            Cell: (props: any) => `${props?.row?.original?.doorName || ``}`,
          },
          {
            id: 'operatorType',
            Header: 'alerts:table.column.operator_type',
            accessor: 'operatorType',
            sortable: true,
            filterable: true,
            Cell: (props: any) => `${props?.row?.original?.operatorType || ``}`,
          },
          {
            id: 'openDateTime',
            Header: 'alerts:table.column.open_date_time',
            accessor: 'operatorType',
            sortable: true,
            filterable: true,
            Cell: (props: any) =>
              props?.row?.original?.openDateTime
                ? moment(props?.row?.original?.openDateTime).format(
                    'DD/MM/YYYY - HH:mm',
                  )
                : ``,
          },
          {
            id: 'closedDateTime',
            Header: 'alerts:table.column.closed_date_time',
            accessor: 'closedDateTime',
            sortable: true,
            filterable: true,
            Cell: (props: any) =>
              props?.row?.original?.closedDateTime
                ? moment(props?.row?.original?.closedDateTime).format(
                    'DD/MM/YYYY - HH:mm',
                  )
                : ``,
          },
          {
            id: 'duration',
            Header: 'alerts:table.column.duration',
            accessor: 'duration',
            sortable: true,
            filterable: true,
            Cell: (props: any) =>
              `${toDuration(props?.row?.original?.duration) || ``}`,
          },
        ].filter((col) => col),
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  const navigateOnRowClick = (row: AlertsList) => {
    const startDate =
      selectedDates.startDate ||
      moment(row.openDateTime)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ssZ');
    const endDate =
      selectedDates.endDate ||
      moment(row.openDateTime)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ssZ');
    history.push({
      pathname: `/doors/${row.icNumber}`,
      search: `?startDate=${startDate}&endDate=${endDate}`,
      state: {
        fromPage: history.location.pathname,
        id: row.icNumber,
        name: `${row.doorName}`,
      },
    });
  };

  const { isMobile } = useScreenSize().screenSize();
  if (isMobile) {
    return (
      <>
        <Grid gridAutoFlow="row" gridRowGap="15px">
          {rows.map((row: any, index: number) => (
            <AlertListItem
              key={index}
              row={row}
              showAccountName={showAccountName}
              navigateOnRowClick={navigateOnRowClick}
            />
          ))}
        </Grid>
        {paginated && (
          <Wrapper>
            {!isLoadingMore && (
              <Pagination>
                <P color="charcoal" size="small">
                  {currentPage} {t('common:showing_total.of')}{' '}
                  {Math.ceil(total / limit)}
                </P>

                <PaginationItem
                  onClick={() => {
                    if (onLoadMore && currentPage > 1) {
                      onLoadMore(currentPage - 1);
                    }
                  }}>
                  <IcomoonIcons
                    icon="chevron-left"
                    color={
                      currentPage > 1 ? 'charcoal' : 'blueGrey'
                    }></IcomoonIcons>
                </PaginationItem>
                <PaginationItem
                  onClick={() => {
                    if (onLoadMore && currentPage < total / limit) {
                      onLoadMore(currentPage + 1);
                    }
                  }}>
                  <IcomoonIcons
                    icon="chevron-right"
                    color={
                      currentPage < total / limit ? 'charcoal' : 'blueGrey'
                    }></IcomoonIcons>
                </PaginationItem>
              </Pagination>
            )}
            {isLoadingMore && (
              <Pagination>
                <Skeleton />
              </Pagination>
            )}
          </Wrapper>
        )}
      </>
    );
  }

  return (
    <Table
      columns={
        showAccountName
          ? '2fr 1.5fr 2.5fr 3fr 3fr 2.5fr 2.5fr 2.5fr 1.5fr 60px'
          : '2fr 1.5fr 2.5fr 3fr 2.5fr 2.5fr 2.5fr 1.5fr 60px'
      }
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      rows={rows}
      prepareRow={prepareRow}
      onRowClick={navigateOnRowClick}
      navigateOnRowClick={navigateOnRowClick}
      paginated={paginated}
      isLoadingMore={isLoadingMore}
      onLoadMore={onLoadMore}
      total={total}
      currentPage={currentPage}
      limit={limit}
      sortBy={sortBy}
      minWidth={1400}
    />
  );
};

export default AlertsTable;
