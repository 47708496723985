import styled from '@emotion/styled';
import { contentWidths, mq } from 'style';
import { space } from 'styled-system';

export const FormContainer = styled.div`
  height: auto;
  padding-left: 30px;
  padding-right: 30px;
  margin: 30px auto;
  width: 100%;
  max-width: ${contentWidths.desktop};
  ${mq.wideDesktop()} {
    max-width: ${contentWidths.wideDesktop};
  }
  ${mq.mobile()} {
    padding-left: 0;
    padding-right: 0;
    max-width: ${contentWidths.mobile};
    padding-bottom: 22px;
  }
  ${space};
`;

export const DetailsContainer = styled.div`
  padding-bottom: 0;
  ${mq.mobile()} {
    padding-bottom: 24px;
    padding-top: 24px;
  }
`;
