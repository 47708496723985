import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';

import {
  ChartContainer,
  Grid,
  SlideToggle,
  IcomoonIcons,
  Paragraph,
  BasicError,
} from 'components';
import { useScreenSize } from 'hooks';

import { DailyTrafficChart } from './DailyTrafficChart/DailyTafficChart';
import { DailyTrafficTable } from './DailyTrafficTable/DailyTrafficTable';
import LoadingSkeleton from '../../../Spaces/SpacesTable/LoadingSkeleton/LoadingSkeleton';
import { DATE_PERIODS } from '../utils';
import { TrafficUiState } from '../../hooks/useDailyTraffic';

const TRAFFIC_DIRECTIONS = ['In', 'Out'] as const;
export type TrafficDirectionT = typeof TRAFFIC_DIRECTIONS[number];

interface Props {
  todayTraffic: TrafficUiState;
  yesterdayTraffic: TrafficUiState;
  error: string;
  activeDevice: string | null;
  setActiveDevice: () => void;
  trafficDirection: 'In' | 'Out';
  setTrafficDirection: any;
  datePeriod: any;
  setDatePeriod: any;
  fetchTodaysTraffic: (id: string) => void;
}
type UseParamsProps = {
  id: string;
};

export const DailyTraffic = ({
  todayTraffic,
  yesterdayTraffic,
  error,
  activeDevice,
  setActiveDevice,
  trafficDirection,
  setTrafficDirection,
  datePeriod,
  setDatePeriod,
  fetchTodaysTraffic,
}: any) => {
  const { id: spaceId } = useParams<UseParamsProps>();
  const { t } = useTranslation();

  const { screenSize } = useScreenSize();
  const { isMobile } = screenSize();

  if (error) {
    return <BasicError heading="Error" subheading={error.toString()} />;
  }

  const ClearSelection = () => (
    <Grid
      gridTemplateColumns="min-content max-content"
      alignContent="center"
      gridColumnGap="10px"
      style={{ cursor: 'pointer', textDecoration: 'underline' }}
      onClick={() => setActiveDevice(undefined)}>
      <IcomoonIcons icon="refresh" color="primary" size={20} />
      <Paragraph size="small" color="primary" as="a">
        {t('spaceDetails:charts.daily_traffic_chart.clear_selection')}
      </Paragraph>
    </Grid>
  );

  return (
    <ErrorBoundary
      fallback={
        <BasicError
          heading="An unexpected error has occured"
          subheading="This tab has timed-out. Please refresh the page"
          shrink
        />
      }>
      <ChartContainer
        title={t('spaceDetails:charts.daily_traffic')}
        mobileToggle={activeDevice && <ClearSelection />}
        toggle={
          <Grid
            gridTemplateColumns={
              !activeDevice
                ? ['1fr', 'repeat(2, 1fr)']
                : ['1fr', 'max-content 1fr']
            }
            gridAutoFlow={['row', 'column']}
            gridAutoRows="1fr"
            gridRowGap="10px"
            mb={['20px', 0]}
            gridColumnGap="35px">
            {activeDevice && !isMobile ? <ClearSelection /> : null}
            <SlideToggle
              indexOverride={trafficDirection === 'In' ? 0 : 1}
              items={TRAFFIC_DIRECTIONS.map((direction) => {
                return {
                  label: t(
                    `spaceDetails:charts.daily_traffic_chart.${direction.toLowerCase()}`,
                  ),
                  callback: () => setTrafficDirection(direction),
                };
              })}
            />
            <SlideToggle
              indexOverride={datePeriod === 'Today' ? 0 : 1}
              items={DATE_PERIODS.map((period) => {
                return {
                  label: t(`common:${period.toLowerCase()}`),
                  callback: () => {
                    setDatePeriod(period);
                  },
                };
              })}
            />
          </Grid>
        }
        tooltip={t('spaceDetails:charts.daily_traffic_tooltip')}>
        <Grid gridRowGap="40px">
          <DailyTrafficChart
            data={
              datePeriod === 'Yesterday'
                ? yesterdayTraffic.data
                : todayTraffic.data
            }
            datePeriod={datePeriod}
            isLoading={
              datePeriod === 'Yesterday'
                ? yesterdayTraffic.isLoading
                : todayTraffic.isLoading
            }
            trafficDirection={trafficDirection}
            activeDevice={activeDevice?.original}
          />
          <LoadingSkeleton
            isLoading={
              datePeriod === 'Yesterday'
                ? yesterdayTraffic.isLoading
                : todayTraffic.isLoading
            }>
            <DailyTrafficTable
              data={
                datePeriod === 'Yesterday'
                  ? yesterdayTraffic.data
                  : todayTraffic.data
              }
              lastRefresh={
                datePeriod === 'Yesterday'
                  ? yesterdayTraffic.lastRefresh
                  : todayTraffic.lastRefresh
              }
              activeDevice={activeDevice}
              setActiveDevice={setActiveDevice}
            />
          </LoadingSkeleton>
        </Grid>
      </ChartContainer>
    </ErrorBoundary>
  );
};

export default DailyTraffic;
