import styled from "@emotion/styled";
import { flexVertical, theme, inputDefaults, flexCenterAll, mq } from "style";

export const Container = styled.div``;

export const Controls = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
  margin-bottom: 30px;

  ${mq.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
`;

export const SearchBarWrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 45px;
  ${flexCenterAll}
`;

export const SearchBar = styled.input`
  border: 1px solid ${theme.colors.blueGrey};
  ${inputDefaults};
  padding: 15px 0px;
  width: 365px;
  padding-left: 45px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 0;
  line-height: normal;
  &:focus {
    border-color: ${theme.colors.primary};
    outline: none;
  }
  color: ${theme.colors.charcoal};
`;

type ColorProps = {
  color: string;
  theme: any;
};

export const MobileFlare = styled.div<ColorProps>`
  height: 16px;
  width: 16px;
  border-radius: 2px;
  margin-right: 8px;
  background-color: ${({ color, theme }) => theme.colors[color]};
`;

export const LegendItem = styled.div`
  margin-right: 30px;
  display: flex;
  ${flexVertical}

  &:last-child {
    margin-right: 0;
  }
`;
