import React, { useState, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import {
  StyledDiv,
  Button,
  ExternalComponentWrapper,
  Indicator,
} from './slideToggleStyle';
import { useScreenSize } from 'hooks';

export interface SlideToggleItem {
  label?: string;
  icon?: any;
  callback: () => void;
}

interface Props {
  items: SlideToggleItem[];
  component?: React.ReactNode;
  fontSize?: string | number;
  indexOverride?: number;
}

const translateReducer = (collection: any[], index: number) => {
  if (index === 0) {
    return 0;
  } else {
    return collection.slice(0, index).reduce((acc, current) => acc + current);
  }
};

const createWidthArr = (arr: any[]) => {
  return arr.map((item) => {
    const { current } = item;
    if (current) {
      return current.getBoundingClientRect().width;
    }
  });
};

const SlideToggle = ({
  items,
  component,
  fontSize,
  indexOverride = 0,
  ...rest
}: Props) => {
  const [collection, setCollection] = useState<any[]>([]);

  const refs: any = useRef(
    Array.from({ length: items.length }, () => React.createRef()) as any,
  );

  const [indicatorWidth, setIndicatorWidth] = useState<number>(0);
  const [translateValue, setTranslateValue] = useState<number>(0);
  const [transition, setTransition] = useState<string>('0.3s');

  useEffect(() => {
    if (collection.length > 0) {
      setIndicatorWidth(collection[isActiveIndex]);
      setTranslateValue(translateReducer(collection, isActiveIndex));
    }
  }, [collection]);

  useEffect(() => {
    const myObserver = new ResizeObserver((entries: any) => {
      entries.forEach((entry: any, index: number) => {
        if (entry) {
          const widthArr = createWidthArr(refs.current);
          setCollection(widthArr);
          setTransition('0s');
        }
      });
    });

    refs.current.forEach((node: any) => {
      const { current } = node;
      myObserver.observe(current);
    });

    return () => {
      refs.current.forEach((node: any) => {
        const { current } = node;
        myObserver.unobserve(current);
      });
    };
  }, [refs]);

  const [isActiveIndex, setIsActiveIndex] = useState<number>(indexOverride);
  useEffect(() => {
    setIsActiveIndex(indexOverride);
  }, [indexOverride]);

  const toggleActionWrapper = (callback: () => void, index: number) => {
    if (collection) {
      const elementWidth = collection[index];
      setIndicatorWidth(elementWidth);
      setTranslateValue(translateReducer(collection, index));
      setTransition('0.3s');
      setIsActiveIndex(index);
      callback();
    }
  };

  return (
    <StyledDiv data-testid="slide-toggle" {...rest}>
      {indicatorWidth > 0 && (
        // @ts-ignore
        <Indicator
          width={indicatorWidth}
          translate={translateValue}
          transition={transition}
        />
      )}

      {items.map((item: SlideToggleItem, index: number) => (
        <Button
          id={`slide_toggle_${index}`}
          data-testid={`slide_toggle_${item.label}`}
          key={`slide_toggle_${index}`}
          ref={refs.current[index]}
          style={{
            fontSize: fontSize,
            minWidth: `calc(${(100 / items.length).toFixed()}%)`,
            textAlign: 'center',
          }}
          data-mapped-button
          onClick={() => toggleActionWrapper(item.callback, index)}
          isActive={isActiveIndex === index}>
          {item.label && item.label}
          {item.icon && { ...item.icon }}
        </Button>
      ))}
      {component && (
        <ExternalComponentWrapper>{component}</ExternalComponentWrapper>
      )}
    </StyledDiv>
  );
};

export default SlideToggle;
