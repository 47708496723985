import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, IconContainer, MobileFlare } from './gridItemStyle';
import { IcomoonIcons, Paragraph as P, Flex, Skeleton, Grid } from 'components';
import { useWindowSize } from 'hooks';
import { useCurrentOccupancyStore } from 'stores';
import { CurrentOccupancyDataObject } from 'utils';
import { StatusEnum } from 'models';

interface Props {
  item: CurrentOccupancyDataObject;
  navigate: (filter: StatusEnum | null) => void;
}

// SKELETONS
const DesktopSkeleton = ({ children, isLoading }: any) =>
  isLoading ? (
    <>
      <Skeleton height={68} width={68} mb="5px" />
      <Skeleton width={250} height={30} mb="5px" />
      <Skeleton width={150} height={24} />
    </>
  ) : (
    children
  );

const GridItem = ({ item, navigate }: Props) => {
  const { isLoading } = useCurrentOccupancyStore();
  const { t } = useTranslation();

  const { windowSize } = useWindowSize();
  return (
    <Container
      onClick={() => navigate(item.filterName)}
      data-cypress="current-occupancy-chart-nav"
      isZero={item.count === 0}>
      {windowSize.width <= 768 ? (
        <Grid
          alignItems="center"
          gridTemplateColumns="min-content 1fr max-content min-content"
          width="100%">
          <MobileFlare color={item.color} />
          <P size="small" color="charcoal" bold>
            {t(item.label.current.toString())}
          </P>
          <P size="small" color="charcoal" mr="15px">
            {t('currentOccupancy:grid.space', { count: item.count })}
          </P>
          {item.count > 0 && <IcomoonIcons icon="chevron-right" />}
        </Grid>
      ) : (
        <DesktopSkeleton isLoading={isLoading}>
          <IconContainer color={item.color}>
            <IcomoonIcons icon={item.icon} color={item.color} size={32} />
          </IconContainer>
          <P size="medium" color="primary" bold margin="5px 0">
            {t(item.label.current.toString())}
          </P>
          <Flex justifyContent="space-between" flexDirection="row">
            <P
              size="small"
              color="charcoal"
              data-testid={`current-occupancy-${item.key.limit}-count`}>
              {t('currentOccupancy:grid.space', { count: item.count })}
            </P>
            {item.count > 0 && <IcomoonIcons icon="chevron-right" />}
          </Flex>
        </DesktopSkeleton>
      )}
    </Container>
  );
};

export default GridItem;
