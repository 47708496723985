import React from 'react';
import i18n from 'core/i18n/i18n';

import { PrivateRoute } from 'pages';

import Spaces from './Spaces/Spaces';
import SpaceDetails from './SpaceDetails/SpaceDetails';
import Dashboard from './Dashboard/Dashboard';
import Settings from './Settings/Settings';
import Profile from './Profile/Profile';

export const OccupancyInsightNavigation = {
  Spaces: {
    routeName: 'Spaces',
    to: '/spaces',
    label: i18n.t('navigation:spaces'),
    icon: 'spaces',
    iconSize: 20,
    adminOnly: false,
    aboveTheFold: true,
  },
};
export const OccupancyInsightPages = () => (
  <>
    <PrivateRoute path="/spaces" component={Spaces} exact />
    <PrivateRoute path="/spaces/:id" component={SpaceDetails} exact />
    <PrivateRoute path="/settings" component={Settings} exact />
    <PrivateRoute path="/dashboard" component={Dashboard} exact />
    <PrivateRoute path="/profile" component={Profile} exact />
  </>
);

export default OccupancyInsightPages;
