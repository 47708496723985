import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Radio, FormComponents, NoMobileWarning } from 'components';
import { LabelValue, NotificationType } from 'models';
import { fetchAvailableNotificationTypes } from 'services';
import { shouldShowModeElement } from 'utils';
import NoAlertTypeWarning from 'components/NoAlertTypeWarning/NoAlertTypeWarning';
import { getNotificationTypeOptions } from 'utils/getNotificationTypeOptions';

interface Props {
  DINotificationScopes: LabelValue[];
  OINotificationScopes: LabelValue[];
  doesUserWantNotificationsDI: boolean;
  doesUserWantAlertsDI: boolean;
  removeOINotifications: boolean;
  showMobileWarningDI: boolean;
  showMobileWarningOI: boolean;
  showNoAlertTypeWarningDI: boolean;
}

export const Form = ({
  DINotificationScopes,
  OINotificationScopes,
  doesUserWantNotificationsDI,
  doesUserWantAlertsDI,
  removeOINotifications,
  showMobileWarningDI,
  showMobileWarningOI,
  showNoAlertTypeWarningDI,
}: Props) => {
  const { t } = useTranslation();

  const [generalAlertTypes, setGeneralAlertTypes] = useState<LabelValue[]>([]);
  const [criticalAlertTypes, setCriticalAlertTypes] = useState<LabelValue[]>(
    [],
  );

  const fetchAlertTypes = async () => {
    const alertTypesData = await fetchAvailableNotificationTypes();
    const generalAlertTypeOptions = getNotificationTypeOptions(
      alertTypesData ?? [],
      NotificationType.Alert,
      true,
    ).filter((a) => !a.isCritical);
    const criticalAlertTypeOptions = getNotificationTypeOptions(
      alertTypesData ?? [],
      NotificationType.Alert,
      true,
    ).filter((a) => a.isCritical);
    setGeneralAlertTypes(generalAlertTypeOptions);
    setCriticalAlertTypes(criticalAlertTypeOptions);
  };

  useEffect(() => {
    fetchAlertTypes();
  }, []);

  return (
    <>
      <FormComponents.Section
        layout="single"
        heading={t('profile:communication_preferences.diHeading')}>
        <Radio
          label={t('profile:communication_preferences.notifications.which')}
          labeltooltip={t(
            'profile:communication_preferences.notifications.which_tooltip',
          )}
          name="DINotificationPreferences.what"
          radioItems={DINotificationScopes}
          idPrefix="di"
        />
        {doesUserWantAlertsDI && (
          <>
            <Checkbox
              label={t(
                'profile:communication_preferences.notifications.alert_types_critical',
              )}
              name="DINotificationPreferences.criticalAlertTypes"
              checkboxItems={criticalAlertTypes}
              labeltooltip={t(
                'profile:communication_preferences.notifications.alert_types_tooltip',
              )}
              selectAllItem={{
                label: t('alerts:names.allCritical'),
                value: NotificationType.AllCritical,
              }}
              columns={3}
            />
            <Checkbox
              label={t(
                'profile:communication_preferences.notifications.alert_types_general',
              )}
              name="DINotificationPreferences.generalAlertTypes"
              checkboxItems={generalAlertTypes}
              labeltooltip={t(
                'profile:communication_preferences.notifications.alert_types_tooltip',
              )}
              columns={3}
              selectAllItem={{
                label: t('alerts:names.allGeneral'),
                value: NotificationType.AllGeneral,
              }}
            />
            {showNoAlertTypeWarningDI && <NoAlertTypeWarning />}
          </>
        )}
        {shouldShowModeElement() && (
          <Radio
            label={t('profile:communication_preferences.notifications.mode')}
            labeltooltip={t(
              'profile:communication_preferences.notifications.mode_tooltip',
            )}
            name="DINotificationPreferences.mode"
            radioItems={DINotificationScopes}
            idPrefix="mode"
          />
        )}
        {doesUserWantNotificationsDI && (
          <>
            <Checkbox
              label={t('profile:communication_preferences.notifications.how')}
              name="DINotificationPreferences.how"
              checkboxItems={[
                {
                  label: t('forms:communication_preferences.methods.sms'),
                  value: 'sms',
                },
                {
                  label: t('forms:communication_preferences.methods.email'),
                  value: 'email',
                },
              ]}
            />
            {showMobileWarningDI && <NoMobileWarning isProfile />}
          </>
        )}
      </FormComponents.Section>
      <FormComponents.Section
        layout="single"
        heading={t('profile:communication_preferences.oiHeading')}>
        <Radio
          label={t('profile:communication_preferences.notifications.what')}
          name="OINotificationPreferences.what"
          radioItems={OINotificationScopes}
          idPrefix="oi"
        />
        {removeOINotifications && (
          <>
            <Checkbox
              label={t('profile:communication_preferences.notifications.when')}
              name="OINotificationPreferences.when"
              checkboxItems={[
                {
                  label: t(
                    'forms:communication_preferences.frequencies.exceeding',
                  ),
                  value: 'alertExceeded',
                },
                {
                  label: t(
                    'forms:communication_preferences.frequencies.approaching',
                  ),
                  value: 'alertApproaching',
                },
              ]}
            />

            <Checkbox
              label={t('profile:communication_preferences.notifications.how')}
              name="OINotificationPreferences.how"
              checkboxItems={[
                {
                  label: t('forms:communication_preferences.methods.sms'),
                  value: 'sms',
                },
                {
                  label: t('forms:communication_preferences.methods.email'),
                  value: 'email',
                },
              ]}
            />
            {showMobileWarningOI && <NoMobileWarning isProfile />}
          </>
        )}
      </FormComponents.Section>
    </>
  );
};
