import styled from '@emotion/styled';
import { theme } from 'style';

export const Footer = styled.div`
  margin-top: 28px;
  padding-bottom: 45px;
  border-top: 1px solid #d7e1f1;
`;

export const AlertActionInfo = styled.div`
  p {
    font-size: 14px;
    color: ${theme.colors.charcoal};
    line-height: 1.4;
    span {
      font-size: 14px !important;
      color: ${theme.colors.charcoal} !important;
      line-height: 1.4;
    }
  }
`;

export const GeneralHeading = styled.text`
  font-size: 12px;
  background-color: #ebf2ff;
  border-radius: 8px;
  padding: 4px 12px;
  align-items: center;
  font-weight: 500;
  margin-left: 12px;
`;

export const CriticalHeading = styled.text`
  color: #e3002a !important;
  font-size: 12px;
  background-color: #ffe9ed;
  border-radius: 8px;
  padding: 4px 12px;
  align-items: center;
  font-weight: 500;
  margin-left: 12px;
`;
