import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Heading as H, Paragraph as P, IcomoonIcons } from 'components';
import { Spacer } from 'style';

import { Container, TextContainer } from './NoCyclesStyle';

const NoCycles = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <IcomoonIcons icon="success" color="success" size={90} />
        <Spacer height={20} />
        <TextContainer>
          <H size="h6" color="primary" mr="8px">
            {t('doorCycles:no_cycles:heading')}
          </H>
        </TextContainer>
        <TextContainer>
          <P size="normal" color="charcoal" textAlign="center" m="10px">
            {t('doorCycles:no_cycles:description')}
          </P>
        </TextContainer>
      </Flex>
    </Container>
  );
};

export default NoCycles;
