import styled from '@emotion/styled';
import { flexCenterAll, mq, theme } from 'style';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;

  ${mq.mobile} {
    display: block;
  }
`;

export const DonutContainer = styled.div`
  ${flexCenterAll};
  border-right: 1px solid ${theme.colors.lightBlueGrey};
  ${mq.mobile} {
    padding: 20px;
    border-right: none;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  ${mq.mobile} {
    display: block;
  }
`;
