import React from 'react';
import { Field, useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { StyledInput, CheckboxWrapper, TickIcon } from './checkboxStyle';

import { Paragraph as P, ToolTip, Grid } from 'components';

import { InputLabel, InputError, InputContainer, Spacer } from 'style';
import { ParagraphSizes } from 'components/Paragraph/Paragraph';
import { LabelTooltipWrapper, LabelWrapper } from 'components/Radio/radioStyle';
import SelectAllCheckbox from './SelectAllCheckbox';

export interface CheckboxItems {
  label: string;
  value: string;
  tooltip?: string;
  disabled?: boolean;
}

interface CheckboxProps {
  /** Name for the input */
  name: string;
  /** Static label about the checkbox */
  label?: string;
  labelSize?: ParagraphSizes;
  labelColor?: string;
  /** Static content to the right of checkbox to describe functionality */
  checkboxItems: CheckboxItems[];
  direction?: 'vertical' | 'horizontal';
  hideErrors?: boolean;
  labeltooltip?: string;
  columns?: number;
  selectAllItem?: CheckboxItems;
  shouldToggleVisibilityOnClick?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checkboxItems,
  direction = 'horizontal',
  hideErrors = false,
  ...props
}: CheckboxProps) => {
  const [field, meta] = useField(props);
  const { t } = useTranslation();  

  return (
    <InputContainer>
      {props.label && (
        <LabelWrapper>
          <P
            color={props.labelColor ? props.labelColor : `charcoal`}
            size={props.labelSize ? props.labelSize : `small`}
            mb="20px"
            bold>
            {props.label}
          </P>
          {props.labeltooltip && (
            <LabelTooltipWrapper>
              <Spacer width={5} />
              <ToolTip
                content={props.labeltooltip}
                iconType="info"
                iconSize={14}
                shouldToggleVisibilityOnClick={props.shouldToggleVisibilityOnClick}          
              />              
            </LabelTooltipWrapper>
          )}          
        </LabelWrapper>
      )}

      {
        props.selectAllItem &&
        <SelectAllCheckbox name={props.name} item={props.selectAllItem} allOptionsWithoutAll={checkboxItems.map(i => i.value)} />
      }      

      <Grid
        gridTemplateColumns={[
          '1fr',
          direction === 'vertical' ? '1fr' : props.columns ? `repeat(${props.columns}, 1fr)` : 'repeat(4, 1fr)',
        ]}
        gridGap={['12px', '12px']}
        mb={meta.touched && meta.error && !hideErrors ? `0` : `40px`}        
        >
        {checkboxItems.map((item: CheckboxItems) => (
          <Field name={props.name} key={item.value}>
            {({ field, form }: { field: any; form: any }) =>
              field.value ? (
                <CheckboxWrapper>
                  <label // style the label to replace the hidden input
                    htmlFor={`${field.name}.${item.value}`}
                    style={{
                      height: 18,
                      width: 18,
                      marginRight: 15,
                    }}>
                    <StyledInput
                      checked={field.value.includes(item.value)}
                      disabled={item.disabled || false}
                      data-testid="checkbox-input">
                      <TickIcon
                        viewBox="0 0 24 24"
                        checked={field.value.includes(item.value)}
                        data-testid="check-icon">
                        <polyline points="20 6 9 17 4 12" />
                      </TickIcon>
                    </StyledInput>
                    <input
                      type="checkbox"
                      {...field}
                      {...props}
                      id={`${field.name}.${item.value}`}
                      checked={field.value.includes(item.value)}
                      onChange={() => {
                        if (field.value.includes(item.value)) {
                          if(props.selectAllItem) {
                            const nextValue = field.value.filter(
                              (value: string) => value !== item.value && props.selectAllItem && value !== props.selectAllItem?.value,
                            );
                            form.setFieldValue(props.name, nextValue);
                          }
                          else {
                            const nextValue = field.value.filter(
                              (value: string) => value !== item.value
                            );
                            form.setFieldValue(props.name, nextValue);
                          }                          
                        } else {                                     
                          if(props.selectAllItem && checkboxItems.length - 1 === field.value.length) {
                            const nextValue = [...field.value, item.value, props.selectAllItem?.value];
                            form.setFieldValue(props.name, nextValue);
                          } 
                          else {
                            const nextValue = field.value.concat(item.value);
                            form.setFieldValue(props.name, nextValue);
                          }                         
                        }
                      }}
                      disabled={item.disabled}
                      style={{
                        // hide the default input
                        border: 0,
                        clip: 'rect(0 0 0 0)',
                        height: '1px',
                        margin: '-1px',
                        overflow: 'hidden',
                        padding: 0,
                        position: 'absolute',
                        whiteSpace: 'nowrap',
                        width: '1px',
                      }}
                    />
                  </label>
                  <P
                    size="small"
                    color={item.disabled ? 'blueGrey' : `charcoal`}
                    margin={0}>
                    {item.label}
                  </P>
                  {item.tooltip && (
                    <>
                      <Spacer width={5} />
                      <ToolTip
                        content={item.tooltip}
                        iconType="question"
                        iconSize={15.5}
                      />
                    </>
                  )}
                </CheckboxWrapper>
              ) : (
                ''
              )
            }
          </Field>
        ))}
      </Grid>

      {!hideErrors && (
        <>
          {meta.touched && meta.error && (
            <InputError>
              <P size="small">{meta.error}</P>
            </InputError>
          )}
        </>
      )}
    </InputContainer>
  );
};

export default Checkbox;
