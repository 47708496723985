import i18next from 'i18next';

export interface ConnectedDoorHealthDonutDataObject {
  name: string;
  displayName: 'Alerts' | 'No Alerts' | null;
  color: string;
  count: number;
}

export interface FilterByConnectivityDonutDataObject {
  name: string | null;
  displayName: 'Commissioned' | 'Decommissioned' | 'Offline';
  color: string;
  count: number;
}

// data structure for connected door health, to be provided to DonutChart.ts
export const connectedDoorHealthDonutChartConfig: ConnectedDoorHealthDonutDataObject[] = [
  {
    name: 'alerts',
    displayName: i18next.t(
      'connectedDoorHealth:doors_by_alert_status.chart_legend_1',
    ),
    color: 'error',
    count: 0,
  },
  {
    name: 'noAlerts',
    displayName: i18next.t(
      'connectedDoorHealth:doors_by_alert_status.chart_legend_2',
    ),
    color: 'success',
    count: 0,
  },
];

// data structure for connected door health, to be provided to DonutChart.ts
export const filterByConnectivityDonutChartConfig: FilterByConnectivityDonutDataObject[] = [
  {
    name: 'commissioned',
    displayName: i18next.t(
      'connectedDoorHealth:doors_by_connectivity_status.chart_legend_1',
    ),
    color: 'success',
    count: 0,
  },
  {
    name: 'decommissioned',
    displayName: i18next.t(
      'connectedDoorHealth:doors_by_connectivity_status.chart_legend_2',
    ),
    color: 'error',
    count: 0,
  },
  {
    name: 'offline',
    displayName: i18next.t(
      'connectedDoorHealth:doors_by_connectivity_status.chart_legend_3',
    ),
    color: 'grey',
    count: 0,
  },
];

export const renameObjKeys = (
  obj: Record<string, string>,
  mapping: Record<string, string>,
) => {
  return Object.keys(obj).reduce((acc: Record<string, string>, key: string) => {
    if (mapping[key]) {
      acc[mapping[key]] = obj[key];
    } else {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};
