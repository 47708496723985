import client from 'core/client/client';
import { ExternalDisplayPin, ExternalDisplayHash, DisplayStatus } from 'models';

const ED_ENDPOINT = 'ExternalDisplay';

export const fetchExternalDisplayPin = async (spaceId: string) => {
  try {
    const request = await client.get(`${ED_ENDPOINT}/${spaceId}/pin`);
    const { status } = request;
    const data: ExternalDisplayPin = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchExternalDisplayHash = async (spaceId: string) => {
  try {
    const request = await client.get(`${ED_ENDPOINT}/${spaceId}/hash`);
    const { status } = request;
    const data: ExternalDisplayHash = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchExternalDisplayStatus = async (sessionId: string) => {
  try {
    const request = await client.get(
      `${ED_ENDPOINT}/details?sessionId=${sessionId}`,
    );
    const { status } = request;
    const data: DisplayStatus = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};
