import create from 'zustand';
import { KeyValue } from './useDoorDetailsStore';

type useDoorStateWebSocketMessageStore = {
  lastMessage: KeyValue | string | null;
  setLastMessage: (data: KeyValue | string) => void;
};

const [useDoorStateWebSocketMessageStore] = create<
  useDoorStateWebSocketMessageStore
>((set) => ({
  lastMessage: null,
  setLastMessage: (data: KeyValue | string) => {
    set({ lastMessage: data });
  },
}));

export default useDoorStateWebSocketMessageStore;
