import styled from '@emotion/styled';

type LegendProps = {
  stackLegends?: boolean;
};

// overall container for the legend section
export const LegendContainer = styled.div<LegendProps>`
  display: flex;
  flex-direction: ${({ stackLegends }) =>
    stackLegends ? 'column-reverse' : 'row-reverse'};
  justify-content: flex-end;
`;

// container for MobileFlare and text
export const LegendItem = styled.div<LegendProps>`
  margin: ${({ stackLegends }) => (stackLegends ? '0px' : '15px')};
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
`;

type MobileFlareProps = {
  color: string;
  size: number;
  theme: any;
};

// small colored square to indicate legend color
export const MobileFlare = styled.div<MobileFlareProps>`
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  border-radius: 2px;
  margin-right: 8px;
  background-color: ${({ color }) => color};
`;
