import React, { useState, useEffect, useRef } from 'react';
import {
  GoogleMap,
  useLoadScript,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api';
import {
  Clusterer,
  ClustererOptions,
} from '@react-google-maps/marker-clusterer';
import { useTranslation } from 'react-i18next';
import { Flex, Button, Skeleton } from 'components';
import { useScreenSize } from 'hooks';
import { DoorListParams, DoorSummary } from 'models';

import DoorsTable from '../DoorsTable/DoorsTable';
import DoorTableLoading from '../DoorsTable/LoadingSkeleton/LoadingSkeleton';
import { mapContainerStyle, options } from './mapAttributes';
import {
  MapContainer,
  TableContainer,
  MobileMapContainer,
  MobileTableContainer,
  MobileTableToggleContainer,
  MobileTableToggle,
} from './doorsMapStyle';
import greenMarker from './green-marker.svg';
import redMarker from './red-marker.svg';
import MarkerTooltip from './MarkerTooltip/MarkerTooltip';
import { getPagedDoors } from 'stores/useDoorStore';

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

interface DoorsMapProps {
  mapData: DoorSummary[];
  tableData: DoorSummary[];
  isLoading: boolean;
  onLoadMore?: (nextPage: number) => void;
  paginated?: boolean;
  total?: number;
  currentPage?: number;
  limit?: number;
  onUpdateIsFollowing?: (value: string) => void;
  listParams: DoorListParams | undefined;
  followedOnly: boolean;
}

interface MarkerProps {
  id: string;
  name: string;
  alertCount: number;
  position: PositionProps;
}

interface PositionProps {
  lat: number;
  lng: number;
}

const DoorsMap = ({
  tableData,
  mapData,
  isLoading,
  onLoadMore,
  paginated,
  total = 0,
  currentPage = 1,
  limit = 20,
  onUpdateIsFollowing,
  listParams,
  followedOnly,
}: DoorsMapProps) => {
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
  });
  const { isMobile } = useScreenSize().screenSize();

  const [defaultCenter, setDefaultCenter] = useState<PositionProps | undefined>(
    undefined,
  );
  const [currentCenter, setCurrentCenter] = useState<PositionProps | undefined>(
    undefined,
  );
  const defaultZoomFlag = useRef(false);
  const [defaultZoom, setDefaultZoom] = useState<number | undefined>(undefined);
  const [currentZoom, setCurrentZoom] = useState<number | undefined>(undefined);
  const [markers, setMarkers] = useState<MarkerProps[]>([]);
  const [activeMarkerId, setActiveMarkerId] = useState<string | null>(null);

  const [mobileTableMode, setMobileTableMode] = useState<
    'Collapsed' | 'Expanded'
  >('Collapsed');

  const [googleMap, setGoogleMap] = useState<any | null>(null);
  const [
    clusterEventListner,
    setClusterEventListner,
  ] = useState<google.maps.MapsEventListener | null>(null);
  const [cluster, setCluster] = useState<any | null>(null);
  const [clusterMarkers, setClusterMarkers] = useState<MarkerProps[] | null>(
    null,
  );
  const [viewableTableData, setViewableTableData] = useState<DoorSummary[]>(
    tableData,
  );
  const [viewableTotal, setViewableTotal] = useState<number>(total);
  const [viewableCurrentPage, setViewableCurrentPage] = useState<number>(
    currentPage,
  );

  // find center to set default map center
  const findCenter = () => {
    let minLat = 90;
    let maxLat = -90;
    let minLng = 180;
    let maxLng = -180;

    mapData.map((point) => {
      const { latitude, longitude } = point.location;
      if (latitude !== null) {
        minLat = Math.min(minLat, latitude);
        maxLat = Math.max(maxLat, latitude);
      }
      if (longitude !== null) {
        minLng = Math.min(minLng, longitude);
        maxLng = Math.max(maxLng, longitude);
      }
    });

    const center = {
      lat: (minLat + maxLat) / 2,
      lng: (minLng + maxLng) / 2,
    };

    setDefaultCenter(center);
    setCurrentCenter(center);
  };

  // create object of markers
  const setupMarkers = () => {
    const result: any = [];
    mapData.map((point) => {
      result.push({
        id: point.id,
        name: point.name,
        alertCount: point.alertCount,
        position: {
          lat: point.location.latitude,
          lng: point.location.longitude,
        },
      });
    });

    setMarkers(result);
  };

  // iterate all marker positions to fit bounds (make sure all of them fit inside the map optimally)
  const fitBounds = (map: any) => {
    const bounds = new window.google.maps.LatLngBounds();

    setGoogleMap(map);

    markers.map((marker) => {
      if (marker.position.lat && marker.position.lng) {
        bounds.extend(marker.position);
      }
    });

    // handle no markers, so the map doesn't show the middle of the sea
    if (
      bounds.getNorthEast().lat() === -1 &&
      bounds.getSouthWest().lat() === 1
    ) {
      bounds.extend({ lat: -33.94, lng: 7.39 });
      bounds.extend({ lat: 52.38, lng: 150.93 });
    }

    map.fitBounds(bounds);
  };

  // set active marker to the door just clicked
  const handleActiveMarker = (markerId: string, position: PositionProps) => {
    if (markerId === activeMarkerId) {
      return;
    }

    // slightly move the center down a bit in mobile view to make sure InfoWindow is not blocked
    if (isMobile) {
      position.lat += 0.0015;
    }
    setCurrentCenter(position);
    setActiveMarkerId(markerId);
    setCurrentZoom(20);
  };

  // show corresponding door on map when a table row has been clicked
  const handleTableRowClick = (row: any) => {
    const lat = row.location.latitude;
    const lng = row.location.longitude;
    if (lat && lng) {
      const id = row.id;
      const position = {
        lat,
        lng,
      };
      handleActiveMarker(id, position);
      setMobileTableMode('Collapsed');
    } else {
      setActiveMarkerId(null);
    }
  };

  const handleOnLoad = (map: any) => {
    fitBounds(map);

    map.set('zoomControlOptions', {
      position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
    });

    google.maps.event.addListener(map, 'bounds_changed', function() {
      if (!defaultZoomFlag.current) {
        setDefaultZoom(map.getZoom());
      }
      defaultZoomFlag.current = true;
      setCurrentZoom(map.getZoom());
    });
  };

  const setViewableTable = () => {
    if (!googleMap) {
      return;
    }

    const bounds = googleMap.getBounds();
    const markersInBounds = markers.filter((m) => bounds.contains(m.position));
    const mapDataInBounds = mapData.filter((m) =>
      markersInBounds.find(
        (mib) =>
          mib.position.lat === m.location.latitude &&
          mib.position.lng === m.location.longitude,
      ),
    );
    const tableDataInBounds = tableData.filter((t) =>
      markersInBounds.find(
        (mib) =>
          mib.position.lat === t.location.latitude &&
          mib.position.lng === t.location.longitude,
      ),
    );
    const viewableCurrentPage =
      mapDataInBounds.length === 0 || tableDataInBounds.length === 0
        ? 0
        : Math.ceil(mapDataInBounds.length / limit) >= currentPage
        ? currentPage
        : 1;
    setViewableCurrentPage(viewableCurrentPage);
    const doorListParams: DoorListParams = {
      offset: (viewableCurrentPage - 1) * limit,
      limit,
    };
    const pagedTableDataInBounds =
      getPagedDoors(doorListParams, tableDataInBounds) ?? [];
    setViewableTotal(tableDataInBounds.length);
    setViewableTableData(pagedTableDataInBounds);
  };

  useEffect(setViewableTable, [tableData, currentPage, listParams]);

  const handleScrollMobileTable = () => {
    if (mobileTableMode === 'Collapsed') {
      setMobileTableMode('Expanded');
    }
  };

  const handleToggleMobileTable = () => {
    if (mobileTableMode === 'Collapsed') {
      setMobileTableMode('Expanded');
    } else {
      setMobileTableMode('Collapsed');
    }
  };

  const getActiveMarkerPosition = (id: string) => {
    const activeMarker = markers.find((marker) => marker.id === id);
    return activeMarker?.position;
  };

  useEffect(() => {
    if (mapData.length > 0) {
      defaultZoomFlag.current = false;
      setActiveMarkerId(null);
      findCenter();
      setupMarkers();
    }
  }, [mapData]);

  const clusterOptions: ClustererOptions = {
    maxZoom: 23,
    gridSize: 30,
    styles: [
      {
        width: 36,
        height: 46,
        url: './cluster.svg',
        textColor: '#FFFFFF',
      },
    ],
    zoomOnClick: false,
  };

  const handleClusterClick = (markerClusterer: Clusterer) => {
    if (clusterEventListner) {
      window.google.maps.event.removeListener(clusterEventListner);
      setClusterMarkers(null);
    }

    const listner = window.google.maps.event.addListener(
      markerClusterer,
      'clusterclick',
      (cluster) => {
        if (googleMap.getZoom() < googleMap.maxZoom) {
          googleMap.setCenter(cluster.getCenter());
          googleMap.setZoom(googleMap.getZoom() + 2);
        } else {
          const clusterDoors = cluster
            .getMarkers()
            .map((marker: any) => marker.title);
          const clusterMarkersData = markers.filter((marker) =>
            clusterDoors.includes(marker.id),
          );
          setClusterMarkers(clusterMarkersData);
          setActiveMarkerId(null);

          google.maps.event.addListener(googleMap, 'zoom_changed', () => {
            setClusterMarkers(null);
          });
        }
      },
    );

    setClusterEventListner(listner);
  };

  useEffect(() => {
    if (window.google && cluster) {
      handleClusterClick(cluster);
    }
  }, [cluster]);

  if (isMobile) {
    return (
      <Flex
        p={0}
        m={0}
        position="fixed"
        top="80px"
        bottom="0"
        width="100%"
        data-testid="doors-map-mobile"
        flexDirection="column">
        <MobileMapContainer>
          {isLoading || !isLoaded ? (
            <Skeleton height="100%" width="100%" />
          ) : (
            <GoogleMap
              onLoad={handleOnLoad}
              onBoundsChanged={setViewableTable}
              zoom={currentZoom}
              center={currentCenter}
              options={{ ...options, minZoom: 1 }}
              mapContainerStyle={mapContainerStyle}>
              <MarkerClusterer options={clusterOptions}>
                {(clusterer) => {
                  setCluster(clusterer);
                  return markers.map(({ id, alertCount, name, position }) => {
                    if (position.lat && position.lng) {
                      return (
                        <Marker
                          zIndex={alertCount === 0 ? 0 : 1}
                          key={id}
                          icon={alertCount === 0 ? greenMarker : redMarker}
                          title={id}
                          position={position}
                          clusterer={clusterer}
                          onClick={() => handleActiveMarker(id, position)}
                          label={
                            alertCount
                              ? {
                                  text: alertCount.toString(),
                                  color: '#FFFFFF',
                                  fontSize: '10px',
                                  fontWeight: 'bold',
                                  className: 'marker-label',
                                }
                              : undefined
                          }>
                          {activeMarkerId && activeMarkerId === id ? (
                            <InfoWindow
                              onCloseClick={() => setActiveMarkerId(null)}
                              position={getActiveMarkerPosition(id)}>
                              <MarkerTooltip
                                id={id}
                                name={name}
                                alertCount={alertCount}
                              />
                            </InfoWindow>
                          ) : null}
                        </Marker>
                      );
                    }
                  });
                }}
              </MarkerClusterer>

              {!activeMarkerId && clusterMarkers ? (
                <InfoWindow
                  onCloseClick={() => setActiveMarkerId(null)}
                  position={getActiveMarkerPosition(clusterMarkers[0].id)}>
                  <>
                    {clusterMarkers.map(
                      ({ id, name, alertCount }: MarkerProps) => (
                        <MarkerTooltip
                          id={id}
                          key={id}
                          name={name}
                          alertCount={alertCount}
                        />
                      ),
                    )}
                  </>
                </InfoWindow>
              ) : null}

              <Button
                style={{ position: 'absolute', right: '60px', bottom: '23px' }}
                disabled={currentZoom === defaultZoom}
                onClick={() => {
                  fitBounds(googleMap);
                }}>
                {t('doorList:map:reset_zoom')}
              </Button>
            </GoogleMap>
          )}
        </MobileMapContainer>
        <MobileTableContainer
          onScroll={handleScrollMobileTable}
          mode={mobileTableMode}>
          <MobileTableToggleContainer>
            <MobileTableToggle onClick={handleToggleMobileTable} />
          </MobileTableToggleContainer>
          <DoorTableLoading isLoading={isLoading} simple>
            <DoorsTable
              data={viewableTableData}
              simple
              onRowClick={handleTableRowClick}
              onLoadMore={onLoadMore}
              total={viewableTotal}
              currentPage={viewableCurrentPage}
              limit={limit}
              paginated={paginated}
            />
          </DoorTableLoading>
        </MobileTableContainer>
      </Flex>
    );
  }

  return (
    <Flex p={0} m={0}>
      <MapContainer>
        {isLoading || !isLoaded ? (
          <Skeleton height="100%" width="100%" />
        ) : (
          <GoogleMap
            onLoad={handleOnLoad}
            onBoundsChanged={setViewableTable}
            zoom={currentZoom}
            center={currentCenter}
            options={options}
            mapContainerStyle={mapContainerStyle}>
            <MarkerClusterer options={clusterOptions}>
              {(clusterer) => {
                setCluster(clusterer);
                return markers.map(({ id, alertCount, name, position }) => {
                  if (position.lat && position.lng) {
                    return (
                      <Marker
                        zIndex={alertCount === 0 ? 0 : 1}
                        key={id}
                        title={id}
                        icon={alertCount === 0 ? greenMarker : redMarker}
                        position={position}
                        clusterer={clusterer}
                        label={
                          alertCount
                            ? {
                                text: alertCount.toString(),
                                color: '#FFFFFF',
                                fontSize: '10px',
                                fontWeight: 'bold',
                                className: 'marker-label',
                              }
                            : undefined
                        }
                        onClick={() => handleActiveMarker(id, position)}>
                        {activeMarkerId && activeMarkerId === id ? (
                          <InfoWindow
                            onCloseClick={() => setActiveMarkerId(null)}>
                            <>
                              <MarkerTooltip
                                id={id}
                                name={name}
                                alertCount={alertCount}
                              />
                            </>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    );
                  }
                });
              }}
            </MarkerClusterer>

            {!activeMarkerId && clusterMarkers ? (
              <InfoWindow
                onCloseClick={() => setActiveMarkerId(null)}
                position={getActiveMarkerPosition(clusterMarkers[0].id)}>
                <>
                  {clusterMarkers.map(
                    ({ id, name, alertCount }: MarkerProps) => (
                      <MarkerTooltip
                        id={id}
                        key={id}
                        name={name}
                        alertCount={alertCount}
                      />
                    ),
                  )}
                </>
              </InfoWindow>
            ) : null}

            <Button
              style={{ position: 'absolute', right: '60px', bottom: '23px' }}
              disabled={currentZoom === defaultZoom}
              onClick={() => {
                fitBounds(googleMap);
              }}>
              {t('doorList:map:reset_zoom')}
            </Button>
          </GoogleMap>
        )}
      </MapContainer>
      <TableContainer>
        <DoorTableLoading isLoading={isLoading} simple>
          <DoorsTable
            data={viewableTableData}
            simple
            onRowClick={handleTableRowClick}
            onLoadMore={onLoadMore}
            onChange={onUpdateIsFollowing}
            total={viewableTotal}
            currentPage={viewableCurrentPage}
            limit={limit}
            paginated={paginated}
          />
        </DoorTableLoading>
      </TableContainer>
    </Flex>
  );
};

export default DoorsMap;
