import moment from 'moment';

import client from 'core/client/client';
import {
  CurrentOccupancy,
  HistoricOccupancy,
  SpaceDetails,
  SpacesList,
  SpaceDailyOccupancyStatus,
  SpaceHistoricOccupancy,
  SpaceTrafficHistory,
  SpacesFilters,
  SpaceListParams,
  HistoricOccupancyParams,
  EditSpaceParams,
  SpaceRecalibrateParams,
  DailyTraffic,
} from 'models';
import {
  formatStartAndEnd,
  createStartEndParam,
  createParamsString,
} from 'utils';

const SPACES_ENDPOINT = 'Spaces';

export const fetchSpacesList = async ({
  params,
  followedOnly
}: {
  followedOnly: boolean;
  params?: SpaceListParams;
}) => {
  const paramsString = params ? '&' + createParamsString(params) : '';
  try {
    const request = await client.get(
      `${SPACES_ENDPOINT}?FollowedOnly=${followedOnly}${paramsString}`,
    );
    const { status } = request;
    const data: SpacesList[] = request.data.items;
    switch (status) {
      case 200:
        return {
          data: data,
          totalSpaceCount: request.data.totalSpaceCount
        };
      case 204:
        return {
          data: data,
          totalSpaceCount: request.data.totalSpaceCount
        };
    }
  } catch (err) {
    throw err;
  }
};

export const fetchSpacesFilters = async () => {
  try {
    const request = await client.get(`${SPACES_ENDPOINT}/filters`);
    const { status } = request;
    const data: SpacesFilters = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchCurrentOccupancy = async (followedOnly: boolean) => {
  try {
    const request = await client.get(
      `${SPACES_ENDPOINT}/occupancy/current?FollowedOnly=${followedOnly}`,
    );

    const { status } = request;
    const data: CurrentOccupancy = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
      case 400:
        return request;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchHistoricOccupancy = async ({
  followedOnly,
  dateObject,
  params,
}: HistoricOccupancyParams) => {
  try {
    const dateString = createStartEndParam(dateObject);
    const paramsString = params ? createParamsString(params) : '';

    const request = await client.get(
      `${SPACES_ENDPOINT}/occupancy?FollowedOnly=${followedOnly}&${dateString}&${paramsString}`,
    );

    const { status } = request;

    const data: HistoricOccupancy[] = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return [];
    }
  } catch (err) {
    throw err;
  }
};

export const fetchSpaceDetails = async (id: string) => {
  try {
    const request = await client.get(`${SPACES_ENDPOINT}/${id}`);
    const { status } = request;
    const data: SpaceDetails = request.data;
    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchSpaceOccupancyEvents = async (id: string) => {
  try {
    const dateObj = formatStartAndEnd('day');
    const dateString = createStartEndParam(dateObj);
    const request = await client.get(
      `${SPACES_ENDPOINT}/${id}/occupancy/events?${dateString}`,
    );
    const { status } = request;
    const data: SpaceDailyOccupancyStatus = request.data;

    switch (status) {
      case 200:
        return data;
      case 204:
        return status;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchSpaceHistoricOccupancy = async ({
  id,
  startDate,
  endDate,
}: {
  id: string;
  startDate: string;
  endDate: string;
}) => {
  try {
    const dateString = createStartEndParam({ startDate, endDate });
    const request = await client.get(
      `${SPACES_ENDPOINT}/${id}/occupancy?${dateString}`,
    );
    const { status } = request;
    const data: SpaceHistoricOccupancy = request.data;

    switch (status) {
      case 200:
        return data;
      case 204:
        return status;
    }
  } catch (err) {
    throw err;
  }
};

export const fetchSpaceTrafficHistory = async ({ id }: { id: string }) => {
  const startDate = moment()
    .startOf('isoWeek')
    .format();
  const endDate = moment()
    .startOf('hour')
    .format();

  try {
    const request = await client.get(
      `${SPACES_ENDPOINT}/${id}/trafficHistory?${createStartEndParam({
        startDate,
        endDate,
      })}`,
    );
    const { status } = request;
    const data: SpaceTrafficHistory[] = request.data;

    switch (status) {
      case 200:
        return data;
      case 204:
        return status;
    }
  } catch (err) {
    throw err;
  }
};

export const editSpaceDetails = async ({
  id,
  editParams,
}: {
  id: string;
  editParams: EditSpaceParams;
}) => {
  try {
    const request = await client.put(`${SPACES_ENDPOINT}/${id}`, editParams);
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export const recalibrate = async ({
  id,
  editParams,
}: {
  id: string;
  editParams: SpaceRecalibrateParams;
}) => {
  try {
    const request = await client.post(
      `${SPACES_ENDPOINT}/${id}/recalibrate`,
      editParams,
    );
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export const followSpace = async (id: string) => {
  try {
    const request = await client.post(`${SPACES_ENDPOINT}/${id}/follow`);
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export const unfollowSpace = async (id: string) => {
  try {
    const request = await client.post(`${SPACES_ENDPOINT}/${id}/unfollow`);
    const { status } = request;
    return status;
  } catch (err) {
    throw err;
  }
};

export const fetchDoorActivity = async (
  id: string,
  {
    startDate,
    endDate,
    sortBy,
    ascending,
  }: {
    startDate: string;
    endDate: string;
    sortBy?: 'InTraffic' | 'OutTraffic' | 'Connectivity' | 'Name';
    ascending?: boolean;
  },
) => {
  const dateString = createStartEndParam({ startDate, endDate });
  const params = sortBy
    ? '&' + createParamsString({ sortBy, Assending: ascending })
    : '';

  try {
    const request = params
      ? await client.get(
          `${SPACES_ENDPOINT}/${id}/devices/traffic?${dateString}${params}`,
        )
      : await client.get(
          `${SPACES_ENDPOINT}/${id}/devices/traffic?${dateString}`,
        );

    const { status } = request;
    const data: DailyTraffic = request.data;

    switch (status) {
      case 200:
        return data;
      case 204:
        return data;
    }
  } catch (err) {
    throw err;
  }
};
