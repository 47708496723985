import config from 'config';

const createMailTo = ({
  email,
  identifier,
  name,
  pin,
}: {
  email: string;
  identifier: string;
  name: string;
  pin: string;
}) => {
  const externalUrl = config.externalDisplay
    .replace('http://', '')
    .replace('.', '&#46;');
  const subject = `Entrivo OI Door Display Details for ${name}`;
  const body = `
  Please open your Entrivo Occupancy Insights Door Display Application and enter the following details:%0D
  %0D
  - Space ID: ${identifier}%0D
  - One-time code: ${pin}%0D
  %0D
  Don't have the Door Display Application setup yet?%0D
  %0D
  Please follow the below instructions.%0D
  %0D
  For Mobile Devices%0D
  %0D
    1. Copy and Paste this URL into your Browser (do not click on the link) ${externalUrl} then:%0D
    %0D
      - For Apple iPad (Safari only), tap the ‘Share’ icon in Safari and select ‘Add to Home Screen’%0D
      - For Android, when prompted, select ‘Add to Home Screen’%0D
      %0D
    2. Close the browser window%0D
    3. Open the Application from the new icon on your Home Screen%0D
    4. Enter the Space ID and One-time code above.%0D
    %0D
  See a short tutorial here for more information%0D
  %0D
  For Desktop Screens (Monitors)%0D
  %0D
    Open ${externalUrl} and enter the details above when prompted%0D
    %0D
  `;
  return (window.location.href = `mailto:?subject=${subject}&body=${body}`);
};

export default createMailTo;
