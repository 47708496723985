import { SelectedDates } from 'models';
import moment from 'moment';

export const formatStartAndEnd = (
  range: 'day' | 'month' | 'week',
  untilNow?: boolean,
) => {
  const date = range === 'week' ? 'isoWeek' : range;
  return {
    startDate: moment()
      .startOf(date)
      .format(),
    endDate: untilNow
      ? moment().format()
      : moment()
          .endOf(date)
          .format(),
  };
};

export const createStartEndParam = ({ startDate, endDate }: any) => {
  const encodeStart = encodeURIComponent(startDate);
  const encodeEnd = encodeURIComponent(endDate);
  const dateParamString = `StartDate=${encodeStart}&EndDate=${encodeEnd}`;
  return dateParamString;
};

export const isSameSelectedDates = (a: SelectedDates, b: SelectedDates) => {
  return a.startDate === b.startDate && a.endDate === b.endDate;
}

export const isCurrentDayWeekOrMonthDates = (selectedDate: SelectedDates): boolean => {
  return isSameSelectedDates(selectedDate, formatStartAndEnd('day'))
  || isSameSelectedDates(selectedDate, formatStartAndEnd('week'))
  || isSameSelectedDates(selectedDate, formatStartAndEnd('month'))
  || isSameSelectedDates({
    startDate: moment(selectedDate.endDate).startOf('day').format(),
    endDate: moment(selectedDate.endDate).endOf('day').format()
  }, formatStartAndEnd('day'));
}

export const isCurrentDayWeek7days = (selectedDate: SelectedDates): boolean => {
  return isSameSelectedDates(selectedDate, formatStartAndEnd('day'))
  || isSameSelectedDates(selectedDate, formatStartAndEnd('week'))
  || moment(selectedDate.endDate).endOf('day').diff( moment(selectedDate.startDate).startOf('day'), 'days') < 7;
}