import styled from '@emotion/styled';

import { theme, mq } from 'style';

export const InfoBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 460px;
  border: 1px solid ${theme.colors.lightBlueGrey};
  box-shadow: 0px 3px 24px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: ${theme.colors.lightBlue};
  padding: 25px;

  ${mq.mobile} {
    width: 100%;
    padding: 25px;
  }
`;
