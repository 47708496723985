import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  Flex,
  Modal,
  Heading as H,
  Paragraph as P,
  Button,
  Grid,
  TextField,
  IcomoonIcons,
} from 'components';
import { useModalStore } from 'stores';
import { Form, Formik } from 'formik';
import i18n from 'core/i18n/i18n';
import { Icon, Header, Heading } from './resetOperatorModalStyles';
import { Spacer } from 'style';
import { requestRemoteOpenDoor } from 'services';
import { Address, DoorDetailsAccount } from 'models';
import { RemoteActionType } from 'models/RemoteActionType';

interface Props {
  deviceUUID: string;
  doorName: string;
  operator: string;
  location: Address;
  account: DoorDetailsAccount;
  isDisabled: boolean;
}

const ResetOperatorModal = ({
  deviceUUID,
  doorName,
  operator,
  location,
  account,
  isDisabled,
}: Props) => {
  const { t } = useTranslation();
  const { resetOperatorModal } = useModalStore();

  const closeAndClearModal = () => {
    setIsSuccess(false);
    setErrors(false);
    setIsSubmitting(false);
    setConfirm(false);
    resetOperatorModal.toggleModal();
  };

  const validation = Yup.object({
    requestReason: Yup.string().required(
      i18n.t('forms:validation.request_reason'),
    ),
  });

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [requestReason, setRequestReason] = useState<string>('');

  const resetOperator = async () => {
    try {
      await requestRemoteOpenDoor(
        deviceUUID,
        RemoteActionType.RESET_OPERATOR,
        requestReason,
      );
      setIsSubmitting(false);
      setIsSuccess(true);
    } catch (err) {
      setIsSubmitting(false);
      setErrors(true);
    }
  };

  return (
    <Modal
      isOpen={resetOperatorModal.isOpen}
      heading={''}
      showHeader={false}
      toggleModal={closeAndClearModal}>
      {isSuccess || errors ? (
        <Flex justifyContent="center" flexDirection="column">
          <Spacer height={24} />
          <Flex centered>
            {isSuccess && (
              <IcomoonIcons icon="success" color="success" size={90} />
            )}
            {errors && <IcomoonIcons icon="error" color="error" size={90} />}
          </Flex>
          <Spacer height={24} />
          <H color="primary" size="h5" marginBottom={30} textAlign="center">
            {isSuccess &&
              t('doorDetails:modals.reset_operator.success.heading')}
            {errors && t('doorDetails:modals.reset_operator.error.heading')}
          </H>
          <Flex justifyContent="center">
            <Button onClick={closeAndClearModal}>{t('common:close')}</Button>
          </Flex>
          <Spacer height={24} />
        </Flex>
      ) : confirm ? (
        <Flex justifyContent="center" flexDirection="column">
          <Header>
            <Heading>
              <P color="primary" bold size="large">
                {t('doorDetails:modals.reset_operator.confirm')}
              </P>
            </Heading>

            <P color="charcoal" bold>
              {operator}
            </P>
            <P color="charcoal" bold>
              {doorName}
            </P>
            <P>
              {location?.city}
              {location?.city && account.name ? `, ` : ``}
              {account.name}
            </P>
          </Header>
          <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap={20}>
            <Button variant="outlined" onClick={closeAndClearModal}>
              {t('common:cancel')}
            </Button>
            <Button
              onClick={() => {
                setIsSubmitting(true);
                resetOperator();
              }}
              isLoading={isSubmitting}>
              {t('common:confirm')}
            </Button>
          </Grid>
        </Flex>
      ) : (
        <Formik
          validateOnMount={true}
          enableReinitialize
          isInitialValid={false}
          initialValues={{ requestReason: '' }}
          validationSchema={validation}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setRequestReason(values.requestReason);
            setSubmitting(false);
            setConfirm(true);
          }}>
          {(props) => {
            return (
              <Form>
                <Icon>
                  <IcomoonIcons icon="refresh" color="primary" size="48" />
                </Icon>
                <Header>
                  <Heading>
                    <P color="primary" bold size="medium">
                      {t('doorDetails:modals.reset_operator.title')}
                    </P>
                  </Heading>

                  <P color="charcoal" bold>
                    {operator}
                  </P>
                  <P color="charcoal" bold>
                    {doorName}
                  </P>
                  <P>
                    {location?.city}
                    {location?.city && account.name ? `, ` : ``}
                    {account.name}
                  </P>

                  <P mt="15px" color="blueGrey">
                    {t('doorDetails:modals.reset_operator.description')}
                  </P>

                  {isDisabled && (
                    <P mt="15px" color="error">
                      {t('doorDetails:modals.reset_operator.disabled_message')}
                    </P>
                  )}
                </Header>
                <TextField
                  name="requestReason"
                  label={t('forms:reset_operator.request_reason.label')}
                  required
                  type="textarea"
                  tooltip={t('forms:reset_operator.request_reason.description')}
                  disabled={isDisabled}
                  rows={5}
                />

                <Grid
                  gridTemplateColumns="repeat(2, 1fr)"
                  gridGap={20}
                  marginTop={20}>
                  <Button variant="outlined" onClick={closeAndClearModal}>
                    {t('common:buttons.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!props.isValid || props.isSubmitting}
                    isLoading={props.isSubmitting}>
                    {t('common:submit')}
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

export default ResetOperatorModal;
