import styled from '@emotion/styled';
import { flexCenterAll, flexVertical, mq, theme } from 'style';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;

  ${mq.mobile} {
    display: block;
  }
`;

export const DonutContainer = styled.div`
  ${flexCenterAll};
  padding: 40px 20px;
`;

export const ListContainer = styled.div`
	padding-right: 10%;
  ${mq.mobile} {
    margin-top: 5%;
    padding-left: 20px;
    padding-bottom: 5%;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const List = styled.div`
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const TabsWrapper = styled.div`
  .react-tabs__tab {
    list-style: none;
    margin: 0;
    padding: 0 0 13px 0;
    color: ${theme.colors.charcoal};
    font-weight: 600;
    cursor: pointer;
    ${flexVertical};
    margin-right: 35px;
    /* &:first-of-type {
      margin-right: 35px;
    } */
  }

  .react-tabs__tab-list {
    display: flex;
    border-bottom: ${theme.borders(1, 'lightBlueGrey')};
    margin-bottom: 30px;
  }

  .react-tabs__tab--selected {
    border-bottom: 3px solid ${theme.colors.primary};
    color: ${theme.colors.primary};
  }
`;
