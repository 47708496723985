import styled from '@emotion/styled';

import { PillProps } from './Pill';

export const BasePill = styled.div<PillProps>`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'black'};
  padding: 3px 10px;
  border-radius: 20px;
`;
