import styled from '@emotion/styled';
import { mq, theme } from 'style';

interface HomeLogoProps {
  variant: 'primary' | 'secondary';
}

export const EntriWorX = styled.span<HomeLogoProps>`
  color: ${(props) => props.variant === 'primary' ? theme.colors.primary : theme.colors.white};
  font-family: ${theme.typography.fontFamily};
  padding: 0;
  font-weight: 600;  
  font-size: 18px;
  ${mq.mobile()} {
    font-size: 14px;
  };
  padding-right: 5px;
`;

export const Insights = styled.span<HomeLogoProps>`
  color: ${(props) => props.variant === 'primary' ? theme.colors.primary : theme.colors.white};
  font-family: ${theme.typography.fontFamily};
  padding: 0;
  font-weight: 400;  
  font-size: 18px;
  ${mq.mobile()} {
    font-size: 14px;
  };
  padding-right: 5px;
`;

export const Badge = styled.span<HomeLogoProps>`
  background-color: ${(props) => props.variant === 'primary' ? theme.colors.primary : theme.colors.white};
  font-family: ${theme.typography.fontFamily};
  color: ${(props) => props.variant === 'primary' ? theme.colors.white : theme.colors.primary};
  padding: 0;
  font-weight: 400;  
  font-size: 12px;
  border-radius: 4px;
  ${mq.mobile()} {
    font-size: 10px;
  };
  padding: 5px;  
`;