import React, { ReactNode } from 'react';
import { useField } from 'formik';

import {
  LabelTooltipWrapper,
  LabelWrapper,
  RadioWrapper,
} from './radioStyle';

import { Grid, Paragraph as P, ToolTip } from 'components';
import { InputContainer, InputError, Spacer } from 'style';
import { ParagraphSizes } from 'components/Paragraph/Paragraph';

interface RadioItems {
  label: string;
  value: string;
  tooltip?: string;
}

interface RadioProps {
  /** Name for the input */
  name: string;
  /** Static label about the Radio */
  radioItems: RadioItems[];
  label?: string;
  labelSize?: ParagraphSizes;
  labelColor?: string;
  labeltooltip?: string;
  direction?: 'vertical' | 'horizontal';
  idPrefix?: string;
  labelWarning?: ReactNode;
}

const Radio: React.FC<RadioProps> = ({
  radioItems,
  direction = 'horizontal',
  idPrefix,
  ...props
}: RadioProps) => {
  const [field, meta] = useField(props);

  return (
    <InputContainer>
      {props.label && (
        <LabelWrapper>
          <P
            color={props.labelColor ? props.labelColor : `charcoal`}
            size={props.labelSize ? props.labelSize : `small`}
            mb="20px"
            bold>
            {props.label}
          </P>
          {props.labeltooltip && (
            <LabelTooltipWrapper>
              <Spacer width={5} />
              <ToolTip
                content={props.labeltooltip}
                iconType="info"
                iconSize={14}
              />
              
              {props.labelWarning && (
                <>
                <Spacer width={5} />
                {props.labelWarning}
                </>
              )}
            </LabelTooltipWrapper>
          )}          
        </LabelWrapper>
      )}

      <Grid
        style={{ position: 'relative' }}
        gridTemplateColumns={[
          '1fr',
          direction === 'vertical' ? '1fr' : 'repeat(4, 1fr)',
        ]}
        gridRowGap={['12px', 0]}
        mb={meta.touched && meta.error ? `0` : `40px`}>
        {radioItems.map((item: RadioItems) => (
          <RadioWrapper key={item.value}>
            <input
              type="radio"
              {...field}
              {...props}
              id={`${idPrefix ? idPrefix + '-' : ''}${item.value}`}
              name={props.name}
              checked={field.value == item.value}
              value={item.value}
              style={{ display: 'none' }}
            />
            <label
              htmlFor={`${idPrefix ? idPrefix + '-' : ''}${item.value}`}
              style={{ position: 'relative' }}>
              <P size="small" color="charcoal" margin={0}>
                {item.label}
              </P>
            </label>
            {item.tooltip && (
              <>
                <Spacer width={5} />
                <ToolTip
                  content={item.tooltip}
                  iconType="question"
                  iconSize={14}
                />
              </>
            )}
          </RadioWrapper>
        ))}
      </Grid>
      {meta.touched && meta.error && (
        <InputError>
          <P size="small">{meta.error}</P>
        </InputError>
      )}
    </InputContainer>
  );
};

export default Radio;
